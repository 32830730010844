import moment from "moment";
import React from "react";
import {
  getAmountName,
  isAmount,
  isFloat,
  roundOffAmount,
} from "../../../helper/Common";

//Dont remove the commented fields it will use in future
const PrintInvoice = (props) => {
  const {
    distributorDetail,
    isTaxSummaryShow,
    invoiceData,
    itemSupplied,
    shippingDetails,
    invoiceTax,
  } = props;
  let addArray = [];

  if (shippingDetails) {
    if (shippingDetails?.address1) {
    }
    if (shippingDetails?.address1) {
      addArray.push(shippingDetails?.address1);
    }
    if (shippingDetails?.address2) {
      addArray.push(shippingDetails?.address2);
    }
    if (shippingDetails?.address3) {
      addArray.push(shippingDetails?.address3);
    }
    if (shippingDetails?.cityId) {
      addArray.push(shippingDetails?.cityId);
    }
    if (shippingDetails?.pincode) {
      addArray.push(shippingDetails?.pincode);
    }
  }

  let salesManData = invoiceData?.salesmanData;

  return (
    <div>
      {/* Heading Part */}
      <div className="text-center">
        <h4>{distributorDetail?.nameToDisplay}</h4>
        <h6>{distributorDetail?.street}</h6>
        <h6>
          {distributorDetail?.cityId} - {distributorDetail?.pincode}
        </h6>
      </div>
      <hr />
      {/* Second Part */}
      <div>
        <div className="d-flex justify-content-center">
          <h4>TAX INVOICE</h4>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <p>CIN : {distributorDetail?.cin}</p>
            <p>Email : {distributorDetail?.userData?.email}</p>
          </div>
          <div>
            <p>Tel No : {distributorDetail?.userData?.contactNumber}</p>
            <p>GSTN No : {distributorDetail?.gstin}</p>
          </div>
          <div>
            <p>FSSAI No : {distributorDetail?.fssai}</p>
            <p>Contact No : {distributorDetail?.userData?.contactNumber}</p>
          </div>
        </div>
      </div>
      {/* User Details */}
      <table className="table  table-bordered">
        <thead>
          <tr>
            <th scope="col" hidden></th>
            <th scope="col" hidden></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width={"50%"}>
              <div>
                <p className="font-weight-bold">Shipping Details:</p>
                <p>
                  Customer Name:{" "}
                  <span className="font-weight-bold">
                    {shippingDetails?.name}
                  </span>
                </p>
                <p>Address:</p>
                <p className="pb-1 pl-3 font-weight-bold">
                  {addArray.join(", ")}.
                </p>
                <p>
                  Contact No:{" "}
                  <span className="font-weight-bold">
                    {shippingDetails?.mobileNo}
                  </span>
                </p>
                <p>
                  GSTN No:{" "}
                  <span className="font-weight-bold">
                    {shippingDetails?.gstNo}
                  </span>
                </p>
              </div>
            </td>
            <td width={"50%"}>
              <div>
                <p className="pb-1">
                  Invoice Number:
                  <span className="pl-2 font-weight-bold">
                    {invoiceData?.invoiceDetails?.invoiceNo}
                  </span>
                </p>
                <p className="pb-1">
                  Invoice Date:
                  <span className="pl-2 font-weight-bold">
                    {invoiceData?.invoiceDetails?.invoiceDate &&
                      moment(invoiceData?.invoiceDetails?.invoiceDate).format(
                        "LL"
                      )}
                  </span>
                </p>

                {/* payment Details */}
                {/* <p>
                  Customer PO Number:{" "}
                  <span className="font-weight-bold">N/A</span>
                </p>
                <p>
                  Customer PO Date:{" "}
                  <span className="font-weight-bold">N/A</span>
                </p> */}
                <p className="pb-1">
                  Payment Terms:{" "}
                  <span className="pl-2 font-weight-bold text-uppercase">
                    {invoiceData?.invoiceDetails?.paymentMode ||
                      invoiceData?.invoiceDetails?.payment_terms}
                  </span>
                </p>
                <p className="pb-1">
                  Customer ID:
                  <span className="pl-2 font-weight-bold">
                    {invoiceData?.invoiceDetails?.sold_to_party ||
                      invoiceData?.invoiceDetails?.bill_to_party}
                  </span>
                </p>
                {/* <p>
                  Sales Emp ID:{" "}
                  <span className="font-weight-bold">
                    {salesManData?.employeeId ?? "N/A"}
                  </span>
                </p>  */}
                <p className="pb-1">
                  Sales Emp Name/Contact No:
                  <span className="pl-2 font-weight-bold">
                    {salesManData?.fullName ?? "N/A"} /{" "}
                    {salesManData?.contactMobile ?? "N/A"}
                  </span>
                </p>
                {/* <p>
                  Delivery From:{" "}
                  <span className="font-weight-bold">
                    {distributorDetail?.warehouseName}
                  </span>
                </p> */}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Item Detail  */}
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">SL No.</th>
            <th scope="col">Material</th>
            <th scope="col">UOM</th>
            <th scope="col">HSN</th>
            <th scope="col" style={{ minWidth: "150px" }}>
              Unit Price with tax
            </th>
            <th scope="col">Quantity</th>
            <th scope="col">Discount</th>
            <th scope="col">Base value</th>
            <th scope="col">GST Rate</th>
            <th scope="col">GST Amount</th>
            <th scope="col">Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {itemSupplied &&
            itemSupplied.length > 0 &&
            itemSupplied.map((item, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{item.itemName}</td>
                <td>{item.uom}</td>
                <td>{item.HSN_number}</td>
                <td>{isAmount(item.total_amount / item.quantity, true)}</td>
                <td>{item.quantity}</td>
                <td>{isAmount(item.discountAmount, true)}</td>
                <td>
                  {isAmount(item.itemAmount + item?.discountAmount, true)}
                </td>
                <td>{item?.sgst_pr + item?.cgst_pr + item?.igst_pr} %</td>
                <td>
                  {isAmount(
                    item?.sgst_value + item?.cgst_value + item?.igst_value,
                    true
                  )}
                </td>
                <td>
                  {isAmount(item.total_amount - item.discountAmount, true)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {/* Sub Amount  */}
      <div className="pull-right" style={{ width: "40%" }}>
        <table className="table table-bordered">
          <tr>
            <td>SubTotal</td>
            <td>
              {isAmount(
                Number(invoiceData?.totalAmount) +
                  Number(invoiceTax?.totalValue?.totalDiscount),
                true
              )}
            </td>
          </tr>
          <tr>
            <td>GST Amount</td>
            <td>{isAmount(invoiceData?.totalTax, true)}</td>
          </tr>
          <tr>
            <td>Discount</td>
            <td>{isAmount(invoiceTax?.totalValue?.totalDiscount, true)}</td>
          </tr>
          {/* <tr>
            <td>Round Off</td>
            <td>
              {invoiceData?.totalNetValue &&
                roundOffAmount(
                  Number(invoiceData?.totalNetValue) -
                    Math.round(invoiceData?.totalNetValue)
                )}
            </td>
          </tr> */}
          <tr>
            <td>Grand Total</td>
            <td>{isAmount(Number(invoiceData?.totalNetValue))}</td>
          </tr>
        </table>
      </div>
      {/* Amount in word  */}
      <table className="table table-bordered">
        <tr>
          <td style={{ maxWidth: "50px" }}>Amount in words:</td>
          <td>
            {invoiceData?.totalNetValue &&
              getAmountName(invoiceData?.totalNetValue)}
          </td>
        </tr>
      </table>
      <p className="pt-4">
        I/We hereby certify that food/foods mentioned in this invoice is/ are
        warranted to be of the nature and quality if/these purports/purpot to
        be.
      </p>
      <div className="d-flex justify-content-between pb-5">
        <p>Received the above goods in condition</p>
        <p>For {distributorDetail?.nameToDisplay}</p>
      </div>
      <div className="d-flex justify-content-between pt-5">
        <p>Customers Signature & Seal</p>
        <p>Authorized Signature</p>
      </div>

      {/* Tax Detail  */}
      {isTaxSummaryShow && (
        <div className="d-flex justify-content-between pageBreak ">
          <div className="col-3">
            <table className="table table-bordered">
              <tr>
                <th
                  className="tax-sum"
                  style={{ marginLeft: "200px" }}
                  colSpan={9}
                >
                  Tax summary
                </th>
              </tr>
              <tr>
                <th className="tax-sum p-3">SL No.</th>
                <th className="tax-sum p-3">Tax %</th>
                <th style={{ minWidth: "100px" }} className="tax-sum p-3">
                  Taxable Amount
                </th>
                <th className="tax-sum p-3">CGST Amount</th>
                <th className="tax-sum p-3">SGST Amount</th>
                <th className="tax-sum p-3">IGST Amount</th>
                <th className="tax-sum p-3">CESS Amount</th>
                <th className="tax-sum p-3">TCS Amount</th>
                <th className="tax-sum p-3">Round Off Amount</th>
              </tr>
              {invoiceTax &&
                invoiceTax.result &&
                invoiceTax.result.map((item, i) => {
                  return (
                    <>
                      <tr>
                        <td>{i + 1}</td>
                        <td> {Math.round(item.taxPercentage)} %</td>
                        <td style={{ maxWidth: "100px" }}>
                          {isAmount(item.taxableAmount)}
                        </td>
                        <td>{isAmount(item.cgst_value)} </td>
                        <td>{isAmount(item.sgst_value)}</td>
                        <td>{isAmount(item.igst_value)}</td>
                        <td>{isAmount(item.cess_value)}</td>
                        <td>{isAmount(item.TCS_amount)}</td>
                        <td></td>
                      </tr>
                    </>
                  );
                })}

              <tr>
                <th></th>
                <td className="tax-sum">Total</td>
                <td className="tax-sum">
                  {isAmount(invoiceData?.totalAmount)}
                </td>
                <td colSpan={4} className="tax-sum">
                  {isAmount(invoiceTax?.totalValue?.gstAmount)}
                </td>
                <td></td>
                <td className="tax-sum">
                  {isAmount(
                    Number(invoiceData?.totalNetValue) -
                      (Number(invoiceData?.totalAmount) +
                        Number(invoiceData?.totalTax))
                  )}
                </td>
              </tr>
              <tr>
                <th colSpan={9}>
                  <span className="float-left ml-5 mr-5"> Grand Total</span>
                  <span className="pl-5 ml-5">
                    {isAmount(Math.ceil(invoiceData?.totalNetValue))}
                  </span>
                </th>
              </tr>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrintInvoice;
