import React from "react";
import PrimaryHeader from "../header/PrimaryHeader";
import { Tabs, Input, Pagination, DatePicker, Spin, Modal } from "antd";
import "./index.scss";
import { AsyncPaginate } from "react-select-async-paginate";
import { getPurchasePendingList } from "../../services/franchiseServices/purchaseServices";
import moment from "moment";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { LoadingOutlined } from "@ant-design/icons";
import { isAmount } from "../../helper/Common";

const { TabPane } = Tabs;
const { Search } = Input;

const options = [
  { label: "Generated", value: 1 },
  { label: "Partial Return", value: 2 },
  { label: "Return", value: 3 },
];
const pendingOptions = [
  { label: "Pending", value: 1 },
  { label: "Request Raised", value: 2 },
];

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function Index(props) {
  const [tab, setTab] = React.useState("Pending");
  const [page, setPage] = React.useState(1);
  const [historyPage, setHistoryPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [skip, setSkip] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [totalHistoryRecords, setTotalHistoryRecords] = React.useState(0);
  const [pendingArray, setPendingArray] = React.useState([]);
  const [historyArray, setHistoryArray] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [filterValues, setFilterValues] = React.useState({
    orderDate: "",
    status: "",
    grnStatus: "",
    deliveryDate: "",
    invoiceDate: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState(false);
  const [key, setKey] = React.useState(false);

  React.useEffect(() => {
    const { state } = props && props.history.location;
    if (state == "history" && key == false) {
      setTab("History");
    }
    if (tab == "Pending") {
      let filterParams = dynamicFilters();
      let qParams = [
        {
          page: page,
        },
        { pageSize: limit },
        { status: "pending" },
        ...filterParams,
      ];
      getPendingList(qParams);
    }
    if (tab == "History") {
      let filterParams = dynamicFilters();
      let qParams = [
        {
          page: historyPage,
        },
        { pageSize: limit },
        { status: "history" },
        ...filterParams,
      ];
      getHistoryList(qParams);
    }
  }, [
    filterValues["deliveryDate"],
    filterValues["status"],
    filterValues["grnStatus"],
    filterValues["orderDate"],
    filterValues["invoiceDate"],
    page,
    searchText,
    tab,
    historyPage,
  ]);

  const getPendingList = async (qParams) => {
    setLoading(true);
    await getPurchasePendingList(qParams)
      .then((res) => {
        setLoading(false);
        setPendingArray(res && res.data.response.data);
        setSkip(res && res.data.response.skip);
        setPageSize(res && res.data.response.totalPages * 7);
        setTotalRecords(res && res.data.response.totalRecords);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const getHistoryList = async (qParams) => {
    setLoading(true);
    await getPurchasePendingList(qParams)
      .then((res) => {
        setLoading(false);
        setHistoryArray(res && res.data.response.data);
        setSkip(res && res.data.response.skip);
        setPageSize(res && res.data.response.totalPages * 7);
        setTotalHistoryRecords(res && res.data.response.totalRecords);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const handleChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };
  const handleHistoryChange = (page, pageSize) => {
    setHistoryPage(page);
    setPageSize(pageSize);
  };

  const redirection = (id) => {
    props.history.push(`/dashboard/purchaseOrders/${id}`);
  };

  const redirectionToHistory = (item) => {
    props.history.push({
      pathname: `/dashboard/purchaseOrders/history/${item._id}`,
      state: item.status,
    });
  };
  const tabHandler = (key) => {
    setTab(key);
    setKey(true);
    setSearchValue("");
    setSearchText(false);
    setFilterValues((prev) => ({
      ...prev,
      orderDate: "",
      deliveryDate: "",
      status: "",
      grnStatus: "",
      invoiceDate: "",
    }));
    setPage(1);
    setHistoryPage(1);
  };

  const filterHandler = (e, type) => {
    if (type == "search") {
      setSearchValue(e.target.value);
    }
    if (type == "orderDate") {
      setFilterValues((prev) => ({
        ...prev,
        orderDate: e ? moment(e._d) : "",
      }));
    }
    if (type == "status") {
      setFilterValues((prev) => ({
        ...prev,
        status: e ? e : "",
      }));
    }
    if (type == "grnStatus") {
      setFilterValues((prev) => ({
        ...prev,
        grnStatus: e ? e : "",
      }));
    }
    if (type == "deliveryDate") {
      setFilterValues((prev) => ({
        ...prev,
        deliveryDate: e ? moment(e._d) : "",
      }));
    }
    if (type == "invoiceDate") {
      setFilterValues((prev) => ({
        ...prev,
        invoiceDate: e ? moment(e._d) : "",
      }));
    }
  };

  const dynamicFilters = () => {
    let qParams = [];
    if (filterValues["orderDate"] != "") {
      qParams = [
        ...qParams,
        {
          orderDate: moment(filterValues["orderDate"]).format("YYYY-MM-DD"),
        },
      ];
    }
    if (filterValues["invoiceDate"] != "") {
      qParams = [
        ...qParams,
        {
          deliveryDate: moment(filterValues["invoiceDate"]).format(
            "YYYY-MM-DD"
          ),
        },
      ];
    }
    if (filterValues["status"] != "") {
      qParams = [
        ...qParams,
        {
          orderStatus:
            filterValues["status"].value == 1 ? "pending" : "requestraised",
        },
      ];
    }
    if (filterValues["grnStatus"] != "") {
      qParams = [
        ...qParams,
        {
          orderStatus:
            filterValues["grnStatus"].value == 1
              ? "generated"
              : filterValues["grnStatus"].value == 2
              ? "partial-return"
              : "return",
        },
      ];
    }
    if (filterValues["deliveryDate"] != "") {
      qParams = [
        ...qParams,
        {
          deliveryDate: moment(filterValues["deliveryDate"]).format(
            "YYYY-MM-DD"
          ),
        },
      ];
    }
    if (searchValue != "") {
      qParams = [...qParams, { purchaseOrderId: searchValue }];
    }

    return qParams;
  };

  const resetFilters = () => {
    setFilterValues((prev) => ({
      ...prev,
      orderDate: "",
      status: "",
      deliveryDate: "",
      invoiceDate: "",
      grnStatus: "",
    }));
  };

  const onSearch = (value) => {
    setSearchText(value);
  };

  const pendingTable = pendingArray.map((item) => {
    return (
      <tr className="cursor" onClick={() => redirection(item._id)}>
        <td>{item["order_date"] ?? "N/A"}</td>
        <td>{item?.Invoice_Id ?? "N/A"}</td>
        <td>{item?.DeliveryDate ?? "N/A"}</td>
        <td>{item.itemCount ? item.itemCount : "N/A"}</td>
        <td>{isAmount(item?.totalAmount, true)}</td>
        <td>
          {
            <span className="pending_div">
              <div
                className={`${
                  item.PoFulfillmentStatus == 0
                    ? "pendingDot"
                    : item.PoFulfillmentStatus == 1
                    ? "requestDot"
                    : ""
                }`}
              ></div>
              {item.PoFulfillmentStatus == 0
                ? "Pending"
                : item.PoFulfillmentStatus == 1
                ? "Request Raised"
                : "Generated"}
            </span>
          }
        </td>
      </tr>
    );
  });

  const historyTable = historyArray.map((item) => {
    return (
      <tr className="cursor" onClick={() => redirectionToHistory(item)}>
        <td>{item["order_date"] ? item["order_date"] : "N/A"}</td>
        <td>{item.Invoice_Id ? item.Invoice_Id : "N/A"}</td>
        <td>{item.DeliveryDate ? item.DeliveryDate : "N/A"}</td>
        <td>{item.itemCount ? item.itemCount : "N/A"}</td>
        <td>{item?.totalAmount ?? "N/A"}</td>
        <td>
          {
            <span className="pending_div">
              <div
                className={`${
                  item.PoFulfillmentStatus == 2
                    ? "generateDot"
                    : item.PoFulfillmentStatus == 3
                    ? "partialDot"
                    : item.PoFulfillmentStatus == 4
                    ? "returnDot"
                    : ""
                }`}
              ></div>
              {item.PoFulfillmentStatus == 2
                ? "Generated"
                : item.PoFulfillmentStatus == 3
                ? "Partial Return"
                : item.PoFulfillmentStatus == 4
                ? "Return"
                : ""}
            </span>
          }
          {(item.PoFulfillmentStatus == 2 || item.PoFulfillmentStatus == 3) && (
            <span>{`ID # ${item.Invoice_Id}`}</span>
          )}
        </td>
      </tr>
    );
  });

  return (
    <div id="content">
      <PrimaryHeader title={"Purchase Receipt"} />
      <Spin indicator={antIcon} spinning={loading}>
        <div className="mt-5">
          <Tabs activeKey={tab} onChange={tabHandler}>
            <TabPane tab={"Pending"} key={"Pending"}>
              <div className="col-lg-5  mt-4">
                <Search
                  placeholder="Search by Order ID/ Invoice ID "
                  onSearch={onSearch}
                  enterButton
                  onChange={(e) => filterHandler(e, "search")}
                  value={searchValue}
                  className="customSearch"
                />
              </div>
              <div className="d-flex mt-5 mb-5">
                <div className=" purchase_picker border_right ">
                  <DatePicker
                    placeholder={"Order Date"}
                    className="mt-1"
                    value={filterValues["orderDate"]}
                    format="DD/MM/YYYY"
                    onChange={(e) => filterHandler(e, "orderDate")}
                  />
                </div>
                <div className="franchise border_right  col-lg-3">
                  <AsyncPaginate
                    className="basic-multi-select"
                    additional={{
                      page: 1,
                    }}
                    // loadOptions={loadMaterialcode}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    value={filterValues["status"]}
                    onChange={(e) => filterHandler(e, "status")}
                    loadingMessage={() => "No Status Found"}
                    placeholder={"Status"}
                    options={pendingOptions}
                  />
                </div>
                <div className="ml-3 purchase_picker ">
                  <DatePicker
                    placeholder={"Delivery Date"}
                    className="mt-1"
                    value={filterValues["deliveryDate"]}
                    format="DD/MM/YYYY"
                    onChange={(e) => filterHandler(e, "deliveryDate")}
                  />
                </div>
                <div className="pt-2" onClick={() => resetFilters()}>
                  <label htmlFor="" className="cursor pending_label ml-4">
                    {"Reset Filter"}
                  </label>
                </div>
              </div>

              {/* <div className="d-flex mb-5">
                            <div className="row col-lg-12">
                                <div className="mt-5 franchise border_right col-lg-3">
                                    <AsyncPaginate
                                        className="basic-multi-select"
                                        additional={{
                                            page: 1,
                                        }}
                                        // loadOptions={loadMaterialcode}
                                        isClearable={true}
                                        isSearchable={true}
                                        closeMenuOnSelect={true}
                                        // value={filterValues["orderDate"]}
                                        // onChange={(e) =>
                                        //     handleChange(e, "materialCode")
                                        // }
                                        loadingMessage={() => "No Date Found"}
                                        placeholder={"Order Date"}
                                        options={options}
                                    />
                                </div>
                                <div className="mt-5 franchise border_right  col-lg-3">
                                    <AsyncPaginate
                                        className="basic-multi-select"
                                        additional={{
                                            page: 1,
                                        }}
                                        // loadOptions={loadMaterialcode}
                                        isClearable={true}
                                        isSearchable={true}
                                        closeMenuOnSelect={true}
                                        // value={filterValues["orderDate"]}
                                        // onChange={(e) =>
                                        //     handleChange(e, "materialCode")
                                        // }
                                        loadingMessage={() => "No Status Found"}
                                        placeholder={"Status"}
                                        options={options}
                                    />
                                </div>
                                <div className="mt-5 franchise col-lg-3">
                                    <AsyncPaginate
                                        className="basic-multi-select"
                                        additional={{
                                            page: 1,
                                        }}
                                        // loadOptions={loadMaterialcode}
                                        isClearable={true}
                                        isSearchable={true}
                                        closeMenuOnSelect={true}
                                        // value={filterValues["orderDate"]}
                                        // onChange={(e) =>
                                        //     handleChange(e, "materialCode")
                                        // }
                                        loadingMessage={() => "No Dates Found"}
                                        placeholder={"Delivery Date "}
                                        options={options}
                                    />
                                </div>
                                <div className="mt-5 ml-4 pt-2">
                                    <label
                                        htmlFor=""
                                        className="cursor pending_label"
                                    >
                                        {"Reset Filter"}
                                    </label>
                                </div>
                            </div>
                        </div> */}
              <div className="table-wrapper mt-4">
                <table className="table">
                  <thead className="remove-border-top">
                    <tr>
                      <th scope="col">{"Invoice Date"}</th>
                      <th scope="col">{"Invoice ID"}</th>
                      <th scope="col">{"Delivery Date"}</th>
                      <th scope="col">{"Item Count"}</th>
                      <th scope="col">{"Amount"}</th>
                      <th scope="col">{"Status"}</th>
                    </tr>
                  </thead>
                  <tbody>{pendingTable}</tbody>
                </table>
                {pendingTable.length === 0 && (
                  <div className="no-data">
                    <p>{"No Data Available"}</p>
                  </div>
                )}
              </div>
              {pendingTable.length > 0 && (
                <div className="row mt-3 mb-3">
                  <div className="col-lg-4">
                    Showing {skip + 1} to{" "}
                    {skip + 10 > totalRecords ? totalRecords : skip + 10} of{" "}
                    {totalRecords} entries
                  </div>
                  <div className="offset-lg-4 col-lg-4 franchisePagination">
                    {pageSize != 0 && (
                      <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRecords}
                        showSizeChanger={false}
                        onChange={(page, pageSize) =>
                          handleChange(page, pageSize)
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </TabPane>
            {/* <TabPane tab={"Return"} key={"Return"}>
                        <div className="col-lg-5 ml-2 mt-4">
                            <Search
                                placeholder="Search by Order ID/ Invoice ID "
                                onSearch={"onSearch"}
                                enterButton
                                className="customSearch"
                            />
                        </div>
                    </TabPane> */}
            <TabPane tab={"History"} key={"History"}>
              <div className="col-lg-5  mt-4">
                <Search
                  placeholder="Search by Order ID/ Invoice ID "
                  onSearch={onSearch}
                  enterButton
                  onChange={(e) => filterHandler(e, "search")}
                  value={searchValue}
                  className="customSearch"
                />
              </div>
              <div className="d-flex mb-5">
                <div className="row col-lg-12">
                  <div className="mt-5 franchise border_right col-lg-3">
                    <AsyncPaginate
                      className="basic-multi-select"
                      additional={{
                        page: 1,
                      }}
                      // loadOptions={loadMaterialcode}
                      isClearable={true}
                      isSearchable={true}
                      closeMenuOnSelect={true}
                      value={filterValues["grnStatus"]}
                      onChange={(e) => filterHandler(e, "grnStatus")}
                      loadingMessage={() => "No Date Found"}
                      placeholder={"GRN Status"}
                      options={options}
                    />
                  </div>
                  <div className="mt-5 purchase_picker">
                    {/* <AsyncPaginate
                                            className="basic-multi-select"
                                            additional={{
                                                page: 1,
                                            }}
                                            // loadOptions={loadMaterialcode}
                                            isClearable={true}
                                            isSearchable={true}
                                            closeMenuOnSelect={true}
                                            // value={filterValues["orderDate"]}
                                            // onChange={(e) =>
                                            //     handleChange(e, "materialCode")
                                            // }
                                            loadingMessage={() =>
                                                "No Status Found"
                                            }
                                            placeholder={"Invoice Date"}
                                            options={options}
                                        /> */}
                    <DatePicker
                      placeholder={"Invoice Date"}
                      className="mt-1"
                      value={filterValues["invoiceDate"]}
                      format="DD/MM/YYYY"
                      onChange={(e) => filterHandler(e, "invoiceDate")}
                    />
                  </div>

                  <div className="mt-5 ml-4 pt-2">
                    <label
                      htmlFor=""
                      className="cursor pending_label"
                      onClick={() => resetFilters()}
                    >
                      {"Reset Filter"}
                    </label>
                  </div>
                </div>
              </div>
              <div className="table-wrapper mt-4">
                <table className="table">
                  <thead className="remove-border-top">
                    <tr>
                      <th scope="col">{"Invoice Date"}</th>
                      <th scope="col">{"Invoice ID"}</th>
                      <th scope="col">{"Delivery Date"}</th>
                      <th scope="col">{"Item Count"}</th>
                      <th scope="col">{"Amount"}</th>
                      <th scope="col">{"GRN Status"}</th>
                    </tr>
                  </thead>
                  <tbody>{historyTable}</tbody>
                </table>
                {historyTable.length === 0 && (
                  <div className="no-data">
                    <p>{"No Data Available"}</p>
                  </div>
                )}
              </div>
              {historyTable.length > 0 && (
                <div className="row mt-3 mb-3">
                  <div className="col-lg-4">
                    Showing {skip + 1} to{" "}
                    {skip + 10 > totalHistoryRecords
                      ? totalHistoryRecords
                      : skip + 10}{" "}
                    of {totalHistoryRecords} entries
                  </div>
                  <div className="offset-lg-4 col-lg-4 franchisePagination">
                    {pageSize != 0 && (
                      <Pagination
                        defaultCurrent={1}
                        current={historyPage}
                        total={totalHistoryRecords}
                        showSizeChanger={false}
                        onChange={(historyPage, totalHistoryPage) =>
                          handleHistoryChange(historyPage, totalHistoryPage)
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </div>
  );
}
