import React from "react";

export default class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="mt-4  table-wrapper" id="print_table">
        <table className="table common-table">
          <thead className="remove-border-top">
            <th scope="col">Material</th>
            <th>Batch ID</th>
            <th>Material Group</th>
            <th>Material Type</th>
            <th>UOM</th>
            <th>Storage Location</th>
            <th>Total Quantity</th>
            <th>Total Value</th>
          </thead>
          <tbody>
            {this.props.data.length > 0 &&
              this.props.data.map((item) => {
                return (
                  <tr>
                    <td>{item.Material}</td>
                    <td>{item.BatchID}</td>
                    <td>{item.MaterialGroup}</td>
                    <td>{item.UOM}</td>
                    <td>{item.MaterialType}</td>
                    <td
                      className="cursor"
                      style={{ textDecoration: "underline" }}
                      // onClick={() => viewInfo(item)}
                    >
                      View Info
                    </td>
                    <td>-</td>
                    <td>{item.TotalQuantity}</td>

                    <td>{item?.totalValue}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}
