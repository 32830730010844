export const setRefreshToken = (refreshToken) => {
  return localStorage.setItem("refreshToken", refreshToken);
};
export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};
export const removeRefreshToken = () => {
  return localStorage.removeItem("refreshToken");
};
export const setAuthToken = (token) => {
  return localStorage.setItem("token", token);
};
export const setRole = (role) => {
  return localStorage.setItem("role", role);
};
export const firstTimeLoggin = (x) => {
  let value;
  if (x == 1) {
    value = true;
  } else {
    value = false;
  }
  return localStorage.setItem("firstTimeLogin", value);
};
export const getAuthToken = () => {
  return localStorage.getItem("token");
};

export const loginStatus = () => {
  if (localStorage.getItem("token")) {
    return true;
  } else {
    return false;
  }
};

export const removeAuthToken = () => {
  localStorage.removeItem("token");
};
export const removeLoginStatus = () => {
  localStorage.removeItem("firstTimeLogin");
};
export const removeLoginRole = () => {
  localStorage.removeItem("role");
};
export const removeSelectedFilters = () => {
  sessionStorage.setItem("SelectedFilters", []);
};
export const logoutFunction = () => {
  removeAuthToken();
  removeLoginStatus();
  removeLoginRole();
  removeSelectedFilters();
  removeRefreshToken();
  localStorage.removeItem("tokenEmail");

  window.location = "/login";
};
