import React from "react";
import { Divider, Typography, Table, Button } from "antd";
import {
  getIndividualOrderDetail,
  getOTPConfig,
} from "../../../action/IndividualOrderDetails";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import getINRString from "../../../utils/orders/getINRString";
import "./BasicDetail.scss";
const SubHeader = ({ title }) => (
  <div
    className="m-3"
    style={{
      display: "flex",
      flexDirection: "row",
      marginLeft: "33px",
      marginTop: "10px",
      alignItems: "center",
    }}
  >
    <span>
      <Divider
        type="vertical"
        style={{
          border: "2px solid #5078F2",
          height: "24px",
        }}
      />
    </span>
    <span>
      <Typography variant="h4" style={{ fontWeight: 600, fontSize: "18px" }}>
        {title}
      </Typography>
    </span>
  </div>
);
export const BasicDetails = () => {
  const { IndividualOrderDetails, ConfigData } = useSelector(
    (state) => state.individualOrderDetailsReducer
  );
  const paymentDetails = IndividualOrderDetails[0]?.paymentDetails;
  const calculateTotalAmount = () => {
    return IndividualOrderDetails[0]?.paymentDetails?.payableAmount
      ? IndividualOrderDetails[0]?.paymentDetails?.payableAmount
      : 0;
  };
  const calculateDeliveryCharges = () => {
    return IndividualOrderDetails[0]?.paymentDetails?.deliveryCharge
      ? IndividualOrderDetails[0]?.paymentDetails?.deliveryCharge
      : 0;
  };

  const ordereditems = IndividualOrderDetails[0]?.itemsSummary?.all.length;
  return (
    <div className="m-3 p-3" style={{ backgroundColor: "#FFFFFF" }}>
      <SubHeader title="Basic Details" />
      <div
        className="ml-4 m-3 p-3"
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span style={{ flexDirection: "column" }}>
            <Typography
              style={{
                color: "#7A7A7A",
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "17px",
                width: "90px",
              }}
            >
              So Number
            </Typography>
            <Typography
              style={{
                color: "#3A4960",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              {IndividualOrderDetails[0]?.salesOrderId || "-"}
            </Typography>
          </span>

          <Divider
            type="vertical"
            style={{
              height: 140,
              border: "1px solid #ECEDEE",
              marginLeft: "15px",
              marginRight: "20px",
            }}
          />
        </div>
        <div className="w-100">
          <div
            className="d-flex justify-content-between px-2 "
            style={{ gap: "15px" }}
          >
            <div>
              <Typography
                style={{
                  color: "#7A7A7A",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "15px",
                }}
              >
                Item Count
              </Typography>
              <Typography
                style={{
                  color: "#3A4960",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "17px",
                  marginTop: "8px",
                }}
              >
                {ordereditems}
              </Typography>
            </div>
            <div>
              <Typography
                style={{
                  color: "#7A7A7A",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "15px",
                }}
              >
                Order Date
              </Typography>
              <Typography
                style={{
                  color: "##3A4960",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "17px",
                  marginTop: "8px",
                }}
              >
                {moment(IndividualOrderDetails[0]?.orderDateDP)
                  ?.utcOffset(0, false)
                  .format("DD/MM/YYYY")}
              </Typography>
            </div>
            <div>
              <Typography
                style={{
                  color: "#7A7A7A",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "15px",
                }}
              >
                Delivery Date
              </Typography>
              <Typography
                style={{
                  color: "#3A4960",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "17px",
                  marginTop: "8px",
                }}
              >
                {IndividualOrderDetails[0]?.deliveryDate
                  ? moment(IndividualOrderDetails[0]?.deliveryDate)
                      ?.utcOffset(0, false)
                      .format("DD/MM/YYYY")
                  : "-"}
              </Typography>
            </div>
            <div>
              <Typography
                style={{
                  color: "#7A7A7A",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "15px",
                }}
              >
                Payment Mode
              </Typography>
              <Typography
                style={{
                  color: "#3A4960",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "17px",
                  marginTop: "8px",
                }}
              >
                {IndividualOrderDetails[0]?.SopaymentMode}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <span>
                <Divider type="vertical" className="basicdetdivider" />
              </span>
              <span style={{ marginLeft: "5px" }}>
                <Typography
                  style={{
                    color: "#7A7A7A",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "17px",
                  }}
                >
                  Delivery Charges
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    marginTop: "8px",
                  }}
                >
                  {getINRString(calculateDeliveryCharges())}
                </Typography>
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <span>
                <Divider type="vertical" className="basicdetdivider" />
              </span>

              <span style={{ marginLeft: "10px" }}>
                {" "}
                <Typography
                  style={{
                    color: "#7A7A7A",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "17px",
                  }}
                >
                  Total Amount
                </Typography>
                <Typography
                  style={{
                    color: "#27AE60",
                    fontSize: "20px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    marginTop: "8px",
                  }}
                >
                  {getINRString(calculateTotalAmount())}
                </Typography>
              </span>
            </div>
          </div>

          <div className="p-3 pt-4 px-2" style={{ marginLeft: "-11px" }}>
            <Typography
              style={{
                color: "#7A7A7A",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "17px",
              }}
            >
              Remarks
            </Typography>
            <Typography
              style={{
                color: "#3A4960",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "17px",
                marginTop: "8px",
              }}
            >
              {IndividualOrderDetails[0]?.orderRemark || "-"}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
