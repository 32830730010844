import { logoutFunction } from "./Auth";
import _forOwn from "lodash/forOwn";
import message from "antd/lib/message";
import { getRefreshToken,setAuthToken } from "./Auth";
import { refreshToken } from "../services/loginServices";
import history from "./History";

const refreshTokenAPI = async () => {
  await refreshToken()
      .then((res) => {
          const newToken = res && res.data.data.token;
          setAuthToken(newToken);
          history.go(0);
          // window.location.reload();
      })
      .catch((err) => {
          console.log(err);
          if (err && err.response && err.response.status === 403) {
              message.error(err.response.data.message);
              message.error("Token Expired ! Please Login again")
              setTimeout(() => logoutFunction(), 2000);
              // logoutFunction();
          }
      });
};
export const handleErrorResponse = (
  err,
  errorMessage = "Unable to process the request"
) => {
  if (typeof err.response === "undefined") {
    return { errorMessage, errors: [], responseCode: 500 };
  }

  //Check if it is JWT authentication error

  if (typeof err.response !== "undefined" && err.response.status === 401) {
    //   logoutFunction();
   // message.error("Token Expired ! Please Login again");
    refreshTokenAPI();
    //setTimeout(() => logoutFunction(), 2000);
   // return false;
  }

  let errors = [];

  //Catch all field errors for displaying it
  if (typeof err.response !== "undefined") {
    //Check if this reponse has for error messages
    if (err.response.data.errors) {
      _forOwn(err.response.data.errors, function (value, key) {
        if (typeof value === "string" && key != "") {
          errors.push(value);
        }
      });
    }

    //If error message was available
    if (typeof err.response.data.message !== "undefined") {
      errorMessage = err.response.data.message;
    }

    return { errorMessage, errors, responseCode: err.response.status };
  }

  return { errorMessage, errors: [], responseCode: 400 };
};
