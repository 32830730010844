import React, { useState, useEffect } from "react";
// import bell from "../../../assets/images/bell-icon.svg";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useLocation, useRouteMatch, useParams } from "react-router-dom";
import * as routes from "../../utils/allroutes";
import UserProfile from "../nav-profile/userProfile";

function PrimaryHeader({ title, backgroundColor,image }) {
  return (
    <div className="sticky-header" style={{ backgroundColor: backgroundColor }}>
      <p> <img src={image}/> {title}</p>
      <div>
        {/* <img className="mr-4" src={bell} alt="bell-icon" /> */}
        {/* <Avatar
            size="large"
            icon={<UserOutlined />}
            onClick={() => setShowUserModal(true)}
          /> */}
        <UserProfile />
      </div>
      {/* {showUserModal && (
          <UserDetails
            showUserInfoModal={showUserModal}
            userInfoModal={closeUserModal}
          />
        )} */}
    </div>
  );
}

export default PrimaryHeader;
