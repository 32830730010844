const types = {
  EDIT_OPEN: "EDIT_OPEN",
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
  SET_LAST_DELIVERY_DATE: "SET_LAST_DELIVERY_DATE",
  SET_EARLY_DELIVERY_DATE: "SET_EARLY_DELIVERY_DATE",
  EMPTY_ALL_FIELDS: "EMPTY_ALL_FIELDS",
  ON_CHANGE_VALUE: "ON_CHANGE_DATE_VALUE",
  ON_CHANGE_EXCLUDED_DAYS: "ON_CHANGE_EXCLUDED_DAYS",
  DELIVERY_DATE_CHECKED: "DELIVERY_DATE_CHECKED",
};

const initialState = {
  editOpen: false,
  earliestDelDate: null,
  lastDelDate: null,
  excludedDay: [],
  errorMessage: "",
  deliveryDateChecked: false,
};

const reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case types.EDIT_OPEN:
      return { ...state, editOpen: true };
    case types.SET_EARLY_DELIVERY_DATE:
      return { ...state, earliestDelDate: action.value, errorMessage: "" };
    case types.SET_LAST_DELIVERY_DATE:
      return { ...state, lastDelDate: action.value, errorMessage: "" };
    case types.EMPTY_ALL_FIELDS:
      return { ...initialState };
    case types.ON_CHANGE_EXCLUDED_DAYS:
      return { ...state, excludedDay: [...action.value] };
    case types.DELIVERY_DATE_CHECKED:
      return { ...state, deliveryDateChecked: action.value };
    case types.SET_ERROR_MESSAGE:
      return { ...state, errorMessage: action.value };
    default:
      return { ...state };
  }
};

export { reducer, initialState };
