import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";
import moment from "moment";

//const dateOfDelivery = localStorage.getItem("SelectedDeliveryDate");


export const getDeliveryDatesServices = async (CustomerId) => {
  let url = `api/v1/cart/getDeliverySlots`;
  let request = await axios.get(APIConfig.revamp_base_url + url, {
    headers: {
      authorization: getAuthToken(),
      portaltype: "distributor",
      customerid: CustomerId,
    },
  });
  return request;
};
export const getPaymentDetailsService = async (CustomerId) => {
  //console.log(moment(dateOfDelivery).format("YYYY-MM-DD"));
  let url = `api/v1/cart/getPaymentDetails`;
  let request = await axios.post(
    APIConfig.revamp_base_url + url,
    {
      dateOfDelivery: moment(localStorage.getItem("SelectedDeliveryDate")).format("YYYY-MM-DD")
    },
    {
      headers: {
        authorization: getAuthToken(),
        portaltype: "distributor",
        customerid: CustomerId,
      },
    }
  );
  return request;
  // return { data: [], status: 200 };
};
export const initiateOtp = async (CustomerId) => {
  let url = `api/v1/orders/sendSoOtp`;
  let request = await axios.get(APIConfig.revamp_base_url + url, {
    headers: {
      authorization: getAuthToken(),
      portaltype: "distributor",
      customerid: CustomerId,
    },
  });
  return request;
};
export const getOtpConfig = async (CustomerId) => {
  let url = `api/v1/orders/checkSoOtpStatus`;
  let request = await axios.get(APIConfig.revamp_base_url + url, {
    headers: {
      authorization: getAuthToken(),
      portaltype: "distributor",
      customerid: CustomerId,
    },
  });
  return request;
};

export const onCheckoutService = async (CustomerId, item) => {
  let url = `api/v1/orders/checkout`;
  let request = await axios.post(
    APIConfig.revamp_base_url + url,
    // {
    //   dateOfDelivery: item.dateOfDelivery,
    //   orderRemark: item.orderRemark,
    //   paymentMode: item.paymentMode,
    //   creditLimit: item.creditLimit,
    //   creditExposure: item.creditExposure,
    //   creditBalance: item.creditBalance,
    //   endTimeSlot: item.endTimeSlot,
    //   startTimeSlot: item.startTimeSlot,
    //   deliveryTimeSlot: item.displayLabel,
    // },
    item,
    {
      headers: {
        authorization: getAuthToken(),
        portaltype: "distributor",
        customerid: CustomerId,
      },
    }
  );
  return request;
  // return { data: [], status: 200 };
};

export const generateSOIDService = async (request) => {
  let url = `index.php/Hybrid_salesOrder_data`;
  let sorequest = await axios.post(
    APIConfig.generate_so_url + url,
    {
      request,
    },
    {
      headers: {
        "X-Authorization":
          "dybhOxOVxuDlrBCpOE0mC4s4aJd5WZqBmQ8P1NF3MmP17xmoqzu23Zm5TVPmdYrZ",
        // authorization: getAuthToken(),
        // portaltype: "distributor",
        // customerid: CustomerId,
      },
    }
  );
  return sorequest;
  // return { data: [], status: 200 };
};
// export const genereatesoOtp = async (CustomerId) => {
//   let url = `api/v1/orders/sendSoOtp`;
//   let request = await axios.get(
//     APIConfig.revamp_base_url + url,
//     {},
//     {
//       headers: {
//         authorization: getAuthToken(),
//         portaltype: "distributor",
//         customerid: CustomerId,
//       },
//     }
//   );
//   return request;
// };
export const genereatesoOtp = async (CustomerId, otp) => {
  let url = `api/v1/orders/verifySoOtp`;
  let request = await axios.post(
    APIConfig.revamp_base_url + url,
    { otp: otp },
    {
      headers: {
        authorization: getAuthToken(),
        // "X-Authorization":
        //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoie1wiX2lkXCI6XCI1Zjg5YzQ4ZmNmYWZiOWZmNDMyZGFjZDZcIixcImZpcnN0TmFtZVwiOlwiUmFqYSBBY2h1dGhhXCIsXCJsYXN0TmFtZVwiOlwiTmFpZHUgS290dGFuYVwiLFwiZ2VuZGVyXCI6XCJtYWxlXCIsXCJhZG1pblR5cGVcIjpcInN1cGVyQWRtaW5cIixcImRlc2lnbmF0aW9uXCI6XCJkaXN0cmlidXRpb25NYW5hZ2VyXCIsXCJyZWdpb25cIjpbXCJiYW5nYWxvcmVcIl0sXCJpc0F1dG9nZW5lcmF0ZWRQYXNzd29yZFwiOjAsXCJlbXBJZFwiOlwiV0ZQLzkwNS8yMFwiLFwicGhvbmVOdW1iZXJcIjoxMjM0LFwiZW1haWxcIjpcInJhamFuYWlkdUB3YXljb29sLmluXCIsXCJ3YXJlaG91c2VJZFwiOlwiNWY3YWE3OWMzNDQxN2E2NWI3ZWI2NmExXCIsXCJwbGFudFwiOjEwMDAsXCJjb21wYW55Q29kZVwiOjEwMDAsXCJjb21wYW55TmFtZVwiOlwiTi9BXCIsXCJkaXN0cmlidXRvcklkXCI6XCI1ZjdhYTc5YzM0NDE3YTY1YjdlYjY2YTFcIixcIm1vYmlsZVwiOlwiTkFcIn0iLCJpYXQiOjE2NzU4MzkwMjUsImV4cCI6MTY3NTg0MjYyNX0.XMXqpPsCT9uDcMgZA7TtTQ8ztWYKMA0a49lI1Tpx_-4",
        portaltype: "distributor",
        customerid: CustomerId,
      },
    }
  );
  return request;
  // return { data: [], status: 200 };
};
export const verifySoOtpTest = async (CustomerId, otp) => {
  let url = `api/v1/orders/verifySoOtp`;
  console.log(CustomerId);
  try {
    let request = await axios.post(
      APIConfig.revamp_base_url + url,
      {
        otp: otp,
      },
      {
        headers: {
          authorization: getAuthToken(),
          // "X-Authorization":
          //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoie1wiX2lkXCI6XCI1Zjg5YzQ4ZmNmYWZiOWZmNDMyZGFjZDZcIixcImZpcnN0TmFtZVwiOlwiUmFqYSBBY2h1dGhhXCIsXCJsYXN0TmFtZVwiOlwiTmFpZHUgS290dGFuYVwiLFwiZ2VuZGVyXCI6XCJtYWxlXCIsXCJhZG1pblR5cGVcIjpcInN1cGVyQWRtaW5cIixcImRlc2lnbmF0aW9uXCI6XCJkaXN0cmlidXRpb25NYW5hZ2VyXCIsXCJyZWdpb25cIjpbXCJiYW5nYWxvcmVcIl0sXCJpc0F1dG9nZW5lcmF0ZWRQYXNzd29yZFwiOjAsXCJlbXBJZFwiOlwiV0ZQLzkwNS8yMFwiLFwicGhvbmVOdW1iZXJcIjoxMjM0LFwiZW1haWxcIjpcInJhamFuYWlkdUB3YXljb29sLmluXCIsXCJ3YXJlaG91c2VJZFwiOlwiNWY3YWE3OWMzNDQxN2E2NWI3ZWI2NmExXCIsXCJwbGFudFwiOjEwMDAsXCJjb21wYW55Q29kZVwiOjEwMDAsXCJjb21wYW55TmFtZVwiOlwiTi9BXCIsXCJkaXN0cmlidXRvcklkXCI6XCI1ZjdhYTc5YzM0NDE3YTY1YjdlYjY2YTFcIixcIm1vYmlsZVwiOlwiTkFcIn0iLCJpYXQiOjE2NzU4MzkwMjUsImV4cCI6MTY3NTg0MjYyNX0.XMXqpPsCT9uDcMgZA7TtTQ8ztWYKMA0a49lI1Tpx_-4",
          portaltype: "distributor",
          customerid: CustomerId,
        },
      }
    );
    return request;
  } catch (error) {
    console.log(error);
  }

  // return { data: [], status: 200 };
};
