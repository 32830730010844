import generateActionTypes from "../utils/generateActionTypes";
export const actionTypes = generateActionTypes(
  "GET_ALL_DRAFT_BEATPLANS",
  "GET_SINGLE_DRAFT_BEAT_PLAN_BY_ID"
);
// get all draft beatplans data
export function getAllDraftBeatPlans(data) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.GET_ALL_DRAFT_BEATPLANS,
      data,
    });
  };
}
// get single draft beat plan by id
export function getSingleDraftBeatPlanById(data) {
  console.log("getSingleDraftBeatPlanById action-",data)
  return (dispatch) => {
    return dispatch({
      type: actionTypes.GET_SINGLE_DRAFT_BEAT_PLAN_BY_ID
      ,
      data,
    });
  };
}