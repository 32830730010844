import { LoadingOutlined } from "@ant-design/icons";
import { Avatar, Modal, Pagination, Spin } from "antd";
import message from "antd/lib/message";
import { isNumber, over } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import profilePic from "../../../src/images/default-pic.jpg";
import PrimaryHeader from "../../components/header/PrimaryHeader";
import { ArrayPagination, getParameterByName } from "../../helper/Common";
import arrowleft from "../../images/arrow-left.jpg";
import Cross from "../../images/cross(1).svg";
import Edit from "../../images/edit-3.svg";
import success from "../../images/lf20_s2lryxtd.json].svg";
import refresh from "../../images/refresh-cw.svg";
import tick from "../../images/tick.svg";
import Tick from "../../images/Vector.svg";
import {
  generateSOInvoice,
  getSoOrderDetail,
  printSO,
} from "../../services/salesOrder/salesOrderServices";
import { handleErrorResponse } from "../../utils/Request";
import EditableInput from "./innerComponent/EditableInput";
import EditableSelect from "./innerComponent/EditableSelect";
import ItemNameCard from "./innerComponent/ItemNameCard";
import "./salesOrder.scss";

const antIcon = <LoadingOutlined className="loadingIcon" spin />;
export default function SalesOrderDetail(props) {
  const [successModal, setsuccessModal] = useState(false);
  const [tab, setTab] = useState("pending");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [invoiceResponseData, setinvoiceResponseData] = useState([]);
  const [saleOrderData, setsalesOrderData] = useState({});
  const [itembreakdown, setitembreakdown] = useState([]);
  const [soid, setsoid] = useState("");

  const [isEditEnable, setIsEditEnable] = useState([]);
  const [invoiceIds, setInvoiceIds] = useState([]);
  const [packedQuantity, setPackedQuantity] = useState({});
  const [requiredQuantity, setRequiredQuantity] = useState({});
  const [itemDiscount, setItemDiscount] = useState({});
  const [itemSubAmount, setItemSubAmount] = useState({});
  const [remark, setRemark] = useState({});
  const [updatedArray, setUpdatedArray] = useState({});
  const [overallOrderAmountObj, setoverAllOrderAmountObj] = useState({});
  const [overallOrderAmount, setoverAllOrderAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [error, setError] = useState({});
  const [soDiscount, setSoDiscount] = useState(0);
  const [overallDiscount, setOverAllDiscount] = useState({});
  const [itemAmountAdd, setItemAmountAdd] = useState({});
  const [itemAmountMinus, setItemAmountMinus] = useState({});

  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const [skipPage, setSkipPage] = useState(0);

  const [overallItemData, setOverallItemData] = useState({});

  const navigateToBack = () => {
    props.history.push({
      pathname: "/dashboard/sales-order/sales-order-all",
      state: { tab: tab },
    });
  };

  const handleSetTab = async () => {
    setsoid(props.location.state && props.location.state.soid);
    let currentTab = await getParameterByName("tab");
    if (currentTab) {
      setTab(currentTab);
    }
    getDetail(currentTab);
  };

  useEffect(() => {
    handleSetTab();
  }, []);

  const getDetail = async (currentTab) => {
    setId(props.match.params.id);
    await getSoOrderDetail(props.match.params.id)
      .then((res) => {
        let invoiceIds = [];
        setsalesOrderData(res.data.data);
        setoverAllOrderAmount(res?.data?.data?.totalAmount);
        setTotalAmount(res?.data?.data?.totalAmount);
        if ((currentTab || tab) == "history") {
          setitembreakdown(
            res.data.data &&
              res.data.data.historyItems &&
              res.data.data.historyItems[0]
          );
        } else {
          setitembreakdown(res.data.data && res.data.data.items);
        }

        let invoiceDetails = res.data.data && res.data.data.invoiceDetail;
        if (invoiceDetails) {
          invoiceDetails.length > 0 &&
            invoiceDetails.forEach((detail) => {
              let id = detail?.invoiceDetails?.invoiceNo;
              let status = detail?.isDelivered;
              let date = detail?.so_deliveryDate;
              if (id) {
                let data = {
                  invoiveId: id,
                  status: status,
                  date: date && moment(date).format("DD/MM/YYYY"),
                };
                invoiceIds.push(data);
                setInvoiceIds(invoiceIds);
              }
            });
        }
        setInvoiceId(
          res.data.data &&
            res.data.data.customerDetail &&
            res.data.data.customerDetail.invoiceNo
        );
      })

      .catch((err) => {
        console.log(err, "errrr");
        setLoading(false);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  //Get Item Amount
  const getItemAmount = async (data) => {
    let objArray = await Object.values(data);
    const sum = await objArray.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);
    // setoverAllOrderAmount(sum);
  };

  //Invoice Genereate
  const invoiceDetail = async (invoiceId) => {
    setLoading(true);
    let inputs = {
      InvoiceId: [invoiceId],
    };

    await printSO(inputs)
      .then((res) => {
        setLoading(false);
        setinvoiceResponseData(res.data.data);
        props.history.push({
          pathname: `/dashboard/sales-order/sales-order-all-print/${invoiceId}`,
          state: { tab: tab, invoiceResponseData: res.data.data },
        });
      })

      .catch((err) => {
        console.log(err, "errrr");
        setLoading(false);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  //Handle Input Item change
  const handleInputChange = async (
    value,
    type,
    name,
    total,
    orderQuantity,
    itemAmount
  ) => {
    if (type == "remark") {
      setRemark({ ...remark, [name]: value });
      setOverallItemData({
        ...overallItemData,
        [name]: { ...overallItemData[name], remark: value },
      });
      setError({ remark: "" });
    }
    if (type == "packedQty") {
      if (value.includes(".")) {
        message.error("Quantity can't be in decimal");
      }
      value = Math.floor(value);
      if (value > orderQuantity) {
        setError({ packedQuantity: "Greater than Order Qty" });
      } else {
        setError({ packedQuantity: "" });
        let orderDiffAmount = (itemAmount / orderQuantity) * Number(value);
        setPackedQuantity({ ...packedQuantity, [name]: Number(value) });
        setOverallItemData({
          ...overallItemData,
          [name]: {
            ...overallItemData[name],
            packedQuantity: Number(value),
            requiredQuantity: orderQuantity - Number(value),
            subTotal:
              overallItemData[name] && overallItemData[name].discount
                ? orderDiffAmount - Number(overallItemData[name].discount)
                : orderDiffAmount,
          },
        });
        setItemSubAmount({ ...itemSubAmount, [name]: total * value });
        let itemAmountDiff = total * value;
        if (itemAmount < itemAmountDiff) {
          itemAmountDiff = itemAmountDiff - itemAmount;
          setItemAmountAdd({ [name]: itemAmountDiff });
        } else {
          itemAmountDiff = itemAmount - itemAmountDiff;
          setItemAmountMinus({ [name]: itemAmountDiff });
        }
        if (orderQuantity < value) {
          setOverallItemData({
            ...overallItemData,
            [name]: {
              ...overallItemData[name],
              requiredQuantity: orderQuantity - Number(value),
              subTotal:
                overallItemData[name] && overallItemData[name].discount
                  ? orderDiffAmount - Number(overallItemData[name].discount)
                  : orderDiffAmount,
            },
          });
          setRequiredQuantity({
            ...requiredQuantity,
            [name]: orderQuantity - value,
          });
        }
      }
    }
    if (type == "discount") {
      if (value.includes(".")) {
        message.error("Discount can't be in decimal");
      }
      value = Math.floor(value);
      if (value >= total) {
        setError({ discount: "Discount is too high" });
      } else {
        setError({ discount: "" });
        setItemDiscount({ ...itemDiscount, [name]: value });
        let sTotal =
          packedQuantity[name] < orderQuantity && itemSubAmount[name]
            ? itemSubAmount[name]
            : total;
        setItemSubAmount({ ...itemSubAmount, [name]: sTotal - value });
        setOverallItemData({
          ...overallItemData,
          [name]: {
            ...overallItemData[name],
            discount: Number(value),
          },
        });
      }
    }
  };

  //Item Array
  const itemsArr = ArrayPagination(itembreakdown, pageSize, page).map(
    (item) => {
      let isEnable = isEditEnable.includes(item.itemId);
      return (
        <tr>
          <td>
            <ItemNameCard
              imageUrl={item.imageUrl}
              itemName={item.itemName}
              itemId={item.itemId}
            />
          </td>
          {tab == "history" ? (
            <>
              <td>
                <div className="pt-4">{item.quantity}</div>
              </td>
              <td>
                <div className="pt-4">{item.rejectedQuantity}</div>
              </td>
              <td>
                <div className="pt-4">{item.comments || "-"}</div>
              </td>
            </>
          ) : (
            <>
              <td>
                <div className="pt-4">&#8377;{item.price}</div>
              </td>
              <td>
                <div className="pt-4">{item.quantity || item.qty}</div>
              </td>
              <td>
                <EditableInput
                  name={item.itemId + ".packedQty"}
                  error={error.packedQuantity}
                  className="pt-2"
                  size={70}
                  type="number"
                  open={isEnable}
                  defaultValue={
                    packedQuantity && packedQuantity[item.itemId]
                      ? packedQuantity[item.itemId]
                      : packedQuantity && packedQuantity[item.itemId] == 0
                      ? 0
                      : item.quantity || item.qty
                  }
                  onChange={(e) => {
                    let value;
                    if (e?.target?.value) {
                      value = e.target.value;
                    } else {
                      value = 0;
                    }
                    handleInputChange(
                      value,
                      "packedQty",
                      item.itemId,
                      item.price,
                      item.quantity || item.qty,
                      item.total_amount ?? item.totalAmount
                    );
                  }}
                />
              </td>
              <td>
                <EditableSelect
                  name={item.itemId + ".remark"}
                  className="pt-2"
                  open={isEnable}
                  error={error?.remark}
                  defaultValue={
                    remark && remark[item.itemId] ? remark[item.itemId] : "-"
                  }
                  onChange={(e) => {
                    handleInputChange(e, "remark", item.itemId);
                  }}
                />
              </td>
              <td>
                <EditableInput
                  name={item.itemId + ".discount"}
                  className="pt-2"
                  size={70}
                  type="number"
                  open={isEnable}
                  error={error.discount}
                  defaultValue={
                    itemDiscount && itemDiscount[item.itemId]
                      ? itemDiscount[item.itemId]
                      : 0
                  }
                  onChange={(e) => {
                    let value;
                    if (e?.target?.value) {
                      value = e.target.value;
                    } else {
                      value = 0;
                    }
                    handleDiscountChange(item.itemId, value);
                    handleInputChange(
                      value,
                      "discount",
                      item.itemId,
                      item.total_amount || item.totalAmount,
                      item.quantity || item.qty
                    );
                  }}
                />
              </td>
              <td>
                <div className="pt-4">
                  {overallItemData &&
                  overallItemData[item.itemId] &&
                  overallItemData[item.itemId].subTotal
                    ? overallItemData[item.itemId].subTotal
                    : overallItemData[item.itemId] &&
                      overallItemData[item.itemId].subTotal == 0
                    ? 0
                    : item.total_amount ?? item.totalAmount}
                </div>
              </td>
              <td>
                {isEnable == false ? (
                  <div className="pt-3">
                    <img
                      src={Edit}
                      alt=""
                      className="ml-2 "
                      onClick={() => inputActivate(item)}
                    />
                  </div>
                ) : (
                  <div className="d-flex pt-2">
                    <div className="mt-2 pt-1 ml-2">
                      <button
                        disabled={
                          error.discount || error.packedQuantity || error.remark
                        }
                        className="button-transparent"
                        onClick={() => inputSave(item)}
                      >
                        <img src={Tick} alt="" />
                      </button>
                    </div>
                    <div className="ml-2 mt-1 edit_border">
                      <img
                        src={Cross}
                        alt=""
                        onClick={() => inputDeActivate(item)}
                      />
                    </div>
                  </div>
                )}
              </td>
            </>
          )}
        </tr>
      );
    }
  );

  //Handle Item Edit
  const inputActivate = (item) => {
    let value = [...isEditEnable];
    value.push(item.itemId);
    value = [...new Set(value)];
    setIsEditEnable(value);
  };

  //Handle Item Edit Close
  const inputDeActivate = (item) => {
    setLoading(true);
    let item_id = item?.itemId;
    const itemId = isEditEnable.indexOf(item.itemId);
    if (itemId > -1) {
      isEditEnable.splice(itemId, 1);
    }
    setError({});
    setIsEditEnable(isEditEnable);
    setOverAllDiscount({ [item_id]: "" });
    setItemDiscount({ [item_id]: "" });
    setPackedQuantity({ [item_id]: "" });
    setRemark({ [item_id]: "" });
    setItemSubAmount({ [item_id]: "" });
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  //Handle Item Save
  const inputSave = (item) => {
    let itemId = item.itemId;
    let updateArray = [];
    let formedObj = {};
    if (overallItemData && overallItemData[itemId]) {
      console.log(
        "overallItemData[itemId].packedQuantity",
        overallItemData[itemId].packedQuantity
      );
      let formedAmount =
        overallItemData[itemId] && overallItemData[itemId].packedQuantity
          ? ((item.totalAmount || item.total_amount) /
              Number(item.totalQuantity || item.qty)) *
            Number(overallItemData[itemId].packedQuantity)
          : overallItemData[itemId].packedQuantity == 0
          ? 0
          : item.totalAmount || item.total_amount;

      formedObj = {
        itemId: item.itemId,
        packedQuantity:
          overallItemData[itemId] && overallItemData[itemId].packedQuantity
            ? Number(overallItemData[itemId].packedQuantity)
            : overallItemData[itemId].packedQuantity == 0
            ? 0
            : Number(item.totalQuantity || item.qty),
        requiredQuantity:
          overallItemData[itemId] && overallItemData[itemId].packedQuantity
            ? Number(item.totalQuantity || item.qty) -
              Number(overallItemData[itemId].packedQuantity)
            : overallItemData[itemId].packedQuantity == 0
            ? Number(item.totalQuantity || item.qty)
            : 0,
        discount:
          overallItemData[itemId] && overallItemData[itemId].discount
            ? Number(overallItemData[itemId].discount)
            : 0,
        remark:
          (overallItemData[itemId] && overallItemData[itemId].remark) || "-",
        subTotal:
          overallItemData[itemId] && overallItemData[itemId].discount
            ? formedAmount - Number(overallItemData[itemId].discount)
            : formedAmount,
      };
      setOverallItemData({ ...overallItemData, [itemId]: formedObj });
    }

    let total = 0;
    itembreakdown &&
      itembreakdown.length > 0 &&
      itembreakdown.forEach((item) => {
        if (formedObj && formedObj.itemId == item.itemId) {
          total = total + formedObj?.subTotal;
        } else if (
          overallItemData &&
          overallItemData.hasOwnProperty(item.itemId)
        ) {
          total = total + overallItemData[item.itemId].subTotal;
        } else {
          total = total + (item.total_amount || item.totalAmount);
        }
      });
    setoverAllOrderAmount(total);
    let data = {
      _id: item._id,
      remark: (overallItemData[itemId] && overallItemData[itemId].remark) || "",
      discount:
        overallItemData[itemId] && overallItemData[itemId].discount
          ? Number(overallItemData[itemId].discount)
          : 0,
      suppliedQuantity:
        overallItemData[itemId] && overallItemData[itemId].packedQuantity
          ? Number(overallItemData[itemId].packedQuantity)
          : overallItemData[itemId].packedQuantity == 0
          ? 0
          : item.totalQuantity || item.qty,
      pickedQuantity: item.totalQuantity || item.qty,
      requireQuantity:
        overallItemData[itemId] && overallItemData[itemId].requiredQuantity
          ? Number(overallItemData[itemId].requiredQuantity)
          : overallItemData[itemId].packedQuantity == 0
          ? Number(overallItemData[itemId].requiredQuantity)
          : 0,
      totalAmount:
        overallItemData[itemId] && overallItemData[itemId].packedQuantity
          ? Number(overallItemData[itemId].packedQuantity) * item.mrp_amount
          : overallItemData[itemId].packedQuantity == 0
          ? 0
          : item.totalAmount || item.total_amount,
      totalQuantity: item.totalQuantity || item.qty,
    };
    updateArray.push(data);
    setUpdatedArray({ ...updatedArray, [item._id]: updateArray });
    getItemAmount(overallOrderAmountObj);
    const item_id = isEditEnable.indexOf(item.itemId);
    if (item_id > -1) {
      isEditEnable.splice(item_id, 1);
    }
    setIsEditEnable(isEditEnable);
  };

  const formItemArray = async (itembreakdown, updatedValues, cb) => {
    let itemUpdated = [];
    (await itembreakdown) &&
      itembreakdown.length >= 0 &&
      itembreakdown.forEach((iItem) => {
        updatedValues &&
          updatedValues.length > 0 &&
          updatedValues.forEach((uItems) => {
            let uItem = uItems[0];
            if (iItem._id == uItem._id) {
              itemUpdated.push(uItem);
            }
          });
      });
    return cb(itemUpdated);
  };

  const formedItemArray = async (result, cb) => {
    let totalItem = itembreakdown;
    result.forEach((resitem) => {
      totalItem.splice(
        totalItem.findIndex((a) => a._id == resitem._id),
        1
      );
    });
    let totalData = [];
    totalItem &&
      totalItem.length >= 0 &&
      totalItem.forEach((iItem) => {
        let data = {
          _id: iItem._id,
          remark: "",
          discount: 0,
          suppliedQuantity: iItem.totalQuantity || iItem.qty,
          pickedQuantity: iItem.totalQuantity || iItem.qty,
          requireQuantity: 0,
          totalAmount: iItem.total_amount || iItem.totalAmount || 0,
          totalQuantity: iItem.totalQuantity || iItem.qty,
        };
        totalData.push(data);
      });

    return cb(totalData);
  };

  //Generate Invoice API
  const generateInvoiceRequest = async () => {
    let itemArray = [];

    if (updatedArray && !updatedArray.length > 0) {
      itembreakdown &&
        itembreakdown.length > 0 &&
        itembreakdown.forEach((item) => {
          let data = {
            _id: item._id,
            remark: "",
            discount: 0,
            suppliedQuantity: item.totalQuantity || item.qty,
            pickedQuantity: item.totalQuantity || item.qty,
            requireQuantity: 0,
            totalAmount: item.total_amount || item.totalAmount || 0,
            totalQuantity: item.totalQuantity || item.qty,
          };
          itemArray.push(data);
        });
    }
    let updatedValues;
    if (updatedArray) {
      updatedValues = Object.values(updatedArray);
    }
    let itemUpdated = [];
    if (updatedValues && updatedValues.length >= 0) {
      await formItemArray(itembreakdown, updatedValues, async (result) => {
        await formedItemArray(result, (result1) => {
          itemUpdated.push(...result);
          itemUpdated.push(...result1);
        });
      });
    }
    let inputs = {
      updatedItemDetails:
        updatedValues && updatedValues[0] && updatedValues[0].length > 0
          ? itemUpdated
          : itemArray,
      soDiscount: soDiscount ?? 0,
      totalOrderAmount:
        overallOrderAmount || (saleOrderData && saleOrderData.totalAmount),
    };
    setLoading(true);
    await generateSOInvoice(soid, inputs)
      .then((res) => {
        setLoading(false);
        if (res?.data?.data?.dataOutput?.response?.invoice_no) {
          setsuccessModal(true);
          setTimeout(() => {
            props.history.push({
              pathname: "/dashboard/sales-order/sales-order-all",
              state: { tab: "invoiced" },
            });
            setsuccessModal(false);
          }, 2000);
        } else {
          let response = res?.data?.data?.dataOutput?.response;
          message.warning(response);
        }
      })
      .catch((err) => {
        console.log(err, "errrr");
        setLoading(false);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  //Pagination
  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const handleDiscountChange = async (name, value) => {
    setOverAllDiscount({ ...overallDiscount, [name]: value });
  };

  return (
    <div id="content" className="">
      <PrimaryHeader title={`SO # ${saleOrderData.so_no}`} />
      <div onClick={navigateToBack}>
        <img src={arrowleft} />
        <span className="ml-2 cursor" style={{ color: "#5078F2" }}>
          Back
        </span>
      </div>
      <Spin indicator={antIcon} spinning={loading}>
        <div
          className="card ml-2 mt-4"
          style={{ width: "100%", backgroundColor: "#FAFAFA" }}
        >
          <div class="card-body">
            <p className="border_left subheader mt-2">Customer Details</p>
            <div className="row">
              <div className="d-flex align-items-center col-lg-4 mt-2">
                <div className="pr-4">
                  <Avatar size={60} src={profilePic} />
                </div>
                <div className="pt-2">
                  <p className="subheader">
                    {saleOrderData?.customerDetail?.customerName}
                  </p>
                  <p className="subHeaderName ">
                    Customer ID : {saleOrderData?.customerDetail?.customerId}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 mt-4 ml-1 border_img">
                <p className="headerName">Plant</p>
                <p className="subHeaderName">{saleOrderData?.plant}</p>
              </div>

              <div className="col-lg-3 mt-4 ml-1 ">
                <p className="headerName">Location</p>
                <p className="subHeaderName">
                  {saleOrderData?.customerDetail?.shippingAddress1 +
                    "," +
                    saleOrderData?.customerDetail?.shippingAddress2 +
                    "," +
                    saleOrderData?.customerDetail?.shippingPlace}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="card ml-2 mt-4"
          style={{ width: "100%", backgroundColor: "#FAFAFA" }}
        >
          <div class="card-body">
            <p className="border_left subheader mt-2">Basic Details</p>
            <div className="d-flex mt-4 justify-content-between align-items-center">
              <div className=" ">
                <p className="headerName">SO Number</p>
                <p className="subTitle">{saleOrderData.so_no}</p>
              </div>

              <div className=" border_basic">
                <p className="headerName">Item Count</p>
                <p className="subHeaderName">{saleOrderData?.itemsCount}</p>
              </div>

              <div>
                <p className="headerName">Order Date</p>
                <p className="subHeaderName">{saleOrderData?.order_date}</p>
              </div>

              <div>
                <p className="headerName">Delivery Date</p>
                <p className="subHeaderName">{saleOrderData?.delivery_date}</p>
              </div>

              <div>
                <p className="headerName">Payment</p>
                <p className="subHeaderName">{saleOrderData?.order_status}</p>
              </div>
              <div>
                <p className="headerName">Total Amount</p>
                <p className="totalAmt ">
                  &#8377;{" "}
                  {overallOrderAmount && isNumber(overallOrderAmount)
                    ? overallOrderAmount
                    : saleOrderData?.totalAmount}
                </p>
              </div>
            </div>
          </div>
        </div>

        {tab == "history" && (
          <div
            className="card ml-2 mt-4"
            style={{ width: "100%", backgroundColor: "#FAFAFA" }}
          >
            <div class="card-body">
              <p className="border_left subheader mt-2">Invoice Details</p>
            </div>
            <div className="row">
              {invoiceIds &&
                invoiceIds.length > 0 &&
                invoiceIds.map((invDetails, i) => (
                  <div className="col-lg-2 mt-2 ml-4">
                    <p className="headerName">Invoice {i + 1}</p>
                    <p className="subTitle">{invDetails?.invoiveId}</p>
                    <p
                      className="viewInvoice cursor"
                      onClick={() => {
                        invoiceDetail(invDetails?.invoiveId);
                      }}
                    >
                      View Invoice
                    </p>
                    {invDetails?.status == 1 && (
                      <>
                        <img src={tick} className="float-left cursor" />
                        <p className="pl-3 mt-n1">
                          Delivered {invDetails?.date}
                        </p>
                      </>
                    )}
                    {invDetails?.status == 2 && (
                      <>
                        <img src={refresh} className="float-left cursor" />
                        <p className="pl-3 mt-n1">
                          Sales Return {invDetails?.date}
                        </p>
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}

        <div
          className="card ml-2 mt-4"
          style={{ width: "100%", backgroundColor: "#FAFAFA" }}
        >
          <div class="card-body">
            <p className="border_left subheader mt-2">
              {tab == "hisory" ? "Sales Return Breakdown" : "Item Breakdown"}
            </p>

            <div
              className="mt-4  table-wrapper table-responsive"
              id="print_table"
            >
              <table className="table common-table">
                <thead className="remove-border-top">
                  <th>Item Details</th>
                  {tab == "history" ? (
                    <>
                      <th scope="col">Invoiced Quantity</th>
                      <th>Return Quantity</th>
                      <th>Remark</th>
                    </>
                  ) : (
                    <>
                      <th scope="col">Unit Price</th>
                      <th>Order Qty</th>
                      <th>Packed Qty</th>
                      <th>Remarks</th>
                      <th>Discount Amount</th>
                      <th>Sub Total</th>
                      <th></th>
                    </>
                  )}
                </thead>
                <tbody>
                  {itemsArr && itemsArr}
                  {itemsArr.length === 0 && (
                    <div className="no-data">
                      <p>No Data Available</p>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
            {itembreakdown.length > 0 && (
              <div className="row mt-3 mb-3">
                <div className="col-lg-4">
                  Showing {skipPage + 1} to{" "}
                  {skipPage + 5 > itembreakdown.length
                    ? itembreakdown.length
                    : skipPage + 5}{" "}
                  of {itembreakdown.length} entries
                </div>
                <div className="offset-lg-4 col-lg-4">
                  {pageSize != 0 && (
                    <Pagination
                      defaultCurrent={1}
                      current={page}
                      total={itembreakdown.length}
                      defaultPageSize={pageSize}
                      showSizeChanger={false}
                      onChange={(page, pageSize) =>
                        handlePageChange(page, pageSize)
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {tab == "pending" && (
          <div className="d-flex justify-content-start mt-4">
            <button
              className="btn btn-primary "
              onClick={generateInvoiceRequest}
              disabled={error.discount || error.packedQuantity || error.remark}
            >
              Generate Invoice
            </button>
          </div>
        )}
      </Spin>
      {successModal && (
        <>
          <Modal
            width={300}
            visible={successModal}
            onCancel={() => {
              setsuccessModal(!successModal);
            }}
            closable
            maskClosable={false}
            footer={null}
            className="invoiceModal"
          >
            <div className="d-flex align-items-center flex-column ">
              <div className="">
                <img src={success} className="modalImg mt-1" />
              </div>
              <br />
              <div>
                <p className="modalmsg ml-2 mt-3">
                  Invoice has been generated successfully!
                </p>
                <p className="subtext mt-n2 ml-3">
                  Redirecting you to Sales Order listing
                </p>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}
