import React, { Component } from "react";
import SearchBar from "../search-bar/searchBar";
import { LoadingOutlined } from "@ant-design/icons";
import { AsyncPaginate } from "react-select-async-paginate";
import PrimaryHeader from "../header/PrimaryHeader";
import "./sapReport.scss";
import moment from "moment";
import { saveAs } from "file-saver";
import { DatePicker, Space } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { Tabs } from "antd";
import download from "../../images/download-sap-report.svg";
import { Spin } from "antd";
import { handleErrorResponse } from "../../utils/Request";
import { sapReportDownload } from "../../services/sapReportServices/sapReport";
import message from "antd/lib/message";
import SapItemModal from "./sapItemModel";
import { Pagination } from "antd";
import {
  invoiceTableHeaders,
  soTableHeaders,
  purchaseOrderHeaders,
  grnHeaders,
  invoiceModelHeaders,
  soModelHeaders,
} from "./constants";
const { TabPane } = Tabs;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const SapTable = ({ loading, tHead, tBody, model, type }) => {
  console.log("tBody", tBody);
  return (
    <div className="table-wrapper">
      <Spin indicator={antIcon} spinning={loading}>
        <table className="table common-table">
          <thead>
            <tr>
              {tHead.map((trow, index) => {
                return <th key={`tr-${index}`}>{trow}</th>;
              })}
            </tr>
          </thead>

          {

            type === "invoice_data" && <tbody>
              <tr>
                {tHead.map((x) => {
                  return (
                    <td onClick={model} className="cursor">
                      {x == "Item Count" && 6}

                    </td>
                  );
                })}
              </tr>
            </tbody>
          }
          {

            type === "sales_data" && <tbody>
              <tr>
                {tHead.map((x) => {
                  return (
                    <td onClick={model} className="cursor">
                      {x == "Item Count" && 6}

                    </td>
                  );
                })}
              </tr>
            </tbody>
          }

          {

            type === "purchase_data" && <tbody>
              <tr>
                {tHead.map((x) => {
                  return (
                    <td>
                      Purchase

                    </td>
                  );
                })}
              </tr>
            </tbody>
          }

          {

            type === "grn_data" && <tbody>
              <tr>
                {tHead.map((x) => {
                  return (
                    <td>
                      Grn

                    </td>
                  );
                })}
              </tr>
            </tbody>
          }
        </table>
      </Spin>
    </div>
  );
};

class SapReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiCalling: false,
      tabType: "invoice_data",
      loading: false,
      tHead: invoiceTableHeaders,
      tBody: [],
      fromDate: moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
      toDate: moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
      showSapModal: false,
      page: 1,
      pageSize: 0,

    };
  }

  openSapModel = () => {
    this.setState({
      showSapModal: !this.state.sapItemModal,
    });
  };

  closeSapModel = () => {
    this.setState({
      showSapModal: false,
    });
  };

  // tabChange
  changeTab = (e) => {
    console.log("change tab is called", e);
    this.setState(
      {
        apiCalling: true,
        tabType: e,
      },
      () => {
        this.getTableData();
      }
    );
  };

  getTableData = async () => {
    /* after calling api when you will be getting res make apiCalling state false and if make it false if 
    you get error
    */
    console.log("getTableData is called", this.state.tabType);
    let { fromDate, toDate, tabType } = this.state;
    let _fromDate = moment(fromDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let _toDate = moment(toDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    if (tabType === "invoice_data") {
      console.log("show invoice list");
      this.setState({
        tHead: invoiceTableHeaders
      })
      // call api
      let _type = tabType;
      // call api
      // await servicename.then((res)=>{
      //   this.setState({
      //     apiCalling: false,
      //     tBody: res.data...,
      //     tHead:invoiceTableHeaders

      //   })
      // }).catch((err)=>{
      //   // call err handler 
      //   this.setState({
      //     apiCalling: false,
      //     tHead:invoiceTableHeaders
      //   })
      // })
    } else if (tabType === "sales_data") {
      console.log("show so list");
      this.setState({
        tHead: soTableHeaders
      })
      let _type = tabType;
      // call api
    } else if (tabType === "purchase_data") {
      console.log("show purchase_data list");
      let _type = tabType;
      this.setState({
        tHead: purchaseOrderHeaders
      })
      // call api
    } else if (tabType === "grn_data") {
      console.log("show grn_data list");
      this.setState({
        tHead: grnHeaders
      })
      let _type = tabType;
      // call api
    }
  };

  selectDateRange = (dates) => {
    this.setState(
      {
        fromDate: moment(dates[0], "YYYY/MM/DD").format("YYYY/MM/DD"),
        toDate: moment(dates[1], "YYYY/MM/DD").format("YYYY/MM/DD"),
      },
      () => {
        this.getTableData();
      }
    );
  };
  handleDownload = async () => {
    console.log(
      "handleDownload is called for tab",
      this.state.tabType,
      this.state.fromDate,
      this.state.toDate
    );
    let { tabType, fromDate, toDate } = this.state;
    let from = fromDate;
    let to = toDate;
    let type = tabType;
    await sapReportDownload(type, from, to)
      .then((res) => {
        console.log("Report is downloaded successfully", res);
        let blob = new Blob([res.data], { type: "text/csv" });
        saveAs(blob, "form.csv");
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        // if (err.response.status === 404) {
        //   message.error(err.response.data.message);
        // } else if (err.response.status === 401) {
        //   message.error("Session expired. Please login and try again!");
        // }
        this.setState({
          loading: false,
        });
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };

  handleChange = (page, pageSize) => {

    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.getTableData();
      }
    );
  };

  componentDidMount() {
    console.log("cdm");
    this.getTableData();
  }
  render() {
    let { apiCalling, loading, tHead, tBody, tabType } = this.state;
    const { RangePicker } = DatePicker;
    console.log("tab" + this.state.tabType)
    return (
      <div id="content">
        <PrimaryHeader title="SAP Report" />
        <div className="sap-report-subheader row ">

          <Space direction="vertical">
            <RangePicker
              className="ant-picker"
              onChange={this.selectDateRange}
            />
          </Space>
          <div className="col-xl-6 col-lg-6 col-md-4 col-sm-4">
            <AsyncPaginate
              placeholder={"Select Warehouse Name"}
              isSearchable={true}
              additional={{
                page: 1,
              }}
              className="col col-xl-7 col-lg-7 col-md-12 col-sm-4 ml-4 pt-1"
            />
          </div>


          <div className="col col-xl-3 col-lg-3 col-md-1 col-sm-3 ml-5">
            <button
              className="downloadReport"
              onClick={this.handleDownload}
            >
              {" "}
              Download csv{" "}
              <img src={download} alt="download" className="ml-1" />
            </button>
          </div>
        </div>

        <div className="mt-3">
          <Tabs
            onChange={(e) => {
              // apiCalling == false && this.changeTab(e);
              this.changeTab(e);
            }}
          >
            <TabPane
              tab="Invoice"
              key="invoice_data"
              className="tabpane"
            // disabled={apiCalling == true ? true : false}
            >
              <SapTable
                loading={loading}
                tHead={tHead}
                tBody={tBody}
                model={this.openSapModel}
                type={tabType}
              />
              <div className="row pagination-width">
                <div className="col-md-12">
                  <div className="justify-content-lg-center justify-content-md-center d-flex mt-4  mb-1">
                    <Pagination
                      defaultCurrent={1}
                      total={50}
                      onChange={(page, pageSize) =>
                        this.handleChange(page, pageSize)
                      }
                    />
                  </div>
                </div>
              </div>
            </TabPane>

            <TabPane
              tab="Sales Order"
              key="sales_data"
            // disabled={apiCalling == true ? true : false}
            // className={
            //   apiCalling == true ? "tabpane pointerEvent" : "tabpane"
            // }
            >
              <SapTable
                loading={loading}
                tHead={tHead}
                tBody={tBody}
                model={this.openSapModel}
                type={tabType}
              />
              <div className="row pagination-width">
                <div className="col-md-12">
                  <div className="justify-content-lg-center justify-content-md-center d-flex mt-4  mb-1">
                    <Pagination
                      defaultCurrent={1}
                      total={50}
                      onChange={(page, pageSize) =>
                        this.handleChange(page, pageSize)
                      }
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane
              tab="Purchase Order"
              key="purchase_data"
            // disabled={apiCalling == true ? true : false}
            // className={
            //   apiCalling == true ? "tabpane pointerEvent" : "tabpane"
            // }
            >
              <SapTable
                loading={loading}
                tHead={tHead}
                tBody={tBody}
                model={this.openSapModel}
                type={tabType}
              />
              <div className="row pagination-width">
                <div className="col-md-12">
                  <div className="justify-content-lg-center justify-content-md-center d-flex mt-4  mb-1">
                    <Pagination
                      defaultCurrent={1}
                      total={50}
                      onChange={(page, pageSize) =>
                        this.handleChange(page, pageSize)
                      }
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane
              tab="GRN"
              key="grn_data"
            // disabled={apiCalling == true ? true : false}
            // className={
            //   apiCalling == true ? "tabpane pointerEvent" : "tabpane"
            // }
            >
              <SapTable
                loading={loading}
                tHead={tHead}
                tBody={tBody}
                model={this.openSapModel}
                type={tabType}
              />
              <div className="row pagination-width">
                <div className="col-md-12">
                  <div className="justify-content-lg-center justify-content-md-center d-flex mt-4  mb-1">
                    <Pagination
                      defaultCurrent={1}
                      total={50}
                      onChange={(page, pageSize) =>
                        this.handleChange(page, pageSize)
                      }
                    />
                  </div>
                </div>
              </div>
            </TabPane>

          </Tabs>
        </div>

        {this.state.showSapModal && (
          <SapItemModal
            displayModal={this.closeSapModel}
            tableHeaders={
              this.state.tabType === "invoice"
                ? invoiceModelHeaders
                : soModelHeaders
            }
          />
        )}
      </div>
    );
  }
}
export default SapReport;
