import generateActionTypes from "../utils/generateActionTypes";
import {
  getSearchCustomerList,
  verifyIfCustomerIsWaycredCustomer,
} from "../services/orderServices/orderService";
import { handleErrorResponse } from "../utils/Request";
import { message } from "antd";

export const actionTypes = generateActionTypes(
  "GET_SEARCH_CUSTOMER_ORDERS_LIST",
  "LOADING",
  "STOP_LOADING",
  "SAVE_SEARCHED_VALUE",
  "SAVE_SELECTED_CUSTOMER"
);

// export function startLoading(loadType) {
//   return (dispatch) => {
//     return dispatch({
//       type: actionTypes.LOADING,
//       load: loadType,
//     });
//   };
// }
export function stopLoading(loadType) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.STOP_LOADING,
      load: loadType,
    });
  };
}
export function saveSearchedValue(val) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SAVE_SEARCHED_VALUE,
      data: val,
    });
  };
}

export function saveSelectedCustomer(val) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SAVE_SELECTED_CUSTOMER,
      data: val,
    });
  };
}

export function removeSearchedCustomer() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_SEARCH_CUSTOMER_ORDERS_LIST,
      data: { list: [], searchTerm: "" },
    });
  };
}

export function checkIfCustomerIsWaycred(customerID) {
  return async (dispatch) => {
    try {
      const res = await verifyIfCustomerIsWaycredCustomer(customerID);
    } catch (err) {
      const { errorMessage, errors } = handleErrorResponse(err);
      console.log(err, "error");
    }
  };
}

// export const getCustome

export const getSearchCustomerOrderList = (searchTerm) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.GET_SEARCH_CUSTOMER_ORDERS_LIST,
    });
    try {
      const res = await getSearchCustomerList(searchTerm);
      const resData = res?.data;
      if (resData.status === 200) {
        const { result } = resData;
        dispatch({
          type: actionTypes.GET_SEARCH_CUSTOMER_ORDERS_LIST,
          data: { list: result, searchTerm },
        });
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
    }
  };
};
