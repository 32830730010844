import moment from 'moment';

// used for google maps calculations
export const secondsToTime = seconds => {
	let d = moment.duration(seconds, 'seconds');
	let str = '';
	if (d._data.days) {
		d._data.hours += d._data.days * 24;
	}
	if (d._data.hours) {
		str += `${d._data.hours} ${d._data.hours < 2 ? 'hour' : 'hours'}`;
	}
	str += ` ${d._data.minutes} ${d._data.minutes < 2 ? 'minute' : 'minutes'}`;

	return str;
};
