import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";

export const getAllCustomerDetails = async (qParams, body) => {
  console.log("body", body);
  let qUrl = getQparams(qParams);
  let request = await axios.post(
    APIConfig.base_url + "api/v1/customer" + qUrl,
    body,
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};
export const getDataFromGoFrugal = async () => {
  let request = await axios.get(
    APIConfig.base_url + "api/v1/customer/goFrugal/sync",
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
export const getDataFromTally = async () => {
  let request = await axios.get(
    APIConfig.base_url + "api/v1/customer/tally/accounts/sync",
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// get minified customer list for addCustomerModal.js
export const getMinifiedCustomerList = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "api/v1/customer/minified/list" + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getAllCustomerLedgerDetails = async (id) => {
  let request = await axios.get(APIConfig.base_url + `api/v1/customer/${id}`, {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};

//get invoice data in customer details page

export const getAllInvoiceData = async (id, qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/customer/${id}/invoice` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get payment data in customer details page

export const getAllPaymentData = async (id, qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/customer/${id}/payment` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get credit data in customer details page

export const getAllCreditData = async (id, qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/customer/${id}/credit` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get debit data in customer details page
export const getAllDebitData = async (id, qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/customer/${id}/debit` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get invoice sync  data in customer details page
export const getInvoiceDataSync = async (location) => {
  let request = await axios.get(
    APIConfig.base_url + `v1/customer/tally/sync/invoice/city/${location}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get payment sync  data in customer details page
export const getPaymentDataSync = async (location) => {
  let request = await axios.get(
    APIConfig.base_url + `v1/customer/tally/sync/payment/city/${location}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get credit note sync  data in customer details page
export const getCreditDataSync = async (location) => {
  let request = await axios.get(
    APIConfig.base_url + `v1/customer/tally/sync/credit/city/${location}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get debit sync  data in customer details page
export const getDebitDataSync = async (location) => {
  let request = await axios.get(
    APIConfig.base_url + `v1/customer/tally/sync/debit/city/${location}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// get salesman list for customer filter
export const getSalesmenList = async () => {
  let request = await axios.get(
    APIConfig.base_url + "api/v1/employee/salesman/filter/list",
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// get customer grp, subgrp list for customer filter
export const getGrpANdSubGrpForFilter = async () => {
  let request = await axios.get(
    APIConfig.base_url + "api/v1/customer/list/filters/groups",
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//download ledger
export const downloadLedger = async (id) => {
  let request = await axios.get(
    APIConfig.base_url + `api/v1/customer/${id}/ledger/download`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
      responseType: "arraybuffer",
    }
  );
  return request;
};

// balance legder details list api
export const getAllLedgerDetails = async (customerid, qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig. revamp_base_url + `api/v1/balance/ledgerDetails`+ qUrl ,
    {
      headers: {
        Authorization: getAuthToken(),
        portaltype:"distributor",
        customerid:customerid
        //customerid:"1026470"
      },
    }
  );
  return request;
};

// ledger details download
export const downloadBalanceLedgerDetails = async (customerid) => {
  let request = await axios.get(
    APIConfig.revamp_base_url + `api/v1/balance/downloadledgerreport`,
    {
      headers: {
        Authorization: getAuthToken(),
        portaltype:"distributor",
        customerid:customerid,
        // customerid:1026470
      },
      responseType: "arraybuffer",
    }
  );
  return request;
};

// Get My Customers List
export const getMyCustomersList = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "api/v1/customer/distributors/my-customers" + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};

// Upload Customer Group 
export const uploadCustomerGroup = async (body) => {
  let request = await axios.post(
    APIConfig.base_url + "api/v1/customer/customer-group/upload",
    body,
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};

export const getAllMinifiedCustomerGroup = async () => {
  let request = await axios.get(
    APIConfig.base_url + "api/v1/customer/minified-customers/allCustomerGroups",
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
}; 