import React from 'react';
import { secondsToTime } from '../helper/DateTime';

export const MapsContext = React.createContext();

export const validateLatLng = location => {
	if (
		!location ||
		typeof location !== 'object' ||
		!Object.keys(location).length
	) {
		return false;
	} else if (typeof location === 'object') {
		const { lat, lng, ...rest } = location;
		let p = {};
		if (!lat || !lng) {
			return false;
		} else {
			p['lat'] = parseFloat(lat);
			p['lng'] = parseFloat(lng);
		}
		return { ...p, ...rest };
	}
};

export const calcDistance = kms => {
	// let d = Math.round(kms / 1000);
	let d = parseFloat(kms / 1000).toFixed(1);
	return `${d} ${d > 1 ? 'KMS' : 'KM'}`;
};

export const calculateETA = (distance, stopCount) => {
	let a = distance / 1000;
	let seconds = (a / 25) * 3600; // 25 is the average speed

	if (stopCount > 0) {
		seconds += stopCount * 120; // 2 min stop halt delay
	}
	seconds += 120; // destination halt delay

	return secondsToTime(seconds);
};