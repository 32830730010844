import React, { Component } from "react";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { Spin, Modal } from "antd";
import plus from "../../images/plus.svg";
import PrimaryHeader from "../header/PrimaryHeader";
import { Link } from "react-router-dom";
import "./cod.scss";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { formatMoney } from "../../utils/common";
import history from "../../utils/History";
import ShowSuccessModal from "../modal/showSuccessModal";
import RepeatedCreditLimitExtension from "../modal/repeatedCreditExtensionModal";
import failure from "../../images/failure.svg";
import {
  creditExtension,
  creditExtensionApprove,
  getCreditExtensionDetails,
  downloadPendingCod,
} from "../../services/cod/cod";
import { resetServerContext } from "react-beautiful-dnd";
import successicon from "../../images/success-icon.svg";
class CreditExtension extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      displaySuccessModal: false,
      displayRepeatedCreditLimitExtension: false,
      modalStatus: false,
      modalData: {},
      responseMsg: "",
      tab: "",
      required_credit_limit: "",
      sap_credit_expo: "",
      customer_no: "",
      rejectModal: false,
      isOutstandingAmount: false,
      outstandingErrMsg: "",
      asmId: "",
      salesmanId: "",
      plant: "",
      distributionChannel: "",
      disableKey: false,
    };
  }

  closeModal = () => {
    this.setState({
      displayRepeatedCreditLimitExtension:
        !this.state.displayRepeatedCreditLimitExtension,
    });
  };
  closeDisplayModal = () => {
    this.setState({ displaySuccessModal: !this.state.displaySuccessModal });
    this.getDetails(this.props.match.params.id);
  };
  closeOutstandingModal = () => {
    this.setState({
      isOutstandingAmount: !this.state.isOutstandingAmount,
      outstandingErrMsg: "",
    });
  };
  closeRejectModal = () => {
    this.setState({ rejectModal: !this.state.rejectModal });
  };

  componentDidMount() {
    const { tab, creditExtStatus } = this.props.location.state;
    this.getDetails(this.props.match.params.id);
    console.log(tab, creditExtStatus);
    // this.setState({ modalData: modalData });
  }

  getDetails = async (id) => {
    this.setState({ loading: true });
    await getCreditExtensionDetails(id)
      .then((res) => {
        console.log(res);
        this.setState({ modalData: res.data.data.results[0] });
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  approveCreditExtension = async () => {
    this.setState({ loading: true });
    let { modalData } = this.state;
    let id = modalData._id;

    // let orderAmount =
    //   Number(modalData.orderDetails.totalAmountIncludedTCS) +
    //   Number(modalData.orderDetails.creditExposure);
    const creditBalance =
      modalData &&
      modalData.orderDetails &&
      parseFloat(modalData.orderDetails.creditLimit) -
        parseFloat(modalData.orderDetails.creditExposure);

    const tcsValue =
      modalData &&
      modalData.orderDetails &&
      parseFloat(modalData.orderDetails.totalAmountIncludedTCS);
    const res = tcsValue - creditBalance;
    let orderAmount = res + parseFloat(modalData.orderDetails.creditLimit);

    //console.log(orderAmount);

    let inputs;
    inputs = {
      orderamount: orderAmount.toFixed(2),
      creditexposure: parseFloat(modalData.orderDetails.creditExposure),
    };

    let creditExtensionStatus = "credit-extension-approve";
    await creditExtension(inputs, id, creditExtensionStatus)
      .then((res) => {
        this.setState({
          loading: false,
          disableKey: true,
        });
        this.getDetails(this.props.match.params.id);
        console.log(res);
        this.setState({
          asmId: res.data.data && res.data.data.asmId,
          distributionChannel:
            res.data.data && res.data.data.distributionChannel,
          salesmanId: res.data.data && res.data.data.salesmanId,
          plant: res.data.data && res.data.data.plant,
        });
        let isNoOutstandingAmount = false;

        if (res && res.data && res.data.message) {
          let isAllowApporve = res.data.message.isAllowApporve;
          if (isAllowApporve === true) {
            isNoOutstandingAmount = true;
          } else {
            isNoOutstandingAmount = false;
            this.setState({
              isOutstandingAmount: true,
              outstandingErrMsg: res.data && res.data.data,
            });
          }
        }
        if (isNoOutstandingAmount === true) {
          if (res.data.data && res.data.data.flag == "Success") {
            this.setState({
              displaySuccessModal: true,
            });

            // this.props.history.push({
            //   pathname: `/dashboard/cod-list`,

            //   state: { tab: this.state.tab },
            // });
          }
          if (res.data.data && res.data.data.flag == "popup2") {
            this.setState({
              displayRepeatedCreditLimitExtension: true,
              required_credit_limit:
                res.data.data && res.data.data.required_credit_limit,
              sap_credit_expo: res.data.data && res.data.data.sap_credit_expo,
              customer_no: res.data.data && res.data.data.customer_no,
              responseMsg: res.data.data && res.data.data.extend_message,
            });
          }
          if (res.data && res.data.message.flag == "Information") {
            message.info("SO failed!!");
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  prevPage = () => {
    this.setState({ tab: "pending" });
    this.props.history.push({
      pathname: `/dashboard/cod-list`,
      state: { tab: "pending" },
    });
  };
  goToList = async () => {
    this.setState({ loading: true });
    let { modalData } = this.state;
    let id = modalData._id;

    let inputs;
    inputs = {
      extendedCreditLimit: 0,
      customerNo: 0,
    };
    let creditExtensionStatus = "credit-extension-rejected";
    await creditExtension(inputs, id, creditExtensionStatus)
      .then((res) => {
        this.setState({
          loading: false,
          tab: "rejected",
          disableKey: true,
        });
        this.props.history.push({
          pathname: `/dashboard/cod-list`,
          state: { tab: "rejected" },
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  onClickOfReject = async () => {
    this.setState({ loading: true });
    let { modalData } = this.state;
    let id = modalData._id;

    let inputs;
    inputs = {
      extendedCreditLimit: 0,
      customerNo: 0,
    };
    let creditExtensionStatus = "credit-extension-rejected";
    await creditExtension(inputs, id, creditExtensionStatus)
      .then((res) => {
        this.getDetails(this.props.match.params.id);
        this.setState({
          loading: false,
          rejectModal: true,
          disableKey: true,
          displayRepeatedCreditLimitExtension: false,
        });
        //message.success("Credit extension rejected successfully");
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  rejectListingTab = () => {
    this.setState({ tab: "rejected" });
    setTimeout(() => {
      this.props.history.push({
        pathname: "/dashboard/cod-list",
        state: { tab: this.state.tab },
      });
    }, 2000);
  };

  creditExtensionApproveYes = async () => {
    this.setState({ loading: true });
    let {
      modalData,
      required_credit_limit,
      customer_no,
      sap_credit_expo,
      asmId,
      distributionChannel,
      salesmanId,
      plant,
    } = this.state;
    let id = modalData._id;
    this.setState({ tab: "pending" });
    let inputs;
    inputs = {
      required_credit_limit: required_credit_limit,
      customer_no: customer_no,
      sap_credit_expo: sap_credit_expo,
      ispopup: 0,
      asmId: asmId,
      salesmanId: salesmanId,
      distributionChannel: distributionChannel,
      plant: plant,
    };
    console.log(inputs);
    await creditExtensionApprove(inputs, id)
      .then((res) => {
        this.setState({
          loading: false,
          disableKey: true,
          displayRepeatedCreditLimitExtension: false,
        });
        console.log(res);
        //message.success("Credit extension Accepted successfully");
        // this.props.history.push({
        //   pathname: `/dashboard/cod-list`,

        //   state: { tab: this.state.tab },
        // });
        this.setState({
          displaySuccessModal: true,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  render() {
    let { modalData, loading, responseMsg, rejectModal } = this.state;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const { creditExtStatus } = this.props.location.state;
    const creditBalance =
      modalData &&
      modalData.orderDetails &&
      parseFloat(modalData.orderDetails.creditLimit) -
        parseFloat(modalData.orderDetails.creditExposure);

    const tcsValue =
      modalData &&
      modalData.orderDetails &&
      modalData.orderDetails.totalAmountIncludedTCS;
    //console.log(tcsValue, "tcsvalue");
    //console.log(Number(creditBalance?.replace(/\,/g, "")), "creditbalance");
    // const orderAmountInTcs =
    //   tcsValue - Number(creditBalance?.replace(/\,/g, ""));
    const orderAmountInTcs = parseFloat(tcsValue) - creditBalance;
    //console.log(orderAmountInTcs, "..............");

    return (
      <div id="content" className="">
        <PrimaryHeader title="Credit Limit Extension Request" />
        <Spin indicator={antIcon} spinning={loading}>
          <div className="mr-3 card mt-5">
            <div className="orderdetails-text pl-1 ml-4 mt-4">
              Order Details
            </div>
            <div className="d-flex mt-4 mb-4 ">
              <div className="d-flex col-lg-12 col-md-12  col-xl-12 ">
                <div className="d-flex col-lg-2 col-md-3  col-xl-2 flex-column">
                  <span className="title-name1 mb-1">Order Date</span>
                  <span className="title-data1">
                    {/* 12-02-2021 */}
                    {modalData &&
                      modalData.orderDetails &&
                      moment(modalData.orderDetails.dateOfOrderPlacing).format(
                        "DD-MM-YYYY"
                      )}
                  </span>
                </div>
                <div className="d-flex col-lg-2 col-md-2  col-xl-2 flex-column">
                  <span className="title-name1 mb-1">Order Type</span>
                  <span className="title-data1">
                    {/* PDWaycool */}
                    {modalData && modalData.orderFrom}
                  </span>
                </div>

                <div className="d-flex col-lg-2 col-md-2  col-xl-2 flex-column">
                  <span className="title-name1 mb-1">Plant ID</span>
                  <span className="title-data1">
                    {/* 1001 */}
                    {modalData &&
                      modalData.orderDetails &&
                      modalData.orderDetails.plant}
                  </span>
                </div>
                <div className="d-flex col-lg-2 col-md-2  col-xl-2 flex-column">
                  <span className="title-name1 mb-1">Order Amount</span>
                  <span className="title-data1">
                    &#8377;
                    {modalData &&
                      modalData.orderDetails &&
                      modalData.orderDetails.totalAmount}
                    {/* 20,516 */}
                  </span>
                </div>
                <div className="d-flex col-lg-3 col-md-2  col-xl-3 flex-column">
                  <span className="title-name1 mb-1">Delivery Date</span>
                  <span className="title-data1">
                    {/* 12-02-2021 */}
                    {modalData &&
                      modalData.orderDetails &&
                      moment(modalData.orderDetails.dateOfDelivery).format(
                        "DD-MM-YYYY"
                      )}
                  </span>
                </div>
              </div>
            </div>
            <div className="orderdetails-text pl-1 ml-4 mt-2">
              Customer Details
            </div>
            <div className="d-flex mt-4 mb-4 ">
              <div className="d-flex col-lg-12 col-md-12  col-xl-12 ">
                <div className="d-flex col-lg-3 col-md-3  col-xl-3 flex-column">
                  <span className="title-name1 mb-1">Customer ID</span>
                  <span className="title-data1">
                    {/* 12345678910 */}
                    {modalData &&
                      modalData.orderDetails &&
                      modalData.orderDetails.goFrugalId}
                  </span>
                </div>
                <div className="d-flex col-lg-3 col-md-2  col-xl-3 flex-column">
                  <span className="title-name1 mb-1">Customer Name</span>
                  <span className="title-data1">
                    {/* 12345678910 */}
                    {modalData &&
                      modalData.orderDetails &&
                      modalData.orderDetails.customerName}
                  </span>
                </div>

                <div className="d-flex col-lg-3 col-md-2  col-xl-3 flex-column">
                  <span className="title-name1 mb-1">Credit Exposure</span>
                  <span className="title-data1">
                    &#8377;
                    {modalData &&
                      modalData.orderDetails &&
                      modalData.orderDetails.creditExposure}
                    {/* 10,12,516 */}
                  </span>
                </div>
                <div className="d-flex col-lg-3 col-md-2  col-xl-3 flex-column">
                  <span className="title-name1 mb-1">Credit Limit</span>
                  <span className="title-data1">
                    &#8377;
                    {modalData &&
                      modalData.orderDetails &&
                      modalData.orderDetails.creditLimit}
                    {/* 18,500 */}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex mt-1 mb-4 ">
              <div className="d-flex col-lg-12 col-md-12  col-xl-12 ">
                <div className="d-flex col-lg-4 col-md-3  col-xl-4 flex-column">
                  <span className="title-name2 mb-1">
                    Required Credit Limit
                  </span>
                  <span className="title-data2">
                    &#8377;
                    {
                      modalData &&
                        modalData.orderDetails &&
                        formatMoney(
                          // Math.round(
                          parseFloat(modalData.orderDetails.creditLimit) +
                            parseFloat(orderAmountInTcs)
                        )
                      // )
                    }
                    {/* 20,516 */}
                  </span>
                </div>
              </div>
            </div>

            {creditExtStatus == 0 ? (
              <div className="row d-flex justify-content-lg-start justify-content-md-start pl-3 mt-4 ml-4 mb-5 ">
                <button
                  type="button"
                  className="btn btn-outline-primary mr-4 btnWidth"
                  onClick={this.onClickOfReject}
                  disabled={
                    modalData.requestStatus == "SAP" ||
                    modalData.SapApproveStatus == "rejected"
                  }
                >
                  Reject
                </button>

                <button
                  type="button"
                  className="btn btn-primary btnWidth"
                  onClick={this.approveCreditExtension}
                  disabled={
                    modalData.requestStatus == "SAP" ||
                    modalData.SapApproveStatus == "rejected"
                  }
                >
                  Approve
                </button>
              </div>
            ) : (
              <div className="row d-flex justify-content-lg-start justify-content-md-start pl-3 mt-4 ml-4 mb-5">
                <button
                  type="button"
                  className="btn btn-outline-secondary mr-4 btnWidth"
                  onClick={this.onClickOfReject}
                  disabled
                >
                  Reject
                </button>

                <button
                  type="button"
                  className="btn btn-secondary btnWidth"
                  onClick={this.approveCreditExtension}
                  disabled
                >
                  Approve Extension
                </button>
                <p className="note mt-3 ml-n1">
                  Note : Changes in credit are not allowed, Please contact
                  finance team for the credit extension.
                </p>
              </div>
            )}
          </div>
          {/* Modal for Outstanding Amount check */}
          {this.state.isOutstandingAmount && (
            <Modal
              visible={this.state.isOutstandingAmount}
              onCancel={this.closeOutstandingModal}
              closable
              maskClosable={false}
              footer={null}
            >
              <div className="text-left">
                <div className="credit-limit-text mt-4">
                  <p className="new-vehicle">{"Extension Not Allowed!"}</p>
                  <p className="new-vehicle">
                    {this.state.outstandingErrMsg &&
                      this.state.outstandingErrMsg}
                  </p>

                  <button
                    type="button"
                    className="btn btn-primary mx-auto d-block btn-md"
                    onClick={this.goToList}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Modal>
          )}
          {this.state.displaySuccessModal && (
            // <ShowSuccessModal
            //   message={"Credit Limit has been extended Successfully!"}
            //   link={"/dashboard/cod-list"}
            //   buttonName={"Credit Extension - Listing"}
            //   tab={this.state.tab}

            // />
            <Modal
              visible={this.state.displaySuccessModal}
              onCancel={this.closeDisplayModal}
              closable
              maskClosable={false}
              footer={null}
            >
              <div className="text-center">
                <div className="credit-limit-text  mt-4">
                  <img src={successicon} />
                  <p className="new-vehicle  mt-3 mx-md-5 mx-lg-auto col-lg-12 text-center col-md-12 mt-3">
                    {"Credit limit request has been sent to SAP!"}
                  </p>
                  <p className="col-lg-8 col-md-10 text-center mx-auto mt-2 new-vehicle">
                    Go To{" "}
                  </p>

                  <button
                    type="button"
                    className="btn btn-primary mb-5 mx-auto d-block btn-md"
                    onClick={this.prevPage}
                  >
                    {"Credit Extension - Listing"}
                  </button>
                </div>
              </div>
            </Modal>
          )}
          {/* {
                this.state.displayRepeatedCreditLimitExtension && (
                    <RepeatedCreditLimitExtension
                        displayModal={this.approveCreditExtension}  
                        closeModal={this.closeModal}                     
                    />
                )
            } */}
          {this.state.displayRepeatedCreditLimitExtension && (
            // <Spin indicator={antIcon} spinning={loading}>
            <Modal
              width={420}
              visible={this.state.displayRepeatedCreditLimitExtension}
              onCancel={this.closeModal}
              closable
              maskClosable={false}
              footer={null}
            >
              <div>
                <Spin indicator={antIcon} spinning={loading}>
                  <div className="text-center">
                    <div className="credit-limit-text ml-2 ml-5 mr-5 mt-4">
                      {/* Required Credit Limt increased from 10,516/- to 20,516/-
                  because Credit Exposure has been increased from 12,000 to
                  24,000. */}
                      {responseMsg}
                    </div>
                    {/* <div className="credit-limit-text mt-5 ">
                  Are you sure you want to proceed ?
                </div> */}

                    <button
                      className="btn btn-primary mr-4 mt-4"
                      onClick={this.creditExtensionApproveYes}
                      //  disabled={this.state.disableKey}
                    >
                      Approve
                    </button>

                    <button
                      className="btn  btn-outline-primary mt-4"
                      onClick={this.onClickOfReject}
                      //  disabled={this.state.disableKey}
                    >
                      Reject
                    </button>
                  </div>
                </Spin>
              </div>
            </Modal>
            // </Spin>
          )}
          {rejectModal && (
            <Modal
              width={650}
              visible={this.state.rejectModal}
              onCancel={this.closeRejectModal}
              closable
              maskClosable={false}
              footer={null}
            >
              <div>
                <div className="text-center">
                  <div className="credit-limit-text mt-4">
                    <img src={failure} />
                    <p className="mt-2">
                      Credit Limit has been Rejected Successfully!
                    </p>
                  </div>
                  <p className="col-lg-8 col-md-10 text-center mx-auto mt-2 new-vehicle">
                    Go To{" "}
                  </p>

                  <button
                    type="button"
                    className="btn btn-primary mb-5 mx-auto d-block btn-md"
                    onClick={this.rejectListingTab}
                  >
                    {"Credit Extension - Listing"}
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </Spin>
      </div>
    );
  }
}

export default CreditExtension;
