import axios from "axios";
import { api as APIConfig } from "../appConfig";

export const routeOptimisation = async (inputs) => {
  // getting the optimized route
  let request = await axios.post(
    APIConfig.azure_func + "api/TravellingSalesMan",
    {
      ...inputs,
    }
  );
  return request;
};