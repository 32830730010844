import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import codList from './../components/cod/codList';
import { getAllPendingSo, getAllRequestedSo,getAllRjectedSo } from "../action/cod";

const mapStateToProps = (state) => {
    return {
        pendingSoList: state.cod.pendingSoList,
        requestedSoList: state.cod.requestedSoList,
        rejectedSOList:state.cod.rejectedSOList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAllPendingSo,
            getAllRequestedSo,
            getAllRjectedSo
        },
        dispatch
    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(codList));
