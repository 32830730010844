import generateActionTypes from "../utils/generateActionTypes";
import { getOrdersList as getOrdersListService } from "../services/orderServices/orderService";

import { handleErrorResponse } from "../utils/Request";
import message from "antd/lib/message";
export const actionTypes = generateActionTypes(
  "GET_ORDERS_LIST",
  "LOADING",
  "STOP_LOADING"
);
export function stopLoading(loadType) {
  return (disaptch) => {
    return disaptch({
      type: actionTypes.STOP_LOADING,
      load: loadType,
    });
  };
}
export const getOrdersList = ({
  startDate,
  endDate,
  searchKey,
  //checkedCustomerGropList,
  checkOrderStatus,
  checkPaymentOption,
  checkedList,
  podStatus,
  otpVerification,
  soStatusList,
  invStatusList,
  page,
  pageSize,
}) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.GET_ORDERS_LIST,
    });
    try {
      const res = await getOrdersListService(
        startDate,
        endDate,
        searchKey,
        //  checkedCustomerGropList,
        checkOrderStatus,
        checkPaymentOption,
        checkedList,
        podStatus,
        otpVerification,
        soStatusList,
        invStatusList,
        page,
        pageSize
      );
      const resData = res?.data;
      if (resData.status == 200) {
        const { result } = resData;
        console.log(result.totalDocuments);
        dispatch({
          type: actionTypes.GET_ORDERS_LIST,
          data: { result, searchKey, totalDocuments: result.totalDocuments },
        });
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
    }
  };
};
