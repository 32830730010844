import React, { Component, Fragment } from 'react';
import { LoadingOutlined, PlusOutlined, PoweroffOutlined } from "@ant-design/icons";
import message from "antd/lib/message";
import { Modal, Upload, Button } from "antd";
import {uploadFile} from '../../services/fileHandler';
import { updateAsmProfile} from '../../services/teamServices/team';
import { handleErrorResponse } from "../../utils/Request";

// get bas64
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

// before uploading 
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

// admin profile class
class AsmTeamProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      apiLoading: false,
      isEmailEdit: false,
      isContactEdit: false,
      isProfilePicUpdate: false,
      removeProfilePic: false,
      contactNumber: '',
      email: '',
      picId: ''
    };
  }

  // handle change of image uploader
  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };

  // uploading file to server 
  uploadToServer = async ({ onSuccess, onError, file }) => {
    let res = await uploadFile(file)
      .then((res) => {
        this.setState({isProfilePicUpdate: true, picId: res.data.data.imageId, removeProfilePic: false })
        onSuccess(null, file);
      }).catch((err) => {
        onError(err);
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  }

  // toggele edit 
  toggleEdit = ((e, input) => {
     if  (e.target.id == 'removeProfilePic') 
     this.setState({ removeProfilePic: !this.state.removeProfilePic, isProfilePicUpdate: true, picId: '' })
  })

 
handleUpdate = async (e,id) => {    
    e.preventDefault();
    let updateObject = {};
    const { isEmailEdit, isContactEdit, isProfilePicUpdate, contactNumber, email, picId } = this.state;
    this.setState({ apiLoading: true });
    if (isProfilePicUpdate) updateObject = { ...updateObject, profilePic: picId }

    // updating admin profile 
    await updateAsmProfile(updateObject,id).then((res) => {
      this.setState({ apiLoading: false })
      message.info('Profile Photo Updated Successfully');
      this.props.onProfileUpdate();
      this.setState({      
                  isProfilePicUpdate: false,
                  picId: '',
             })

    }).catch((err) => {
      this.setState({ apiLoading: false })
      const { errorMessage, errors } = handleErrorResponse(err);
      message.error(errorMessage);
    })
  }


 
 

  render() {
    let { loading, imageUrl, isEmailEdit, isContactEdit, contactNumber, email, isProfilePicUpdate, apiLoading, removeProfilePic, isResetModalVisible, existingPassword, newPassword, confirmPassword } = this.state;
    const { data } = this.props;
   
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Fragment>       
          
         
                <form className="form-horizontal">
                  <div className="form-group" style={{marginTop:"3rem"}}>
                   <Upload
                      id="profilePicture"
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      customRequest={this.uploadToServer}
                      beforeUpload={beforeUpload}
                      onChange={this.handleChange}
                    >
                      {!removeProfilePic ? imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : data.imageUrl ? <img src={data.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton : uploadButton}
                    </Upload>
                    <i class="fa fa-times-circle-o pic-delete-btn-asm text-danger" aria-hidden="true"
                      data-toggle="tooltip"
                      title="Remove!"
                      id="removeProfilePic"
                      onClick={(e) => this.toggleEdit(e, 'removeProfilePic')}
                      hidden={removeProfilePic ? true : data.imageUrl ? false : true}
                    ></i>
                  </div>          
                 
  
                </form>
                 <div >
               <Button type="primary"
                hidden={ isProfilePicUpdate ? false : true}
                className="update-button-asm"
                shape="rounded"
                onClick={(e) => this.handleUpdate(e,this.props.id)}
                loading={apiLoading ? true : false}>
                Update
              </Button>
              </div>  

      
      </Fragment >
    );
  }
}

export default AsmTeamProfile;
