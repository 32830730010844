import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import CustomerLedgerDetails from "../components/Customers/customerDetails";

//Action Creator
import { getAllCustomersLedgerDetails } from "../action/customers";
import { getInvoiceData,getPaymentData,getCreditData,getDebitData,getLedgerDetails } from './../action/customers';


const mapStateToProps = (state) => {
  return {
    // from reducer
    allCustomersLedgerDetails : state.customers.allCustomersLedgerDetails,
    allInvoiceData:state.customers.allInvoiceData,
    allPaymentData:state.customers.allPaymentData,
    allCreditdata:state.customers.allCreditdata,
    allDebitData:state.customers.allDebitData,
    allLedgerDetails:state.customers.allLedgerDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
       getAllCustomersLedgerDetails,
       getInvoiceData,
       getPaymentData,
       getCreditData,
       getDebitData,
       getLedgerDetails,
     },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerLedgerDetails)
);
