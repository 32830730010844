import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import TeamSalesmen from "../components/team/teamSalesmen";

//Action Creator
import { getSalesmen } from "../action/team";

const mapStateToProps = (state) => {
    return {
        allSalesmen: state.team.allSalesmen
    };
  };

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
        getSalesmen,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamSalesmen));
