import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Pagination, Spin } from "antd";
import message from "antd/lib/message";
import { sum } from "lodash";
import moment from "moment";
import React from "react";
import Select from "react-select";
import { isAmount } from "../../helper/Common";
import ArrowLeft from "../../images/arrow-left.svg";
import Cross from "../../images/cross(1).svg";
import Edit from "../../images/edit-3.svg";
import image from "../../images/Frame 1245687.svg";
import mail from "../../images/mailing.svg";
import papad from "../../images/papad-removebg-preview 1.svg";
import picture from "../../images/picturee.svg";
import Tick from "../../images/Vector.svg";
import {
  generateGRNEmail,
  getPurchasePendingDetails,
  GRNService,
  updatePurchaseList,
} from "../../services/franchiseServices/purchaseServices";
import { handleErrorResponse } from "../../utils/Request";
import PrimaryHeader from "../header/PrimaryHeader";

const selectOptions = [
  { label: "Quantity discrepancy", value: 1 },
  { label: "IN transit Damage", value: 2 },
  { label: "Wrong SKU", value: 3 },
  { label: "Spec issue", value: 4 },
  { label: "Price issue", value: 5 },
  { label: "Late delivery", value: 6 },
  { label: "Poor Quality", value: 7 },
];

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function Details(props) {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(7);
  const [id, setId] = React.useState("");
  const [basicDetails, setBasicDetails] = React.useState({});
  const [items, setItems] = React.useState([]);
  const [skip, setSkip] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [inputBox, setInputBox] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [grnModal, setGrnModal] = React.useState(false);
  const [authenticationModal, setAuthenticationModal] = React.useState(false);

  React.useEffect(() => {
    setId(props.match.params.id);
    getDetails(props.match.params.id);
  }, []);

  const getDetails = async (id) => {
    setLoading(true);
    await getPurchasePendingDetails(id)
      .then((res) => {
        setLoading(false);
        setId(res.data.response.data[0]._id);
        setBasicDetails(res && res.data.response.data[0].basicDetails);
        setItems(
          res &&
            res.data.response.data[0].itemBreakDown.map((item) => {
              return {
                ...item,
                inputStatus: false,
                recQty: item.received_qty,
                remark: item.remarks,
              };
            })
        );
        setSkip(res && res.data.response.skip);
        setTotalPage(res && res.data.response.totalPage);
        setTotalRecords(res && res.data.response.totalRecords);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const redirectToBack = () => {
    props.history.push("/dashboard/purchaseOrders");
  };

  const getLatestArray = () => {
    let latestArray = items.map((item) => {
      return item;
    });
    setItems(latestArray);
  };

  const inputActivate = (item) => {
    let objIndex = items.findIndex((obj) => obj.itemId === item.itemId);
    items[objIndex].inputStatus = true;
    getLatestArray();
  };

  const inputDeActivate = (item, type) => {
    if (type == "cancel") {
      let id = item.itemId;
      let objIndex = items.findIndex((obj) => obj.itemId === item.itemId);
      items[objIndex].inputStatus = false;
      items[objIndex].received_qty = items[objIndex].recQty;
      items[objIndex].remarks = items[objIndex].remark;
      getLatestArray();
      let handleCross = Object.assign({}, inputBox);
      if (handleCross.hasOwnProperty(id)) {
        delete handleCross[id];
      }
      setInputBox(handleCross);
    } else {
      patchPurchaseList(item, type);
    }
  };

  const inputHandler = (e) => {
    let _id = e._id;
    let value = e.amountMapped;
    let handleInputField = Object.assign({}, inputBox);
    let unitPrice = e.unitPrice;
    handleInputField[_id] = unitPrice * value;
    setInputBox(handleInputField);
    let objIndex = items.findIndex((obj) => obj.itemId === _id);
    items[objIndex].received_qty = value;
    getLatestArray();
  };

  const selectHandler = (e, item) => {
    let objIndex = items.findIndex((obj) => obj.itemId === item.itemId);
    items[objIndex].remarks = e.label;
    getLatestArray();
  };

  const handleChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const patchPurchaseList = async (item) => {
    setLoading(true);
    let objIndex = items.findIndex((obj) => obj.itemId === item.itemId);
    let data;
    data = {
      itemNo: item.item_no,
      recQty: items[objIndex].received_qty,
      remarks: items[objIndex].remarks
        ? items[objIndex].remarks.toLowerCase()
        : "",
      invQty: items[objIndex].quantity,
    };

    await updatePurchaseList(props.match.params.id, data)
      .then((res) => {
        setLoading(false);
        message.success(res.data.message);
        getDetails(props.match.params.id);
      })
      .catch((err) => {
        setLoading(false);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(err.response.data.data);
      });
  };

  const emailKey = items.every((item) => {
    if (item.remarks == "") {
      return true;
    }
  });

  const grnDisabled = items.every((item) => {
    if (item.inputStatus == false) {
      return true;
    }
  });

  const generateGRN = async () => {
    setLoading(true);
    let qParams = [{ invoiceId: basicDetails.invoiceId }];
    await GRNService(qParams)
      .then((res) => {
        setLoading(false);
        setGrnModal(true);
        setTimeout(() => {
          props.history.push("/dashboard/purchaseOrders");
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const showModal = () => {
    setAuthenticationModal(true);
  };

  const emailGeneration = async () => {
    setLoading(true);
    let modifiedItems = items.filter((item) => {
      return item.remarks != "";
    });

    let data = modifiedItems.map((item) => {
      return {
        poId: id,
        itemName: item.itemName,
        itemId: item.itemId,
        recQty: item.received_qty,
        remarks: item.remarks,
        invQty: item.quantity,
        uom: item.uom,
        itemAmount: item.total_amount,
      };
    });
    let sendObj = { grnMailData: data };

    await generateGRNEmail(sendObj)
      .then((res) => {
        setLoading(false);
        message.success(res.data.message);
        setTimeout(() => {
          setAuthenticationModal(false);
        }, 600);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const closeModals = () => {
    setAuthenticationModal(false);
  };

  const calculateUnitPrice = (total, discount, qty) => {
    discount = Math.abs(discount);
    let unitPrice = (Number(total)+Number(discount))/Number(qty);
    unitPrice = isAmount(unitPrice, true);
    return unitPrice;
  };

  const purchaseTbody = items.map((item) => {
    let objIndex = items.findIndex((obj) => obj.itemId === item.itemId);
    return (
      <>
        <tr>
          <td>
            <div className="d-flex">
              <div className="">
                <img src={papad} alt="" className="table_image" />
              </div>
              <div className="ml-4 mt-2">
                <p className="purchase_item">
                  {item.itemName ? item.itemName : "N/A"}
                </p>
                <p className="mt-n2 purchase_id">
                  {item.itemId ? item.itemId : "N/A"}
                </p>
                {/* <p className="mt-n2 discount">
                                    {item.discount}
                                    <span className="ml-2 economy">
                                        {item.status}
                                    </span>
                                </p> */}
              </div>
            </div>
            {(items[objIndex].remarks != "" ||
              items[objIndex]["received_qty"] <
                items[objIndex]["quantity"]) && (
              <div className="mt-3 mb-3">
                <label htmlFor="" className="purchase_remarks">
                  {"Remarks"}
                </label>
                <br />
                <Select
                  options={selectOptions}
                  className={"reactSelect"}
                  menuPosition="fixed"
                  onChange={(e) => selectHandler(e, item)}
                  value={
                    item.remarks
                      ? {
                          label: item.remarks,
                          value: item.remarks,
                        }
                      : ""
                  }
                  placeholder={"Choose Remarks"}
                />
              </div>
            )}
          </td>
          <td>
            <div className="mt-3">
              <p className="purchase_unit">
                {item?.salePrice &&
                  calculateUnitPrice(
                    item?.total_amount,
                    item?.discountAmount,
                    item?.quantity
                  )}
              </p>
            </div>
          </td>
          <td>
            <div className="mt-3">
              <p className="purchase_unit">
                {item?.total_amount && isAmount(item.total_amount, true)}
              </p>
              {item.discountAmount != 0 && (
                <p className="strike_data">{item.discountAmount}</p>
              )}
            </div>
          </td>
          <td>
            <div className="mt-3">
              <p className="purchase_unit">
                {item.quantity ? item.quantity : "N/A"}
              </p>
            </div>
          </td>
          <td>
            <>
              <div className="d-flex mt-2 purchase_input">
                <input
                  type="number"
                  value={item.received_qty}
                  className={`${
                    item.inputStatus == false &&
                    item.received_qty == item.quantity
                      ? "purchase_unit form-control form-label"
                      : item.inputStatus == false &&
                        item.received_qty < item.quantity
                      ? "purchase_unit_false form-control form-label"
                      : item.inputStatus == true
                      ? "purchase_unit_true form-control form-label"
                      : ""
                  }`}
                  disabled={
                    item.received_qty == item.quantity && !item.inputStatus
                  }
                  onChange={(e) =>
                    inputHandler({
                      _id: item.itemId,
                      amountMapped: +e.target.value,
                      totalAmount: item.total_amount,
                      unitPrice: item.total_amount / item.quantity,
                      salePrice: item.salePrice,
                      quantity: item.quantity,
                    })
                  }
                />

                {item.inputStatus == false ? (
                  <img
                    src={Edit}
                    alt=""
                    className="ml-2"
                    onClick={() => inputActivate(item)}
                  />
                ) : (
                  <div className="d-flex">
                    <div className="mt-2 pt-1 ml-2">
                      <img
                        src={Tick}
                        alt=""
                        onClick={() => inputDeActivate(item, "inputTick")}
                      />
                    </div>
                    <div className="ml-2 mt-1 edit_border">
                      <img
                        src={Cross}
                        alt=""
                        onClick={() => inputDeActivate(item, "cancel")}
                      />
                    </div>
                  </div>
                )}
              </div>
              {items[objIndex]["received_qty"] >
                items[objIndex]["quantity"] && (
                <div>
                  <span className="error-message">
                    {"Not allowed, Please"}
                    <br />
                    {"return extra Qty"}
                    {/* {"RecievedQty is greater than"}
                                        <br />
                                        {"InvoiceQty please return"}
                                        <br />
                                        {"Some quantity"} */}
                  </span>
                </div>
              )}
              {items[objIndex]["remarks"] == "" &&
                items[objIndex]["received_qty"] <
                  items[objIndex]["quantity"] && (
                  <div>
                    <span className="error-message">
                      {"Please Choose Remarks"}
                    </span>
                  </div>
                )}
            </>
          </td>
        </tr>
      </>
    );
  });

  return (
    <div id="content">
      <PrimaryHeader title={`Order # ${basicDetails.invoiceId}`} />
      <Spin indicator={antIcon} spinning={loading}>
        <div className="mt-5 cursor d-flex col-lg-2" onClick={redirectToBack}>
          <div className="">
            <img src={ArrowLeft} alt="" />
          </div>
          <div className="ml-2 mt-1 arrow_label">
            <p>{"Back"}</p>
          </div>
        </div>
        <div className="mt-4  purchase_content">
          <div className="border_left ml-3">
            <label htmlFor="" className="purchase_label">
              {"Basic Details"}
            </label>
          </div>
          <div className="mt-4">
            <div className="row col-lg-12">
              <div className="col-lg-3">
                <label htmlFor="" className="purchase_sub">
                  {"Order Date"}
                </label>
                <p htmlFor="" className="purchase_value">
                  {basicDetails.orderDate
                    ? moment(basicDetails.orderDate).format("DD-MM-YYYY")
                    : "N/A"}
                </p>
              </div>
              <div className="col-lg-2">
                <label htmlFor="" className="purchase_sub">
                  {"Invoice ID"}
                </label>
                <p htmlFor="" className="purchase_value">
                  {basicDetails ? basicDetails.invoiceId : "N/A"}
                </p>
              </div>
              <div className="col-lg-3 pl-5">
                <label htmlFor="" className="purchase_sub">
                  {"Delivery Date"}
                </label>
                <p htmlFor="" className="purchase_value">
                  {basicDetails
                    ? moment(basicDetails.deliveryDate).format("DD-MM-YYYY")
                    : "N/A"}{" "}
                </p>
              </div>
              <div className="col-lg-2">
                <label htmlFor="" className="purchase_sub">
                  {"Item Count"}
                </label>
                <p htmlFor="" className="purchase_value">
                  {basicDetails ? basicDetails.itemCount : "N/A"}{" "}
                </p>
              </div>
              <div className="col-lg-2">
                <label htmlFor="" className="purchase_amt_sub">
                  {"Total Amount"}
                </label>
                <p htmlFor="" className="purchase_amount">
                  {basicDetails
                    ? Object.values(inputBox).length > 0
                      ? isAmount(sum(Object.values(inputBox)), true)
                      : isAmount(Number(basicDetails.totalAmount), true)
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 purchase_content">
          <div className="border_left ml-3">
            <label htmlFor="" className="purchase_label">
              Item Breakdown
            </label>
          </div>
          <div className="table-wrapper">
            <div className="mt-4 ml-2 purchase_table">
              <table className="table">
                <thead className="remove-border-top">
                  <tr>
                    <th>Item Details</th>
                    <th>Unit Price</th>
                    <th>Net Total Amount</th>
                    <th>Invoiced Qty</th>
                    <th>Recieved Qty</th>
                  </tr>
                </thead>
                <tbody>{purchaseTbody}</tbody>
              </table>
              {purchaseTbody.length === 0 && (
                <div className="no-data">
                  <p>No Data Available</p>
                </div>
              )}
              {purchaseTbody.length > 0 && (
                <div className="row mt-3 mb-3">
                  <div className="col-lg-4">
                    Showing {skip + 1} to
                    {skip + 10 > totalRecords ? totalRecords : skip + 10} of
                    {totalRecords} entries
                  </div>
                  <div className="offset-lg-4 col-lg-4 franchisePagination">
                    {totalPage != 0 && (
                      <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRecords}
                        showSizeChanger={false}
                        onChange={(page, pageSize) =>
                          handleChange(page, pageSize)
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="d-flex">
            <button className="btn btn-outline-primary" disabled>
              {"Reject"}
            </button>
            <button
              className="ml-4 btn btn-primary"
              disabled={!grnDisabled}
              onClick={emailKey ? () => generateGRN() : () => showModal()}
            >
              {"Generate GRN"}
            </button>
          </div>
        </div>
        {grnModal && (
          <Modal
            visible={grnModal}
            footer={null}
            onCancel={false}
            className="grnModalContent"
          >
            <div className="grnModal">
              <div className="mt-2">
                <img src={picture} alt="" />
              </div>
              <div className="mt-3">
                <p className="grnLabel">
                  {"GRN has been generated successfully!"}
                </p>
              </div>
              <div className="mt-n2">
                <p className="grn_sub">
                  {"Redirecting you to Purchase Receipt listing"}
                </p>
              </div>
            </div>
          </Modal>
        )}
        {authenticationModal && (
          <Modal
            visible={authenticationModal}
            footer={null}
            onCancel={() => closeModals()}
            className="authenticationModalContent"
          >
            <div className="authenticationModal">
              <div className="mt-2">
                <img src={image} alt="" />
              </div>
              <div className="mt-3 ml-4">
                <div className="sub_div">
                  <p className="auth_content">{"Authorisation Required!"}</p>
                </div>
                <div className="mt-n2">
                  <p className="auth_sub">
                    A authorisation request mail will be sent to{" "}
                    <span className="modal_highlights">FNL team</span> on
                    <span className="modal_highlights">
                      {" "}
                      example@gamil.com
                    </span>{" "}
                    post which GRN will be generated.
                  </p>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => emailGeneration()}
                  >
                    {"Send"}
                    <img src={mail} alt="" className="ml-2" />
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Spin>
    </div>
  );
}
