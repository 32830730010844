import { Typography, Divider, Button } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { handleErrorResponse } from "../../../../src/utils/Request";
import message from "antd/lib/message";
import {
  triggerOtpService,
  getOTPMenuConfig,
} from "../../../services/otpServices/otpServices";

const items = [
  { label: "Shipment 1", key: "item-1" }, // remember to pass the key prop
  { label: "Shipment 2", key: "item-2" },
];

// const { IndividualOrderDetails, ConfigData } = useSelector(
//   (state) => state.individualOrderDetailsReducer
// );
function Welcome({ bgColor }) {
  return (
    <div
      style={{
        height: "10px",
        width: "10px",
        borderRadius: "50px",
        backgroundColor: bgColor,
        marginTop: "4px",
      }}
    ></div>
  );
}

function ShippmentDetails({
  title,
  invoiceno,
  paymentstatus,
  digitalsign,
  pod,
  otp,
  creditnote,
  shipment,
  shipmentindex,
}) {
  let Otp = "3";

  const params = useParams();
  let history = useHistory();
  const soId = params?.soId;
  const { IndividualOrderDetails, ConfigData } = useSelector(
    (state) => state.individualOrderDetailsReducer
  );
  const creditNote = IndividualOrderDetails[0]?.creditNote;
  // console.log(shipment);
  // let shippments = IndividualOrderDetails[0]?.shipments[shipment];
  // let isCompleteSalesReturn = shipment?.isCompleteSalesReturn;
  // console.log(shipment);
  // console.log(invoiceno);

  const customerID = params?.customerID;

  // console.log(start, "ConfigData");
  // const ConfigData = {};
  const invoiceDetails = async () => {
    try {
      // history.push({
      //   pathname:
      //     "/dashboard/orders/viewinvoice/" +
      //     soId +
      //     "/" +
      //     record.mginvoiceId +
      //     "/" +
      //     customerID +
      //     "/" +
      //     1,
      // });
      const InvoiceId = shipment.invoiceId;
      Otp = "3";
      const location = {
        pathname: `/dashboard/orders/viewinvoice/`,
        state: { soId, InvoiceId, customerID, Otp, shipment, shipmentindex },
      };
      history.push(location);
    } catch (err) {
      console.log("err");
    }
  };
  const triggerOTP = async (record) => {
    try {
      const InvoiceId = shipment.invoiceNo;

      await triggerOtpService(soId, InvoiceId, ConfigData?.isLogistics).then(
        (res) => {
          if (res?.status == 200) {
            // history.push({
            //   pathname:
            //     "/dashboard/orders/viewinvoice/" +
            //     soId +
            //     "/" +
            //     record.mginvoiceId +
            //     "/" +
            //     customerID +
            //     "/" +
            //     4,
            // });
            const InvoiceId = shipment.invoiceId;
            Otp = "4";
            const location = {
              pathname: `/dashboard/orders/viewinvoice/`,
              state: {
                soId,
                InvoiceId,
                customerID,
                Otp,
                shipment,
                shipmentindex,
              },
            };
            history.push(location);
          } else {
            message.error(res?.message);
          }
        }
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        console.log(error, "error");
        const { errorMessage, errors } = handleErrorResponse(error);
      } else {
        message.error(error?.response?.data?.message);
      }
    }
  };
  const getPODStatus = () => {
    if (shipment?.isCompleteSalesReturn == true) {
      return "NA";
    }
    if (shipment?.invoiceTrack?.length < 3) {
      if (ConfigData?.isLogistics == false) {
        if (pod?.length > 0) {
          return "AVAILABLE";
        } else if (pod?.length == 0) {
          return "UPLOAD";
        }
      } else {
        return "NA";
      }
    }

    if (pod?.length > 0) {
      return "AVAILABLE";
    } else if (pod?.length == 0) {
      return "UPLOAD";
    } else {
      return "NA";
    }

    return "NA";
  };
  const detailsBtnDisplay = () => {
    if (ConfigData.isLogistics == false) {
      return true;
    } else if (shipment?.isCompleteSalesReturn == true) {
      return false;
    } else if (shipment?.invoiceTrack?.length < 3) {
      return false;
    } else {
      return true;
    }
  };
  const getOTPStatus = () => {
    if (ConfigData?.isLogistics == false) {
      if (shipment?.otp?.status === true) {
        return "Verified";
      } else {
        return "Trigger";
      }
    } else {
      if (shipment?.isCompleteSalesReturn == true) {
        return "NA";
      }
      if (shipment?.otp?.status === true) {
        return "Verified";
      } else if (shipment?.otp?.statusFlag === true) {
        return "Trigger";
      } else if (shipment?.otp?.statusFlag === false) {
        return "Trigger OTP After ";
      } else {
        return "NA";
      }
    }
  };
  const triggerPod = async () => {
    try {
      // history.push({
      //   pathname:
      //     "/dashboard/orders/viewinvoice/" +
      //     soId +
      //     "/" +
      //     record.mginvoiceId +
      //     "/" +
      //     customerID +
      //     "/" +
      //     3,
      // });
      const InvoiceId = shipment.invoiceId;
      Otp = "3";
      const location = {
        pathname: `/dashboard/orders/viewinvoice/`,
        state: { soId, InvoiceId, customerID, Otp, shipment, shipmentindex },
      };
      history.push(location);
    } catch (err) {
      console.log("err");
    }
  };
  const getRemainingTime = () => {
    const minutes = shipment?.otp?.pendingTime;
    if (minutes == undefined || minutes < 0) {
      return "";
    }

    if (minutes < 60) {
      return minutes + " Minutes";
    } else {
      var hours = parseInt(minutes / 60);
      var min = minutes % 60;
      if (hours == 1) {
        return hours + " Hour " + min + " Minutes";
      } else {
        return hours + " Hours " + min + " Minutes";
      }
    }
  };
  return (
    <div>
      <div className="m-2 p-3" style={{ backgroundColor: "#FFFFFF" }}>
        <Typography
          style={{
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "22px",
            color: "##3A4960",
            display: "flex",
            flexDirection: "row",
            // marginLeft: "33px",
            marginTop: "10px",
            alignItems: "center",
          }}
        >
          <Divider
            type="vertical"
            style={{
              border: "2px solid #5078F2",
              height: "24px",
            }}
          />
          {title}
        </Typography>
        <div
          className="ml-4 m-3 p-3"
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "-26px",
            }}
          >
            <span style={{ flexDirection: "column" }}>
              <Typography
                style={{
                  color: "#7A7A7A",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "17px",
                }}
              >
                Invoice Number
              </Typography>
              <Typography
                style={{
                  color: "#3A4960",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "34px",
                }}
              >
                #{invoiceno}
              </Typography>
            </span>

            <Divider
              type="vertical"
              style={{
                height: 120,

                border: "1px solid #ECEDEE",
              }}
            />
          </div>
          <div className="w-100">
            <div
              className="d-flex justify-content-between px-5"
              style={{ marginLeft: "-25px" }}
            >
              <div>
                <Typography
                  style={{
                    color: "#7A7A7A",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "15px",
                  }}
                >
                  Payment Status
                </Typography>
                <Typography
                  style={{
                    color: "#3A4960",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "17px",
                    marginTop: "8px",
                  }}
                >
                  {paymentstatus}
                </Typography>
              </div>
              {ConfigData?.deDigitalSignature === true ? (
                <div>
                  <Typography
                    style={{
                      color: "#7A7A7A",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "15px",
                    }}
                  >
                    Digital Sign
                  </Typography>

                  <div style={{ display: "flex", marginTop: "8px" }}>
                    {digitalsign?.status === true ? (
                      <div style={{ display: "flex" }}>
                        <Welcome bgColor="#219653" />
                        <Typography style={{ paddingLeft: "10px" }}>
                          Verified
                        </Typography>
                      </div>
                    ) : digitalsign?.status === false ? (
                      <div style={{ display: "flex" }}>
                        <Welcome bgColor="#E13440" />
                        <Typography style={{ paddingLeft: "10px" }}>
                          Not Verified
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <Welcome bgColor="#E13440" />
                        <Typography style={{ paddingLeft: "10px" }}>
                          NA
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              <div>
                <Typography
                  style={{
                    color: "#7A7A7A",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "15px",
                  }}
                >
                  POD
                </Typography>

                <div style={{ display: "flex", marginTop: "8px" }}>
                  <Typography>
                    {getPODStatus().toUpperCase() === "AVAILABLE" ? (
                      <div style={{ display: "flex" }}>
                        <Welcome bgColor="#219653" />
                        <Typography style={{ marginLeft: "5px" }}>
                          Available
                        </Typography>
                      </div>
                    ) : getPODStatus().toUpperCase() === "UPLOAD" ? (
                      <Button type="link" onClick={() => triggerPod()}>
                        <div style={{ display: "flex", marginLeft: "-15px" }}>
                          <Welcome bgColor="#E13440" />
                          <Typography
                            className="podtext"
                            style={{
                              marginLeft: "5px",
                              textDecoration: "underline",
                              color: "blue",
                            }}
                          >
                            Upload
                          </Typography>
                        </div>
                      </Button>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <Welcome bgColor="#E13440" />
                        <Typography style={{ paddingLeft: "10px" }}>
                          NA
                        </Typography>
                      </div>
                    )}
                  </Typography>
                </div>
              </div>
              {ConfigData?.deOtpStatus === true ? (
                <div>
                  <Typography
                    style={{
                      color: "#7A7A7A",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "15px",
                    }}
                  >
                    OTP
                  </Typography>

                  <div style={{ display: "flex", marginTop: "8px" }}>
                    {getOTPStatus().toUpperCase() === "VERIFIED" ? (
                      <div style={{ display: "flex" }}>
                        <Welcome bgColor="#219653" />
                        <Typography style={{ paddingLeft: "10px" }}>
                          {getOTPStatus()}
                        </Typography>{" "}
                      </div>
                    ) : getOTPStatus().toUpperCase() ===
                      "TRIGGER OTP AFTER " ? (
                      <div style={{ display: "flex", marginLeft: "-1px" }}>
                        <Welcome bgColor="#E13440" />
                        <div style={{ paddingLeft: "10px" }}>
                          <Typography style={{ color: "#979797" }}>
                            {getOTPStatus()}{" "}
                          </Typography>
                          <Typography style={{ color: "#979797" }}>
                            {" "}
                            {getRemainingTime()}
                          </Typography>
                        </div>
                      </div>
                    ) : getOTPStatus().toUpperCase() === "TRIGGER" ? (
                      <Button type="link" onClick={() => triggerOTP()}>
                        <div style={{ display: "flex", marginLeft: "-17px" }}>
                          <Welcome bgColor="#E13440" />
                          <Typography
                            className="podtext"
                            style={{
                              marginLeft: "5px",
                              textDecoration: "underline",
                              color: "blue",
                            }}
                          >
                            Trigger OTP
                          </Typography>
                        </div>
                      </Button>
                    ) : getOTPStatus().toUpperCase() === "NA" ? (
                      <div style={{ display: "flex" }}>
                        <Welcome bgColor="#E13440" />
                        <Typography style={{ paddingLeft: "10px" }}>
                          {getOTPStatus()}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div>
                <Typography
                  style={{
                    color: "#7A7A7A",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "17px",
                  }}
                >
                  Credit note/Debit note
                </Typography>

                <Typography style={{ marginTop: "8px" }}>
                  {creditNote}
                </Typography>
              </div>

              <div style={{ marginRight: "-100px" }}>
                {detailsBtnDisplay() ? (
                  <div style={{ marginTop: "5px" }}>
                    <Button
                      style={{
                        border: "#5078F2 1px solid",
                        width: "100px",
                        height: "46px",
                        color: "#5078F2",
                        fontSize: "14px",
                      }}
                      onClick={(record) => invoiceDetails()}
                    >
                      Detail
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShippmentDetails;
