import React, { Component } from "react";
import "./modal.scss";
import { AsyncPaginate } from "react-select-async-paginate";
import { getMinifiedCustomerList } from "../../services/customerServices/customer";
// err msg & loader
import { message, Spin, Modal, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

class AddCustomerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      page: 1,
      search: "",
      options: [],
      selectedCustomers: [],
      value: [],
      visible: true,
    };
  }
  componentDidMount = () => {};

  selectOption = (e) => {
    console.log("selectOption is called", e);
    if (e != null && e.length > 20) {
      message.warning("You can select up to 10 customers");
      let sliced = e.slice(0, 20);
      this.setState({
        selectedCustomers: sliced.map((customer, i) => {
          return { customerId: customer.value, seq: i + 1, key: i };
        }),
        value: sliced.map((item) => {
          return { value: item.value, label: item.label, key: item.value };
        }),
      });
    } else if (e != null && e.length <= 20) {
      let customers = e;
      let seqCustomers = customers.map((customer, i) => {
        return { customerId: customer.value, seq: i + 1, key: i };
      });

      let displayedList = customers.map((item, i) => {
        let newItem = {
          value: item.value,
          label: item.label,
          key: item.value,
        };
        return newItem;
      });

      this.setState({
        selectedCustomers: seqCustomers,
        value: displayedList,
      });
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  loadOptions = async (_search, loadedOptions, { page }) => {
    let qParams = [{ page }, { search: _search }];

    let data = await getMinifiedCustomerList(qParams).then((res) => {
      return {
        options:
          res.data.data.results &&
          res.data.data.results.map((item) => {
            return {
              key: item.goFrugalId,
              value: item._id,
              label: item.name,
            };
          }),
        totalPage:
          parseInt(res.data.data.pageMeta.total || 0) /
          parseInt(res.data.data.pageMeta.pageSize || 0),
      };
    });

    return {
      options: data.options,
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  newOption = () => {
    let newOptions = this.state.options;
    if (this.state.value != null && this.state.selected.value === 5) {
      newOptions = newOptions;
    }
  };

  render() {
    let { displayModal, addCustomersToBeatplan, value } = this.props;

    let { options, loading, selectedCustomers,visible } = this.state;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
     
      <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <>
          <div id="">
          <div className="customer-modal">
            <p id="title">Adding Customer</p>
             <div className="box mb-2">Select Customer Name</div>
             <Spin indicator={antIcon} spinning={loading}>
              <AsyncPaginate
                value={this.state.value}
                loadOptions={this.loadOptions}
                isMulti={true}
                closeMenuOnSelect={false}
                onChange={(e) => {
                  this.selectOption(e);
                }}
                additional={{
                  page: 1,
                }}
                className="basic-multi-select"
              />
             </Spin>

             <footer>
            <div
              className="btn btn-outline-primary mr-3"
              onClick={displayModal}
            >
              Cancel
            </div>
            <div
              className="btn btn-primary"
              onClick={() => addCustomersToBeatplan(selectedCustomers)}
            >
              Add to Beat Plan
            </div>
          </footer>
           </div>

          </div>
          
          </>
        </Modal>
    );
  }
}

export default AddCustomerModal;
