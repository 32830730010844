import React, { Component } from 'react';
import "./RouteMap.scss";
import Label1 from '../../images/map-pin-1.svg'
import {
	calcDistance,
	validateLatLng,
	calculateETA,
} from '../../services/Utility';
// import { secondsToTime } from '../../helpers/DateTime';
import { isEqual } from 'lodash';

class RouteMap extends Component {
	routeInfo = {};
	stopMarkers = [];

	state = {
		isLoading: false,
		satelliteView: false,
	};

	panTo = (center) => {
		this.center = center;
		this.map.panTo(this.center);
		this.map.setZoom(15);
	};

	// initiate google map with markers
	loadMap = async () => {
		const { id, instance, stops, origin } = this.props;
		console.log('The maps here is ---> ', stops, origin);
		const { satelliteView } = this.state;

		const mapTypeId = satelliteView ? instance.maps.MapTypeId.SATELLITE : instance.maps.MapTypeId.ROADMAP;

		if (!instance) return;

		this.center = {
			lat: origin ? origin.lat : 0,
			lng: origin ? origin.lng : 0,
		};

		this.map = new instance.maps.Map(document.getElementById(id), {
			center: this.center,
			mapTypeControl: false,
			mapTypeId,
			zoom: 10,
			maxZoom: 20,
			minZoom: 10,
			streetViewControl: false,
			gestureHandling: 'cooperative',
			styles: [
				{
					featureType: 'all',
					// stylers: [{ saturation: -50 }]
				},
			],
		});

		this.directionsService = new instance.maps.DirectionsService();
		this.directionsRenderer = new instance.maps.DirectionsRenderer({
			suppressMarkers: false, // to hide default way point markers
		});

		this.directionsRenderer.setMap(null);

		this.setRoute();
	};

	setRoute = async () => {
		this.createWayPointMarkers();

		const { origin, destination, stops = [] } = this.props;
		try {
			if (!this.directionsService) {
				let error = {
					message: 'Directions Service failed to initiate',
					data: this.props,
				};
				throw error;
			}

			this.waypoints = [origin];
			this.waypoints = this.waypoints.concat(stops);
			this.waypoints.push(destination);

			let invalidStops = this.waypoints.some(
				(stop) => !validateLatLng(stop)
			);

			if (invalidStops) {
				let error = {
					message: 'Invalid stop latlongs found',
				};
				throw error;
			}

			var limit = 25;
			var factor = Math.ceil(this.waypoints.length / limit);
			let lastIndex = 0;

			let stopSets = Array(factor)
				.fill(1)
				.map((a, i) => {
					let temp = this.waypoints.slice(lastIndex, (i + 1) * limit);
					lastIndex = (i + 1) * limit - 1;
					return temp;
				});

			let routes = [];

			for (const index in stopSets) {
				let a = await this.getRouteData(stopSets[index]);
				routes.push(a.routes[0]);
			}
			this.drawPolylinesAlongTheRoute(routes);
			this.calculateDistanceWithLegs(routes);
		} catch (err) { }
	};

	getRouteData = (route) => {
		return new Promise((resolve, reject) => {
			this.directionsService.route(
				{
					origin: route[0],
					destination: route[route.length - 1],
					travelMode: 'DRIVING',
					optimizeWaypoints: false,
					waypoints: route
						.slice(1, route.length - 1)
						.map((location) => ({
							location: validateLatLng(location),
						})),
				},
				(response, status) => {
					if (status === 'OK') {
						resolve(response);
					} else {
						console.log(status);
						reject(status);
					}
				}
			);
		});
	};

	calculateDistanceWithLegs = (routes) => {
		if (!routes.length) return;

		const { onChange, stops } = this.props;

		// let duration = 0;
		let distance = 0;
		routes.forEach((route) => {
			route.legs.forEach(({ distance: dist, duration: dura }) => {
				distance += dist.value;
				// duration += dura.value;
			});
		});

		let data = {
			duration: calculateETA(distance, stops.length),
			distance: calcDistance(distance),
		};

		onChange(data);
	};

	createWayPointMarkers = () => {
		try {
			this.deleteMarkers();

			const { stops = [], instance, origin, destination } = this.props;

			if (!validateLatLng(origin) || !validateLatLng(destination)) return;

			this.waypoints = [{ ...origin, locationType: 'O' }];

			this.waypoints = this.waypoints
				.concat(stops)
				.concat([{ ...destination, locationType: 'D' }]);

			this.calculateDistance();

			var originIcon = {
				url: process.env.PUBLIC_URL + '/static/images/map-solid-pin1.svg', // url
				scaledSize: new instance.maps.Size(34, 34),
				labelOrigin: new instance.maps.Point(17, 15),
			};

			var icon = {
				url: process.env.PUBLIC_URL + '/static/images/map-solid-pin.svg', // url
				scaledSize: new instance.maps.Size(34, 34), // scaled size
				labelOrigin: new instance.maps.Point(17, 15),
			};

			this.stopMarkers = this.waypoints.map((m, index) => {
				var infowindow = new instance.maps.InfoWindow({
					content: `<strong class=''>${m.label}</strong>`,
				});

				let marker = validateLatLng(m);

				if (marker) {
					var markerIcon = icon;

					if (m.locationType === 'O') markerIcon = originIcon;
					else if (m.locationType === 'D') markerIcon = originIcon;

					let a = new instance.maps.Marker({
						position: {
							lat: marker.lat,
							lng: marker.lng,
						},
						draggable: false,
						map: this.map,
						icon: markerIcon,
						label: {
							// text: `${index + 1}`,
							text: `${index}`,
							color: '#333',
							fontSize: '12px',
							fontWeight: '700',
						},
					});
					a.addListener('mouseover', function () {
						infowindow.open(this.map, a);
					});

					a.addListener('mouseout', function () {
						infowindow.close(this.map, a);
					});

					return a;
				} else {
					throw 'Invalid Stops found';
				}
			});
			let stopsPathCoordinates = [{ lat: origin.lat, lng: origin.lng }];

			stopsPathCoordinates = stopsPathCoordinates.concat(
				stops.map(({ lat, lng }) => ({
					lat,
					lng,
				}))
			);
			stopsPathCoordinates.push({
				lat: destination.lat,
				lng: destination.lng,
			});
		} catch (e) {
			this.props.onChange({ status: 'FAILED' });
			console.log(e);
		}
	};

	deleteMarkers = () => {
		//Loop through all the markers and remove
		for (var i = 0; i < this.stopMarkers.length; i++) {
			this.stopMarkers[i].setMap(null);
		}

		this.stopMarkers = [];
	};

	// Calculate distance and ETA
	calculateDistance = async () => {
		const { stops, onChange, instance } = this.props;
		this.setState({ isLoading: true });

		onChange({ status: 'LOADING' });

		let i = 0;
		var distance = 0;

		// stopsPath.setMap(this.map);
		var routes = [];

		for (const stop of this.waypoints) {
			if (i + 1 < this.waypoints.length) {
				try {
					let response = await this.distanceMatrix(
						this.waypoints[i],
						this.waypoints[i + 1]
					);

					let legs = response.routes[0].legs;
					routes.push(response.routes[0]);
					legs.forEach(({ distance: dist }) => {
						distance += dist.value;
					});
				} catch (err) {
					onChange({ status: 'FAILED' });
				}

				i++;
			}
		}

		let data = {
			duration: calculateETA(distance, stops.length),
			distance: calcDistance(distance),
			status: 'SUCCESS',
		};

		onChange(data);
	};

	drawPolylinesAlongTheRoute = (routes) => {
		if (!routes.length) return;

		const { instance } = this.props;

		if (this.routepolyline) this.routepolyline.getPath().clear();

		this.routepolyline = new instance.maps.Polyline({
			path: [],
			geodesic: true,
			strokeColor: '#005aff',
			strokeOpacity: 0.5,
			strokeWeight: 5,
		});
		var bounds = new instance.maps.LatLngBounds();

		routes.forEach(({ legs }) => {
			legs.forEach(({ steps }) => {
				for (let j = 0; j < steps.length; j++) {
					var nextSegment = steps[j].path;
					for (let k = 0; k < nextSegment.length; k++) {
						this.routepolyline.getPath().push(nextSegment[k]);
						bounds.extend(nextSegment[k]);
					}
				}
			});
		});

		this.bounds = bounds;

		this.routepolyline.setMap(this.map);
		this.map.fitBounds(bounds);
		this.setState({ isLoading: false });
	};

	distanceMatrix = (origin, destination) => {
		const { instance } = this.props;
		return new Promise((resolve, reject) => {
			var orig = new instance.maps.LatLng(origin.lat, origin.lng);
			var dest = new instance.maps.LatLng(
				destination.lat,
				destination.lng
			);

			var request = {
				origin: orig,
				destination: dest,
				travelMode: 'DRIVING',
			};

			this.directionsService.route(request, function (result, status) {
				if (status === 'OK') {
					if (status === 'OK') {
						setTimeout(() => {
							resolve(result);
						}, 700);
					} else reject({ status, result });
				}
			});
		});
		// let data = {
		// duration: calculateETA(distance, stops.length),
		// distance: calcDistance(distance)
	};

	componentDidMount() {
		this.loadMap();
	}

	componentDidUpdate({ instance, stops, origin, destination, center }) {
		if (this.props.instance && !instance && !this.map) this.loadMap();

		let condition =
			!isEqual(origin, this.props.origin) ||
			!isEqual(destination, this.props.destination) ||
			!isEqual(stops, this.props.stops) ||
			(this.waypoints &&
				this.waypoints.length - 2 !== this.props.stops.length);

		if (condition) {
			this.setRoute();
		}

		if (
			this.props.center &&
			(!isEqual(this.props.center, center) ||
				!isEqual(this.center, this.props.center))
		) {
			this.panTo(this.props.center);
		}
	}

	toggleSatelliteView = (val) => {
		this.setState({ satelliteView: val }, () => {
			const { satelliteView } = this.state;
			const { instance } = this.props;

			const mapTypeId = satelliteView
				? instance.maps.MapTypeId.HYBRID
				: instance.maps.MapTypeId.ROADMAP;
			this.map.setOptions({ mapTypeId });
		});
	};

	resetPosition = () => {
		if (!this.bounds) return;

		this.map.fitBounds(this.bounds);
		this.center = {};
	};

	render() {
		const {
			id,
			instance,
			overlay,
			showInfo = false,
			overlayText = () => 'Some overlay content here',
		} = this.props;

		const { isLoading, satelliteView } = this.state;

		const Overlay = overlayText;
		return (
			<div className="map-container">
				<div>
					<>
						{overlay && !isLoading && (
							<div className="map-overlay">
								<Overlay />
							</div>
						)}

						{isLoading && (
							<div className="map-overlay">
								Please wait while we are plotting the route.
							</div>
						)}
						<button
							onClick={this.resetPosition}
							disabled={isLoading}
							type="button"
							className="reset-bound"
						>
							<i className="ion-ios7-refresh-empty"></i>
						</button>
						<div className="map-type-control">
							<div className="controls">
								<div
									className="btn-group"
									role="group"
									aria-label="First group"
								>
									<button
										type="button"
										className={
											satelliteView
												? 'font-weight-bold button-arrow'
												: 'button-arrow'
										}
										onClick={() =>
											this.toggleSatelliteView(true)
										}
									>
										Satellite
									</button>
									<button
										type="button"
										className={
											!satelliteView
												? 'font-weight-bold button-arrow'
												: 'button-arrow'
										}
										onClick={() =>
											this.toggleSatelliteView(false)
										}
									>
										Map
									</button>
								</div>
							</div>
							<button className="button-arrow">
								<i className="ion-chevron-right"></i>
							</button>
						</div>
						<div className="w-100 h-100" id={id}>
							{!instance && 'No map instance is found!'}
						</div>
					</>
				</div>
			</div>
		);
	}
}

RouteMap.defaultProps = {
	id: 'routeMap',
	origin: null,
	destination: null,
	onChange: () => { },
	stops: [],
};

export default RouteMap;
