import React, { useState } from "react";

import { Typography, Form, Input, Button, Modal } from "antd";

// import "./balancecreation.scss";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../action/balanceCrestionAction";

import { useParams, useHistory } from "react-router-dom";
import { set } from "lodash";
import OtpInput from "react-otp-input";
import { getOtp } from "../../services/deleteUser/deleteUserServices";
import { Checkbox } from 'antd';
import { deleteUserService } from "../../services/deleteUser/deleteUserServices";

function Preview() {

  const [buttondisable, setButtonDisable] = useState(true);
  const [outstanding, setOutstanding] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const deleteUser = async () => {
      try {
        const res = await deleteUserService();
        if (res.status == 200) {
            setSubmitted(true) ;
          if (res.data.status == 200) {
            setOutstanding(false)
          } else if (res.data.status == 400) { 
            if(res.data.error == "Deleted Customer") {
                setIsDeleted(true);
            }       
            setOutstanding(true)
        }
        }
      } catch (err) {
        console.log(err, "err");
      }
  };
  const onCancelRequest = (e) => {
    const location = {
      pathname: `/cancelRequest`,
    };
    history.push(location);
   }
  const onChange = () =>{
    setButtonDisable(!buttondisable)
  }
 
  return (
    <div
    className="example"
    style={{
      //marginTop: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",

      overflow: "hidden",
      scrollBehavior: "none",
      position: "fixed",
    }}
  >
    { isSubmitted == false ? 
        <div>
        <Typography
          style={{ fontWeight: 700, fontSize: "24px", lineHeight: "41px", marginLeft:"108px", color:"red" }}
        >
          Disclaimer
        </Typography>
        <div>
          <div style={{ display: "flex", gap: "30px", marginTop: "15px", marginLeft:"5px", }}>
          <Typography
          style={{ fontWeight: 100, fontSize: "8px", lineHeight: "12px", marginLeft:"5px", }}
        >
          <ol>
            <li>Deleting your account is a permanent action and cannot be undone. All of your data, including personal information, content, and any other associated materials, will be permanently deleted.</li>
            <li>Once your account is deleted, you will no longer be able to avail any of our services.</li>
            <li>Account deletion does not exempt you from any outstanding financial obligations or legal responsibilities.</li>
            <li>Ensure that all dues and commitments are settled before requesting deletion. <p style={{fontWeight:"bold"}}>
               Deletion request wont be processed in case of any outstanding amount/dues.</p></li>
            <li>In accordance with our privacy policy and legal obligations, certain information may be retained. This information will be limited to what is necessary for compliance with legal and regulatory requirements.</li>   
          </ol>         

        </Typography>
          </div>
          {/* <div style={{ display: "flex", gap: "30px", marginLeft:"5px", }}>
          <Typography
          style={{ fontWeight: 700, fontSize: "14px", lineHeight: "25px", marginLeft:"5px", }}
        >
          2. Deletion request wont be processed in <br></br>
          case of any outstanding pending amount to be paid.
  
        </Typography>
          </div> */}
          
        </div>
        <div style={{ display: "flex", justifyContent: "left",marginTop:"5px", marginLeft:"10px" }}>
        <Checkbox onChange={onChange}><p style={{color:"red"}}>I agree to the above terms and conditions</p></Checkbox>
        </div>
        <div style={{ display: "flex", justifyContent: "left",marginTop:"0px",marginLeft:"80px" }}>
          <Button
            type="link"
            onClick={() => deleteUser()}
            disabled={buttondisable}
          >
            Delete My Account
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "left",marginTop:"0px",marginLeft:"80px" }}>
          <Button
            onClick={() => onCancelRequest()}
          >
            Cancel My Request
          </Button>
        </div>
      </div>: outstanding == true ?
      <div>
        <Typography
          style={{ fontWeight: 200, fontSize: "12px", lineHeight: "21px", marginLeft:"5px",color:"red" }}
        >
            { isDeleted == true ? `Your account delete request has been submitted already`:`Please ensure all outstanding dues are cleared before attempting to delete your account again.`}
          
        </Typography>
      </div>:<div>
      <Typography
          style={{ fontWeight: 400, fontSize: "11px", lineHeight: "41px", marginLeft:"5px",color:"green" }}
        >
          Your account delete request has been submitted successfully
  
        </Typography>
      </div>
    }
  </div>
  );
}

export default Preview;
