import React from "react";
import DayPicker from "./dayPicker";
import downArrow from "../../images/down-arrow.svg";
const RowWithOptions = ({
  viewType,
  showSaveAsDraftBtn,
  mapView,
  showSaveAsDraftBtnForDailyView,
  showType,
  publishPlan,
  selectDay,
  saveAsDraft,
  saveAsDraftForDayView,
  updatedAt,
  publishedTime
}) => {
  return (
    <>
      <div className="row _View">
        <div className={viewType == "Weekly" ?"col-4 view pl-0":"col-3 view pl-0"}>
          View:{" "}
          <span className="viewType" onClick={showType}>
            {viewType} <img src={downArrow} alt="v" />
          </span>
        </div>
        {/* Weekly displayed option starts*/}
        {viewType == "Weekly" && showSaveAsDraftBtn == true && (
          <div className="col-4">
            <button
              className="draftsPageBtn"
              onClick={saveAsDraft}
              title="information about this button"
            >
              Save as Draft
            </button>
          </div>
        )}
        {viewType == "Weekly" && showSaveAsDraftBtn == false && (
          <div className="col-4">
            <p className="note p-0 mb-0 ml-0">
              Note : Drag customer list to shuffle
            </p>
          </div>
        )}

        {viewType == "Weekly" && (
          <div class="col-4">
            <button className="draftsPageBtn" onClick={publishPlan}>
              Publish in Day’s Plan
            </button>
          </div>
        )}

        {/* Weekly displayed option ends*/}

        {/* daily display option starts*/}
        {viewType === "Daily" && (
          <div class="col-3">
            <DayPicker selectDay={selectDay} />
          </div>
        )}
        {viewType == "Daily" && showSaveAsDraftBtnForDailyView == false && (
          <div class="col-3">
            <p className="note p-0 mb-0 ml-0">
              Note : Drag customer list to shuffle
            </p>
          </div>
        )}

        {viewType == "Daily" && showSaveAsDraftBtnForDailyView == true && (
          <div class="col-3">
            <button
              className="draftsPageBtn"
              onClick={saveAsDraftForDayView}
              title="information about this button"
            >
              Save as Draft
            </button>
          </div>
        )}
        {viewType == "Daily" && (
          <div class="col-3">
            <button className="draftsPageBtn" onClick={publishPlan}>
              Publish in Day’s Plan
            </button>
          </div>
        )}
        {/* daily display option ends*/}
      </div>
      <div className="row mb-0">
        <div className="col col-6">
          Last Updated: <span>{updatedAt && updatedAt}</span>{" "}
        </div>
        <div className="col col-6">
          Last Published: <span>{publishedTime && publishedTime}</span>{" "}
        </div>
      </div>
    </>
  );
};

export default RowWithOptions;
