import React, { Component } from "react";
import "./planDetails.scss";
import info from "./imgs/info.svg";
import Toggle from "../../images/Toggle.svg";
import inactiveToggle from "../../images/inactive-toggle.svg";
import plus from "./imgs/plus.svg";
import userlogo from "./imgs/user-logo.svg";
import AddCustomerModal from "../modal/addCustomerModal";
import CustomerDetails from "../modal/customerDetails";
import cross from "../../images/cross.svg";
class PlanDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayModalState: false,
      displayCustomerDetailsModal: false,
      activePlan: true,
    };
  }

  displayModal = () => {
    this.setState({
      displayModalState: !this.state.displayModalState,
    });
  };

  openCustomerDetailsModal = () => {
    this.setState({
      displayCustomerDetailsModal: !this.state.displayCustomerDetailsModal,
    });
  };
  changeStatus = () => {
    this.setState({
      activePlan: !this.state.activePlan,
    });
  };
  render() {
    let days = [
      {
        day: "Sunday",
        customersName: [
          "Carter Stanton",
          "Carla Lubin",
          "Ruben Geidt",
          "Madelyn Septimus",
        ],
      },
      {
        day: "Monday",
        customersName: [
          "Carter Stanton",
          "Carla Lubin",
          "Ruben Geidt",
          "Madelyn Septimus",
        ],
      },
      {
        day: "Tuesday",
        customersName: [
          "Carter Stanton",
          "Carla Lubin",
          "Ruben Geidt",
          "Madelyn Septimus",
        ],
      },
      {
        day: "Wednesday",
        customersName: [
          "Carter Stanton",
          "Carla Lubin",
          "Ruben Geidt",
          "Madelyn Septimus",
        ],
      },
      {
        day: "Thursday",
        customersName: [
          "Carter Stanton",
          "Carla Lubin",
          "Ruben Geidt",
          "Madelyn Septimus",
        ],
      },
      {
        day: "Friday",
        customersName: [
          "Carter Stanton",
          "Carla Lubin",
          "Ruben Geidt",
          "Madelyn Septimus",
        ],
      },
      {
        day: "Saturday",
        customersName: [
          "Carter Stanton",
          "Carla Lubin",
          "Ruben Geidt",
          "Madelyn Septimus",
        ],
      },
    ];
    let id = 1234;
    return (
      <div className="planDetails component-container">
        <header className="Header">
          <span className="span1">
            Beat Plan# {id}
            <img src={info} alt="info" className="cursor info-logo" />
          </span>
          <span className="span2" onClick={this.changeStatus}>
            <p className="toggleButtonWidth">
              {this.state.activePlan == true ? "Active" : "Inactive"}
            </p>
            <img
              src={this.state.activePlan == true ? Toggle : inactiveToggle}
              alt="toggle"
              className="cursor"
            />
          </span>
        </header>

        <div className="parts">
          {days.map((day) => {
            return (
              <div className="day">
                <div className="dayName">{day.day}</div>
              </div>
            );
          })}
        </div>
        <hr />
        <div className="parts">
          {days.map((day) => {
            return (
              <div className="day">
                <React.Fragment>
                  <div className="rectangle">
                    <img
                      src={plus}
                      onClick={this.displayModal}
                      title="add customer"
                    />
                    <img src={plus} alt="M" title="remove customers" />
                  </div>
                  <div className="customer-rectangle">
                    {day.customersName.map((name) => {
                      return (
                        <div className="name-rectangle">
                          <span
                            onClick={this.openCustomerDetailsModal}
                            title="click to know customer details"
                          >
                            <img src={userlogo} />
                            {name}{" "}
                          </span>
                          <span className="remove-user" title="remove customer">
                            <img src={cross} alt="X" />
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              </div>
            );
          })}
        </div>
        {this.state.displayModalState && (
          <AddCustomerModal displayModal={this.displayModal} />
        )}
        {this.state.displayCustomerDetailsModal && (
          <CustomerDetails
            openCustomerDetailsModal={this.openCustomerDetailsModal}
          />
        )}
      </div>
    );
  }
}

export default PlanDetails;
