const getRequiredDevApis = () => {
  return {
    base_url: "https://dev-api-dms.censanext.com/",
    login_url: "https://dev-api-dms.censanext.com/",
    pdWaycool_url: "https://uat-api.censanext.com/",
    revamp_base_url: "https://dev-api-wcrevamp-client.censanext.com/",
    invoice_url: "https://dev-api-logistics.censanext.com/",
    generate_so_url: "https://dev-api-censamaster.censanext.com/",
    azure_func: "https://analytics-adhoc.azurewebsites.net/",
    Sap_url: "https://dev-api-censamaster.censanext.com/",
  };
};
const getRequiredUatApis = () => {
  return {
    base_url: "https://uat-api-dms.censanext.com/",
    login_url: "https://uat-api-dms.censanext.com/",
    pdWaycool_url: "https://uat-api.censanext.com/",
    revamp_base_url: "https://uat-api-wcrevamp-client.censanext.com/",
    invoice_url: "https://uat-api-logistics.censanext.com/",
    generate_so_url: "https://uat-api-censamaster.censanext.com/",
    azure_func: "https://analytics-adhoc.azurewebsites.net/",
    Sap_url: "https://uat-api-censamaster.censanext.com/",
  };
};
const getRequiredPreProdApis = () => {
  return {
    base_url: "https://preprod-api-dms.censanext.com/",
    login_url: "https://preprod-api-dms.censanext.com/",
    pdWaycool_url: "https://uat-api.censanext.com/",
    revamp_base_url: "https://preprod-api-wcrevamp.censanext.com/",
    invoice_url: "https://preprod-api-logistics.censanext.com/",
    generate_so_url: "https://preprod-api-censamaster.censanext.com/",
    azure_func: "https://analytics-adhoc.azurewebsites.net/",
    Sap_url: "https://preprod-api-censamaster.censanext.com/",
  };
};
const getRequiredProdApis = () => {
  return {
    base_url: "https://api-dms.censanext.com/",
    login_url: "https://api-dms.censanext.com/",
    pdWaycool_url: "https://api.censanext.com/",
    revamp_base_url: "https://revampapi.censanext.com/",
    invoice_url: "https://api-logistics.censanext.com/",
    generate_so_url: "https://api-censamaster.censanext.com/",
    azure_func: "https://analytics-adhoc.azurewebsites.net/",
    Sap_url: "https://api-censamaster.censanext.com/",

  };
};

//Dev Environment
const dev = getRequiredDevApis();

//UAT Environment
const uat = getRequiredUatApis();

//Pre Prod Environment
const preProd = getRequiredPreProdApis();

//Production Environment
const production = getRequiredProdApis();

let configVariables = {};

//Change the config for production and development
switch (process.env.REACT_APP_BUILD_ENV) {
  case "dev":
    configVariables = {
      ...dev,
    };
    break;
  case "uat":
    configVariables = {
      ...uat,
    };
    break;
  case "pre-prod":
    configVariables = {
      ...preProd,
    };
    break;
  case "production":
    configVariables = {
      ...production,
    };
    break;
  default:
    configVariables = {
      ...production,
    };
    break;
}

// ecporting the defaults
export default {
  ...configVariables,
};
