import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";
import { handleErrorResponse } from "../../utils/Request";

export const getProfileDetails = async () => {
  try{
    let request = await axios.get(APIConfig.base_url + "api/v1/user/details", {
      headers: {
        "x-access-token": getAuthToken(),
      },
    });
    return request;
  }catch(err){
    const { errorMessage } = handleErrorResponse(err);
  }
  
};

