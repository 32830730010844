import { Avatar } from "antd";
import React from "react";
import profilePic from "../../../../src/images/default-pic.jpg";

const ItemNameCard = (props) => {
  const { imageUrl, itemName, itemId, flag } = props;
  return (
    <div className="d-flex">
      <Avatar size={60} shape="square" src={imageUrl ?? profilePic} />
      <div className="pl-2">
        <h6>{itemName ?? "ItemName"}</h6>
        <small className="">Item ID {itemId ?? "ItemId"}</small>
        <br />
        {flag && <small>{flag ?? "ItemName"}</small>}
      </div>
    </div>
  );
};

export default ItemNameCard;
