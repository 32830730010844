import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";

// create beat plan

export const createBeatPlan = async (inputs) => {
  let url = "api/v1/beat-plan/draft/new";
  let request = await axios.post(
    APIConfig.base_url + url,
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};

// get all drafted beatplans

export const getDraftedBeatplanList = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "api/v1/beat-plan/draft" + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// delete draft beat plan by id
export const removeDraftBeatPlan = async (id) => {
  let request = await axios.delete(
    APIConfig.base_url + `api/v1/beat-plan/draft/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// get draft beat plan details by id

export const getDraftBeatPlanDetailsById = async (id) => {
  console.log("called api");
  let request = await axios.get(
    APIConfig.base_url + `api/v1/beat-plan/draft/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// add a day to beat plan
export const addDayToBeatPlan = async (id, day) => {
  console.log("addDayToBeatPlan is called, id, day", id, day);
  let request = await axios.patch(
    APIConfig.base_url + `api/v1/beat-plan/draft/${id}/day-mapping`,
    {
      addDay: day,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// remove a day to beat plan
export const removeDayToBeatPlan = async (id, day) => {
  console.log("removeDayToBeatPlan is called, id, day", id, day);
  let request = await axios.patch(
    APIConfig.base_url + `api/v1/beat-plan/draft/${id}/day-mapping`,
    {
      deleteDay: day,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// publish beatplan
export const publishDraftedBeatplan = async (id) => {
  let request = await axios.get(
    APIConfig.base_url + `api/v1/beat-plan/draft/${id}/publish`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// change salesman of a beatplan
export const changeSalesmanOfBeatplan = async (salesmanId, beatplanId) => {
  let request = await axios.patch(
    APIConfig.base_url +
      `api/v1/beat-plan/draft/${beatplanId}/change/salesman/${salesmanId}
    `,
    {},
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// get all published beatplans

export const getPublishedBeatplanList = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "api/v1/beat-plan/published" + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
// delete publish beat plan by id
export const removePublishBeatPlan = async (id) => {
  let request = await axios.delete(
    APIConfig.base_url + `api/v1/beat-plan/published/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
// add customers to a day of beatplan .It will also be using for deleting customer
export const addCustomersToBeatPlan = async (id, inputs) => {
  let request = await axios.patch(
    APIConfig.base_url + `api/v1/beat-plan/draft/${id}/customer-mapping`,
    inputs,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// Edit Customer Mapping Day-Wise
export const editCustomerMappingDaywise = async (beatPlanId, dayId, inputs) => {
  let request = await axios.patch(
    APIConfig.base_url +
      `api/v1/beat-plan/draft/${beatPlanId}/day/${dayId}/customer-mapping`,
    inputs,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// get particular day details
export const getParticularDayDetails = async (_day, beatPlanId) => {
  let request = await axios.get(
    APIConfig.base_url + `api/v1/beat-plan/draft/${beatPlanId}/day/${_day}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
// change salesman of a beatplan
export const changetoggleStatus = async (beatplanId, status) => {
  let request = await axios.patch(
    APIConfig.base_url +
      `api/v1/beat-plan/draft/${beatplanId}/status/${status}`,
    {},
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// get published beat plan details by id

export const getPublistedBeatPlanDetailsById = async (id) => {
  let request = await axios.get(
    APIConfig.base_url + `api/v1/beat-plan/published/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// Delete customer using customer id from published beat plan
export const removeCustomerFromPublishedBeatPlan = async (
  beatPlanId,
  customerId
) => {
  let request = await axios.delete(
    APIConfig.base_url +
      `api/v1/beat-plan/published/${beatPlanId}/customer/${customerId}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
// add ad-hoc customer to published beat plan
export const addAdhocCustomer = async (inputs, id) => {
  let url = `api/v1/beat-plan/published/${id}/adhoc-customers`;
  let request = await axios.post(
    APIConfig.base_url + url,

    inputs,
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};

// get maxTimeToPublish time details
export const getMaxTimeToPublishDetails = async () => {
  let request = await axios.get(
    APIConfig.base_url +
      "api/v1/beat-plan/configurable-values?type=maxTimeToPublish",
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// get onboarded customer list by salesman id
export const getOnboardedCustomerList = async (qParams, id) => {
  // ?page=1&search=&sortBy=
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
      `api/v1/employee/salesman/${id}/onbaorded-customers/list` +
      qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
// CSV Template Download
export const csvTemplateDownload = async () => {
  let request = await axios.get(
    APIConfig.base_url + `v1/beat-plan/draft/template/csv`,
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "text/csv",
      },
    }
  );
  return request;
};

// Bulk Upload
export const bulkUpload = async (file) => {
  let url = `api/v1/beat-plan/draft/template/csv`;
  let formData = new FormData();
  formData.append("file", file);
  let request = await axios.post(
    APIConfig.base_url + url,
    formData,

    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};
//  Download beatplans list
export const downloadBeatplans = async () => {
  let request = await axios.get(
    APIConfig.base_url + `api/v1/beat-plan/draft/format/csv`,
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "text/csv",
      },
    }
  );
  return request;
};
