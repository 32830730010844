import { actionTypes } from "../action/cod";
const { GET_ALL_PENDING_SO, GET_ALL_REQUESTED_SO, GET_ALL_REJECTED_SO } =
  actionTypes;

const initialState = {
  pendingSoList: [],
  requestedSoList: [],
  rejectedSOList: [],
};

export default function cod(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PENDING_SO:
      return { ...state, pendingSoList: [...action.data] };
    case GET_ALL_REQUESTED_SO:
      return { ...state, requestedSoList: [...action.data] };
    case GET_ALL_REJECTED_SO:
      return { ...state, rejectedSOList: [...action.data] };
    default:
      return state;
  }
}
