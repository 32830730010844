import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";
import moment from "moment";

//const dateOfDelivery = moment(localStorage.getItem("SelectedDeliveryDate")).format("YYYY-MM-DD");

export const getCartItems = async (CustomerId) => {
  let url = `api/v1/cart/myCart?dateOfDelivery=${moment(localStorage.getItem("SelectedDeliveryDate")).format("YYYY-MM-DD")}`;
  let request = await axios.get(APIConfig.revamp_base_url + url, {
    headers: {
      authorization: getAuthToken(),
      portaltype: "distributor",
      customerid: CustomerId,
    },
  });
  return request;
};

export const addToCart = async (reqData) => {
  let url = `api/v1/cart/addtocart`;
  let request = await axios.post(
    APIConfig.revamp_base_url + url,
    {
      ...reqData,
    },
    {
      headers: {
        authorization: getAuthToken(),
        portaltype: "distributor",
        customerid: reqData.customerId,
      },
    }
  );
  return request;
};

export const deleteCartItemService = async (CustomerId, cart_items) => {
  let url = `api/v1/cart/deletefromcart`;
  let request = await axios.post(
    APIConfig.revamp_base_url + url,
    {
      cart_items,
    },
    {
      headers: {
        authorization: getAuthToken(),
        portaltype: "distributor",
        customerid: CustomerId,
      },
    }
  );
  return request;
  // return { data: [], status: 200 };
};
export const clearCartService = async (CustomerId, cart_items) => {
  let url = `api/v1/cart/deletefromcart`;
  let request = await axios.post(
    APIConfig.revamp_base_url + url,
    {
      flag1: 1,
    },
    {
      headers: {
        authorization: getAuthToken(),
        portaltype: "distributor",
        customerid: CustomerId,
      },
    }
  );
  return request;
  // return { data: [], status: 200 };
};
