import React, { Component } from "react";
import "./team.scss";
import search from "../../images/white-search-icon.svg";
import { withAsyncPaginate } from "react-select-async-paginate";
import { AsyncPaginate } from "react-select-async-paginate";
import Creatable from "react-select/creatable";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { handleErrorResponse } from "../../utils/Request";
import { getProfileDetails } from "../../services/userProfileServices/userProfile";
import { getDistributorOptions } from "../../services/teamServices/team";
import Select from "react-select";
import AsmSearch from "../../images/AsmSearch.png";
import history from "../../utils/History";

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

class Employer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAgencyId: "",
      createNewAgency: "",
      userDetails: {},
      selectedOptions: [],
      distributorsList: [],
      newOptions: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.setState({ newOptions: this.props.options }, () => {});
    }
  }

  componentDidMount() {
    this.getUserDetails();
    this.getDistributorList();
  }
  getUserDetails = async () => {
    await getProfileDetails()
      .then((res) => {
        if (
          res.data.data.isStandalone != true &&
          res.data.data.accessRole == 0
        ) {
          window.location = `/dashboard/accessDenaid`;
          history.push({
            pathname: `/dashboard/accessDenaid`,
          });
        }
        this.setState({
          loading: false,
          asmApprovalRequired:
            res.data.data && res.data.data.asmApprovalRequired,
          userDetails: res.data.data,
        });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        this.setState({ loading: false });
      });
  };
  handleSelect = (data) => {
    this.setState({
      selectedOptions: data.value,
    });
    this.props.selectDistributor(data);
  };
  handleEmployeeType = (data) => {
    this.setState({
      selectedOptions: [],
    });
    this.props.selectEmployeeType(data);
  };
  getDistributorList = async () => {
    await getDistributorOptions()
      .then((res) => {
        this.setState({
          distributorsList:
            res.data.data &&
            res.data.data.map((list) => {
              return {
                key: list.distributorId,
                value: list.distributorId,
                label: list.nameToDisplay,
                cityId: list.cityId,
              };
            }),
        });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        console.log(err);
      });
  };

  render() {
    const optionList = [
      { value: "red", label: "Red" },
      { value: "green", label: "Green" },
      { value: "yellow", label: "Yellow" },
      { value: "blue", label: "Blue" },
      { value: "white", label: "White" },
    ];

    const { userDetails } = this.state;
    const dynamic = [
      userDetails && userDetails.isStandalone,
      userDetails && userDetails.warehouse && userDetails.warehouse.isHybrid,
    ].every((ele) => ele === true);

    const companyCode = userDetails && userDetails.companyCode;
    const accessRole = userDetails && userDetails.accessRole;
    const isStandalone = userDetails && userDetails.isStandalone;

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let {
      empId,
      employeeType,
      companyName,
      selectEmployeeType,
      selectDistributor,
      searchById,
      handleChange,
      updateCompanyName,
      isEditPage,
      showCompanyEmpDetails,
      options,
      selectedAgency,
      onCreateOption,
      clearable,
      showButton,
      error,
    } = this.props;

    return (
      <div className="add-asm-container">
        {isEditPage == false ? (
          <div className="employer-container">
            <p className="employer-container-left">Employer </p>
            <div className="employer-container-right">
              <p className="">SELECT EMPLOYER </p>
              <div className="">
                {/* <div className="right_side_div"> */}
                {isStandalone != true ? (
                  <button
                    title="Click"
                    id="waycool-emp-btn"
                    className={
                      employeeType == "waycool"
                        ? "empBtn empBtnActive"
                        : "empBtn"
                    }
                    name="waycool"
                    // onClick={(e) => selectEmployeeType(e)}
                    onClick={(e) => this.handleEmployeeType(e)}
                  >
                    {" "}
                    Waycool Foods and Products Pvt. Ltd.
                  </button>
                ) : (
                  ""
                )}
                <button
                  title="Click"
                  id="others-emp-btn"
                  className={
                    employeeType == "other" ? "empBtn empBtnActive" : "empBtn"
                  }
                  name="other"
                  //  onClick={(e) => selectEmployeeType(e)}
                  onClick={(e) => this.handleEmployeeType(e)}
                >
                  Others
                </button>
                {/* </div> */}
                {/* if user select waycool display the below div  */}
                {employeeType == "waycool" && (
                  <div className="mt-4">
                    {accessRole == 1 && isStandalone != true ? (
                      <div>
                        <div className="">Distributor</div>
                        <div style={{ width: "330px" }} className="mt-2">
                          <Select
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            options={this.state.distributorsList}
                            placeholder="Select the distributor"
                            selected={this.state.selectedOptions}
                            onChange={this.handleSelect}
                            // onChange={(e) => selectDistributor(e)}
                            isSearchable={true}
                            // isClearable={true}
                          />
                          <img className="Distributor-search" src={AsmSearch} />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {isStandalone == true ? (
                      <div>
                        <p className="mt-3">Enter Employer ID</p>
                        <div className="right_side_div">
                          <input
                            type="text"
                            placeholder="Enter Employee Id"
                            name="empId"
                            value={empId}
                            id="border-none"
                            onChange={handleChange}
                            className="enter-id textbox"

                            // maxLength={12}
                          />

                          <button
                            onClick={searchById}
                            id="search-btn"
                            disabled={error}
                            className="btn btn-primary"
                          >
                            <img
                              // style={{ marginTop: "-5px" }}
                              src={search}
                              alt="search"
                            />
                          </button>
                        </div>
                      </div>
                    ) : this.state.selectedOptions.length > 0 ? (
                      <div>
                        <p className="mt-3">Enter Employer ID</p>
                        <div className="right_side_div">
                          <input
                            type="text"
                            placeholder="Enter Employee Id"
                            name="empId"
                            value={empId}
                            id="border-none"
                            onChange={handleChange}
                            className="enter-id textbox"

                            // maxLength={12}
                          />

                          <button
                            onClick={searchById}
                            id="search-btn"
                            disabled={error}
                            className="btn btn-primary"
                          >
                            <img
                              // style={{ marginTop: "-5px" }}
                              src={search}
                              alt="search"
                            />
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {error && <p style={{ color: "red" }}>{error}</p>}
                  </div>
                )}
                {/* if user select other display the below div  */}
                {employeeType == "other" && (
                  <div className="mt-4">
                    {accessRole == 1 && isStandalone != true ? (
                      <div>
                        <div className="">Distributor</div>
                        <div style={{ width: "330px" }} className="mt-2">
                          <Select
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            options={this.state.distributorsList}
                            placeholder="Select the distributor"
                            selected={this.state.selectedOptions}
                            onChange={this.handleSelect}
                            // onChange={(e) => selectDistributor(e)}
                            isSearchable={true}
                            // isClearable={true}
                          />
                          <img className="Distributor-search" src={AsmSearch} />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {isStandalone == true ? (
                      <div className="mt-4">
                        <div className="">Agency Name </div>
                        <div className="special-dropdown">
                          <CreatableAsyncPaginate
                            key={JSON.stringify(this.state.newOptions)}
                            isClearable={true}
                            isSearchable={true}
                            onCreateOption={onCreateOption}
                            options={this.state.newOptions}
                            onChange={selectedAgency}
                          />
                        </div>
                      </div>
                    ) : this.state.selectedOptions.length > 0 ? (
                      <div className="mt-4">
                        <div className="">Agency Name </div>
                        <div className="special-dropdown">
                          <CreatableAsyncPaginate
                            isClearable={true}
                            isSearchable={true}
                            onCreateOption={onCreateOption}
                            options={options}
                            onChange={selectedAgency}
                          />
                        </div>
                        {showButton && (
                          <div
                            className="col col-md-6 refresh"
                            onClick={() => window.location.reload(false)}
                          >
                            Please refresh the page to get the newly added
                            option in the dropdown
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="emp-name">Employer Details</div>
            <div className="details">
              <p className="emp-id">EMPLOYEE ID : </p>
              <p className="emp-id-data">{empId && empId}</p>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Employer;
