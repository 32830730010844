import { actionTypes } from "../action/beatplan";
const { GET_ALL_DRAFT_BEATPLANS, GET_SINGLE_DRAFT_BEAT_PLAN_BY_ID } = actionTypes;

const initialState = {
 draftBeatPlans:[],
 getSingleDraftBeatPlanById:{}
 
};

export default function beatPlans(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_DRAFT_BEATPLANS:
      return {...state, draftBeatPlans:[...action.data]}

      case GET_SINGLE_DRAFT_BEAT_PLAN_BY_ID:
      return {...state, getSingleDraftBeatPlanById:{...action.data}}
    default:
      return state;
  }
}
