import React, { Component } from "react";
import SearchBar from "../search-bar/searchBar";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { Spin, Pagination, Tabs, DatePicker, Space, Modal } from "antd";
import plus from "../../images/plus.svg";
import PrimaryHeader from "../header/PrimaryHeader";
import { Link } from "react-router-dom";
import "./cod.scss";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import CreditLimitExtension from "./../modal/creditLimitExtension";
import {
  getAllSo,
  downloadPendingCod,
  getGlobalStatus,
} from "../../services/cod/cod";
import { formatMoney, stringSortingByChar } from "../../utils/common";
import { saveAs } from "file-saver";
import history from "../../utils/History";
import { Alert } from "antd";
import alertTringle from "../../images/alert-triangle.svg";
import crossCircle from "../../images/cross-circle.svg";
import alert from "../../images/alert-triangle.png";
const { TabPane } = Tabs;
const dateFormat = "DD/MM/YYYY";

class codList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tab: "pending",
      showCreditLimitExtensionModal: false,
      fromDate: new Date(),
      toDate: "",
      page: 1,
      totalPage: 0,
      pageSize: 0,
      totalItem: 0,
      modalData: "",
      pendingSearch: "",
      requestedSearch: "",
      rejectedSearch: "",
      pendingStatusModal: false,
      failedStatusModal: false,
      creditExtStatus: "",
      statusMsg: "",
    };
  }

  onChangeTab = (key) => {
    this.setState(
      {
        tab: key,
      },
      () => {
        this.callListApi(key);
      }
    );
  };

  getPendingSoDetails = async () => {
    this.setState({ loading: true });
    let { page, fromDate, toDate, pendingSearch } = this.state;
    let { getAllPendingSo } = this.props;
    let qParams = [
      { page },
      { fromDate: fromDate },
      { toDate: toDate },
      { creditExtensionStatus: "pending" },
      { search: pendingSearch },
    ];
    await getAllSo(qParams)
      .then((res) => {
        this.setState({
          loading: false,
          totalPage: res.data.data.pageMeta.total,
          pageSize: res.data.data.pageMeta.pageSize,
          totalItems: res.data.data.pageMeta.total,
        });

        getAllPendingSo(res.data.data.results);
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  getRequestedSoDetails = async () => {
    this.setState({ loading: true });
    let { page, fromDate, toDate, requestedSearch } = this.state;
    let { getAllRequestedSo } = this.props;
    let qParams = [
      { page },
      { fromDate: fromDate },
      { toDate: toDate },
      { creditExtensionStatus: "approved" },
      { search: requestedSearch },
    ];
    await getAllSo(qParams)
      .then((res) => {
        this.setState({
          loading: false,
          totalPage: res.data.data.pageMeta.total,
          pageSize: res.data.data.pageMeta.pageSize,
          totalItems: res.data.data.pageMeta.total,
        });

        getAllRequestedSo(res.data.data.results);
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  getRejectedSoDetails = async () => {
    console.log("rejected details");
    this.setState({ loading: true });
    let { page, fromDate, toDate, rejectedSearch } = this.state;
    let { getAllRjectedSo } = this.props;
    let qParams = [
      { page },
      { fromDate: fromDate },
      { toDate: toDate },
      { creditExtensionStatus: "rejected" },
      { search: rejectedSearch },
    ];
    await getAllSo(qParams)
      .then((res) => {
        this.setState({
          loading: false,
          totalPage: res.data.data.pageMeta.total,
          pageSize: res.data.data.pageMeta.pageSize,
          totalItems: res.data.data.pageMeta.total,
        });

        getAllRjectedSo(res.data.data.results);
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  callListApi = async (key) => {
    this.setState({
      loading: true,
    });
    if (key === "pending") {
      this.getPendingSoDetails();
    } else if (key === "approved") {
      this.getRequestedSoDetails();
    } else if (key === "rejected") {
      this.getRejectedSoDetails();
    } else {
      console.log("no");
    }
  };

  handlePendingChange = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.callListApi(this.state.tab);
      }
    );
  };

  handleRequestedChange = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.callListApi(this.state.tab);
      }
    );
  };

  handleRejectedChange = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.callListApi(this.state.tab);
      }
    );
  };

  onPendingSearch = (e) => {
    this.setState(
      {
        pendingSearch: e.target.value,
        page: 1,
      },
      () => {
        this.callListApi(this.state.tab);
      }
    );
  };

  onRequestedSearch = (e) => {
    this.setState(
      {
        requestedSearch: e.target.value,
        page: 1,
      },
      () => {
        this.callListApi(this.state.tab);
      }
    );
  };

  onRejectedSearch = (e) => {
    this.setState(
      {
        rejectedSearch: e.target.value,
        page: 1,
      },
      () => {
        this.callListApi(this.state.tab);
      }
    );
  };

  selectDateRange = (dates) => {
    if (dates !== null) {
      this.setState(
        {
          fromDate: moment(dates[0], "DD/MM/YYYY").format("DD/MM/YYYY"),
          toDate: moment(dates[1], "DD/MM/YYYY").format("DD/MM/YYYY"),
          page: 1,
        },
        () => {
          this.getStatusKey();
          this.callListApi(this.state.tab);
        }
      );
    } else {
      let today = new Date();
      if (today !== null) {
        this.setState(
          {
            fromDate: moment(today, "DD/MM/YYYY").format("DD/MM/YYYY"),
            toDate: moment(today, "DD/MM/YYYY").format("DD/MM/YYYY"),
          },
          () => {
            this.getStatusKey();
            this.callListApi(this.state.tab);
          }
        );
      }
    }
  };

  displayTodayDate = () => {
    let today = new Date();
    if (today !== null) {
      this.setState(
        {
          fromDate: moment(today, "DD/MM/YYYY").format("DD/MM/YYYY"),
          toDate: moment(today, "DD/MM/YYYY").format("DD/MM/YYYY"),
        },
        () => {
          this.callListApi(this.state.tab);
        }
      );
    }
  };

  pendingCodDownloads = async () => {
    this.setState({ loading: true });
    let { fromDate, toDate } = this.state;
    let qParams = [
      { fromDate: fromDate },
      { toDate: toDate },
      { status: this.state.tab },
    ];
    await downloadPendingCod(qParams)
      .then((res) => {
        let blob = new Blob([res.data], { type: "text/csv" });
        saveAs(blob, `pending_cod_orders_${fromDate}_${toDate}_report.csv`);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  // to disable the future dates
  customeDisableDates = (current) => {
    let date = new Date();
    return current && current > moment(date, "DD-MM-YYYY");
  };

  redirectToCreditLimitPage = (item) => {
    if (item) {
      window.location = `/dashboard/cod-list/${item._id}`;
      this.history.push({
        pathname: `/dashboard/cod-list/${item._id}`,
      });
    }
  };

  getStatusKey = async () => {
    this.setState({ loading: true });

    await getGlobalStatus()
      .then((res) => {
        console.log(res.data.message);
        this.setState({
          loading: false,
          creditExtStatus: res.data.data.creditExtStatus,
        });
        if (res.data.data.creditExtStatus == "1") {
          // message.info(
          //   "Changes in credit are not allowed, Please connect with the finance team for the credit extension."
          // );
          this.setState({
            statusMsg:
              "Changes in credit are not allowed, Please contact  finance team for the credit extension.",
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  componentDidMount = () => {
    console.log("first");
    console.log("second");
    this.getStatusKey();
    if (
      this.props.location.state &&
      this.props.location.state.tab === "approved"
    ) {
      const { tab } = this.props.location.state;

      this.setState({ tab: tab });
      // this.getRequestedSoDetails();
      this.displayTodayDate();
    } else if (
      this.props.location.state &&
      this.props.location.state.tab === "rejected"
    ) {
      const { tab } = this.props.location.state;
      console.log(tab);
      this.setState({ tab: tab });
      //this.getRejectedSoDetails();
      this.displayTodayDate();
    } else {
      this.setState({ tab: "pending" });

      this.displayTodayDate();
    }
  };

  redirectToCreditExtension = (item) => {
    this.setState({ modalData: item });
    this.props.history.push({
      pathname: `/dashboard/cod-list/${item._id}`,

      state: {
        modalData: item,
        tab: this.state.tab,
        creditExtStatus: this.state.creditExtStatus,
      },
    });
  };

  // statusMessage = (item) => {
  //   item.orderFrom === "pdwaycool"
  //     ? message.error(
  //         "SO Generation Failed!. Please see the SO report for the reason for failure. Contact the customer excellence or MDM team for any further clarification.",
  //         12
  //       )
  //     : message.warning(
  //         "SO Generation Pending!. Contact customer excellence CustomerExcellence@waycool.in team for any further clarification.",
  //         12
  //       );
  // };

  openModal = (item) => {
    if (item.orderDetails && item.orderDetails.paymentStatus === "pending") {
      this.setState({
        pendingStatusModal: !this.state.pendingStatusModal,
      });
    } else if (
      item.orderDetails &&
      item.orderDetails.paymentStatus === "failed"
    ) {
      this.setState({
        failedStatusModal: !this.state.failedStatusModal,
      });
    }
  };

  closeFailedModal = () => {
    this.setState({
      failedStatusModal: !this.state.failedStatusModal,
    });
  };
  closePendingModal = () => {
    this.setState({
      pendingStatusModal: !this.state.pendingStatusModal,
    });
  };
  render() {
    const { RangePicker } = DatePicker;
    let { loading, tab, page, totalItems, totalPage, pageSize, statusMsg } =
      this.state;
    let { pendingSoList, requestedSoList, rejectedSOList } = this.props;
    let todayDate = new Date();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div id="content" className="">
        <PrimaryHeader title="Credit Limit Extension" />
        {tab === "pending" && statusMsg != "" && (
          <div className=" alerttringle  mb-3 ">
            <img
              src={alertTringle}
              style={{ height: "20px", width: "20px" }}
              className="float-left mt-2 ml-5"
            />
            <p className="mt-2 ">{statusMsg}</p>
          </div>
        )}
        <div className="sap-report-subheader row ">
          <div className="col col-sm-4 col-md-4 col-lg-4 col-xl-4">
            {tab === "pending" ? (
              <>
                <SearchBar
                  onSearch={this.onPendingSearch}
                  searchText={this.state.pendingSearch}
                  fromPage="cod"
                />
              </>
            ) : tab === "approved" ? (
              <SearchBar
                onSearch={this.onRequestedSearch}
                searchText={this.state.requestedSearch}
                fromPage="cod"
              />
            ) : (
              <SearchBar
                onSearch={this.onRejectedSearch}
                searchText={this.state.rejectedSearch}
                fromPage="cod"
              />
            )}
          </div>
          <div className="col col-sm-4 col-md-3 col-lg-6 col-xl-6 ml-n5 cod-rangepicker">
            <Space direction="vertical">
              <RangePicker
                className="ant-picker"
                format={dateFormat}
                onChange={this.selectDateRange}
                disabledDate={this.customeDisableDates}
                defaultValue={[
                  moment(todayDate, dateFormat),
                  moment(todayDate, dateFormat),
                ]}
              />
            </Space>
          </div>
          {/* {tab === "pending" && ( */}
          <div className="col-lg-1 col-xl-1 col-md-2 ml-n2 mt-1">
            {/* {tab === "pending" ? (
              <button
                className="btn btn-primary"
                onClick={this.pendingCodDownloads}
                disabled={statusMsg}
              >
                Download
              </button>
            ) : ( */}
            <button
              className="btn btn-primary"
              onClick={this.pendingCodDownloads}
            >
              Download
            </button>
            {/* )} */}
          </div>
          {/* )} */}
        </div>

        <div className="container-fluid mt-2">
          <Tabs activeKey={tab} onChange={this.onChangeTab}>
            <TabPane tab="Pending" key="pending">
              <div className="table-wrapper mt-n3">
                <Spin indicator={antIcon} spinning={loading}>
                  <table className="table approved-table table-hover">
                    <thead className="remove-border-top">
                      <tr>
                        <th scope="col">Order Date</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Credit Limit</th>
                        <th scope="col">Credit Exposure</th>
                        <th scope="col">Credit Balance</th>
                        <th scope="col">Order Amount</th>
                        <th scope="col">Order Amount (incl. TCS)</th>
                        <th scope="col">Required Credit Limit</th>
                        <th scope="col">Delivery Date</th>
                        <th scope="col">Plant</th>
                        {/* <th scope="col">Seller</th> */}
                        <th scope="col">Order Type</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {pendingSoList &&
                        pendingSoList.length > 0 &&
                        pendingSoList.map((pending) => {
                          console.log(pending);
                          return (
                            <tr className="cursor">
                              <td>
                                {moment(
                                  pending.orderDetails.dateOfOrderPlacing
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td
                                className="nameCapitalize"
                                onClick={() =>
                                  this.redirectToCreditExtension(pending)
                                }
                                title={pending.orderDetails.customerName}
                              >
                                <span className="blue-link ">
                                  {pending.orderDetails.goFrugalId}{" "}
                                </span>
                                <br />
                                {stringSortingByChar(
                                  pending.orderDetails.customerName,
                                  10
                                )}
                              </td>
                              <td>
                                &#8377;
                                {pending.orderDetails.creditLimit}
                              </td>
                              <td>
                                &#8377;
                                {pending.orderDetails.creditExposure}
                              </td>
                              <td>
                                &#8377;
                                {formatMoney(
                                  pending.orderDetails.creditLimit -
                                    pending.orderDetails.creditExposure
                                )}
                              </td>
                              <td>
                                &#8377;
                                {pending.orderDetails.totalAmount}
                              </td>
                              <td>
                                &#8377;
                                {pending.orderDetails.totalAmountIncludedTCS}
                              </td>
                              <td>
                                &#8377;
                                {formatMoney(
                                  parseFloat(
                                    pending.orderDetails.totalAmountIncludedTCS
                                  ) +
                                    parseFloat(
                                      pending.orderDetails.creditExposure
                                    )
                                )}
                              </td>
                              <td>
                                {moment(
                                  pending.orderDetails.dateOfDelivery
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td>{pending.orderDetails.plant}</td>
                              {/* <td className="nameCapitalize">
                                {pending.orderFrom === "pdwaycool"
                                  ? pending.orderDetails.salesmanObj
                                      .salesmanName
                                  : pending.orderDetails.salesmanObj &&
                                    pending.orderDetails.salesmanObj.salesManId}
                              </td> */}
                              <td className="nameCapitalize">
                                {pending.orderFrom}
                              </td>
                              <td>
                                <span
                                  class="dot mr-1"
                                  style={{
                                    backgroundColor: "#F2994A",
                                  }}
                                ></span>
                                {pending.requestStatus
                                  ? pending.requestStatus
                                  : "N/A"}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {pendingSoList.length === 0 && (
                    <div className="no-data">
                      <p>No Data Available</p>
                    </div>
                  )}
                </Spin>
              </div>
              <div className="pagination">
                {totalItems != 0 && (
                  <p className="p-2">Total {totalItems} Pending SO's </p>
                )}
                {totalPage != 0 && (
                  <Pagination
                    className="page"
                    defaultCurrent={1}
                    current={page}
                    total={totalPage}
                    defaultPageSize={pageSize}
                    showSizeChanger={false}
                    onChange={(page, pageSize) =>
                      this.handlePendingChange(page, pageSize)
                    }
                  />
                )}
              </div>
            </TabPane>
            <TabPane tab="Approved" key="approved">
              <div className="table-wrapper  mt-n3">
                <Spin indicator={antIcon} spinning={loading}>
                  {/* <div className="table-responsive"> */}
                  <table className="table table-hover">
                    <thead className="remove-border-top">
                      <tr>
                        <th scope="col">Order Date</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Credit Limit</th>
                        <th scope="col">Credit Exposure</th>
                        <th scope="col">Credit Balance</th>
                        <th scope="col">Order Amount</th>
                        <th scope="col">Order Amount (incl. TCS)</th>
                        <th scope="col">Required Credit Limit</th>
                        <th scope="col">Delivery Date</th>
                        <th scope="col">Plant</th>
                        <th scope="col">Seller</th>
                        <th scope="col">Order Type</th>
                        <th scope="col">SO Number</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requestedSoList &&
                        requestedSoList.length > 0 &&
                        requestedSoList.map((requested) => {
                          return (
                            <tr
                              className="cursor"
                              //    onClick={() => this.statusMessage(requested)}
                            >
                              <td>
                                {moment(
                                  requested.orderDetails.dateOfOrderPlacing
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td
                                className="nameCapitalize"
                                title={requested.orderDetails.customerName}
                              >
                                {requested.orderDetails.goFrugalId} <br />
                                {stringSortingByChar(
                                  requested.orderDetails.customerName,
                                  10
                                )}
                              </td>
                              <td>
                                &#8377;
                                {requested.orderDetails.creditLimit}
                              </td>
                              <td>
                                &#8377;
                                {requested.orderDetails.creditExposure}
                              </td>
                              <td>
                                &#8377;
                                {formatMoney(
                                  requested.orderDetails.creditLimit -
                                    requested.orderDetails.creditExposure
                                )}
                              </td>
                              <td>
                                &#8377;
                                {requested.orderDetails.totalAmount}
                              </td>
                              <td>
                                &#8377;
                                {requested.orderDetails.totalAmountIncludedTCS}
                              </td>
                              <td>
                                &#8377;
                                {formatMoney(
                                  parseFloat(
                                    requested.orderDetails
                                      .totalAmountIncludedTCS
                                  ) +
                                    parseFloat(
                                      requested.orderDetails.creditExposure
                                    )
                                )}
                              </td>
                              <td>
                                {moment(
                                  requested.orderDetails.dateOfDelivery
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td>{requested.orderDetails.plant}</td>
                              <td className="nameCapitalize">
                                {requested.orderFrom === "pdwaycool"
                                  ? requested.orderDetails.salesmanObj
                                      .salesmanName
                                  : requested.orderDetails.salesmanObj &&
                                    requested.orderDetails.salesmanObj
                                      .salesManId}
                              </td>
                              <td className="nameCapitalize">
                                {requested.orderFrom}
                              </td>
                              <td className="nameCapitalize">
                                {/* 123456789011 */}
                                {requested.SalesOrder &&
                                requested.SalesOrder[0] &&
                                requested.SalesOrder[0].salesOrderId == "NA"
                                  ? "-"
                                  : requested.SalesOrder &&
                                    requested.SalesOrder[0] &&
                                    requested.SalesOrder[0].salesOrderId
                                  ? requested.SalesOrder &&
                                    requested.SalesOrder[0] &&
                                    requested.SalesOrder[0].salesOrderId
                                  : "-"}
                              </td>
                              <td
                                className="nameCapitalize"
                                style={{
                                  minWidth: "100px",
                                }}
                              >
                                {
                                  // requested.soStatus == "pending" ? (
                                  //   <span
                                  //     className="dot "
                                  //     style={{ backgroundColor: "#FDBF00" }}
                                  //     onClick={() => this.openModal(requested)}
                                  //   >
                                  //     <span className="ml-3">Pending</span>
                                  //   </span>
                                  // )  :
                                  requested.SalesOrder &&
                                  requested.SalesOrder[0] &&
                                  requested.SalesOrder[0].isSOPosted == 1 ? (
                                    <span
                                      class="dot"
                                      style={{
                                        backgroundColor: "#328D41",
                                      }}
                                    >
                                      <span className="ml-3"> Successful</span>
                                    </span>
                                  ) : (
                                    // requested.SalesOrder &&
                                    // requested.SalesOrder[0] &&
                                    //  requested.SalesOrder[0].salesOrderId !== undefined   &&
                                    // requested.soStatus == "successfull" ?

                                    <span
                                      class="dot"
                                      style={{
                                        backgroundColor: "#E13440",
                                      }}
                                      onClick={() => this.openModal(requested)}
                                    >
                                      <span className="ml-3">Failed</span>
                                    </span>
                                  )
                                }
                                {/* //  :
                             
                                //   // requested.SalesOrder &&
                                //   // requested.SalesOrder[0] &&
                                //   // requested.SalesOrder[0].hasOwnProperty('salesOrderId')   &&
                                //   requested.soStatus == "failure" ? (
                                //   <span
                                //     class="dot"
                                //     style={{ backgroundColor: "#E13440" }}
                                //     onClick={() => this.openModal(requested)}
                                //   >
                                //     <span className="ml-3">Failed</span>
                                //   </span>
                                // ):
                                //  (
                                //   ""
                                // )} */}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {/* </div> */}
                  {requestedSoList.length === 0 && (
                    <div className="no-data">
                      <p>No Data Available</p>
                    </div>
                  )}
                </Spin>
              </div>

              <div className="pagination">
                {totalItems != 0 && (
                  <p className="p-2">Total {totalItems} Approved SO's </p>
                )}
                {totalPage != 0 && (
                  <Pagination
                    className="page"
                    defaultCurrent={1}
                    current={page}
                    total={totalPage}
                    defaultPageSize={pageSize}
                    showSizeChanger={false}
                    onChange={(page, pageSize) =>
                      this.handleRequestedChange(page, pageSize)
                    }
                  />
                )}
              </div>
            </TabPane>
            <TabPane tab="Rejected" key="rejected">
              <div className="table-wrapper  mt-n3">
                <Spin indicator={antIcon} spinning={loading}>
                  <div className="table-responsive">
                    <table className="table approved-table">
                      <thead className="remove-border-top">
                        <tr>
                          <th scope="col">Order Date</th>
                          <th scope="col">Customer</th>
                          <th scope="col">Credit Limit</th>
                          <th scope="col">Credit Exposure</th>
                          <th scope="col">Credit Balance</th>
                          <th scope="col">Order Amount</th>
                          <th scope="col">Order Amount (incl. TCS)</th>
                          <th scope="col">Required Credit Limit</th>
                          <th scope="col">Delivery Date</th>
                          <th scope="col">Plant</th>
                          {/* <th scope="col">Salesman/Seller</th> */}
                          <th scope="col">Order Type</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rejectedSOList &&
                          rejectedSOList.length > 0 &&
                          rejectedSOList.map((rejected) => {
                            return (
                              <tr>
                                <td>
                                  {moment(
                                    rejected.orderDetails.dateOfOrderPlacing
                                  ).format("DD-MM-YYYY")}
                                </td>
                                <td
                                  className="nameCapitalize"
                                  title={rejected.orderDetails.customerName}
                                >
                                  {rejected.orderDetails.goFrugalId} <br />
                                  {stringSortingByChar(
                                    rejected.orderDetails.customerName,
                                    10
                                  )}
                                </td>
                                <td>
                                  &#8377;
                                  {rejected.orderDetails.creditLimit}
                                </td>
                                <td>
                                  &#8377;
                                  {rejected.orderDetails.creditExposure}
                                </td>
                                <td>
                                  &#8377;
                                  {formatMoney(
                                    rejected.orderDetails.creditLimit -
                                      rejected.orderDetails.creditExposure
                                  )}
                                </td>
                                <td>
                                  &#8377;
                                  {rejected.orderDetails.totalAmount}
                                </td>
                                <td>
                                  &#8377;
                                  {rejected.orderDetails.totalAmountIncludedTCS}
                                </td>
                                <td>
                                  &#8377;
                                  {formatMoney(
                                    parseFloat(
                                      rejected.orderDetails
                                        .totalAmountIncludedTCS
                                    ) +
                                      parseFloat(
                                        rejected.orderDetails.creditExposure
                                      )
                                  )}
                                </td>
                                <td>
                                  {moment(
                                    rejected.orderDetails.dateOfDelivery
                                  ).format("DD-MM-YYYY")}
                                </td>
                                <td>{rejected.orderDetails.plant}</td>
                                {/* <td className="nameCapitalize">
                                  {rejected.orderFrom === "pdwaycool"
                                    ? rejected.orderDetails.salesmanObj
                                     && rejected.orderDetails.salesmanObj
                                        .salesmanName
                                    : rejected.orderDetails.salesmanObj &&
                                      rejected.orderDetails.salesmanObj
                                        .salesManId}
                                </td> */}
                                <td className="nameCapitalize">
                                  {rejected.orderFrom}
                                </td>
                                <td
                                  className="nameCapitalize"
                                  style={{
                                    minWidth: "90px",
                                  }}
                                >
                                  {rejected.requestStatus == "ASM" ||
                                  rejected.requestStatus == "SAP" ? (
                                    <span
                                      class="dot"
                                      style={{
                                        backgroundColor: "#FDBF00",
                                      }}
                                    >
                                      <span className="ml-3">
                                        {rejected.requestStatus}
                                      </span>
                                    </span>
                                  ) : rejected.requestStatus == "expired" ? (
                                    <span
                                      class="dot"
                                      style={{
                                        backgroundColor: "#E13440",
                                      }}
                                    >
                                      <span className="ml-3">
                                        {rejected.requestStatus}
                                      </span>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {/* <span
                                  class="dot"
                                  style={{ backgroundColor: "#FDBF00" }}
                                ></span>
                                Rejected
                                <span
                                  class="dot"
                                  style={{ backgroundColor: "#E13440" }}
                                ></span>
                                Expired */}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {rejectedSOList.length === 0 && (
                    <div className="no-data">
                      <p>No Data Available</p>
                    </div>
                  )}
                </Spin>
              </div>

              <div className="pagination">
                {totalItems != 0 && (
                  <p className="p-2">Total {totalItems} Rejected SO's </p>
                )}
                {totalPage != 0 && (
                  <Pagination
                    className="page"
                    defaultCurrent={1}
                    current={page}
                    total={totalPage}
                    defaultPageSize={pageSize}
                    showSizeChanger={false}
                    onChange={(page, pageSize) =>
                      this.handleRequestedChange(page, pageSize)
                    }
                  />
                )}
              </div>
            </TabPane>
          </Tabs>
        </div>
        {this.state.failedStatusModal && (
          <Modal
            // width={200}
            visible={this.state.failedStatusModal}
            onCancel={this.closeFailedModal}
            closable
            maskClosable={false}
            footer={null}
          >
            <div>
              <img
                src={crossCircle}
                style={{ height: "50px", width: "50px" }}
              />
              <span className="warningMsg">SO Generation Failed!</span>
              <p className="descriptMsg ml-4">
                Please see the SO report for the reason for failure. Contact the
                <b> customer excellence or MDM team </b>for any further
                clarification.
              </p>
            </div>
          </Modal>
        )}
        {this.state.pendingStatusModal && (
          <>
            <Modal
              width={200}
              visible={this.state.pendingStatusModal}
              onCancel={this.closePendingModal}
              closable
              maskClosable={false}
              footer={null}
            >
              <div>
                <img
                  src={alertTringle}
                  style={{ height: "50px", width: "50px" }}
                />
                <span className="warningMsg">SO Generation Pending!</span>
                <p className="descriptMsg ml-4">
                  Please see the SO report for the reason for failure. Contact
                  the customer excellence or MDM team for any further
                  clarification.
                </p>
              </div>
            </Modal>
          </>
        )}

        {/* {this.state.showCre'ditLimitExtensionModal && (
          <CreditLimitExtension
            displayModal={this.openCreditLimitExtensionModal}
            modalData={this.state.modalData}
          />
        )} */}
      </div>
    );
  }
}

export default codList;
