import React, { Component } from "react";
import "./team.scss";
import "../dropdown/dropdown.scss";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  updateDetails,
  editAsm,
  editSalesman,
} from "../../services/teamServices/team";
import downArrow from "../../images/down-arrow.svg";
// loader
import { Spin } from "antd";
// err msg
import message from "antd/lib/message";
import { handleErrorResponse } from "../../utils/Request";
import { Modal, Upload, Button } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { uploadFile } from "../../services/fileHandler";
import history from "../../utils/History";
import { checkErr } from "../../utils/common";
// dropdown
import {
  getAsmListForDropdown,
  areaSalesManagerDetails,
  salesManDetails,
  getAsmListForDropdownHRRole,
} from "../../services/teamServices/team";
import Dropdown from "../dropdown/dropdown";
import { isEmpty } from "lodash";
import AsmTeamProfile from "../form/asmTeamProfile";
import SalesmanTeamProfile from "../form/salesmanTeamProfile";
import { getActualImage } from "../../utils/generateImageUrl";
import "../profile/profile.scss";

// get bas64
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

// before uploading
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      phone: "",
      loading: false,
      page: 1,
      asmDropdownList: [],
      reportingTo: "Select",
      selectedReportingManagerId: "",
      showDropdown: false,
      personDetails: {},
      changedField: [],
      loading: false,
      imageLoading: false,
      details: {},
      email: "",
      phone: "",
      photo: "",
      salesmanId: "",
      isWaycoolEmp: true,
      gender: " ",
      agencyId: "5f6325d901ad601ae5c17835 ",
      removeProfilePic: "",
      picId: "",
      value: "",
      fullName: "",
      defaultGenderValue: "",
      detailASM: {},
      distributor: "",
      isOnboarded: false,
      seldistributorId: ",",
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSubmit = async (e) => {
    if (
      this.props.type === "salesman" &&
      this.state.selectedReportingManagerId == ""
    ) {
      message.error("Reporting Manager Id should be a valid Id");
      return;
    }

    this.setState({ loading: true });

    let {
      detailsId,
      type,
      isEditPage,
      details,
      selectDistributor,
      selectedCity,
    } = this.props;
    let {
      email,
      phone,
      selectedReportingManagerId,
      isWaycoolEmp,
      agencyId,
      gender,
      picId,
      defaultGenderValue,
    } = this.state;
    let inputs;

    if (type === "asm") {
      inputs = {
        empId: details.employeeId,
        firstName: details.firstName,
        lastName: details.lastName,
        reportingManagerId: details.reportingTo.id,
        email,
        contactMobile: phone.toString(),
        isWaycoolEmp: isWaycoolEmp,
        gender:
          this.state.defaultGenderValue == "" ? gender : defaultGenderValue,
        profilePic: picId,
        distributorId: selectDistributor,
        cityId: selectedCity,
      };
    }
    if (type === "salesman") {
      inputs = {
        empId: details.employeeId,
        firstName: details.firstName,
        lastName: details.lastName,
        isWaycoolEmp: isWaycoolEmp,
        reportingManagerId: selectedReportingManagerId,
        email,
        contactMobile: phone.toString(),
        gender:
          this.state.defaultGenderValue == "" ? gender : defaultGenderValue,
        profilePic: picId,
        distributorId: selectDistributor,
        cityId: selectedCity,
      };
    }
    await updateDetails(inputs, type)
      .then((res) => {
        console.dir("res1", res);
        this.setState({ loading: false });

        if (type == "asm") {
          message.success("Asm created successfully!!");
          window.location = `/dashboard/team/asms`;
          history.push({
            pathname: `/dashboard/team/asms`,
          });
        }
        if (type == "salesman") {
          console.dir("res2", res);
          message.success(" Salesman created successfully!!");
          window.location = `/dashboard/team/salesmen`;
          history.push({
            pathname: `/dashboard/team/salesmen`,
          });
          // setTimeout(
          //   () => (window.location = `/dashboard/team/salesmen`),

          //   2000
          // );
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.setState({ isOnboarded: true });
        let _err = err.response.data.message;
        if (err.response.data.message == "Bad Request") {
          message.warning(err.response.data.error.contactMobile);
        } else {
          message.warning(_err);
        }
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };
  onEdit = () => {
    let { selectedReportingManagerId, personDetails, email, phone } =
      this.state;
    // this.setState({ loading: true });
    let { details, type, detailsId } = this.props;
    let id = details._id;
    let inputs = {};

    if (this.props.type == "asm") {
      if (personDetails.email != email) {
        inputs = { ...inputs, email: email };
      }
      if (personDetails.contactMobile.toString() != phone.toString()) {
        inputs = { ...inputs, contactMobile: phone };
      }
      !isEmpty(inputs) && this.editAsmFunction(id, inputs);
    } else if (this.props.type == "salesman") {
      if (personDetails.asmMapping.asmId._id != selectedReportingManagerId) {
        inputs = { reportingManagerId: selectedReportingManagerId };
      }
      if (personDetails.email != email) {
        inputs = { ...inputs, email };
      }
      if (personDetails.contactMobile != phone) {
        inputs = { ...inputs, contactMobile: phone.toString() };
      }

      !isEmpty(inputs) && this.editSalesmanFunction(id, inputs);
    }
  };

  // edit asm function
  editAsmFunction = async (id, inputs) => {
    await editAsm(id, inputs)
      .then((res) => {
        message.success("Profile updated successfully");
        areaSalesManagerDetails(this.props.detailsId)
          .then((res) => {
            this.setState({
              loading: false,
              personDetails: res.data.data,
              distributor: res.data.data.warehouseId.nameToDisplay,
              email: res.data.data.email,
              phone: res.data.data.contactMobile,
              reportingTo:
                res.data.data &&
                res.data.data.reportingTo &&
                res.data.data.reportingTo.name
                  ? res.data.data.reportingTo.name
                  : "N/A",
            });
            setTimeout(
              () => (window.location = `/dashboard/team/asms`),
              //  history.push({
              //     pathname: "/dashboard/team/asms",
              //   }),
              3000
            );
          })

          .catch((err) => {
            this.setState({ loading: false });

            if (err.response.data.message == "Bad Request") {
              message.warning(err.response.data.error.body);
            } else {
              let _err = err.response.data.message;
              message.warning(_err);
            }
            const { errorMessage, errors } = handleErrorResponse(err);
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response.data.message == "Bad Request") {
          message.warning(err.response.data.error.body);
        } else {
          let _err = err.response.data.message;
          message.warning(_err);
        }
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };
  // editsalesman function
  editSalesmanFunction = async (id, inputs) => {
    await editSalesman(id, inputs)
      .then((res) => {
        message.success("Profile updated successfully");
        let { detailsId } = this.props;
        salesManDetails(detailsId)
          .then((res) => {
            this.setState({
              personDetails: res.data.data,
              distributor: res.data.data.warehouseId.name,
              email: res.data.data.email,
              phone: res.data.data.contactMobile.toString(),
              reportingTo:
                res.data.data.asmMapping != {}
                  ? res.data.data.asmMapping.asmId.firstName +
                    " " +
                    res.data.data.asmMapping.asmId.lastName
                  : "Select",
              loading: false,
            });
            setTimeout(
              () => (window.location = `/dashboard/team/salesmen`),
              //  history.push({
              //     pathname: "/dashboard/team/asms",
              //   }),
              3000
            );
          })
          .catch((err) => {
            this.setState({ loading: false });
            if (err.response.data.message == "Bad Request") {
              message.warning(err.response.data.error.body);
            } else {
              let _err = err.response.data.message;
              message.warning(_err);
            }
            const { errorMessage, errors } = handleErrorResponse(err);
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response.data.message == "Bad Request") {
          message.warning(err.response.data.error.body);
        } else {
          let _err = err.response.data.message;
          message.warning(_err);
        }
        const { errorMessage, errors } = handleErrorResponse(err);
      });
    this.setState({ loading: false });
  };

  // dropdown functions starts

  menuClick = (e) => {
    if (e != null) {
      let value = e[0] ? e[0].label : e.label;
      let id = e[0] ? e[0].value : e.value;
      this.setState({
        reportingTo: value,
        selectedReportingManagerId: id,
        value: e,
      });
    }
  };

  showDropDownFunction = () => {
    this.setState({
      showDropdown: !this.state.showDropdown,
    });
  };
  getDropdownOptions = () => {
    let { page } = this.state;
    let { selectDistributor } = this.props;
    getAsmListForDropdownHRRole(page, "", selectDistributor)
      .then((res) => {
        this.setState({
          loading: false,
          asmDropdownList: res.data.data.results,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  loadOptions = async (search, loadedOptions, { page }) => {
    let distributorID;
    let { selectDistributor, isEditPage } = this.props;

    if (isEditPage == true) {
      distributorID = this.state.seldistributorId;
    } else {
      distributorID = selectDistributor;
    }

    let data = await getAsmListForDropdownHRRole(page, search, distributorID)
      .then((res) => {
        return {
          options:
            res.data.data.results &&
            res.data.data.results.map((item) => {
              return {
                key: item.employeeId,
                value: item._id,
                label: `${item.firstName} ${item.lastName}`,
              };
            }),
          totalPage:
            parseInt(res.data.data.pageMeta.total || 0) /
            parseInt(res.data.data.pageMeta.pageSize || 0),
        };
      })
      .catch((err) => console.log(err));
    return {
      options: data.options,
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };
  //dropdown functions end
  componentDidUpdate = (prevProps) => {
    if (prevProps.selectDistributor !== this.props.selectDistributor) {
      let { detailsId, type, isEditPage, details } = this.props;
      if (type === "salesman") {
        this.setState({
          reportingTo: "select",
          selectedReportingManagerId: "",
        });
        message.error(
          "Kindly re-select the Reporting Manager name as you have changed the Distributor"
        );
      }
    }
  };
  componentDidMount = async () => {
    let { detailsId, type, isEditPage, details } = this.props;
    {
      type === "salesman" && this.getDropdownOptions();
    }
    if (type == "asm" && isEditPage == true) {
      // get dtails
      await areaSalesManagerDetails(detailsId)
        .then(async (res) => {
          this.setState({
            loading: false,
            personDetails: res.data.data,
            distributor: res.data.data.warehouseId.nameToDisplay,
            email: res.data.data.email,
            phone: res.data.data.contactMobile,
            reportingTo:
              res.data.data &&
              res.data.data.reportingTo &&
              res.data.data.reportingTo.name
                ? res.data.data.reportingTo.name
                : "N/A",
            imageUrl: res.data.data.profilePic
              ? await getActualImage(res.data.data.profilePic)
              : "",
            defaultGenderValue: res.data.data.gender,
          });
        })

        .catch((err) => {
          this.setState({ loading: false });

          console.log(err, "err");
          message.error(err);
          const { errorMessage, errors } = handleErrorResponse(err);
        });
    }
    if (type == "salesman" && isEditPage == true) {
      // get details

      await salesManDetails(detailsId)
        .then(async (res) => {
          this.setState({
            personDetails: res.data.data,
            distributor: res.data.data.warehouseId.name,
            seldistributorId: res.data.data.warehouseId._id,
            email: res.data.data.email,
            fullName: res.data.data.fullName,
            phone:
              res.data.data.contactMobile != null
                ? res.data.data.contactMobile
                : this.state.phone,
            reportingTo:
              res.data.data.asmMapping != {}
                ? res.data.data.asmMapping.asmId.firstName +
                  " " +
                  res.data.data.asmMapping.asmId.lastName
                : "Select",
            loading: false,

            imageUrl: res.data.data.profilePic
              ? await getActualImage(res.data.data.profilePic)
              : "",
            defaultGenderValue: res.data.data.gender,
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          const { errorMessage, errors } = handleErrorResponse(err);
        });
    }
    if (isEditPage == false) {
      // if this is not edit page
      this.setState({
        personDetails: details,
        email: details.email,
        phone: details.contactMobile,
        reportingTo:
          type == "asm"
            ? details.reportingTo && details.reportingTo.name
            : "Select",
        defaultGenderValue: details.gender,
      });
    }
  };

  onProfileUpdate = async () => {
    this.setState({ loading: true });
    //  call api
    await areaSalesManagerDetails(this.props.detailsId)
      .then(async (res) => {
        this.setState({
          details: res.data.data,
          // email: res.data.data.email,
          // phone: res.data.data.contactMobile,
          loading: false,
          imageUrl: res.data.data.profilePic
            ? await getActualImage(res.data.data.profilePic)
            : "",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  onSalesProfileUpdate = async () => {
    this.setState({ loading: true });
    //  call api
    await salesManDetails(this.props.detailsId)
      .then(async (res) => {
        this.setState({
          details: res.data.data,
          loading: false,
          imageUrl: res.data.data.profilePic
            ? await getActualImage(res.data.data.profilePic)
            : "",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  // handle change of image uploader
  handleImageChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  // uploading file to server
  uploadToServer = async ({ onSuccess, onError, file }) => {
    let res = await uploadFile(file)
      .then((res) => {
        this.setState({
          isProfilePicUpdate: true,
          picId: res.data.data.imageId,
          removeProfilePic: false,
        });
        onSuccess(null, file);
      })
      .catch((err) => {
        onError(err);
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };

  onGenderChange = (e) => {
    this.setState({
      gender: e.target.value,
    });
  };

  render() {
    let {
      email,
      phone,
      loading,
      asmDropdownList,
      reportingTo,
      showDropdown,
      personDetails,
      distributor,
      removeProfilePic,
      imageLoading,
      imageUrl,
      details,
      gender,
      isOnboarded,
    } = this.state;
    let { isEditPage, type } = this.props;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Spin indicator={antIcon} spinning={loading}>
        <div className="add-asm-container mt-4">
          <div className="employer-container">
            <p className="employer-container-left">
              {type == "asm" ? "ASM Details" : "Salesman Details"}
            </p>
            <div className="employer-container-right">
              <form className="form-horizontal">
                <div className="form-group">
                  {!isEditPage && (
                    <div>
                      <Upload
                        id="profilePicture"
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={this.uploadToServer}
                        beforeUpload={beforeUpload}
                        onChange={this.handleImageChange}
                      >
                        {!removeProfilePic ? (
                          imageUrl ? (
                            <img
                              src={imageUrl}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : imageUrl ? (
                            <img
                              src={imageUrl}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </div>
                  )}

                  {type == "asm" && isEditPage && (
                    <AsmTeamProfile
                      data={{ details, imageUrl }}
                      onProfileUpdate={() => this.onProfileUpdate()}
                      id={personDetails && personDetails._id}
                    />
                  )}

                  {type == "salesman" && isEditPage && (
                    <SalesmanTeamProfile
                      data={{ details, imageUrl }}
                      onSalesProfileUpdate={() => this.onSalesProfileUpdate()}
                      id={this.props.detailsId}
                    />
                  )}
                </div>
                {isEditPage == true ? (
                  <div className="form-group row">
                    <label className="control-label text-muted col-sm-3">
                      {" "}
                      Distributor:
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="input-text"
                        value={distributor}
                        // value={this.state.fullName}
                        id="name"
                        disabled={true}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group row">
                  <label className="control-label text-muted col-sm-3">
                    {" "}
                    Full Name:
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="input-text"
                      value={
                        personDetails &&
                        personDetails.firstName + " " + personDetails.lastName
                      }
                      // value={this.state.fullName}
                      id="name"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="control-label text-muted col-sm-3">
                    Reporting Manager:
                  </label>
                  <div class="col-sm-9">
                    <div className="special-dropdown-form">
                      {type == "salesman" && (
                        <AsyncPaginate
                          key={this.props.selectDistributor}
                          className="repotingId-drop-down"
                          value={{
                            value: this.state.reportingTo,
                            label: this.state.reportingTo,
                          }}
                          loadOptions={this.loadOptions}
                          onChange={(e) => {
                            this.menuClick(e);
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      )}
                      {/*              
              {type == "asm" && <p className="value">{reportingTo}</p>} */}
                      {type == "asm" && (
                        <div
                          className="col-sm-13"
                          style={{ marginLeft: "0rem" }}
                        >
                          <input
                            type="text"
                            className="input-text"
                            value={reportingTo}
                            disabled
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="control-label text-muted col-sm-3">
                    Phone Number:
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="tel"
                      id="phone"
                      className="input-text"
                      pattern="\+?\d[\d -]{8,12}\d"
                      placeholder="Enter phone no"
                      name="phone"
                      value={phone}
                      onChange={(e) => this.handleChange(e, "phone")}
                      maxLength={10}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="control-label text-muted col-sm-3">
                    Email id:
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="email"
                      className="input-text"
                      placeholder="Enter email"
                      name="email"
                      value={email}
                      onChange={(e) => this.handleChange(e, "email")}
                      disabled={email}
                    />
                  </div>
                </div>

                {this.state.defaultGenderValue != "" && (
                  <div className="form-group row">
                    <label
                      className="control-label text-muted col-sm-3"
                      for="gender"
                    >
                      {" "}
                      Gender:
                    </label>

                    {/* populate the options while you are in details part */}
                    <div className="mt-2 custom-radio ml-4 pl-3">
                      <input
                        type="radio"
                        className="custom-control-input"
                        required
                        id="defaultInline1"
                        name="gender"
                        value="gender"
                        checked={true}
                      />
                      <label
                        className="custom-control-label custom-gender-label"
                        for="defaultInline1"
                      >
                        {this.state.defaultGenderValue}
                      </label>
                    </div>
                  </div>
                )}
                {this.state.defaultGenderValue == "" && (
                  <div className="form-group row">
                    <label
                      className="control-label text-muted col-sm-3 pl-1"
                      for="gender"
                    >
                      {" "}
                      Gender:
                    </label>
                    <div className="custom-control custom-radio custom-control-inline ml-3">
                      <input
                        type="radio"
                        className="custom-control-input "
                        required
                        id="defaultInline1"
                        name="Male"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={this.onGenderChange}
                      />
                      <label
                        className="custom-control-label custom-gender-label"
                        for="defaultInline1"
                      >
                        Male
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        required
                        className="custom-control-input"
                        id="defaultInline2"
                        value="Female"
                        name="Female"
                        checked={gender === "Female"}
                        onChange={this.onGenderChange}
                      />
                      <label
                        className="custom-control-label custom-gender-label"
                        for="defaultInline2"
                      >
                        Female
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        required
                        className="custom-control-input"
                        id="defaultInline3"
                        value="Other"
                        name="Other"
                        checked={gender === "Other"}
                        onChange={this.onGenderChange}
                      />
                      <label
                        className="custom-control-label custom-gender-label"
                        for="defaultInline3"
                      >
                        Other
                      </label>
                    </div>
                  </div>
                )}
                {/* {isOnboarded ?   <div className="form-group row">
                  <label style={{color:"red"}}>Note: Salesman is already onboarded</label>
                </div>:""} */}
              </form>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={isEditPage == false ? this.onSubmit : this.onEdit}
                disabled={!phone}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}
export default Details;
