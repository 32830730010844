// import React, { Component } from "react";
// import "./modal.scss";
// import cross from "../../images/cross.svg";
// import history from './../../utils/History';
// import { formatMoney } from "../../utils/common";
// import { handleErrorResponse } from "../../utils/Request";
// import message from "antd/lib/message";
// import moment from "moment";
// import { creditExtension } from "../../services/cod/cod";
// import {
//     LoadingOutlined,

// } from "@ant-design/icons";
// import { Spin, Space } from "antd";

// class CreditLimitExtension extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             loading: false,
//         };
//     }

//     handleSubmit = async () => {
//         this.setState({ loading: true })
//         let { modalData } = this.props;
//         let id = modalData._id;
//         let inputs;
//         inputs = {
//             extendedCreditLimit:
//                 (Math.round(parseFloat(modalData.orderDetails.totalAmount) + parseFloat(modalData.orderDetails.creditExposure))),
//             customerNo: modalData.orderDetails.goFrugalId
//         }
//         await creditExtension(inputs, id)
//             .then((res) => {
//                 this.setState({ loading: false })
//                 message.success("Credit limit extension has been submitted sucessfully!")
//                 window.location = "/dashboard/cod-list";
//                 history.push({
//                     pathname: `/dashboard/cod-list`,
//                 });
//             })
//             .catch((err) => {
//                 this.setState({ loading: false });
//                 const { errorMessage, errors } = handleErrorResponse(err);
//                 message.error(errorMessage);
//             });
//     }
//     render() {
//         let { modalData } = this.props
//         const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
//         return (
//             <React.Fragment>
//                 <div className="Backdrop">
//                     <div className="credit-modal-container ">
//                         <div className="row ">
//                             <div className="col-lg-9 col-md-9 credit-text ml-3 mt-1">
//                                 Credit Limit Extension Request Details
//                             </div>
//                             <div className="col-lg-2 col-md-2 pt-1 ml-4">
//                                 <img src={cross} onClick={this.props.displayModal} />
//                             </div>
//                         </div>
//                         <hr />
//                         <Spin indicator={antIcon} spinning={this.state.loading}>
//                             <div className="d-flex mt-4 mb-4 ">
//                                 <div className="d-flex col-lg-12 col-md-12  col-xl-12 ">
//                                     <div className="d-flex col-lg-3 col-md-3  col-xl-3 flex-column">
//                                         <span className="title-name1 mb-1">Customer ID</span>
//                                         <span className="title-data1">{modalData.orderDetails.goFrugalId}</span>
//                                     </div>
//                                     <div className="d-flex col-lg-3 col-md-2  col-xl-3 flex-column">
//                                         <span className="title-name1 mb-1">Credit Limit</span>
//                                         <span className="title-data1">&#8377;{modalData.orderDetails.creditLimit}</span>
//                                     </div>

//                                     <div className="d-flex col-lg-3 col-md-2  col-xl-3 flex-column">
//                                         <span className="title-name1 mb-1">Credit Exposure</span>
//                                         <span className="title-data1">&#8377;{modalData.orderDetails.creditExposure}</span>

//                                     </div>
//                                     <div className="d-flex col-lg-3 col-md-2  col-xl-3 flex-column">
//                                         <span className="title-name1 mb-1">Order Amount</span>
//                                         <span className="title-data1">&#8377;{modalData.orderDetails.totalAmount}</span>
//                                     </div>

//                                 </div>
//                             </div>
//                         </Spin>
//                         <div className="d-flex mt-4 mb-4 ">
//                             <div className="d-flex col-lg-12 col-md-12  col-xl-12 ">
//                                 <div className="d-flex col-lg-3 col-md-3  col-xl-3 flex-column">
//                                     <span className="title-name1 mb-1">Delivery Date</span>
//                                     <span className="title-data1">{moment(modalData.orderDetails.dateOfDelivery).format("DD-MM-YYYY")}</span>
//                                 </div>
//                                 <div className="d-flex col-lg-4 col-md-3  col-xl-4 flex-column">
//                                     <span className="title-name2 mb-1">Required Credit Limit</span>
//                                     <span className="title-data2">&#8377;
//                                         {formatMoney(Math.round(parseFloat(modalData.orderDetails.totalAmount) + parseFloat(modalData.orderDetails.creditExposure)))}</span>
//                                 </div>
//                             </div>
//                         </div>
//                         <button className="btn btn-primary float-right mr-5 mt-4" onClick={this.handleSubmit}>
//                             Request For Extension
//                         </button>

//                     </div>
//                 </div>
//             </React.Fragment>
//         );
//     }
// }
// export default CreditLimitExtension;

// // New Code
import React, { Component } from "react";
import { Modal, Button } from "antd";
import "./modal.scss";
import cross from "../../images/cross.svg";
import ShowSuccessModal from "./showSuccessModal";
import history from "./../../utils/History";
import { formatMoney } from "../../utils/common";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import moment from "moment";
import { creditExtension } from "../../services/cod/cod";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Space } from "antd";

export class creditLimitExtension extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      loading: false,
      showSuccessModal: false,
    };
  }
  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    let { modalData } = this.props;
    let id = modalData._id;
    let inputs;
    inputs = {
      extendedCreditLimit: Math.round(
        parseFloat(modalData.orderDetails.totalAmount) +
          parseFloat(modalData.orderDetails.creditExposure)
      ),
      customerNo: modalData.orderDetails.goFrugalId,
    };
    await creditExtension(inputs, id)
      .then((res) => {
        this.setState({ loading: false });
        message.success(
          "Credit limit extension has been submitted sucessfully!"
        );
        window.location = "/dashboard/cod-list";
        history.push({
          pathname: `/dashboard/cod-list`,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  render() {
    let { modalData } = this.props;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let { visible, loading } = this.state;
    return (
      <Modal
        visible={visible}
        title="Credit Limit Extension Request Details"
        width={1500}
        loading={loading}
        onCancel={this.handleCancel}
        footer={null}
        className="credit"
      >
        <div className="row">
          <div className="col-3">
            <div className="title-name1 mb-1">Customer ID</div>
            <div className="title-data1">
              {modalData.orderDetails.goFrugalId}
            </div>
          </div>
          <div className="col-3">
            <div className="title-name1 mb-1">Credit Limit</div>
            <div className="title-data1">
              &#8377;{modalData.orderDetails.creditLimit}
            </div>
          </div>
          <div className="col-3">
            <div className="title-name1 mb-1">Credit Exposure</div>
            <div className="title-data1">
              &#8377;{modalData.orderDetails.creditExposure}
            </div>
          </div>
          <div className="col-3">
            <div className="title-name1 mb-1">Order Amount</div>
            <div className="title-data1">
              &#8377;{modalData.orderDetails.totalAmount}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-3">
            <div className="title-name1 mb-1">Delivery Date</div>
            <div className="title-data1">
              {moment(modalData.orderDetails.dateOfDelivery).format(
                "DD-MM-YYYY"
              )}
            </div>
          </div>
          <div className="col-3">
            <div className="title-name2 mb-1">Required Credit Limit</div>
            <div className="title-data2">
              &#8377;
              {formatMoney(
                // Math.round(
                parseFloat(modalData.orderDetails.totalAmount) +
                  parseFloat(modalData.orderDetails.creditExposure)
                // )
              )}
            </div>
          </div>
        </div>
        <div className="row mt-2 justify-content-end mr-2">
          <button className="btn btn-primary " onClick={this.handleSubmit}>
            Request For Extension
          </button>
        </div>
      </Modal>
    );
  }
}

export default creditLimitExtension;
