import React, { Component } from "react";
import UnderConstruction from './../underConstruction/underConstruction';
import PrimaryHeader from "../header/PrimaryHeader";

class SapSync extends Component{
    constructor(props){
        super(props)
        this.state={

        }

    }

    render() {
        return (
            <div id="content">
                <PrimaryHeader title="SAP Sync" />

            <UnderConstruction/>
            </div>
        );
    }
}

export default SapSync;