import React, { useState } from "react";

import { Typography, Form, Input, Button, Modal } from "antd";

// import "./balancecreation.scss";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../action/balanceCrestionAction";

import { useParams, useHistory } from "react-router-dom";
import { set } from "lodash";
import OtpInput from "react-otp-input";
import { getOtp } from "../../services/deleteUser/deleteUserServices";

// import {
//   setBalanceConfirmationToken,
//   getAuthTokenCustID,setAuthPhoneNo
// } from "../../components/balanceCreation/authToken";

import Sessionexpired from "../../images/balaceconfirmationsession.svg";

function PhoneNumber() {
  const [otp, setOtp] = useState("");

  const [seconds, setSeconds] = useState(120);
  const [phone, setPhone] = useState();
  const [buttondisable, setButtonDisable] = useState(false);
  const [contactNumber, setContactNumber] = useState();
  const [invalidNo, setInvalidNo] = useState(false);
  const [notRegNo, setNotRegNo] = useState(false);
  const [failed, setFailed] = useState(false);

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const verifyotp = async () => {
    if (contactNumber?.length !== 10) {
      setInvalidNo(true);
        console.log("err");
    } else {
      setInvalidNo(false);
      try {
        const res = await getOtp(contactNumber);

        if (res.status == 200) {
          if (res.data.status == 200) {
            const location = {
              pathname: `/deleteUserOtp`,
              state: {
                contactNumber,
              },
            };
            history.push(location);
          } else if (res.data.status == 400) {
            setNotRegNo(true);

        }
        else if(res.data.status == 500){
          setFailed(true)
        }
        }
      } catch (err) {
        console.log(err, "err");
      }
    }
  };
  const onChangeContactNumber = (e) => {
    setContactNumber(e.target.value);
    console.log(contactNumber, "nnnn");
    setNotRegNo(false);
    if (e.target.value?.length === 10) {
      setInvalidNo(false);

   }
  };
  const onCancelRequest = (e) => {
    const location = {
      pathname: `/cancelRequest`,
    };
    history.push(location);
   }
  
  return (
    <div
    className="example"
    style={{
      //marginTop: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",

      overflow: "hidden",
      scrollBehavior: "none",
      position: "fixed",
    }}
  >
    <div>
      <Typography
        style={{ fontWeight: 700, fontSize: "14px", lineHeight: "41px", marginLeft:"5px", }}
      >
        Please provide your registered mobile number
      </Typography>
      <div>
        <div style={{ display: "flex", gap: "30px", marginTop: "15px", marginLeft:"5px", }}>
        <Input
              value={contactNumber}
              onChange={onChangeContactNumber}
              style={{
                width: "90%",
                height: "40px",
                // marginLeft: "19px",
                borderRadius: "6px",
              }}
            />
        </div>
        {notRegNo == true ? <span
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            color:"red",
            marginLeft:"5px",
          }}
        >
          Number is not registered
        </span>:""}
        {invalidNo == true ? <span
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            color:"red",
            marginLeft:"5px",
          }}
        >
          Enter valid mobile Number
        </span>:""}
        {failed == true ? <span
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            color:"red",
            marginLeft:"5px",
          }}
        >
          Failed to send OTP
        </span>:""}
      </div>
      <div style={{ display: "flex", justifyContent: "center",marginTop:"15px" }}>
      <Button
          onClick={() => onCancelRequest()}
          disabled={buttondisable}
        >
          Cancel
        </Button>
        <Button
          type="link"
          onClick={() => verifyotp()}
          disabled={buttondisable}
          style={{marginLeft:"5px"}}
        >
          Get OTP
        </Button>
      </div>
    </div>
  </div>
  );
}

export default PhoneNumber;
