import { Typography } from "antd";
import React from "react";

const CardLeftContent = ({ icon, title, subTitle }) => {
  return (
    <div className="d-flex flex-row align-items-center">
      <div className="p-3 mr-5" style={{ maxHeight: 100 }}>
        {icon}
      </div>
      <div className="d-flex justify-content-around flex-column h-75">
        <Typography
          className="font-weight-bold"
          style={{ fontSize: 16, color: "#3A4960" }}
        >
          {title}
        </Typography>
        <Typography style={{ fontSize: 14, color: "#7A7A7A" }}>
          {subTitle}
        </Typography>
      </div>
    </div>
  );
};

export default CardLeftContent;
