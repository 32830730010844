import {
  DownloadOutlined,
  DownOutlined,
  FileAddOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Divider,
  Dropdown,
  Input,
  Menu,
  Modal,
  notification,
  Pagination,
  Row,
  Space,
  Spin,
  Steps,
  Typography,
  DatePicker
} from "antd";
import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { getCartItemList } from "../../action/itemCart";
import { saveSelectedCustomer } from "../../action/SearchOrdersListaction";
import { getItemsList } from "../../services/orderServices/itemsService";

import {
  getBasketCount,
  getItemsLists,
  getCreditLimits,
} from "../../action/itemListactions";
import { getPaymentDetails } from "../../action/paymentDetailsaction";
import customerlogo from "../../images/customerlogo.jpg";
// import { getSearchCustomerList } from "../components/orders/OrdersService";
import {
  uploadCartItemsCSV,
  getAllItemsList,
} from "../../services/orderServices/orderService";
import { productLists } from "../../utils/allroutes";
import PrimaryHeader from "../header/PrimaryHeader";
import CustomerDetails from "./CustomerDetails";
import ItemCard from "./Item/ItemCard";
import OrderSteps from "./OrderSteps";
import "./placeorder.scss";
import ErrorAlertMessage from "./ErrorAlertMessage/ErrorAlertMessage";
import message from "antd/lib/message";
import { handleErrorResponse } from "../../utils/Request";
import edit from "../../images/edit.svg";
import moment from "moment";


function PlaceOrder() {
  const { Step } = Steps;
  const { Search } = Input;
  const [uploadCartPopUp, setUploadCartPopUp] = useState(false);
  const [uploadfile, setUploadfile] = useState(null);
  const [isUploadFileValid, setIsUploadFileValid] = useState(true);
  const [confirmUploadLoading, setConfirmUploadLoading] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});
  const [openMessage, setOpenMessage] = useState(false);
  const [editDelieryDate, setEditDelieryDate] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const fileUploadRef = useRef();
  const params = useParams();
  const CustomerId = params?.id;
  const [deliveryDate, setdeliveryDate] = useState(moment(localStorage.getItem("SelectedDeliveryDate")));
  const [selectedDate, setSelectedDate] = useState();
  const [disDates, setDisDates] = useState([]);


  const { loading, itemsList, productSearchValue, basketCount, totalRecords } =
    useSelector((state) => state.itemsListReducer);

  const { cartItemList } = useSelector((state) => state.itemCart);
  const { searchOrdersList } = useSelector(
    (state) => state.serachOrdersReducer
  );
  const { paymentDetails } = useSelector(
    (state) => state.paymentDetailsReducer
  );
  const { deliveryDateDetails } = useSelector(
    (state) => state.deliveryDatesReducer
  );
  const totalNoOfDays =
    deliveryDateDetails?.settings?.orderDeliveryDays?.lastDay - deliveryDateDetails?.settings?.orderDeliveryDays?.earliestDay;
  const ExcludedDays = [];

  const disabledDatesArr = [];
  const j = 0;
  // const getExcludedDays = () => {
  let days_exempted = [];
  const minimumDelDate = deliveryDateDetails?.settings?.orderDeliveryDays?.earliestDay;
  const today = moment(paymentDetails?.currentDate);
  const minDate = moment(today, "DD-MM-YYYY").add(minimumDelDate, "days");
  const [searchWord, setSearchWord] = useState(productSearchValue);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let selectedCust = searchOrdersList?.find(
      (item) => item.goFrugalId == CustomerId
    );

    if (!searchOrdersList?.length) {
      selectedCust = JSON.parse(localStorage.getItem("customer"));
    }
    dispatch(saveSelectedCustomer(selectedCust));
    dispatch(getItemsLists("", CustomerId, 1, pageSize));

    // dispatch(getCartItemList(CustomerId));
    // dispatch(getCreditLimits(CustomerId));
    // dispatch(getPaymentDetails(CustomerId));
  }, [CustomerId]);

  useEffect(() => {
    if (deliveryDateDetails?.settings?.orderDeliveryDays?.excludedDays?.length >= 1) {
      days_exempted = deliveryDateDetails?.settings?.orderDeliveryDays?.excludedDays;
      days_exempted.forEach((element) => {
        switch (element.toLowerCase()) {
          case "monday": {
            ExcludedDays.push(1);
            break;
          }
          case "tuesday": {
            ExcludedDays.push(2);
            break;
          }
          case "wednesday": {
            ExcludedDays.push(3);
            break;
          }
          case "thursday": {
            ExcludedDays.push(4);
            break;
          }
          case "friday": {
            ExcludedDays.push(5);
            break;
          }
          case "saturday": {
            ExcludedDays.push(6);
            break;
          }
          case "sunday": {
            ExcludedDays.push(0);
            break;
          }
          default: {
            break;
          }
        }
      });
    }

    for (var i = 0; i <= totalNoOfDays; i++) {
      // const day = moment.day
      const dayOfWeek = moment(minDate, "DD-MM-YYYY").add(i, "days").weekday();
      let isExcluded = ExcludedDays.every((m) => m !== dayOfWeek);
      if (isExcluded) {
        disabledDatesArr.push(moment(minDate, "DD-MM-YYYY").add(i, "days"));
      }
    }
    setDisDates(disabledDatesArr);

  }, [deliveryDateDetails?.length]);
  useEffect(() => {
    dispatch(
      getItemsLists(searchWord.toUpperCase(), CustomerId, page, pageSize)
    );
  }, [page, pageSize]);

  useEffect(() => {
    dispatch(getBasketCount(CustomerId));
  }, []);

  const uploadCartItems = () => {
    setUploadCartPopUp(true);
  };
  function disabledDate(current) {
    return disDates.every(
      (m) =>
        m.startOf("day")._d.toString() !== current.startOf("day")._d.toString()
    );
  }
  const onSearchChange = (e) => {
    if (e.target.value === " ") return;
    setSearchWord(e.target.value);
  };

  // const onChangeSearchValueHandle = (value) => {
  //   if (searchKey === "" && value === " ") return;
  //   set_PageNumber(1);
  //   setSearchvalue(value);
  // };

  // useEffect(() => {
  //   let timer;
  //   if (openMessage) {
  //     timer = setTimeout(() => {
  //       // console.log("setVisible false");
  //       setMessageInfo({});
  //       setOpenMessage(false);
  //     }, 2000);
  //   }
  //   return () => clearTimeout(timer);
  // }, [openMessage]);

  // console.log(itemsList);
  const getRemainingStock = (item) => {
    if (item.stock === -1) {
      return "unlimited";
    } else {
      return item.stock - item.orderQuantity;
    }
  };
  const DownloadCartItemsCSV = async () => {
    try {
      const res = await getAllItemsList(CustomerId);
      if (res.status === 200) {
        // const dataofArray = itemsList.filter(
        //   (item) => item.condition_type === "ZBAS"
        // );
        const resData = res?.data;
        const records = resData.result;
        const arrangedData = records.map((record) => {
          const {
            material,
            amount,
            item,
            condition_type,
            unit_of_measure,
            valid_from,
            valid_to,
          } = record;

          const { brand, imageUrl, itemName, shortName, inventory } = item[0];
          // console.log(
          //   cart[0]?.items[0],
          //   "CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC"
          // );

          const { stock, moq, orderQuantity } = inventory[0];

          // let cartObj =
          //   cart[0]?.items[0] && Object.keys(cart[0]?.items[0]).length > 0
          //     ? cart[0]?.items[0]
          //     : {};

          // if (Object.keys(cartObj).length > 0) {
          //   extractedCart.push(cartObj);
          // }
          return {
            uniqueId: material.concat(unit_of_measure.concat(condition_type)),
            material,
            amount,
            brand,
            condition_type,
            unit_of_measure,
            valid_from,
            valid_to,
            imageUrl,
            name: itemName,
            shortName,
            stock,
            allowedQuantity: stock / moq,
            moq,
            orderQuantity,
            //  cart: { ...cartObj },
            outOfStock: stock === orderQuantity || stock === 0,
            // discount: wcdiscount[0]?.discount.sort(
            //   (a, b) => a.min_quantity - b.min_quantity
            // ),
          };
        });
        const arrayofData = [];
        arrayofData.push({
          ProductName: "ProductName",
          SAPItemID: "SAPItemID",
          Quantity: "Quantity",
          Price: "Price",
          MinimumOrderQunatity: "MinimumOrderQuantity",
          RemainingStock: "RemainingStock",
          UOM: "UOM",
          ConditionType: "ConditionType",
        });

        arrangedData.forEach((item) => {
          arrayofData.push({
            ProductName: item.name,
            SAPItemID: item.material,
            Quantity: "",
            Price: item.amount,
            MinimumOrderQunatity: item.moq,
            RemainingStock: getRemainingStock(item),
            UOM: item.unit_of_measure,
            ConditionType: item.condition_type,
          });
        });

        const fileTitle = "downloadTemplate"; // or 'my-unique-title'
        exportCSVFile(arrayofData, fileTitle);
      } else {
        message.error(
          res.data?.error && typeof res.data.error === "string"
            ? res.data.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      console.log(err, "error");
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
    }

    // const dataofArray = itemsList.filter(
    //   (item) => item.condition_type === "ZBAS"
    // );
    // const arrayofData = [];
    // arrayofData.push({
    //   ProductName: "ProductName",
    //   SAPItemID: "SAPItemID",
    //   Quantity: "Quantity",
    //   Price: "Price",
    //   MinimumOrderQunatity: "MinimumOrderQuantity",
    //   RemainingStock: "RemainingStock",
    //   UOM: "UOM",
    //   ConditionType: "ConditionType",
    // });

    // dataofArray.forEach((item) => {
    //   arrayofData.push({
    //     ProductName: item.name,
    //     SAPItemID: item.material,
    //     Quantity: "",
    //     Price: item.amount,
    //     MinimumOrderQunatity: item.moq,
    //     RemainingStock: getRemainingStock(item),
    //     UOM: item.unit_of_measure,
    //     ConditionType: item.condition_type,
    //   });
    // });

    // const fileTitle = "downloadTemplate"; // or 'my-unique-title'
    // exportCSVFile(arrayofData, fileTitle);
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",

          label: (
            <Button style={{ border: "none" }} onClick={uploadCartItems}>
              <div style={{ display: "flex" }}>
                <UploadOutlined
                  style={{ fontSize: "20px", marginLeft: "-10px" }}
                />
                <p style={{ marginLeft: "10px" }}>Upload Template</p>
              </div>
            </Button>
          ),
        },

        {
          key: "2",

          label: (
            <Button style={{ border: "none" }} onClick={DownloadCartItemsCSV}>
              <div style={{ display: "flex" }}>
                <DownloadOutlined
                  style={{
                    fontSize: "20px",

                    marginLeft: "-10px",

                    color: "green",
                  }}
                />
                <p style={{ marginLeft: "10px" }}>Download Template</p>
              </div>
            </Button>
          ),
        },
      ]}
    />
  );

  const convertToCSV = (objArray) => {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    //  str += "\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += `${line}\r\n`;
    }
    return str;
  };
  const exportCSVFile = (items) => {
    // Convert Object to JSON`
    const jsonObject = JSON.stringify(items);

    // eslint-disable-next-line no-use-before-define
    const csv = convertToCSV(jsonObject);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `Orders_Product_list-WC_PD.csv`);
  };

  const handleUploadTemplate = async () => {
    if (!isUploadFileValid) {
      notification.error({ message: "Please select a valid csv file" });
      return;
    }
    try {
      setConfirmUploadLoading(true);
      const res = await uploadCartItemsCSV(CustomerId, uploadfile);
      if (res.status === 200) {
        dispatch(
          getItemsLists(searchWord.toUpperCase(), CustomerId, page, pageSize)
        );
        // dispatch(getCartItemList(CustomerId));
        setConfirmUploadLoading(false);
        setUploadCartPopUp(false);
        // setOpenMessage(true);
        setUploadfile(null);
        fileUploadRef.current.value = "";

        if (res.data.status == 400) {
          notification.error({
            message: res?.data?.error,
          });
        } else {
          notification.success({
            message: res?.data?.result,
          });
        }

        //  dispatch(getStockmoq(pageNo, recordPerPage, debouncedSearchTerm));
      } else {
        message.error(
          res.data?.error && typeof res.data.error === "string"
            ? res.data.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      setConfirmUploadLoading(false);
      setUploadfile(null);
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
    }
  };

  const handleUploadCancel = () => {
    setUploadCartPopUp(false);
    setUploadfile(null);
  };
  const handleEditCancel = () => {
    setEditDelieryDate(false);
    setdeliveryDate(moment(localStorage.getItem("SelectedDeliveryDate")));
  };
  const onHandleMessageClose = () => {
    setOpenMessage(false);
  };
  const onUploadFileChange = (files) => {
    if (!files.length) {
      //   notification.error({ message: "Please Search by Item Name / ID" });
      return;
    }
    let fileName = files[0]?.name;
    let extensionStartIndex = fileName.lastIndexOf(".");
    let extension = fileName.substring(extensionStartIndex);
    // console.log("uplod file");
    if (extension === ".csv") {
      checkUploadFileValid(files[0]);
      setUploadfile(files[0]);
    } else {
      notification.error({ message: "Please select a valid csv file" });
    }
  };
  // const handleSearch = (val) => {
  //   if (val.trim() === "") {
  //     notification.warning({
  //       message: "Search by Item Name/ID",
  //     });
  //   } else if (val !== "") {
  //     dispatch(getItemsLists(val.toUpperCase(), CustomerId, 1, pageSize));
  //     //dispatch(saveSearchedValue(val));
  //   }
  // };
  const handleSearch = (val) => {
    dispatch(getItemsLists(val.toUpperCase(), CustomerId, 1, pageSize));
    //dispatch(saveSearchedValue(val));
  };
  const onEditDeliveryDate = () => {
    setEditDelieryDate(true);
  };
  const onDeliverDateChange = (e) => {
    setSelectedDate(e);
    setdeliveryDate(e);
  };

  const updateDeliveryDate = (e) => {
    localStorage.setItem("SelectedDeliveryDate", selectedDate);
    setEditDelieryDate(false);
  };
  const checkUploadFileValid = (fileToRead) => {
    // const fileToRead = file;
    const reader = new FileReader();
    setIsUploadFileValid(true);
    let isValid = true;
    reader.onload = (event) => {
      // The file's text will be printed here

      const csvData = event.target.result;
      const headers = csvData.slice(0, csvData.indexOf("\n")).split(",");
      headers.pop();
      const requiredHeaders = [
        "ProductName",
        "SAPItemID",
        "Quantity",
        "Price",
        "MinimumOrderQuantity",
        "RemainingStock",
        "UOM",
        "ConditionType",
      ];
      headers.forEach((item, i) => {
        if (item !== requiredHeaders[i]) {
          setIsUploadFileValid(false);
        }
      });
    };
    reader.readAsText(fileToRead);
    // return isValid;
  };

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCurrent(1);
    }, 200);
  }, []);

  return (
    <div className="w-100 h-100" style={{ backgroundColor: "#f5f7fa" }}>
      <div className="px-4">
        <PrimaryHeader title="Place Order" backgroundColor="#f5f7fa" />
      </div>
      <OrderSteps
        current={current}
        onClickBack={() => {
          localStorage.setItem("customer", JSON.stringify({}));
          dispatch(saveSelectedCustomer({}));
          history.push("/dashboard/orders/placeorder");
        }}
      />
      <CustomerDetails />

      <div class="m-4 mt-3 py-4" style={{ backgroundColor: "#fff" }}>
        <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px", marginTop: "1px" }}>
          <div className="d-flex ml-4">
            <div style={{
              marginTop: "1px",
              marginRight: "10px",
            }}>
              <Typography>Delivery Date : {moment(deliveryDate).format("DD/MM/YYYY")}</Typography>


            </div>
          </div>
          <div >
            <img
              src={edit}
              alt="edit"
              id="edit-icon"
              title="edit"
              className="mr-2"
              onClick={onEditDeliveryDate}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "26px",
            marginRight: "20px",
          }}
        >

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="d-flex ml-4">
              <Divider
                type="vertical"
                style={{
                  border: "2px solid #737EED",
                  height: "22px",
                  marginTop: "3px",
                }}
              />
              <div>
                <Typography>Items</Typography>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <span>
              <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </span>
            <span>
              <Divider
                type="vertical"
                style={{
                  border: "1px solid #c4c4c4",
                  height: "35px",
                  marginRight: "-34px",
                }}
              />
            </span>
            <span>
              <div>
                <Link to={productLists}>
                  <Button type="link">
                    <Badge
                      style={{ position: "absolute", right: 0, top: 0 }}
                      count={basketCount}
                    >
                      <i
                        class="fa fa-shopping-basket"
                        style={{
                          color: "blue",
                          fontSize: "20px",
                        }}
                      ></i>
                    </Badge>
                  </Button>
                </Link>
              </div>
            </span>
          </div>
        </div>
        <div
          style={{ marginTop: "10px", marginLeft: "44px" }}
          className="search_input"
        >
          <Search
            style={{ width: "500px" }}
            size="large"
            className="serach_customerswithId"
            placeholder="Search by Item Name / ID"
            enterButton
            onSearch={handleSearch}
            value={searchWord}
            onChange={onSearchChange}
          />
        </div>
        <div>
          <div
            className="m-auto align-items-center"
            style={{ width: "95%", minHeight: 500 }}
          >
            <Row
              className="d-flex flex-wrap"
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            >
              {!loading ? (
                itemsList.length > 0 ? (
                  itemsList?.map((product) => (
                    <ItemCard
                      key={product.uniqueId}
                      product={product}
                      customerId={CustomerId}
                      setMessageInfo={setMessageInfo}
                      setOpenMessage={setOpenMessage}
                      page={page}
                      pageSize={pageSize}
                      searchWord={searchWord}
                    />
                  ))
                ) : (
                  <div
                    className="d-flex w-100"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 200,
                    }}
                  >
                    <div>No items found</div>
                  </div>
                )
              ) : (
                <div
                  className="d-flex w-100"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 200,
                  }}
                >
                  <Spin size="large" />
                </div>
              )}
            </Row>
          </div>
        </div>
        <Space className="my-5 d-flex justify-content-center">
          {totalRecords >= pageSize ? (
            <Pagination
              showSizeChanger={false}
              current={page}
              total={totalRecords}
              pageSize={pageSize}
              onChange={(pageNo, pageSize) => {
                setPage(pageNo);
                // setPageSize(pageSize);
              }}
              size="default"
            />
          ) : (
            ""
          )}
        </Space>
      </div>

      <Modal
        title="Upload"
        visible={uploadCartPopUp}
        onOk={handleUploadTemplate}
        okText="Upload file"
        okButtonProps={{ disabled: !uploadfile }}
        confirmLoading={confirmUploadLoading}
        onCancel={handleUploadCancel}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
        >
          <Typography className="py-2">Cart Items</Typography>

          <label className="upAndDownloadBtn" htmlFor="upload">
            <FileAddOutlined className="btnIcon" />
            <Typography>
              Select csv file (only .csv)
              <input
                type="file"
                style={{ display: "none" }}
                id="upload"
                ref={fileUploadRef}
                accept=".csv"
                onChange={(e) => onUploadFileChange(e.target.files)}
              />
            </Typography>
          </label>
          <Typography className="py-2">{uploadfile?.name}</Typography>
        </div>
      </Modal>
      <Modal
        // title="Upload"
        visible={editDelieryDate}
        //onOk={updateDeliveryDate}
        okText="Save"
        footer={null}
        //okButtonProps={{ disabled: !uploadfile }}
        // confirmLoading={confirmUploadLoading}
        onCancel={handleEditCancel}
        style={{ marginTop: "100px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div> <Divider
              type="vertical"
              style={{
                border: "2px solid #737EED",
                height: "22px",
                marginTop: "8px",
                marginLeft: "-8px"
              }}
            /></div>
            <div> <Typography className="py-2" style={{ marginRight: "276px", fontWeight: "bold" }}>Edit Delivery Date</Typography></div>


          </div>
          <div className="my-4" style={{ width: "-webkit-fill-available" }}>
            <Typography
              style={{
                fontSize: "14px",
                color: "#3A4960cc",
                fontWeight: 400,
              }}
            >
              Choose Delivery Date{" "}
            </Typography>

            <DatePicker
              className="mt-2 chooseDatePicker"
              allowClear={false}
              size="small"
              format="DD/MM/YYYY"
              placement="bottomLeft"
              value={deliveryDate}
              style={{ padding: "11px" }}
              onChange={onDeliverDateChange}
              disabledDate={disabledDate}
            />
          </div>
          <Button type="primary" style={{ marginLeft: "-338px", marginTop: "10px" }} onClick={updateDeliveryDate}>Save</Button>

        </div>
      </Modal>

      <div className={openMessage ? "backdrop" : ""}>
        <div className="customMessage">
          <ErrorAlertMessage
            open={openMessage}
            success={messageInfo.success}
            onHandleClose={onHandleMessageClose}
            title={messageInfo.title}
            subTitle={messageInfo.subTitle}
          />
        </div>
      </div>
    </div>
  );
}

export default PlaceOrder;
