import React from "react";
import "./dropdown.scss";
import InfiniteScroll from "react-infinite-scroll-component";
const Dropdown = ({
  options,
  onClickOption,
  fetchMoreData,
  dataLength,
  from,
}) => {
  if (from === "details") {
    return (
      <ul className={`dd-menu position-asmList`}>
        {options.map((item) => {
          return (
            <li
              key={item.employeeId}
              value={item.firstName + " " + item.lastName}
              onClick={() =>
                onClickOption(item.firstName + " " + item.lastName, item._id)
              }
            >
              {item.firstName + " " + item.lastName}
            </li>
          );
        })}
      </ul>
    );
  } else {
    if (options.length > 0) {
      return (
        <ul className="dd-menu position">
          <InfiniteScroll
            dataLength={dataLength}
            next={fetchMoreData}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            inverse={true}
            hasMore={true}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {options.map((item) => {
              return (
                <li key={item._id} title={item.name}>
                  {item.name + "(" + item.goFrugalId + ")"}
                </li>
              );
            })}
          </InfiniteScroll>
        </ul>
      );
    } else {
      return <ul className="dd-menu position text"> No data </ul>;
    }
  }
};
export default Dropdown;
