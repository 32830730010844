import React, { useState, useEffect } from "react";
import CardLeftContent from "../CardLeftContent";
import { EditOutlined, TeamOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Select,
  Switch,
  Typography,
  notification,
  Table,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import "./manageShipping.scss";

import { actionTypes } from "../../../action/manageShippingaction";
import { dataSource } from "../../products/_tempTableData";
import { getListOfExceptionalCustomeTaleData } from "../../../action/manageShippingaction";
import { updateOrderamountAndShippingCharge } from "../../../services/settings/manageShippingservice";

const ExceptionalCustomers = () => {
  const [editOpen, setEditOpen] = useState(false);

  const [openMessage, setOpenMessage] = React.useState(false);
  const [customers, setCustomersList] = useState([]);
  const { Option } = Select;
  const dispatch = useDispatch();
  const [customerIdList, setCustomerIdList] = useState([]);
  const [orderAmountValue, setOrderAmountValue] = useState();
  const [shippingCharge, setShippingCharge] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  // const [loading, setloading] = useState(false);
  const { loading, listOfEceptionalCustomers, exceptionalCustomerTable } =
    useSelector((state) => state.manageShippingReducer);
  // console.log(listOfEceptionalCustomers, exceptionalCustomerTable, "lkjnhb");
  const { exceptionalCustomerDeliveryDates } = useSelector(
    (state) => state.manageOrder
  );
  // console.log(exceptionalCustomerDeliveryDates, "hhh");
  // const [messageInfo, setMessageInfo] = React.useState({});
  // const { showOrderAmountAndShipppingCharge } = useSelector(
  //   (state) => state.manageShippingReducer.showOrderAmountAndShipppingCharge
  // );
  // console.log(minimumordervaluestt, "ghjkjhghjk");
  const onHandleEditOpen = () => {
    setEditOpen(true);
  };
  const onHandleMessageClose = () => {
    setOpenMessage(false);
  };

  const columns = [
    {
      title: "Customer",
      dataIndex: "customerId",
      render: (_, record) => (
        <div>
          <Typography>{record.customerName}</Typography>
          <Typography style={{ opacity: 0.7 }} className="mt-1">
            {record.customerId}
          </Typography>
        </div>
      ),
    },
    {
      title: "Order Amount",
      dataIndex: "ShippingOrderAmt",
      render: (ShippingOrderAmt) => (
        <div>
          <Typography style={{ opacity: 0.7 }} className="mt-1">
            {ShippingOrderAmt}
          </Typography>
        </div>
      ),
    },
    {
      title: "Shipping Charge",
      dataIndex: "shippingCharge",
      render: (shippingCharge) => (
        <div>
          <Typography style={{ opacity: 0.7 }} className="mt-1">
            {shippingCharge}
          </Typography>
        </div>
      ),
    },
  ];
  const onChangeCustomerList = (val) => {
    // console.log(val);
    setCustomerIdList(val);
  };
  const onChangeShippingCharge = (e) => {
    setShippingCharge(e.target.value);
  };
  // console.log(shippingCharge);
  const onChangeOrderValue = (e) => {
    setOrderAmountValue(e.target.value);
  };
  // console.log(loading);

  const emptyTextFields = () => {
    setCustomerIdList([]);
    setOrderAmountValue("");
    setShippingCharge("");
  };
  const updateOrderamntandshippingCharge = async () => {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.EXCEPTIONAL_UPDATE_ORDER_AMOUNT_AND_SHIPPING,
      });

      const response = await updateOrderamountAndShippingCharge(
        customerIdList.map((item) => item.toString()),
        orderAmountValue,
        shippingCharge
      );
      if (response.status == 200) {
        const { data } = response;
        const message = data.data;
        // console.log(message, "iiii");
        // setEditOpen(false);
        // console.log("dkdkn", data, response.data);
        notification.success({
          message: message,
        });
        emptyTextFields();
        dispatch({
          type: actionTypes.STOP_LOADING,
          load: actionTypes.EXCEPTIONAL_UPDATE_ORDER_AMOUNT_AND_SHIPPING,
        });
        setEditOpen(false);
        dispatch(getListOfExceptionalCustomeTaleData());
      } else if (response.status == 400) {
        setEditOpen(true);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: actionTypes.STOP_LOADING,
        load: actionTypes.EXCEPTIONAL_UPDATE_ORDER_AMOUNT_AND_SHIPPING,
      });
    }
  };
  // useEffect(() => {
  //   getmanageShippingDta();
  // }, []);

  function onClickSaveHandler() {
    updateOrderamntandshippingCharge();
  }

  const getButtonState = () =>
    !shippingCharge || !orderAmountValue || !customerIdList.length;

  return (
    <div className="rounded-lg shadow-sm py-4 px-4 bg-white">
      <div className=" d-flex justify-content-between">
        <CardLeftContent
          icon={<TeamOutlined style={{ fontSize: 60, color: "#717C8D" }} />}
          title="Exceptional Customers"
          subTitle="Manage shipping charges for exceptional customers."
        />
        {editOpen ? null : (
          <div className="d-flex flex-row align-items-center">
            <div>
              {loading.includes(
                actionTypes.GET_LIST_0F_EXCEPTIONAL_CUSTOMER_TABLE
              ) ? (
                <Spin />
              ) : (
                <Typography className="font-weight-bold">
                  {exceptionalCustomerTable?.length || "0"} Customers
                </Typography>
              )}
            </div>
            <div className="p-3 ml-5 d-flex align-items-center">
              <EditOutlined
                style={{ fontSize: 24, color: "#5078F2" }}
                onClick={() => onHandleEditOpen()}
              />
            </div>
          </div>
        )}
      </div>
      {editOpen ? (
        <div className="mt-2" style={{ marginLeft: 160 }}>
          <div className="d-flex flex-column py-2">
            <div className="w-25 pb-4 pt-2">
              <Typography style={{ fontSize: 16 }}>Select Customer:</Typography>
              <Select
                mode="multiple"
                className="datePicker"
                placeholder="Eg: 10012345 - Venkatesh Rao"
                style={{ borderRadius: 30 }}
                value={customerIdList}
                goFrugalId
                onChange={onChangeCustomerList}
              >
                {listOfEceptionalCustomers?.map((customerlist) => (
                  <Option
                    key={customerlist.goFrugalId}
                    value={customerlist.goFrugalId}
                  >
                    <Typography>{`${customerlist.goFrugalId} - ${customerlist.name}`}</Typography>
                  </Option>
                ))}
              </Select>
            </div>
            <div className="d-flex w-100 flex-row align-items-end">
              <div className="mr-3">
                <Typography style={{ fontSize: 16, color: "#3A4960" }}>
                  Order amount :
                </Typography>
                <Input
                  placeholder="₹ 10,000"
                  className="mr-3"
                  style={{ height: 40 }}
                  value={orderAmountValue}
                  onChange={onChangeOrderValue}
                  type="number"
                />
              </div>
              <div className="mr-3">
                <Typography style={{ fontSize: 16, color: "#3A4960" }}>
                  Shipping charge :
                </Typography>
                <Input
                  placeholder="₹ 10,000"
                  className="mr-3"
                  style={{ height: 40 }}
                  value={shippingCharge}
                  onChange={onChangeShippingCharge}
                  type="number"
                />
              </div>
              <div
                style={{
                  position: "fixed",
                  top: 100,
                  right: 45,
                  zIndex: 1010,
                }}
              ></div>
            </div>
            {exceptionalCustomerTable?.length ? (
              <div className="mt-5">
                <Table
                  dataSource={exceptionalCustomerTable}
                  columns={columns}
                  align="center"
                  pagination={{
                    hideOnSinglePage: true,
                    current: page,
                    pageSize: pageSize,
                    onChange: (page, pageSize) => {
                      setPage(page);
                      setPageSize(pageSize);
                    },
                  }}
                  rowKey={(record) => record.goFrugalId}
                />
              </div>
            ) : null}
            <div>
              <div className="mt-5">
                <Button
                  size="large"
                  value="default"
                  className="cancel_button"
                  onClick={() => setEditOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  className="save_button rounded-lg ml-3"
                  onClick={onClickSaveHandler}
                  disabled={getButtonState()}
                  loading={loading.includes(
                    actionTypes.EXCEPTIONAL_UPDATE_ORDER_AMOUNT_AND_SHIPPING
                  )}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ExceptionalCustomers;
