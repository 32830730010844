import React, { Component } from "react";
import "./team.scss";
import add from "../../images/add-new-plan.svg";
import arrow from "../../images/down-arrow.svg";
import activeToggle from "../../images/Toggle.svg";
import inactiveToggle from "../../images/inactive-toggle.svg";
import SearchBar from "../search-bar/searchBar";
import { Link } from "react-router-dom";
import edit from "../../images/edit.svg";
import trash from "../../images/trash.svg";
import { FaAngleUp } from "react-icons/fa";
import {
  getAllAsms,
  toggleStatus,
  removeAsm,
  getSalesmanCount,
  areaSalesManagerDetails,
} from "../../services/teamServices/team";
import { stringSortingByChar } from "../../utils/common";
import DeleteConfirmation from "../modal/deleteConfirmation";
// loader
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import AreaSalesManDetails from "./../modal/areaSalesManDetails";
import { getImageUrl } from "./../../utils/generateImageUrl";
import defaultPic from "../../images/default-pic.jpg";
import ReactTooltip from "react-tooltip";
import { Pagination } from "antd";
import PrimaryHeader from "../header/PrimaryHeader";
import { getProfileDetails } from "../../services/userProfileServices/userProfile";
class TeamAsms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      sortBy: "",
      scroller: false,
      search: "",
      loading: false,
      showDeleteConfirmation: false,
      removeId: null,
      removeItemId: null,
      numberOfSalesmanMapped: 0,
      warningMsg: "",
      displayAreaSalesManDetailsModal: false,
      singleAsmDetails: {},
      modalLoading: false,
      totalPage: 0,
      pageSize: 0,
      totalItems: 0,
      userDetails: {},
    };
  }
  componentDidMount = () => {
    this.getUserDetails();
    this.getAsmList();
  };
  // handle scroll
  handleScroll = (e) => {
    if (e.target.scrollTop > 0) this.setState({ scroller: true });
    else this.setState({ scroller: false });
  };

  // scroll  to top
  scrollToTop = () => {
    let intervalId = setInterval(() => {
      if (this.refs.asmList.scrollTop <= 0) {
        clearInterval(intervalId);
      }
      this.refs.asmList.scrollTop =
        this.refs.asmList.scrollTop - this.refs.asmList.scrollTop * 0.75;
    }, 100);
  };
  getAsmList = async () => {
    this.setState({ loading: true });
    let { page, sortBy, search } = this.state;
    let { getAsms } = this.props;
    let qParams = [{ page }, { sortBy }, { search }];
    await getAllAsms(qParams)
      .then((res) => {
        if (res.data.data.results.length == 0) {
          message.warning("No data available!");
        }
        this.setState({
          loading: false,
          totalPage: res.data.data.pageMeta.total,
          pageSize: res.data.data.pageMeta.pageSize,
          totalItems: res.data.data.pageMeta.total,
        });
        // call action
        getAsms(res.data.data.results);
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  changePageNo = (type) => {
    if (type === "increment") {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          // call api
        }
      );
    } else if (type === "decrement" && this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
        },
        () => {
          // call api
        }
      );
    } else if (type === "decrement" && this.state.page === 1) {
      this.setState({
        page: 1,
      });
    }
  };
  changeToggleStatus = async (item) => {
    this.setState({ loading: true });
    let status = item.status == 1 ? "deactivate" : "activate";
    let id = item._id;
    let type = "asm";
    await toggleStatus(id, status, type)
      .then((res) => {
        {
          status == "activate" && message.success("ASM activated successfully");
        }
        {
          status == "deactivate" &&
            message.success("ASM deactivated successfully");
        }
        this.getAsmList();
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  displayWarningMsg = (no) => {
    let { removeId } = this.state;
    let msg = "";
    if (no == 0) {
      msg = `Are you sure you want to delete the ASM with employee ID ${removeId}?`;
    } else if (no == 1) {
      msg = `Removing the ASM will move the related salesman to the 'Unmapped' list. Are you sure you want to remove the ASM?`;
    } else {
      msg = `Removing the ASM will move the related salesmen to the 'Unmapped' list. Are you sure you want to remove the ASM?`;
    }
    this.setState({
      warningMsg: msg,
    });
  };
  showDeleteConfirmationModal = async (item) => {
    this.setState({ loading: true });
    if (item.status != 0) {
      // call api
      await getSalesmanCount(item._id)
        .then((res) => {
          let numberOfSalesman = res.data.data.numberOfSalesman;

          this.setState({
            numberOfSalesmanMapped: numberOfSalesman > 0 ? numberOfSalesman : 0,
            showDeleteConfirmation: !this.state.showDeleteConfirmation,
            removeId: item.employeeId,
            removeItemId: item._id,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          const { errorMessage, errors } = handleErrorResponse(err);

          this.closeDeleteConfirmationModal();
        });
      this.displayWarningMsg(this.state.numberOfSalesmanMapped);
    } else if (item.status == 0) {
      message.error("Asm is deactivated");
      this.setState({ loading: false });
    }
  };
  closeDeleteConfirmationModal = () => {
    this.setState({
      showDeleteConfirmation: false,
      removeId: null,
      removeItemId: null,
    });
  };
  removeAsm = async () => {
    this.setState({ loading: true });
    let id = this.state.removeItemId;
    // call api with this id
    await removeAsm(id)
      .then((res) => {
        message.success("ASM deleted successfully");
        this.closeDeleteConfirmationModal();
        this.getAsmList();
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        this.closeDeleteConfirmationModal();
      });
  };
  onSearch = (e) => {
    this.setState(
      {
        search: e.target.value,
      },
      () => {
        this.getAsmList();
      }
    );
  };

  openAreaSalesManDetailsModal = async (item) => {
    this.setState({ modalLoading: true });

    await areaSalesManagerDetails(item._id)
      .then((res) => {
        this.setState({
          singleAsmDetails: res.data.data,
          modalLoading: false,
        });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        console.log(err);
      });

    this.setState({
      displayAreaSalesManDetailsModal:
        !this.state.displayAreaSalesManDetailsModal,
    });
  };

  handleChange = (page, pageSize) => {
    console.log("handleChange is called", page, pageSize);
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.getAsmList();
      }
    );
  };

  getUserDetails = async () => {
    await getProfileDetails()
      .then((res) => {
        this.setState({
          loading: false,
          asmApprovalRequired:
            res.data.data && res.data.data.asmApprovalRequired,
          userDetails: res.data.data,
        });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        this.setState({ loading: false });
      });
  };

  render() {
    const { userDetails } = this.state;
    console.log(userDetails);
    const dynamic = [
      userDetails && userDetails.isStandalone,
      userDetails && userDetails.warehouse && userDetails.warehouse.isHybrid,
    ].every((ele) => ele === true);
    const companyCode = userDetails && userDetails.companyCode;
    const Standalone = userDetails && userDetails.isStandalone;
    const accessRole = userDetails && userDetails.accessRole;
    console.log(`${dynamic},${companyCode}`);
    let asmId;
    {
      this.props.allAsmDetails.map((item, i) => {
        return (asmId = item._id);
      });
    }
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let {
      loading,
      scroller,
      numberOfSalesmanMapped,
      removeId,
      page,
      totalPage,
      pageSize,
      totalItems,
    } = this.state;
    let { allAsmDetails } = this.props;

    return (
      <div id="content">
        <PrimaryHeader title="ASMs" />
        <div className="asm-header">
          <SearchBar searchText={this.state.search} onSearch={this.onSearch} />
          {Standalone == true ? (
            <Link
              to={{
                pathname: "/dashboard/team/asms/new",
                edit: false,
              }}
              className="id-color"
            >
              <img
                src={add}
                alt="add asm"
                className="cursor"
                title="Add New Area Sales Manager"
              />
            </Link>
          ) : accessRole == 1 ? (
            <Link
              to={{
                pathname: "/dashboard/team/asms/new",
                edit: false,
              }}
              className="id-color"
            >
              <img
                src={add}
                alt="add asm"
                className="cursor"
                title="Add New Area Sales Manager"
              />
            </Link>
          ) : (
            ""
          )}
        </div>
        <div
          className="table-wrapper"
          // ref="asmList"
          // onScroll={this.handleScroll}
        >
          <Spin indicator={antIcon} spinning={loading}>
            <table className="table">
              <thead>
                <tr>
                  {/* headers */}
                  <th>Employee</th>
                  <th>Employee ID</th>
                  <th>Employer</th>
                  {/* <th>Outstanding(&#8377;)</th>
                      <th>Overdue(&#8377;)</th> */}
                  <th>No of Salesman</th>
                  {Standalone != true && accessRole == 1 ? (
                    <th>Distributor</th>
                  ) : (
                    ""
                  )}

                  <th className="change-style" id="status-color">
                    Status
                  </th>

                  <th>Manager </th>
                  {Standalone == true ? (
                    <th>Action</th>
                  ) : accessRole == 1 ? (
                    <th>Action</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>

              {allAsmDetails.length > 0 && (
                <tbody>
                  {allAsmDetails.map((item, i) => {
                    return (
                      <tr
                        className={item.status == 0 ? "disabled" : ""}
                        key={i}
                      >
                        <td
                          className="change-style on-Hover cursor"
                          title={item.firstName + " " + item.lastName}
                          onClick={() =>
                            item.status == 1 &&
                            this.openAreaSalesManDetailsModal(item)
                          }
                        >
                          {item.profilePic ? (
                            <img
                              src={getImageUrl + item.profilePic}
                              style={{ borderRadius: "50%" }}
                              alt="logo"
                              id="user-logo"
                            />
                          ) : (
                            <img
                              src={defaultPic}
                              style={{
                                borderRadius: "50%",
                                width: "20px",
                                height: "20px",
                                marginRight: "5px",
                              }}
                            />
                          )}

                          {stringSortingByChar(
                            item.firstName + " " + item.lastName,
                            20
                          )}
                        </td>

                        {/* emp id */}
                        <td>{item.employeeId}</td>
                        <td title={item.employerName}>{item.employerName}</td>

                        {/* <td>{formatMoney(null)}</td>
                            
                            <td>{formatMoney(null)}</td> */}
                        {/* salesmen */}

                        <td
                          data-tip={item.salesmanMapping.map((name, i) => {
                            return name.fullName;
                          })}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          <ReactTooltip className="tooltip" multiline={true} />
                          {item.numberOfSalesman}
                        </td>
                        {Standalone != true && accessRole == 1 ? (
                          <td>{item.distributorName}</td>
                        ) : (
                          ""
                        )}
                        {Standalone == true ? (
                          <td onClick={() => this.changeToggleStatus(item)}>
                            <img
                              src={
                                item.status == 1 ? activeToggle : inactiveToggle
                              }
                              alt="toggle"
                              name={item.status}
                            />
                          </td>
                        ) : accessRole == 1 ? (
                          <td onClick={() => this.changeToggleStatus(item)}>
                            <img
                              src={
                                item.status == 1 ? activeToggle : inactiveToggle
                              }
                              alt="toggle"
                              name={item.status}
                            />
                          </td>
                        ) : (
                          <td>
                            <div className="d-flex">
                              <div
                                className={`mt-1 ${
                                  item.status == 1
                                    ? "active-dot"
                                    : "inActive-dot"
                                }`}
                              ></div>
                              <div className="ml-2">
                                {item.status == 1 ? "Active" : "Inactive"}
                              </div>
                            </div>
                          </td>
                        )}

                        {item.reportingTo ? (
                          <td title={item.reportingTo.name}>
                            {stringSortingByChar(item.reportingTo.name, 16)}{" "}
                          </td>
                        ) : (
                          <div style={{ marginLeft: "1rem" }}>N/A</div>
                        )}
                        {Standalone == true ? (
                          <td>
                            {" "}
                            <Link
                              to={{
                                pathname: `/dashboard/team/asms/edit/${item._id}`,
                                query: item._id,
                                edit: true,
                                employeeId: item.employeeId,
                              }}
                              className="id-color"
                              disabled={item.status == 0 ? true : false}
                            >
                              {/* {item.isWaycoolEmp ? ( */}
                              <img src={edit} alt="edit" className="pr-2" />
                              {/* ) : (
                              " "
                            )}  */}
                            </Link>
                            <img
                              //    className={item.isWaycoolEmp ?"":"ml-4"}
                              src={trash}
                              alt="delete"
                              onClick={() =>
                                this.showDeleteConfirmationModal(item)
                              }
                            />
                          </td>
                        ) : accessRole == 1 ? (
                          <td>
                            {" "}
                            <Link
                              to={{
                                pathname: `/dashboard/team/asms/edit/${item._id}`,
                                query: item._id,
                                edit: true,
                                employeeId: item.employeeId,
                              }}
                              className="id-color"
                              disabled={item.status == 0 ? true : false}
                            >
                              {/* {item.isWaycoolEmp ? ( */}
                              <img src={edit} alt="edit" className="pr-2" />
                              {/* ) : (
                              " "
                            )}  */}
                            </Link>
                            {/* <img
                            //    className={item.isWaycoolEmp ?"":"ml-4"}
                            src={trash}
                            alt="delete"
                            onClick={() =>
                              this.showDeleteConfirmationModal(item)
                            }
                          /> */}
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </Spin>
        </div>

        <button
          title="Back to top"
          className={`scroll ${scroller ? "" : "invisible"}`}
          onClick={() => {
            this.scrollToTop();
          }}
        >
          <FaAngleUp className="arrow" />
        </button>
        {this.state.showDeleteConfirmation && (
          <DeleteConfirmation
            closeDeleteConfirmationModal={this.closeDeleteConfirmationModal}
            removeId={this.state.removeId}
            header={"Remove ASM"}
            warningMsg={this.state.warningMsg}
            removeItem={this.removeAsm}
          />
        )}

        {this.state.displayAreaSalesManDetailsModal && (
          <AreaSalesManDetails
            displayModal={this.openAreaSalesManDetailsModal}
            details={this.state.singleAsmDetails}
            modalLoading={this.state.modalLoading}
          />
        )}
        {/* pagination */}
        <div className="pagination">
          {totalItems != 0 && <p className="p-2">Total {totalItems} Asms </p>}
          {totalPage != 0 && (
            <Pagination
              className="page"
              defaultCurrent={1}
              current={page}
              total={totalPage}
              defaultPageSize={pageSize}
              showSizeChanger={false}
              onChange={(page, pageSize) => this.handleChange(page, pageSize)}
            />
          )}
          {/*<ScrollToTop smooth />*/}
          {/* <div
              className="toTop"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              &#8593;
            </div> */}
        </div>
      </div>
    );
  }
}
export default TeamAsms;
