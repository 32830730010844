import React from "react";
import "./login.scss";
import logo from "../../images/new_logo.svg";

export const TopDiv = () => {
  return (
    <div className="login-top-div">
      <img src={logo} className="garuda-logo" />
      <div>
        <p className="title">GARUDA</p>
        <p className="sub-title">Distributor Portal</p>
      </div>
    </div>
  );
};
export const RightSideDiv = ({ message }) => {
  return (
    <div className="login-left-div">
      <div className="image-title">
        <p className="welcome-para">Welcome To DMS</p>
        <p className="small-para">{message}</p>
      </div>
    </div>
  );
};
