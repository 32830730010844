import { CalendarOutlined, EditOutlined } from "@ant-design/icons";
import { Button, notification, Select, Spin, Switch, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionTypes,
  getDeliveryDates,
  getListOfExceptionalCustomerDeliveryDates,
} from "../../../action/manageOrder";
import { updateDeliveryDates } from "../../../services/settings/manageOrder";
import CardLeftContent from "../CardLeftContent";
// Styles
import "./styles.scss";
import { initialState, reducer } from "./_deliveryDatedReducer";
import { deliveryDateOptions, weekDays } from "./_deliveryOptions";

const { Option } = Select;
const { Text } = Typography;

const DeliveryDates = () => {
  // USE REDUCER HOOK
  const [state, dispatchLocal] = useReducer(reducer, initialState);

  const {
    deliveryDates: { earliestDay, lastDay, excludedDays },
    loading,
  } = useSelector((state) => state.manageOrder);
  const dispatch = useDispatch();

  // Destructuring state from reducer
  const {
    editOpen,
    earliestDelDate,
    lastDelDate,
    excludedDay,
    errorMessage,
    deliveryDateChecked,
  } = state;
  console.log(excludedDays);
  let firsDeltDayKey = deliveryDateOptions.filter(
    (day) => day.value == earliestDay
  )[0]?.key;

  let lastDelDayKey = deliveryDateOptions.filter(
    (day) => day.value == lastDay
  )[0]?.key;
  let firsDeltDayValue = deliveryDateOptions.filter(
    (day) => day.value == earliestDay
  )[0]?.value;

  let lastDelDayValue = deliveryDateOptions.filter(
    (day) => day.value == lastDay
  )[0]?.value;

  //#region On click Save Handler
  // const onEarlyDayChange = (val) => {
  //   console.log(val);
  //   setDefaultStartDay(val);
  //   dispatchLocal({
  //     type: "SET_EARLY_DELIVERY_DATE",
  //     value: val,
  //   });
  // };
  const onClickEditHandler = () => {
    dispatchLocal({ type: "EDIT_OPEN" });
    dispatchLocal({
      type: "SET_EARLY_DELIVERY_DATE",
      value: firsDeltDayValue,
    });
    dispatchLocal({
      type: "SET_LAST_DELIVERY_DATE",
      value: lastDelDayValue,
    });
    dispatchLocal({ type: "ON_CHANGE_EXCLUDED_DAYS", value: excludedDays });
  };
  const onClickSaveHandler = async () => {
    try {
      if (excludedDay.length <= 6) {
        dispatch({
          type: actionTypes.LOADING,
          load: actionTypes.UPDATE_DELIVERY_DATES,
        });

        let sortedExcludedDays = weekDays
          .filter((day) => excludedDay.includes(day.key))
          .map((day) => day.key);

        const res = await updateDeliveryDates(
          earliestDelDate,
          lastDelDate,
          sortedExcludedDays,
          deliveryDateChecked
        );

        if (res.status === 200) {
          const { data } = res;
          const message = data.data;
          dispatch(getDeliveryDates());
          dispatchLocal({ type: "EMPTY_ALL_FIELDS" });
          notification.success({
            message: message,
          });
          if (deliveryDateChecked) {
            dispatch(getListOfExceptionalCustomerDeliveryDates());
          }
        }
      } else if (excludedDay.length >= 6) {
        notification.warning({
          message: "All days are not excluded",
        });
      }
      dispatch({
        type: actionTypes.STOP_LOADING,
        load: actionTypes.UPDATE_DELIVERY_DATES,
      });
    } catch (error) {
      console.log({ error });
      dispatch({
        type: actionTypes.STOP_LOADING,
        load: actionTypes.UPDATE_DELIVERY_DATES,
      });
    }
  };

  //#endregion

  const getDateFromTodayWithFormat = (days) =>
    days === 0 || days ? moment().add(days, "d").format("DD MMMM YYYY") : null;

  const getButtonDisableState = () => {
    if (!earliestDelDate || !lastDelDate) return true;
    if (parseInt(earliestDelDate) === 0 && parseInt(lastDelDate) === 0)
      return false;
    // if (parseInt(earliestDelDate) === parseInt(lastDelDate)) return true;
    if (parseInt(earliestDelDate) > parseInt(lastDelDate)) return true;
    return false;
  };

  useEffect(() => {
    if (!earliestDelDate || !lastDelDate) return;
    // if (parseInt(earliestDelDate) === 0 && parseInt(lastDelDate) === 0) return;
    // if (parseInt(earliestDelDate) === parseInt(lastDelDate))
    //   dispatchLocal({
    //     type: "SET_ERROR_MESSAGE",
    //     value: "Both Earliest Delivery Day and Last Delivery Day can't be same",
    //   });

    if (parseInt(earliestDelDate) > parseInt(lastDelDate))
      dispatchLocal({
        type: "SET_ERROR_MESSAGE",
        value: "Last Delivery Date Must be Greater Than Earliest Delivery Date",
      });
  }, [earliestDelDate, lastDelDate]);

  return (
    <div className="rounded-lg shadow-sm py-4 px-4 bg-white">
      <div className=" d-flex justify-content-between">
        <CardLeftContent
          icon={<CalendarOutlined style={{ fontSize: 60, color: "#717C8D" }} />}
          title="Set Delivery Window"
          subTitle="Earliest to Lastest date for delivery."
        />
        {editOpen ? null : (
          <div className="d-flex flex-row">
            <div className="d-flex justify-content-center flex-column">
              {loading.includes(actionTypes.GET_DELIVERY_DATES) ? (
                <Spin />
              ) : (
                <Typography
                  className="font-weight-bold"
                  style={{ fontSize: 16, color: "#3A4960", textAlign: "right" }}
                >
                  {earliestDay === 0 ? (
                    <Typography style={{ color: "red", textAlign: "right" }}>
                      Active
                    </Typography>
                  ) : null}
                  {earliestDay === 0 && lastDay === 0
                    ? "Same day Delivery"
                    : earliestDay === lastDay
                    ? `${getDateFromTodayWithFormat(earliestDay)}`
                    : `${getDateFromTodayWithFormat(earliestDay)} to 
                  ${getDateFromTodayWithFormat(lastDay)}`}
                  <br />
                  {excludedDays?.length > 0 ? "Excluded Days - " : null}
                  {excludedDays?.length > 0
                    ? excludedDays?.map((item) => (
                        <Typography
                          key={item}
                          className="d-inline weekdaysChips"
                        >
                          {item.substring(0, 3)}
                        </Typography>
                      ))
                    : null}
                </Typography>
              )}
            </div>
            <div className="p-3 ml-5 d-flex align-items-center">
              <EditOutlined
                style={{ fontSize: 24, color: "#5078F2" }}
                // onClick={() => dispatchLocal({ type: "EDIT_OPEN" })}
                onClick={onClickEditHandler}
              />
            </div>
          </div>
        )}
      </div>
      {editOpen ? (
        <div className="my-3" style={{ marginLeft: 160 }}>
          <div className="d-flex w-100 flex-row">
            <div className="w-25">
              <Typography style={{ fontSize: 16 }} className="my-1">
                Earliest Delivery Day:
              </Typography>

              <Select
                className="datePicker"
                placeholder="0 (Same Day Delivery)"
                defaultValue={{
                  value: firsDeltDayKey,
                  label: firsDeltDayKey,
                }}
                onChange={(val) =>
                  dispatchLocal({
                    type: "SET_EARLY_DELIVERY_DATE",
                    value: val,
                  })
                }
              >
                {deliveryDateOptions.map((opt) => (
                  <Option value={opt.value} key={opt.value}>
                    {/*  disabled={parseInt(opt.value) > parseInt(lastDelDate) - 1}*/}
                    {opt.key}
                  </Option>
                ))}
              </Select>
              <Typography
                className="mt-2"
                style={{
                  color: "#219653",
                  textTransform: "uppercase",
                  fontSize: 12,
                }}
              >
                From the date of order
              </Typography>
            </div>
            <div className="mr-5" />
            <div className="w-25">
              <Typography style={{ fontSize: 16 }} className="my-1">
                Last Delivery Day :
              </Typography>
              <Select
                className="datePicker"
                placeholder="4 days"
                defaultValue={{
                  value: lastDelDayKey,
                  label: lastDelDayKey,
                }}
                onChange={(val) =>
                  dispatchLocal({
                    type: "SET_LAST_DELIVERY_DATE",
                    value: val,
                  })
                }
              >
                {deliveryDateOptions.map((opt) => (
                  <Option value={opt.value} key={opt.value}>
                    {opt.key}
                  </Option>
                ))}
              </Select>
              <Typography
                className="mt-2"
                style={{
                  color: "#219653",
                  textTransform: "uppercase",
                  fontSize: 12,
                }}
              >
                From the date of order
              </Typography>
            </div>
            <div className="mr-5" />
            <div className="w-25">
              <Typography style={{ fontSize: 16 }} className="my-1">
                Days Excluded :
              </Typography>
              <Select
                className="datePicker"
                placeholder="Eg. Sunday"
                mode="multiple"
                defaultValue={excludedDays}
                onChange={(val) =>
                  dispatchLocal({ type: "ON_CHANGE_EXCLUDED_DAYS", value: val })
                }
                // value={excludedDay}
              >
                {weekDays.map((day) => (
                  <Option value={day.key}>{day.value}</Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="d-flex my-3">
            <Switch
              checked={deliveryDateChecked}
              onChange={(checkState) =>
                dispatchLocal({
                  type: "DELIVERY_DATE_CHECKED",
                  value: checkState,
                })
              }
            />
            <Typography style={{ color: "#3A4960" }} className="ml-4">
              Enabling this will set shipping charges for Exceptional Customers
              also.
            </Typography>
          </div>
          <div
            className="mt-4 d-flex justify-content-between flex-column"
            // style={{ paddingTop: errorMessage ? "0" : "24px" }}
          >
            <Text type="danger" className="py-2">
              {errorMessage}
            </Text>
            <div
              className="d-flex justify-content-between"
              style={{ width: 200 }}
            >
              <Button
                onClick={() => dispatchLocal({ type: "EMPTY_ALL_FIELDS" })}
                size="large"
                value="default"
                className="cancel_button"
                disabled={loading.includes(actionTypes.UPDATE_DELIVERY_DATES)}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="rounded-lg save_button"
                style={{
                  backgroundColor: "#5078F2",
                  color: "#fff",
                }}
                onClick={onClickSaveHandler}
                disabled={getButtonDisableState()}
                loading={loading.includes(actionTypes.UPDATE_DELIVERY_DATES)}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DeliveryDates;

// TODO: On cancel make the check to fasle
