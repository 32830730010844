import React from "react";
import PrimaryHeader from "../../components/header/PrimaryHeader";
import { useState, useEffect } from "react";
import {
  Spin,
  Pagination,
  Tabs,
  DatePicker,
  Space,
  Modal,
  Input,
  Checkbox,
  Avatar,
} from "antd";
import { AsyncPaginate } from "react-select-async-paginate";
import { over, update, upperFirst } from "lodash";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import debounce from "debounce-async";
import moment from "moment";
import arrowleft from "../../images/arrow-left.jpg";
import profilePic from "../../../src/images/default-pic.jpg";
import success from "../../images/lf20_s2lryxtd.json].svg";
import tick from "../../images/tick.svg";
import refresh from "../../images/refresh-cw.svg";
import "./salesOrder.scss";
import { formatMoney } from "../../utils/common";

import { LoadingOutlined } from "@ant-design/icons";
import Edit from "../../images/edit-3.svg";
import Tick from "../../images/Vector.svg";
import Cross from "../../images/cross(1).svg";

import { getSalesReturnView } from "./salesOrderReturnServices";
const antIcon = <LoadingOutlined className="loadingIcon" spin />;

export default function SalesOrderReturnView(props) {
  const [id, setId] = useState("");
  const [detail, setDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemArr, setItemArr] = useState([]);
  const getDetail = async () => {
    setLoading(true);
    setId(props.match.params.id);
    await getSalesReturnView(props.match.params.id)
      .then((res) => {
        setLoading(false);
        console.log(res.data.data[0]);
        console.log(
          res.data.data &&
            res.data.data.length > 0 &&
            res.data.data[0]["Order Break Dowm"] &&
            res.data.data[0]["Order Break Dowm"]
        );
        setDetail(res.data.data[0]);
        setItemArr(
          res.data.data &&
            res.data.data.length > 0 &&
            res.data.data[0]["Order Break Dowm"] &&
            res.data.data[0]["Order Break Dowm"]
        );
      })

      .catch((err) => {
        console.log(err, "errrr");
        setLoading(false);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  useEffect(() => {
    console.log(props.location.state.invoiceId);
    getDetail();
  }, []);

  const navigateToBack = () => {
    props.history.push("/dashboard/sales-order/sales-order-return");
  };
  const itemsArr =
    itemArr.length > 0 &&
    itemArr.map((item) => {
      return (
        <tr>
          <td>
            {item.itemName}
            <p>{item.itemId}</p>
          </td>
          <td>{item.invoicedQty}</td>
          <td>{item.returnQty}</td>
          <td>{item.remarks}</td>
        </tr>
      );
    });
  return (
      <div id="content" className="">
          <PrimaryHeader
              title={`Sales return ID # ${
                  props.location.state && props.location.state.invoiceId
              }`}
          />
          <>
              <img src={arrowleft} onClick={navigateToBack} />
              <span className="ml-2 cursor" style={{ color: "#5078F2" }}>
                  Back
              </span>
          </>
          <Spin indicator={antIcon} spinning={loading}>
              <div
                  className="card ml-2 mt-4"
                  style={{ width: "100%", backgroundColor: "#FAFAFA" }}
              >
                  <div class="card-body">
                      <p className="border_left subheader mt-2">
                          Customer Details
                      </p>
                      <div className="row">
                          <div className="d-flex align-items-center col-lg-4 mt-2">
                              <div className="pr-4">
                                  <Avatar size={60} src={profilePic} />
                              </div>
                              <div className="pt-2">
                                  <p className="subheader">
                                      {detail && detail["Customer Name"]}
                                  </p>
                                  <p className="subHeaderName ">
                                      Customer ID :{" "}
                                      {detail && detail["Customer ID"]}
                                  </p>
                              </div>
                              {/* <img
                  className="ml-1 mr-2 float-left"
                  src={profilePic}
                  style={{ height: "40px", width: "40px" }}
                />  */}
                          </div>
                          <div className="col-lg-3 mt-4 ml-1 border_img">
                              <p className="headerName">Plant</p>
                              <p className="subHeaderName">
                                  {detail && detail["Plant Name"]}
                              </p>
                          </div>

                          <div className="col-lg-3 mt-4 ml-1 ">
                              <p className="headerName">Location</p>
                              <p className="subHeaderName">
                                  {detail && detail.Location}
                              </p>
                          </div>
                      </div>
                  </div>
              </div>

              <div
                  className="card ml-2 mt-4"
                  style={{ width: "100%", backgroundColor: "#FAFAFA" }}
              >
                  <div class="card-body">
                      <p className="border_left subheader mt-2">
                          Basic Details
                      </p>
                      <div className="row">
                          <div className="col-lg-2 mt-4 ">
                              <p className="headerName">SO Number</p>
                              <p className="subTitle">
                                  {detail && detail["SO Number"]}
                              </p>
                          </div>

                          <div className="col-lg-2 mt-4 border_basic">
                              <p className="headerName">Item Count</p>
                              <p className="subHeaderName">
                                  {detail && detail["Item Count"]}
                              </p>
                          </div>

                          <div className="col-lg-2 mt-4  ">
                              <p className="headerName">Order Date</p>
                              <p className="subHeaderName">
                                  {detail &&
                                      moment(detail["Order Date"]).format(
                                          "DD/MM/YYYY"
                                      )}
                              </p>
                          </div>

                          <div className="col-lg-2 mt-4  ">
                              <p className="headerName">Delivery Date</p>
                              <p className="subHeaderName">
                                  {detail &&
                                      moment(detail["Deliver Date"]).format(
                                          "DD/MM/YYYY"
                                      )}
                              </p>
                          </div>

                          <div className="col-lg-2 mt-4  ">
                              <p className="headerName">Payment</p>
                              <p className="subHeaderName">
                                  {detail && detail.payment}
                              </p>
                          </div>

                          <div className="col-lg-2 mt-4  ">
                              <p className="headerName">Total Amount</p>
                              <p className="totalAmt ">
                                  &#8377; {detail && detail["Total Amount"]}
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
              <div
                  className="card ml-2 mt-4"
                  style={{ width: "100%", backgroundColor: "#FAFAFA" }}
              >
                  <div class="card-body">
                      <p className="border_left subheader mt-2">
                          Order Breakdown
                      </p>

                      <div
                          className="mt-4  table-wrapper table-responsive"
                          id="print_table"
                      >
                          <table className="table common-table">
                              <thead className="remove-border-top">
                                  <th>Item Details</th>

                                  <th>Invoiced Qty</th>
                                  <th>Return Qty</th>
                                  <th>Remarks</th>
                              </thead>
                              <tbody>
                                  {itemsArr && itemsArr}
                                  {itemsArr.length === 0 && (
                                      <div className="no-data">
                                          <p>No Data Available</p>
                                      </div>
                                  )}
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </Spin>
      </div>
  );
}
