import axios from "axios";
// import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";

const APIConfig = {
  base_url: "http://13.76.40.217:3002",
};

export const getTargetSettingList = async (type, page, search) => {
  console.log(page);
  let request = await axios.get(
    APIConfig.base_url +
      `/api/v1/target/distributor/list?page=${page}&search=${search}&type=${type}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//target setting details
export const getTargetSettingDetails = async (id) => {
  let request = await axios.get(
    APIConfig.base_url + `/api/v1/target/distributor/target/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//download csv
export const downloadCsv = async (id) => {
  let request = await axios.get(
    APIConfig.base_url + `/api/v1/target/distributor/target/${id}/csv-download`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const addComment = async (id, type, input) => {
  let request = await axios.patch(
    APIConfig.base_url + `/api/v1/target/distributor/target/${id}/mark/${type}`,
    { ...input },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
