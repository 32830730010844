import {
  getCartItems,
  deleteCartItemService,
  addToCart as addToCartService,
  clearCartService,
} from "../services/orderServices/cartService";
import generateActionTypes from "../utils/generateActionTypes";
import { handleErrorResponse } from "../utils/Request";
import {
  getBasketCount,
  getItemsLists,
  outOfStockError,
} from "./itemListactions";
import { getPaymentDetails } from "./paymentDetailsaction";
import message from "antd/lib/message";
import { notification } from "antd";
export const actionTypes = generateActionTypes(
  "GET_CART_ITEMS_LIST",
  "LOADING_CART",
  "LOADING_ITEM_ACTION",
  "STOP_LOADING_ITEM_ACTION",
  "STOP_LOADING",
  "ADD_TO_CART",
  "UPDATE_CART",
  "EMPTY_CART",
  "DELETE_FROM_CART",
  "OUT_OF_STOCK_ERROR_IN_CART",
  "REMOVE_OUT_OF_STOCK_ERROR_IN_CART"
);

export const outOfStockErrorInCart = (uniqueId) => {
  return async (dispatch) => {
    dispatch({ type: "OUT_OF_STOCK_ERROR_IN_CART", uniqueId: uniqueId });
  };
};

export const removeOutOfStockItemFromError = (uniqueId) => {
  return async (dispatch) => {
    dispatch({ type: "REMOVE_OUT_OF_STOCK_ERROR_IN_CART", uniqueId: uniqueId });
  };
};

const _getCartItemList = async (CustomerId) => {
  try {
    const res = await getCartItems(CustomerId);
    const resData = res?.data?.result;
    const status = res?.data?.status;
    // Need to ignore the category and extract the data from the response
    const extractedData = [];
    if (resData?.priceStatus) {
      notification.error({
        message:
          "Pricing is not available for few selected item(s). Kindly clear the cart and add the items again to order.",
      });
    }
    if (!resData.items) return { arrangedData: [], status: 200, metaData: {} };
    resData.items.forEach((eachCartCategory) =>
      eachCartCategory.data.forEach((item) => {
        extractedData.push(item);
      })
    );

    const arrangedData = extractedData.map((record) => {
      let flattDiscount;
      if (record.discount[0] && record.discount[0]?.flat_discount) {
        flattDiscount =
          record.discount[0] && record.discount[0]?.flat_discount[0];
      }

      const { moq, stock, orderQuantity } = record.inventory[0];

      return {
        itemId: record.itemId,
        uniqueId: record.itemId.concat(record.uom.concat(record.conditionType)),
        price: record.price,
        offerPrice: record.offerPrice,
        remaining_stock: record.remaining_stock,
        savedAmount: record.savedAmount,
        total: record.total,
        brand: record.brand,
        conditionType: record.conditionType,
        unit_of_measure: record.uom,
        imageUrl: record.imageUrl,
        name: record.itemName,
        remaining_stock: record.remaining_stock,
        quantity: record.quantity,
        displayQty: record.displayQty,
        moq: moq,
        stock: stock,
        orderQuantity: orderQuantity,
        outOfStock: record.remaining_stock === 0,
        discount: record.discount.sort(
          (a, b) => b.min_quantity - a.min_quantity
        ),
        flattDiscount,
      };
    });

    const metaData = {
      itemsCount: resData.itemsCount,
      netTotal: resData.netTotal,
      savedTotal: resData.savedTotal,
      subTotal: resData.subTotal,
    };
    return { arrangedData, status, metaData };
  } catch (err) {
    //console.log(err, "error");
    const { errorMessage, errors } = handleErrorResponse(err);
  }
};

export const getCartItemList = (CustomerId) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING_CART,
    });
    try {
      const { arrangedData, status, metaData } = await _getCartItemList(
        CustomerId
      );

      if (status === 200) {
        dispatch({
          type: actionTypes.GET_CART_ITEMS_LIST,
          data: [...arrangedData],
          metaData,
        });
      }
    } catch (err) {
      console.log(err, "error");
      const { errorMessage, errors } = handleErrorResponse(err);
    }
  };
};

export const stopItemLoading = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.STOP_LOADING_ITEM_ACTION,
    });
  };
};

export const addToCart = (
  reqData,
  { searchWord, customerId, page, pageSize }
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOADING_ITEM_ACTION,
        load: reqData.uniqueId,
      });

      const res = await addToCartService(reqData);
      if (res.data.status === 200) {
        dispatch(
          getItemsLists(
            searchWord.toUpperCase(),
            customerId,
            0,
            pageSize,
            true,
            reqData.uniqueId
          )
        );
        dispatch(outOfStockError(null));
      } else if (
        res.data.status === 400 &&
        res.data.error === "Out of stock!!!"
      ) {
        //  TODO: stop loading also
        dispatch(outOfStockError(reqData.uniqueId));
      } else {
        message.error(res?.data?.error);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
    }
  };
};

export const emptyCart = () => {
  return async (dispatch) => {
    //   TODO: making API call pending
    dispatch({ type: actionTypes.EMPTY_CART });
  };
};
export const updateCart = (reqData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOADING_CART,
        load: reqData.itemId,
      });
      const res = await addToCartService(reqData);

      if (res.status === 200) {
        if (res.data.status === 200) {
          const { arrangedData, status, metaData } = await _getCartItemList(
            reqData.customerId
          );
          if (status === 200) {
            dispatch({
              type: actionTypes.GET_CART_ITEMS_LIST,
              data: [...arrangedData],
              metaData,
            });
            dispatch(getPaymentDetails(reqData.customerId));
          }
        } else if (
          res.data.status === 400 &&
          res.data.error === "Out of stock!!!"
        ) {
          //  TODO: stop loading also
          const { itemId, conditionType, uom } = reqData;
          let uniqueId = itemId.concat(uom.concat(conditionType));

          dispatch(outOfStockErrorInCart(uniqueId));
        }
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
    }

    // dispatch({ type: actionTypes.UPDATE_CART, data: { material, qty } });
  };
};

// export const deleteFromCart = (item) => {
//   return async (dispatch) => {
//     //   TODO: making API call pending
//     dispatch({ type: actionTypes.DELETE_FROM_CART, data: item });
//   };
// };

export const deleteFromCart = (CustomerId, item, itemListMetaData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOADING_ITEM_ACTION,
        load: item[0].uniqueId,
      });
      const res = await deleteCartItemService(CustomerId, item);
      const resData = res?.data;

      if (resData.status === 200) {
        if (itemListMetaData && Object.keys(itemListMetaData).length) {
          const { searchWord, customerId, page, pageSize } = itemListMetaData;
          dispatch(
            getItemsLists(
              searchWord,
              customerId,
              page,
              pageSize,
              true,
              item[0].uniqueId
            )
          );
          dispatch(outOfStockError(null));
          return;
        }
        const { arrangedData, status, metaData } = await _getCartItemList(
          CustomerId
        );

        if (status === 200) {
          dispatch({
            type: actionTypes.GET_CART_ITEMS_LIST,
            data: [...arrangedData],
            metaData,
          });
          dispatch(getPaymentDetails(CustomerId));
        }
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      console.log(err, "error");
      if (err?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Something went wrong");
      }
    }
  };
};
export const clearCartItems = (CustomerId) => {
  return async (dispatch) => {
    try {
      const res = await clearCartService(CustomerId);
      const resData = res?.data;

      if (resData.status === 200) {
        const { arrangedData, status, metaData } = await _getCartItemList(
          CustomerId
        );

        if (status === 200) {
          dispatch({
            type: actionTypes.GET_CART_ITEMS_LIST,
            data: [...arrangedData],
            metaData,
          });
        }
        dispatch(getPaymentDetails(CustomerId));
        notification.success({
          message: resData?.result,
        });
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
    }
  };
};
