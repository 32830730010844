// import React, { Component } from "react";
// import "./modal.scss";
// import { Days } from "../../constants/constants";
// import { AsyncPaginate } from "react-select-async-paginate";
// import { getAllSalesmen } from "../../services/teamServices/team";
// import { createBeatPlan } from "../../services/beatplanServices/beatplan";
// import history from "../../utils/History";
// import message from "antd/lib/message";
// import { handleErrorResponse } from "../../utils/Request";
// class CreateBeatPlan extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       days: Days,
//       selectedDay: [],
//       value: "select",
//       loading: false,
//       selectedDays: [],
//       page: 1,
//       options: [],
//       selectedSalesmanId: "",
//       disable: false,
//     };
//   }
//   selectDay = (d) => {
//     let { days } = this.state;
//     let DaysArr = [...days];
//     let newdaysArr =
//       DaysArr &&
//       DaysArr.map((day) => {
//         if (day.id == d.id) {
//           day.selected = !day.selected;
//         }
//         return day;
//       });
//     this.setState(
//       {
//         days: newdaysArr,
//       },
//       () => {
//         let selectedDays = days.filter((day) => day.selected == true);
//         let filteredArr = selectedDays.map((x) => {
//           return { day: x.value };
//         });

//         this.setState({
//           selectedDays: filteredArr,
//         });
//       }
//     );
//   };
//   selectOption = (e) => {
//     if (e != null) {
//       this.setState({
//         selectedSalesmanId: e.key,
//         value: e,
//       });
//     }
//   };

//   createNewBeatPlan = async () => {
//     this.setState({ loading: true, disable: true });
//     let { selectedDays, selectedSalesmanId } = this.state;
//     if (selectedDays.length != 0 && selectedSalesmanId != "") {
//       let inputs = { days: selectedDays, salesmanId: selectedSalesmanId };
//       await createBeatPlan(inputs)
//         .then((res) => {
//           message.success("Beatplan created successfully!");
//           this.setState({ loading: false });
//           window.location = `/dashboard/beatplan/drafts`;
//           history.push({
//             pathname: `/dashboard/beatplan/drafts`,
//           });
//         })
//         .catch((err) => {
//           this.setState({ loading: false });
//           const { errorMessage, errors } = handleErrorResponse(err);
//           message.error(errorMessage);
//         });
//     } else if (selectedDays.length == 0 && selectedSalesmanId == "") {
//       message.warning("Please select day and salesman to create a beat plan");
//     } else if (selectedDays.length == 0) {
//       message.warning(
//         "Please select atleast one day for creating a new beat plan"
//       );
//     } else if (selectedSalesmanId == "") {
//       message.warning("Please select a salesman");
//     }
//   };

//   loadOptions = async (_search, loadedOptions, { page }) => {
//     let qParams = [
//       { page },
//       { type: "mapped" },
//       { status: "active" },
//       { search: _search },
//     ];
//     let data = await getAllSalesmen(qParams).then((res) => {
//       return {
//         options:
//           res.data.data.results &&
//           res.data.data.results.map((item) => {
//             return {
//               key: item._id,
//               value: item.employeeId,
//               label: item.firstName + " " + item.lastName,
//             };
//           }),
//         totalPage:
//           parseInt(res.data.data.pageMeta.total || 0) /
//           parseInt(res.data.data.pageMeta.pageSize || 0),
//       };
//     });

//     return {
//       options: data.options,
//       hasMore: data.totalPage > page ? true : false,
//       additional: {
//         page: page + 1,
//       },
//     };
//   };
//   removeSelectedOptions = () => {
//     let _Days = Days.map((d) => {
//       d.selected = false;
//       return d;
//     });
//     let { displayModal } = this.props;
//     this.setState({
//       days: _Days,
//       value: {},
//       selectedDays: [],
//       options: [],
//       selectedSalesmanId: "",
//     });
//     displayModal();
//   };
//   render() {
//     let { displayModal } = this.props;
//     let { value, disable } = this.state;
//     return (
//       <div>
//         <div className="overlay"></div>
//         <div id="modal-container">
//           <div className="beat-plan-modal">
//             <div className="beat-plan-modal-left">
//               <p>Assign </p>
//             </div>
//             <div className="beat-plan-modal-right">
//               <AsyncPaginate
//                 value={value}
//                 loadOptions={this.loadOptions}
//                 onChange={(e) => {
//                   this.selectOption(e);
//                 }}
//                 additional={{
//                   page: 1,
//                 }}
//                 isSearchable={true}
//                 isClearable={false}
//                 classNamePrefix="select"
//                 className="select-box"
//               />
//             </div>
//           </div>
//           <div className="beat-plan-modal">
//             <div className="beat-plan-modal-left">
//               <p>Repeat</p>
//             </div>
//             <div className="beat-plan-modal-right">
//               <p>SALESMAN</p>
//               <p>SELECT DAYS</p>
//               <div id="week-days">
//                 {Days.map((d) => {
//                   return (
//                     <div
//                       className={
//                         d.selected == false ? "day-sqr" : "selected-day-sqr"
//                       }
//                       key={d.id}
//                       name={d.name}
//                       value={d.value}
//                       onClick={() => this.selectDay(d)}
//                     >
//                       {d.name}
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           </div>
//           <footer>
//             <div
//               className="btn btn-outline-primary mr-3"
//               // id="cancel-button"
//               // onClick={displayModal}
//               onClick={this.removeSelectedOptions}
//             >
//               Cancel
//             </div>
//             <button
//               className="btn btn-primary"
//               // id="create-beat-plan"
//               onClick={this.createNewBeatPlan}
//               disabled={disable}
//             >
//               Create Beat Plan
//             </button>
//           </footer>
//         </div>
//       </div>
//     );
//   }
// }
// export default CreateBeatPlan;


import React, { Component } from "react";
import "./modal.scss";
import { Days } from "../../constants/constants";
import { AsyncPaginate } from "react-select-async-paginate";
import { getAllSalesmen } from "../../services/teamServices/team";
import { createBeatPlan } from "../../services/beatplanServices/beatplan";
import history from "../../utils/History";
import message from "antd/lib/message";
import { handleErrorResponse } from "../../utils/Request";
import { Modal, Button } from "antd";
class CreateBeatPlan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            days: Days,
            value: "select",
            loading: false,
            selectedDays: [],
            page: 1,
            options: [],
            selectedSalesmanId: "",
            visible: true,
        };
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 3000);
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    selectDay = (d) => {
        let { days } = this.state;
        let DaysArr = [...days];
        let newdaysArr =
            DaysArr &&
            DaysArr.map((day) => {
                if (day.id == d.id) {
                    day.selected = !day.selected;
                }
                return day;
            });
        this.setState(
            {
                days: newdaysArr,
            },
            () => {
                let selectedDays = days.filter((day) => day.selected == true);
                let filteredArr = selectedDays.map((x) => {
                    return { day: x.value };
                });

                this.setState({
                    selectedDays: filteredArr,
                });
            }
        );
    };
    selectOption = (e) => {
        if (e != null) {
            this.setState({
                selectedSalesmanId: e.key,
                value: e,
            });
        }
    };

    createNewBeatPlan = async () => {
        this.setState({ loading: true,  });
        let { selectedDays, selectedSalesmanId } = this.state;
        if (selectedDays.length != 0 && selectedSalesmanId != "") {
            let inputs = { days: selectedDays, salesmanId: selectedSalesmanId };
            await createBeatPlan(inputs)
                .then((res) => {
                    message.success("Beatplan created successfully!");
                    this.setState({ loading: false });
                    window.location = `/dashboard/beatplan/drafts`;
                    history.push({
                        pathname: `/dashboard/beatplan/drafts`,
                    });
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    const { errorMessage, errors } = handleErrorResponse(err);
                    message.error(errorMessage);
                });
        } else if (selectedDays.length == 0 && selectedSalesmanId == "") {
            message.warning(
                "Please select day and salesman to create a beat plan"
            );
        } else if (selectedDays.length == 0) {
            message.warning(
                "Please select atleast one day for creating a new beat plan"
            );
        } else if (selectedSalesmanId == "") {
            message.warning("Please select a salesman");
        }
    };

    loadOptions = async (_search, loadedOptions, { page }) => {
        let qParams = [
            { page },
            { type: "mapped" },
            { status: "active" },
            { search: _search },
        ];
        let data = await getAllSalesmen(qParams).then((res) => {
            return {
                options:
                    res.data.data.results &&
                    res.data.data.results.map((item) => {
                        return {
                            key: item._id,
                            value: item.employeeId,
                            label: item.firstName + " " + item.lastName,
                        };
                    }),
                totalPage:
                    parseInt(res.data.data.pageMeta.total || 0) /
                    parseInt(res.data.data.pageMeta.pageSize || 0),
            };
        });

        return {
            options: data.options,
            hasMore: data.totalPage > page ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };
    removeSelectedOptions = () => {
        let _Days = Days.map((d) => {
            d.selected = false;
            return d;
        });
        let { displayModal } = this.props;
        this.setState({
            days: _Days,
            value: {},
            selectedDays: [],
            options: [],
            selectedSalesmanId: "",
        });
        displayModal();
    };
    render() {
        let { displayModal } = this.props;
        let { value, visible ,selectedSalesmanId,selectedDays} = this.state;
        console.log(selectedDays,"selectedDays")
        return (
            <div>
                <Modal
                 
                    //  width={800}
                     visible={visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                //    style={{width: "100%"}}
                >
                    <>
                        <div className=""></div>
                        {/* <div id="modal-container"> */}
                        <div className="beat-plan-modal">
                            <div className="beat-plan-modal-left">
                                <p>Assign </p>
                            </div>
                            <div className="beat-plan-modal-right">
                                <AsyncPaginate
                                    value={value}
                                    loadOptions={this.loadOptions}
                                    onChange={(e) => {
                                        this.selectOption(e);
                                    }}
                                    additional={{
                                        page: 1,
                                    }}
                                    isSearchable={true}
                                    isClearable={false}
                                    classNamePrefix="select"
                                    className="select-box"
                                />
                            </div>
                        </div>
                        <div className="beat-plan-modal">
                            <div className="beat-plan-modal-left">
                                <p>Repeat</p>
                            </div>
                            <div className="beat-plan-modal-right">
                                <p>SALESMAN</p>
                                <p>SELECT DAYS</p>
                                <div id="week-days">
                                    {Days.map((d) => {
                                        return (
                                            <div
                                                className={
                                                    d.selected == false
                                                        ? "day-sqr"
                                                        : "selected-day-sqr"
                                                }
                                                key={d.id}
                                                name={d.name}
                                                value={d.value}
                                                onClick={() =>
                                                    this.selectDay(d)
                                                }
                                            >
                                                {d.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <footer>
                            <div
                                className="btn btn-outline-primary mr-3"
                                // id="cancel-button"
                                // onClick={displayModal}
                                onClick={this.removeSelectedOptions}
                            >
                                Cancel
                            </div>
                            <button
                                className="btn btn-primary"
                                // id="create-beat-plan"
                                onClick={this.createNewBeatPlan}
                                disabled={!(selectedDays.length != 0 && selectedSalesmanId )}
                            >
                                Create Beat Plan
                            </button>
                        </footer>
                        {/* </div>  */}
                    </>
                </Modal>
                {/* <div className="overlay"></div>
        <div id="modal-container">
          <div className="beat-plan-modal">
            <div className="beat-plan-modal-left">
              <p>Assign </p>
            </div>
            <div className="beat-plan-modal-right">
              <AsyncPaginate
                value={value}
                loadOptions={this.loadOptions}
                onChange={(e) => {
                  this.selectOption(e);
                }}
                additional={{
                  page: 1,
                }}
                isSearchable={true}
                isClearable={false}
                classNamePrefix="select"
                className="select-box"
              />
            </div>
          </div>
          <div className="beat-plan-modal">
            <div className="beat-plan-modal-left">
              <p>Repeat</p>
            </div>
            <div className="beat-plan-modal-right">
              <p>SALESMAN</p>
              <p>SELECT DAYS</p>
              <div id="week-days">
                {Days.map((d) => {
                  return (
                    <div
                      className={
                        d.selected == false ? "day-sqr" : "selected-day-sqr"
                      }
                      key={d.id}
                      name={d.name}
                      value={d.value}
                      onClick={() => this.selectDay(d)}
                    >
                      {d.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <footer>
            <div
              className="btn btn-outline-primary mr-3"
              // id="cancel-button"
              // onClick={displayModal}
              onClick={this.removeSelectedOptions}
            >
              Cancel
            </div>
            <button
              className="btn btn-primary"
              // id="create-beat-plan"
              onClick={this.createNewBeatPlan}
              disabled={disable}
            >
              Create Beat Plan
            </button>
          </footer>
        </div> */}
            </div>
        );
    }
}
export default CreateBeatPlan;

