import { actionTypes } from "../action/itemListactions";
const initialState = {
  loading: false,
  itemsList: [],
  itemActionLoading: null,
  totalRecords: 0,
  creditLimits: {},
  productSearchValue: "",
  basketCount: 0,
  outOfStockItems: null,
  extractedCart: [],
};

export const itemsListReducer = (state = initialState, action) => {
  const { type, data, load, uniqueId } = action;
  switch (type) {
    case actionTypes.LOADING:
      return { ...state, loading: true };
    case actionTypes.STOP_LOADING:
      return { ...state, loading: false };
    case actionTypes.LOADING_ITEM_ACTION:
      return { ...state, itemActionLoading: load };
    case actionTypes.GET_ITEMS_LIST:
      return {
        ...state,
        itemsList: [...data.list],
        searchedValue: data.searchTerm,
        totalRecords: data.totalRecords,
        itemActionLoading: null,
        extractedCart: [...data.extractedCart],
        loading: false,
      };
    case actionTypes.GET_CREDIT_LIMITS:
      return {
        ...state,
        creditLimits: { ...data },
      };
    case actionTypes.REMOVE_CREDIT_DETAILS:
      return { ...state, creditLimits: {} };

    case actionTypes.GET_BASKET_COUNT:
      return {
        ...state,
        basketCount: data.result.count,
      };

    case actionTypes.OUT_OF_STOCK_ERROR:
      return {
        ...state,
        outOfStockItems: uniqueId,
        itemActionLoading: !uniqueId ? state.itemActionLoading : null,
      };

    case actionTypes.RESET:
      return { ...initialState };

    default:
      return { ...state };
  }
};
