import { Modal } from "antd";
import React from "react";
import check from "../../images/check-circle.png";

const SuccessModalNew = (props) => {
  const { isOpen, toggle, text, description } = props;
  return (
    <div>
      <Modal
        width={400}
        visible={isOpen}
        onCancel={toggle}
        closable
        style={{ marginRight: "40px", minWidth: "480px" }}
        maskClosable={false}
        footer={null}
        className="confirmModal"
      >
        <div className="d-flex ">
          <div>
            <img src={check} className="modalImg mt-1" alt="i" />
          </div>
          <div>
            <p className="modalmsg ml-2 mt-3">{text}</p>
            <p className="subtext ml-2 mt-n2">{description}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SuccessModalNew;
