import { ShoppingCartOutlined, CheckOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import "./actionButton.scss";

const iconProps = {
  color: "white",
  fontSize: "20px",
  alignItems: "center",
  marginTop: "2px",
};
const ButtonComp = ({
  bg,
  title = "Add",
  icon,
  onClick,
  loading,
  disabled = false,
}) => (
  <Button
    style={{
      backgroundColor: bg ? bg : "#9CA3AF",
      color: "white",
      alignItems: "center",
      fontSize: "16px",
      flex: 1,
      height: 40,
    }}
    className="actionBtn"
    loading={loading}
    onClick={onClick}
    disabled={disabled}
  >
    {icon}
    {title}
  </Button>
);

const ActionButton = ({ type, addToCart, updateCart, loading, disabled }) => {
  // console.log(loading);
  switch (type) {
    case "ADD":
      return (
        <ButtonComp
          bg="#5078F2"
          title="Add"
          icon={<ShoppingCartOutlined {...iconProps} />}
          onClick={addToCart}
          loading={loading}
          disabled={disabled}
        />
      );
    case "AAD_DISABLED":
      return (
        <ButtonComp
          bg=""
          title="Add"
          loading={loading}
          icon={<ShoppingCartOutlined {...iconProps} />}
          disabled={true}
        />
      );
    case "ADDED":
      return (
        <ButtonComp
          bg="#219653"
          title="Added"
          icon={<CheckOutlined {...iconProps} />}
          loading={loading}
          disabled={disabled}
        />
      );
    case "UPDATE":
      return (
        <ButtonComp
          bg="#0C32A7"
          title="Update"
          onClick={addToCart}
          loading={loading}
          disabled={disabled}
          icon={false}
        />
      );

    case "REMOVE":
      return (
        <ButtonComp
          bg="#E13440"
          title="Remove"
          onClick={addToCart}
          loading={loading}
          disabled={disabled}
          icon={false}
        />
      );
    case "OUT_OF_STOCK":
      return (
        <p style={{ color: "#E13440", marginBottom: 0, height: 40 }}>
          Currently out of stock
        </p>
      );

    default:
      return <ButtonComp loading={loading} />;
  }
};

export default ActionButton;
