import React, { Component, Fragment } from "react";
import "./searchBar.scss";
import { ReactComponent as Search } from "../../../src/images/search.svg";
// import bell from "../../../src/images/bell.svg";
import UserProfile from "../nav-profile/userProfile";
import { DebounceInput } from "react-debounce-input";
class SearchBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { searchText, onSearch, fromPage, disProp, title } = this.props;
    // console.log(title);
    return (
      <div className="custom-search">
        <div className="d-flex align-items-center">
          <Search />
          {/* <img alt="img" src={search} /> */}
          <DebounceInput
            type="text"
            id="search"
            title={
              title
                ? title
                : fromPage == "drafts"
                ? "Search by Assigned to or Plan Id"
                : fromPage === "targetSetting"
                ? "Search by Target Id"
                : fromPage === "cod"
                ? "Search by Customer Id/Name"
                : fromPage === "po"
                ? "Search by PO ID"
                : fromPage === "customer"
                ? "Search by Customer Name, Customer Id, Contact No."
                : "Search"
            }
            placeholder={
              title
                ? title
                : fromPage == "drafts"
                ? "Search by Assigned to or Plan Id"
                : fromPage === "targetSetting"
                ? "Search by Target Id"
                : fromPage === "cod"
                ? "Search by Customer Id/Name"
                : fromPage === "po"
                ? "Search by PO ID"
                : fromPage === "customer"
                ? "Search by Customer Name, Customer Id, Contact No."
                : "Search"
            }
            value={searchText}
            // minLength={2}
            debounceTimeout={400}
            onChange={onSearch}
            disabled={disProp}
          />
        </div>
      </div>
      // <Fragment>
      //   <nav className="navbar search-div navbar-light bg-light justify-content-between">
      //     <div className="col-md-12">
      //       <div
      //         className="d-flex justify-content-between"
      //         style={{ alignItems: "center" }}
      //       >
      //         <img alt="img" src={search} />
      //         <DebounceInput
      //           type="text"
      //           id="search"
      //           placeholder={fromPage == "drafts"? "Search by Assigned to  or  Plan Id":"Search"}
      //           value={searchText}
      //           minLength={2}
      //           debounceTimeout={300}
      //           onChange={onSearch}
      //         />
      //         {/* <img
      //           src={bell}
      //           alt="notification"
      //           id="bell"
      //           title="notification"
      //         /> */}
      //         <UserProfile />
      //       </div>
      //     </div>
      //   </nav>
      // </Fragment>
    );
  }
}

export default SearchBar;
