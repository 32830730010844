import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";

export const getAllSo = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
      "app/api/v1/salesman/order/sales-order/credit-extension" +
      qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const creditExtension = async (body, id, creditExtensionStatus) => {
  let request = await axios.patch(
    APIConfig.base_url +
      `app/api/v1/salesman/order/sales-order/${id}/${creditExtensionStatus}`,
    body,
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};

//getCreditExtensionDetails
export const getCreditExtensionDetails = async (id) => {
  let request = await axios.get(
    APIConfig.base_url +
      `app/api/v1/salesman/order/sales-order/credit-extension/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};

export const downloadPendingCod = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
      "app/api/v1/salesman/order/sales-order/cod-order-report" +
      qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const creditExtensionApprove = async (body, id) => {
  let request = await axios.patch(
    APIConfig.base_url +
      `app/api/v1/salesman/${id}/credit-extension-approve-yes`,
    body,
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};

//GET API to get the global status

export const getGlobalStatus = async () => {
  let request = await axios.get(
    APIConfig.base_url + "app/api/v1/salesman/CreditExtensionStatus",

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
