import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";

// get all internal and external po list

export const getAllIntExtList = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "api/v1/purchase-order/get-all" + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getSinglePoDetails = async (id, qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/purchase-order/${id}` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
export const getAllAsms = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "api/v1/purchase-order/get-all" + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
// get warehouse list
export const getWarehouseList = async (qParams) => {
  // qParams is page, search
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "api/v1/assests/list" + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
// get Warehouse Items
export const getWarehouseItems = async (id, qParams) => {
  // qParams is page
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/assests/${id}/items` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//Get Vendor List
export const getVendorList = async (qParams) => {
  // qParams is page
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "app/api/v1/vendorDetails/get-list" + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//Get doc type List
export const getDocTypesList = async (qParams) => {
  // qParams is page
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "app/api/v1/vendorDetails/document-types" + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//Get All Items List
export const getVendorItemList = async (qParams) => {
  // qParams is page
  console.log("getVendorItemList ia called")
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "api/v1/utils/item-master/list/vendor" + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
export const createPO = async (inputs) => {
  let request = await axios.post(
    APIConfig.base_url + "api/v1/purchase-order/create",
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );

  return request;
};

//po ID api integration
export const createPOIDGeneration = async (inputs) => {
  let request = await axios.post(
    APIConfig.Sap_url + "index.php/instant_PurchaseOrderCreation",
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );

  return request;
};

