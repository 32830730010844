import { Divider, Image, Spin, Typography } from "antd";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCreditLimits } from "../../action/itemListactions";
import businessIcon from "../../images/business.svg";
import customerlogo from "../../images/customerlogo.jpg";
import emailIcon from "../../images/email.svg";
import PhoneIcon from "../../images/phone.svg";
import defaultPic from "../../images/default-pic.jpg";
import getINRString from "../../utils/orders/getINRString";

function CustomerDetails() {
  const { selectedCustomer, searchOrdersList } = useSelector(
    (state) => state.serachOrdersReducer
  );
  const { creditLimits } = useSelector((state) => state.itemsListReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedCustomer?.goFrugalId) {
      dispatch(getCreditLimits(selectedCustomer.goFrugalId));
    }
  }, [selectedCustomer]);

  const getAmount = (type) => {
    if (!Object.keys(creditLimits).length > 0) {
      return "₹--.--";
    }
    return getINRString(creditLimits[type]);
  };

  return (
    <div>
      <div style={{ backgroundColor: "#fff" }} className="m-4">
        <div
          className="customerdetail_card p-5"
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            className="customerLeftContent"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-between",
              flex: 1.1,
            }}
          >
            <img
              src={defaultPic}
              className="customer_logo"
              style={{ height: "100px" }}
            />

            <div className="ml-4">
              <div className="mb-3">
                <Typography
                  style={{
                    color: "#3A4960",
                    fontSize: "20px",
                    alignItems: "center",
                    lineHeight: "24px",
                    marginBottom: 6,
                    fontWeight: 600,
                  }}
                >
                  {selectedCustomer?.name}
                </Typography>
                <Typography
                  style={{
                    color: "#7A7A7A",
                    fontSize: "16px",
                    lineHeight: "17px",
                    fontWeight: 400,
                    padding: "5px 0px 0px 0px",
                  }}
                >
                  Customer ID {selectedCustomer?.goFrugalId}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 30,
                  color: "#3A4960",
                  flexWrap: "wrap",
                  marginTop: 20,
                }}
                className="mt-2"
              >
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    src={PhoneIcon}
                    alt="phone icon"
                    preview={false}
                    style={{
                      width: 20,
                      height: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />

                  <Typography className="ml-1">
                    {selectedCustomer?.phone1 ? selectedCustomer.phone1 : "N/A"}
                  </Typography>
                </span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    src={emailIcon}
                    alt="phone icon"
                    preview={false}
                    style={{
                      width: 15,
                      height: 15,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                  <Typography className="ml-1">
                    {selectedCustomer?.email ? selectedCustomer.email : "N/A"}
                  </Typography>
                </span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    src={businessIcon}
                    alt="phone icon"
                    preview={false}
                    style={{
                      width: 15,
                      height: 15,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                  <Typography className="ml-1">
                    {selectedCustomer?.businessType
                      ? selectedCustomer.businessType
                      : "N/A"}
                  </Typography>
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <Divider
              type="vertical"
              style={{ backgroundColor: "#ECEDEE", height: 100 }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              opacity: Object.keys(creditLimits || {}).length ? 1 : 0.2,
              flexDirection: "column",
              flex: 1,
              marginLeft: 60,
            }}
          >
            <div>
              <Typography
                style={{
                  color: "#3A4960",
                  fontSize: "18px",
                  alignItems: "center",
                  fontWeight: 500,
                  lineHeight: "19px",
                  marginBottom: 10,
                }}
              >
                CREDIT DETAILS
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                gap: 60,
                marginTop: 20,
                marginTop: "8px",
                flexWrap: "wrap",
              }}
            >
              <span>
                <Typography className="creditDetailsText">
                  Credit Balance
                </Typography>
                <Typography
                  style={{
                    color: "#219653",
                    opacity: 0.8,
                    letterSpacing: -1.5,
                  }}
                >
                  {getAmount("credit_balance")}
                </Typography>
              </span>
              <span>
                <Typography className="creditDetailsText">
                  Credit Limit
                </Typography>
                <Typography
                  style={{
                    color: "#F2994A",
                    opacity: 0.8,
                    letterSpacing: -1.5,
                  }}
                >
                  {getAmount("credit_limit")}
                </Typography>
              </span>
              <span>
                <Typography className="creditDetailsText">
                  Credit Exposure
                </Typography>
                <Typography
                  style={{
                    color: "#E13440",
                    opacity: 0.8,
                    letterSpacing: -1.5,
                  }}
                >
                  {getAmount("credit_exposure")}
                </Typography>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerDetails;
