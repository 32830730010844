import React from "react";
import PrimaryHeader from "../header/PrimaryHeader";
import "./salesReturn.scss";
import { DatePicker, Space, Modal } from "antd";
import { AsyncPaginate } from "react-select-async-paginate";
import papad from "../../images/papad-removebg-preview 1.svg";
import salesFrame from "../../images/salesFrame.svg"
import Select from "react-select";
import success from "../../images/success.svg";
import {
  getInvoiceListDropdown,
  getSalesReturnView,
  postRaiseReturn,
  getInvoiceDetail,
  getItemDetailByInvoiceNo,
} from "./salesOrderReturnServices";

import { useState, useEffect } from "react";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Pagination, Tabs, Input, Checkbox, Avatar } from "antd";
import { disabledFutureDate } from "../../utils/common";
import moment from "moment";
import _ from "lodash";
import Item from "antd/lib/list/Item";
const antIcon = <LoadingOutlined className="loadingIcon" spin />;
const Invoice = [
  { id: 1, value: "12345678901112", label: "12345678901112" },
  { id: 2, value: "64192465187364", label: "64192465187364" },
  { id: 3, value: "75923593859874", label: "75923593859874" },
];

const selectOptions = [
  { label: "Poor Quality", value: "poor quality" },
  { label: "Late Delivery", value: "late delivery" },
  { label: "Wrong SKU", value: "wrong SKU" },
  { label: "Spec Issue", value: "spec issue" },
  { label: "In-transit Damage", value: "in transit damage" },
  { label: "Price issue", value: "price issue" },
  { label: "Customer didn't order", value: "customer didn't order" },
  { label: "Good stock return", value: "good stock return" },
  { label: "Shop not found", value: "shop not found" },
  { label: "Quantity discrepancy", value: "quantity discrepancy" },
  { label: "Payment Issue", value: "payment issue" },
];

const Basictable = [
  {
    item: "Freshey’s Appalam - 3 Inch 25g",
    itemId: "12345678910",
    invoice: "100",
    return: "25",
    remarks: "Poor Quality",
  },
  {
    item: "Freshey’s Appalam - 3 Inch 25g",
    itemId: "12345678910",
    invoice: "100",
    return: "25",
    remarks: "Poor Quality",
  },
];

export default function SalesOrderReturnDetails(props) {
  const [invoiceNo, setInvoiceNo] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [returnDate, setReturnDate] = useState("");
  const [remarks, setRemarks] = useState({});
  const [returnQty, setreturnQty] = useState({});
  const [totalAmount, settotalAmount] = useState("");
  const [itemSupplied, setitemSupplied] = useState([]);
  const [detail, setDetail] = useState([]);
  const [updatedArray, setUpdatedArray] = useState([]);
  const [ItemIdList, setItemIdList] = useState([]);
  const [err, setErr] = useState(false);
  const [disabledas, setdisabledas] = useState(false);
  const loadInvoiceNo = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 100 }, { invoiceNo: search }];
    let data = await getInvoiceListDropdown(qParams).then((res) => {
      return {
        options:
          res.data.data && res.data.data &&
          res.data.data.getInvoiceNo.map((item) => {
            return {
              key: item._id,
              value: item._id,
              label: item.InvoiceNo,
            };
          }),
        totalPage: parseInt(15 *  res.data.data.getInvoiceNo.length || 0) / parseInt(15 || 0),
      };
    });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleChange = (e, type) => {
    if (type == "invoiceNo") {
      setRemarks({});
      setreturnQty({});
      setUpdatedArray([]);
      setitemSupplied([]);
      setInvoiceNo(e);
      setLoading(true);
      if(e && e.value){
        getInvoiceDetail(e && e.value)
        .then((res) => {
          setLoading(false);

          setDetail(res.data.data[0]);
          // setitemSupplied(
          //   res.data.data &&
          //     res.data.data.length > 0 &&
          //     res.data.data[0].ItemsSupplied &&
          //     res.data.data[0].ItemsSupplied
          // );
          setitemSupplied(
            res.data.data &&
              res.data.data.length > 0 &&
              res.data.data[0].ItemsSupplied &&
              res.data.data[0].ItemsSupplied.map((item) => {
                return {
                  ...item,

                  returnQty: 0,
                  remarks: "",
                };
              })
          );
          let arr =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data[0].ItemsSupplied &&
            res.data.data[0].ItemsSupplied.map((item) => {
              return {
                ...item,

                returnQty: 0,

                remarks: "",
              };
            });
          console.log(arr, "arr");
          setItemIdList(res.data.data[0] && res.data.data[0].ItemId);
        })

        .catch((err) => {
          setLoading(false);
          const { errorMessage } = handleErrorResponse(err);
          message.error(errorMessage);
        });
      }
      else{
        message.error("Please select one invoice ID");
        setLoading(false);
      }
    
    }
    if (type == "returnDate") {
      setReturnDate(e);
    }
  };
  useEffect(() => {}, []);
  const showmodal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };
  const getLatestArray = () => {
    let latestArray = itemSupplied.map((item) => {
      return item;
    });

    setitemSupplied(latestArray);
  };

  const disableKey = (arr) => {
    var flag = false;
    var count = 0;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].remarks != "" && arr[i].returnQty != 0) {
        count++;
      }
    }
    if (count == arr.length) {
      flag = true;
      console.log(flag);
      setdisabledas(flag);
    } else {
      flag = false;
      console.log(flag);
      setdisabledas(flag);
    }
  };
  const selectHandler = (e, type, itemId, invoicedQty, itemName, item) => {
    if (type == "returnQty") {
      let objIndex = itemSupplied.findIndex((obj) => obj._id === item._id);
      itemSupplied[objIndex].returnQty = e.target.value;
      getLatestArray();
      disableKey(itemSupplied);
    }
    if (type == "remarks") {
      let objIndex = itemSupplied.findIndex((obj) => obj._id === item._id);
      itemSupplied[objIndex].remarks = e.label;

      getLatestArray();
      disableKey(itemSupplied);
    }
  };

  const itemDropdown =
    ItemIdList.length > 0 &&
    ItemIdList.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  const handelItemChange = async (e) => {
    console.log(e,invoiceNo && invoiceNo.label);
    setLoading(true);
    await getItemDetailByInvoiceNo(
      invoiceNo && invoiceNo.label,
      e && e.value 
    ).then((res) => {
      setLoading(false);
      console.log(res.data.data);
      setitemSupplied(
        res.data.data &&
          res.data.data.length > 0 &&
    
          res.data.data.map((item) => {
            return {
              ...item,

              returnQty: 0,
              remarks: "",
            };
          })
      );
    }).catch((err) => {
      setLoading(false);
      const { errorMessage } = handleErrorResponse(err);
      message.error(errorMessage);
    });
  };

  const raiseReturn = async () => {
    setLoading(true);
    console.log(updatedArray);
    let newArr = [];
    newArr =
      itemSupplied.length > 0 &&
      itemSupplied.map((item) => {
        return {
          itemName: item.itemName,
          itemId: item.itemId,
          invoicedQty: item.quantity,
          returnQty: parseInt(item.returnQty),
          remarks: item.remarks ? item.remarks.toLowerCase() : "",
          quantity:item.quantity,
          total_amount:item.total_amount,
          discountAmount:item.discountAmount,
          uom:item.uom,

        };
      });
    console.log(newArr);
    let inputs = {
      returnDate: moment(returnDate).format("YYYY-MM-DD"),
      invoiceNumber: invoiceNo && invoiceNo.label,
      saleOrderId: detail && detail.SalesOrderId,
      customerName: detail && detail.Name,
      customerid: detail && detail.CustomerId,
      totalAmount: detail && detail.totalAmount,
      paymentType: detail && detail.PaymentType,
      orderDate: detail && moment(detail.OrderDate).format("YYYY-MM-DD"),
      invoiceDate: detail && moment(detail.InvoiceDate).format("YYYY-MM-DD"),
      deliveryDate: detail && moment(detail.DeliveryDate).format("YYYY-MM-DD"),
      // quantity: 1,
      location: detail && detail.Location,
      wareHouseName: detail && detail.WareHouseName,
      itemSupplied: newArr,
      payment:detail && detail.Payment,
    };
    console.log(inputs);
    await postRaiseReturn(inputs)
      .then((res) => {
        setLoading(false);

        showmodal();
        setTimeout(() => {
          props.history.push("/dashboard/sales-order/sales-order-return");
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  const BasicTbody =
    itemSupplied.length > 0 &&
    itemSupplied.map((item) => {
      let objIndex = itemSupplied.findIndex((obj) => obj._id === item._id);
      return (
        <tr>
          <td>
            <div className="d-flex">
              <div className="mt-2">
                {/* <img src={papad} alt="" className="table_image" /> */}
              </div>
              <div className="ml-4 mt-2">
                <p className="basic_item">{item.itemName}</p>
                <p className="mt-n2 basic_id">{`Item Id${item.itemId}`}</p>
              </div>
            </div>
          </td>
          <td className="item_value">{item.quantity}</td>
          <td className="item_input">
            <input
              type="number"
              value={item.returnQty}
              className="form-control form-label col-lg-10"
              onChange={(e) =>
                selectHandler(
                  e,
                  "returnQty",
                  item.itemId,
                  item.quantity,
                  item.itemName,
                  item
                )
              }
            />
            {itemSupplied[objIndex]["returnQty"] >
              itemSupplied[objIndex]["quantity"] && (
              <div>
                <span className="error-message">
                  {"Return Quantity is greater than"}
                  <br />
                  {"Invoiced Quantity, please return"}
                  <br />
                  {"Some quantity"}
                </span>
              </div>
            )}
          </td>
          <td className="item_remarks">
            <Select
              options={selectOptions}
              className={"reactSelect"}
              menuPosition="fixed"
              onChange={(e) =>
                selectHandler(
                  e,
                  "remarks",
                  item.itemId,
                  item.quantity,
                  item.itemName,
                  item
                )
              }
              // defaultValue={
              //   remarks && remarks[item.itemId] ? remarks[item.itemId] : "-"
              // }
              value={{
                label: item.remarks,
                value: item.remarks,
              }}
              placeholder="Choose remarks"
            />
          </td>
        </tr>
      );
    });

  return (
    <div id="content">
      <PrimaryHeader title={"Raise Return "} />
      <div>
        <lable htmlFor="" className="step_line">
          {"Step 1 : Choose date of return"}
          <hr width="100%" />
        </lable>
      </div>
      <div className="mt-3">
        <label htmlFor="" className="input_heading">
          {"Return Date"}
        </label>
      </div>
      <div>
        <DatePicker
          onChange={(e) => handleChange(e, "returnDate")}
          disabledDate={disabledFutureDate}
        />
      </div>
      <div className="mt-5">
        <lable htmlFor="" className="step_line">
          {"Step 2 : Choose Invoice Number"}
          <hr width="100%" />
        </lable>
      </div>
      <Spin indicator={antIcon} spinning={loading}>
        <div className="mt-3">
          <label htmlFor="" className="input_heading">
            {"Invoice No."}
          </label>
        </div>

        <div className="col-lg-6 ml-n3">
          <AsyncPaginate
            isClearable={true}
            isSearchable={true}
            closeMenuOnSelect={true}
            placeholder={"Enter Invoice Number"}
            className="basic-multi-select"
            loadOptions={loadInvoiceNo}
            onChange={(e) => handleChange(e, "invoiceNo")}
            loadingMessage={() => "No Date Found"}
            additional={{
              page: 1,
            }}
            value={invoiceNo}
          />

          <div>
            {invoiceNo && (
              <div className="mt-4 invoice_detail">
                <div className="mt-4">
                  <div className="row col-lg-12">
                    <div className="col-lg-3">
                      <label htmlFor="" className="invoice_head_sub">
                        {detail && detail.Name}
                      </label>
                      <p htmlFor="" className="invoice_sub">
                        {`Customer Id ${detail && detail.CustomerId}`}
                      </p>
                    </div>
                    <div className="pl-3 col-lg-2">
                      <label htmlFor="" className="invoice_sub">
                        {"Order Date"}
                      </label>
                      <p htmlFor="" className="invoice_value">
                        {detail &&
                          moment(detail.OrderDate).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="" className="invoice_sub">
                        {"Delivery Date"}
                      </label>
                      <p htmlFor="" className="invoice_value">
                        {detail &&
                          moment(detail.DeliveryDate).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="" className="invoice_sub">
                        {"Item Count"}
                      </label>
                      <p htmlFor="" className="invoice_value">
                        {detail && detail.ItemCount}
                      </p>
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="" className="invoice_sub">
                        {"Total Amount  "}
                      </label>
                      <p htmlFor="" className="invoice_amount">
                        {`₹ ${detail && detail.totalAmount}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="return_head mt-4">
          <label htmlFor="" className="border_left">
            {"Item List"}
          </label>
        </div>
        <div className="mt-3">
          {invoiceNo == "" && <img src={salesFrame} alt="" className="frame" />}
        </div>
      </Spin>
      <div>
        {invoiceNo && (
          <div>
            <div className="mt-4 purchase_content">
              <div className="mt-3">
                <label htmlFor="" className="input_heading">
                  {"Item ID"}
                </label>
              </div>
              <div className="col-lg-6 ml-n3">
                {/* <AsyncPaginate
                  isClearable={true}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  // placeholder={"Enter Invoice Number"}
                  // className="basic-multi-select"
                  options={itemDropdown}
                  // onChange={(e) => handleChange(e, "invoiceNo")}
                  additional={{
                    page: 1,
                  }}
                /> */}
                <Select
                  options={itemDropdown}
                  className={"reactSelect"}
                  // menuPosition="fixed"
                  onChange={handelItemChange}
                  style={{zIndex:"1 !important"}}
                />
              </div>
              <div className="table-wrapper">
                <div className="mt-4 ml-2 detail_table">
                  <table className="table">
                    <thead className="remove-border-top">
                      <tr>
                        <th>{"Item Details"}</th>
                        <th>{"Invoiced Qty"}</th>
                        <th style={{ width: "110px" }}>{"Return Qty"}</th>
                        <th style={{ width: "110px" }}>{"Remarks"}</th>
                      </tr>
                    </thead>
                    <tbody>{BasicTbody && BasicTbody}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-5">
        <button
          className="btn btn-primary"
          onClick={raiseReturn}
          disabled={disabledas == false}
        >
          {"Submit"}
        </button>
      </div>

      {modal && (
        <Modal
          width={500}
          bodyStyle={{ height: 225 }}
          visible={modal}
          onCancel={closeModal}
          // closable
          maskClosable={true}
          footer={null}
        >
          <div className="center-content mt-4">
            <div className="">
              <img
                src={success}
                className=""
                // alt="successicon"
              />
            </div>
            <div>
              <p className="mt-3 modal-text">
                Return has been raised successfully!
              </p>
              <p className="ml-4 modal-sub-text">
                Redirecting you to Sales Return Listing
              </p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
