import React, { Component } from "react";
import PrimaryHeader from "../header/PrimaryHeader";
import { Tabs } from "antd";
import InternalPO from "./InternalPO";
import ExternalPO from "./ExternalPO";
const { TabPane } = Tabs;
class CreatePO extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: "Internal",
    };
  }
  onChangeTab = (key) => {
    console.log("onChangeTab, ", key);
    this.setState({
      tab: key,
    });
  };
  render() {
    let { tab } = this.state;
  
    return (
      <div id="content">
        <PrimaryHeader title="Create PO Request" />
        <div className="d-flex row choose-po-title ml-4 mb-4">
          Choose PO Type
        </div>
        <div className="container-fluid create-po-div ml-4">
          <Tabs activeKey={tab} onChange={this.onChangeTab} className="ml-4">
            <TabPane tab="Internal" key="Internal">
              <InternalPO props={this.props}/>
            </TabPane>
            <TabPane tab="External" key="External">
              <ExternalPO props={this.props}/>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default CreatePO;
