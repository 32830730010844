import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Customers from "../components/Customers/customers";

//Action Creator
import { getAllCustomers, syncWithGoFrugal } from "../action/customers";

const mapStateToProps = (state) => {
  return {
    allCustomersDetails: state.customers.allCustomersDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllCustomers,
      syncWithGoFrugal
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Customers)
);
