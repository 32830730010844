import { actionTypes } from "../action/IndividualOrderDetails";
const initialState = {
  loading: false,
  IndividualOrderDetails: [],
  ConfigData: {},
};
// const stopLoading = (type, loadArray) => {
//   return loadArray.filter((load) => load !== type);
// };
export const individualOrderDetailsReducer = (state = initialState, action) => {
  const { type, data, load } = action;

  switch (type) {
    case actionTypes.LOADING:
      return { ...state, loading: true };
    // case actionTypes.STOP_LOADING:
    //   return { ...state, loading: stopLoading(load, state.loading) };

    case actionTypes.GET_INDIVIDUAL_ORDER_DETAILS:
      return {
        ...state,
        IndividualOrderDetails: [data],
        loading: false,
        //  loading: stopLoading(type, state.loading),
      };

    case actionTypes.GET_CONFIG_DATA:
      return {
        ...state,
        ConfigData: data,
      };
    case actionTypes.RESET_INDIVIDUAL_ORDER_DETAILS:
      return { ...initialState };
    default:
      return state;
  }
  console.log("err");
};
