import React, { useEffect, useState } from "react";
import UserProfile from "../nav-profile/userProfile";
import "./salesdashboard.css";
import { logoutFunction } from "../../utils/Auth";
import { getSalesList } from "../../action/salesDashboard";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Menu,
  Row,
  Col,
  message,
  Typography,
  Modal,
  Pagination,
  Space,
} from "antd";
import PrimaryHeader from "../header/PrimaryHeader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import session from "../../images/SessionExpired.svg";

import TechnicalIssue from "./TechnicalIssue";
import DataNotAvailable from "./DataNotAvailable";
import SalesDashboardData from "./SalesDashboardData";

const { RangePicker } = DatePicker;
const antIcon = (
  <div
    style={{
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    }}
  >
    <LoadingOutlined
      style={{
        fontSize: "92px",
      }}
    />
    <Typography>Fetching data !</Typography>
    {/* <Typography>
      Please wait, this action is taking time than usual. Don’t <br />
      close or refresh this page.{" "}
    </Typography> */}
  </div>
);

// let date = moment(new Date());
//     return current && current > moment(date, "DD-MM-YYYY");
const SalesDashboard = () => {
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const { salesList, loading, isTokenExpired, isError } = useSelector(
    (state) => state.salesOverviewReducer
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log(salesList);

  useEffect(() => {
    if (isTokenExpired) {
      setIsModalOpen(true);
    }
  }, [isTokenExpired]);

  const TotalOrderValue =
    salesList[0]?.totalOrderValue && salesList[0]?.totalOrderValue != "NA"
      ? parseFloat(salesList[0]?.totalOrderValue).toLocaleString()
      : "NA";

  const AverageOrderValue =
    salesList[0]?.averageOrederValue && salesList[0]?.averageOrederValue != "NA"
      ? parseFloat(salesList[0]?.averageOrederValue).toLocaleString()
      : "NA";
  const averageOrederValues = AverageOrderValue.toLocaleString();
  const TotalSalesOrderValues = salesList[0]?.totalSalesOrder
    ? salesList[0]?.totalSalesOrder != "NA"
      ? parseInt(salesList[0]?.totalSalesOrder)
      : salesList[0]?.totalSalesOrder
    : "NA";
  const totalSalesOrdervalue = TotalSalesOrderValues.toLocaleString();
  console.log(totalSalesOrdervalue, "totalSalesOrdervalue");
  const CancelOrder = salesList[1]?.cancelledOrders
    ? salesList[1]?.cancelledOrders != "NA"
      ? parseInt(salesList[1]?.cancelledOrders)
      : salesList[1]?.cancelledOrders
    : "NA";
  const CancelOrderValue = CancelOrder.toLocaleString();
  const CancelOrderValues =
    salesList[1]?.cancelledOrderValue &&
    salesList[1]?.cancelledOrderValue != "NA"
      ? parseFloat(salesList[1]?.cancelledOrderValue).toLocaleString()
      : "NA";
  console.log(salesList[0]?.cancelledOrders, CancelOrderValues);
  useEffect(() => {
    if (startDate && endDate)
      dispatch(
        getSalesList({
          startDate: startDate.format("yyyy-MM-DD"),
          endDate: endDate.format("yyyy-MM-DD"),
        })
      );
  }, [startDate, endDate]);
  const customeDisableDates = (current) => {
    let date = new Date();
    return current && current > moment(date, "DD-MM-YYYY");
  };
  // const handleOk1 = () => {
  //   setIsModalOpen(false);
  // };
  const getSalesData = () => {
    dispatch(
      getSalesList({
        startDate: startDate.format("yyyy-MM-DD"),
        endDate: endDate.format("yyyy-MM-DD"),
      })
    );
  };
  const logout = () => {
    logoutFunction();
    //  setIsModalOpen(false);
  };
  const handleChange = (dates) => {
    const [start, end] = dates?.length ? dates : ["", ""];
    setPage(1);
    if (start) {
      setStartDate(start);
    }
    if (end) {
      setEndDate(end);
    }
  };
  return (
    <div className="mt-3 w-100 px-4">
      <div>
        <PrimaryHeader title="Sale Order Overview" />
      </div>

      <div className="rangePickerNew">
        <RangePicker
          bordered={false}
          size="small"
          onCalendarChange={handleChange}
          disabledDate={customeDisableDates}
          defaultValue={[startDate, endDate]}
          value={[startDate, endDate]}
          allowClear={false}
        />
      </div>
      {loading === true ? (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: "100px",
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      ) : isError == false ? (
        <div style={{ marginTop: "50px" }}>
          <Typography
            style={{ fontSize: "20px", fontWeight: 600, lineHeight: "24px" }}
          >
            Sales Overview
          </Typography>
          <div style={{ display: "flex", gap: "5%", flexWrap: "wrap" }}>
            {totalSalesOrdervalue == "NA" ? (
              <DataNotAvailable />
            ) : (
              <div className="sales-overview">
                <SalesDashboardData
                  value={totalSalesOrdervalue}
                  title="Total Sales Order"
                />
              </div>
            )}

            {TotalOrderValue == "NA" ? (
              <DataNotAvailable />
            ) : (
              <div className="sales-overview">
                <SalesDashboardData
                  value={TotalOrderValue}
                  title="Total Order Value (in Rs)"
                />
              </div>
            )}
            {averageOrederValues == "NA" ? (
              <DataNotAvailable />
            ) : (
              <div className="sales-overview">
                <SalesDashboardData
                  value={averageOrederValues}
                  title="Average Order Value (in Rs)"
                />
              </div>
            )}
          </div>
          <div>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "24px",
                marginTop: "65px",
              }}
            >
              Cancellation Overview
            </Typography>
          </div>
          <div style={{ display: "flex", gap: "5%", flexWrap: "wrap" }}>
            {CancelOrderValue == "NA" ? (
              <DataNotAvailable />
            ) : (
              <div className="cancel-overview">
                <SalesDashboardData
                  value={CancelOrderValue}
                  title="Cancelled Orders"
                />
              </div>
            )}
            {CancelOrderValues == "NA" ? (
              <DataNotAvailable />
            ) : (
              <div className="cancel-overview">
                <SalesDashboardData
                  value={CancelOrderValues}
                  title="Cancelled Order Value (in Rs)"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <TechnicalIssue getSalesData={getSalesData} />
      )}
      <Modal visible={isModalOpen} footer={null} closable={false}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={session} alt="Session" style={{ marginTop: "10px" }} />
          <Typography
            style={{
              fontWeight: 600,
              fontSize: "23px",
              color: "#3A4960",
              lineHeight: "33px",
            }}
          >
            Your session has expired !
          </Typography>
          <Button
            style={{
              backgroundColor: "#5078F2",
              borderRadius: "8px",
              height: "48px",
              width: "203px",
              color: "white",
              marginTop: "5px",
            }}
            onClick={logout}
          >
            Login Again
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default SalesDashboard;
