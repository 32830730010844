import { actionTypes } from "../action/balanceCrestionAction";
const initialState = {
  loading: false,
  tokenExpired: false,
  balaceCreationData: [],
  gettransactionsdata: [],
  allTransactiondata: {},
};

export const balanceCreationReducer = (state = initialState, action) => {
  const { type, data, load, balaceCreationData, id } = action;
  console.log(id, "idd");
  switch (type) {
    case actionTypes.LOADING:
      return { ...state, loading: true };
    // case actionTypes.STOP_LOADING:
    //   return { ...state, loading: stopLoading(load, state.loading) };

    case actionTypes.GET_BALANCE_CONFORMATION_DATA:
      console.log(data, "GET_BALANCE_CONFORMATION_DATA");

      return {
        ...state,
        balaceCreationData: data,
        loading: false,
        tokenExpired: false,
        //  loading: stopLoading(type, state.loading),
      };
    case actionTypes.GET_TRANASACTION:
      console.log(data, "GET_TRANASACTION");
      let tmpatyvariable = {};

      if (Object.keys(state.allTransactiondata).includes(id)) {
        tmpatyvariable = { ...state.allTransactiondata };
        tmpatyvariable[id] = {
          transactions: [
            ...state.allTransactiondata[id].transactions,
            ...data.transactions.map((item) => ({ ...item, monthID: id })),
          ],
          paginationData: data.paginationData,
        };
      } else {
        tmpatyvariable = { ...state.allTransactiondata };
        tmpatyvariable[id] = {
          ...data,
          transactions: data.transactions.map((item) => ({
            ...item,
            monthID: id,
          })),
        };
      }
      // let seemore =

      return {
        ...state,
        gettransactionsdata: data,
        allTransactiondata: tmpatyvariable,
        loading: false,
        tokenExpired: false,
        //  loading: stopLoading(type, state.loading),
      };
    case actionTypes.TOKENEXPIRED:
      return { ...state, tokenExpired: true };
    case actionTypes.SETTOKEN:
      return { ...state, tokenExpired: false };

    default:
      return state;
  }
};
