import React, { Component } from "react";
import Dashboard from "../container/dashboardMain";
import Login from "../container/login";
import history from "../utils/History";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ForgetPassword from "../components/login/forgetPassword";
import ResetPassword from "../components/login/resetPassword";
import ResetFirstPassword from "../components/login/resetFirstPassword";
import File from "../components/playstore/File";
import Otp from "../components/deleteAccount/otp";
import PhoneNumber from "../components/deleteAccount/phoneNumber";
import Preview from "../components/deleteAccount/preview";
import CancelRequest from "../components/deleteAccount/cancelRequest";
// import BalanceCreation from "../components/balanceCreation/BalanceCreation";
// import BalanceStatement from "../components/balanceCreation/BalanceStatement";
//import SesionExpired from "../components/balanceCreation/SessionTimeOut";
// import Issusse from "../components/balanceCreation/Issusse";
// import IssusseForm from "../components/balanceCreation/IssusseForm";

import SalesReturn from "../components/SalesReturn/salesReturn";
import SalesReturnDetails from "../components/SalesReturn/salesReturnDetails";
class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route
          path="/login"
          exact
          render={(props) =>
            this.props.isLoggedIn ? (
              this.props.redirectAfterLogin()
            ) : (
              <Login {...props} />
            )
          }
        />
        <Route
          exact
          strict
          path="/dashboard/*"
          render={() =>
            localStorage.getItem("firstTimeLogin") == true ? (
              <ResetFirstPassword />
            ) : (
              <Dashboard />
            )
          }
        />
        <Route
          exact
          strict
          path="/user/profile/password/reset/:token/email/:email"
          component={ResetPassword}
        />
        <Route
          exact
          strict
          path="/user/profile/password/reset"
          component={ResetFirstPassword}
        />
        <Route
          exact
          strict
          path="/user/profile/password/forget"
          component={ForgetPassword}
        />
        <Route exact strict path="/customerapp" component={File} />
        <Route
          exact
          strict
          path="/deleteUserOtp"
          component={Otp}
        />
        <Route
          exact
          strict
          path="/deleteUser"
          component={PhoneNumber}
        />
        <Route
          exact
          strict
          path="/deleteUserSubmission"
          component={Preview}
        />
        <Route
          exact
          strict
          path="/cancelRequest"
          component={CancelRequest}
        />
        
        {/* <Route
          exact
          strict
          path="/customerBalance/:id"
          component={BalanceCreation}
        />
        <Route
          exact
          strict
          path="/balancestatement"
          component={BalanceStatement}
        /> */}
        {/* <Route exact strict path="/SessionExpired" component={SesionExpired} /> */}
        {/* <Route
          exact
          strict
          path="/balancestatement/issues"
          component={Issusse}
        />
        <Route exact strict path="/issusesform" component={IssusseForm} /> */}

        <Route exact strict path="/salesReturn" component={SalesReturn} />
        <Route
          exact
          strict
          path="/salesReturn/SalesReturnDetails"
          component={SalesReturnDetails}
        />
        <Redirect from="*" to="/login" />
      </Switch>
    );
  }
}

export default Routes;
