import React, { useEffect } from "react";
import Success from "../../images/success.svg";
import { Link } from "react-router-dom";

function SuccessModal({ message, name }) {
  return (
    <div className="success-modal">
      <img src={Success} alt="success" />
      <p className="bold-text">{message}</p>
      <p>Go to</p>
      <Link to={`/dashboard/target-setting/${name}`}>
        <button className="submit-button cursor capitalise">
          {`Target Setting - ${name} List`}
        </button>
      </Link>
    </div>
  );
}

export default SuccessModal;
