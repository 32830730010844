import axios from "axios";
import { api as APIConfig } from "../../appConfig";

import { getAuthToken } from "../../utils/Auth";
import { getAuthTokenBalance } from "../../components/balanceCreation/authToken";

export const getOtp = async (PhoneNumber) => {
  let url = `api/v1/profile/sendOtp`;
  let request = await axios.post(
    APIConfig.revamp_base_url + url,
    {phoneNumber:PhoneNumber},
    {
      headers: {
        authorization: getAuthToken(),
      },
    }
  );
  return request;
};

export const verifyOtp = async (phoneNumber, otp) => {
  let url = `api/v1/profile/login?otp=${otp}&phoneNumber=${phoneNumber}`;
  let request = await axios.post(
    APIConfig.revamp_base_url + url,
    {
      phoneNumber: phoneNumber,
      otp: otp,
      deviceId:"Playstore"
    },
    // {
    //   headers: {
    //     authorization: getAuthToken(),
    //   },
    // }
  );
  return request;
};
export const deleteUserService = async (ipAdd) => {
  let url = `api/v1/profile/customerDelete`;
  let request = await axios.post(
    APIConfig.revamp_base_url + url,
    {
      ip: "fromPlayStore",
    },
    {
      headers: {
        authorization: localStorage.getItem("deleteUserToken"),
      },
    }
  );
  return request;
};





