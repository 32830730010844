import React, { Component } from "react";
import "./team.scss";
import Details from "./details";
import {
  zohoDetails,
  areaSalesManagerDetails,
} from "../../services/teamServices/team";
// loader
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// err msg
import message from "antd/lib/message";
import { handleErrorResponse } from "../../utils/Request";
import Employer from "./employer";
import CompanyDetails from "./companyDetails";
import {
  getAgencyList,
  createAgency,
} from "./../../services/teamServices/team";
// import bell from "../../../src/images/bell.svg";
import UserProfile from "../nav-profile/userProfile";
import PrimaryHeader from "../header/PrimaryHeader";

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

class AddNewAsm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      empId: "",
      loading: false,
      asmDetails: null,
      employeeType: "",
      companyName: "Marico",
      isEditPage: false,
      options: [],
      page: 1,
      search: "",
      selectedAgencyId: "",
      optionsvalue: false,
      searchoptions: [],
      createAgency: "",
      totalItems: "",
      totalPage: 0,
      pageSize: 0,
      selectedCustomers: [],
      asmId: "",
      showButton: false,
      error: "",
      selectedDist: "",
      selectedCity: "",
    };
  }
  selectDistributor = (e) => {
    this.setState({
      selectedDist: e.value,
    });
    this.setState({
      selectedCity: e.cityId,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (value.length >= 12) {
      this.setState({ error: "Please enter upto 12 digits" });
    } else if (value.length < 12) {
      this.setState(
        {
          [e.target.name]: e.target.value.toUpperCase(),
          error: "",
        },
        () => {
          if (this.state.empId == "") {
            this.setState({
              email: "",
              phone: null,
              asmDetails: null,
            });
          }
        }
      );
    }
  };
  updateCompanyName = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  searchById = async () => {
    this.setState({ asmDetails: null });
    let empId = this.state.empId;
    this.getAsmDetails(empId);
  };

  // get asm details from zoho
  getAsmDetails = async (empId) => {
    this.setState({ loading: true });
    let { asmData } = this.props;
    await zohoDetails(empId)
      .then((res) => {
        this.setState({
          asmDetails: res.data.data,
          loading: false,
        });
        // call action
        asmData(res.data.data);
      })
      .catch((err) => {
        this.setState({ asmDetails: null, loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  selectEmployeeType = (e) => {
    this.setState(
      {
        employeeType: e.target.name,
      },
      () => {
        this.setState({
          asmDetails: null,
          search: "",
        });
      }
    );
  };

  getAgencyList = async () => {
    // await sleep(4000);
    let { page, options, search } = this.state;

    let qParams = [{ page }, { search }];
    // this.setState({
    //   page: page + 1
    // })

    await getAgencyList(qParams)
      .then((res) => {
        this.setState({
          options:
            res.data.data.results &&
            res.data.data.results.map((agency) => {
              return {
                key: agency._id,
                value: agency._id,
                label: agency.nameToDisplay,
              };
            }),

          totalItems: res.data.data.pageMeta.total,
          totalPage: res.data.data.pageMeta.total,
          pageSize: res.data.data.pageMeta.pageSize,
        });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        console.log("Error", err);
      });

    let prevOptions = options;

    // if (!search) {
    //   prevOptions = options;
    //   return {
    //     options: prevOptions,
    //     hasMore: true,
    //     additional: {
    //       page: page + 1,
    //     },
    //   };
    // } else {
    //   const searchLower = search.toLowerCase();

    //   prevOptions = options.filter(({ label }) =>
    //     label.toLowerCase().includes(searchLower)
    //   );
    //   return {
    //     options: prevOptions,
    //     hasMore: true,
    //     additional: {
    //       page: page + 1,
    //     },
    //   };
    // }
  };

  // selectOption = (e) => {

  //   if(e != null){
  //     let options = e;
  //   let seqCustomers = options.map((customer, i) => {
  //     return { customerId: customer.value, seq: i + 1 };
  //   });
  //   this.setState({
  //     selectedCustomers: seqCustomers,
  //     value:seqCustomers
  //   });
  //   }
  // };

  selectOption = (e) => {
    if (e != null) {
      this.setState({
        search: e.label,
        selectedAgencyId: e.key,
      });
    }
    if (e == null) {
      this.setState({
        search: "",
      });
    }
  };

  clearable = () => {
    this.setState({ search: "" });
  };

  createNewAgency = async (inputValue) => {
    let inputs = {
      name: inputValue,
    };

    await createAgency(inputs)
      .then((res) => {
        message.success("Agency created successfully!!");
        this.setState(
          {
            showButton: true,
          },
          () => {
            this.getAgencyList();
          }
        );
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  handlePageChange = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        let body = {};
        this.getAgencyList(body);
      }
    );
  };

  componentDidMount(e) {
    this.getAgencyList();
    let editId = this.props.location.query;
    //instead of employeeId = this.props.match.params.id, write a func to filter empId from that  this.props.match.params.id use
    let employeeId = this.props.location.employeeId;
    let editPage =
      this.props.match.path === "/dashboard/team/asms/edit/:id" ? true : false;
    // editPage &&
    //   this.setState({
    //     empId:employeeId,
    //   });
    // if this is edit page fetch details according to the id and display data
    if (editPage == true) {
      areaSalesManagerDetails(this.props.match.params.id).then((res) => {
        this.setState(
          {
            loading: false,
            asmDetails: res.data.data,
            empId: res.data.data.employeeId,
          },
          () => console.log(this.state.asmDetails, "asm")
        );
      });
    }
  }
  render() {
    let {
      loading,
      asmDetails,
      empId,
      employeeType,
      companyName,
      isEditPage,
      options,
      selectedAgency,
      searchoptions,
      selectedAgencyId,
      totalItems,
      totalPage,
      pageSize,
      page,
      clearable,
      selectedDist,
      selectedCity,
    } = this.state;

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
      <div id="content">
        <PrimaryHeader title="Add new ASM" />
        <Spin indicator={antIcon} spinning={loading}>
          <Employer
            isEditPage={
              this.props.match.path === "/dashboard/team/asms/edit/:id"
            }
            empId={empId}
            employeeType={employeeType}
            companyName={companyName}
            updateCompanyName={this.updateCompanyName}
            selectEmployeeType={this.selectEmployeeType}
            selectDistributor={this.selectDistributor}
            searchById={this.searchById}
            handleChange={this.handleChange}
            options={options}
            // options={this.getAgencyList}
            showCompanyEmpDetails={
              this.state.search && this.showCompanyEmpDetails
            }
            onCreateOption={this.createNewAgency}
            pageSize={pageSize}
            totalItems={totalItems}
            totalPage={totalPage}
            page={page}
            handlePageChange={this.handlePageChange}
            selectedAgency={(e) => {
              this.selectOption(e);
            }}
            showButton={this.state.showButton}
            error={this.state.error}
          />

          {asmDetails && (
            <Details
              detailsId={asmDetails._id}
              details={asmDetails}
              isEditPage={
                this.props.match.path === "/dashboard/team/asms/edit/:id"
              }
              type="asm"
              selectDistributor={selectedDist}
              selectedCity={selectedCity}
            />
          )}

          {this.state.search ? (
            <CompanyDetails
              selectedAgencyId={selectedAgencyId}
              type="asm"
              selectDistributor={selectedDist}
              selectedCity={selectedCity}
            />
          ) : (
            " "
          )}
        </Spin>
      </div>
    );
  }
}

export default AddNewAsm;
