import React from "react";
import "./underConstruction.scss";
import underconstruction from "../../images/under-construction.svg";

function UnderConstruction({ name }) {
    return (
        <div className="text-center">
            <img src={underconstruction} className="construction-img ml-n5 mt-n3"/>
            <p className="page-text ml-n5">PAGE UNDER CONSTRUCTION !</p>
        </div>

    );
}

export default UnderConstruction;