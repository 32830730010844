class MapsProvider {
	init = () => {
		return new Promise((resolve, reject) => {
			if (!window.google) {
				if (!this.scriptInserted) {
					var s = document.createElement('script');
					s.id = 'maps-script';
					s.type = 'text/javascript';
					s.src = `https://maps.google.com/maps/api/js?key=AIzaSyAwKpdatha_Z7vNvE6hPAtUvBM0mZbJnSY&libraries=places`;
					var x = document.getElementsByTagName('script')[0];
					x.parentNode.insertBefore(s, x);
					this.scriptInserted = true;
				}

				let script = document.getElementById('maps-script');
				// on script load
				script.addEventListener('load', e => {
					resolve(window.google);
				});
			} else {
				resolve(window.google);
			}
		});
	};

	getMapsInstance = async () => {
		let instance = await this.init();
		return instance;
	};
}

export default new MapsProvider();
