import React, { Component } from "react";
import "./login.scss";
import { RightSideDiv, TopDiv } from "./commondivs";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { verifyResetToken, resetPassword } from "../../services/loginServices";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      newPassword: "",
      confirmPassword: "",
      firstTimeLogin: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    let token = match.params.token || undefined,
      email = match.params.email || "prasun.jaiswal@waycool.in";

    // if token is present
    if (token) {
      verifyResetToken({ email, token }).catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
    }
  }

  resetPassword = (e) => {
    e.target.blur();
    let { newPassword, confirmPassword } = this.state;
    const { match } = this.props;
    let token = match.params.token || undefined,
      email = match.params.email || "prasun.jaiswal@waycool.in";
    if (newPassword === confirmPassword) {
      resetPassword(
        {
          password: newPassword,
          confirmPassword,
        },
        {
          email,
          token,
        }
      )
        .then((res) => {
          message.info("Password Reset Successfully");
          window.location.href = "/login";
        })
        .catch((err) => {
          this.setState({ loading: false });
          const { errorMessage, errors } = handleErrorResponse(err);
          message.error(errorMessage);
        });
    }
    if (newPassword != confirmPassword) {
      message.warning("New password and Confirm password should be same!");
    }
  };
  onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    console.log(e,name,value)
    this.setState({
      [name]: value,
    });
  };
  render() {
    let { loading, firstTimeLogin, newPassword, confirmPassword } = this.state;
    return (
      <div className="login-wrapper">
        <RightSideDiv message={"Reset Password"} />
        <div className="login-left-div">
          <TopDiv />
          <Spin indicator={antIcon} spinning={loading}>
            <div className="form">
              {/* <p className="title">Reset Password</p> */}
              <input
                name="newPassword"
                className="email"
                placeholder="new Password"
                type="password"
                onChange={this.onChangeHandler}
              />
              <input
                name="confirmPassword"
                className="email"
                placeholder="confirm Password"
                type="password"
                onChange={this.onChangeHandler}
              />
              <button
                className="btn btn-primary"
                onClick={(e) => this.resetPassword(e)}
               // disabled={newPassword && confirmPassword}
               disabled={
                !(
                  newPassword &&
                  confirmPassword == newPassword
                )
            }
              >
                Change{" "}
              </button>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
