import generateActionTypes from "../utils/generateActionTypes";
import {
  getClusterHeadDetails,
  viewEMployeeDetails,
  searchEmployee,
  clusterDropdown,
  areaDropdown,
} from "../../src/services/teamMapping/teamMappingService";
import { handleErrorResponse } from "../utils/Request";
import message from "antd/lib/message";

export const actionTypes = generateActionTypes(
  "GET_CLUSTER_HEAD_DETAILS",
  "LOADING",
  "VIEW_EMPLOYEE",
  "SEARCH_EMPLOYEE",
  "CLUSTERS_DROPDOWN",
  "AREAS_DROPDOWN",
  "SEARCHDETAIL",
  "SEARCH_DETAILS",
  "EMPTY_SEARCH_FIELDS",
  "View_DETAILS"
);

export const getClusterDetails = (
  clusterLocation,
  areaLocation,
  searchKey,
  //areaLocation,
  path,
  page,
  pageSize
) => {
  console.log(path, "path");
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.GET_CLUSTER_HEAD_DETAILS,
    });
    try {
      const res = await getClusterHeadDetails(
        clusterLocation,
        areaLocation,
        searchKey,
        // areaLocation,
        path,
        page,
        pageSize
      );
      const resData = res?.data;
      console.log(resData, "team");

      if (resData.status == 200) {
        const result = resData;
        console.log(result?.message?.totalDocuments, "kjhgf");
        dispatch({
          type: actionTypes.GET_CLUSTER_HEAD_DETAILS,
          data: {
            result,
            //searchKey,
            totalDocuments: result?.message?.totalDocuments,
          },
        });
      } else {
        dispatch({
          type: actionTypes.GET_CLUSTER_HEAD_DETAILS,
          data: [],
        });
      }
    } catch (err) {
      dispatch({
        type: actionTypes.GET_CLUSTER_HEAD_DETAILS,
        data: [],
      });
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error(err?.response?.data?.message);
      }
    }
  };
};
export const viewEmployeeDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.VIEW_EMPLOYEE,
    });
    try {
      const res = await viewEMployeeDetails(id);
      console.log(res, "ress");
      // const resData = res?.data;
      // console.log(resData, "kk");

      if (res.data.status == 200) {
        // const result = resData;
        // console.log(result?.message?.totalDocuments, "kjhgf");
        dispatch({
          type: actionTypes.VIEW_EMPLOYEE,
          data: res?.data,
        });
      } else {
        // message.error(
        //   resData?.error && typeof resData.error === "string"
        //     ? resData.error
        //     : "Something went wrong"
        // );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error(err?.response?.data?.message);
      }
    }
  };
};
export const getclustersDropdown = (clusterid) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.CLUSTERS_DROPDOWN,
    });
    try {
      const res = await clusterDropdown(clusterid);

      if (res.data.status == 200) {
        dispatch({
          type: actionTypes.CLUSTERS_DROPDOWN,
          data: res?.data,
        });
      } else {
        // message.error(
        //   resData?.error && typeof resData.error === "string"
        //     ? resData.error
        //     : "Something went wrong"
        // );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error(err?.response?.data?.message);
      }
    }
  };
};
export const getareassDropdown = (clusterLocation, areaLocation) => {
  // console.log(clusterid, "avv");
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.AREAS_DROPDOWN,
    });
    try {
      const res = await areaDropdown(clusterLocation, areaLocation);
      console.log(res, "ress");

      if (res.data.status == 200) {
        dispatch({
          type: actionTypes.AREAS_DROPDOWN,
          data: res?.data,
        });
      } else {
        // message.error(
        //   resData?.error && typeof resData.error === "string"
        //     ? resData.error
        //     : "Something went wrong"
        // );
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error(err?.response?.data?.message);
      }
    }
  };
};
export const searchEmployeeDetails = (employerId) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SEARCH_DETAILS,
      load: actionTypes.SEARCH_EMPLOYEE,
    });
    try {
      const res = await searchEmployee(employerId);

      console.log(res.data.data[0], "ress");

      if (res.data.status == 200) {
        dispatch({
          type: actionTypes.SEARCH_EMPLOYEE,
          data: res.data.data[0],
        });
      } else {
        console.log("data", "jjj");

        dispatch({
          type: actionTypes.SEARCH_DETAILS,
        });
        // message.error(
        //   resData?.error && typeof resData.error === "string"
        //     ? resData.error
        //     : "Something went wrong"
        // );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        dispatch({
          type: actionTypes.SEARCH_DETAILS,
        });

        message.error(err?.response?.data?.message);
      }
    }
  };
};
export function emptysearchfields() {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.EMPTY_SEARCH_FIELDS,
      // data: {},
    });
  };
}
export function viewEmptysearchfields() {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.View_DETAILS,
      // data: {},
    });
  };
}
