import React, { Component } from "react";
import "../../components/dashboardMain/dashboard.scss";
import calendar from "../../images/calendar.svg";
import { Tabs } from "antd";
import DefaultTablePic from "../../images/defaultPic.svg";
import Downloadcsv from "../../images/download-csv.svg";
// import bell from "../../../src/images/bell.svg";
import SearchIcon from "../../../src/images/search.svg";
import leftarrow from "../../../src/images/leftarrow.svg";
import rightarrow from "../../../src/images/rightarrow.svg";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

const { TabPane } = Tabs;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      endDate: "",
      startDate: "",
    };
  }

  callback = (key) => {
    console.log(key);
  };

  render() {
    return (
     // <div >
      <div className="container-fluid my-0 mx-2 p-0">
        <div className="row mb-0">
          <div className="col">
            <div className="dashboard">Dashboard </div>
            {/* <img
              src={bell}
              alt="notification"
              id="bell"
              title="notification"
              className="bell"
            /> */}
          </div>
          <div className="custom-container-overall">
            <Tabs defaultActiveKey="1" onChange={this.callback}>
              <TabPane tab="Overall Summary" key="1">
                <div className="d-flex ">
                  <div className="mr-auto mt-2 p-2 w-100">
                    <span className="productivity">Productivity Metrices </span>
                    {/* <span className="date-day"><span className="left-arrow">
                          <img
                          src={leftarrow}
                          /></span> Jul 20 - Aug 18, 2020
                          <span>
                          <img
                          src={rightarrow}
                          className="right-arrow"
                          />
                          </span>                          
                          </span> */}

                    <DateRangePicker
                      startDate={this.state.startDate}
                      startDateId="your_unique_start_date_id"
                      endDate={this.state.endDate}
                      endDateId="your_unique_end_date_id"
                      onDatesChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) =>
                        this.setState({ focusedInput })
                      }
                    />

                    <img src={calendar} alt="calendar" className="calendar1" />

                    <div className="form-group col-md-4">
                      <select
                        id="inputState"
                        className="form-control drop-down"
                        style={{ height: "50px", width: "350px" }}
                      >
                        <option selected className="option-select">
                          Select Area Sales Manager
                        </option>
                        <option>...</option>
                      </select>
                    </div>
                  </div>
                </div>

                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Area Sales Manager </th>
                      <th scope="col">Sales reporting men</th>
                      <th scope="col">Salesmen checked in</th>
                      <th scope="col">No.of Man days </th>
                      <th scope="col">Customer visits </th>
                      <th scope="col ">
                        {" "}
                        New customers added
                        {/* <div className="col-12">New customers added </div>
                          <div className="col-12">
                              <span className="col-6" >abc</span> 
                              <span className="col-6">def</span>                          
                          </div> */}
                      </th>

                      <th scope="col">Orders</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                  </tbody>
                </table>
                <img
                  src={Downloadcsv}
                  alt="download-csv"
                  className="download-csv"
                />
                <button className="more-button">Show More</button>
                <div className="border-line"></div>
                <div className="d-flex">
                  <div className="mr-auto  p-2 w-100">
                    <span className="productivity">Top 5 Best seller </span>

                    <span className="date-day" style={{ marginLeft: "7.1rem" }}>
                      <span className="left-arrow">
                        <img src={leftarrow} />
                      </span>{" "}
                      Jul 20 - Aug 18, 2020
                      <span>
                        <img src={rightarrow} className="right-arrow" />
                      </span>
                    </span>

                    <img src={calendar} alt="calendar" className="calendar1" />
                  </div>
                </div>
                <div className="custom-table">
                  <div className="row">
                    <div className="col-6 SKU-name">
                      SKU by Value
                      <table className="table  table-borderless ">
                        <thead>
                          <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Product Name 1</td>
                            <td>600000</td>
                          </tr>
                          <tr>
                            <td>Product Name 2</td>
                            <td>500000</td>
                          </tr>
                          <tr>
                            <td>Product Name 3</td>
                            <td>700000</td>
                          </tr>
                          <tr>
                            <td>Product Name 4</td>
                            <td>300000</td>
                          </tr>
                          <tr>
                            <td>Product Name 5</td>
                            <td>400000</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-6 SKU-name">
                      SKU by Volume
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Volume</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Product Name 1</td>
                            <td>250kg</td>
                          </tr>
                          <tr>
                            <td>Product Name 2</td>
                            <td>150kg</td>
                          </tr>
                          <tr>
                            <td>Product Name 3</td>
                            <td>450kg</td>
                          </tr>
                          <tr>
                            <td>Product Name 4</td>
                            <td>350kg</td>
                          </tr>
                          <tr>
                            <td>Product Name 5</td>
                            <td>200kg</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="custom-table">
                  <div className="row">
                    <div className="col-6 SKU-name">
                      Customer by Value
                      <table className="table  table-borderless ">
                        <thead>
                          <tr>
                            <th scope="col">Customer</th>
                            <th scope="col">Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Customer Name 1</td>
                            <td>600000</td>
                          </tr>
                          <tr>
                            <td>Customer Name 2</td>
                            <td>500000</td>
                          </tr>
                          <tr>
                            <td>Customer Name 3</td>
                            <td>700000</td>
                          </tr>
                          <tr>
                            <td>Customer Name 4</td>
                            <td>300000</td>
                          </tr>
                          <tr>
                            <td>Customer Name 5</td>
                            <td>400000</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-6 SKU-name">
                      Customer by Volume
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Customer</th>
                            <th scope="col">Volume</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Customer Name 1</td>
                            <td>250kg</td>
                          </tr>
                          <tr>
                            <td>Customer Name 2</td>
                            <td>150kg</td>
                          </tr>
                          <tr>
                            <td>Customer Name 3</td>
                            <td>450kg</td>
                          </tr>
                          <tr>
                            <td>Customer Name 4</td>
                            <td>350kg</td>
                          </tr>
                          <tr>
                            <td>Customer Name 5</td>
                            <td>200kg</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Team" key="2">
                <div className="d-flex">
                  <div className="mr-auto mt-2 p-2 w-100">
                    <span className="productivity">Productivity Metrices </span>
                    <span className="date-day">
                      <span className="left-arrow">
                        <img src={leftarrow} />
                      </span>{" "}
                      Jul 20 - Aug 18, 2020
                      <span>
                        <img src={rightarrow} className="right-arrow" />
                      </span>
                    </span>

                    <img src={calendar} alt="calendar" className="calendar1" />

                    <div className="form-group col-md-4">
                      <select
                        id="inputState"
                        className="form-control drop-down-team"
                      >
                        <option selected className="option-select">
                          Select Sales Manager
                        </option>
                        <option>...</option>
                      </select>
                    </div>
                  </div>
                </div>

                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Area Sales Manager </th>
                      <th scope="col">Sales reporting men</th>
                      <th scope="col">Salesmen checked in</th>
                      <th scope="col">No.of Man days </th>
                      <th scope="col">Customer visits </th>
                      <th scope="col">New customers added </th>
                      <th scope="col">Orders</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                  </tbody>
                </table>

                <img
                  src={Downloadcsv}
                  alt="download-csv"
                  className="download-csv"
                />
                <button className="more-button">Show More</button>
                <div className="border-line-team"></div>
                <div className="d-flex">
                  <div className="mr-auto second-part p-2 w-100">
                    <span className="productivity">Top 5 Best seller </span>
                    <span className="date-day" style={{ marginLeft: "7.1rem" }}>
                      <span className="left-arrow">
                        <img src={leftarrow} />
                      </span>{" "}
                      Jul 20 - Aug 18, 2020
                      <span>
                        <img src={rightarrow} className="right-arrow" />
                      </span>
                    </span>

                    <img src={calendar} alt="calendar" className="calendar1" />
                    <img
                      className="team-sales-search"
                      src={SearchIcon}
                      alt="SearchIcon"
                    />
                    <input
                      className="my_search-bar"
                      type="text "
                      placeholder="Search by Sales Man"
                    />
                  </div>
                </div>
                <div className="custom-table">
                  <div className="row">
                    <div className="col-6 SKU-name">
                      SKU by Value
                      <table className="table  table-borderless ">
                        <thead>
                          <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Product Name 1</td>
                            <td>600000</td>
                          </tr>
                          <tr>
                            <td>Product Name 2</td>
                            <td>500000</td>
                          </tr>
                          <tr>
                            <td>Product Name 3</td>
                            <td>700000</td>
                          </tr>
                          <tr>
                            <td>Product Name 4</td>
                            <td>300000</td>
                          </tr>
                          <tr>
                            <td>Product Name 5</td>
                            <td>400000</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-6 SKU-name">
                      SKU by Volume
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Volume</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Product Name 1</td>
                            <td>250kg</td>
                          </tr>
                          <tr>
                            <td>Product Name 2</td>
                            <td>150kg</td>
                          </tr>
                          <tr>
                            <td>Product Name 3</td>
                            <td>450kg</td>
                          </tr>
                          <tr>
                            <td>Product Name 4</td>
                            <td>350kg</td>
                          </tr>
                          <tr>
                            <td>Product Name 5</td>
                            <td>200kg</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="custom-table">
                  <div className="row">
                    <div className="col-6 SKU-name">
                      Customer by Value
                      <table className="table  table-borderless ">
                        <thead>
                          <tr>
                            <th scope="col">Customer</th>
                            <th scope="col">Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Customer Name 1</td>
                            <td>600000</td>
                          </tr>
                          <tr>
                            <td>Customer Name 2</td>
                            <td>500000</td>
                          </tr>
                          <tr>
                            <td>Customer Name 3</td>
                            <td>700000</td>
                          </tr>
                          <tr>
                            <td>Customer Name 4</td>
                            <td>300000</td>
                          </tr>
                          <tr>
                            <td>Customer Name 5</td>
                            <td>400000</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-6 SKU-name">
                      Customer by Volume
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Customer</th>
                            <th scope="col">Volume</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Customer Name 1</td>
                            <td>250kg</td>
                          </tr>
                          <tr>
                            <td>Customer Name 2</td>
                            <td>150kg</td>
                          </tr>
                          <tr>
                            <td>Customer Name 3</td>
                            <td>450kg</td>
                          </tr>
                          <tr>
                            <td>Customer Name 4</td>
                            <td>350kg</td>
                          </tr>
                          <tr>
                            <td>Customer Name 5</td>
                            <td>200kg</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Customer" key="3">
                <div className="d-flex">
                  <div className="mr-auto  mb-3 p-2 w-100">
                    <span className="productivity">Productivity Metrices </span>
                    <span className="date-day">
                      <span className="left-arrow">
                        <img src={leftarrow} />
                      </span>{" "}
                      Jul 20 - Aug 18, 2020
                      <span>
                        <img src={rightarrow} className="right-arrow" />
                      </span>
                    </span>

                    <img src={calendar} alt="calendar" className="calendar1" />
                    <img
                      className="customer-sales-search"
                      src={SearchIcon}
                      alt="SearchIcon"
                    />
                    <input
                      className="customer_search-bar"
                      type="text "
                      placeholder="Search by Sales Man"
                    />
                  </div>
                </div>

                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Area Sales Manager </th>
                      <th scope="col">Sales reporting men</th>
                      <th scope="col">Salesmen checked in</th>
                      <th scope="col">No.of Man days </th>
                      <th scope="col">Customer visits </th>
                      <th scope="col">New customers added </th>
                      <th scope="col">Orders</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={DefaultTablePic} alt={"Dphoto"} />
                        &nbsp; Carter Stanton
                      </td>
                      <td>10</td>
                      <td>20</td>
                      <td>260</td>
                      <td>70</td>
                      <td>200</td>
                      <td>450</td>
                    </tr>
                  </tbody>
                </table>
                <img
                  src={Downloadcsv}
                  alt="download-csv"
                  className="download-csv"
                />
                <button className="more-button-customer">Show More</button>
                <div className="border-line-customer"></div>
                <div className="d-flex ">
                  <div className="mr-auto p-2 w-100">
                    <span className="productivity">Top 5 Best seller </span>
                    <span className="date-day" style={{ marginLeft: "7.1rem" }}>
                      <span className="left-arrow">
                        <img src={leftarrow} />
                      </span>{" "}
                      Jul 20 - Aug 18, 2020
                      <span>
                        <img src={rightarrow} className="right-arrow" />
                      </span>
                    </span>

                    <img src={calendar} alt="calendar" className="calendar1" />
                    <img
                      className="customer-cust-search"
                      src={SearchIcon}
                      alt="SearchIcon"
                    />
                    <input
                      className="customer_search-bar"
                      type="text "
                      placeholder="Search by Customer"
                    />
                  </div>
                </div>
                <div className="custom-table">
                  <div className="row">
                    <div className="col-6 SKU-name">
                      SKU by Value
                      <table className="table  table-borderless ">
                        <thead>
                          <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Product Name 1</td>
                            <td>600000</td>
                          </tr>
                          <tr>
                            <td>Product Name 2</td>
                            <td>500000</td>
                          </tr>
                          <tr>
                            <td>Product Name 3</td>
                            <td>700000</td>
                          </tr>
                          <tr>
                            <td>Product Name 4</td>
                            <td>300000</td>
                          </tr>
                          <tr>
                            <td>Product Name 5</td>
                            <td>400000</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-6 SKU-name">
                      SKU by Volume
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Volume</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Product Name 1</td>
                            <td>250kg</td>
                          </tr>
                          <tr>
                            <td>Product Name 2</td>
                            <td>150kg</td>
                          </tr>
                          <tr>
                            <td>Product Name 3</td>
                            <td>450kg</td>
                          </tr>
                          <tr>
                            <td>Product Name 4</td>
                            <td>350kg</td>
                          </tr>
                          <tr>
                            <td>Product Name 5</td>
                            <td>200kg</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
