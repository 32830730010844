import React, { useState } from "react";
import PrimaryHeader from "../../header/PrimaryHeader";

import { Divider, Typography, Table, Button } from "antd";
// import customerlogo from "../../../images/customerlogo.jpg";
import pending from "../../../images/clock.svg";
import delivered from "../../../images/delivered.svg";
import OrderStatusStepper from "../OrderStatusStepper";
import { CustomerDetails } from "./CustomerDetails";
import { BasicDetails } from "./BasicDetails";
import { Link, useParams, useHistory } from "react-router-dom";
import exclamation from "../../../images/exclamation.svg";
import { DownOutlined, ArrowLeftOutlined } from "@ant-design/icons";
// import { data } from "./data";
import moment from "moment";
import Shipment from "./Shipment";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getIndividualOrderDetail,
  getOTPConfig,
} from "../../../action/IndividualOrderDetails";
import getINRString from "../../../utils/orders/getINRString";
import placeHolder from "../../../images/groceries.svg";
import {
  triggerOtpService,
  getOTPMenuConfig,
} from "../../../services/otpServices/otpServices";
//import history from "../../../utils/History";
import { handleErrorResponse } from "../../../../src/utils/Request";
import message from "antd/lib/message";
import ItemsProceesing from "./ItemsProceesing";

const SubHeader = ({ title }) => (
  <div
    className="m-3"
    style={{
      display: "flex",
      flexDirection: "row",
      marginLeft: "33px",
      marginTop: "10px",
      alignItems: "center",
    }}
  >
    <span>
      <Divider
        type="vertical"
        style={{
          border: "2px solid #5078F2",
          height: "24px",
        }}
      />
    </span>
    <span>
      <Typography variant="h4" style={{ fontWeight: "bold", fontSize: "18px" }}>
        {title}
      </Typography>
    </span>
  </div>
);
function Welcome({ bgColor }) {
  return (
    <div
      style={{
        height: "10px",
        width: "10px",
        borderRadius: "50px",
        backgroundColor: bgColor,
        marginTop: "4px",
        display: "inline-block",
      }}
    ></div>
  );
}

function OrderAndBasicDetails() {
  const { selectedCustomer, searchOrdersList } = useSelector(
    (state) => state.serachOrdersReducer
  );
  const params = useParams();
  let history = useHistory();
  const soId = params?.soId;
  const customerID = params?.customerID;
  const invoiceNo = params?.invoiceNo;
  // const invoiceNo = "0";

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const { id } = useParams();
  let Otp = "3";
  const { IndividualOrderDetails, ConfigData } = useSelector(
    (state) => state.individualOrderDetailsReducer
  );

  const orderdate = IndividualOrderDetails[0]?.orderDateDP;
  const orderdatae = moment(orderdate);
  const todaysdate = moment(new Date());
  var diffInMinutes = todaysdate.diff(orderdatae, "days");
  const getProccessingDate = () => {
    if (diffInMinutes == 0) {
      return "3";
    }
    if (diffInMinutes == 1) {
      return "2";
    }
    if (diffInMinutes == 2) {
      return "1";
    }
  };
  const orderId = IndividualOrderDetails[0]?.salesOrderId;
  const ordereditems = IndividualOrderDetails[0]?.itemsSummary?.all;
  const processingitems =
    IndividualOrderDetails[0]?.itemsSummary?.processingitems || [];
  const undelivered =
    IndividualOrderDetails[0]?.itemsSummary?.undelivered || [];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIndividualOrderDetail(soId, customerID));
    dispatch(getOTPConfig());
  }, []);

  const triggerPod = async (record) => {
    try {
      const InvoiceId = record.mginvoiceId;
      Otp = "3";
      const location = {
        pathname: `/dashboard/orders/viewinvoice/`,
        state: { soId, InvoiceId, customerID, Otp },
      };
      history.push(location);
    } catch (err) {
      console.log("err");
    }
  };
  const invoiceDetails = async (record) => {
    try {
      const InvoiceId = record.mginvoiceId;
      Otp = "3";
      const location = {
        pathname: `/dashboard/orders/viewinvoice/`,
        state: { soId, InvoiceId, customerID, Otp },
      };
      history.push(location);
    } catch (err) {
      console.log("err");
    }
  };
  const triggerOTP = async (record) => {
    try {
      await triggerOtpService(
        soId,
        record.invoiceid,
        ConfigData?.isLogistics
      ).then((res) => {
        if (res?.status == 200) {
          const InvoiceId = record.mginvoiceId;
          Otp = "4";
          const location = {
            pathname: `/dashboard/orders/viewinvoice/`,
            state: { soId, InvoiceId, customerID, Otp },
          };
          history.push(location);
        } else {
          message.error(res?.message);
        }
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        console.log(error, "error");
        const { errorMessage, errors } = handleErrorResponse(error);
      } else {
        message.error(error?.response?.data?.message);
      }
    }
  };
  const ordersListData = ordereditems || [];
  const StatusShipments = ordersListData.map((item) => item.shipment);
  let allShipments = [];
  StatusShipments.forEach((c) => {
    if (!allShipments.includes(c) && c) {
      allShipments.push(c);
    }
  });

  const shipment = allShipments.map((item) => ({ value: item, text: item }));
  const orderstatusData = ordersListData.map((item) => item.status);
  let uniqueChars = [];
  orderstatusData.forEach((c) => {
    if (!uniqueChars.includes(c) && c) {
      uniqueChars.push(c);
    }
  });
  const status = uniqueChars.map((item) => ({
    value: item,
    text: item,
  }));

  const getTotalAmount = (itemAmt, discount_amount) => {
    if (itemAmt !== undefined && discount_amount != undefined) {
      return getINRString(itemAmt - Math.abs(discount_amount));
    } else {
    }
    return "";
  };
  const getOTPStatus = (record) => {
    if (ConfigData?.isLogistics == false) {
      if (record?.otp?.status === true) {
        return "Verified";
      } else {
        return "Trigger";
      }
    } else if (
      record?.invoiceStatus.toUpperCase() === "PACKED" ||
      record?.invoiceStatus.toUpperCase() === "ORDER PACKED" ||
      record?.invoiceStatus.toUpperCase() === "OUT FOR DELIVERY" ||
      record?.invoiceStatus.toUpperCase() === "ORDER ADJUSTED & PACKED" ||
      record?.invoiceStatus.toUpperCase() === "SALES RETURN"
    ) {
      return "NA";
    } else {
      if (record?.otp?.status === true) {
        return "Verified";
      } else if (record?.otp?.statusFlag === true) {
        return "Trigger";
      } else if (record?.otp?.statusFlag === false) {
        return "Trigger OTP After ";
      } else {
        return "NA";
      }
    }
  };
  const getPODStatus = (record) => {
    if (record?.isCompleteSalesReturn == true) {
      return "NA";
    }
    if (
      record?.invoiceStatus.toUpperCase() === "PACKED" ||
      record?.invoiceStatus.toUpperCase() === "ORDER PACKED" ||
      record?.invoiceStatus.toUpperCase() === "OUT FOR DELIVERY" ||
      record?.invoiceStatus.toUpperCase() === "ORDER ADJUSTED & PACKED" ||
      record?.invoiceStatus.toUpperCase() === "SALES RETURN"
    ) {
      if (ConfigData?.isLogistics == false) {
        if (record?.pod?.length > 0) {
          return "AVAILABLE";
        } else if (record?.pod?.length == 0) {
          return "UPLOAD";
        }
      } else {
        return "NA";
      }
    } else {
      if (record?.pod?.length > 0) {
        return "AVAILABLE";
      } else if (record?.pod?.length == 0) {
        return "UPLOAD";
      }
    }
    return "NA";
  };

  const isOTPTab = () => {
    if (ConfigData?.isLogistics == false) {
      return true;
    } else if (ConfigData?.deOtpStatus == true) {
      return true;
    } else {
      return false;
    }
  };
  const getDiscountedAmount = (record) => {
    if (record?.discount_amount != "" && record?.discount_amount <= 0) {
      return null;
    } else {
      return ((record?.discount_amount / record?.price) * 100).toFixed(2);
    }
  };

  const detailsBtnDisplay = (record) => {
    if (ConfigData.isLogistics == false) {
      return true;
    } else if (record?.isCompleteSalesReturn == true) {
      return false;
    } else if (
      record?.invoiceStatus.toUpperCase() === "PACKED" ||
      record?.invoiceStatus.toUpperCase() === "ORDER PACKED" ||
      record?.invoiceStatus.toUpperCase() === "OUT FOR DELIVERY" ||
      record?.invoiceStatus.toUpperCase() === "ORDER ADJUSTED & PACKED" ||
      record?.invoiceStatus.toUpperCase() === "SALES RETURN"
    ) {
      return false;
    } else {
      return true;
    }
  };
  const getOrderStatus = (record) => {
    const statusNum = record?.statusNum;
    if (statusNum == undefined || statusNum == null || statusNum == "null") {
      return "";
    }
    if (record?.itemStatus == 0) {
      if (statusNum === 1 || statusNum == 3) {
        return <Welcome bgColor="#219653" />;
        //green
      } else if (
        statusNum === 4 ||
        statusNum === 6 ||
        statusNum === 2 ||
        statusNum === 0 ||
        statusNum === 7
      ) {
        return <Welcome bgColor="#F2994A" />;
      } else if (statusNum === 5) {
        return <Welcome bgColor="#E13440" />;
      } else {
        return null;
      }
    } else {
      if (statusNum === 1 || statusNum == 4 || statusNum == 3) {
        return <Welcome bgColor="#219653" />;
      } else if (
        statusNum === 5 ||
        statusNum === 2 ||
        statusNum == 12 ||
        statusNum == 13
      ) {
        return <Welcome bgColor="#F2994A" />;
      } else if (
        statusNum === 6 ||
        statusNum === 7 ||
        statusNum === 8 ||
        statusNum === 9
      ) {
        return <Welcome bgColor="#E13440" />;
      } else {
        return null;
      }
    }
  };
  const getRemainingTime = (minutes) => {
    if (minutes == undefined || minutes < 0) {
      return "";
    }

    if (minutes < 60) {
      return minutes + " Minutes";
    } else {
      var hours = parseInt(minutes / 60);
      var min = minutes % 60;
      if (hours == 1) {
        return hours + " Hour " + min + " Minutes";
      } else {
        return hours + " Hours " + min + " Minutes";
      }
    }
  };

  const columns = [
    {
      key: "1",
      title: <div className="title_table">Item Details</div>,
      dataIndex: "name",
      render: (_, record) => (
        <>
          <div>
            {record.isShippment == true ? (
              <span>
                <Typography
                  style={{
                    fontSize: "11px",
                    fontWeight: 400,
                    color: "#496EDE",
                    marginBottom: "10px",
                  }}
                >
                  *This item is split into multiple shipments for faster
                  delivery
                </Typography>
              </span>
            ) : (
              ""
            )}

            <div
              className="d-flex align-items-center justify-space-between"
              style={{ gap: 5 }}
            >
              <div className="mr-1">
                <img
                  src={record.imageUrl || placeHolder}
                  width={48}
                  className="rounded"
                />
              </div>
              <div
                className=" ml-2 d-flex flex-column justify-content-between"
                style={{ gap: 5 }}
              >
                <Typography
                  className="bold"
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: 1.6,
                    letterSpacing: 1.1,
                    color: "#3A4960",
                  }}
                >
                  {record.itemName}
                  {record.moq > 1 && `(Set of ${record.moq})`}
                </Typography>
                <Typography
                  type="secondary"
                  style={{ lineHeight: 1.3, opacity: 0.7 }}
                >
                  Item ID {record.itemId}
                </Typography>
                {record.ReturnedQty > 0 ? (
                  <span>
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "18px",
                        color: "#B3251D",
                      }}
                    >
                      Returned Qty : {record.ReturnedQty}
                    </Typography>
                  </span>
                ) : (
                  ""
                )}

                {/*<span
                  style={{ display: "flex", flexDirection: "row" }}
                  className="mt-1"
                >
                  {getDiscountedAmount(record) > 0 ? (
                    <Typography
                      style={{ color: "#E13440" }}
                      className="mr-2 font-weight-bold"
                    >
                      {getDiscountedAmount(record)}% OFF
                    </Typography>
                  ) : null}
                  {record.conditionType === "ZSEC" ? (
                    <Typography className="economy_type1">Economy</Typography>
                  ) : (
                    ""
                  )}
                </span> */}
              </div>
            </div>
          </div>

          {record.outOfStock ? (
            <Typography style={{ color: "#E13440", fontSize: "14px" }}>
              This product is currently out of stock
            </Typography>
          ) : null}
        </>
      ),
    },
    {
      key: "2",
      title: <div className="title_table">Ordered Qty</div>,
      dataIndex: "OrderedQty",
      align: "center",
      render: (_, record) =>
        record.OrderedQty ? (
          <Typography style={{ textAlign: "center" }} className="invoiceno">
            {record.OrderedQty}
          </Typography>
        ) : (
          <Typography style={{ textAlign: "center" }}>-</Typography>
        ),
    },
    {
      key: "3",

      title: <div className="title_table">Invoiced Qty</div>,
      render: (_, record) =>
        record.InvoicedQty ? (
          <Typography style={{ textAlign: "center" }} className="invoiceno">
            {record.InvoicedQty}
          </Typography>
        ) : (
          <Typography style={{ textAlign: "center" }}>-</Typography>
        ),
    },
    {
      key: "4",

      title: <div className="title_table">Delivered Qty</div>,
      render: (_, record) =>
        record.DeliveredQty ? (
          <Typography style={{ textAlign: "center" }} className="invoiceno">
            {record.DeliveredQty}
          </Typography>
        ) : (
          <Typography style={{ textAlign: "center" }}>-</Typography>
        ),
    },
    shipment.length > 1
      ? {
          key: "5",

          title: <div className="title_table">Shipment</div>,
          filters: shipment,
          dataIndex: "shipment",

          render: (_, record) =>
            record.shipment && shipment.length != 1 ? (
              <Typography>{record.shipment}</Typography>
            ) : shipment.length == 1 ? (
              <Typography style={{ textAlign: "center", marginRight: "30px" }}>
                {" "}
                Shipment{" "}
              </Typography>
            ) : (
              <Typography style={{ textAlign: "center", marginRight: "30px" }}>
                {" "}
                -{" "}
              </Typography>
            ),
          onFilter: (value, record) => {
            return record.shipment === value;
          },
          filterMultiple: false,
          filterIcon: (filtered) => <DownOutlined />,
        }
      : {
          key: "5",

          title: <div className="title_table">Shipment</div>,
          dataIndex: "shipment",
          render: (_, record) =>
            record.shipment && shipment.length != 1 ? (
              <Typography>{record.shipment}</Typography>
            ) : shipment.length == 1 ? (
              <Typography style={{ textAlign: "center", marginRight: "30px" }}>
                {" "}
                Shipment{" "}
              </Typography>
            ) : (
              <Typography style={{ textAlign: "center", marginRight: "30px" }}>
                {" "}
                -{" "}
              </Typography>
            ),
        },

    status.length > 1
      ? {
          key: "6",

          title: <div className="title_table">Status</div>,
          render: (_, record) => (
            <div style={{ display: "-webkit-inline-box" }}>
              {getOrderStatus(record)}
              {record.status ? (
                <Typography style={{ marginLeft: "8px" }}>
                  {record.status}
                </Typography>
              ) : (
                <Typography
                  style={{ textAlign: "center", marginRight: "30px" }}
                >
                  -{" "}
                </Typography>
              )}
            </div>
          ),

          filters: status,
          onFilter: (value, record) => {
            return record.status === value;
          },
          dataIndex: "status",
          filterIcon: (filtered) => <DownOutlined />,
        }
      : {
          key: "6",
          title: <div className="title_table">Status</div>,

          dataIndex: "status",
          render: (_, record) => (
            <div style={{ display: "-webkit-inline-box" }}>
              {getOrderStatus(record)}
              {record.status ? (
                <Typography style={{ marginLeft: "5px" }}>
                  {record.status}
                </Typography>
              ) : (
                <Typography
                  style={{ textAlign: "center", marginRight: "30px" }}
                >
                  -{" "}
                </Typography>
              )}
            </div>
          ),
        },
  ];

  return (
    <div id="content" style={{ backgroundColor: "#f5f7fa" }}>
      <div className="" style={{ display: "flex", marginTop: "6px" }}>
        <Button type="link" onClick={() => history.goBack()}>
          <ArrowLeftOutlined
            style={{ color: "black", fontSize: "20px", marginTop: "15px" }}
          />
        </Button>
        <div className="llllll" style={{ width: "100%", zIndex: 4000 }}>
          <PrimaryHeader
            // title="Order ID"
            title={`Order ID ${orderId}`}
            backgroundColor="#f5f7fa"
            marginTop="-6px"
          />
        </div>
      </div>
      {IndividualOrderDetails[0]?.shipments?.length > 1 ? (
        <div className="shipment-banner">
          <Typography
            style={{
              backgroundColor: "#FFF2CC",
              padding: "10px",
              color: "#3E2E00",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            Order has been split into{" "}
            {IndividualOrderDetails[0]?.shipments?.length} shipments.
          </Typography>
        </div>
      ) : null}
      <div></div>
      <div>
        <CustomerDetails />
      </div>

      <div>
        <BasicDetails />
      </div>
      <div className="m-3 p-3" style={{ backgroundColor: "#FFFFFF" }}>
        <Shipment invoiceNo={invoiceNo} />
      </div>
      <div className="m-3 bg-white p-3 py-4">
        <SubHeader title="Item Breakdown" />
        {/*{undelivered.length > 0 ? (
          <ItemsProceesing
            title={`${undelivered.length} items are not able to deliver in this order`}
            icon={exclamation}
          />
        ) : null} */}
        {processingitems.length > 0 ? (
          <ItemsProceesing
            title={`${
              processingitems.length
            } items are processing in this order. It will updated within ${getProccessingDate()} days `}
            icon={exclamation}
          />
        ) : null}
        <div className="mx-4 pt-3">
          <Table
            dataSource={ordereditems}
            columns={columns}
            // onChange={onChange}
            pagination={{
              hideOnSinglePage: true,
              position: ["bottomCenter"],
              current: page,
              pageSize: pageSize,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default OrderAndBasicDetails;
