import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";

export const getOTPMenuConfig = async () => {
  let url = `api/v1/distributor/settings/getDigitalOtp/Config`;
  let request = await axios.get(APIConfig.base_url + url, {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};

export const getInvoiceDetailsService = async (InvoiceId, customerID) => {
  let url = `api/v1/orderdetails/invoicedetails?invoiceId=${InvoiceId}`;
  let request = await axios.get(APIConfig.revamp_base_url + url, {
    headers: {
      authorization: getAuthToken(),
      portaltype: "distributor",
      customerid: customerID,
    },
  });
  return request;
};
export const triggerOtpService = async (otpSoId, invcID, isLogistics) => {
  let url = `v1/soOrderStatus/customer/otp-request`;
  let request = await axios.post(
    APIConfig.base_url + url,
    {
      orderId: otpSoId,
      invoiceNo: invcID,
      isLogistics: isLogistics,
    },
    {
      headers: {
        authorization: getAuthToken(),
        // portaltype: "distributor",
        // customerid: reqData.customerId,
      },
    }
  );
  return request;
};

export const verifyOtp = async (otp, invId, otpSoId, isLogistics) => {
  let url = `v1/soOrderStatus/customer/otp-verify`;
  let request = await axios.post(
    APIConfig.base_url + url,
    {
      otp: otp,
      orderId: otpSoId,
      invoiceNo: invId,
      isLogistics: isLogistics,
    },
    {
      headers: {
        authorization: getAuthToken(),
        portaltype: "distributor",
      },
    }
  );
  return request;
};

export const uploadPODService = async (formData, InvoiceId, type) => {
  //let url = `api/v1/soOrderStatus/podimagesupload?inVoiceId=${InvoiceId}&type=${type}`;

  let url = `api/v1/soOrderStatus/podimagesupload/${InvoiceId}?type=${type}`;
  let request = await axios.post(APIConfig.base_url + url, formData, {
    // {
    //   ...reqData,
    // },

    headers: {
      "x-access-token": getAuthToken(),
      portaltype: "distributor",
    },
  });
  return request;
};
