import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import {
  osVersion,
  osName,
  fullBrowserVersion,
  browserVersion,
  browserName,
  deviceType,
} from "react-device-detect";
//Verify the credentials for login

export const uploadFile = async (inputs) => {
  const formData = new FormData();
  formData.append('image', inputs)
  let request = await axios.post(APIConfig.base_url + "api/v1/file/image/upload",
    formData, {
    headers: {
      'x-access-token': getAuthToken(),
      'content-type': 'multipart/form-data',
      osVersion: osVersion,
      osName: osName,
      fullBrowserVersion: fullBrowserVersion,
      browserVersion: browserVersion,
      browserName: browserName,
      deviceType: deviceType,
    },
  });

  // returning the request
  return request;
};