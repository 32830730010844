import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import "./targetSetting.scss";
import { Pagination } from "antd";
import { handleErrorResponse } from "../../utils/Request";
import { Spin, message } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  getTargetSettingList,
  downloadCsv,
} from "../../services/targetSettingServices/targetSetting";
import SearchBar from "../search-bar/searchBar";
import { LoadingOutlined } from "@ant-design/icons";
import {
  addData,
  APPROVED_DATA,
  REJECTED_DATA,
  PENDING_DATA,
  addTargetSettingDetails,
} from "../../action/targetSetting";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "antd/lib/layout/layout";
import PrimaryHeader from "../header/PrimaryHeader";
const { TabPane } = Tabs;

function TargetSetting() {
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [approvedData, setApprovedData] = useState({});
  const [pendingData, setPendingData] = useState({});
  const [rejectedData, setRejectedData] = useState({});
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [pageMeta, setpageMeta] = useState(10);
  const [load, setload] = useState(false);
  const antIcon = <LoadingOutlined className="loadIcon" spin />;
  const dispatch = useDispatch();
  const history = useHistory();
  const targetSettingData = useSelector((state) => state.targetSettingData);
  const { name } = useParams();

  useEffect(() => {
    try {
      if (name === "approved") {
        if (targetSettingData.approvedData.loadData) {
          const data = fetchData();
        }
      } else if (name === "pending") {
        if (targetSettingData.pendingData.loadData) {
          const data = fetchData();
        }
      } else {
        if (targetSettingData.rejectedData.loadData) {
          const data = fetchData();
        }
      }
    } catch (error) {
      const { errorMessage, errors } = handleErrorResponse(error);
      message.error(errorMessage);
    }
  }, [name, page, searchText]);

  const fetchData = async () => {
    setload(true);
    const result = await getTargetSettingList(name, page, searchText);
    const data = result.data.data;
    console.log(result.data.data.pageMeta, "page");
    if (name === "approved") {
      dispatch(addData(data, APPROVED_DATA, false));
    } else if (name === "pending") {
      dispatch(addData(data, PENDING_DATA, false));
    } else {
      dispatch(addData(data, REJECTED_DATA, false));
    }
    setload(false);
    return result.data.data;
  };

  console.log(pendingData, "pending");

  const handleTabChange = (key) => {
    history.push(`/dashboard/target-setting/${key}`);
    // setType(key);
  };
  const formatDate = (from, to) => {
    if (from != undefined) {
      return `${from.substring(0, 10)} - ${to.substring(0, 10)}`;
    } else {
      return "-";
    }
  };

  const handleSearch = (e) => {
    if (name === "approved") {
      dispatch(addData(targetSettingData, APPROVED_DATA, true));
    } else if (name === "pending") {
      dispatch(addData(targetSettingData, PENDING_DATA, true));
    } else {
      dispatch(addData(targetSettingData, REJECTED_DATA, true));
    }
    setSearchText(e.target.value);
  };

  const handlePageChange = (page) => {
    if (name === "approved") {
      dispatch(
        addData(
          {
            ...targetSettingData,
            approvedData: { ...targetSettingData.approvedData, page: page },
          },
          APPROVED_DATA,
          true
        )
      );
    } else if (name === "pending") {
      dispatch(
        addData(
          {
            ...targetSettingData,
            pendingData: { ...targetSettingData.pendingData, page: page },
          },
          PENDING_DATA,
          true
        )
      );
    } else {
      dispatch(
        addData(
          {
            ...targetSettingData,
            rejectedData: { ...targetSettingData.rejectedData, page: page },
          },
          REJECTED_DATA,
          true
        )
      );
    }
    const data = fetchData();
  };

  const sendData = (row, e) => {
    dispatch(addTargetSettingDetails(row));
    history.push(`/dashboard/target-setting/${name}/${row._id}`);
  };

  const viewDocument = async (row, e) => {
    try {
      setload(true);
      const result = await downloadCsv(row._id);
      let blob = new Blob([result.data], { type: "text/csv" });
      saveAs(blob, "target.csv");
      setload(false);
    } catch (error) {
      const { errorMessage, errors } = handleErrorResponse(error);
      setload(false);
      message.error(errorMessage);
    }
  };

  return (
    <div id="content" className="target-setting-container">
      <PrimaryHeader title="Target Setting" />
      <SearchBar
        searchText={searchText}
        onSearch={handleSearch}
        fromPage="targetSetting"
      />
      <div className="mt-3">
        <Spin indicator={antIcon} spinning={load}>
          <Tabs activeKey={name} onChange={handleTabChange}>
            <TabPane tab="Approved" key="approved">
              <div className="table-wrapper">
                <table className="table approved-table">
                  <thead className="remove-border-top">
                    <tr>
                      <th scope="col">Target Setting Id</th>
                      <th scope="col">Distributor</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Product</th>
                      <th scope="col">Time</th>
                      <th scope="col">Document</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {targetSettingData.approvedData?.results?.map((row) => (
                      <>
                        <tr key={row.targetId}>
                          <td className="blue-link cursor">
                            <p
                              // to={`/dashboard/target-setting/${row.targetId}`}
                              onClick={(e) => sendData(row, e)}
                              title={row.targetId}
                            >
                              {row.targetId}
                            </p>
                          </td>
                          <td title={row.distributorDetails.distributorName}>
                            {row.distributorDetails.distributorName}
                          </td>
                          <td>{row.customerType}</td>
                          <td>{row.productTypeId}</td>
                          <td>{formatDate(row.fromDate, row.toDate)}</td>
                          <td
                            className="green-link cursor"
                            onClick={(e) => viewDocument(row, e)}
                          >
                            View Document
                          </td>
                          <td className="blue-link cursor underline-text">
                            Track Target
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
              {targetSettingData.approvedData?.results?.length == 0 ? (
                <div className="no-data">
                  <p>No Data Available</p>
                </div>
              ) : (
                <div className="pagination">
                  {targetSettingData.approvedData?.pageMeta != null && (
                    <Pagination
                      pageSize={
                        targetSettingData.approvedData?.pageMeta?.pageSize
                      }
                      current={targetSettingData.approvedData.page}
                      total={targetSettingData.approvedData?.pageMeta?.total}
                      onChange={handlePageChange}
                    />
                  )}
                </div>
              )}
            </TabPane>
            <TabPane activeKey={name} tab="Pending" key="pending">
              <div className="table-wrapper">
                <table className="table">
                  <thead className="remove-border-top">
                    <tr>
                      <th scope="col">Target Setting Id</th>
                      <th scope="col">Distributor</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Product</th>
                      <th scope="col">Time</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {targetSettingData.pendingData?.results?.map((row) => (
                      <tr>
                        <td className="blue-link cursor" title={row.targetId}>
                          <p
                            onClick={(e) => sendData(row, e)}
                            title={row.targetId}
                          >
                            {row.targetId}
                          </p>
                        </td>
                        <td title={row.distributorDetails.distributorName}>
                          {row.distributorDetails.distributorName}
                        </td>
                        <td>{row.customerType}</td>
                        <td>{row.productTypeId}</td>
                        <td>{formatDate(row.fromDate, row.toDate)}</td>
                        <td className="orange-link break-word">
                          Template available for download
                        </td>
                        <td
                          className="blue-link cursor"
                          onClick={(e) => viewDocument(row, e)}
                        >
                          View Document
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {targetSettingData.pendingData?.results?.length == 0 ? (
                <div className="no-data">
                  <p>No Data Available</p>
                </div>
              ) : (
                targetSettingData.pendingData?.pageMeta != null && (
                  <div className="pagination">
                    <Pagination
                      current={page}
                      pageSize={
                        targetSettingData.pendingData?.pageMeta?.pageSize
                      }
                      total={targetSettingData.pendingData?.pageMeta?.total}
                      onChange={() => setPage(page + 1)}
                    />
                  </div>
                )
              )}
            </TabPane>
            <TabPane activeKey={name} tab="Rejected" key="rejected">
              <div className="table-wrapper">
                <table className="table">
                  <thead className="remove-border-top">
                    <tr>
                      <th scope="col">Target Setting Id</th>
                      <th scope="col">Distributor</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Product</th>
                      <th scope="col">Time</th>
                      <th scope="col">Document</th>
                      <th scope="col">Comments By Distributor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {targetSettingData.rejectedData?.results?.map((row) => (
                      <tr>
                        <td className="blue-link cursor" title={row.targetId}>
                          <p
                            onClick={(e) => sendData(row, e)}
                            title={row.targetId}
                          >
                            {row.targetId}
                          </p>
                        </td>
                        <td title={row.distributorDetails.distributorName}>
                          {row.distributorDetails.distributorName}
                        </td>
                        <td>{row.customerType}</td>
                        <td>{row.productTypeId}</td>
                        <td>{formatDate(row.fromDate, row.toDate)}</td>
                        <td
                          className="blue-link cursor"
                          onClick={(e) => viewDocument(row, e)}
                        >
                          View Document
                        </td>
                        <td>{row.reasonToReject}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {targetSettingData.rejectedData?.results?.length == 0 ? (
                <div className="no-data">
                  <p>No Data Available</p>
                </div>
              ) : (
                targetSettingData.rejectedData?.pageMeta != null && (
                  <div className="pagination">
                    <Pagination
                      pageSize={
                        targetSettingData.rejectedData?.pageMeta?.pageSize
                      }
                      total={targetSettingData.rejectedData?.pageMeta?.total}
                      onChange={() => setPage(page + 1)}
                    />
                  </div>
                )
              )}
            </TabPane>
          </Tabs>
        </Spin>
      </div>
    </div>
  );
}

export default TargetSetting;
