import React, { Component } from "react";
import { GetFormattedDate, getNewFormattedDate } from "../../utils/common";
import SearchBar from "../search-bar/searchBar";
import { Pagination } from "antd";
import calendar from "../../images/calendar.svg";
import { DatePicker } from "antd";
// import DatePicker from "react-datepicker";
import { saveAs } from "file-saver";
import moment from "moment";

// loader
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { Link } from "react-router-dom";
import { formatMoney } from "../../utils/common";
import {
  getAllReports,
  reportDownload,
} from "../../services/teamServices/team";
import PrimaryHeader from "../header/PrimaryHeader";
class Reports extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      search: "",
      date: new Date(),
      reportDetails: null,
    };
  }

  getReports = async (dateParam) => {
    this.setState({ loading: true });
    let { date } = this.state;
    let _date = moment(date, "DD-MM-YYYY").format("DD/MM/YYYY");
    await getAllReports(dateParam)
      .then((res) => {
        if (res.data.data.length == 0) {
          message.warning("No data available!");
        }
        this.setState({
          reportDetails: res.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        message.warning(err.response.data.message);
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };
  changeDate = (date) => {
    console.log(date);
    if (date != null) {
      this.setState(
        {
          startDate: moment(date, "DD-MM-YYYY").format("DD/MM/YYYY"),
        },
        () => {
          this.getReports(this.state.startDate);
        }
      );
    } else {
      let today = new Date();
      if (today !== null) {
        this.setState(
          {
            startDate: moment(today, "DD-MM-YYYY").format("DD/MM/YYYY"),
          },
          () => {
            this.getReports(this.state.startDate);
          }
        );
      }
    }
  };
  downloadReport = async () => {
    let { date } = this.state;

    let _date = moment(date, "DD-MM-YYYY").format("DD/MM/YYYY");
    let type = "csv";
    await reportDownload(_date, type)
      .then((res) => {
        let blob = new Blob([res.data], { type: "text/csv" });
        saveAs(blob, "report.csv");
      })
      .catch((err) => {
        message.warning(err.response.data.message);
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };

  componentDidMount = () => {
    let { date } = this.state;
    this.setState(
      {
        startDate: moment(date, "DD-MM-YYYY").format("DD/MM/YYYY"),
      },
      () => {
        this.getReports(this.state.startDate);
      }
    );
  };
  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let { loading, date, reportDetails } = this.state;

    let _date = moment(date, "DD-MM-YYYY").format("DD/MM/YYYY");
    return (
      <div id="content">
        <PrimaryHeader title="Salesmen Reports" />
        <div className="reports-header">
          <DatePicker
            onChange={(date) => this.changeDate(date)}
            format={"DD-MM-YYYY"}
          />
          <Link to={{ pathname: "/dashboard/team/reports/download" }}>
            <a className="downloadButton ml-2">Reports</a>
          </Link>
        </div>
        <div className="table-wrapper">
          <Spin indicator={antIcon} spinning={loading}>
            <table className="table">
              <thead>
                <tr>
                  {/* headers */}
                  <th>Employee</th>
                  <th>Check in </th>
                  <th>Check out</th>
                  <th>Hours Logged (hrs)</th>
                  <th>Customers in beat plan</th>
                  <th>Unique customers checked in</th>
                  <th>Total Collections</th>
                  <th>Order Count</th>
                  <th>Total Order Amount(&#8377;)</th>
                </tr>
              </thead>
              <tbody>
                {reportDetails &&
                  reportDetails.map((report, i) => {
                    return (
                      <tr key={i}>
                        <td title={report.asm} className="ordername">
                          {report.salesman}
                        </td>
                        <td>
                          <div className="row row-height">
                            {report.attendance &&
                              report.attendance.map((item, i) => {
                                return (
                                  <div
                                    className="section addition mb-0"
                                    key={i}
                                  >
                                    {/* {i + 1}. {item.checkInTime} */}
                                    {item.checkInTime}
                                  </div>
                                );
                              })}
                          </div>
                        </td>
                        <td>
                          <div className="row row-height">
                            {report.attendance &&
                              report.attendance.map((item, i) => {
                                return (
                                  <div
                                    className="section addition mb-0"
                                    key={i}
                                  >
                                    {/* {i + 1}. {item.checkOutTimeIn} */}
                                    {item.checkOutTimeIn}
                                  </div>
                                );
                              })}
                          </div>
                        </td>
                        <td>{report.totalWorkingHours}</td>
                        <td>{report.noOfCustomers}</td>
                        <td>
                          <Link
                            to={{
                              pathname: `/dashboard/team/reports/customer-details/${report._id}`,
                              state: this.state.startDate,
                            }}
                          >
                            {report.storesCheckedIn}
                          </Link>{" "}
                        </td>
                        <td>{formatMoney(report.collectionAmount)}</td>
                        <td>
                          {" "}
                          <Link
                            to={{
                              pathname: `/dashboard/team/reports/order-details`,
                              state: _date,
                              salesmanId: report._id,
                              name: report.salesman,
                            }}
                          >
                            {report.orderCount ? report.orderCount : "0"}
                          </Link>
                        </td>
                        <td>{formatMoney(report.totalOrderAmount)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Spin>
        </div>
      </div>
    );
  }
}

export default Reports;
