import React, { Component } from "react";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import "./login.scss";
import { RightSideDiv, TopDiv } from "./commondivs";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import history from "../../history";
import { getUserDetails } from "../../action/userDetails";
import PropTypes from "prop-types";
import { doLogin } from "../../services/loginServices";
import { setAuthToken, firstTimeLoggin, setRole,setRefreshToken } from "../../utils/Auth";
import Cryptr from "cryptr";
import { Link } from "react-router-dom";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Login extends Component {
  constructor(props) {
    super(props);
    this.cryptrSecretKey =
      "7ghjchbhjcb6347gbh34hr656333bvhvgvx452vb91ghvvghv7g6vgh";
    this.cryptr = new Cryptr(this.cryptrSecretKey);
    this.state = {
      email: "",
      loading: false,
      password: "",
      showEmail: false,
      showPassword: false,
    };
  }
  blur = (e) => {
    e.target.blur();
  };

  onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  setShowEmail = () => {
    console.log("trueeeeeeeee");
    this.setState({
      showEmail: !this.state.showEmail,
    });
  };

  setShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };
  // prasun.jaiswal@waycool.in 23506795
  submitFromFunction = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    const encryptedString = this.cryptr.encrypt(password);
    console.log("encryptedString", encryptedString);
    this.setState({ loading: true });
    // loginAction(formData)

    await doLogin({ email: email, password: encryptedString })
      .then((res) => {
        console.log("DPS");
        firstTimeLoggin(res.data.data.isAutogeneratedPassword);
        setRefreshToken(res.data.data.resfreshToken);
        localStorage.setItem("tokenEmail", res.data.data.email);
        if (res.data.data.isAutogeneratedPassword == 1) {
          this.setState({ loading: false });
          setAuthToken(res.data.data.token);
          if (res.data.data.accessRole == 0) {
            if (res.data.data?.regionHead == true) {
              setRole("RH");
            } else {
              setRole("DP");
            }
          } else if (res.data.data.accessRole == 1) {
            setRole("HR");
          } else if (res.data.data.accessRole == 2) {
            setRole("RBH");
          }
          history.push({
            pathname: `/user/profile/password/reset`,
          });
        } else if (res.data.data.isAutogeneratedPassword == 0) {
          this.setState({ loading: false });
          this.props.loggedIn();
          // if (userDetails === null) {
          //   this.fetchUserDetails();
          // }
          setAuthToken(res.data.data.token);
          if (res.data.data.accessRole == 1) {
            setRole("HR");
            history.push({
              pathname: `/dashboard/team/asms`,
            });
          } else if (res.data.data.accessRole == 0) {
            if (res.data.data?.regionHead == true) {
              setRole("RH");
            } else {
              setRole("DP");
            }
            history.push({
              pathname: `/dashboard/beatplan/published`,
            });
          } else if (res.data.data.accessRole == 2) {
            setRole("RBH");
            history.push({
              pathname: `/salesReturn`,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  fetchUserDetails = async () => {
    // try {
    //   const result = await userDetails();
    //   dispatch(getUserDetails(result.data.data));
    //   return result.data.data;
    // } catch (error) {
    //   const { errorMessage, errors } = handleErrorResponse(error);
    //   message.error(errorMessage);
    // }
  };

  render() {
    let { loading } = this.state;
    return (
      <div className="login-wrapper">
        <Spin indicator={antIcon} spinning={loading}>
          <div className="login-form-container">
            <RightSideDiv message={"Login into your account"} />
            <div className="login-right-div">
              <TopDiv />
              <form
                className="form"
                onSubmit={(e) => this.submitFromFunction(e)}
              >
                {/* <p className="login-label mt-3">
                  {this.state.showEmail ? "Email / Phone" : " "}
                </p> */}
                <input
                  name="email"
                  placeholder="Email"
                  type="email"
                  onChange={this.onChangeHandler}
                  onFocus={this.setShowEmail}
                />

                {/* <p className="login-label mt-3">
                  {this.state.showPassword ? "Password" : " "}
                </p> */}
                <input
                  name="password"
                  placeholder="Password"
                  type="password"
                  autocomplete="off"
                  onChange={this.onChangeHandler}
                  onFocus={this.setShowPassword}
                />

                <Link to={`/user/profile/password/forget`}>
                  <p className="forgot-password">Forgot password?</p>
                </Link>
                <button
                  className="btn btn-primary mt-3 px-5"
                  onClick={(e) => this.blur(e)}
                  type="submit"
                >
                  Login
                </button>
              </form>
              {/* <Link to={`/customerBalance/1026470`}>
                <p className="forgot-password">Balance Creation</p>
              </Link> */}

              {/* <Link to="/customerapp">File</Link> */}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default Login;
