import React, { useState, useEffect } from "react";
import PrimaryHeader from "../../components/header/PrimaryHeader";
import { AsyncPaginate } from "react-select-async-paginate";
import { Input } from "antd";
import "./salesReturn.scss";
import { useHistory } from "react-router-dom";
import {
  getReturnDate,
  getCutomerId,
  getInvoiceDate,
  getPaymentType,
  getSearchList,
} from "./salesOrderReturnServices";
import { upperFirst } from "lodash";
import moment from "moment";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { LoadingOutlined, SnippetsOutlined } from "@ant-design/icons";
import {
  Spin,
  Pagination,
  Tabs,
  DatePicker,
  Space,
  Modal,
 
  Checkbox,
} from "antd";

const { Search } = Input;
const antIcon = <LoadingOutlined className="loadingIcon" spin />;
// const pendingArray = [
//   {
//     date: "22/03/2022",
//     id: "108908310",
//     invoiceDate: "23/03/2022",
//     customer: "12",
//     itemCount: "100",
//     paymentType: "Pending",
//   },
//   {
//     date: "22/03/2022",
//     id: "108908310",
//     invoiceDate: "23/03/2022",
//     customer: "12",
//     itemCount: "100",
//     paymentType: "Request Raised",
//   },
// ];

const options = [
  { key: "Key", value: "value", label: "label" },
  { key: "Key", value: "value", label: "label" },
  { key: "Key", value: "value", label: "label" },
];

function SalesOrderReturn(props) {
  const history = useHistory();
  const [image, setImage] = useState(false);
  const [returnDate, setReturnDate] = useState("");
  const [customer, setCustomer] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [paymentType, setpaymentType] = useState("");
  const [filterValues, setFilterValues] = useState({
    returnDate: "",
    customer: "",
    invoiceDate: "",
    paymentType: "",
  });
  const [page, setPage] = useState(1);
  const [skipPage, setSkipPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalitems, setTotalitems] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [limit, setLimit] = useState(10);
  const [resultArr, setResultArr] = useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  const handleChange = (e, type) => {
    console.log(e)
    if (type == "returnDate") {
      setFilterValues((prev) => ({
        ...prev,
        returnDate: e ? e : "",
      }));
    }
    if (type == "customer") {
      setFilterValues((prev) => ({
        ...prev,
        customer: e ? e : "",
      }));
    }
    if (type == "invoiceDate") {
      setFilterValues((prev) => ({
        ...prev,
        invoiceDate: e ? e : "",
      }));
    }
    if (type == "paymentType") {
      setFilterValues((prev) => ({
        ...prev,
       paymentType: e ? e : "",
      }));
    }
    if (type == "search") {
      setSearchValue(e.target.value);
    }
  };

  const resetFilters = () => {
    setFilterValues((prev) => ({
      ...prev,
      returnDate: "",
      customer: "",
      invoiceDate: "",
      paymentType: "",
    }));
   
  };
  const dynamicFilters = () => {
    let qParams = [];
    if (filterValues["returnDate"] != "") {
      qParams = [
        ...qParams,
        {
          returnDate: moment(filterValues["returnDate"].label).format("YYYY/MM/DD"),
        },
      ];
    }

    if (filterValues["invoiceDate"] != "") {
      console.log(filterValues["invoiceDate"]);
      qParams = [
        ...qParams,
        {
          invoiceDate: moment(filterValues["invoiceDate"].label).format("YYYY/MM/DD"),
        },
      ];
    }
    if (filterValues["customer"] != "") {
      qParams = [
        ...qParams,
        {
          customerid: filterValues["customer"].value,
        },
      ];
    }
    if (filterValues["paymentType"] != "") {
      qParams = [
        ...qParams,
        {
          paymentType: filterValues["paymentType"].label,
        },
      ];
      console.log( filterValues["paymentType"].label)
    }

    if (searchValue != "") {
      qParams = [...qParams, { invoiceNo: searchValue }];
    }
    // if (searchValue != "") {
    //   qParams = [...qParams, { salesOrderNo: searchValue }];
    // }

    return qParams;
  };

  const RaiseReturn = () => {
    history.push("/dashboard/sales-order/sales-order-return-so-detail");
  };

  const redirection = (id, ReturnId) => {
      props.history.push({
          pathname: `/dashboard/sales-order/sales-order-return-view-only/${id}`,
          state: { invoiceId: ReturnId },
      });
  };

  const loadReturnDate = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 5 }, { searchText: search }];
    let data = await getReturnDate(qParams).then((res) => {
      console.log(res.data.data.ReturnDateData, "ress");
      return {
        options:
          res.data.data &&res.data.data&&
          res.data.data.ReturnDateData.map((item) => {
            return {
              key: item._id,
              value: item["Return Date"],
              label: moment(item["Return Date"]).format("YYYY/MM/DD"),
            };
          }),
        totalPage: parseInt(15 * res.data.data.length || 0) / parseInt(15 || 0),
      };
    });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadCutomerId = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 5 }, { searchText: search }];
    let data = await getCutomerId(qParams).then((res) => {
      console.log(res, "Customer");
      return {
        options:
          res.data.data &&  res.data.data &&
          res.data.data.customer.map((item) => {
            return {
              key: item._id,
              value: item["Customer ID"],
              label: item["Customer ID"]+"/"+item["Customer Name"],
            };
          }),
        totalPage: parseInt(15 * res.data.data.length || 0) / parseInt(15 || 0),
      };
    });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadInvoiceDate = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 5 }, { searchText: search }];
    let data = await getInvoiceDate(qParams).then((res) => {
      console.log(res, "invoice");
      return {
        options:
          res.data.data &&  res.data.data&&
          res.data.data.InvoiceDateData.map((item) => {
            return {
              key: item._id,
              value: item["Invoice Date"],
              label:moment(item["Invoice Date"]).format("YYYY/MM/DD"),
            };
          }),
        totalPage: parseInt(15 * res.data.data.length || 0) / parseInt(15 || 0),
      };
    });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadPaymentType = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 5 }, { searchText: search }];
    let data = await getPaymentType(qParams).then((res) => {
      console.log(res, "payment");
      return {
        options:
          res.data.data &&
          res.data.data.resultData.map((item) => {
            return {
              key: item._id,
              value: item["Payment Type"],
              label: item["Payment Type"],
            };
          }),
        totalPage: parseInt(15 * res.data.data.length || 0) / parseInt(15 || 0),
      };
    });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  const SearchList = async (Params) => {
    setLoading(true);

    await getSearchList(Params)
      .then((res) => {
        console.log(res.data, "ressss");

        setResultArr(res.data.data.searchListing);
        setPageSize(res.data.data.pageMeta.pageSize * 7);
        setLoading(false);
        setSkipPage(res.data.data.pageMeta.skip);
        setTotalitems(res.data.data.pageMeta.total);
      })

      .catch((err) => {
        console.log(err, "errrr");
        setLoading(false);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  useEffect(() => {

    let filterParams = dynamicFilters();
    let Params = [{ page: page }, { pageSize: limit }, ...filterParams];
    SearchList(Params);
  }, [
    filterValues["returnDate"],
    filterValues["customer"],
    filterValues["invoiceDate"],
    filterValues["paymentType"],
    page,
    searchText,
  ]);
  const onSearch = (value) => {
  
      setSearchText(value);
 
  };
  
  const handlePageChange = (page, pageSize) => {
   
      let filterParams = dynamicFilters();
      setPage(page);
      setPageSize(pageSize);
      let Params = [
        { page },
        { pageSize: limit },
       
        { invoiceNo: searchText },
        ...filterParams,
      ];
      SearchList(Params);
    
  }
  const returnTableTbody =
    resultArr.length > 0 &&
    resultArr.map((item) => {
      return (
        <tr className="cursor" onClick={() => redirection(item._id,item.ReturnId)}>
          <td>{item.ReturnDate ? moment(item.ReturnDate).format("DD/MM/YYYY"):"-"}</td>
          <td>{item.ReturnId ?item.ReturnId :"-"}</td>
          <td>{item.InvoiceDate ?moment(item.InvoiceDate).format("DD/MM/YYYY") :"-"}</td>
          <td>{item.InvoiceId ? item.InvoiceId :"-"}</td>
          <td>{item.CustomerName ? item.CustomerName :"-"}
         <br/> #{item.CustomerId ?item.CustomerId :"-" }
          </td>
          <td>{item.ItemCount}</td>
          <td>{item.PaymentType ? item.PaymentType :"-"}</td>
          {/* <td>{item.rate ? item.rate :"-"}</td> */}
          <td>{item.returnAmount ? item.returnAmount :"-"}</td>
        </tr>
      );
    });

  return (
    <div id="content">
      <PrimaryHeader title={"Sales Return"} />
      <div className="d-flex">
        <div className="col-lg-5 mt-2">
          <Search
            placeholder="Search by SO ID/
            Invoice ID "
            onSearch={onSearch}
            enterButton
            value={searchValue}
            onChange={(e) => handleChange(e, "search")}
            className="customSearch"
          />
        </div>
        <div className="offset-lg-5  mt-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={RaiseReturn}
          >
            Raise Return
          </button>
        </div>
      </div>
      <Spin indicator={antIcon} spinning={loading}>
      <div className="d-flex col-lg-12">
        <div className="mt-5 border_right franchise col-lg-2">
          <AsyncPaginate
            className="basic-multi-select"
            additional={{
              page: 1,
            }}
            loadOptions={loadReturnDate}
            isClearable={true}
            isSearchable={true}
            closeMenuOnSelect={true}
             value={filterValues["returnDate"]}
            onChange={(e) => handleChange(e, "returnDate")}
            loadingMessage={() => "No Date Found"}
            placeholder={"Return Date"}
          />
        </div>
        <div className="mt-5 border_right franchise col-lg-2">
          <AsyncPaginate
            className="basic-multi-select"
            additional={{
              page: 1,
            }}
            loadOptions={loadCutomerId}
            isClearable={true}
            isSearchable={true}
            closeMenuOnSelect={true}
             value={filterValues["customer"]}
            onChange={(e) => handleChange(e, "customer")}
            loadingMessage={() => "No Date Found"}
            placeholder={"Customer ID/Name"}
          />
        </div>
        <div className="mt-5 border_right franchise col-lg-2">
          <AsyncPaginate
            className="basic-multi-select"
            additional={{
              page: 1,
            }}
            loadOptions={loadInvoiceDate}
            isClearable={true}
            isSearchable={true}
            closeMenuOnSelect={true}
             value={filterValues["invoiceDate"]}
            onChange={(e) => handleChange(e, "invoiceDate")}
            loadingMessage={() => "No Date Found"}
            placeholder={"Invoice Date"}
          />
        </div>
        <div className="mt-5 border_right franchise col-lg-2">
          <AsyncPaginate
            className="basic-multi-select"
            additional={{
              page: 1,
            }}
            loadOptions={loadPaymentType}
            isClearable={true}
            isSearchable={true}
            closeMenuOnSelect={true}
             value={filterValues["paymentType"]}
            onChange={(e) => handleChange(e, "paymentType")}
            loadingMessage={() => "No Date Found"}
            placeholder={"Payment Type"}
          />
        </div>
        <div className="mt-5 ml-4 pt-2">
          <label
            htmlFor=""
            className="cursor filter_label"
            onClick={() => resetFilters()}
          >
            {"Reset Filter"}
          </label>
        </div>
      </div>
      <div className="return_table mt-3 ml-3">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead className="remove-border-top">
              <tr>
                <th scope="col">{"Return Date"}</th>
                <th scope="col">{"Return ID"}</th>
                <th scope="col">{"Invoice Date"}</th>
                <th scope="col">{"Invoice ID"}</th>
                <th scope="col">{"Customer"}</th>
                <th scope="col">{"Item Count"}</th>
                <th scope="col">{"Payment Type"}</th>
                {/* <th scope="col">{"Rate"}</th> */}
                <th scope="col">{"Return Amount"}</th>
              </tr>
            </thead>
            <tbody>{returnTableTbody && returnTableTbody}</tbody>
          </table>
        </div>
        {returnTableTbody.length > 0 && (
              <div className="row mt-3 mb-3">
                <div className="col-lg-4">
                  Showing {skipPage + 1} to{" "}
                  {skipPage + 10 > totalitems ? totalitems : skipPage + 10} of{" "}
                  {totalitems} entries
                </div>
                <div className="offset-lg-4 col-lg-4">
                  {pageSize != 0 && (
                    <Pagination
                      defaultCurrent={1}
                      current={page}
                      total={totalitems}
                      //defaultPageSize={page}
                      showSizeChanger={false}
                      onChange={(page, pageSize) =>
                        handlePageChange(page, pageSize)
                      }
                    />
                  )}
                </div>
              </div>
            )}

      </div>
      </Spin>
    </div>
  );
}

export default SalesOrderReturn;
