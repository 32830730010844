import React, { useState, useEffect } from "react";
import Logo from "../../../src/images/Logo.svg";
import { ReactComponent as Chevron_down } from "../../../src/images/chevron-down.svg";
import Menu from "../../../src/images/hamburger-icon.svg";
// import { ReactComponent as AccessLogout } from "../../../src/images/AccessLogout.svg";
import Acces from "../../../src/images/AccessLogout.svg";
import {
  navbarData,
  inventoryData,
  dynamicData,
  userAccess,
  GeoFencingData,
} from "./navbarData";
import { Button } from "antd";
import { NavLink,useLocation } from "react-router-dom";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { motion, AnimatePresence } from "framer-motion";
import { logoutFunction } from "../../utils/Auth";
// import { userDetails } from "../../services/userServices/user";
import { useSelector } from "react-redux";
import "./navbar.scss";

const scaleVariants = {
  hover: {
    scale: 1.1,
  },
};

function FindComponent({ icon }) {
  const Icon = icon;
  return <>{<Icon />}</>;
}

function ListItem({ item, showNav,subItems = [] }) {
  const location = useLocation();

  console.log(subItems,'Check SUb Items')
  subItems = Array.isArray(subItems) ? subItems : [];
  const isSubItemActive = subItems.some(subItem => location.pathname.includes(subItem.path));

  // Determine if the current ListItem should be considered active
  console.log(item,showNav,subItems,'Check All')
  return (
    <NavLink to={item.path} isActive={() => isSubItemActive} activeStyle={{ color: "#5078F2" }} className="link">
      <motion.li
        className={item.classname}
        variants={scaleVariants}
        whileHover="hover"
      >
        {item.icon && <FindComponent icon={item.icon} />}
        <p className={showNav ? "show_content" : "hide_content"}>
          {item.title}
        </p>
      </motion.li>
    </NavLink>
  );
}

function CollapsibleListItem({ item, showNav, setShowNav, showNavTablet,subItems }) {
    
  const location = useLocation();

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const isSubItemActive = subItems.some((subItem) => location.pathname.includes(subItem.path));
    if (isSubItemActive) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [location.pathname, subItems]);

  const isActive = subItems.some((sub_item) => location.pathname.includes(sub_item.path));

  useEffect(() => {
    if (isActive) {
      setExpanded(true);
    }
  }, [isActive]);

  const handleClick = () => {
    setExpanded(!expanded);
    if (!showNav) {
      setShowNav(true);
    }
  };

  return (
    <li>
    <motion.div
        className={`nav_items ${isActive ? "active" : ""} ${expanded && !showNavTablet ? "nav_items_expanded" : ""}`}
        initial={false}
  onClick={handleClick}
  variants={scaleVariants}
  whileHover="hover"
>
  <FindComponent icon={item.icon} />
  <p className={showNav || showNavTablet ? "show_content" : "hide_content"}>
    {item.title}
  </p>
  {item.hasOwnProperty("sub_items") && (
          <span className={`chevron_down ${showNav || showNavTablet ? "show_content" : "hide_content"} ${isActive ? "active" : ""}`}>
      <Chevron_down />
    </span>
  )}
</motion.div>
      <AnimatePresence initial={false}>
        {expanded && showNav && (
          <motion.div
            className={`collapsible_list ${!showNavTablet ? "collapsible_list_glass" : ""
              }`}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 0.4,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
          >
            {item.sub_items.map((sub_item) => (
              <NavLink
                to={sub_item.path}
                activeStyle={{ color: "#5078F2" }}
                className="link"
              >
                <motion.li
                  className={sub_item.classname}
                  variants={{
                    collapsed: { scale: 0.8 },
                    open: { scale: 1 },
                  }}
                  //variants={scaleVariants}
                  whileHover="hover"
                  transition={{ duration: 0.4 }}
                >
                  {sub_item.icon && <FindComponent icon={sub_item.icon} />}
                  <p>{sub_item.title}</p>
                </motion.li>
              </NavLink>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  );
}

function Navbar({ showNav, setShowNav, tabletView }) {
  const [showNavTablet, setshowNavTablet] = useState(false);

  const userDetails = useSelector((state) => state.userDetails);
  console.log(userDetails);
  // console.log(userDetails);
  let dynamic = [
    userDetails && userDetails.isStandalone,
    userDetails && userDetails.warehouse && userDetails.warehouse.isHybrid,
  ].every((ele) => ele === true);
  const companyCode = userDetails && userDetails.companyCode;

  console.log(dynamic);
  console.log(companyCode);

  function leftNavBarData() {
    if (dynamic && companyCode != "1000") return inventoryData;
    else if (localStorage.getItem("role") == "HR") return userAccess;
    else if (localStorage.getItem("role") == "RH") return GeoFencingData;
    else return navbarData;

    // return navbarData;
  }
  const array = leftNavBarData();

  return (
    <>
      {!tabletView ? (
        <div
          className={`nav_container ${showNav ? "nav_container_expanded" : ""}`}
        >
          <nav
            className={`nav_wrapper ${showNav ? "nav_wrapper_expanded" : ""}`}
          >
            <div className="nav_header">
              <img className="logo" src={Logo} alt="Logo" />
              <div className="nav_title">
                <p
                  className={`nav_main_title ${showNav ? "show_content" : "hide_content"
                    }`}
                >
                  Dashboard
                </p>
                {/* <p
                  className={`nav-sub-title ${
                    showNav ? "show_content" : "hide_content"
                  }`}
                >
                  Warehouse Portal
                </p> */}
              </div>
            </div>
            <div className="nav-user-details">
              <p
                className={`admin-title ${showNav ? "show_content" : "hide_content"
                  }`}
              >
                Admin
              </p>
              <NavLink
                to="/dashboard/profile"
                activeStyle={{ color: "#5078F2" }}
                className="link"
              >
                <p
                  className={`admin-name ${showNav ? "show_content" : "hide_content"
                    }`}
                ></p>
                {/* {`${userDetails?.firstName} ${userDetails?.lastName}`} */}
                {userDetails &&
                  `${userDetails.firstName}
                  ${userDetails.lastName}`}
              </NavLink>
            </div>

            <ul className="nav_body">
              {array.map((item) =>
                //check if the list has sub items
                item.hasOwnProperty("sub_items") ? (
                  <CollapsibleListItem
                    key={item.name}
                    item={item}
                    showNav={showNav}
                    setShowNav={setShowNav}
                    subItems={item.sub_items}
                  />
                ) : (
                  <ListItem key={item.name} item={item} showNav={showNav} subItems={item.sub_items} />
                )
              )}
              {localStorage.getItem("role") == "HR" ? (
                <Button
                  type="link"
                  onClick={logoutFunction}
                  className="logout_sub_items  nav_items"
                >
                  <span>
                    <img src={Acces} />
                  </span>
                  <span style={{ marginLeft: "18px", color: "#7A7A7A" }}>
                    {" "}
                    Logout
                  </span>
                </Button>
              ) : (
                ""
              )}
            </ul>

            {showNav ? (
              <div className="nav_footer" onClick={() => setShowNav(false)}>
                <p>Collapse</p>
                <DoubleLeftOutlined />
              </div>
            ) : (
              <DoubleRightOutlined
                className="nav_footer nav_footer_collapsed"
                onClick={() => setShowNav(true)}
              />
            )}
          </nav>
          <div className="version-text mt-n4 ml-4">{"V 3.21.0.1"}</div>
        </div>
      ) : (
        <div className="tablet_view">
          <div className="header_tablet_view">
            <img
              id="header_menu"
              onClick={() => setshowNavTablet(true)}
              src={Menu}
              alt="menu"
            />
            <div id="tablet_header" className="nav_header">
              <img className="logo" src={Logo} alt="Logo" />
              <div className="nav_title">
                <p className="nav_main_title">GARUDA</p>
                <p className="nav_sub_title">Distributor Portal</p>
              </div>
            </div>
          </div>
          {showNavTablet && (
            <div className="nav_container">
              <motion.nav className="nav_wrapper_expanded">
                <div className="nav_header">
                  <img
                    id="menu"
                    onClick={() => setshowNavTablet(false)}
                    src={Menu}
                    alt="menu"
                  />
                  <img className="logo" src={Logo} alt="Logo" />
                  <div className="nav_title">
                    <p className="nav_main_title">GARUDA</p>
                    <p className="nav_sub_title">Distributor Portal</p>
                  </div>
                </div>
                <ul className="nav_body">
                  {array.map((item) =>
                    item.hasOwnProperty("sub_items") ? (
                      <CollapsibleListItem
                        key={item.name}
                        item={item}
                        showNav={showNav}
                        setShowNav={setShowNav}
                        showNavTablet={showNavTablet}
                      />
                    ) : (
                      <ListItem
                        key={item.name}
                        item={item}
                        showNav={showNavTablet}
                      />
                    )
                  )}
                </ul>
              </motion.nav>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Navbar;
