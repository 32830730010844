import {
  Spin
} from "antd";
import React, { useEffect, useState } from "react";
import PrimaryHeader from "../../components/header/PrimaryHeader";
import arrowleft from "../../images/arrow-left.jpg";

import { LoadingOutlined } from "@ant-design/icons";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import print from "../../images/printer.svg";
import { getInvoiceDetail, PrintKeySet } from "../../services/salesOrder/salesOrderServices";
import PrintInvoice from "./innerComponent/PrintInvoice";
import ComponentToPrint from "./printData";
import "./salesOrder.scss";
// import { isTransformEffect } from "html2canvas/dist/types/render/effects";

const antIcon = <LoadingOutlined className="loadingIcon" spin />;

export default function InvoicePrint(props) {
  const [id, setId] = useState("");
  const [tab, setTab] = useState("history");
  const [invoiceResponseData, setinvoiceResponseData] = useState({});
  const [isTaxSummaryShow, setIsTaxSummayShow] = useState();
  const [distributorDetail, setDistributorDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [companyDetails, setcompanyDetails] = useState({});
  const [shippingDetails, setshippingDetails] = useState({});
  const [itemSupplied, setitemSupplied] = useState([]);

  const [isPrint, setIsPrint] = useState(false);

  const componentRef = React.useRef(null);
  const navigateToBack = () => {
    props.history.push({
      pathname: `/dashboard/sales-order/sales-order-all`,
      state: { tab: tab },
    });
  };

  const handleGetInvoiceDetail = async () => {
    let invoiceId = props.match.params.id;
    let bodyObj = { InvoiceId: [invoiceId] };
    setLoading(true);
    await getInvoiceDetail(bodyObj).then((res) => {
      if (res?.data?.data) {
        let invoiceData = res?.data?.data?.invoiceDetails[0];
        let distributorData = res?.data?.data?.distributorData;
        let taxSummaryShow = res?.data?.data?.companyDetails?.isShowInvoiceTaxSummary;
        setIsTaxSummayShow(taxSummaryShow);
        setDistributorDetail(distributorData);
        setinvoiceResponseData(invoiceData);
        if (invoiceData.itemSupplied) {
          setitemSupplied(invoiceData.itemSupplied);
        }
        if (invoiceData.companyDetails) {
          setcompanyDetails(invoiceData.companyDetails);
        }
        if (invoiceData.shippingDetails) {
          setshippingDetails(invoiceData.shippingDetails);
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    handleGetInvoiceDetail();
    if (props.location.state && props.location.state.tab == "invoiced") {
      setTab("invoiced");
    } else {
      setTab("history");
    }
    setId(props.match.params.id);
  }, []);
  const showItemList =
    itemSupplied.length > 0 &&
    itemSupplied.map((item, i) => {
      return (
        <tr>
          <td>{i}</td>
          <td>{item.mrp_amount}</td>
          <td>{item.itemName}</td>
          <td>{item.HSN_number}</td>
          <td>{item.fre}</td>
          <td>{item.quantity}</td>
          <td>&#8377;{item.TCS_rate}</td>
          <td>&#8377;{item.discountAmount}</td>
          <td>{item.igst_value}</td>
          <td>&#8377;{item.igst_pr}</td>
          <td>&#8377;{item.igst_pr}</td>
          <td>&#8377;{item.total_amount}</td>
        </tr>
      );
    });

// console.log(invoiceResponseData, "invoiceResponseData");
  const handlePrint = async () => {
    setIsPrint(true);
    await PrintKeySet(props.match.params.id)
    setTimeout(async () => {
      await handleP();
    }, 1000);
    setTimeout(async () => {
      setIsPrint(false);
    }, 3000);
  };

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handleP = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Invoice",
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  return (
    <div id="content" className="">
      <PrimaryHeader title={`SO # ${invoiceResponseData?.soId}`} />
      <Spin indicator={antIcon} spinning={loading}>
        <div className="row">
          <div className="col-lg-2" onClick={navigateToBack}>
            <img src={arrowleft} className="cursor" />
            <span className="ml-2 " style={{ color: "#5078F2" }}>
              Back
            </span>
          </div>

          <div className="col-lg-2 offset-lg-8">
            {/* <button className="btn btn-outline-dark">
            Print
            <img
              src={print}
              // onClick={navigateToBack}
              className="ml-2 cursor mt-n1"
            />
          </button> */}
            <button
              className="btn btn-outline-dark ml-2 mr-2"
              type="button"
              onClick={() => {
                handlePrint();
              }}
            >
              <div>
                <span>Print</span>
                <img src={print} className="cursor ml-2 float-right" />
              </div>
            </button>
          </div>
        </div>
        {/* Invoice Page  */}
        <PrintInvoice
          invoiceData={invoiceResponseData}
          isTaxSummaryShow={isTaxSummaryShow}
          itemSupplied={itemSupplied}
          shippingDetails={shippingDetails}
          companyDetails={companyDetails}
          itemList={showItemList}
          distributorDetail={distributorDetail}
          invoiceTax={invoiceResponseData?.invoiceTax}
        />
      </Spin>
      <div className="mt-5">
        {isPrint && (
          <>
            <ReactToPrint
              content={reactToPrintContent}
              documentTitle="Invoice Detail"
              removeAfterPrint
            />
            <ComponentToPrint
              ref={componentRef}
              invoiceData={invoiceResponseData}
              isTaxSummaryShow={isTaxSummaryShow}
              itemSupplied={itemSupplied}
              shippingDetails={shippingDetails}
              companyDetails={companyDetails}
              itemList={showItemList}
              distributorDetail={distributorDetail}
              payerDetails={invoiceResponseData?.payerDetails}
              invoiceTax={invoiceResponseData?.invoiceTax}
            />
          </>
        )}
      </div>
    </div>
  );
}
