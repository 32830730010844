import React from "react";
import { Tabs } from "antd";
import PrimaryHeader from "../header/PrimaryHeader";
import Settings from "./settings";
import Notifications from "./Notifications";
import ManageOrder from "./ManageOrder";
import ManageShipping from "./ManageShpping";

const { TabPane } = Tabs;

const index = () => {
  function callback(key) {
    console.log(key);
  }

  return (
    <div id="content" className="" style={{ backgroundColor: "#FAFAFA" }}>
      <PrimaryHeader title="Settings" backgroundColor="#FAFAFA" />
      <Tabs
        defaultActiveKey="otp"
        onChange={callback}
        size="small"
        // className="d-inline"
      >
        {/* <TabPane tab="OTP Validation" key="otp">
          <Settings />
        </TabPane> */}

        {/* <TabPane tab="Notifications" key="notifications">
          <Notifications />
        </TabPane> */}

        <TabPane tab="Manage Order" key="manageOrder">
          <div className="mt-4 pb-5">
            <ManageOrder />
          </div>
        </TabPane>

        <TabPane tab="Manage Shipping" key="manageShipping">
          <div className="mt-4 pb-5">
            <ManageShipping />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default index;
