import generateActionTypes from "../utils/generateActionTypes";
export const actionTypes = generateActionTypes(
  "GET_ALL_PENDING_SO",
  "GET_ALL_REQUESTED_SO",
  "GET_ALL_REJECTED_SO"
);

// get all pending so data
export function getAllPendingSo(data) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.GET_ALL_PENDING_SO,
      data,
    });
  };
}

// get all requested so  data
export function getAllRequestedSo(data) {
  return (dispatch) => {
    return dispatch({ type: actionTypes.GET_ALL_REQUESTED_SO, data });
  };
}

// get all rejected so  data
export function getAllRjectedSo(data) {
  return (dispatch) => {
    return dispatch({ type: actionTypes.GET_ALL_REJECTED_SO, data });
  };
}
