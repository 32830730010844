import React, { useState } from "react";

import { Typography, Form, Input, Button, Modal } from "antd";
import { useEffect } from "react";

function CancelRequest() {
  useEffect(() => {
    localStorage.removeItem("deleteUserToken");
  }, []);
  
  return (
    <div
    className="example"
    style={{
      //marginTop: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",

      overflow: "hidden",
      scrollBehavior: "none",
      position: "fixed",
    }}
  >
    <div>
      <Typography
        style={{ fontWeight: 700, fontSize: "14px", lineHeight: "41px", marginLeft:"15px", }}
      >
        Thanks for contacting Waycool support
      </Typography>
      <div>      
    </div>
  </div>
  </div>
  );
}

export default CancelRequest;
