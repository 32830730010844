import { Typography } from "antd";
import React from "react";

function SalesDashboardData({ value, title }) {
  return (
    <div className="">
      <Typography className="sales-overview-text">{value}</Typography>
      <Typography className="sales-overview-value">{title}</Typography>
    </div>
  );
}

export default SalesDashboardData;
