import React, { Component } from "react";
import "./customerDetails.scss";
import callIcon from "../../../src/images/callsymbol.svg";
import mailIcon from "../../../src/images/mail.svg";
import SearchIcon from "../../../src/images/search.svg";
import { ReactComponent as Search } from "../../images/search.svg";

import Gstn from "../../../src/images/gstn.svg";
import DownloadLedger from "../../../src/images/download-ledger.svg";
import { Tabs } from "antd";
import sync from "../../images/sync.svg";
import {
  getAllCustomerLedgerDetails,
  getAllInvoiceData,
  getAllPaymentData,
  getAllCreditData,
  getAllDebitData,
  getInvoiceDataSync,
  getPaymentDataSync,
  getCreditDataSync,
  getDebitDataSync,
  downloadLedger,
} from "./../../services/customerServices/customer";
import { SyncOutlined, LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import moment from "moment";
import message from "antd/lib/message";
import DefaultPic from "../../images/default-pic.jpg";
import DefaultTablePic from "../../images/defaultPic.svg";
import { stringSortingByChar } from "../../utils/common";
import { DebounceInput } from "react-debounce-input";
import { saveAs } from "file-saver";
import PrimaryHeader from "../header/PrimaryHeader";
import { handleErrorResponse } from "../../utils/Request";

const { TabPane } = Tabs;

class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      inSyncInvoice: false,
      inSyncPayment: false,
      insyncCredit: false,
      inSyncDebit: false,
      loading: true,
      // itemsToShow:2,
      // expanded:false,
      tabValue: "invoice",
      tabLoader: false,
    };
  }

  callback = (key) => {
    if (key == 1) {
      this.setState({
        tabValue: "invoice",
      });
    } else if (key == 2) {
      this.setState({
        tabValue: "payments",
      });
    } else if (key == 3) {
      this.setState({
        tabValue: "creditNote",
      });
    } else if (key == 4) {
      this.setState({
        tabValue: "debitNote",
      });
    }
  };

  getAllCustomerLedgerFunction = async (id) => {
    let { getAllCustomersLedgerDetails } = this.props;
    this.setState({
      loading: true,
    });
    await getAllCustomerLedgerDetails(id)
      .then((res) => {
        this.setState({
          loading: false,
          cityId: res.data.data.cityId,
        });
        getAllCustomersLedgerDetails(res.data.data);
      })

      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        console.log("Error" + err);
      });
  };

  getInvoiceDataFunction = async (id) => {
    let { getInvoiceData } = this.props;
    this.setState({ tabLoader: true });
    let qParams = [{ search: this.state.searchText }];
    await getAllInvoiceData(id, qParams)
      .then((res) => {
        getInvoiceData(res.data.data);
        this.setState({ tabLoader: false });
      })
      .catch((err) => {
        console.log("Error" + err);
        this.setState({ tabLoader: false });
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };

  getPaymentDataFunction = async (id) => {
    let { getPaymentData } = this.props;
    let qParams = [{ search: this.state.searchText }];
    this.setState({ tabLoader: true });
    await getAllPaymentData(id, qParams)
      .then((res) => {
        getPaymentData(res.data.data);
        this.setState({ tabLoader: false });
      })
      .catch((err) => {
        console.log("Error" + err);
        this.setState({ tabLoader: false });
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };

  getCreditDataFunction = async (id) => {
    let { getCreditData } = this.props;
    let qParams = [{ search: this.state.searchText }];
    this.setState({ tabLoader: true });
    await getAllCreditData(id, qParams)
      .then((res) => {
        getCreditData(res.data.data);
        this.setState({ tabLoader: false });
      })
      .catch((err) => {
        console.log("Error" + err);
        this.setState({ tabLoader: false });
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };

  getdebitDataFunction = async (id) => {
    let { getDebitData } = this.props;
    let qParams = [{ search: this.state.searchText }];
    this.setState({ tabLoader: true });
    await getAllDebitData(id, qParams)
      .then((res) => {
        getDebitData(res.data.data);
        this.setState({ tabLoader: false });
      })
      .catch((err) => {
        console.log("Error" + err);
        this.setState({ tabLoader: false });
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };

  invoiceSync = async () => {
    this.setState({
      inSyncInvoice: true,
    });

    let location = this.state.cityId;
    await getInvoiceDataSync(location).then((res) => {
      if (res.data.status == 200) {
        let syncingMsg = "Syncing process Completed";
        message.info(syncingMsg);
        this.setState({ inSyncInvoice: false });
      }
    });
  };

  paymentSync = async () => {
    this.setState({
      inSyncPayment: true,
    });
    let location = this.state.cityId;
    await getPaymentDataSync(location).then((res) => {
      if (res.data.status == 200) {
        let syncingMsg = "Syncing process Completed";
        message.info(syncingMsg);
        this.setState({ inSyncPayment: false });
      }
    });
  };

  creditNoteSync = async () => {
    this.setState({
      insyncCredit: true,
    });

    let location = this.state.cityId;
    await getCreditDataSync(location).then((res) => {
      console.log("message", res.data.status);
      if (res.data.status == 200) {
        let syncingMsg = "Syncing process Completed";
        message.info(syncingMsg);
        this.setState({ insyncCredit: false });
      }
    });
  };

  debitNoteSync = async () => {
    this.setState({
      inSyncDebit: true,
    });
    let location = this.state.cityId;
    await getDebitDataSync(location).then((res) => {
      console.log("message", res.data.status);
      if (res.data.status == 200) {
        let syncingMsg = "Syncing process Completed";
        message.info(syncingMsg);
        this.setState({ inSyncDebit: false });
      }
    });
  };

  changeTime = (time) => {
    let gmtDateTime = moment.utc(time, "YYYY-MM-DD HH");
    let local = gmtDateTime.local().format("h:mm A | DD MMM YYYY");
    return local;
  };

  // showMore=()=>{
  //   let {allCustomersLedgerDetails} =this.props
  //   this.state.itemsToShow === 2 ? (
  //     this.setState({itemsToShow:allCustomersLedgerDetails.beatPlans.length, expanded:true})
  //   ):
  //   (
  //     this.setState({ itemsToShow:2, expanded: false })
  //   )
  // }

  componentDidMount = () => {
    let id = this.props.match.params.id;
    this.getAllCustomerLedgerFunction(id);
    this.getInvoiceDataFunction(id);
    this.getPaymentDataFunction(id);
    this.getCreditDataFunction(id);
    this.getdebitDataFunction(id);
  };

  onSearch = (e) => {
    this.setState(
      {
        searchText: e.target.value,
      },
      () => {
        let { tabValue } = this.state;
        let qParams = [{ search: this.state.searchText }];
        let id = this.props.match.params.id;
        if (tabValue == "invoice") {
          this.getInvoiceDataFunction(id, qParams);
        } else if (tabValue == "payments") {
          this.getPaymentDataFunction(id, qParams);
        } else if (tabValue == "creditNote") {
          this.getCreditDataFunction(id, qParams);
        } else if (tabValue == "debitNote") {
          this.getdebitDataFunction(id, qParams);
        }
      }
    );
  };

  downloadLedger = async () => {
    this.setState({
      loading: true,
    });
    let id = this.props.match.params.id;
    try {
      const response = await downloadLedger(id);
      let blob = new Blob([response.data], {
        type: "application/pdf;charset=utf-8",
      });
      saveAs(blob, "ledger.pdf");
      this.setState({
        loading: false,
      });
    } catch (error) {
      message.warning(error.response.data.message);
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    let {
      allCustomersLedgerDetails,
      allInvoiceData,
      allPaymentData,
      allCreditdata,
      allDebitData,
    } = this.props;
    console.log("allInvoiceData", allInvoiceData);
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div id="content">
        <PrimaryHeader title="Customer Details" />
        <Spin indicator={antIcon} spinning={this.state.loading}>
          <div className="add-shadow">
            <div className="customer-details-wrapper">
              <img
                className="customerProfile ml-0"
                src={DefaultPic}
                alt="CustomerProfilePhoto"
              />
              <div className="customer-info">
                <p
                  className="customer-name"
                  title={allCustomersLedgerDetails.name}
                >
                  {allCustomersLedgerDetails.name}
                </p>
                <p className="pb-3">
                  Customer ID {allCustomersLedgerDetails.goFrugalId}
                </p>
                <img src={callIcon} alt="callIcon" />
              </div>
              <div className="account-overview-wrapper">
                <div className="account-overview-top">
                  <p>ACCOUNT OVERVIEW</p>
                  <button
                    className="btn btn-primary"
                    onClick={this.downloadLedger}
                    download
                  >
                    Download Ledger
                  </button>
                </div>
                <ul className="horizontal-list">
                  <li>
                    <p>ON-ACCOUNT</p>
                    <p id="on-account">
                      &#8377;
                      {allCustomersLedgerDetails.customerAccounts
                        ? allCustomersLedgerDetails.customerAccounts &&
                          allCustomersLedgerDetails.customerAccounts.onAccount
                        : "0"}
                    </p>
                  </li>
                  <li>
                    <p>OVERDUE</p>
                    <p id="overdue">
                      &#8377;
                      {allCustomersLedgerDetails.customerAccounts
                        ? allCustomersLedgerDetails.customerAccounts &&
                          allCustomersLedgerDetails.customerAccounts
                            .customerOverdueOutstanding
                        : "0"}
                    </p>
                  </li>
                  <li>
                    <p>OUTSTANDING</p>
                    <p id="outstanding">
                      {" "}
                      &#8377;
                      {allCustomersLedgerDetails.customerAccounts
                        ? allCustomersLedgerDetails.customerAccounts &&
                          allCustomersLedgerDetails.customerAccounts
                            .customerTotalOutstanding
                        : "0"}
                    </p>
                  </li>
                  <li>
                    <p>CREDIT LIMIT</p>
                    <p id="credit-limit">
                      {allCustomersLedgerDetails
                        ? allCustomersLedgerDetails.creditLimit
                        : "0"}
                    </p>
                  </li>
                  {/* <li>
                    <p>CREDIT DAYS</p>
                    <p id="credit-days">
                      {allCustomersLedgerDetails.customerAccounts
                        ? allCustomersLedgerDetails.customerAccounts &&
                          allCustomersLedgerDetails.customerAccounts.creditDays
                        : "0"}
                    </p>
                  </li> */}
                </ul>
              </div>
            </div>

            {/* Account details */}
            <div className="account-details-wrapper">
              <p className="account-sub-title">ACCOUNT DETAILS</p>
              <div>
                <ul className="horizontal-list">
                  <li>
                    <p>Customer Name</p>
                    <p title={allCustomersLedgerDetails.name}>
                      {stringSortingByChar(allCustomersLedgerDetails.name, 18)}
                    </p>
                  </li>
                  <li>
                    <p>Customer Id</p>
                    <p>{allCustomersLedgerDetails.goFrugalId}</p>
                  </li>
                  <li>
                    <p>Email</p>
                    <p>
                      {allCustomersLedgerDetails.email == " "
                        ? "N/A"
                        : allCustomersLedgerDetails.email}
                    </p>
                  </li>
                  <li>
                    <p>Phone</p>
                    <p>
                      {allCustomersLedgerDetails.mobile
                        ? allCustomersLedgerDetails.mobile
                        : "N/A"}
                    </p>
                  </li>
                </ul>
                <ul className="horizontal-list">
                  <li>
                    <p> Address </p>
                    <p
                      title={
                        allCustomersLedgerDetails.address1 +
                        " " +
                        allCustomersLedgerDetails.address2 +
                        " " +
                        allCustomersLedgerDetails.address3 +
                        " "
                        // +
                        // allCustomersLedgerDetails.area
                      }
                    >
                      {stringSortingByChar(
                        allCustomersLedgerDetails.address1 == " "
                          ? "N/A"
                          : allCustomersLedgerDetails.address1 +
                              " " +
                              allCustomersLedgerDetails.address2 +
                              " " +
                              allCustomersLedgerDetails.address3 +
                              " ",
                        //+allCustomersLedgerDetails.area
                        18
                      )}
                    </p>
                  </li>
                  {/* <li>
                    <p> Address 2</p>
                    <p
                      title={
                        allCustomersLedgerDetails.address2
                        // +
                        // " " +
                        // allCustomersLedgerDetails.address2 +
                        // " " +
                        // allCustomersLedgerDetails.address3 +
                        // " " +
                        // allCustomersLedgerDetails.area
                      }
                    >
                      {stringSortingByChar(
                        allCustomersLedgerDetails.address2 == " "
                          ? "N/A"
                          : allCustomersLedgerDetails.address2,
                        // +
                        // " " +
                        // allCustomersLedgerDetails.address2 +
                        // " " +
                        // allCustomersLedgerDetails.address3 +
                        // " " +
                        // allCustomersLedgerDetails.area
                        18
                      )}
                    </p>
                  </li>
                  <li>
                    <p> Address 3</p>
                    <p
                      title={
                        allCustomersLedgerDetails.address3
                        // +
                        // " " +
                        // allCustomersLedgerDetails.address2 +
                        // " " +
                        // allCustomersLedgerDetails.address3 +
                        // " " +
                        // allCustomersLedgerDetails.area
                      }
                    >
                      {stringSortingByChar(
                        allCustomersLedgerDetails.address3 == " "
                          ? "N/A"
                          : allCustomersLedgerDetails.address3,
                        // +
                        // " " +
                        // allCustomersLedgerDetails.address2 +
                        // " " +
                        // allCustomersLedgerDetails.address3 +
                        // " " +
                        // allCustomersLedgerDetails.area
                        18
                      )}
                    </p>
                  </li> */}
                  <li>
                    <p>City</p>
                    <p>
                      {allCustomersLedgerDetails.city == " "
                        ? "N/A"
                        : allCustomersLedgerDetails.city}
                    </p>
                  </li>
                  <li>
                    <p>Pincode</p>
                    <p>
                      {allCustomersLedgerDetails.pincode == ""
                        ? "N/A"
                        : allCustomersLedgerDetails.pincode}
                    </p>
                  </li>
                  <li>
                    <p>GSTN</p>
                    <p>
                      {" "}
                      {allCustomersLedgerDetails.gstNumber == " "
                        ? "N/A"
                        : allCustomersLedgerDetails.gstNumber}
                    </p>
                  </li>
                </ul>
                {/* <li>
                    <p>Group</p>
                    <p>
                      {allCustomersLedgerDetails.customerAccounts ? 
                        allCustomersLedgerDetails.customerAccounts.group : "N/A"}
                    </p>
                  </li>
                  <li>
                    <p>Sub-Group</p>
                    <p>
                      {allCustomersLedgerDetails.customerAccounts ?
                        allCustomersLedgerDetails.customerAccounts.subGroup  : "N/A"}
                    </p>
                  </li> */}
              </div>
            </div>
          </div>
          {/* mapped salesmen details */}
          <div className="add-shadow mapped-salesmen-details">
            <div className="salesman-details-top">
              <p className="account-sub-title">MAPPED SALESMAN DETAILS</p>
              <p className="no-of-salesmen">
                No.Of Salesman :{" "}
                {allCustomersLedgerDetails.beatPlans &&
                  allCustomersLedgerDetails.beatPlans.length}
              </p>
            </div>
            <div class="table-wrapper">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Salesman Name </th>
                    <th scope="col">Employee ID</th>
                    <th scope="col">Manager</th>
                    <th scope="col">Email </th>
                    <th scope="col">Phone </th>
                  </tr>
                </thead>

                {allCustomersLedgerDetails.beatPlans &&
                  allCustomersLedgerDetails.beatPlans.map((salesman, i) => {
                    return (
                      <tbody>
                        <tr>
                          <td>
                            <img src={DefaultTablePic} alt={"Dphoto"} />
                            &nbsp;
                            {salesman.beatPlan.salesman &&
                              salesman.beatPlan.salesman?.salesman?.fullName}
                          </td>
                          <td>
                            {salesman.beatPlan.salesman &&
                              salesman.beatPlan.salesman?.salesman?.employeeId}
                          </td>
                          <td>
                            {salesman.beatPlan.salesman &&
                              salesman.beatPlan.salesman?.salesman?.fullName}
                          </td>
                          <td>
                            {salesman.beatPlan.salesman &&
                              salesman.beatPlan.salesman?.salesman?.email}
                          </td>
                          <td>
                            {salesman.beatPlan.salesman &&
                              salesman.beatPlan.salesman?.salesman
                                ?.contactMobile}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            </div>
          </div>

          {/* {allCustomersLedgerDetails.beatPlans &&
            allCustomersLedgerDetails.beatPlans.length == 0 && (
              <div className="no-data">No Data Available</div>
            )} */}

          {/* {
               allCustomersLedgerDetails.beatPlans && allCustomersLedgerDetails.beatPlans.length==0 ? 
                 (
                   <div className="no-data">No Data Available</div>
                 ):
                 (
               
                  <div>
                    <button className="show-more-button" onClick={this.showMore}>
                    {this.state.expanded ? (
                    <span>Show Less</span>
                   ) : (
                   <span>Show More</span>
                  )}
                   </button>
                  </div> 
                 )     
                 } */}

          <div className="add-shadow">
            <Spin indicator={antIcon} spinning={this.state.tabLoader}>
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Invoices" key="1">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="common__search">
                      <Search />
                      <input
                        placeholder="Search with Invoice ID"
                        type="text"
                        value={this.state.searchText}
                        onChange={this.onSearch}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="last-update mr-3">
                        LAST UPDATED ON{" "}
                        {this.changeTime(allInvoiceData.lastSyncTime)}
                      </span>
                      <SyncOutlined
                        style={{
                          fontSize: 20,
                          color: "green",
                        }}
                        onClick={this.invoiceSync}
                        title="Click to start Invoice live sync"
                        spin={this.state.inSyncInvoice}
                      />
                    </div>
                  </div>

                  <div class="table-wrapper">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Invoice ID</th>
                          <th scope="col">Invoice Voucher No</th>
                          <th scope="col">Payment Status</th>
                          <th scope="col">Date(DD-MM-YYYY)</th>
                          <th scope="col">Invoice Amount</th>
                          <th scope="col">Pending Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allInvoiceData.data > 0 &&
                          allInvoiceData.data.map((invoice, i) => (
                            <tr key={i}>
                              <td>{invoice.invoiceId}</td>
                              <td>{invoice.invoiceVoucher}</td>
                              <td></td>
                              <td>
                                {moment(invoice.invoiceDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td>&#8377;{invoice.totalAmount}</td>
                              <td>&#8377;{invoice.amountRemaining}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {allInvoiceData.data == 0 && (
                    <div className="no-data">No Data Available</div>
                  )}
                </TabPane>

                <TabPane tab="Payments" key="2">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="common__search">
                      <Search />
                      <input
                        placeholder="Search with Payment ID"
                        type="text"
                        value={this.state.searchText}
                        onChange={this.onSearch}
                      />
                    </div>

                    <div className="d-flex align-items-center">
                      <div className="last-update mr-3">
                        LAST UPDATED ON{" "}
                        {this.changeTime(allPaymentData.lastSyncTime)}
                      </div>

                      <SyncOutlined
                        style={{
                          fontSize: 22,
                          color: "green",
                        }}
                        onClick={this.paymentSync}
                        spin={this.state.inSyncPayment}
                      />
                    </div>
                  </div>
                  <div class="table-wrapper">
                    <table className="table table-hover my_table">
                      <thead>
                        <tr>
                          <th scope="col">Invoice ID</th>
                          <th scope="col">Payment Reference Number</th>
                          <th scope="col">Date(DD-MM-YYYY)</th>
                          <th scope="col">Payment Amount</th>
                          <th scope="col">Mode</th>
                        </tr>
                      </thead>
                      {allPaymentData.data &&
                        allPaymentData.data.map((payment, i) => {
                          return (
                            <tbody>
                              <tr key={i}>
                                <td>{payment.invoiceId}</td>
                                <td>{payment.paymentReferenceNo}</td>
                                <td>
                                  {moment(payment.entryDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>&#8377;{payment.paymentAmount}</td>
                                <td>{payment.paymentType}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  </div>
                  {allPaymentData.data == 0 && (
                    <div className="no-data">No Data Available</div>
                  )}
                </TabPane>

                <TabPane tab="Credit Note" key="3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="common__search">
                      <Search />
                      <input
                        placeholder="Search with Invoice ID"
                        type="text"
                        value={this.state.searchText}
                        onChange={this.onSearch}
                      />
                    </div>

                    <div className="d-flex align-items-center">
                      <div className="last-update mr-3">
                        LAST UPDATED ON{" "}
                        {this.changeTime(allCreditdata.lastSyncTime)}
                      </div>
                      <SyncOutlined
                        style={{
                          fontSize: 20,
                          color: "green",
                        }}
                        onClick={this.creditNoteSync}
                        spin={this.state.insyncCredit}
                      />
                    </div>
                  </div>
                  <div class="table-wrapper">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Invoice ID</th>
                          <th scope="col">Date(DD-MM-YYYY)</th>
                          <th scope="col">Credit-Note Amount</th>
                        </tr>
                      </thead>
                      {allCreditdata.data &&
                        allCreditdata.data.map((credit, i) => {
                          return (
                            <tbody>
                              <tr>
                                <td>{credit.invoiceId}</td>
                                <td>
                                  {moment(credit.creditNoteDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>&#8377;{credit.amount}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  </div>
                  {allCreditdata.data == 0 && (
                    <div className="no-data">No Data Available</div>
                  )}
                </TabPane>

                <TabPane tab="Debit Note" key="4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="common__search">
                      <Search />
                      <input
                        placeholder="Search with Debit Note ID"
                        type="text"
                        value={this.state.searchText}
                        onChange={this.onSearch}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="last-update mr-3">
                        LAST UPDATED ON{" "}
                        {this.changeTime(allDebitData.lastSyncTime)}
                      </div>

                      <SyncOutlined
                        style={{
                          fontSize: 20,
                          color: "green",
                        }}
                        title="Click to start Debit Note live sync"
                        onClick={this.debitNoteSync}
                        spin={this.state.inSyncDebit}
                      />
                    </div>
                  </div>
                  <div class="table-wrapper">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Invoice ID</th>
                          <th scope="col">Date(DD-MM-YYYY)</th>
                          <th scope="col">Debit-Note Amount</th>
                        </tr>
                      </thead>
                      {allDebitData.data &&
                        allDebitData.data.map((debit, i) => {
                          return (
                            <tbody>
                              <tr key={i}>
                                <td>{debit.invoiceId}</td>
                                <td>
                                  {moment(debit.debitNoteDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>&#8377;{debit.amount}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  </div>
                  {allDebitData.data == 0 && (
                    <div className="no-data">No Data Available</div>
                  )}
                </TabPane>
              </Tabs>
            </Spin>
          </div>
        </Spin>
      </div>
    );
  }
}
export default CustomerDetails;
