import {
  Typography,
  DatePicker,
  Button,
  Menu,
  Modal,
  Input,
  Radio,
  Space,
  Divider,
  notification,
  Select,
  Spin,
} from "antd";
import "./payment.scss";
import "./placeorder.scss";
import { CheckOutlined } from "@ant-design/icons";
import MenuItem from "antd/lib/menu/MenuItem";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import PrimaryHeader from "../header/PrimaryHeader";
import OrderSteps from "./OrderSteps";
import message from "antd/lib/message";
import { getPaymentDetails } from "../../action/paymentDetailsaction";
import { handleErrorResponse } from "../../utils/Request";

import {
  onCheckoutService,
  generateSOIDService,
} from "../../services/orderServices/checkoutService";
import "./checkout.scss";
import moment from "moment";

function Payment() {
  const [openMessage, setOpenMessage] = useState(false);
  const [remark, setRemark] = useState("");
  const [paymentMode, setpaymentMode] = useState("cod");
  let history = useHistory();
  //const today = new Date();
  const [disableArr, setdisableArr] = useState(moment());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showCreditLimits, setShowCreditLimits] = useState(false);
  const [disablePlaceOrder, setDisablePlaceOrder] = useState(false);
  const [buttonload, setButtonload] = useState(false);

  const [loadingUntilCustomerCheck, setLoadingUntilCustomerCheck] =
    useState(false);

  const dispatch = useDispatch();
  const { selectedCustomer } = useSelector(
    (state) => state.serachOrdersReducer
  );

  const { cartItemList } = useSelector((state) => state.itemCart);

  // useEffect(() => {
  //   if (!cartItemList.length) {
  //     history.goBack();
  //   }
  // }, []);
  const { TextArea } = Input;
  let arr = [];
  arr.push(moment());
  //setdisableArr(arr);
  const { paymentDetails, loading } = useSelector(
    (state) => state.paymentDetailsReducer
  );
  let selectedtime = paymentDetails?.slotTimes?.find(
    (item) => item.isDefault === true
  );
  const [timeSlot, setTimeSlot] = useState();
  const [istimeSlotRequired, setIstimeSlotRequired] = useState(true);

  useEffect(() => {
    setIstimeSlotRequired(paymentDetails?.isDeliverySlotEnabled);
  }, [paymentDetails?.isDeliverySlotEnabled]);
  useEffect(() => {
    if (selectedtime?._id) {
      setTimeSlot(selectedtime);
    } else {
      setTimeSlot(selectedtime);
    }
  }, [selectedtime]);

  const minimumDelDate = paymentDetails?.minDeliveryDay;
  const today = moment(paymentDetails?.currentDate);
  const minDate = moment(today, "DD-MM-YYYY").add(minimumDelDate, "days");
  const sootp = paymentDetails?.otpStatus;

  // const [deliveryDate, setdeliveryDate] = useState(moment(minDate));
  const [deliveryDate, setdeliveryDate] = useState(moment(localStorage.getItem("SelectedDeliveryDate")).format("YYYY-MM-DD"));
  // const ExcludedDays = paymentDetails?.daysExempted;
  const ExcludedDays = [];
  const totalNoOfDays =
    paymentDetails?.maxDeliveryDay - paymentDetails?.minDeliveryDay;
  const disabledDatesArr = [];
  const j = 0;
  // const getExcludedDays = () => {
  let days_exempted = [];

  function disabledDate(current) {
    return disabledDatesArr.every(
      (m) =>
        m.startOf("day")._d.toString() !== current.startOf("day")._d.toString()
    );
  }

  const onCheckout = async () => {
    if (!deliveryDate) {
      notification.error({ message: "Please select delivery date" });
      return;
    }
    if (istimeSlotRequired == true && timeSlot == undefined) {
      notification.error({ message: "Please select delivery time slot" });
      return;
    }
    let selectedslot = timeSlot;
    let chekout;
    if (!istimeSlotRequired) {
      chekout = {
        dateOfDelivery: deliveryDate,
        orderRemark: remark,
        paymentMode: paymentMode,
        creditLimit: paymentDetails.creditLimit,
        creditExposure: paymentDetails.out_standing_amount,
        creditBalance: paymentDetails.totalCreditBalance,
      };
    } else {
      chekout = {
        dateOfDelivery: deliveryDate,
        orderRemark: remark,
        paymentMode: paymentMode,
        creditLimit: paymentDetails.creditLimit,
        creditExposure: paymentDetails.out_standing_amount,
        creditBalance: paymentDetails.totalCreditBalance,
        endTimeSlot: selectedslot?.endTimeSlot,
        startTimeSlot: selectedslot?.startTimeSlot,
        deliveryTimeSlot: selectedslot?.displayLabel,
      };
    }
    if (sootp) {
      const dateOfDelivery = deliveryDate;
      const location = {
        pathname: `/dashboard/orders/payment-otp`,
        state: {
          dateOfDelivery,
          remark,
          paymentMode,
          chekout,
          // ...selectedslot,
          // endTimeSlot,
          // startTimeSlot,
          // displayLabel,
        },
      };
      history.push(location);
    } else {
      setLoadingUntilCustomerCheck(true);
      // delete selectedslot._id;
      try {
        await onCheckoutService(selectedCustomer.goFrugalId, chekout).then(
          (response) => {
            if (response.status === 200) {
              if (response.data.status == 200) {
                setIsModalVisible(true);
                postCheckout(response.data.data.soDBId);
              } else {
                notification.error({ message: response.data.error });
              }
            } else {
              notification.error({
                message: response?.data?.error
                  ? response.data.error
                  : "Something went wrong",
              });
            }
            setLoadingUntilCustomerCheck(false);
          }
        );
      } catch (err) {
        if (err?.response?.status === 401) {
          const { errorMessage, errors } = handleErrorResponse(err);
        } else {
          message.error("Unable to process the request");
        }

        setLoadingUntilCustomerCheck(false);
      }
    }
  };
  const postCheckout = async (soID) => {
    const request = {
      sales_order_key: soID,
    };
    await generateSOIDService(request).then((response) => { });
  };

  const [value, setValue] = useState(1);

  let paymentOptions = [
    {
      id: 1,
      label: "Cash On Delivery",
      value: "cod",
    },
    {
      id: 2,
      label: "Credits",
      value: "credit",
    },
  ];
  const onDeliverDateChange = (e) => {
    //  const delivryDate = moment(e).format("YYYY-MM-DD");
    setdeliveryDate(e);
  };
  const onRemarksChange = (e) => {
    setRemark(e.target.value);
  };
  const onPaymentModeChange = (val) => {
    const paymentMode = val;
    if (
      paymentMode == "credit" &&
      paymentDetails?.totalCreditBalance &&
      parseFloat(paymentDetails?.totalCreditBalance) <
      parseFloat(paymentDetails?.netTotal)
    ) {
      setShowCreditLimits(true);
      setDisablePlaceOrder(true);
      //  disablePlaceOrder = true;
    } else {
      setShowCreditLimits(false);
      setDisablePlaceOrder(false);
      // disablePlaceOrder = false;
    }
    setpaymentMode(paymentMode);
  };
  const onChangeTimeSlot = (val) => {
    let selectedtime = paymentDetails?.slotTimes?.find(
      (item) => item.displayLabel === val
    );
    // const timeslot = val;
    setTimeSlot(selectedtime);
  };
  const validateCreditBalance = () => {
    if (
      parseFloat(paymentDetails?.totalCreditBalance) <
      parseFloat(paymentDetails?.netTotal)
    )
      return false;
    else return true;
  };
  const CustomerId = selectedCustomer?.goFrugalId;
  useEffect(() => {
    dispatch(getPaymentDetails(CustomerId));
  }, []);

  const [current, setCurrent] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      setCurrent(2);
    }, 200);
  }, []);

  useEffect(() => {
    if (paymentDetails?.daysExempted?.length >= 1) {
      days_exempted = paymentDetails?.daysExempted;
      days_exempted.forEach((element) => {
        switch (element.toLowerCase()) {
          case "monday": {
            ExcludedDays.push(1);
            break;
          }
          case "tuesday": {
            ExcludedDays.push(2);
            break;
          }
          case "wednesday": {
            ExcludedDays.push(3);
            break;
          }
          case "thursday": {
            ExcludedDays.push(4);
            break;
          }
          case "friday": {
            ExcludedDays.push(5);
            break;
          }
          case "saturday": {
            ExcludedDays.push(6);
            break;
          }
          case "sunday": {
            ExcludedDays.push(0);
            break;
          }
          default: {
            break;
          }
        }
      });
    }

    for (var i = 0; i <= totalNoOfDays; i++) {
      // const day = moment.day
      const dayOfWeek = moment(minDate, "DD-MM-YYYY").add(i, "days").weekday();
      let isExcluded = ExcludedDays.every((m) => m !== dayOfWeek);
      if (isExcluded) {
        disabledDatesArr.push(moment(minDate, "DD-MM-YYYY").add(i, "days"));
      }
    }
  });
  return (
    <div className="w-100 h-100" style={{ backgroundColor: "#f5f7fa" }}>
      <div className="px-4">
        <PrimaryHeader title="Place Order" backgroundColor="#f5f7fa" />
      </div>
      <OrderSteps
        current={current}
        onClickBack={() => history.push("/dashboard/orders/placeorder")}
        onClickBackItem={() => history.push("/dashboard/orders/checkout")}
      />
      <div className="p-5 m-4 " style={{ backgroundColor: "#fff" }}>
        <div>
          <div className="d-flex mb-4">
            <Divider
              type="vertical"
              style={{
                border: "2px solid #737EED",
                height: "22px",
                marginTop: "3px",
              }}
            />
            <div>
              <Typography>Delivery & Payment </Typography>
            </div>
          </div>
          <div className="m-2">
            <div className="my-4" style={{ minHeight: 80 }}>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#3A4960cc",
                  fontWeight: 400,
                }}
              >
                Delivery Address :
              </Typography>
              {loading ? (
                <Spin className="m-3 mt-4 ml-5" />
              ) : (
                <>
                  <Typography
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#3A4960",
                      marginTop: "10px",
                    }}
                  >
                    {paymentDetails?.deliveryAddress?.address1}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",

                      color: "#3A4960",
                    }}
                  >
                    {paymentDetails?.deliveryAddress?.address2}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#3A4960",
                    }}
                  >
                    {paymentDetails?.deliveryAddress?.city}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "#3A4960",
                    }}
                  >
                    {paymentDetails?.deliveryAddress?.pincode}
                  </Typography>
                </>
              )}
            </div>
            <div className="my-4">
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#3A4960cc",
                  fontWeight: 400,
                }}
              >
                Choose Delivery Date{" "}
              </Typography>
              <Input className="mt-2 selectPaymentMode" style={{ height: "45px" }} value={deliveryDate} readOnly></Input>
              {/* <DatePicker
                className="mt-2 chooseDatePicker"
                size="middle"
                format="DD/MM/YYYY"
                placement="topRight"
                value={deliveryDate}
                style={{ padding: "11px" }}
                onChange={onDeliverDateChange}
                disabledDate={disabledDate}
              /> */}
            </div>
            {istimeSlotRequired ? (
              <div>
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#3A4960cc",
                    fontWeight: 400,
                  }}
                >
                  Choose Time Slot
                </Typography>
                <div style={{ display: "flex" }} className="payment_mode">
                  <Select
                    className="mt-2 selectPaymentMode"
                    onChange={onChangeTimeSlot}
                    placeholder="Choose Time Slot"
                    value={timeSlot?.displayLabel}
                  >
                    {paymentDetails?.slotTimes?.map((item) => (
                      <option key={item.displayLabel} value={item.displayLabel}>
                        {item.displayLabel}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="my-4">
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#3A4960cc",
                  fontWeight: 400,
                }}
              >
                Choose Payment Mode
              </Typography>

              <div style={{ display: "flex" }} className="payment_mode">
                <Select
                  className="mt-2 selectPaymentMode"
                  onChange={onPaymentModeChange}
                  defaultValue={"cod"}
                  placeholder="Payment mode"
                >
                  {paymentOptions.map((item) => (
                    <option
                      key={item.id}
                      value={item.value}
                    //  disabled={item.id !== 1 && validateCreditBalance()}
                    >
                      {item.label}
                    </option>
                  ))}
                </Select>

                {showCreditLimits && (
                  <span className="ml-5 mt-2">
                    <Typography
                      style={{
                        color: "#3A4960",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      Credit Balance = ₹{paymentDetails?.totalCreditBalance}
                    </Typography>

                    <Typography
                      className="mt-1"
                      style={{ color: "#E13440", fontSize: "14px" }}
                    >
                      Note : Insufficient Credit Balance please choose Cash on
                      delivery.
                    </Typography>
                  </span>
                )}
              </div>
              <div className="my-4">
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#3A4960cc",
                    fontWeight: 400,
                  }}
                >
                  Remarks(optional)
                </Typography>
                <TextArea
                  className="mt-2 remarkInput"
                  value={remark}
                  placeholder="Enter the remarks"
                  onChange={onRemarksChange}
                  rows={5}
                />
              </div>
              <div className="mt-5">
                <Button
                  className="save_button"
                  disabled={disablePlaceOrder}
                  style={{
                    height: "40px",

                    borderRadius: "5px",
                  }}
                  loading={loadingUntilCustomerCheck}
                  onClick={onCheckout}
                >
                  Place Order
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal visible={isModalVisible} closable={false} footer={null}>
          <div style={{ textAlign: "center", padding: "15px" }}>
            <CheckOutlined
              size="large"
              style={{
                fontSize: "30px",
                color: "white",
                backgroundColor: "#219653",
                borderRadius: "40px",
                padding: "10px",
              }}
            />
            <h5 className="mt-3">Order has been placed succesfully!</h5>
            <br />
            <p>Go To</p>
            <Link to="/dashboard/orders">
              <Button
                style={{
                  backgroundColor: "#5078F2",
                  color: "white",
                  width: "135px",
                  height: "36px",
                }}
              >
                Orders
              </Button>
            </Link>
          </div>
        </Modal>
        <div className={isModalVisible ? "payment_backdrop" : ""}></div>
      </div>
    </div>
  );
}

export default Payment;
