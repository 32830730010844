import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import message from "antd/lib/message";
import debounce from "debounce-async";
import html2pdf from "html2pdf.js";
import "jspdf-autotable";
import { upperFirst } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import ArrowLeft from "../../../images/arrow-left.svg";
import Download from "../../../images/download (3).svg";
import circle from "../../../images/Ellipse 160.svg";
import {
  getMaterialJourney,
  getMaterialJourneyNewAPI,
  getStockBatchId,
} from "../../../services/inventoryServices/inventoryServices";
import { handleErrorResponse } from "../../../utils/Request";
import PrimaryHeader from "../../header/PrimaryHeader";

export default function JourneyReports(props) {
  const [array, setArray] = useState([]);
  const [roadMap, setRoadMap] = useState([]);
  const [date, setDate] = useState("");
  const [batchId, setBatchId] = useState({ label: "All", value: 0 });
  const [loading, setLoading] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    const values = props.location.state.values;
    const plant = localStorage.getItem("plant");
    setDate(
      moment(values["from"]).format("YYYY/MM/DD") +
        " - " +
        moment(values["to"]).format("YYYY/MM/DD")
    );

    let qParams = [
      { searchText: values["materialCode"].label.split(" ").shift() },
      { fromDate: moment(values["from"]).format("DD/MM/YYYY") },
      { toDate: moment(values["to"]).format("DD/MM/YYYY") },
    ];
    getData(qParams);
  }, []);

  const getData = async (qParams) => {
    setLoading(true);
    await getMaterialJourney(qParams)
      .then((res) => {
        setLoading(false);
        setArray(res && res.data.data && res.data.data[0].data);
        res.data.data[0].data.batchId && getMaterialRoadMap("All");
        if (res.data.message == "Data not found !") {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const getMaterialRoadMap = async (batchId) => {
    setLoading(true);
    const values = props.location.state.values;
    let filterQparams = filterParams(batchId);
    let qParams = [
      { fromDate: moment(values["from"]).format("DD/MM/YYYY") },
      { toDate: moment(values["to"]).format("DD/MM/YYYY") },
      { itemId: values["materialCode"].label.split(" ").shift() },
      ...filterQparams,
    ];

    let params = [
      { fromDate: moment(values["from"]).format("DD/MM/YYYY") },
      { toDate: moment(values["to"]).format("DD/MM/YYYY") },
      { itemId: values["materialCode"].label.split(" ").shift() },
    ];

    await getMaterialJourneyNewAPI(qParams).then((resp) => {
      let journeyDatas = resp?.data?.data;
      let filteredArray = journeyDatas.filter((n) => n.values.length > 0);
      setRoadMap(filteredArray);
      setLoading(false);
    });
  };
  const redirectToBack = () => {
    props.history.push({
      pathname: "/dashboard/inventory-management/report",
      state: "Material Journey",
    });
  };
  const loadBatchId = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 5 }, { batchId: search }];
    let data = await getStockBatchId(qParams).then((res) => {
      let addedArray =
        page == 1
          ? [{ _id: 1, BatchID: "All" }, ...res.data.data]
          : [...res.data.data];
      return {
        options:
          addedArray &&
          addedArray.map((item) => {
            return {
              key: item._id,
              value: item.BatchID,
              label: upperFirst(item.BatchID),
            };
          }),
        totalPage: parseInt(15 * res.data.data.length || 0) / parseInt(15 || 0),
      };
    });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };
  const perfectFormat = (item) => {
    let format = item.date;
    let d = moment(format, "DD-MM-YYYY");

    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // d = new Date(d);
    let char = month[d && d._d.getMonth()];
    let date = d && d._d.getDate();
    let year = d && d._d.getFullYear();

    return (
      <span className="date_content">{`${
        char && char.slice(0, 3)
      } ${date}, ${year}`}</span>
    );
  };

  const dateFormatString = (date) => {
    return new Date(date);
  };

  const journeyReportsInfo = roadMap.map((item, index) => {
    return (
      <div className="report_div">
        <div className={`${index === 0 ? "first_obj" : "second_obj"}`}>
          <label
            htmlFor=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="main_time"
          >
            {perfectFormat(item)}
          </label>
        </div>
        {item.values
          .sort(
            (a, b) =>
              dateFormatString(a.dateCreated) - dateFormatString(b.dateCreated)
          )
          .sort((a, b) => (a.type == "Opening Stock" ? -1 : 1))
          .map((sub_item, index) => {
            if (sub_item.qty > 0) {
              return (
                <div
                  className="d-flex"
                  style={{
                    flexDirection: "column",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {index === 0 && (
                    <div
                      style={{
                        height: "40px",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      <hr
                        style={{ marginTop: "40px" }}
                        className="first_hr ml-2"
                      />
                    </div>
                  )}
                  <div className="d-flex">
                    <div
                      className={`${
                        sub_item.dateCreated ? "ml-n5 mt-1" : "ml-n5 mt-1"
                      }`}
                    >
                      <label htmlFor="" className="ml-n4">
                        {sub_item.dateCreated
                          ? moment(sub_item.dateCreated).format("LTS")
                          : "N/A"}
                      </label>
                    </div>
                    <div
                      // className="ml-4 pl-2 mt-1"
                      className={`${
                        sub_item.dateCreated
                          ? "mt-1 ml-3 pl-2"
                          : "ml-4 pl-2 mt-1"
                      }`}
                    >
                      <img src={circle} alt="" className="ml-1" />
                    </div>
                    <hr className="second_hr" />
                    <div className="top_one mt-n1">
                      <label htmlFor="">
                        {!sub_item.from && (
                          <>
                            <span className="mj_labels">{`${sub_item.type}`}</span>
                            <span>{` - ${sub_item.qty} units`}</span>
                          </>
                        )}
                        {sub_item.from && (
                          <>
                            <span className="mj_labels">{`${sub_item.type}`}</span>
                            <span>{` - From : ${sub_item.from} _ To : ${sub_item.to} - ${sub_item.qty} units`}</span>
                          </>
                        )}
                      </label>
                    </div>
                  </div>
                  {index + 1 < item.values.length && (
                    <div
                      style={{
                        height: "50px",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      <hr className="third_hr" />
                    </div>
                  )}
                </div>
              );
            }
          })}
      </div>
    );
  });
  const handleChange = (e, type) => {
    if (type == "batchId") {
      setBatchId(e ?? "");
      if (e?.label) {
        getMaterialRoadMap(e.label);
      }
    }
  };
  const filterParams = (batchId) => {
    let qParams = [];
    if (batchId != "All") {
      qParams = [...qParams, { batchId: batchId }];
    }
    return qParams;
  };
  const pdfView = (
    <div id="pdf_view">
      {roadMap.map((item, index) => {
        return (
          <div className="report_div">
            <div className={`${index === 0 ? "first_obj" : "second_obj"}`}>
              <label
                htmlFor=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="main_time"
              >
                {perfectFormat(item)}
              </label>
            </div>
            {item.values
              .sort(
                (a, b) =>
                  dateFormatString(a.dateCreated) -
                  dateFormatString(b.dateCreated)
              )
              .sort((a, b) => (a.type == "Opening Stock" ? -1 : 1))
              .map((sub_item, index) => {
                if (sub_item.qty > 0) {
                  return (
                    <div
                      className="d-flex"
                      style={{
                        flexDirection: "column",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {index === 0 && (
                        <div
                          style={{
                            height: "40px",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <hr
                            style={{ marginTop: "40px" }}
                            className="first_hr_pdf ml-2"
                          />
                        </div>
                      )}
                      <div className="d-flex">
                        <div
                          className={`${
                            sub_item.dateCreated ? "ml-n5 mt-1" : "ml-n5 mt-1"
                          }`}
                        >
                          <label htmlFor="" className="ml-n4">
                            {sub_item.dateCreated
                              ? moment(sub_item.dateCreated).format("LTS")
                              : "N/A"}
                          </label>
                        </div>
                        <div
                          // className="ml-4 pl-2 mt-1"
                          className={`${
                            sub_item.dateCreated.length == 11
                              ? "mt-1 ml-3 pl-2"
                              : "ml-4 pl-2 mt-1"
                          }`}
                        >
                          <img src={circle} alt="" className="ml-1" />
                        </div>
                        <hr className="second_hr" />
                        <div className="top_one mt-n1">
                          <label htmlFor="">
                            {!sub_item.from && (
                              <>
                                <span className="mj_labels">{`${sub_item.type}`}</span>
                                <span>{` - ${sub_item.qty} units`}</span>
                              </>
                            )}
                            {sub_item.from && (
                              <>
                                <span className="mj_labels">{`${sub_item.type}`}</span>
                                <span>{` - From : ${sub_item.from} _ To : ${sub_item.to} - ${sub_item.qty} units`}</span>
                              </>
                            )}
                          </label>
                        </div>
                      </div>
                      {index + 1 < item.values.length && (
                        <div
                          style={{
                            height: "50px",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <hr className="third_hr_pdf" />
                        </div>
                      )}
                    </div>
                  );
                }
              })}
          </div>
        );
      })}
    </div>
  );
  const downloadAPI = () => {
    setLoading(true);
    const element = document.getElementById("pdf_view");
    var opt = {
      filename: "materialJourneyReport.pdf",
      image: { type: "jpg", quality: 0.2 },
      html2canvas: { dpi: 192, letterRendering: true, useCORS: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
    };
    setLoading(false);
    html2pdf().from(element).set(opt).save();
  };
  return (
    <div id="content">
      <PrimaryHeader title={"Reports"} />
      <Spin indicator={antIcon} spinning={loading}>
        <div className="card">
          <div className="card-body">
            <div className="d-flex cursor">
              <div className="" onClick={redirectToBack}>
                <img src={ArrowLeft} alt="" />
              </div>
              <div className="ml-2 mt-1 arrow_label" onClick={redirectToBack}>
                <p>{"Back"}</p>
              </div>
              <div className="ml-auto mt-n1 stock_audit_btns">
                <button
                  className="btn btn-outline-secondary mr-2"
                  onClick={downloadAPI}
                  disabled={journeyReportsInfo.length == 0}
                >
                  {"Download"}
                  <img src={Download} alt="" className="ml-2" />
                </button>
              </div>
            </div>
            <div className="stock_title ml-1 mt-3">
              <label htmlFor="" className="border_left">
                {"Search Results"}
              </label>
            </div>
            <div className="row mt-3 ml-1">
              <div className="col-lg-3">
                <label htmlFor="" className="journey_labels">
                  {"Material Code"}
                </label>
                <br />
                <label htmlFor="" className="journey_sub">
                  {(array && array.materialCode) || "N/A"}
                </label>
              </div>
              <div className="col-lg-3">
                <label htmlFor="" className="journey_labels">
                  {"Material Name"}
                </label>
                <br />
                <label htmlFor="" className="journey_sub">
                  {(array && array.materialName) || "N/A"}
                </label>
              </div>
              <div className="ml-3 mr-2">
                <label htmlFor="" className="journey_labels">
                  {"Date"}
                </label>
                <br />
                <label htmlFor="" className="journey_sub">
                  {date ? date : "N/A"}
                </label>
              </div>
              <div className="col-lg-3 mt-n3">
                <label htmlFor="" className="journey_labels">
                  {"Batch ID"}
                </label>
                <br />
                <AsyncPaginate
                  className="basic-multi-select"
                  additional={{
                    page: 1,
                  }}
                  loadOptions={debounce(loadBatchId, 500)}
                  isClearable={true}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  value={batchId}
                  onChange={(e) => handleChange(e, "batchId")}
                  loadingMessage={() => "No ID's Found"}
                />
              </div>
            </div>
            <hr />
            <div>{journeyReportsInfo && journeyReportsInfo}</div>
            {journeyReportsInfo.length === 0 && (
              <div className="no-data">
                <p>{"No Data Available"}</p>
              </div>
            )}
            <div style={{ display: "none" }}>{pdfView}</div>
          </div>
        </div>
      </Spin>
    </div>
  );
}
