import generateActionTypes from "../utils/generateActionTypes";
export const actionTypes = generateActionTypes(
  "GET_ALL_INTERNAL_PO",
  "GET_ALL_EXTERNAL_PO",
  "GET_SINGLE_PO_DETAILS"
);

// get all internal po data
export function getAllInternalPo(data) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.GET_ALL_INTERNAL_PO,
      data,
    });
  };
}

// get all external po data
export function getAllExternalPo(data) {
  return (dispatch) => {
    return dispatch({ type: actionTypes.GET_ALL_EXTERNAL_PO, data });
  };
}

// get single po details
export function getSinglePoDetails(data) {
  return (dispatch) => {
    return dispatch({ type: actionTypes.GET_SINGLE_PO_DETAILS, data });
  };
}
