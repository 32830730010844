//CONVERT TIME TO 12h FORAMT
export default (time) => {
  if (!time) return false;
  let [hours, minute] = time?.split(":");
  let suffix = hours >= 12 ? "PM" : "AM";
  hours = hours > 12 ? hours - 12 : hours;
  hours = hours == "00" ? 12 : hours;
  if (!hours || !minute) {
    return false;
  }
  return `${hours}:${minute} ${suffix}`;
};
