import React, { Component } from "react";
import redCross from "../../images/redCross.svg";
import defaultPic from "../../images/defaultPic.svg";
import { formatMoney } from "../../utils/common";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { MapsContext } from '../../services/Utility';
import { RouteMapLines } from '../Maps';
import MapsProvider from '../../services/GoogleMaps';
import { secondsToTime } from '../../helper/DateTime';
import WrappedMap from "./googleMap";
import "./draftBeatPlan.scss";
class DailyViewTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stops: [],
      routeInfo: {
        duration: '',
        distance: ''
      },
    };
  }

  handleChange = (key, data) => {
    let { routeDetails } = Object.assign({}, this.state);
    routeDetails[key] = data;

    this.setState({ routeDetails });
    console.log('The Route Details here is --> ', routeDetails);
  };

  handleRouteInfo = routeInfo => {
    this.setState({ routeInfo });
  };

  onChangeDrag = () => {
    console.log('iam called here');
  }

  async componentDidMount() {
    let { customerDetailsPerDay, getLocationArr } = this.props;
    let instance = await MapsProvider.getMapsInstance();
    this.setState({ instance });
    getLocationArr(customerDetailsPerDay);
    console.log("in daily comp customerDetailsPerDay as props", customerDetailsPerDay)
  }
  render() {
    let {
      instance,
      routeInfo
    } = this.state;
    let {
      customerDetailsPerDay,
      dailyViewDayDetails,
      deleteCustomer,
      mapView,
      dragging,
      onDragEndOfDayView,
      onDragStartOfDayView,
      mapCenter,
      showInfo,
      removeInfo,
      selectedCustomer,
      stops,
      from,
      to,
    } = this.props;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    console.log("dailyViewDayDetails", dailyViewDayDetails);
    console.log(from)

    return (
      <DragDropContext
        onDragEnd={(result) =>
          onDragEndOfDayView(result, customerDetailsPerDay, dailyViewDayDetails)
        }
        onDragStart={onDragStartOfDayView}
      >
        <div className={mapView ? "row shrinkedOutsideBox" : "row outsideBox"}>
          {/* droppable starts */}
          <div className={mapView ? "col-md-6" : "col-md-12"}>
            <Droppable
              droppableId={dailyViewDayDetails._id}
              className={mapView ? "shrink" : "noshrink"}
            >
              {(provided, i) => (

                <div
                  className="dailyList"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  key={i}
                >
                  {customerDetailsPerDay.map((item, i) => {
                    console.log(item)
                    // if (item.seq == (i + 1))
                    // console.log('Tyhe customer her eis ---> ', item, i);
                    return (
                      // draggable starts
                      <Draggable
                        onDragEnd={this.onChangeDrag}
                        onDragStart={this.onChangeDrag}
                        draggableId={`${item.customer && item.customer._id} + ${i}`}
                        index={i}
                        key={item.customer && item.customer._id}
                      >
                        {(provided) => (
                          <div
                            key={item.customer && item.customer._id}
                            className="outside-div"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <span className="seqNo"> {item.seq}. </span>
                            {/* add shrink classname conditionally */}
                            <div
                              // className={
                              //   mapView
                              //     ? "row daily-div p-2 ml-0 shrink"
                              //     : "row daily-div p-2 ml-0"
                              // }
                              className="row daily-div p-2 ml-0"
                            >
                              <img
                                src={defaultPic}
                                alt="defaultPic"
                                className="customerPic"
                              />
                              <div className="col">
                                <p className="customerName">
                                  {" "}
                                  {item.customer && item.customer.name} <br />
                                  <span className="customer-name">
                                    {item.customer && item.customer.goFrugalId}
                                  </span>
                                </p>
                              </div>
                              {item.customer && item.customer.location && item.customer.location.coordinates && item.customer.location.coordinates[0] == null &&
                                <i class="fa fa-exclamation fa-lg" title="Location is not available !" style={{ color: 'red' }}></i>
                              }
                              <div className={mapView ? "col none" : "col"}>
                                <p>
                                  GROUP <br />
                                  <span className="grp-name">
                                    {item.customer &&
                                      item.customer.customersAccount &&
                                      item.customer.customersAccount.group}
                                  </span>
                                </p>
                              </div>
                              <div className={mapView ? "col none" : "col"}>
                                <span>
                                  SUB-GROUP <br />{" "}
                                  {item.customer &&
                                    item.customer.customersAccount &&
                                    item.customer.customersAccount.subGroup}
                                </span>
                              </div>
                              <div className={mapView ? "col none" : "col"}>
                                <p>
                                  ON-ACCOUNT <br />
                                  <span className="on-account">
                                    &#8377;{" "}
                                    {formatMoney(
                                      item.customer &&
                                      item.customer.customersAccount &&
                                      item.customer.customersAccount.onAccount
                                    )}
                                  </span>
                                </p>
                              </div>
                              <div className={mapView ? "col none" : "col"}>
                                <p>
                                  OUTSTANDING <br />{" "}
                                  <span className="outstanding">
                                    {" "}
                                    &#8377;{" "}
                                    {formatMoney(
                                      item.customer &&
                                      item.customer.customersAccount &&
                                      item.customer.customersAccount
                                        .totalOutStanding
                                    )}
                                  </span>
                                </p>
                              </div>
                              <div className={mapView ? "col none" : "col"}>
                                <p>
                                  OVERDUE <br />{" "}
                                  <span className="overdue">
                                    {" "}
                                    &#8377;{" "}
                                    {formatMoney(
                                      item.customer &&
                                      item.customer.customersAccount &&
                                      item.customer.customersAccount
                                        .overdueOutStanding
                                    )}{" "}
                                  </span>
                                </p>
                              </div>
                              <div className={mapView ? "col none" : "col"}>
                                <p>
                                  CREDIT LIMIT <br />
                                  <span className="credit-limit">
                                    {" "}
                                    &#8377;{" "}
                                    {formatMoney(
                                      item.customer &&  item.customer.creditLimit
                                    )}{" "}
                                  </span>
                                </p>
                              </div>
                              <div className={mapView ? "col none" : "col"}>
                                <p>
                                  CREDIT DAYS <br />
                                  <span className="credit-days">
                                    {" "}
                                    {item.customer && item.customer.creditDays}
                                  </span>
                                </p>
                              </div>

                              <img
                                src={redCross}
                                alt="cross"
                                className="cross-padding"
                                onClick={() =>
                                  deleteCustomer(
                                    item._id,
                                    dailyViewDayDetails.day,
                                    dailyViewDayDetails.customers,
                                    dailyViewDayDetails._id,
                                    "dailyView"
                                  )
                                }
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                      // draggable ends
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          {/* droppable ends */}
          {mapView && (
            <div className="col-md-6">
              <div className="row mb-2">
                <div className="map-estimation">
                  <div className="row">
                    <div className="col-md-6 text-center form-input">
                      <label>&nbsp;</label>
                      <span className="text-primary form-control">
                        <span>
                          Estimated time:&nbsp;
													</span>

                        {false ? (
                          <span>
                            {secondsToTime(
                              to.timeFromSource *
                              60
                            )}
                          </span>
                        ) : (
                            <span>
                              {routeInfo.duration
                                ? routeInfo.duration
                                : ' --'}
                            </span>
                          )}
                      </span>
                    </div>
                    <div className="col-md-6 text-center form-input">
                      <label>&nbsp;</label>
                      <span className="text-primary form-control">
                        <span>Distance:&nbsp;</span>
                        {false ? (
                          <span>
                            <span>
                              {
                                to.distanceFromSource
                              }
                            </span>
                            <span>
                              &nbsp;
																{to.distanceFromSource >
                                1
                                ? 'KMS'
                                : 'KM'}
                            </span>
                          </span>
                        ) : routeInfo.distance ? (
                          routeInfo.distance
                        ) : (
                              ' --'
                            )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <RouteMapLines
                  instance={instance}
                  mapCenter={mapCenter}
                  showInfo={showInfo}
                  removeInfo={removeInfo}
                  selectedCustomer={selectedCustomer && selectedCustomer}
                  origin={from}
                  destination={to}
                  stops={stops}
                  onChange={this.handleRouteInfo}
                  // overlay={
                  //   !from.value || !to.value
                  // }
                  overlayText={() => {
                    return (
                      <>
                        <span className="mr-3">
                          <i className="icon-alert-circle"></i>
                        </span>
                        <span>
                          Please add a Customer First !
                      </span>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {/* < div className="map-container">
              <WrappedMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAscjPuHsmxseZEeitNZp3SrZ8kl7Z1EX0"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            mapCenter={mapCenter}
            customerDetailsPerDay={customerDetailsPerDay}
            showInfo={showInfo}
            removeInfo={removeInfo}
            selectedCustomer={selectedCustomer && selectedCustomer}
          />
        </div> */}
      </DragDropContext >
    );
  }
}
export default DailyViewTable;
