import React, { useState, useEffect, useLayoutEffect } from "react";
import SearchBar from "../search-bar/searchBar";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Drafts from "../../container/beatplan";
import PlanDetails from "../planDetails/planDetails";
import TeamSalesmen from "../../container/teamSalesmen";
// import TeamAsms from "../team/teamAsms";
import Customers from "../../container/customers";
import DownloadReport from "../team/downloadReport";
import CustomerDetails from "../../container/customerLedgerDetails";
import Dashboard from "../dashboard/Dashboard";
import "./dashboard.scss";
import { loginStatus } from "../../utils/Auth";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { userDetails } from "../../services/userServices/user";
import AddNewAsm from "../../container/addNewAsm";
import TeamAsms from "../../container/teamAsms";
import AddNewSalesman from "../../components/team/addNewSalesman";
import ScrollUpButton from "react-scroll-up-button";
import Profile from "../../container/profile";
import DraftBeatPlan from "../../container/draftBeatPlan";
import PublishedBeatPlan from "../../components/beatplans/publishedBeatPlan";
import Publish from "../../components/beatplans/publish";
import Reports from "../../components/team/reports";
import OrderDetails from "../../components/team/orderDetails";
import AllCustomerDetails from "../../components/team/customerDetails";
import GofrugalSync from "../gofrugalSync/gofrugalSync";
import SapReport from "../sapReport/sapReport";
import TargetSetting from "../targetSetting/targetSetting";
import TargetSettingDetails from "../targetSetting/targetSettingDetails";
import CreateBeatPlan from "../modal/createPlanModal";
import Navbar from "../navbar/navbar";
import "../../index.scss";
// import OrderAndBasicDetails from "../orders/OrderAndBasicDetails";
import OrderAndBasicDetails from "../orders/OrdersHistory/OrderAndBasicDetails";
import Header from "../header/header";
import { useDispatch, useSelector } from "react-redux";
import * as routes from "../../utils/allroutes";
import CreateBeatPlan1 from "../modal/createBeatPlan";
import { getUserDetails } from "../../action/userDetails";
import SapSync from "./../sapSync/sapSync";
import PoGeneration from "../../container/poGeneration";
import CreatePO from "../poGeneration/CreatePO";
import PoDetails from "../../container/poDetails";
import codList from "./../../container/codList";
import Settings from "../settings";
import CreditExtension from "../cod/creditExtension";
import stockandmoq from "../products/Stockandmoq";
import Stockandmoq from "../products/Stockandmoq";
// import Pricing from "../products/Pricing";
//import Orders from "../orders/Orders";
import Orders from "../orders/OrdersHistory/Orders";
import PlaceOrder from "../orders/PlaceOrder";
import OrdersCustomersList from "../orders/OrdersCustomersList";
import Logout from "../balanceCreation/Logout";
import OrdersList from "../orders/OrdersList";
import Payment from "../orders/Payment";
// import Invoice from "../orders/Invoice";
import Invoice from "../orders/OrdersHistory/Invoice";
//import Otp from "../orders/OrdersHistory/Otp";

import PurchaseOrdersHistoryDetails from "../purchaseOrders/historyDetails";
import SalesOrderReturnView from "../salesOrder/salesOrderReturnView";

import CurrentStock from "../inventoryManagement/currentStock";
import StockAudit from "../inventoryManagement/stockAudit";
import StockMovement from "../inventoryManagement/stockMovement";
import Report from "../inventoryManagement/report";
import ClosingStock from "../inventoryManagement/currentStock/closingStock";
import results from "../inventoryManagement/stockMovement/results";
import searchResults from "../inventoryManagement/stockAudit/searchResults";
import JourneyReports from "../inventoryManagement/report/journeyReports";
import SalesOrderAll from "../salesOrder/salesOrderAll";
import SalesOrderDetail from "../salesOrder/salesOrderDetail";
import SalesOrderReturn from "../salesOrder/salesOrderReturn";
import SalesOrderReturnDetail from "../salesOrder/salesOrderReturnDetails";
import PurchaseOrders from "../purchaseOrders";
import PurchaseOrdersDetails from "../purchaseOrders/details";
import Login from "../login/login";

import InvoicePrint from "../salesOrder/invoicePrint";
import PricingUpload from "../pricingUpload/pricingUpload";
import SalesDashboard from "../salesDashboard/salesDashboard";
import Otp from "../orders/Otp";
import AccessDenied from "../balanceCreation/AccessDenied";
// import Otp from "../orders/Otp";
import SRAccessDenaid from "../SalesReturn/AccessDenied";
import TeamMapping from "../teammapping/TeamMapping";
import Viewemployee from "../teammapping/Viewemployee";
import AddEmployee from "../teammapping/AddEmployee";
import MyCustomers from "../Customers/myCustomers";
function useMedia(query) {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);
  // Activity normally for componentDidMount + componentDidUpdate
  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    media.addListener(listener);

    return () => media.removeListener(listener);
  }, [query]);

  // publish value for render
  return matches;
}

function DashboardMain() {
  const history = useHistory();
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.userDetails);

  useEffect(() => {
    // if (!localStorage.getItem("token")) {
    //   history.push({
    //     pathname: `/login`,
    //   });
    // }
    // if (
    //   localStorage.getItem("firstTimeLogin") == true &&
    //   localStorage.getItem("token")
    // ) {
    //   history.push({
    //     pathname: `/user/profile/password/reset`,
    //   });
    // }
    // if (
    //   localStorage.getItem("firstTimeLogin") == false &&
    //   localStorage.getItem("token")
    // ) {
    //   history.push({
    //     pathname: `/dashboard/beatplan/drafts`,
    //   });
    // }
    if (profileDetails === null) {
      const data = fetchUserDetails();
      console.log("in dashboard");
    }
  }, []);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  const fetchUserDetails = async () => {
    try {
      console.log("fetch data");
      const result = await userDetails();
      console.log(result, "reuslt");
      dispatch(getUserDetails(result.data.data));
      return result.data.data;
    } catch (error) {
      console.log("in catch");
      const { errorMessage, errors } = handleErrorResponse(error);
      message.error(errorMessage);
    }
  };

  const [loading, setLoading] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const tabletView = useMedia(
    "only screen and (max-width: 1200px) and (min-width: 768px)"
  );
  return (
    <>
      {localStorage.getItem("token") ? (
        <div
          class={`main-container ${
            tabletView
              ? "main-container-tablet"
              : showNav
              ? "main-container-shrink"
              : "main-container-expand"
          }`}
        >
          {localStorage.getItem("role") != "RBH" ? (
            <Navbar
              showNav={showNav}
              setShowNav={setShowNav}
              tabletView={tabletView}
            />
          ) : (
            ""
          )}

          <Switch history={history}>
            {/* <Route
                      exact
                      strict
                      path="/dashboard/"
                      component={Dashboard}
                    /> */}
            {(localStorage.getItem("token") &&
              localStorage.getItem("role") == "DP") ||
            localStorage.getItem("role") == "RH" ? (
              <>
                <Route
                  exact
                  strict
                  path="/dashboard/profile"
                  component={Profile}
                />
                {/* <Route
                  exact
                  strict
                  path="/dashboard/accessDenaid"
                  component={AccessDenied}
                /> */}
                <Route
                  exact
                  strict
                  path="/dashboard/accessDenaid"
                  component={AccessDenied}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/accessDenaid"
                  component={AccessDenied}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/beatplan/drafts"
                  component={Drafts}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/beatplan/published"
                  component={Publish}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/beatplan/create"
                  component={CreateBeatPlan1}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/beatplan/drafts/:id"
                  component={DraftBeatPlan}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/beatplan/published/:id"
                  component={PublishedBeatPlan}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/allplans/:id"
                  component={PlanDetails}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/customers"
                  component={Customers}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/myCustomers"
                  component={MyCustomers}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/customers/:id"
                  component={CustomerDetails}
                ></Route>

                <Route
                  exact
                  strict
                  path="/dashboard/team/reports"
                  component={Reports}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/team/reports/customer-details/:id"
                  component={AllCustomerDetails}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/team/reports/order-details"
                  component={OrderDetails}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/team/asms/edit/:id"
                  component={AddNewAsm}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/team/asms/new"
                  component={AddNewAsm}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/team/reports/download"
                  component={DownloadReport}
                />

                <Route
                  exact
                  strict
                  path="/dashboard/team/salesmen/edit/:id"
                  component={AddNewSalesman}
                />
                <Route
                  exact
                  strict
                  path={routes.gofrugalSync}
                  component={GofrugalSync}
                />
                <Route
                  exact
                  strict
                  path={routes.sapReport}
                  component={SapReport}
                />
                <Route exact strict path={routes.sapSync} component={SapSync} />
                <Route
                  exact
                  strict
                  path="/dashboard/target-setting/:name"
                  component={TargetSetting}
                ></Route>
                <Route
                  exact
                  strict
                  path={routes.targetSettingDetails}
                  component={TargetSettingDetails}
                />

                <Route
                  exact
                  strict
                  path={routes.poGeneration}
                  component={PoGeneration}
                />
                <Route
                  exact
                  strict
                  path={routes.createPO}
                  component={CreatePO}
                />

                <Route
                  exact
                  strict
                  path={routes.poDetails}
                  component={PoDetails}
                />

                <Route exact strict path={routes.cod} component={codList} />

                <Route
                  exact
                  strict
                  path={routes.creditExtension}
                  component={CreditExtension}
                />

                <Route
                  exact
                  strict
                  path={routes.settings}
                  component={Settings}
                />

                <Route
                  exact
                  strict
                  path={routes.stockAndMoq}
                  component={Stockandmoq}
                />

                {/*  <Route exact strict path={routes.pricing} component={Pricing} />*/}
                <Route exact strict path={routes.orders} component={Orders} />

                <Route
                  exact
                  strict
                  path={"/dashboard/teammapping/heads/Cluster"}
                  component={TeamMapping}
                />
                <Route
                  exact
                  strict
                  path={"/dashboard/teammapping/heads/Area"}
                  component={TeamMapping}
                />
                <Route
                  exact
                  strict
                  path={"/dashboard/teammapping/heads/Hub"}
                  component={TeamMapping}
                />
                <Route
                  exact
                  strict
                  path={"/dashboard/teammapping/heads/Cluster/viewemployee/:id"}
                  component={Viewemployee}
                />
                <Route
                  exact
                  strict
                  path={"/dashboard/teammapping/heads/Area/viewemployee/:id"}
                  component={Viewemployee}
                />
                <Route
                  exact
                  strict
                  path={"/dashboard/teammapping/heads/Hub/viewemployee/:id"}
                  component={Viewemployee}
                />
                <Route
                  exact
                  strict
                  path={"/dashboard/teammapping/heads/Cluster/addemployee/:id"}
                  component={AddEmployee}
                />
                <Route
                  exact
                  strict
                  path={"/dashboard/teammapping/heads/Area/addemployee/:id"}
                  component={AddEmployee}
                />
                <Route
                  exact
                  strict
                  path={"/dashboard/teammapping/heads/Hub/addemployee/:id"}
                  component={AddEmployee}
                />

                <Route
                  exact
                  strict
                  path={routes.placeorders}
                  component={PlaceOrder}
                />
                <Route
                  exact
                  strict
                  path={routes.salesDashboard}
                  component={SalesDashboard}
                />
                <Route
                  exact
                  strict
                  path={routes.orderAndBasicDetails}
                  component={OrderAndBasicDetails}
                />
                {/* <Route
                  exact
                  strict
                  path={routes.orderAndBasicDetails1}
                  component={OrderAndBasicDetails}
                /> */}
                <Route
                  exact
                  strict
                  path={routes.orderAndBasicDetails1}
                  component={OrderAndBasicDetails}
                />
                {/* <Route
                  exact
                  strict
                  path={routes.orderAndBasicDetails1}
                  component={OrderAndBasicDetails}
                /> */}
                <Route
                  exact
                  strict
                  path={routes.viewInvoice}
                  component={Invoice}
                />
                <Route exact strict path={routes.podOtp} component={Invoice} />
                <Route exact strict path={routes.orderOtp} component={Otp} />

                <Route
                  exact
                  strict
                  path={routes.productLists}
                  component={OrdersList}
                />
                <Route exact strict path={routes.payment} component={Payment} />
                <Route
                  exact
                  strict
                  path={routes.OrdersCustomersList}
                  component={OrdersCustomersList}
                />
                <Route
                  exact
                  strict
                  path={routes.inventoryManagementClosingStock}
                  component={ClosingStock}
                />

                <Route
                  exact
                  strict
                  path={routes.inventoryManagementCurrentStock}
                  component={CurrentStock}
                />
                <Route
                  exact
                  strict
                  path={routes.inventoryManagementStockAudit}
                  component={StockAudit}
                />
                <Route
                  exact
                  strict
                  path={routes.inventoryManagementStockMovement}
                  component={StockMovement}
                />
                <Route
                  exact
                  strict
                  path={routes.inventoryManagementReport}
                  component={Report}
                />

                <Route
                  exact
                  strict
                  path={"/dashboard/inventory-management/searchResults"}
                  component={searchResults}
                />
                <Route
                  exact
                  strict
                  path={"/dashboard/inventory-management/Results"}
                  component={results}
                />

                <Route
                  exact
                  strict
                  path={"/dashboard/reports/journeyReports"}
                  component={JourneyReports}
                />
                <Route
                  exact
                  strict
                  path={routes.salesOrderAll}
                  component={SalesOrderAll}
                />
                <Route
                  exact
                  strict
                  path={"/dashboard/sales-order/sales-order-all-so-detail/:id"}
                  component={SalesOrderDetail}
                />
                <Route
                  exact
                  strict
                  path={routes.salesOrderReturn}
                  component={SalesOrderReturn}
                />
                <Route
                  exact
                  strict
                  path={routes.salesOrderReturnDetail}
                  component={SalesOrderReturnDetail}
                />

                <Route
                  exact
                  strict
                  path={"/dashboard/purchaseOrders"}
                  component={PurchaseOrders}
                />
                <Route
                  exact
                  strict
                  path={"/dashboard/purchaseOrders/:id"}
                  component={PurchaseOrdersDetails}
                />
                <Route
                  exact
                  strict
                  path={"/dashboard/sales-order/sales-order-all-print/:id"}
                  component={InvoicePrint}
                />

                <Route
                  exact
                  strict
                  path={"/dashboard/purchaseOrders/history/:id"}
                  component={PurchaseOrdersHistoryDetails}
                />
                <Route
                  exact
                  strict
                  path={
                    "/dashboard/sales-order/sales-order-return-view-only/:id"
                  }
                  component={SalesOrderReturnView}
                />
                {/* <Route path="/*" component={Publish} /> */}
                <Route
                  exact
                  strict
                  path={routes.pricingUpload}
                  component={PricingUpload}
                />

                <Route
                  exact
                  strict
                  path="/dashboard/team/asms"
                  component={TeamAsms}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/team/salesmen/new"
                  component={AddNewSalesman}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/team/salesmen"
                  component={TeamSalesmen}
                />
              </>
            ) : localStorage.getItem("role") == "HR" ? (
              <Switch>
                <Route
                  exact
                  strict
                  path="/dashboard/team/asms/edit/:id"
                  component={AddNewAsm}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/team/salesmen/edit/:id"
                  component={AddNewSalesman}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/beatplan/published"
                  component={Publish}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/team/asms"
                  component={TeamAsms}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/team/salesmen/new"
                  component={AddNewSalesman}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/team/salesmen"
                  component={TeamSalesmen}
                />
                <Route
                  exact
                  strict
                  path="/dashboard/team/asms/new"
                  component={AddNewAsm}
                />
                <Route exact strict path="/*" component={AccessDenied} />
              </Switch>
            ) : localStorage.getItem("role") == "RBH" ? (
              <Switch>
                {/* <Route
                  exact
                  strict
                  path="/dashboard/team/asms/new"
                  component={AddNewAsm}
                /> */}
                <Route exact strict path="/*" component={SRAccessDenaid} />
              </Switch>
            ) : (
              <Route path="/*" component={Login} />
            )}
          </Switch>
        </div>
      ) : (
        <Route path="/*" component={Login} />
      )}
    </>
  );
}

export default DashboardMain;
