import generateActionTypes from "../utils/generateActionTypes";
import { getSalesReturnList } from "../services/salesReturnService";
import { handleErrorResponse } from "../utils/Request";
import message from "antd/lib/message";
export const actionTypes = generateActionTypes(
  "GET_SALES_RETURN_LIST",
  "LOADING",
  "STOP_LOADING"
);
export const getAllSalesReturnList = ({
  fromDate,
  toDate,
  searchKey,
  placeBy,
  checkOrderStatus,
  page,
  pageSize,
}) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.GET_SALES_RETURN_LIST,
    });
    try {
      const res = await getSalesReturnList(
        fromDate,
        toDate,
        searchKey,
        placeBy,
        checkOrderStatus,
        page,
        pageSize
      );
      const resData = res?.data;
      console.log(resData, "kk");

      if (resData.status == 200) {
        const result = resData;
        console.log(result?.message?.totalDocuments, "kjhgf");
        dispatch({
          type: actionTypes.GET_SALES_RETURN_LIST,
          data: {
            result,
            //searchKey,
            totalDocuments: result?.message?.totalDocuments,
          },
        });
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error(err?.response?.data?.message);
      }
    }
  };
};
