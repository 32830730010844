import React, { useState, useEffect } from "react";
// import bell from "../../../assets/images/bell-icon.svg";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useLocation, useRouteMatch, useParams } from "react-router-dom";
import * as routes from "../../utils/allroutes";
import UserProfile from "../nav-profile/userProfile";

function Header({ showNav, tabletView }) {
  const location = useLocation();
  const [showUserModal, setShowUserModal] = useState(false);
  const closeUserModal = () => {
    setShowUserModal(false);
  };
  const matchCustomerDetails = useRouteMatch(routes.customerDetails);
  const matchCustomerCollectionDetails = useRouteMatch(
    routes.customerCollectionDetails
  );
  const matchDaysPlanId = useRouteMatch(routes.daysPlanWithId);
  const matchBeatPlanDetails = useRouteMatch(routes.viewBeatPlanDetails);
  const matchTargetSettingDetails = useRouteMatch(routes.targetSettingDetails);

  const getHeaderTitle = () => {
    if (location.pathname === routes.profile) {
      return "Profile Details";
    } else if (location.pathname === routes.customers) {
      return "Customers";
    } else if (matchCustomerDetails?.isExact) {
      return "Customer Details";
    } else if (location.pathname === routes.customerOrderDetails) {
      return "Customer Order Details";
    }
    //team
    else if (location.pathname === routes.ASM) {
      return "ASMs";
    } else if (location.pathname === routes.createASM) {
      return "Add new ASM";
    }
    //cash verfications
    else if (location.pathname === routes.salesmen) {
      return "Salesman";
    } else if (location.pathname === routes.createSalesman) {
      return "Add New Salesman";
    } else if (location.pathname === routes.reports) {
      return "Salesmen Reports";
    } else if (location.pathname === routes.downloadReports) {
      return "Download Report";
    } else if (location.pathname === routes.daysPlan) {
      return "Day's Plan";
    } else if (matchDaysPlanId?.isExact) {
      return `Day's Plan#`;
    } else if (location.pathname === routes.viewBeatPlan) {
      return "View Beat Plans";
    } else if (matchBeatPlanDetails?.isExact) {
      return "View Beat Plan ";
    } else if (location.pathname === routes.createNew) {
      return "Create New Beat Plan";
    } else if (matchCustomerCollectionDetails?.isExact) {
      return "Customer Collection Details";
    } else if (location.pathname === routes.gofrugalSync) {
      return "Gofrugal Sync";
    } else if (location.pathname === routes.targetSettings) {
      return "Target Setting";
    } else if (matchTargetSettingDetails?.isExact) {
      return "Target Setting Details";
    } else {
      return "";
    }
  };
  return (
    <div
      className={`primary-header ${
        tabletView
          ? ""
          : showNav
          ? "primary-header-shrink"
          : "primary-header-full"
      }`}
    >
      <div className="primary-header-content">
        <p>{getHeaderTitle()}</p>
        <div>
          {/* <img className="mr-4" src={bell} alt="bell-icon" /> */}
          {/* <Avatar
            size="large"
            icon={<UserOutlined />}
            onClick={() => setShowUserModal(true)}
          /> */}
          <UserProfile />
        </div>
        {/* {showUserModal && (
          <UserDetails
            showUserInfoModal={showUserModal}
            userInfoModal={closeUserModal}
          />
        )} */}
      </div>
    </div>
  );
}

export default Header;
