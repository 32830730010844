import axios from "axios";
import { api as APIConfig } from "../../src/appConfig";
import { getAuthToken } from "../../src/utils/Auth";
// export const getSalesReturnList = async (searchKey, placeBy) => {
//   let url = `api/v1/postDelivery/crm-approved?fromDate=2023/04/04&toDate=2023/04/05`;
//   let request = await axios.get(APIConfig.base_url + url, {
//     headers: {
//       "x-access-token": getAuthToken(),
//       portaltype: "distributor",
//     },
//   });
//   return request;
// };
const formateQueryString = (inArray, paramName) => {
  let queryString = "";
  inArray.forEach((item, index) => {
    queryString = queryString.concat(`${paramName}[${index}]=${item}&`);
  });
  return queryString;
};

export const getSalesReturnList = async (
  fromDate,
  toDate,
  search,
  source,
  status,
  page,
  pageSize
) => {
  console.log(status, "checkOrderStatus");

  let url =
    APIConfig.base_url +
    `api/v1/postDelivery/crm-approved?fromDate=${fromDate}&toDate=${toDate}&page=${page}&recordPerPage=${pageSize}&`;
  if (search) {
    url = `${url}search=${search}&`;
  }

  // if (source?.length == 1) {
  //   let checkedplacedLiistString = formateQueryString(source, "source");
  //   url = url.concat(checkedplacedLiistString);
  // }
  if (source?.length == 1) {
    url = url + `&source=${source[0]}&`;
  }
  console.log(url, "bbb");
  if (status?.length == 1) {
    if (status == 3) {
      url = url + `&status=3&`;
    } else {
      url = url + `&itemStatus=2&`;
    }
  }

  // if (status?.length > 0) {
  //   let checkedOrderStatusString = formateQueryString(status, "status");
  //   url = url.concat(checkedOrderStatusString);
  // }

  let request = await axios.get(url.slice(0, -1), {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};

export const rbhApproveSubmit = async (returnID, items) => {
  let request = await axios.patch(
    APIConfig.base_url + `api/v1/postDelivery/crm-approved/${returnID}`,
    {
      items,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
