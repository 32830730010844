import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import CardLeftContent from "../CardLeftContent";
import { Switch, Typography, Button, Select, Spin, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getCancellationOrderDetails } from "../../../action/manageOrder";
import { hours, minutes } from "./_deliveryOptions";
import orderCancellation1 from "../../../images/orderCancellation.svg";
import { actionTypes } from "../../../action/manageOrder";
import { updateCancellationOrderDetails } from "../../../services/settings/manageOrder";
function OrderCancellation() {
  const [editopen, setEditOpen] = useState(false);
  const [cancelToggelStatus, setCancelToggelStatus] = useState(false);
  const cancelToggelStatus1 = cancelToggelStatus ? 1 : 0;
  const [toggleSwitch, setToggleSwitch] = useState(cancelToggelStatus1);
  const [cancelTimeHours, setCancelTimeHours] = useState();
  const [cancelTimeMins, setCancelTimeMins] = useState();

  const onHandleEditOpen = () => {
    console.log("hello");
    setEditOpen(true);
  };
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch();
  const onClickCancelHandler = () => {
    setEditOpen(false);
  };
  const onSwitchHandler = () => {
    setToggleSwitch(true);
    setEditOpen(true);
  };
  const onChangeCancelTimeHours = (val) => {
    setCancelTimeHours(val);
  };
  const onChangeCancelTimeMins = (val) => {
    setCancelTimeMins(val);
  };
  const onChangeCancelToggelStatus = (val) => {
    setCancelToggelStatus(val);
    setToggleSwitch(!cancelToggelStatus);
    // console.log(cancelToggelStatus);
    // setEditOpen(!editopen);
  };
  const updateCancelletionOrderDetails = async () => {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.UPDATE_ORDER_CANCELLATION,
      });
      let toggleStatus;
      if (cancelTimeMins == 0 && cancelTimeHours == 0) {
        toggleStatus = 0;
      } else {
        toggleStatus = 1;
      }
      const response = await updateCancellationOrderDetails(
        parseInt(cancelTimeHours),
        parseInt(cancelTimeMins),
        toggleStatus
      );

      if (response.status == 200) {
        const { data } = response;
        const message = data.data;
        notification.success({
          message: message,
        });

        dispatch({
          type: actionTypes.STOP_LOADING,
          load: actionTypes.UPDATE_ORDER_CANCELLATION,
        });
        dispatch(getCancellationOrderDetails());
        setEditOpen(false);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: actionTypes.STOP_LOADING,
        load: actionTypes.UPDATE_ORDER_CANCELLATION,
      });
    }
  };

  const getButtonDisableState = () => !cancelTimeHours || !cancelTimeMins;

  const { orderCancellation, loading } = useSelector(
    (state) => state.manageOrder
  );
  const { Option } = Select;
  return (
    <div className="rounded-lg shadow-sm py-4 px-4 bg-white mt-4">
      <div className=" d-flex justify-content-between">
        <CardLeftContent
          icon={
            <img
              src={orderCancellation1}
              style={{ width: "100%", height: "100%" }}
            />
          }
          title="Order Cancellation Window"
          subTitle="The time window within which orders can be cancelled."
        />

        <div className="d-flex flex-row align-items-center">
          <div>
            {loading.includes(actionTypes.GET_ORDER_CANCELLATION) ? (
              <Spin />
            ) : orderCancellation[0]?.settings?.cancelToggelStatus === 0 ? (
              <Switch disabled={disabled} />
            ) : (
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "17px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <span style={{ display: "flex" }}>
                    <span>
                      {" "}
                      {orderCancellation &&
                        orderCancellation[0]?.settings?.cancelTimeHours}{" "}
                    </span>
                    <span style={{ marginLeft: "4px" }}>
                      {orderCancellation[0]?.settings?.cancelTimeHours != 1 ? (
                        <Typography>hours</Typography>
                      ) : (
                        <Typography>hour</Typography>
                      )}
                    </span>
                  </span>
                  <span style={{ marginLeft: "10px" }}>
                    {" "}
                    {orderCancellation &&
                      orderCancellation[0]?.settings?.cancelTimeMinutes}{" "}
                    minutes
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="p-3 ml-5 d-flex align-items-center">
            <EditOutlined
              style={{ fontSize: 24, color: "#5078F2" }}
              onClick={() => onHandleEditOpen()}
            />
          </div>
        </div>
      </div>
      {editopen ? (
        <div className="mt-3 mb-2" style={{ marginLeft: 123 }}>
          <div className="m-1" style={{ display: "flex" }}>
            <span>
              <Switch
                // onClick={() => onSwitchHandler()}
                checked={cancelToggelStatus}
                value={cancelToggelStatus1}
                onChange={onChangeCancelToggelStatus}
              />
            </span>
            <span>
              <Typography style={{ color: "#3A4960" }} className="ml-4">
                Enabling this you will be able to set cancelation window.
              </Typography>
            </span>
          </div>
          {toggleSwitch ? (
            <div>
              <div className="d-flex w-100 flex-row">
                <div className="w-25 pb-4 pt-2">
                  <Typography style={{ fontSize: 16 }}>Hrs:</Typography>

                  <Select
                    className="datePicker"
                    placeholder="00 Hours"
                    value={cancelTimeHours}
                    onChange={onChangeCancelTimeHours}
                  >
                    {hours.map((opt) => (
                      <Option value={opt.value} key={opt.value}>
                        {/*  disabled={parseInt(opt.value) > parseInt(lastDelDate) - 1}*/}
                        {opt.key} {opt.key == 1 ? " Hour" : " Hours"}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="w-25 pb-4 pt-2 ml-4">
                  <Typography style={{ fontSize: 16 }}>Mns:</Typography>

                  <Select
                    className="datePicker"
                    placeholder="00 minutes"
                    value={cancelTimeMins}
                    onChange={onChangeCancelTimeMins}
                  >
                    {minutes.map((opt) => (
                      <Option value={opt.value} key={opt.value}>
                        {/*  disabled={parseInt(opt.value) > parseInt(lastDelDate) - 1}*/}
                        {opt.key} minutes
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          ) : null}

          <div
            className="mt-4 d-flex justify-content-between"
            style={{ width: 200 }}
          >
            <Button
              size="large"
              value="default"
              className="cancel_button"
              onClick={onClickCancelHandler}
            >
              Cancel
            </Button>
            <Button
              size="large"
              className="rounded-lg save_button"
              style={{ backgroundColor: "#5078F2", color: "#fff" }}
              onClick={updateCancelletionOrderDetails}
              disabled={getButtonDisableState()}
              loading={loading.includes(actionTypes.UPDATE_ORDER_CANCELLATION)}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default OrderCancellation;
