import React, { useState } from "react";
import accessdenaied from "../../images/accessdenied.svg";
import { Typography, Form, Input, Button, Modal } from "antd";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useParams, useHistory } from "react-router-dom";
import { set } from "lodash";

import { logoutFunction } from "../../utils/Auth";

function AccessDenied() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const id = params?.id;
  console.log(id, "jhj");
  const reLogin = () => {
    // setBalanceConfirmationToken("");
    // setAuthPhoneNo("");
    // const custId = getAuthTokenCustID();
    // dispatch({
    //   type: actionTypes.SETTOKEN,
    //   token: false,
    // });
    //  history.push(`/customerBalance/${custId}`);
  };
  return (
    <div
      className="mt-3 w-100 px-4"
      style={{
        textAlign: "center",
      }}
    >
      <div className="m-5">
        <Link to={`/dashboard/team/asms`}>
          <img src={accessdenaied} className="mt-5" />
        </Link>
      </div>
    </div>
  );
}

export default AccessDenied;
