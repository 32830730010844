import { Typography, Button } from "antd";
import React from "react";
import nodata from "../../images/nodata.svg";
import "./salesdashboard.css";
import { Link } from "react-router-dom";
function TechnicalIssue({ getSalesData }) {
  return (
    <div className="mt-3 w-100 px-4">
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "75px",
        }}
      >
        <img src={nodata} alt="Technical issue" />
        <Typography
          style={{ fontSize: "28px", lineHeight: "34px", fontWeight: 600 }}
        >
          Something went wrong !
        </Typography>
        <Typography
          style={{ fontSize: "24px", fontWeight: 400, lineHeight: "29px" }}
        >
          Please contact the administrator for
          <br />{" "}
          <span style={{ marginLeft: "85px" }}>technical assistance.</span>
        </Typography>
        {/* <Link to="/dashboard/sales-order"> */}
        <Button
          className="button_refresh"
          type="primary"
          onClick={getSalesData}
        >
          Refresh
        </Button>
        {/* </Link> */}
      </div>
    </div>
  );
}

export default TechnicalIssue;
