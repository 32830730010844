import generateActionTypes from "../utils/generateActionTypes";
export const actionTypes = generateActionTypes(
  "GET_ALL_CUSTOMERS_DATA",
  "SYNC_WITH_GO_FRUGAL",
  "GET_ALL_CUSTOMERS_LEDGER_DATA",
  "GET_INVOICE_DATA",
  "GET_PAYMENT_DATA",
  "GET_CREDIT_DATA",
  "GET_DEBIT_DATA",
  "GET_LEDGER_DETAILS"
);
// action functions

// get all customers data
export function getAllCustomers(data){
 
 return (dispatch)=>{
    return dispatch({
      type: actionTypes.GET_ALL_CUSTOMERS_DATA, data
    })
 }
}


// sync with goFrugal
export function syncWithGoFrugal(data){
  return (dispatch)=>{
    console.log("action- syncWithGoFrugal");
    return dispatch({type: actionTypes.SYNC_WITH_GO_FRUGAL, data})
  }
}

// get all customers ledger details data
export function getAllCustomersLedgerDetails(data){
  return (dispatch)=>{
     return dispatch({
       type: actionTypes.GET_ALL_CUSTOMERS_LEDGER_DATA, data
     })
  }
 }

 //get invoice table data in customer details page

 export function getInvoiceData(data){
  return (dispatch)=>{
     return dispatch({
       type: actionTypes.GET_INVOICE_DATA, data
     })
  }
 }
 
  //get payment table data in customer details page

  export function getPaymentData(data){
    return (dispatch)=>{
       return dispatch({
         type: actionTypes.GET_PAYMENT_DATA, data
       })
    }
   }

     //get credit table data in customer details page

  export function getCreditData(data){
    return (dispatch)=>{
       return dispatch({
         type: actionTypes.GET_CREDIT_DATA, data
       })
    }
   }

   //get debit table data in customer details page

  export function getDebitData(data){
    return (dispatch)=>{
       return dispatch({
         type: actionTypes.GET_DEBIT_DATA, data
       })
    }
   }

   //get balance ledger details list
   export function getLedgerDetails(data){
    return (dispatch)=>{
       return dispatch({
         type: actionTypes.GET_LEDGER_DETAILS, data
       })
    }
   }