import { DownOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Menu,
  Row,
  Col,
  message,
  Typography,
  Table,
  Pagination,
  Space,
} from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { OrdersCustomersList } from "../../../utils/allroutes";
import PrimaryHeader from "../../header/PrimaryHeader";
import SearchBar from "../../search-bar/searchBar";
import { orderAndBasicDetails } from "../../../utils/allroutes";
import { useHistory, useParams } from "react-router";
import "./orders.scss";
import useSessionStorage from "../../../utils/useSessionStorage";
import { getOrdersList } from "../../../action/ordersHistoryaction";
import {
  saveSelectedCustomer,
  removeSearchedCustomer,
} from "../../../action/SearchOrdersListaction";
import {
  removeCreditDetails,
  resetState,
} from "../../../action/itemListactions";
//import { resetOrderState } from "../../../action/IndividualOrderDetails";
import { resetOrderState } from "../../../action/IndividualOrderDetails";
import useDebounce from "../../../utils/useDebounce";

let filtermapping = {
  5: "placedBy",
  6: "paymentType",
};
const { RangePicker } = DatePicker;
function Orders() {
  const [Products, setProducts] = useState([]);
  const [startDate, setStartDate] = useSessionStorage("_sd", moment());
  const [endDate, setEndDate] = useSessionStorage("_ed", moment());
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const history = useHistory();

  const { ordersList, totalDocuments, loading } = useSelector(
    (state) => state.ordersHistoryReducer
  );
  const [searchKey, setSearchvalue] = useState("");
  const debouncedSearchTerm = useDebounce(searchKey, 500);
  console.log(ordersList[0]?.allOrders, "orderSearch");

  // const orders = useSelector((state) => state.OrdersReducer.orders);
  //const orders = useSelector((state) => state.OrdersReducer?.orders);
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getOrdersList());

    localStorage.setItem("customer", JSON.stringify({}));
    dispatch(saveSelectedCustomer({}));
    dispatch(removeCreditDetails({}));
    dispatch(resetState());
    dispatch(resetOrderState());
    dispatch(removeSearchedCustomer());
    // dispatch
  }, []);

  const [current, setCurrent] = useState("1");
  const [values, setValues] = useState(true);

  // const plainOptions = [
  //   "Sales Executive",
  //   "SAP",
  //   "Call Center",
  //   "Distributor",
  //   "Customer",
  // ];
  // const sostatusvalues = Ind
  const plainOptions = [
    { label: "Sales Executive", value: "SalesManApp" },
    { label: "SAP", value: "SAP" },
    // { label: "Call Center", value: "Callcenter" },
    { label: "Distributor", value: "Distributor" },
    { label: "Customer", value: "Customer" },
  ];
  const customerGroupoptions = ["GT", "FSMT", "FS Retail", "FS KAM"];
  const [filteredInfo, setFilteredInfo] = useState({});
  const pamentOption = [
    { label: "COD", value: "cod" },
    { label: "Credit", value: "credit" },
    { label: "WayCred", value: "waycoolcredit" },
  ];
  const OTPVerification = [
    { label: "Verified", value: "true" },
    { label: "Not verified", value: "false" },
  ];
  const orderStatus = ["Pending", "Fullfilled"];
  // const PodStatus = ["PODAvailable", "PODNotAvailable"];
  const PodStatus = [
    {
      label: "POD Available",
      value: "true",
    },
    {
      label: "POD Not Available",
      value: "false",
    },
  ];

  const [checkedCustomerGropList, setcheckedCustomerGropList] = useState();

  const [otpVerification, setOtpVerification] = useState();
  const [podStatus, setPodStatus] = useState();

  const filtersType = sessionStorage.getItem("SelectedFilters")
    ? JSON.parse(sessionStorage.getItem("SelectedFilters"))
    : "";
  const [selectedplacedBy, setSelectedPlacedBy] = useState(
    filtersType.placementby
  );
  const [checkedList, setCheckedList] = useState(filtersType.placementby);
  const [checkPaymentOption, setcheckPaymentOption] = useState(
    filtersType.paymentType
  );
  const [selectedPayment, setSelectedPayment] = useState(
    filtersType.paymentType
  );
  const [selectedStatus, setSelectedStatus] = useState(filtersType.orderStatus);
  const [checkOrderStatus, setCheckOrderStatus] = useState();
  const [soStatusList, setsoStatusList] = useState(filtersType.soStatusList);
  const [invStatusList, setInvStatusList] = useState(
    filtersType.InvoiceStatusList
  );
  const handleClick = (e) => {
    setCurrent(e.key);
  };
  const handleResetCustomerGroup = () => {
    // setcheckedCustomerGropList([]);
    // setCheckedList([]);
    // setcheckPaymentOption([]);
    // setCheckOrderStatus([]);
    setPodStatus([]);
    setOtpVerification([]);
    setsoStatusList([]);
    setStartDate(moment());
    setEndDate(moment());
    sessionStorage.setItem("SelectedFilters", []);
    setcheckPaymentOption([]);
    setCheckedList([]);
    setInvStatusList([]);
    setSelectedPlacedBy([]);
    setSelectedPayment([]);
    setSelectedStatus([]);

    // handleChangefilter();
    message.info("Customer group has been reset");
  };
  function Welcome({ bgColor }) {
    return (
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "50px",
          backgroundColor: bgColor,
          marginTop: "4px",
          display: "inline-block",
        }}
      ></div>
    );
  }
  function Welcome1({ bgColor }) {
    return (
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "50px",
          backgroundColor: bgColor,
          marginTop: "4px",
          display: "inline-block",
        }}
      ></div>
    );
  }
  // const handleSearch = (e) => {
  //   // console.log(e);
  //   let value = e.target.value;

  //   if (searchKey === "" && value === " ") return;
  //   // console.log("i am emptyy");
  //   setPage(1);

  //   setSearchvalue(value);
  //   // console.log(e.target.value, "gggggggggggg");
  // };

  const handleSearch = (value) => {
    // let value = e.target.value;
    if (searchKey === "" && value === " ") return;
    setPage(1);
    setSearchvalue(value);
  };

  const handleChange = (dates) => {
    const [start, end] = dates?.length ? dates : ["", ""];
    setPage(1);
    if (start) {
      setStartDate(start);
      setEndDate("");
    }
    if (end) {
      setEndDate(end);
    }
  };
  const customeDisableDates = (current) => {
    let date = new Date();
    return current && current > moment(date, "DD-MM-YYYY");
  };
  const onPodChangeStatus = (list) => {
    setPage(1);
    setPodStatus(list);
  };

  // useEffect(() => {
  //   sessionStorage.setItem("SelectedFilters", []);
  // }, []);

  useEffect(() => {
    if (startDate && endDate)
      dispatch(
        getOrdersList({
          startDate: startDate.format("yyyy-MM-DD"),
          endDate: endDate.format("yyyy-MM-DD"),
          searchKey: debouncedSearchTerm,
          //checkedCustomerGropList,
          checkOrderStatus,
          checkPaymentOption,
          checkedList,
          podStatus,
          otpVerification,
          soStatusList,
          invStatusList,
          page,
          pageSize,
        })
      );
  }, [
    startDate,
    endDate,
    debouncedSearchTerm,
    //  checkedCustomerGropList,
    checkOrderStatus,
    checkPaymentOption,
    checkedList,
    podStatus,
    otpVerification,
    soStatusList,
    invStatusList,
    page,
    pageSize,
  ]);

  const onChange = (list) => {
    setPage(1);
    setCheckedList(list);
  };
  // const onChangeCustomerGroup = (list) => {
  //   setPage(1);
  //   setcheckedCustomerGropList(list);
  // };
  const onOtpVerificationChange = (list) => {
    setPage(1);
    setOtpVerification(list);
  };
  const onPaymentChangeOption = (list) => {
    setPage(1);
    setcheckPaymentOption(list);
  };

  const onChangeOrderStatus = (list) => {
    setPage(1);
    setCheckOrderStatus(list);
  };

  const orderStatusIcon = (record) => {
    if (record?.dpColor) {
      return <Welcome bgColor={record?.dpColor} />;
    } else {
      let soStatus = record?.soStatus;

      if (soStatus == undefined || soStatus == null || soStatus == "null") {
        return "";
      }
      if (soStatus === 1 || soStatus === 3) {
        return <Welcome bgColor="#219653" />;
      } else if (soStatus === 2 || soStatus === 0 || soStatus === 7) {
        return <Welcome bgColor="#F2994A" />;
      } else if (soStatus === 5 || soStatus === 4) {
        return <Welcome bgColor="#E13440" />;
      } else {
        return null;
      }
    }
  };
  const getOrderStatus = (record) => {
    let Status = record?.invoiceStatusValue;
    if (record?.invoiceStatusValue == "") {
      Status = record?.soStatusValue;
    }
    return Status;
  };
  // const getOrderStatus = (orderStatus) =>
  //  {
  //   if (
  //     orderStatus == undefined ||
  //     orderStatus == null ||
  //     orderStatus == "null"
  //   ) {
  //     return "";
  //   } else if (!isNaN(orderStatus)) {
  //     orderStatus = orderStatus.toString();
  //   }
  //   if (
  //     orderStatus.toUpperCase() === "ORDER DELIVERED" ||
  //     orderStatus.toUpperCase() === "ORDER PLACED" ||
  //     orderStatus.toUpperCase() === "ORDER PACKED" ||
  //     orderStatus.toUpperCase() === "PACKED" ||
  //     orderStatus.toUpperCase() === "OUT FOR DELIVERY" ||
  //     orderStatus.toUpperCase() === "DELIVERED"
  //   ) {
  //     return <Welcome bgColor="#219653" />;
  //   } else if (
  //     orderStatus.toUpperCase() === "ORDER PENDING" ||
  //     orderStatus.toUpperCase() === "DELIVERED PARTIALLY" ||
  //     orderStatus.toUpperCase() === "ORDER PROCESSING" ||
  //     orderStatus.toUpperCase() === "DELIVERED (SALES RETURN)" ||
  //     orderStatus.toUpperCase() === "ORDER ADJUSTED & PACKED" ||
  //     orderStatus.toUpperCase() === "ADJUSTED & PACKED" ||
  //     orderStatus.toUpperCase() === "WE ARE NOT ABLE TO FIND YOU" ||
  //     orderStatus.toUpperCase() === "DELIVERY ATTEMPTED" ||
  //     orderStatus.toUpperCase() === "SHOP CLOSED" ||
  //     orderStatus.toUpperCase() === "DELIVERY NOT ACCEPTED"
  //   ) {
  //     return <Welcome bgColor="#F2994A" />;
  //   } else if (
  //     orderStatus.toUpperCase() === "ORDER FAILED" ||
  //     orderStatus.toUpperCase() === "SALES RETURN" ||
  //     orderStatus.toUpperCase() === "ORDER CANCELLED"
  //   ) {
  //     return <Welcome bgColor="#E13440" />;
  //   } else {
  //     return null;
  //   }
  // };

  // const handleSearch = (e) => {
  //   setSearchvalue(e.target.value);
  // console.log(e.target.value, "hh");
  // if (val === "") {
  //   console.log("hii");
  //   // notification.success({
  //   //   message: "please enter customer name or customer id",
  //   // });
  // } else if (val !== "") {
  //   dispatch(getOrdersList(val));
  //   // setTableDisplay(true);
  //   // dispatch(saveSearchedValue(val));
  // }
  // };

  // const onChangeSearchValueHandle = (value) => {
  //   if (searchKey === "" && value === " ") return;
  //   set_PageNumber(1);
  //   setSearchvalue(value);
  // };
  const handleChangefilter = (pagination, filters) => {
    console.log(filters);
    if (filters == undefined || filters == null) {
      return;
    }
    let filterkeys = Object.keys(filters);
    let paymentType = [];
    let placementby = [];
    let orderStatus = [];
    // filterkeys.forEach((key) =>{

    // })
    filterkeys.forEach((key) => {
      if (key == "6" && filters[6]) {
        paymentType = [...filters[6]];
      }
      if (key == "5" && filters[5]) {
        placementby = [...filters[5]];
      }
      if (key == "7" && filters[7]) {
        orderStatus = [...filters[7]];
      }
    });
    setSelectedPlacedBy(placementby);
    setSelectedPayment(paymentType);
    setSelectedStatus(orderStatus);
    setcheckPaymentOption(paymentType);
    setCheckedList(placementby);

    let soStatusList = [],
      InvoiceStatusList = [];
    orderStatus.forEach((item) => {
      switch (item) {
        case "0":
          soStatusList.push("0");
          break;
        case "1":
          soStatusList.push("1");
          break;
        case "2":
          InvoiceStatusList.push("2");
          break;
        case "3":
          InvoiceStatusList.push("3");
          break;
        case "4":
          soStatusList.push("4");
          break;
        case "5":
          soStatusList.push("5");
          break;
        case "6":
          soStatusList.push("6");
          break;
        case "7":
          InvoiceStatusList.push("7");
          break;
        case "8":
          InvoiceStatusList.push("8");
          InvoiceStatusList.push("6");
          break;
        case "9":
          InvoiceStatusList.push("9");
          InvoiceStatusList.push("5");
          break;
        case "10":
          InvoiceStatusList.push("4");
          break;
        case "11":
          InvoiceStatusList.push("1");
          break;
      }
    });

    setsoStatusList(soStatusList);
    setInvStatusList(InvoiceStatusList);
    sessionStorage.setItem(
      "SelectedFilters",
      JSON.stringify({
        paymentType: paymentType,
        placementby: placementby,
        soStatusList: soStatusList,
        InvoiceStatusList: InvoiceStatusList,
        orderStatus: orderStatus,
      })
    );

    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
  };
  console.log(ordersList[0]?.allOrders, "gg");
  const allOrdersListData = ordersList[0]?.allOrders || [];
  const status = allOrdersListData.map((item) => item.soStatus);
  let allStatus = [];
  status.forEach((c) => {
    if (!allStatus.includes(c) && c) {
      allStatus.push(c);
    }
  });

  console.log(allStatus);
  let Status = allOrdersListData.map((item) => ({
    value: item.soStatus,
    text: item.soStatusValue,
  }));

  console.log(allOrdersListData, "hhh");
  const columns = [
    {
      key: "1",

      title: <div className="title_table">Order Date</div>,
      dataIndex: "dateOfOrderPlacing",

      render: (dateOfOrderPlacing) => {
        return (
          <Typography className="invoiceno">
            {moment(dateOfOrderPlacing)
              ?.utcOffset(0, false)
              .format("DD/MM/YYYY")}
          </Typography>
        );
      },
    },
    {
      key: "2",

      title: <div className="title_table">Invoice No</div>,
      render: (_, record) =>
        record.invoiceNo ? (
          <Typography className="invoiceno">{record.invoiceNo}</Typography>
        ) : (
          <Typography style={{ textAlign: "center", marginRight: "30px" }}>
            -
          </Typography>
        ),
    },
    {
      key: "3",
      title: <div className="title_table">SO No</div>,
      render: (_, record) =>
        record.salesOrderId ? (
          <Typography className="invoiceno">{record.salesOrderId}</Typography>
        ) : (
          <Typography style={{ textAlign: "center", marginRight: "30px" }}>
            -
          </Typography>
        ),
    },
    {
      key: "4",

      title: <div className="title_table">Customer</div>,
      render: (_, record) => (
        <div className="d-flex">
          <div className="">
            <Typography
              style={{
                marginBottom: "2px",
                fontWeight: 500,
                fontSize: "14px",
                color: "#3A4960",
              }}
            >
              {record.customerName}
            </Typography>
            <Typography
              style={{
                marginTop: "3px",
                fontWeight: 400,
                fontSize: "13px",
                color: "#7A7A7A",
              }}
            >
              ID-{record.customerId}
            </Typography>
          </div>
        </div>
      ),
    },

    {
      key: "5",
      title: <div className="title_table">Placed By</div>,

      filters: [
        {
          text: "SAP",
          value: "SAP",
        },
        {
          text: "Sales Executive",
          value: "SalesManApp",
        },
        // {
        //   text: "Call Center",
        //   value: "Callcenter",
        // },
        {
          text: "Distributor",
          value: "Distributor",
        },
        {
          text: "Customer",
          value: "Customer",
        },
      ],
      // render: (portal) => {
      //   return <p>{portal}</p>;
      // },
      dataIndex: "portal",
      // onFilter: (value, record) => {
      //   return record.portal === value;
      // },
      defaultFilteredValue: filtersType.placementby,
      filteredValue: selectedplacedBy,

      filterIcon: (filtered) => <DownOutlined />,
    },
    {
      key: "6",
      title: <div className="title_table">Payment Type</div>,

      filters: [
        {
          text: "COD",
          value: "cod",
        },
        {
          text: "Credit",
          value: "credit",
        },
        {
          text: "WayCred",
          value: "waycoolcredit",
        },
      ],
      defaultFilteredValue: filtersType.paymentType,
      filteredValue: selectedPayment,
      filterIcon: (filtered) => <DownOutlined />,
      dataIndex: "paymentMode",
    },

    {
      key: "7",
      title: <div className="title_table">Order Status</div>,
      filters: [
        { text: "Order Processing", value: "0" },
        { text: "Order Placed", value: "1" },
        { text: "Order cancelled", value: "4" },
        { text: "Order Failed", value: "5" },
        { text: "Order Pending", value: "6" },
        { text: "Order Packed", value: "11" },
        { text: "Adjusted & Packed", value: "2" },
        { text: "Out For Delivery", value: "3" },
        { text: "Delivered", value: "10" },
        { text: "Denied Delivery", value: "7" },
        { text: "Delivery Attempted", value: "8" },
        { text: "Sales Return", value: "9" },
      ],
      defaultFilteredValue: filtersType.orderStatus,
      filteredValue: selectedStatus,
      filterIcon: (filtered) => <DownOutlined />,

      dataIndex: "soStatusValue",
      render: (_, record) => (
        <div style={{ display: "-webkit-inline-box" }}>
          {orderStatusIcon(record)}
          <Typography style={{ paddingLeft: "10px" }}>
            {/* {record?.soStatusValue?.toString().toUpperCase() ===
            "DELIVERED PARTIALLY"
              ? "Delivered (Sales Return)"
              : record?.soStatusValue} */}
            {getOrderStatus(record)}
          </Typography>
        </div>
      ),
    },
  ];
  return (
    <div className="mt-3 w-100 px-4">
      <SearchOutlined style={{ fontSize: 32, color: "#3A4960" }} />
      <input
        placeholder="Search by Customer ID/ Name, SO No."
        style={{
          outline: "none",
          paddingLeft: 20,
          width: "70%",
          lineHeight: 3,
          border: "none",
        }}
        // onChange={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        value={searchKey}
      />

      <Divider />
      <Row
        class="orders_date"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginLeft: "15px",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Col
          className=""
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            className="mr-5"
            style={{ fontWeight: 600, fontSize: "18px", color: "#3A4960" }}
          >
            Orders
          </Typography>
          <div className="rangePicker">
            <RangePicker
              bordered={false}
              size="small"
              onCalendarChange={handleChange}
              disabledDate={customeDisableDates}
              defaultValue={[startDate, endDate]}
              value={[startDate, endDate]}
            />
          </div>
        </Col>
        <Col>
          <Link to={OrdersCustomersList}>
            <Button
              style={{
                backgroundColor: "#5078F2",
                color: "white",
                height: "42px",
                borderRadius: "4px",
              }}
            >
              Place New Order
            </Button>
          </Link>
        </Col>
      </Row>

      <Row
        className="mt-3"
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Col className="ml-1">
          <Menu
            onClick={handleClick}
            style={{ width: 180 }}
            className="rounded"
            selectedKeys={[current]}
            mode="vertical"
            expandIcon={<DownOutlined />}
          >
            <SubMenu
              key="sub3"
              title={`OTP Verification ${
                otpVerification?.length > 0 ? otpVerification?.length : ""
              }`}
            >
              <Checkbox.Group
                className="p-2 d-flex flex-column justify-content-between"
                options={OTPVerification}
                value={otpVerification}
                onChange={onOtpVerificationChange}
              />
            </SubMenu>
          </Menu>
        </Col>
        <Col className="ml-5">
          <Menu
            onClick={handleClick}
            style={{ width: 180 }}
            className="rounded"
            selectedKeys={[current]}
            mode="vertical"
            expandIcon={<DownOutlined />}
          >
            <SubMenu
              key="sub2"
              title={`POD Status ${
                podStatus?.length > 0 ? podStatus?.length : ""
              }`}
            >
              <Checkbox.Group
                className="p-2 d-flex flex-column justify-content-between"
                options={PodStatus}
                value={podStatus}
                onChange={onPodChangeStatus}
              />
            </SubMenu>
          </Menu>
        </Col>

        <Col className="d-flex align-items-center">
          <Button
            className="ml-3"
            type="link"
            style={{ color: "#5078F2", fontSize: "14px", fontWeight: 600 }}
            onClick={handleResetCustomerGroup}
          >
            RESET FILTER
          </Button>
        </Col>
      </Row>
      <div style={{ marginTop: "30px", marginBottom: 30 }}>
        <Table
          // <Link to={"/dashboard/orders/placeorder/" + record.goFrugalId}>
          // </Link>
          onRow={({ soId, customerId, invoiceNo }) => ({
            onClick: () => {
              invoiceNo
                ? history.push(
                    `/dashboard/orders/order-details/${customerId}/${soId}/${invoiceNo}`
                  )
                : history.push(
                    `/dashboard/orders/order-details/${customerId}/${soId}`
                  );
            },
          })}
          columns={columns}
          loading={loading}
          onChange={handleChangefilter}
          dataSource={allOrdersListData}
          pagination={false}
        />
        <Space className="my-5 d-flex justify-content-center">
          <Pagination
            hideOnSinglePage={true}
            showSizeChanger={false}
            current={page}
            total={totalDocuments}
            pageSize={pageSize}
            onChange={(pageNo, pageSize) => {
              setPage(pageNo);
              // setPageSize(pageSize);
            }}
            size="default"
          />
        </Space>
      </div>
    </div>
  );
}

export default Orders;
