import {
  Button,
  Divider,
  Image,
  Input,
  notification,
  Spin,
  Steps,
  Table,
  Typography,
  Modal,
  DatePicker
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  actionTypes,
  getSearchCustomerOrderList,
} from "../../action/SearchOrdersListaction";
import frame from "../../images/Frame.svg";
import { verifyIfCustomerIsWaycredCustomer } from "../../services/orderServices/orderService";
import { handleErrorResponse } from "../../utils/Request";
import PrimaryHeader from "../header/PrimaryHeader";
import ErrorAlertMessage from "./ErrorAlertMessage/ErrorAlertMessage";
// import "../orders.scss";
import OrderSteps from "./OrderSteps";
import "./placeorder.scss";
import message from "antd/lib/message";
import { getPaymentDetails } from "../../action/paymentDetailsaction";
import { getDeliveryDates } from "../../action/deliveryDatesAction"
import moment from "moment";


const { Text } = Typography;

function OrdersCustomersList() {
  const dispatch = useDispatch();
  const { Search } = Input;
  const { Step } = Steps;
  const [tableDisplay, setTableDisplay] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});
  const [openMessage, setOpenMessage] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [editDelieryDate, setEditDelieryDate] = useState(false);
  const [deliveryDate, setdeliveryDate] = useState();
  const [custID, setCustID] = useState();
  const [disDates, setDisDates] = useState([]);
  const [selectedCust, setSelectedCust] = useState();
  const [loadingUntilCustomerCheck, setLoadingUntilCustomerCheck] =
    useState(null);
  const history = useHistory();
  const onSearchChange = (e) => {
    setSearchWord(e.target.value);
  };
  const { loading, searchOrdersList, searchedValue } = useSelector(
    (state) => state.serachOrdersReducer
  );
  const { paymentDetails } = useSelector(
    (state) => state.paymentDetailsReducer
  );
  const { deliveryDateDetails } = useSelector(
    (state) => state.deliveryDatesReducer
  );
  const totalNoOfDays =
    deliveryDateDetails?.settings?.orderDeliveryDays?.lastDay - deliveryDateDetails?.settings?.orderDeliveryDays?.earliestDay;
  const ExcludedDays = [];

  const disabledDatesArr = [];
  const j = 0;
  let days_exempted = [];
  const minimumDelDate = deliveryDateDetails?.settings?.orderDeliveryDays?.earliestDay;
  const today = moment(paymentDetails?.currentDate);
  const minDate = moment(today, "DD-MM-YYYY").add(minimumDelDate, "days");
  const [searchWord, setSearchWord] = useState();
  useEffect(() => {
    if (searchOrdersList?.length) {
      setTableDisplay(true);
      setSearchWord(searchedValue);
    } else {
      //setTableDisplay(true);
    }
  }, [searchOrdersList]);

  useEffect(() => {

    if (deliveryDateDetails?.settings?.orderDeliveryDays?.excludedDays?.length >= 1) {
      days_exempted = deliveryDateDetails?.settings?.orderDeliveryDays?.excludedDays;
      days_exempted.forEach((element) => {
        switch (element.toLowerCase()) {
          case "monday": {
            ExcludedDays.push(1);
            break;
          }
          case "tuesday": {
            ExcludedDays.push(2);
            break;
          }
          case "wednesday": {
            ExcludedDays.push(3);
            break;
          }
          case "thursday": {
            ExcludedDays.push(4);
            break;
          }
          case "friday": {
            ExcludedDays.push(5);
            break;
          }
          case "saturday": {
            ExcludedDays.push(6);
            break;
          }
          case "sunday": {
            ExcludedDays.push(0);
            break;
          }
          default: {
            break;
          }
        }
      });
    }

    for (var i = 0; i <= totalNoOfDays; i++) {
      // const day = moment.day
      const dayOfWeek = moment(minDate, "DD-MM-YYYY").add(i, "days").weekday();
      let isExcluded = ExcludedDays.every((m) => m !== dayOfWeek);
      if (isExcluded) {
        disabledDatesArr.push(moment(minDate, "DD-MM-YYYY").add(i, "days"));
      }
    }
    setDisDates(disabledDatesArr);

  }, [deliveryDateDetails]);

  const onEditDeliveryDate = () => {

    setEditDelieryDate(true);
  };
  const updateDeliveryDate = () => {
  };
  const handleEditCancel = () => {
    setEditDelieryDate(false);
  };
  const onHandleMessageClose = () => {
    setOpenMessage(false);
  };
  function disabledDate(current) {
    return disDates.every(
      (m) =>
        m.startOf("day")._d.toString() !== current.startOf("day")._d.toString()
    );
  }
  const checkCustomerIsWaycred = async (customerId) => {
    try {
      setLoadingUntilCustomerCheck(customerId);
      const res = await verifyIfCustomerIsWaycredCustomer(customerId);
      const resData = res?.data;
      if (resData.status == 200) {
        setLoadingUntilCustomerCheck(null);
        return !res?.data?.result;
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
      setLoadingUntilCustomerCheck(null);
    }
  };
  const onDeliverDateChange = async (e) => {
    setdeliveryDate(e);
    localStorage.setItem("SelectedDeliveryDate", e);
  }
  const onOrderBtnClick = async (customerId) => {
    dispatch(getDeliveryDates(customerId));
    setSelectedCust(customerId);
    setTimeout(() => {
      setEditDelieryDate(true);
    }, 500);


  }
  const takeToNextScreen = async () => {
    if (deliveryDate == undefined) {
      notification.warning({
        message: "please select delivery date",
      });
      return;

    }
    setEditDelieryDate(false);
    const isValid = await checkCustomerIsWaycred(selectedCust);
    if (isValid) {
      history.push("/dashboard/orders/placeorder/" + selectedCust);
    } else {
      setOpenMessage(true);
      setMessageInfo({
        success: false,
        title: "Order Placing Blocked!",
        subTitle: "Order placing has been blocked for waycred customers.",
      });
    }
  };
  const handleSearch = (val) => {
    if (val.trim() === "") {
      notification.warning({
        message: "please enter customer name or customer id",
      });
    } else if (val !== "") {
      dispatch(getSearchCustomerOrderList(val));
      setTableDisplay(true);
      //dispatch(saveSearchedValue(val));
    }
  };
  const columns = [
    {
      key: "1",
      title: "Customer",
      dataIndex: "name",
      render: (_, record) => (
        <div className="d-flex">
          <div className="mr-1">
            {record.imageUrl ? (
              <Image
                src={record.imageUrl || "https://picsum.photos/50/50"}
                width={48}
                className="rounded"
              />
            ) : (
              <div
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#ccc",
                  borderRadius: 10,
                }}
              ></div>
            )}
          </div>
          <div className="ml-2 d-flex flex-column justify-content-between">
            <Text className="">{record.name}</Text>
            <Text type="secondary">ID #{record.goFrugalId}</Text>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      title: "Contact",
      dataIndex: "phone1",
    },
    {
      key: "3",
      title: "Email ID",
      dataIndex: "email",
    },
    {
      key: "4",
      title: "Sales Category",
      dataIndex: "businessType",
    },

    {
      key: "7",
      title: "",
      align: "right",
      dataIndex: "key",
      render: (text, record) => (
        <div className="d-flex align-items-center justify-content-center">
          {record?.active_state ? (
            <Button
              className="px-4 order_btn"
              loading={loadingUntilCustomerCheck === record.goFrugalId}
              onClick={() => onOrderBtnClick(record.goFrugalId)}
            >
              Order
            </Button>
          ) : (
            <div>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "red",
                }}
              >
                Ordering is not allowed.
              </Typography>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "red",
                }}
              >
                This customer is blocked in SAP
              </Typography>
            </div>
          )}
        </div>
      ),
    },
  ];

  // console.log(searchedValue);
  return (
    <div style={{ width: "100%", backgroundColor: "#f5f7fa" }}>
      <div className="mt-3 w-100 px-4">
        <PrimaryHeader title="Place Order" backgroundColor="#f5f7fa" />
      </div>
      <OrderSteps />

      <div className="bg-white m-4 py-4">
        <div className="d-flex ml-4">
          <Divider
            type="vertical"
            style={{
              border: "2px solid #737EED",
              height: "22px",
              marginTop: "3px",
            }}
          />
          <div>
            <Typography>Search Customer</Typography>
          </div>
        </div>
        <div className="d-flex ml-4 mt-4 search_input">
          <Search
            style={{ width: "500px", padding: "10px 20px" }}
            size="large"
            className="serach_customerswithId"
            placeholder="Search by Customer Name / ID"
            enterButton
            onSearch={handleSearch}
            value={searchWord}
            onChange={onSearchChange}
          />
        </div>
        <div className="" style={{ minHeight: "40vh" }}>
          {searchOrdersList === null || searchOrdersList?.length >= 0 ? (
            tableDisplay === true ? (
              <Table
                className="m-4"
                dataSource={searchOrdersList}
                pagination={{
                  hideOnSinglePage: true,
                  current: page,
                  pageSize: pageSize,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                  },
                }}
                columns={columns}
                loading={loading?.includes(
                  actionTypes.GET_SEARCH_CUSTOMER_ORDERS_LIST
                )}
                style={{
                  border: "none",
                }}
              />
            ) : null
          ) : loading?.includes(actionTypes.GET_SEARCH_CUSTOMER_ORDERS_LIST) ? (
            <div
              className="d-flex w-100"
              style={{
                alignItems: "center",
                justifyContent: "center",
                marginTop: 200,
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <div style={{ textAlign: "center" }} className="my-5">
              <img src={frame} />
              <div className="mt-5">
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#3A4960",
                    marginBottom: "10px",
                  }}
                >
                  No Results Found!
                </Typography>
                <Typography
                  className="mt-1"
                  style={{
                    fontWeight: 500,
                    fontsize: "14px",
                    lineHeight: "17px",
                    color: "#9CA3AF",
                  }}
                >
                  We can’t seem to find the customer name that you are looking
                  for.
                </Typography>
              </div>
            </div>
          )}

          {/*searchOrdersList?.length && searchOrdersList !== null ? (
            <Table
              className="m-4"
              dataSource={searchOrdersList}
              columns={columns}
              loading={loading?.includes(
                actionTypes.GET_SEARCH_CUSTOMER_ORDERS_LIST
              )}
              pagination={false}
              style={{
                border: "none",
              }}
            />
          ) : (
           null
          )*/}
        </div>
      </div>
      <div className={openMessage ? "backdrop" : ""}>
        <div className="customMessage">
          <ErrorAlertMessage
            open={openMessage}
            success={messageInfo.success}
            onHandleClose={onHandleMessageClose}
            title={messageInfo.title}
            subTitle={messageInfo.subTitle}
          />
        </div>
      </div>
      <Modal
        visible={editDelieryDate}
        onOk={updateDeliveryDate}
        okText="Save"
        footer={null}
        // cancelButtonProps={{ style: { display: 'none' } }}
        //okButtonProps={{ disabled: !uploadfile }}
        // confirmLoading={confirmUploadLoading}
        onCancel={handleEditCancel}
        style={{ marginTop: "100px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div> <Divider
              type="vertical"
              style={{
                border: "2px solid #737EED",
                height: "22px",
                marginTop: "8px",
                marginLeft: "-10px"
              }}
            /></div>
            <div> <Typography className="py-2" style={{ marginRight: "243px", fontWeight: "bold" }}>Choose Delivery Date</Typography></div>


          </div>

          <div className="my-4" style={{ width: "-webkit-fill-available" }}>
            <Typography
              style={{
                fontSize: "14px",
                color: "#3A4960cc",
                fontWeight: 400,
              }}
            >
              Choose Delivery Date{" "}
            </Typography>

            <DatePicker
              className="mt-2 chooseDatePicker"
              allowClear={false}
              placeholder="Delivery Date"
              size="small"
              format="DD/MM/YYYY"
              placement="bottomLeft"
              value={deliveryDate}
              style={{ padding: "11px" }}
              onChange={onDeliverDateChange}
              disabledDate={disabledDate}
            />
          </div>
          <Button type="primary" style={{ marginLeft: "-316px", marginTop: "10px" }} onClick={takeToNextScreen}>Proceed</Button>

        </div>
      </Modal>
    </div>
  );
}

export default OrdersCustomersList;
