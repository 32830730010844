import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";

export const getProducts = async ({
  pageNo,
  recordPerPage,
  // plantCode,
  // salesOrg,
  // distributionChannel,
  searchKey,
  selectedCustGrp
}) => {
  let url = `api/v1/distributor/item?pageNo=${pageNo}&recordPerPage=${recordPerPage}&customerGroup=${selectedCustGrp}`;
  // let url = `api/v1/distributor/item?distributionCenter=${distributionCenter}&pageNo=${pageNo}&recordPerPage=${recordPerPage}&plantCode=${plantCode}&salesOrg=${salesOrg}&distributionChannel=${distributionChannel}`;
  if (searchKey) {
    url = `${url}&searchKey=${searchKey}`;
  }
  // const res = await fetch(url);

  // const data = await res.json();
  let request = await axios.get(APIConfig.base_url + url, {
    headers: {
      "x-access-token": getAuthToken(),
      "Content-Type": "application/json",
    },
  });
  return request;
};
export const getAllProducts = async (selectedCustGrp) => {
  let url = `api/v1/distributor/exportData?customerGroup=${selectedCustGrp}`;

  let request = await axios.get(APIConfig.base_url + url, {
    headers: {
      "x-access-token": getAuthToken(),
      "Content-Type": "application/json",
    },
  });
  return request;
};

export const uploadStockAndUpload = async (file) => {
  let url = `api/v1/distributor/update-inventory`;
  let formData = new FormData();
  formData.append("file", file);

  let request = await axios.put(APIConfig.base_url + url, formData, {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};

export const priceSync = async (selectedCustGrp) => {
  let url = `api/v1/distributor/priceSyncMdb?customerGroup=${selectedCustGrp}`;
  let request = await axios.get(APIConfig.base_url + url, {
    headers: {
      "x-access-token": getAuthToken(),
      "Content-Type": "application/json",
    },
  });
  return request;
};

export const getCustomerGroupService = async () => {
  let url = `api/v1/utils/priceGroupList`;
  let request = await axios.get(APIConfig.base_url + url, {
    headers: {
      "x-access-token": getAuthToken(),
      "Content-Type": "application/json",
    },
  });
  return request;
};