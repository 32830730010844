import generateActionTypes from "../utils/generateActionTypes";
import { getIndividualOrderDetails } from "../services/orderServices/itemsService";
import { getOTPMenuConfig } from "../services/otpServices/otpServices";
import { handleErrorResponse } from "../utils/Request";
import message from "antd/lib/message";

export const actionTypes = generateActionTypes(
  "LOADING",
  "STOP_LOADING",
  "GET_INDIVIDUAL_ORDER_DETAILS",
  "RESET_INDIVIDUAL_ORDER_DETAILS",
  "GET_CONFIG_DATA"
);

export const resetOrderState = () => {
  return async (dispatch) => {
    dispatch({
      type: "RESET_INDIVIDUAL_ORDER_DETAILS",
    });
  };
};

export const getIndividualOrderDetail = (salesOrderId, customerID) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
    });
    try {
      const res = await getIndividualOrderDetails(salesOrderId, customerID);
      const resData = res?.data;

      if (resData.status == 200) {
        const { yourorderdetails } = resData.result;

        dispatch({
          type: actionTypes.GET_INDIVIDUAL_ORDER_DETAILS,
          data: resData.result,
        });
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error();
      }
    }
  };
};

export const getOTPConfig = () => {
  return async (dispatch) => {
    try {
      const res = await getOTPMenuConfig();
      const resData = res?.data;
      if (resData.status == 200) {
        const result = res?.data?.data[0];

        dispatch({
          type: actionTypes.GET_CONFIG_DATA,
          data: result,
        });
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
    }
  };
};

// export const getOTPConfig = () => {
//   return async (dispatch) => {
//     try {
//       const res = await getOTPMenuConfig();
//       const resData = res?.data;
//       if (resData.status == 200) {
//         const result = res?.data?.data[0];
//         console.log(result);
//         dispatch({
//           type: actionTypes.GET_CONFIG_DATA,
//           data: result,
//         });
//       } else {
//         message.error(
//           resData?.error && typeof resData.error === "string"
//             ? resData.error
//             : "Something went wrong"
//         );
//       }
//     } catch (err) {
//       if (err?.response?.status === 401) {
//         console.log(err, "error");
//         const { errorMessage, errors } = handleErrorResponse(err);
//       } else {
//         message.error(err?.response?.data?.message);
//       }
//     }
//   };
// };
