// import React, { Component } from "react";
// import "./modal.scss";
// import cross from "../../images/cross.svg";
// import edit from "../../images/edit.svg";
// import trash from "../../images/trash.svg";
// import profilePic from "../../images/user-logo.svg";

// class SalesManDetails extends Component {
//   render() {
//     let {details}= this.props
//     return (
//       <React.Fragment>
//         <div className="overlay"></div>
//         <div className="customer-modal-container salesman-modal-container">
//           <div className="customer-details">
//             <div className="cross-button">
//               {" "}
//               <img src={cross} onClick={this.props.displayModal} className="cross-img"/>
//             </div>
//             <div className="profilePic">
//               <img src={details.photo}  className="photo" /><br/>
//               <p>{ details.firstName +" "+details.lastName}</p>
//             </div>
//           </div>

//           <div className="other-details">
//           <div className="rectangle first">
//             <div className="name"> Employee Id </div>
//             <div className="description">{ details.employeeId}</div>
//           </div>
//           <div className="rectangle">
//             <div className="name">Email</div>
//             <div className="description">{details.email}</div>
//           </div>
//           <div className="rectangle">
//             <div className="name">Phone Number</div>
//             <div className="description">{details.contactMobile}</div>
//           </div>
//           <div className="rectangle">
//             <div className="name">Designation</div>
//             <div className="description">{details.designation}</div>
//           </div>
//           <div className="rectangle">
//             <div className="name">Reporting To</div>
//             <div className="description">{details.asmMapping.asmId && details.asmMapping.asmId.firstName+" "+details.asmMapping.asmId.lastName}</div>
//           </div>
//           </div>
//         </div>
//       </React.Fragment>
//     );
//   }
// }
// export default SalesManDetails;

import React, { Component } from "react";
import "./modal.scss";
import cross from "../../images/cross.svg";
import edit from "../../images/edit.svg";
import trash from "../../images/trash.svg";
import profilePic from "../../images/user-logo.svg";
import { Modal, Button } from "antd";
class SalesManDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }
  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  render() {
    let { details } = this.props;
    let { visible } = this.state;

    return (
      <Modal
        visible={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        width={200}
      >
        <div>
          <div className="row justify-content-center">
            <img src={details.photo} className="photo" />
          </div>
          <div className="row justify-content-center profilePic mt-2">
            <p>
              {details.firstName
                ? details.firstName
                : "" + " " + details.lastName
                ? details.lastName
                : ""}
            </p>
          </div>
          <hr className="mx-n4" />
          <div className="row">
            <div className="col-lg-4 name"> Employee Id </div>
            <div className="col-lg-4 description">{details.employeeId}</div>
          </div>
          <hr className="mx-n4" />
          <div className="row">
            <div className="col-lg-4 name"> Email </div>
            <div className="col-lg-4 description">{details.email}</div>
          </div>
          <hr className="mx-n4" />
          <div className="row">
            <div className="col-lg-4 name"> Phone Number </div>
            <div className="col-lg-4 description">{details.contactMobile}</div>
          </div>
          <hr className="mx-n4" />
          <div className="row">
            <div className="col-lg-4 name"> Designation </div>
            <div className="col-lg-4 description">{details.designation}</div>
          </div>
          <hr className="mx-n4" />
          <div className="row">
            <div className="col-lg-4 name"> Reporting To </div>
            <div className="col-lg-4 description">
              {details.asmMapping &&
                details.asmMapping.asmId &&
                details.asmMapping.asmId.firstName + " " + details.asmMapping &&
                details.asmMapping.asmId &&
                details.asmMapping.asmId.lastName}
            </div>
          </div>
          <hr className="mx-n4" />
          <div className="row">
            <div className="col-lg-4 name"> Agency Name </div>
            <div className="col-lg-4 description">
              {details.employerName && details.employerName}
            </div>
          </div>
          <hr className="mx-n4" />
        </div>
      </Modal>
    );
  }
}

export default SalesManDetails;
