import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import message from "antd/lib/message";
import { Button, Input, Typography, Spin, notification, Switch } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "./manageShipping.scss";
import CardLeftContent from "../CardLeftContent";
import Rupee from "../../../images/rupees.svg";
import { blockInvalidChar } from "../../../utils/numberValidation";
import { upDateShippingCharge } from "../../../services/settings/manageShippingservice";
import {
  actionTypes,
  getListOfExceptionalCustomeTaleData,
  getShippingCharges,
} from "../../../action/manageShippingaction";

const ShippingCharges = () => {
  const dispatch = useDispatch();
  const { shippingCharges, loading, listOfEceptionalCustomers } = useSelector(
    (state) => state.manageShippingReducer
  );

  const { ShippingOrderAmt, shippingCharge } = shippingCharges;
  const [editOpen, setEditOpen] = useState(false);
  const [exeptionCheck, setEceptionCheck] = useState(false);

  const [invalidError, setInvalidError] = useState("");

  const [shippingOrderAmt, setShippingOrderAmt] = useState();
  const [shippingChargeValue, setshippingChargeValue] = useState();

  const onHandleEditOpen = () => {
    setEditOpen(true);
  };

  useEffect(() => {
    setShippingOrderAmt(ShippingOrderAmt);
    setshippingChargeValue(shippingCharge);
  }, [ShippingOrderAmt, shippingCharge]);

  // console.log(listOfEceptionalCustomers);
  async function onClickSaveHandler() {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.UPDATE_ORDER_AMOUNT_AND_SHIPPING,
      });
      const res = await upDateShippingCharge({
        orderAmount: shippingOrderAmt,
        shippingCharge: shippingChargeValue,
        exemptCheck: exeptionCheck ? 1 : 0,
      });
      if (res.data.status == 200) {
        notification.success({ message: "Data updated successfully!" });

        setEditOpen(false);
        dispatch(getShippingCharges());
        if (exeptionCheck) {
          dispatch(getListOfExceptionalCustomeTaleData());
        }
      } else {
        message.error(res.data.data);
      }

      dispatch({
        type: actionTypes.STOP_LOADING,
        load: actionTypes.UPDATE_ORDER_AMOUNT_AND_SHIPPING,
      });
    } catch (error) {
      console.log({ error });
    }
  }

  function onDeliveryDateCheckedSwitch(checked) {
    // console.log(checked);
    setEceptionCheck(checked);
  }

  function onShippingChargeChange(e) {
    if (e.target.value.length > 8) return false;
    setshippingChargeValue(e.target.value);
  }
  function onShippingOrderAmtChange(e) {
    if (e.target.value.length > 8) return false;
    setShippingOrderAmt(e.target.value);
    // setInvalidError("");
    // if (e.target.value.length == 0) {
    //   setInvalidError("enter the shipping charge amount");
    // }
  }

  const onClickCancel = () => {
    setShippingOrderAmt(ShippingOrderAmt);
    setshippingChargeValue(shippingCharge);
    setEditOpen(false);
    // setErrorMessage("");
  };

  return (
    <div className="rounded-lg shadow-sm py-4 px-4 bg-white">
      <div className=" d-flex justify-content-between">
        <CardLeftContent
          icon={<img src={Rupee} alt="box" style={{ width: 60, padding: 7 }} />}
          title="Shipping Charges"
          subTitle="Order amount below which shipping charges is appliciable."
        />
        {editOpen ? null : (
          <div className="d-flex flex-row ml-5">
            <div className="d-flex justify-content-center flex-column">
              {loading.includes(actionTypes.MINIMUM_SHIPPING_CHARGES) ? (
                <Spin />
              ) : (
                <Typography
                  className="font-weight-bold"
                  style={{ fontSize: 16, color: "#3A4960" }}
                >
                  ₹ {shippingChargeValue}
                </Typography>
              )}
            </div>

            <div className="p-3 ml-5 d-flex align-items-center">
              <EditOutlined
                style={{ fontSize: 24, color: "#5078F2" }}
                onClick={() => onHandleEditOpen()}
              />
            </div>
          </div>
        )}
      </div>
      {editOpen ? (
        <div style={{ marginLeft: "160px" }}>
          <div className="pt-3">
            <div className="d-flex w-75 flex-column">
              <div className="d-flex w-100 flex-row align-items-end">
                <div className="mr-3">
                  <Typography style={{ fontSize: 16, color: "#3A4960" }}>
                    Order amount :
                  </Typography>
                  <Input
                    placeholder="₹ 10,000"
                    className="mr-3"
                    type="number"
                    value={shippingOrderAmt}
                    onKeyPress={blockInvalidChar}
                    onChange={onShippingOrderAmtChange}
                    style={{ height: 40 }}
                  />
                </div>
                {/* <p style={{ color: "red" }}>{invalidError}</p>*/}
                <div className="mr-3">
                  <Typography style={{ fontSize: 16, color: "#3A4960" }}>
                    Shipping charge :
                  </Typography>
                  <Input
                    placeholder="₹ 10,000"
                    className="mr-3"
                    value={shippingChargeValue}
                    onChange={onShippingChargeChange}
                    type="number"
                    onKeyPress={blockInvalidChar}
                    style={{ height: 40 }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex my-3">
              <Switch
                checked={exeptionCheck}
                onChange={onDeliveryDateCheckedSwitch}
              />

              <Typography style={{ color: "#3A4960" }} className="ml-4">
                Enabling this will set shipping charges for Exceptional
                Customers also.
              </Typography>
            </div>
            <div className="mt-5">
              <Button
                size="large"
                value="default"
                className="cancel_button"
                onClick={() => onClickCancel()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="rounded-lg save_button ml-5"
                onClick={onClickSaveHandler}
                disabled={!shippingChargeValue || !shippingOrderAmt}
                loading={loading.includes(
                  actionTypes.UPDATE_ORDER_AMOUNT_AND_SHIPPING
                )}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ShippingCharges;
