import React, { useState, useEffect } from "react";
import { Spin, Steps, Typography } from "antd";
import orderPlaced from "../../images/orderPlaced.svg";
import orderPending from "../../images/orderPending_o.svg";
import orderFailed from "../../images/orderFailed_r.svg";
import orderCancelled from "../../images/orderCancelled_r.svg";
import packing from "../../images/packing_g.svg";
import packing_w from "../../images/Packing_w.svg";
import Intrip from "../../images/Intrip_g.svg";
import Intrip_w from "../../images/InTrip_w.svg";
import Location_g from "../../images/location_g.svg";
import Location_o from "../../images/location_o.svg";
import Location_w from "../../images/Location_w.svg";
import deliveryAttempted from "../../images/deliveryAttempted.svg";
import shopClosed from "../../images/shopClosed.svg";
import deliveryDenaid from "../../images/deliveryDenaid.svg";
import salesReturned from "../../images/salesReturned.svg";
import adjustedandpacked from "../../images/adjustedandpacked_o.svg";
import deliverydenaied from "../../images/deliverydenaied.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import "./orderStatusStepper.scss";

const { Step } = Steps;
const OrderStatusStepper = ({ type, data }) => {
  const { IndividualOrderDetails, loading } = useSelector(
    (state) => state.individualOrderDetailsReducer
  );

  let salesorderstatus = IndividualOrderDetails[0]?.salesorderstatus;
  let salesorderNumber = IndividualOrderDetails[0]?.soStatusNumber;
  let salesOrderStatusDate = IndividualOrderDetails[0]?.orderDateDP;
  let deliverdDate = IndividualOrderDetails[0]?.deliveryDate;
  console.log(deliverdDate);

  const returnStepperData = (index) => {
    index = index.toString();
    switch (index) {
      case "0":
        if (data.length < 1) {
          return {
            invoiceStatus: "",
            dpLabel: "Packed",
            CreatedDate: "",
          };
        } else {
          return data[0];
        }
        break;
      case "1":
        if (data.length < 2) {
          return {
            invoiceStatus: "",
            dpLabel: "In-Trip",
            CreatedDate: "",
          };
        } else {
          return data[1];
        }
        break;
      case "2":
        if (data.length < 3) {
          return {
            invoiceStatus: "",
            dpLabel: "Expected delivery date",
            CreatedDate: "",
          };
        } else {
          return data[2];
        }
        break;
    }
  };
  let orderPacked1 = returnStepperData(0);
  let outForDelivery1 = returnStepperData(1);
  let deliveryStatus2 = returnStepperData(2);

  //let orderPacked1 = data.invoiceStatus

  let orderPacked = IndividualOrderDetails[0]?.orderpacked?.orderpackedstatus;
  let outForDelivery =
    IndividualOrderDetails[0]?.outfordelivery?.outfordeliverystatus;

  let deliveredEnum = [
    "order delivered",
    "delivered partially",
    "delivery attempted",
    "we are not able to find you",
    "delivery not accepted",
    "sales return",
  ];
  let deliveryStatus = deliveredEnum.includes(
    IndividualOrderDetails[0]?.delivered?.deliveredstatus.toLowerCase()
  )
    ? IndividualOrderDetails[0]?.delivered?.deliveredstatus
    : null;

  let deliveryStatus1 =
    deliveryStatus?.toLowerCase() === "delivered partially"
      ? "Delivered (Sales Return)"
      : deliveryStatus?.toLowerCase() === "we are not able to find you"
      ? "Delivery Attempted"
      : deliveryStatus?.toLowerCase() === "delivery not accepted"
      ? "Denied Delivery"
      : deliveryStatus;

  const [current, setCurrent] = useState(0);
  useEffect(() => {
    if (
      deliveryStatus2.invoiceStatus != undefined &&
      deliveryStatus2.invoiceStatus != ""
    ) {
      setCurrent(3);
      return;
    }
    if (
      outForDelivery1.invoiceStatus != undefined &&
      outForDelivery1.invoiceStatus != ""
    ) {
      setCurrent(2);
      return;
    }
    if (
      orderPacked1.invoiceStatus != undefined &&
      orderPacked1.invoiceStatus != ""
    ) {
      setCurrent(1);
      return;
    }
    if (salesorderNumber) {
      setCurrent(0);
      return;
    }
  });

  const stepper1 = () => {
    type = salesorderNumber?.toString();
    switch (type) {
      //case "Order Placed":
      case "7":
        return (
          <div>
            {
              <img
                src={orderPlaced}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                  // height: "29px",
                  // padding: "2px",
                  // backgroundColor: "#27ae60",
                }}
              />
            }
          </div>
        );
      //  case "Order Pending":
      case "6":
        return (
          <div>
            {
              <img
                src={orderPending}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
          </div>
        );

      //  case "Order Failed":
      case "5":
        return (
          <div>
            {
              <img
                src={orderFailed}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
          </div>
        );
      // case "Order Processing":
      case "0":
        return (
          <div>
            {
              <img
                src={orderPending}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
          </div>
        );
      //  case "Order Cancelled":
      case "4":
        return (
          <div>
            {
              <img
                src={orderCancelled}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
          </div>
        );
      default:
        return (
          <div>
            {
              <img
                src={orderPlaced}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                  // height: "29px",
                  // padding: "2px",
                  // backgroundColor: "#27ae60",
                }}
              />
            }
          </div>
        );
    }
  };
  const stepper2 = () => {
    type = orderPacked1.invoiceStatus?.toString();
    switch (type) {
      // case "Order Adjusted & Packed":
      case "2":
        return (
          <div>
            {
              <img
                src={adjustedandpacked}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
          </div>
        );
      //  case "Packed":
      case "1":
        return (
          <div>
            {
              <img
                src={packing}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
          </div>
        );
      // case "Order Packed":
      // case "ORDER PACKED":
      //   return (
      //     <div>
      //       {
      //         <img
      //           src={packing}
      //           style={{
      //             height: "55px",
      //             width: "54px",
      //             marginTop: "-4px",
      //             marginLeft: "-3px",
      //           }}
      //         />
      //       }
      //     </div>
      //   );

      default:
        return (
          <div>
            {
              <img
                src={packing_w}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
          </div>
        );
    }
  };
  const stepper3 = () => {
    type = outForDelivery1.invoiceStatus?.toString();

    switch (type) {
      // case "Out For delivery":
      case "3":
        return (
          <div>
            {
              <img
                src={Intrip}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
          </div>
        );

      default:
        return (
          <div>
            {
              <img
                src={Intrip_w}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                  // height: "29px",
                  // padding: "2px",
                  // backgroundColor: "#27ae60",
                }}
              />
            }
          </div>
        );
    }
  };
  const stepper4 = () => {
    type = deliveryStatus2.invoiceStatus?.toString();
    console.log(type);
    switch (type) {
      // case "Order delivered":
      case "4":
        return (
          <div>
            {
              <img
                src={Location_g}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
          </div>
        );

      // case "Delivery Attempted":
      case "8":
        return (
          <div>
            {
              <img
                src={deliveryAttempted}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
            <Typography className="delivery_orderStatus">
              Customer was not available <br /> at the time of delivery.
            </Typography>
          </div>
        );
      // Sales return
      case "9":
        return (
          <div>
            {
              <img
                src={salesReturned}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
            <Typography className="delivery_orderStatus">
              Order has been returned by <br /> customer.
            </Typography>
          </div>
        );
      // case We are not able to find you
      case "6":
        return (
          <div>
            {
              <img
                src={shopClosed}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
            <Typography className="delivery_orderStatus">
              Store was closed <br /> at the time of delivery.
            </Typography>
          </div>
        );

      // case "Delivered (Sales Return)":
      case "5":
        return (
          <div>
            {
              <img
                src={salesReturned}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
            <Typography className="delivery_orderStatus">
              Order has been returned by <br /> customer.
            </Typography>
          </div>
        );
      //case "Delivery not accepted."
      case "7":
        return (
          <div>
            {
              <img
                src={deliverydenaied}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
            <Typography className="delivery_orderStatus">
              {/* Customer denied delivery. */}
              {deliveryStatus2?.dpRemark}
            </Typography>
          </div>
        );

      default:
        return (
          <div>
            {
              <img
                src={Location_w}
                style={{
                  height: "55px",
                  width: "54px",
                  marginTop: "-4px",
                  marginLeft: "-3px",
                }}
              />
            }
          </div>
        );
    }
  };

  return (
    <div className="bg-white p-1 m-3" style={{ height: "230px" }}>
      <div class="container steps_container">
        {loading ? (
          <div className="d-flex m-5 flex-column justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <Steps
            current={current}
            className="mt-5 mr-10"
            labelPlacement="vertical"
            style={{ marginLeft: "-32px" }}
          >
            <Step
              title={salesorderstatus ? salesorderstatus : "Order Packed"}
              icon={stepper1()}
              description={
                salesOrderStatusDate
                  ? moment(salesOrderStatusDate)
                      ?.utcOffset(0, false)
                      .format("MMM DD,YYYY")
                  : ""
              }
            />

            <Step
              title={orderPacked1.dpLabel ? orderPacked1.dpLabel : "Packed"}
              icon={stepper2()}
              description={
                orderPacked1.CreatedDate
                  ? moment(orderPacked1.CreatedDate)
                      ?.utcOffset(0, false)
                      .format("MMM DD,YYYY")
                  : ""
              }
            />
            <Step
              title={
                outForDelivery1.dpLabel ? outForDelivery1.dpLabel : "In-trip"
              }
              icon={stepper3()}
              description={
                outForDelivery1.CreatedDate
                  ? moment(outForDelivery1.CreatedDate)
                      ?.utcOffset(0, false)
                      .format("MMM DD,YYYY")
                  : ""
              }
            />
            <Step
              title={
                deliveryStatus2.dpLabel ? deliveryStatus2.dpLabel : "Delivered"
              }
              icon={stepper4()}
              // title={deliveryStatus2.CreatedDate}
              description={
                deliveryStatus2.CreatedDate
                  ? moment(deliveryStatus2.CreatedDate)
                      ?.utcOffset(0, false)
                      .format("MMM DD,YYYY")
                  : ""
              }
            />
          </Steps>
        )}
      </div>
    </div>
  );
};

export default OrderStatusStepper;
