import { actionTypes } from "../action/manageOrder";

const {
  GET_ORDERING_TIME,
  GET_DELIVERY_DATES,
  GET_EXCEPTIONAL_CUSTOMER_DELIVERY_DATE,
  LOADING,
  STOP_LOADING,
  GET_EARLIER_LAST_EXCLUDED_DAY,
  GET_LIST_OF_EXCEPTIONAL_CUSTOMERS,
  GET_ORDER_CANCELLATION,
} = actionTypes;

const initialState = {
  loading: [],
  orderingTime: {},
  deliveryDates: {},
  exceptionalCustomerDeliveryDates: [],
  exceptionalCustomerEarliestLatestAndExcludedDays: {},
  listOfEceptionalCustomers: [],
  orderCancellation: [],
};

const stopLoading = (type, loadArray) => {
  // console.log(type, loadArray);
  return loadArray.filter((load) => load !== type);
};

export default function cod(state = initialState, action) {
  const { type, data, load } = action;

  switch (type) {
    case LOADING:
      return { ...state, loading: [...state.loading, load] };
    case STOP_LOADING:
      return { ...state, loading: stopLoading(load, state.loading) };
    case GET_ORDERING_TIME:
      return {
        ...state,
        orderingTime: { ...data },
        loading: stopLoading(type, state.loading),
      };
    case GET_DELIVERY_DATES:
      return {
        ...state,
        deliveryDates: { ...data },
        loading: stopLoading(type, state.loading),
      };
    case GET_EXCEPTIONAL_CUSTOMER_DELIVERY_DATE:
      return {
        ...state,
        exceptionalCustomerDeliveryDates: [...data],
        loading: stopLoading(type, state.loading),
      };

    // return { ...state, exceptionalCustomerDeliveryDates: [...action.data] };
    case GET_EARLIER_LAST_EXCLUDED_DAY:
      return {
        ...state,
        exceptionalCustomerEarliestLatestAndExcludedDays: { ...action.data },
      };

    case GET_LIST_OF_EXCEPTIONAL_CUSTOMERS:
      return {
        ...state,
        listOfEceptionalCustomers: [...data],
        loading: stopLoading(type, state.loading),
      };
    case GET_ORDER_CANCELLATION:
      return {
        ...state,
        orderCancellation: { ...action.data },
        loading: stopLoading(type, state.loading),
      };
    default:
      return state;
  }
}
