import { actionTypes } from "../action/deliveryDatesAction";
const initialState = {
    loading: false,
    deliveryDateDetails: [],
};

export const deliveryDatesReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {

        case actionTypes.DELIVERY_DATES:
            return {
                ...state,
                deliveryDateDetails: { ...data.result },
            };

        default:
            return state;
    }
};
