import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";

export const getOrderingTime = async () => {
  let url = `api/v1/distributorSettings/settings/manageOrders`;
  let request = await axios.get(
    APIConfig.base_url + url,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const updateOrderingTime = async (from, to) => {
  let url = `api/v1/distributorSettings/settings/updateOrderingTime`;
  let request = await axios.patch(
    APIConfig.base_url + url,
    { fromTime: from, toTime: to },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getExeptionalCustomerOrder = async () => {
  let url = `api/v1/distributorSettings/settings/getCustomerDropDown`;
  let request = await axios.get(
    APIConfig.base_url + url,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const updateDeliveryDates = async (
  ear,
  las,
  excludedDays,
  deliveryDateChecked
) => {
  let url = `api/v1/distributorSettings/settings/updateDeliveryDates`;
  let request = await axios.patch(
    APIConfig.base_url + url,
    {
      lastDay: las,
      earliestDay: ear,
      exemptCheck: deliveryDateChecked ? 1 : 0,
      excludedDays,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const updateManageOrderEarlierLastAndExcluded = async (
  earliestDay,
  lastDay,
  excludedDays,
  customerIdList
) => {
  // console.log(earliestDay, lastDay, excludedDays, customerIdList, "jhgfdfgh");
  // let url = `api/v1/distributorSettinggetAuthTokens/settings/updateExceptionCustomers`;
  let url = `api/v1/distributorSettings/settings/updateExceptionCustomers`;
  let request = await axios.patch(
    APIConfig.base_url + url,
    {
      daysExcluded: excludedDays.length ? excludedDays : [],
      endDay: lastDay,
      startDay: earliestDay,
      customerId: customerIdList.map((item) => item.toString()),
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getListOfExeptionalCustomerOrder = async () => {
  // let url = `api/v1/distributorSettings/settings/listExceptionalCustomers`;
  let url = `api/v1/distributorSettings/settings/getExceptionalCustomers`;
  let request = await axios.get(
    APIConfig.base_url + url,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
export const getCancellationOrderDetail = async () => {
  console.log("api cal");
  let url = `api/v1/distributorSettings/settings/cancel`;
  let request = await axios.get(
    APIConfig.base_url + url,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
export const updateCancellationOrderDetails = async (
  cancelTimeHours,
  cancelTimeMinutes,
  cancelToggelStatus
) => {
  // console.log(earliestDay, lastDay, excludedDays, customerIdList, "jhgfdfgh");
  // let url = `api/v1/distributorSettinggetAuthTokens/settings/updateExceptionCustomers`;
  let url = `api/v1/distributorSettings/settings/updateCancelSettings`;
  let request = await axios.patch(
    APIConfig.base_url + url,
    {
      cancelTimeHours,
      cancelTimeMinutes,
      cancelToggelStatus,
    },

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
