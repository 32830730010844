import message from "antd/lib/message";
import moment from "moment";
export const checkErr = (_err) => {
  console.log("from common", _err);
  let errMsg = "";
  if (_err.firstName) {
    errMsg = errMsg + _err.firstName + ". ";
  }
  if (_err.lastName) {
    errMsg = errMsg + _err.lastName + ". ";
  }
  if (_err.contactMobile) {
    errMsg = errMsg + _err.contactMobile + ". ";
  }
  if (_err.email) {
    errMsg = errMsg + _err.email + ". ";
  }
  if (_err.reportingManagerId) {
    errMsg = errMsg + _err.reportingManagerId;
  }

  return errMsg;
};

export const formatMoney = (amount, toFixed) => {
  try {
    if (toFixed === undefined) {
      toFixed = 2;
    }
    if (amount === undefined) {
      return "...";
    }
    if (amount === null) {
      return "....";
    }
    if (amount.length === "") {
      return "....";
    }
    if (isNaN(+amount)) {
      return "....";
    }

    // return (+amount).toFixed(toFixed).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    let Amount = amount.toFixed(2);
    let _amount =
      Amount.toString().split(".")[0].length > 3
        ? Amount.toString()
            .substring(0, amount.toString().split(".")[0].length - 3)
            .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          Amount.toString().substring(
            amount.toString().split(".")[0].length - 3
          )
        : Amount.toString();
    // let finalAmount = _amount.toFixed(2);
    return _amount;
  } catch (error) {}
};

export const getQparams = (qParams) => {
  // let qParams =[{page:1},{search:"abi"}]
  let url = "";

  if (qParams) {
    let params = qParams;
    let flag = 1;
    params.map((item, index) => {
      if (Object.values(item)[0] !== "") {
        if (flag) {
          flag = 0;
          url += `?${Object.keys(item)}=${Object.values(item)}`;
        } else {
          url += `&${Object.keys(item)}=${Object.values(item)}`;
        }
      }
    });
    console.log(url,'Check URL')
    return url;
    // ?page=1&search=abi"
  }
};
export const stringSortingByChar = (str, char) => {
  if (
    str != null &&
    str != undefined &&
    str != "" &&
    str.length > char &&
    str.length != char
  ) {
    let sorted = str.slice(0, char);

    return sorted + "..";
  } else {
    return str;
  }
};

export const determineMaxdate = () => {
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  var date = new Date();

  let maxDate = date.addDays(14);
  return maxDate;
};
export const GetFormattedDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  let res = [day, month, year].join("-");
  return res;
};
export const getDayName = (name) => {
  switch (name) {
    case "sun":
      return "Sunday";
    case "mon":
      return "Monday";
    case "tue":
      return "Tuesday";
    case "wed":
      return "Wednesday";
    case "thu":
      return "Thursday";
    case "fri":
      return "Friday";
    case "sat":
      return "Saturday";
  }
};
export const checkForChar = (char) => {
  switch (char) {
    case 42:
      message.warning("Please don't enter '*' !");
      return false;
    case 32:
      message.warning("Please don't enter space !");
      return false;
    case 45:
      message.warning(
        "Please don't enter '-' .Negative value is not accepted!"
      );
      return false;
    default:
      return true;
  }
};
// Can not select days before today and today
export const disabledDate = (current) => {
  return current && current < moment().endOf("day");
};
export const disabledEndDate = (current, startDate) => {
  return current && current < moment(startDate, "DD/MM/YYYY");
};
// get difference between two arrays of object
export const compareArrayFunction = (a, b) => {
  function comparer(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other.value == current.value && other.label == current.label;
        }).length == 0
      );
    };
  }

  var onlyInA = a.filter(comparer(b));
  var onlyInB = b.filter(comparer(a));

  let result = onlyInA.concat(onlyInB);
  // console.log("result ", result);
  return result;
};
export const disabledPastDate = (current) => {
  let date = new Date();
  return current && current < moment(date, "DD-MM-YYYY").subtract(1,'day');
}
export const disabledFutureDate = (current) => {
  let date = new Date();
  return current && current > moment(date, "DD-MM-YYYY");
}
