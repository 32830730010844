import React, { useEffect } from "react";
import { logoutFunction } from "../../utils/Auth";

function Logout() {
  useEffect(() => {
    logoutFunction();
  }, []);
  return <div></div>;
}

export default Logout;
