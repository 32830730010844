import { getProducts } from "../services/Products/product";
import generateActionTypes from "../utils/generateActionTypes";
import { handleErrorResponse } from "../utils/Request";
export const actionTypes = generateActionTypes(
  "PRODUCTS_LISTS",
  "STOCK_MOQ",
  "PAGINATE_STOCK",
  "LOADING",
  "STOP_LOADING"
);
export function getProductsList(data) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.PRODUCTS_LISTS,
      data,
    });
  };
}
export function startLoading(loadType) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.LOADING,
      load: loadType,
    });
  };
}

export function stopLoading(loadType) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.STOP_LOADING,
      load: loadType,
    });
  };
}

export function getStockmoq(pageNo, recordPerPage, searchKey,selectedCustGrp) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.STOCK_MOQ,
    });
    try {
      const distributionCenter = 1017;
      // const plantCode = 1017;
      // const salesOrg = 5000;
      // const distributionChannel = 50;

      // const searchKey = "";
      const { data } = await getProducts({
        distributionCenter,
        pageNo,
        recordPerPage,
        // plantCode,
        // salesOrg,
        // distributionChannel,
        searchKey,
        selectedCustGrp
      });

      if (data.status === 200) {
        return dispatch({
          type: actionTypes.STOCK_MOQ,
          data,
        });
      }
      return dispatch({
        type: actionTypes.STOCK_MOQ,
        data: {
          result: { items: [], pageNo: 1, totalPages: 1, recordPerPage: 10 },
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: actionTypes.STOP_LOADING,
        load: actionTypes.STOCK_MOQ,
      });
      const { errorMessage, errors } = handleErrorResponse(err);
    }
  };
}

export function listenOnStockPageChange(pageNo, recordPerPage) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.PAGINATE_STOCK,
      data: { pageNo, recordPerPage },
    });
  };
}
