import React, { useEffect, useState } from "react";
import { Tabs, DatePicker, Pagination, Spin } from "antd";
import PrimaryHeader from "../../header/PrimaryHeader";
import "./index.scss";
import { AsyncPaginate } from "react-select-async-paginate";
import "antd/dist/antd.css";
import {
    getMaterialCode,
    getStockVarianceDownload,
    getStockVarianceList,
} from "../../../services/inventoryServices/inventoryServices";
import { upperFirst } from "lodash";
import moment from "moment";
import ChevDown from "../../../images/chevDown.svg";
import ChevUp from "../../../images/ChevUp.svg";
import Download from "../../../images/download (2).svg";
import ArrowDown from "../../../images/ArrowDown.svg";
import calender from "../../../images/calendar.svg";
import { handleErrorResponse } from "../../../utils/Request";
import message from "antd/lib/message";
import { saveAs } from "file-saver";
import debounce from "debounce-async";
import { LoadingOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const today = new Date();
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function Report(props) {
    const [tab, setTab] = React.useState("Stock Variance");
    const [date, setDate] = React.useState({
        from: moment(today),
        to: moment(today),
    });
    const [image, setImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [array, setArray] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [values, setValues] = useState({
        materialCode: "",
        from: "",
        to: "",
    });

    //   console.log(moment().format("MMMM Do YYYY, h:mm:ss a"));

    const redirectToJourneyReports = () => {
        props.history.push({
            pathname: "/dashboard/reports/journeyReports",
            state: { values: values },
        });
    };

    const handleChange = (e, type) => {
        if (type == "async") {
            setValues((prev) => ({
                ...prev,
                materialCode: e,
            }));
        }
        if (type == "from") {
            setValues((prev) => ({
                ...prev,
                from: e && moment(e._d).format("YYYY-MM-DD"),
            }));
        }
        if (type == "to") {
            setValues((prev) => ({
                ...prev,
                to: e && moment(e._d).format("YYYY-MM-DD"),
            }));
        }
    };

    useEffect(() => {
        const { state } = props.location;
        if (state === "Material Journey") {
            setTab(state);
        }
        console.log(props);
        let qParams = [
            { page: 1 },
            { pageSize: 10 },
            { from: moment(date["from"]).format("DD/MM/YYYY") },
            { to: moment(date["to"]).format("DD/MM/YYYY") },
        ];

        console.log(qParams);
        getStockVariance(qParams);
    }, []);

    const loadMaterialcode = async (search, loadedOptions, { page }) => {
        let qParams = [{ page: page }, { pageSize: 5 }, { material: search }];
        let data = await getMaterialCode(qParams).then((res) => {
            console.log(res, "resul");
            return {
                options:
                    res.data.data &&
                    res.data.data.map((item) => {
                        return {
                            key: item._id,
                            value: item.material,
                            label: upperFirst(item.material),
                        };
                    }),
                totalPage:
                    parseInt(15 * res.data.data.length || 0) /
                    parseInt(15 || 0),
            };
        });
        return {
            options: data ? data.options : [],
            hasMore: data.totalPage > page ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };

    const toggleImage = (type) => {
        setImage(!image);
        if (type == "ascending") {
            array &&
                array.sort((a, b) => {
                    let fa = a.storage_location.type.toLowerCase(),
                        fb = b.storage_location.type.toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
        } else {
            array &&
                array.sort((a, b) => {
                    let fa = a.storage_location.type.toLowerCase(),
                        fb = b.storage_location.type.toLowerCase();
                    if (fa < fb) {
                        return 1;
                    }
                    if (fa > fb) {
                        return -1;
                    }
                    return 0;
                });
        }

        console.log(array, "sortedArray");
    };

    const handlePageChange = (page, limit) => {
        setPage(page);
        setLimit(limit);
        // setPageSize(pageSize);
        let qParams = [
            { page: page },
            { pageSize: limit },
            { from: moment(date["from"]).format("DD/MM/YYYY") },
            { to: moment(date["to"]).format("DD/MM/YYYY") },
        ];
        getStockVariance(qParams);
    };

    console.log(page);

    const changeDate = async (dates, dateString) => {
        console.log(dates);

        if (dates) {
            setDate((prev) => ({
                ...prev,
                from: moment(dates[0]._d),
                to: moment(dates[1]._d),
            }));
            let qParams = [
                { page: 1 },
                { pageSize: 10 },
                { from: moment(dates[0]._d).format("DD/MM/YYYY") },
                { to: moment(dates[1]._d).format("DD/MM/YYYY") },
            ];
            getStockVariance(qParams);
        }

        if (!dates) {
            setDate((prev) => ({
                ...prev,
                from: moment(today),
                to: moment(today),
            }));
            let qParams = [
                { page: 1 },
                { pageSize: 10 },
                { from: moment(dates[0]._d).format("DD/MM/YYYY") },
                { to: moment(dates[1]._d).format("DD/MM/YYYY") },
            ];

            console.log(qParams);
            getStockVariance(qParams);
        }
    };

    console.log(date);
    console.log(array);

    const getStockVariance = async (qParams) => {
        setLoading(true);
        await getStockVarianceList(qParams)
            .then((res) => {
                console.log(res, "stock");
                setArray(
                    res && res.data.data.length != 0 && res.data.data.Result
                );
                setLoading(false);
                setTotalPage(
                    res.data.data.length != 0 && res.data.data.TotalPage * 10
                );
            })
            .catch((err) => {
                setLoading(false);
                const { errorMessage, errors } = handleErrorResponse(err);
                message.error(errorMessage);
            });
    };

    const stockVarianceDownload = async () => {
        setLoading(true);
        let fromDt = moment(date["from"]).format("DD-MM-YYYY");
        let toDt = moment(date["to"]).format("DD-MM-YYYY");
        await getStockVarianceDownload(
            date["from"].format("DD/MM/YYYY"),
            date["to"].format("DD/MM/YYYY")
        )
            .then((res) => {
                message.success("File downloaded successfully");
                let blob = new Blob([res.data], { type: "text/csv" });
                saveAs(blob, `doc.csv`);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                const { errorMessage, errors } = handleErrorResponse(err);
                message.error(errorMessage);
            });
    };

    const stockVarianceTbody =
        array &&
        array.map((item, key) => {
            return (
                <tr>
                    <td>
                        {item.itemId ? item.itemId : "N/A"} <br />
                        {item.itemName ? item.itemName : "N/A"}{" "}
                    </td>
                    <td>{item.batchId ? item.batchId : "N/A"}</td>
                    <td>{item.material_group ? item.material_group : "N/A"}</td>
                    <td>{item.material_type ? item.material_type : "N/A"}</td>
                    <td>{item.uom ? item.uom : "N/A"}</td>
                    <td>
                        {item &&
                        item.storage_location &&
                        item.storage_location.type
                            ? item.storage_location.type
                            : "N/A"}
                    </td>
                    <td>
                        {item &&
                            item.storage_location &&
                            item.storage_location.variance &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ] &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ].system_qty}
                    </td>
                    <td>
                        {item &&
                            item.storage_location &&
                            item.storage_location.variance &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ] &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ].physical_qty}
                    </td>
                    <td>
                        {item &&
                            item.storage_location &&
                            item.storage_location.variance &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ] &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ].variance_qty}
                    </td>
                    <td>
                        {item &&
                            item.storage_location &&
                            item.storage_location.variance &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ] &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ].variance_percent_qty}
                    </td>
                    <td>
                        {item &&
                            item.storage_location &&
                            item.storage_location.variance &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ] &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ].system_value}
                    </td>
                    <td>
                        {item &&
                            item.storage_location &&
                            item.storage_location.variance &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ] &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ].physical_value}
                    </td>
                    <td>
                        {item &&
                            item.storage_location &&
                            item.storage_location.variance &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ] &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ].varaince_value}
                    </td>
                    <td>
                        {item &&
                            item.storage_location &&
                            item.storage_location.variance &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ] &&
                            item.storage_location.variance[
                                item.storage_location.variance.length - 1
                            ].variance_percent_qty}
                    </td>
                </tr>
            );
        });

    console.log(stockVarianceTbody);

    const tabHandler = (key) => {
        console.log(key);
        setTab(key);
    };

    return (
        <div id="content">
            <PrimaryHeader title={"Reports"} />
            <Spin indicator={antIcon} spinning={loading}>
                <div className="mt-5">
                    <Tabs activeKey={tab} onChange={tabHandler}>
                        <TabPane tab={"Stock Variance"} key={"Stock Variance"}>
                            <div className="d-flex">
                                <div className="d-flex">
                                    <div
                                        className="row pl-3 date_picker"
                                        //   style={{ width: "280px", height: "40px" }}
                                    >
                                        <RangePicker
                                            onChange={changeDate}
                                            value={[
                                                moment(date["from"]),
                                                moment(date["to"]),
                                            ]}
                                            format={"MMM DD dddd,YYYY"}
                                        />
                                    </div>
                                    {/* <div>
                <img src={calender} alt="" style={{height:"40px", marginLeft:"40px"}} />
                </div> */}
                                </div>
                                <div
                                    className="cursor"
                                    style={{ marginLeft: "auto" }}
                                >
                                    <button
                                        className="btn btn-outline-secondary ml-4"
                                        onClick={stockVarianceDownload}
                                    >
                                        <span htmlFor="" className="mt-1">
                                            {"Download"}
                                        </span>
                                        <img
                                            src={Download}
                                            alt=""
                                            className="ml-2"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className="report_table">
                                <div className="table-wrapper">
                                    <table className="table table-responsive">
                                        <thead className="remove-border-top">
                                            <tr>
                                                <th scope="col">
                                                    {"Material"}
                                                </th>
                                                <th scope="col">
                                                    {"Batch ID"}
                                                </th>
                                                <th scope="col">
                                                    {"Material Group"}
                                                </th>
                                                <th scope="col">
                                                    {"Material Type"}
                                                </th>
                                                <th scope="col">{"UOM"}</th>
                                                <th scope="col">
                                                    {"Storage Location"}
                                                    <span
                                                        className=""
                                                        onClick={
                                                            !image
                                                                ? () =>
                                                                      toggleImage(
                                                                          "ascending"
                                                                      )
                                                                : () =>
                                                                      toggleImage(
                                                                          "descending"
                                                                      )
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                !image
                                                                    ? ChevDown
                                                                    : ChevUp
                                                            }
                                                            alt=""
                                                            className="ml-2"
                                                            style={{
                                                                width: "10px",
                                                            }}
                                                        />
                                                    </span>
                                                </th>
                                                <th scope="col">
                                                    {"System QTY"}
                                                </th>
                                                <th scope="col">
                                                    {"Physical QTY"}
                                                </th>
                                                <th scope="col">
                                                    {"Variance in Qty"}
                                                </th>
                                                <th scope="col">
                                                    {"Variance(%) in Qty"}
                                                </th>
                                                <th scope="col">
                                                    {"System Value"}
                                                </th>
                                                <th scope="col">
                                                    {"Physical Value"}
                                                </th>
                                                <th scope="col">
                                                    {"Variance in Value"}
                                                </th>
                                                <th scope="col">
                                                    {"Variance(%) in Value"}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {stockVarianceTbody &&
                                                stockVarianceTbody}
                                        </tbody>
                                    </table>
                                    {stockVarianceTbody.length === 0 && (
                                        <div className="no-data">
                                            <p>{"No Data Available"}</p>
                                        </div>
                                    )}
                                </div>
                                <div className="pagination">
                                    {totalPage != 0 && (
                                        <Pagination
                                            defaultCurrent={1}
                                            current={page}
                                            total={totalPage}
                                            defaultPageSize={limit}
                                            showSizeChanger={false}
                                            onChange={(page, limit) =>
                                                handlePageChange(page, limit)
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </TabPane>
                        <TabPane
                            tab={"Material Journey"}
                            key={"Material Journey"}
                        >
                            <div className="material_card mt-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stock_title">
                                            <label
                                                htmlFor=""
                                                className="border_left"
                                            >
                                                {"Search Stock "}
                                            </label>
                                        </div>
                                        <div className="mt-4">
                                            <div className="stock_labels">
                                                <label htmlFor="">
                                                    {"Material Code/Name"}
                                                </label>
                                            </div>
                                            <div
                                                className=""
                                                style={{ width: "400px" }}
                                            >
                                                <AsyncPaginate
                                                    className="basic-multi-select"
                                                    additional={{
                                                        page: 1,
                                                    }}
                                                    loadOptions={debounce(
                                                        loadMaterialcode,
                                                        500
                                                    )}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    closeMenuOnSelect={true}
                                                    value={
                                                        values["materialcode"]
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(e, "async")
                                                    }
                                                    loadingMessage={() =>
                                                        "No code/name Found"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="stock_labels">
                                                <label htmlFor="">
                                                    {"Date Range"}
                                                </label>
                                                <br />
                                                <div className="d-flex">
                                                    <div
                                                        style={{
                                                            width: "180px",
                                                        }}
                                                    >
                                                        <label
                                                            htmlFor=""
                                                            className="mt-2"
                                                        >
                                                            {"From"}
                                                        </label>
                                                        <div className="row pl-3 report_datepicker">
                                                            <DatePicker
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        e,
                                                                        "from"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "160px",
                                                        }}
                                                        className="ml-5"
                                                    >
                                                        <label
                                                            htmlFor=""
                                                            className="mt-2"
                                                        >
                                                            {"To"}
                                                        </label>
                                                        <div
                                                            className="row"
                                                            id="report_datepicker"
                                                        >
                                                            <DatePicker
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        e,
                                                                        "to"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <button
                                                className="btn btn-primary"
                                                onClick={
                                                    redirectToJourneyReports
                                                }
                                                disabled={
                                                    values["materialCode"] ==
                                                        "" ||
                                                    values["materialCode"] ==
                                                        null ||
                                                    values["from"] == "" ||
                                                    values["from"] == null ||
                                                    values["to"] == "" ||
                                                    values["to"] == null
                                                }
                                                // disabled={
                                                //     !Object.values(values).every(
                                                //         (item) => {
                                                //             if (item != "") {
                                                //                 return true;
                                                //             }
                                                //         }
                                                //     )
                                                // }
                                            >
                                                {"Search"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </Spin>
        </div>
    );
}
