import React from "react";
import "./toast.scss"
export default function Toast_msg({ category, message, dismiss }) {
  return (
    <div className={`Toast Toast--${category}`}>
      <main className="Toast__message">
        <header className="Toast__message-category">{category}</header>
        <p className="Toast__message-text">{message}</p>
      </main>
      <span
        className={`Toast__button Toastbtn--${category}`}
        type="button"
        onClick={dismiss}
      >
        Dismiss
      </span>
    </div>
  );
}
