import React, { Component, Fragment } from "react";
import "./modal.scss";
import cross from "../../images/cross.svg";
import profilePic from "../../images/user-logo.svg";
import edit from "../../images/edit.svg";
import trash from "../../images/trash.svg";

class CustomerDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { openCustomerDetailsModal } = this.props;
    return (
      <React.Fragment>
        <div className="overlay"></div>
        <div className="customer-modal-container">
          <div className="customer-details">
            <div className="cross-button">
              {" "}
              <img
                src={cross}
                onClick={openCustomerDetailsModal}
                className="cross-img"
              />
              <img src={edit} alt="edit" className="edit btns" />
              <img src={trash} alt="trash" className="dlt btns" />
            </div>
            <div className="profilePic">
              <img src={profilePic} />
              <p>Carter Stanton</p>
            </div>
          </div>

          <div className="other-details">
            <div className="rectangle first">
              <div className="name"> Customer Type </div>
              <div className="description">General Trade</div>
            </div>
            <div className="rectangle">
              <div className="name">GSTIN</div>
              <div className="description">32563287</div>
            </div>
            <div className="rectangle">
              <div className="name">Email Address </div>
              <div className="description">carter.stan@gmail.com</div>
            </div>

            <div className="rectangle">
              <div className="name">Phone</div>
              <div className="description">+91 978 1356 758</div>
            </div>
            <div className="rectangle">
              <div className="name">Address</div>
              <div className="description">
                12/3 KannanaggalaVill. Whitefield Bengaluru 560038
              </div>
            </div>
            <div className="recent-invoices">Recent Invoices</div>

            {/* loop through all invoices */}
            <div className="invoice-container">
              <div className="rectangle ">
                <div className="name">
                  <span>Invoice # 252323</span>
                  <br />
                  <span className="lineHeight">4th May, 2020</span>
                </div>
                <div className="description right-align">
                  <span className="invoice-status">Pending</span>
                  <br />
                  <span className="lineHeight">₹12,456.00</span>
                </div>
              </div>
              <div className="rectangle ">
                <div className="name">
                  <span>Invoice # 252329</span>
                  <br />
                  <span className="lineHeight">4th May, 2020</span>
                </div>
                <div className="description right-align">
                  <span className="invoice-status">Pending</span>
                  <br />
                  <span className="lineHeight">₹22,956.00</span>
                </div>
              </div>
              <div className="rectangle">
                <div className="name">
                  <span>Invoice # 952323</span>
                  <br />
                  <span className="lineHeight">8th May, 2020</span>
                </div>
                <div className="description right-align">
                  <span className="invoice-status">Pending</span>
                  <br />
                  <span className="lineHeight">₹92,456.00</span>
                </div>
              </div>
            </div>

            <span className="view-profile">View Profile</span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomerDetails;
