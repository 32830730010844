import generateActionTypes from "../utils/generateActionTypes";
export const actionTypes = generateActionTypes(
  "GET_SALES_MANAGER_DETAILS",
  "GET_ALL_ASMS",
  "GET_ALL_SALESMEN"
);
// get all asms data
export function getAsms(data) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.GET_ALL_ASMS,
      data,
    });
  };
}

// get asm details data
export function asmData(data) {
  return (dispatch) => {
    return dispatch({ type: actionTypes.GET_SALES_MANAGER_DETAILS, data });
  };
}
// get all salesmen data
export function getSalesmen(data) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.GET_ALL_SALESMEN,
      data,
    });
  };
}
