import React, { Component } from "react";
import "./login.scss";
import { RightSideDiv, TopDiv } from "./commondivs";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  verifyResetToken,
  resetPasswordForFirstTime,
} from "../../services/loginServices";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class ResetFirstPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      newPassword: "",
      confirmPassword: "",
      oldPassword: "",
    };
  }

  componentDidMount() {
    const { match } = this.props;
    let token = match.params.token || undefined,
      email = match.params.email || "prasun.jaiswal@waycool.in";

    // if token is present
    if (token) {
      verifyResetToken({ email, token }).catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
    }
  }
  checkFlag = () => {
    let { newPassword, confirmPassword, oldPassword } = this.state;
    if (newPassword === "" || (confirmPassword === "" && oldPassword === "")) {
      return false;
    } else {
      return true;
    }
  };
  resetPassword = () => {
    let { newPassword, confirmPassword, oldPassword } = this.state;
    const { match } = this.props;
    let token = match.params.token || undefined,
      email = match.params.email || "prasun.jaiswal@waycool.in";
    if (newPassword === "" || confirmPassword === "" || oldPassword === "") {
      message.warning("Field/Fields can not be empty!");
    }
    if (newPassword != "" && confirmPassword != "" && oldPassword != "") {
      if (newPassword === confirmPassword && newPassword != oldPassword) {
        // api call
        resetPasswordForFirstTime(
          {
            existingPassword: oldPassword,
            password: newPassword,
            confirmPassword,
          },
          {
            email,
            token,
          }
        )
          .then((res) => {
            message.info("Password reset Successfully");
            localStorage.setItem("firstTimeLogin", false);
            window.location.href = "/login";
          })
          .catch((err) => {
            this.setState({ loading: false });
            const { errorMessage, errors } = handleErrorResponse(err);
            message.error(errorMessage);
          });
      } else if (newPassword != confirmPassword) {
        message.warning("New Password and Confirm Password are not same!");
      }
    }
  };
  onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
    });
  };
  render() {
    let flag = this.checkFlag();
    let {
      loading,
      firstTimeLogin,
      newPassword,
      confirmPassword,
      oldPassword,
    } = this.state;
    return (
      <div className="login-form-container">
        <RightSideDiv message={"Reset password"} />
        <div className="login-left-div">
          <TopDiv />
          <Spin indicator={antIcon} spinning={loading}>
            {!firstTimeLogin && (
              <div className="form">
                <input
                  name="oldPassword"
                  className="email"
                  placeholder="Old password"
                  type="password"
                  onChange={this.onChangeHandler}
                />
                <input
                  name="newPassword"
                  className="email"
                  placeholder="new password"
                  type="password"
                  onChange={this.onChangeHandler}
                  minLength="6"
                />
                <input
                  name="confirmPassword"
                  className="email"
                  placeholder="confirm password"
                  type="password"
                  onChange={this.onChangeHandler}
                  minLength="6"
                />

                <button className="login-button" onClick={this.resetPassword}>
                  Change Password
                </button>
              </div>
            )}
            {firstTimeLogin && (
              <div className="form">
                <p className="title">Existing Password</p>
                <input
                  name="existingPassword"
                  className="email"
                  placeholder="Existing Password"
                  type="password"
                  onChange={this.onChangeHandler}
                />
                <input
                  name="newPassword"
                  className="email"
                  placeholder="new password"
                  type="password"
                  onChange={this.onChangeHandler}
                />
                <input
                  name="confirmPassword"
                  className="email"
                  placeholder="confirm password"
                  type="password"
                  onChange={this.onChangeHandler}
                />
                <button className="login-button" onClick={this.resetPassword}>
                  Change Password
                </button>
              </div>
            )}
          </Spin>
        </div>
      </div>
    );
  }
}

export default ResetFirstPassword;
