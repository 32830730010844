import axios from "axios";
// import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";

const APIConfig = {
  base_url: "http://13.76.40.217:3030/",
};

export const getAPIList = async () => {
  let request = await axios.get(APIConfig.base_url + "v1/config/goFrugal-api", {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};

export const getList = async (qParams, searchURL) => {
  if (searchURL != "") {
    let qUrl = getQparams(qParams);
    let request = await axios.get(APIConfig.base_url + searchURL + qUrl, {
      headers: {
        "x-access-token": getAuthToken(),
      },
    });
    return request;
  }
  return "";
};

export const postValues = async (body) => {
  console.log("body", body);
  let request = await axios.post(
    APIConfig.base_url + "api/v1/customer/goFrugal/manual/sync",
    body,
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};
