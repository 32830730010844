import React, { Component } from "react";
import "./modal.scss";
import cross from "../../images/cross.svg";
import WrappedSalesmanMap from "./../team/customerLocation/salesmanMap";

class SalesmanCustomerMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { details, modalLoading } = this.props;

    return (
      <React.Fragment>
        <div className="overlay">
          <div className="salesmanmap-modal-container ">
            <div className="customer-details">
              <div className="cross-button">
                {" "}
                <img
                  src={cross}
                  onClick={this.props.displayModal}
                  className="cross-img"
                />
              </div>
              <div style={{ width: "75vw", height: "65vh" }}>
                <WrappedSalesmanMap
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAscjPuHsmxseZEeitNZp3SrZ8kl7Z1EX0"
                  loadingElement={
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  }
                  containerElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  mapCenter={this.props.mapdetails}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SalesmanCustomerMap;
