// import { ActionTypes } from "../action/manageShippingActionTypes";
import { actionTypes } from "../action/manageShippingaction";

const initialState = {
  minimumOrderValue: "",
  customerList: [],
  showOrderAmountAndShipppingCharge: [],
  shippingCharges: {
    ShippingOrderAmt: "",
    shippingCharge: "",
  },
  listOfEceptionalCustomers: [],
  exceptionalCustomerTable: [],
  loading: [],
};

const stopLoading = (type, loadArray) => {
  // console.log(type, loadArray);
  return loadArray.filter((load) => load !== type);
};

export const manageShippingReducer = (state = initialState, action) => {
  const { type, data, load } = action;
  switch (type) {
    case actionTypes.LOADING:
      return { ...state, loading: [...state.loading, load] };

    case actionTypes.STOP_LOADING:
      return { ...state, loading: stopLoading(load, state.loading) };

    case actionTypes.MINIMUM_ORDER_VALUE:
      return { ...state, minimumOrderValue: data };

    case actionTypes.MINIMUM_SHIPPING_CHARGES:
      return { ...state, shippingCharges: { ...action.data } };

    case actionTypes.GET_CUSTOMER_LIST:
      const actionData = action?.data?.filter((item) => !!item);
      return { ...state, customerList: [...actionData] };

    case actionTypes.GET_LIST_OF_EXEPTIONAL_CUSTOMER_SHIPPING:
      return {
        ...state,
        listOfEceptionalCustomers: [...data],
        loading: stopLoading(load, state.loading),
      };
    case actionTypes.GET_LIST_0F_EXCEPTIONAL_CUSTOMER_TABLE:
      return {
        ...state,
        exceptionalCustomerTable: [...data],
        loading: stopLoading(type, state.loading),
      };

    // case actionTypes.SHOW_ORDERAMT_SHIPPINGCHARGE:
    //   return {
    //     ...state,
    //     showOrderAmountAndShipppingCharge: [...action.data],
    //     loading: stopLoading(type, state.loading),
    //   };

    default:
      return state;
  }
};
