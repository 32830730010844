import { Typography } from "antd";
import React from "react";

function ItemsProceesing({ title, icon }) {
  return (
    <div
      style={{
        width: "96%",
        padding: "10px",
        background: "#FDF3F0",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        marginLeft: "22px",
      }}
    >
      <img
        src={icon}
        style={{ width: "18px", height: "18px", marginLeft: "23px" }}
      />
      <Typography
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "28px",
          marginLeft: "20px",
          color: " #4D4E5C",
        }}
      >
        {title}
      </Typography>
    </div>
  );
}

export default ItemsProceesing;
