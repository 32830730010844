import React, { useRef } from "react";
import PrimaryHeader from "../../header/PrimaryHeader";
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import DigitalSignature from "./DigitalSignature";
import Pod from "./Pod";
import TruckImage from "./TruckImage";
import TruckReceipt from "./TruckReceipt";
import { useHistory, useLocation } from "react-router-dom";
// import { useLocation, useHistory } from "react-router-dom";

import {
  getInvoiceDetailsService,
  getOTPMenuConfig,
} from "../../../services/otpServices/otpServices";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { handleErrorResponse } from "../../../utils/Request";
import { useSelector, useDispatch } from "react-redux";

import ReactToPrint from "react-to-print";
import {
  getIndividualOrderDetail,
  getOTPConfig,
} from "../../../action/IndividualOrderDetails";

import { ComponentToPrint } from "./ComponentToPrint";

import "./invoice.scss";

import moment from "moment";
import OtpComp from "./Otp";

function Invoice() {
  let history = useHistory();
  const dispatch = useDispatch();

  const componentRef = useRef();
  // querry params
  // const params = useParams();
  // const soId = params?.soId;
  // const InvoiceId = params?.InvoiceId;
  // const Otp = params?.Otp;
  // const customerID = params?.customerID;

  const {
    state: { soId, InvoiceId, Otp, customerID, shipment, shipmentindex },
  } = useLocation();
  console.log(shipment, "shipmentindex");
  // const locData = useLocation();
  // const invData = locData.state;
  const [defaultTab, setDefaultTab] = useState(Otp);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  // const [config, setConfig] = useState({});
  const [invcDetails, setInvcDetails] = useState({});
  const [invID, setInvID] = useState("");
  const { IndividualOrderDetails, ConfigData } = useSelector(
    (state) => state.individualOrderDetailsReducer
  );
  // const invoiceIDD =
  //   IndividualOrderDetails &&
  //   shipment?.filter((invId) => invId.invoiceId == InvoiceId);
  // console.log(invoiceIDD, "invoiceIDD");
  const invoicedetails =
    IndividualOrderDetails &&
    IndividualOrderDetails[0]?.orderpacked?.invoicedetails?.filter(
      (invId) => invId.mginvoiceId == InvoiceId
    );
  const headerInvoiceID = invoicedetails && invoicedetails[0]?.invoiceid;
  //const [podDetail, setPodDetail] = useState([]);
  //const [digitalsign, setDigitalSign] = useState();

  useEffect(() => {
    //getConfig();
    // getInvoicedetails();
    if (invoicedetails === undefined || ConfigData === undefined) {
      dispatch(getIndividualOrderDetail(soId, customerID));
      dispatch(getOTPConfig());
    }
  }, []);

  const onChangeTab = (tabIndex) => {
    setDefaultTab(tabIndex);
  };
  // const getConfig = async () => {
  //   try {
  //     const res = await getOTPMenuConfig();
  //     const resData = res?.data;
  //     const { data } = resData;
  //     setConfig(data[0]);
  //   } catch (error) {
  //     const { errorMessage, errors } = handleErrorResponse(error);
  //   }
  // };
  // const getInvoicedetails = async () => {
  //   try {
  //     const res = await getInvoiceDetailsService(InvoiceId, customerID);
  //     const resData = res?.data;
  //     const { result } = resData;
  //     const podDetail = result.PodDetails;
  //     const digitalsign = result.digitalSignature;
  //     setInvcDetails(result);
  //     setInvID("invoice #" + result[0]?.invoiceNo);
  //     setPodDetail(podDetail);
  //     setDigitalSign(digitalsign);
  //   } catch (error) {
  //     const { errorMessage, errors } = handleErrorResponse(error);
  //   }
  // };

  return (
    <div
      id="content"
      style={{
        display: "flex",

        flexDirection: "column",
      }}
    >
      <div>
        <div>
          <PrimaryHeader
            title={"invoice #" + shipment?.invoiceNo}
            backgroundColor="#f5f7fa"
          />
        </div>

        <Tabs
          defaultActiveKey={defaultTab}
          activeKey={defaultTab}
          onChange={onChangeTab}
        >
          {/* <Tabs.TabPane tab="Invoice" key="1">
            <div
              style={{
                height: "100%",
                backgroundColor: "#f5f7fa",
              }}
            >
              <div className="m-3 ml-2">
                <div className="m-3">
                  <span>
                    <Button type="link" onClick={() => history.goBack()}>
                      <ArrowLeftOutlined
                        style={{ color: "#5078F2", fontSize: "20px" }}
                      />
                    </Button>
                  </span>
                  <span>
                    <Button
                      type="link"
                      onClick={() => history.goBack()}
                      style={{ color: "#5078F2" }}
                    >
                      Back
                    </Button>
                  </span>
                </div>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      style={{
                        height: "42px",
                        width: "150px",
                        padding: "9px",
                        alignItems: "center",
                        backgroundColor: "#f5f7fa",
                        pointerEvents: isDataLoaded ? "auto" : "none",
                        cursor: isDataLoaded ? "pointer" : "not-allowed",
                      }}
                    >
                      Download
                      <DownloadOutlined
                        style={{
                          fontSize: "20px",
                          alignItems: "center",
                          marginLeft: "18px",
                        }}
                      />
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
                <ComponentToPrint
                  ref={componentRef}
                  setIsDataLoaded={setIsDataLoaded}
                />
              </div>
            </div>
          </Tabs.TabPane> */}
          {ConfigData.deDigitalSignature == true ? (
            <Tabs.TabPane tab="Digital Signature" key="2">
              <div>
                <span>
                  <Button type="link" onClick={() => history.goBack()}>
                    <ArrowLeftOutlined
                      style={{ color: "#5078F2", fontSize: "20px" }}
                    />
                  </Button>
                </span>
                <span>
                  <Button
                    type="link"
                    onClick={() => history.goBack()}
                    style={{ color: "#5078F2" }}
                  >
                    Back
                  </Button>
                </span>
                <DigitalSignature shipment={shipmentindex} />
                {/* <DigitalSignature invData={invData} /> */}
              </div>
            </Tabs.TabPane>
          ) : (
            ""
          )}
          <Tabs.TabPane tab="POD" key="3">
            <span>
              <Button type="link" onClick={() => history.goBack()}>
                <ArrowLeftOutlined
                  style={{ color: "#5078F2", fontSize: "20px" }}
                />
              </Button>
            </span>
            <span>
              <Button
                type="link"
                onClick={() => history.goBack()}
                style={{ color: "#5078F2" }}
              >
                Back
              </Button>
            </span>
            <Pod shipment={shipmentindex} />
          </Tabs.TabPane>
          {ConfigData.deOtpStatus == true ? (
            <Tabs.TabPane tab="OTP" key="4">
              <span>
                <Button type="link" onClick={() => history.goBack()}>
                  <ArrowLeftOutlined
                    style={{ color: "#5078F2", fontSize: "20px" }}
                  />
                </Button>
              </span>
              <span>
                <Button
                  type="link"
                  onClick={() => history.goBack()}
                  style={{ color: "#5078F2" }}
                >
                  Back
                </Button>
              </span>
              <OtpComp shipment={shipmentindex} />
            </Tabs.TabPane>
          ) : (
            ""
          )}
          <Tabs.TabPane tab="Truck Image" key="5">
            <span>
              <Button type="link" onClick={() => history.goBack()}>
                <ArrowLeftOutlined
                  style={{ color: "#5078F2", fontSize: "20px" }}
                />
              </Button>
            </span>
            <span>
              <Button
                type="link"
                onClick={() => history.goBack()}
                style={{ color: "#5078F2" }}
              >
                Back
              </Button>
            </span>
            <TruckImage shipment={shipmentindex} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Truck Receipt" key="6">
            <span>
              <Button type="link" onClick={() => history.goBack()}>
                <ArrowLeftOutlined
                  style={{ color: "#5078F2", fontSize: "20px" }}
                />
              </Button>
            </span>
            <span>
              <Button
                type="link"
                onClick={() => history.goBack()}
                style={{ color: "#5078F2" }}
              >
                Back
              </Button>
            </span>
            <TruckReceipt shipment={shipmentindex} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default Invoice;
