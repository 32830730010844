import React from "react";
import { Divider, Typography, Table, Button } from "antd";
import {
  getIndividualOrderDetail,
  getOTPConfig,
} from "../../../action/IndividualOrderDetails";
import { useSelector, useDispatch } from "react-redux";

const SubHeader = ({ title }) => (
  <div
    className="m-3"
    style={{
      display: "flex",
      flexDirection: "row",
      marginLeft: "33px",
      marginTop: "10px",
      alignItems: "center",
    }}
  >
    <span>
      <Divider
        type="vertical"
        style={{
          border: "2px solid #5078F2",
          height: "24px",
        }}
      />
    </span>
    <span>
      <Typography variant="h4" style={{ fontWeight: "bold", fontSize: "18px" }}>
        {title}
      </Typography>
    </span>
  </div>
);
export const CustomerDetails = () => {
  const { IndividualOrderDetails, ConfigData } = useSelector(
    (state) => state.individualOrderDetailsReducer
  );

  // const invoicetabledata =
  //   IndividualOrderDetails[0]?.orderpacked.invoicedetails;
  // const InvoiceID = IndividualOrderDetails[0]?.orderpacked.invoiceId;
  // const deliverDates = IndividualOrderDetails[0]?.salesorderdatentime;
  // const ordereditems = IndividualOrderDetails[0]?.ordereditems;
  // const orderPacked = IndividualOrderDetails[0]?.orderpacked.orderpackedstatus;
  const customerName = IndividualOrderDetails[0]?.customerinfo?.customername;
  const customerlogo = IndividualOrderDetails[0]?.customerinfo?.customerpic;
  const location = IndividualOrderDetails[0]?.customerinfo?.location;
  const plant = IndividualOrderDetails[0]?.customerinfo?.plant;
  const categaries = IndividualOrderDetails[0]?.customerinfo?.salescategory;

  return (
    <div className="m-3">
      <div className="p-3" style={{ backgroundColor: " #FFFFFF" }}>
        <SubHeader title="Customer Details" />
        <div className="d-flex p-3 px-5">
          <div className="d-flex flex-row align-items-center">
            {customerlogo ? (
              <img src={customerlogo} width={48} className="rounded" />
            ) : (
              <div
                style={{
                  width: 70,
                  height: 70,
                  backgroundColor: "#ccc",
                  borderRadius: 50,
                }}
              ></div>
            )}

            <div
              className="d-flex flex-column ml-4 justify-content-between"
              style={{ gap: 6 }}
            >
              <Typography
                style={{
                  color: "#3A4960",
                  fontSize: "20px",
                  alignItems: "center",
                  lineHeight: "24px",
                  fontWeight: 600,
                }}
              >
                {customerName}
              </Typography>
              <Typography
                style={{
                  color: "#7A7A7A;",
                  fontSize: "14px",
                  lineHeight: "17px",
                  fontWeight: 400,
                  padding: "5px 0px 0px 0px",
                }}
              >
                ID - {IndividualOrderDetails[0]?.goFrugalId}
              </Typography>
            </div>
          </div>
          <div
            className="d-flex"
            style={{ flex: 0.2, justifyContent: "flex-end" }}
          >
            <Divider
              type="vertical"
              style={{
                height: 100,
                border: "1px solid #ECEDEE",
              }}
            />
          </div>
          <div
            className="d-flex align-items-center"
            style={{ flex: 0.8, justifyContent: "space-around", gap: "20px" }}
          >
            <span style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                style={{
                  color: "#7A7A7A",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Plant
              </Typography>
              <Typography
                style={{
                  color: "#3A4960",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {plant}
              </Typography>
            </span>
            <span style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                style={{
                  color: "#7A7A7A",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Sales Category
              </Typography>
              <Typography
                style={{
                  color: "#3A4960",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {categaries}
              </Typography>
            </span>
            <span style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                style={{
                  color: "#7A7A7A",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Location
              </Typography>
              <Typography
                style={{
                  color: "#3A4960",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                {location}
              </Typography>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
