import React from "react";
import Delete from "../../images/trash.svg";
import plus from "../../images/gn-plus.svg";
import cross from "../../images/cross.svg";
export const Options = ({
  displayMultiselector,
  closeOptionModal,
  chosenDay,
  selectedCustomersNumber,
  displayMultiselectorStatus,
  showModal,
  displayDeleteDayModal
}) => {
  return (
    <>
      {!displayMultiselectorStatus && (
        <div className="option-modal-div">
          <div>
            <li className="option-list" onClick={()=>{displayDeleteDayModal(chosenDay)}}>
              {" "}
              <span>Delete Day</span>{" "}
              <img
                src={Delete}
                alt="Delete"
                className="li-icon"
                title="delete day"
               
              />
            </li>
            <li
              className="option-list"
              onClick={() => displayMultiselector(chosenDay)}
            >
              {" "}
              <span>Delete Customers</span>{" "}
              <img
                src={Delete}
                alt="Delete"
                className="li-icon"
                title="delete customers"
               
              />
            </li>
          </div>
          <img
            src={cross}
            alt="cross"
            className="li-cross-icon"
            onClick={closeOptionModal}
          />
        </div>
      )}

      {displayMultiselectorStatus && (
        <div className="option-modal-div border">
          <li className="option-list">
            {" "}
            <span>{selectedCustomersNumber && selectedCustomersNumber} Selected </span>{" "}
            <img
              src={Delete}
              alt="Delete"
              className="li-icon"
              title="delete selected day"
              onClick={showModal}
            />
          </li>
          <img
            src={cross}
            alt="cross"
            className="li-cross-icon"
            onClick={closeOptionModal}
          />
        </div>
      )}
    </>
  );
};
