import React, { Component } from "react";
import "./draftBeatPlan.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { Spin } from "antd";
import activeToggle from "../../images/Toggle.svg";
import inactiveToggle from "../../images/inactive-toggle.svg";
import plus from "../../images/gn-plus.svg";
import clearAll from "../../images/clearAll.svg";
import AddCustomer from "../../images/AddCustomer.svg";
import Group from "../../images/Group.svg";
import info from "../../images/info.svg";
import { getDayName } from "../../utils/common";
import { Options } from "./options";
import RowWithOptions from "./rowWithOptions";
import { AsyncPaginate } from "react-select-async-paginate";
import downArrow from "../../images/down-arrow.svg";
import moment from "moment";
import Dropdown from "../dropdown/dropdown";
import { getProfileDetails } from "../../services/userProfileServices/userProfile";
import {
  getDraftBeatPlanDetailsById,
  addDayToBeatPlan,
  removeDayToBeatPlan,
  addCustomersToBeatPlan,
  getParticularDayDetails,
  editCustomerMappingDaywise,
  publishDraftedBeatplan,
  changeSalesmanOfBeatplan,
  removeCustomersToBeatPlan,
  getMaxTimeToPublishDetails,
  changetoggleStatus,
  getOnboardedCustomerList,
} from "../../services/beatplanServices/beatplan";
import { ShowAdd } from "./addDay";
import ShowList from "./showCustomerList";
import AddCustomerModal from "../modal/addCustomerModal";
import { getAllSalesmen } from "../../services/teamServices/team";
import DailyViewTable from "./dailyViewTable";
import DeleteConfirmation from "../modal/deleteConfirmation";
import _ from "lodash";
import { DragDropContext } from "react-beautiful-dnd";
import { routeOptimisation } from "../../services/routeOptimisation";
import PrimaryHeader from "../header/PrimaryHeader";

class DraftBeatPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stops: [],
      from: {},
      to: {},
      loading: false,
      updatedAt: "",
      publishedTime: "",
      dragging: false,
      showInfoMsg: false,
      dailyViewDragging: false,
      draggableDay: "",
      viewType: "Weekly",
      showTypeStatus: false,
      dayIdForAddingCustomer: "",
      showChangeSalesmanOptionState: false,
      showToggleModal: false,
      toggleBtnStatus: null,
      toggleWarningMsg: "",
      warehouseLocation: {
        name: "N/A",
        lat: undefined,
        lng: undefined,
      },
      daysDetails: [
        {
          id: "",
          day: "sun",
          customers: null,
        },
        {
          id: "",
          day: "mon",
          customers: null,
        },
        {
          id: "",
          day: "tue",
          customers: null,
        },
        {
          id: "",
          day: "wed",
          customers: null,
        },
        {
          id: "",
          day: "thu",
          customers: null,
        },
        {
          id: "",
          day: "fri",
          customers: null,
        },
        {
          id: "",
          day: "sat",
          customers: null,
        },
      ],
      getPublishTimeValue: "",
      showOptionsModal: false,
      showOptionsModalFor: "",
      showAddCustomerModalStatus: false,
      showDeleteCustomerModalStatus: false,
      showClearAllModalStatus: false,
      salesmanPageNo: 1,
      salesmanList: [],
      salesmanLoader: false,
      selectedSalesmanId: "",
      deleteCustomerId: "",
      deleteCustomerOfTheDay: "",
      // deleteView will specify the type of the beatplan(daily or weekly)
      deleteView: "",
      dayIdOfSelectedCustomer: "",
      _customersArr: [],
      customerDetailsPerDay: [],
      dailyViewDayDetails: [],
      displayMultiselector: false,
      multiselectorCustomerArr: [],
      multiselectorModalStatus: false,
      dayIdForMultiSelect: "",
      showDeleteDayModal: false,
      DayToBeDeleted: "",
      showSaveAsDraftBtn: false,
      mapView: false,
      showSaveAsDraftBtnForDailyView: false,
      selectedCustomer: null,
      onboardPage: 1,
      assignedSalesman: null,
      showCustomerDropdown: false,
      onboardedCustomers: [],
      pageMeta: null,
    };
  }
  showInfo = (e) => {
    e.stopPropagation();
    this.setState({
      showInfoMsg: !this.state.showInfoMsg,
    });

    message.info(
      `Any edits/ updates, if needed, to be done by ${this.state.getPublishTimeValue} IST to be reflected in the schedule for that day.`
    );
  };
  showType = () => {
    this.setState({
      showTypeStatus: !this.state.showTypeStatus,
    });
  };
  setType = (e) => {
    this.setState(
      {
        viewType: e.target.innerHTML,
      },
      () => {
        this.setState({
          showTypeStatus: false,
          showSaveAsDraftBtnForDailyView: false,
          showSaveAsDraftBtn: false,
        });
      }
    );
  };
  showOptions = (dayName) => {
    this.setState({
      showOptionsModalFor: dayName,
      showOptionsModal: !this.state.showOptionsModal,
      displayMultiselector: false,
    });
  };
  closeOptionModal = () => {
    this.setState({
      showOptionsModal: false,
      displayMultiselector: false,
      multiselectorCustomerArr: [],
    });
  };
  displayMultiSelector = (day) => {
    let { daysDetails } = this.state;
    let filteredDay = daysDetails.filter((item) => item.day === day);

    let multiSelectCustomersArray = filteredDay[0].customers.map((item) => {
      return { ...item, selected: false };
    });

    this.setState({
      displayMultiselector: !this.state.displayMultiselector,
      multiselectorCustomerArr: multiSelectCustomersArray,
      dayIdForMultiSelect: filteredDay[0].id,
    });
  };

  displayDeleteDayModal = async (day) => {
    this.setState({
      showDeleteDayModal: true,
      DayToBeDeleted: day,
    });
  };
  closeDisplayDeleteDayModal = () => {
    this.setState({
      showDeleteDayModal: false,
      DayToBeDeleted: "",
    });
  };
  removeDay = async () => {
    this.setState({ loading: true });
    let id = this.props.match.params.id;
    let { DayToBeDeleted, daysDetails } = this.state;
    let dayDetails = daysDetails.filter((day) => day.day == DayToBeDeleted);
    let day = dayDetails[0].id;
    await removeDayToBeatPlan(id, day)
      .then((res) => {
        message.success(
          "This day has been removed from the beat plan successfully"
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
          showDeleteDayModal: false,
          DayToBeDeleted: "",
          showOptionsModal: false,
          displayMultiselector: false,
        });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
    await getDraftBeatPlanDetailsById(id)
      .then((res) => {
        let dayMapping = res.data.data.dayMapping;
        this.setState({
          daysDetails: this.compareFunForDay(
            dayMapping,
            daysDetails,
            DayToBeDeleted
          ),
          showDeleteDayModal: false,
          DayToBeDeleted: "",
          showOptionsModal: false,
          displayMultiselector: false,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          showDeleteDayModal: false,
          DayToBeDeleted: "",
          showOptionsModal: false,
          displayMultiselector: false,
        });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  setMultiselectorCustomerArr = (customer) => {
    let { multiselectorCustomerArr } = this.state;
    let filteredArr = multiselectorCustomerArr.map((item) => {
      if (item._id == customer._id) {
        let value = !item.selected;
        return { ...item, selected: value };
      } else {
        return { ...item };
      }
    });
    return filteredArr;
  };
  lengthOfSelectedCustomerArr = () => {
    let { multiselectorCustomerArr } = this.state;
    let length = multiselectorCustomerArr.filter(
      (item) => item.selected == true
    ).length;
    return length;
  };
  handleChangeChk = (customer) => {
    this.setState({
      multiselectorCustomerArr: this.setMultiselectorCustomerArr(customer),
      check: !this.state.check,
    });
  };
  addDayFunction = (dayName) => {
    let id = this.props.match.params.id;
    let day = dayName;
    addDayToBeatPlan(id, day).then((res) => {
      message.success("This day added successfully");
      this.getBeatPlanDetails();
    });
  };
  showChangeSalesmanOption = () => {
    this.setState(
      {
        showChangeSalesmanOptionState:
          !this.state.showChangeSalesmanOptionState,
      },
      () => {
        // this.getSalesmenFunction();
      }
    );
  };

  // NEED TO REMOVE
  getSalesmenFunction = async () => {
    this.setState({ salesmanLoader: true });
    let { salesmanPageNo } = this.state;
    let qParams = [
      { page: salesmanPageNo },
      { sortBy: "" },
      { search: "" },
      { type: "mapped" },
    ];
    await getAllSalesmen(qParams)
      .then((res) => {
        if (res.data.data.results.length == 0) {
          message.warning("No data available!");
        }
        this.setState({
          salesmanList: res.data.data.results.map((item) => {
            return {
              key: item._id,
              value: item.employeeId,
              label: item.firstName + " " + item.lastName,
            };
          }),

          salesmanLoader: false,
        });
      })
      .catch((err) => {
        this.setState({ salesmanLoader: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  loadOptions = async (search, loadedOptions, { page }) => {
    let qParams = [
      { page: page },
      { sortBy: "createdAt" },
      { search: search },
      { type: "mapped" },
      { status: "active" },
    ];

    let data = await getAllSalesmen(qParams).then((res) => {
      return {
        options:
          res.data.data.results &&
          res.data.data.results.map((item) => {
            return {
              key: item._id,
              value: item._id,
              label: item.fullName,
            };
          }),
        totalPage:
          parseInt(res.data.data.pageMeta.total || 0) /
          parseInt(res.data.data.pageMeta.pageSize || 0),
      };
    });

    return {
      options: data.options,
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  compareFun = (frombk, defaultDetails) => {
    let defaultArr = [...defaultDetails];
    frombk.forEach((bk) => {
      defaultArr.forEach((def) => {
        if (bk.day === def.day) {
          def.id = bk._id;
          def.customers = bk.customers;
        }
      });
    });
    return defaultArr;
  };
  compareFunForDay = (frombk, defaultDetails, DayToBeDeleted) => {
    let defaultArr = [...defaultDetails];
    frombk.forEach((bk) => {
      defaultArr.forEach((def) => {
        if (bk.day === def.day) {
          def.id = bk._id;
          def.customers = bk.customers;
        }
      });
    });

    let newArr = defaultArr.map((item) => {
      if (item.day === DayToBeDeleted) {
        let newItem = item;
        newItem.id = "";
        newItem.customers = null;
        return newItem;
      } else {
        return item;
      }
    });
    return newArr;
  };

  showAddCustomerModal = (id) => {
    this.setState({
      showAddCustomerModalStatus: !this.state.showAddCustomerModalStatus,
      dayIdForAddingCustomer: id,
    });
  };

  addCustomersToBeatplan = (customerArray) => {
    this.setState({ loading: true });
    let { dayIdForAddingCustomer } = this.state;
    let { details } = this.props;
    let beatPlanId = this.props.match.params.id;
    let dayId = dayIdForAddingCustomer;
    let _dayarray = details.dayMapping;
    let selectedDay = _dayarray.filter((day) => day._id === dayId);
    let _customerArray = selectedDay[0].customers;
    //  if customer arr length is 0 then do
    if (_customerArray.length == 0) {
      let customers = customerArray;
      let inputs = {
        customers,
      };
      this.editCustomerMapping(beatPlanId, dayId, inputs);
    }
    // if customer arr length is greater than 0 then do
    else if (_customerArray.length > 0) {
      // create a new array with sequence combining two array
      let arr = _customerArray.map((item) => {
        return { customerId: item.customerId, seq: "" };
      });
      // let finalArr = arr.concat(customerArray);
      let finalArr = _.unionBy(arr, customerArray, "customerId");
      let finalSeqArr = finalArr.map((item, i) => {
        return { ...item, seq: i + 1 };
      });
      let customers = finalSeqArr;
      let inputs = {
        customers,
      };
      this.editCustomerMapping(beatPlanId, dayId, inputs);
    }
    //  call api
  };

  addCustomersToDailyDraftPlan = (customerArray) => {
    this.setState({ loading: true });

    let { details } = this.props;
    let beatPlanId = this.props.match.params.id;
    let { dayIdForAddingCustomer } = this.state;
    let dayId = dayIdForAddingCustomer;
    let _dayarray = details.dayMapping;
    let selectedDay = _dayarray.filter((day) => day._id === dayId);
    let _customerArray = selectedDay[0].customers;
    //  if customer arr length is 0 then do
    if (_customerArray.length == 0) {
      let customers = customerArray;
      let inputs = {
        customers,
      };
      // this.editCustomerMapping(beatPlanId, dayId, inputs);
      // nw
      editCustomerMappingDaywise(beatPlanId, dayId, inputs)
        .then((res) => {
          message.success("Customer list has been updated successfully.");
          // you will get beat plan by day name (send the day name like mon, sun, sat etc.), beatplanid

          this.getDayDetails(selectedDay[0].day, beatPlanId);
          this.getBeatPlanDetails();
          this.setState({
            loading: false,
            showAddCustomerModalStatus: false,
            showDeleteCustomerModalStatus: false,
          });
        })
        .catch((err) => {
          const { errorMessage, errors } = handleErrorResponse(err);
          message.error(errorMessage);
          this.setState({
            loading: false,
            showAddCustomerModalStatus: false,
            showDeleteCustomerModalStatus: false,
          });
        });
    }
    // if customer arr length is greater than 0 then do
    else if (_customerArray.length > 0) {
      // create a new array with sequence combining two array
      let arr = _customerArray.map((item) => {
        return { customerId: item.customerId, seq: "" };
      });
      // let finalArr = arr.concat(customerArray);
      let finalArr = _.unionBy(arr, customerArray, "customerId");
      let finalSeqArr = finalArr.map((item, i) => {
        return { ...item, seq: i + 1 };
      });
      let customers = finalSeqArr;
      let inputs = {
        customers,
      };
      // this.editCustomerMapping(beatPlanId, dayId, inputs);
      editCustomerMappingDaywise(beatPlanId, dayId, inputs)
        .then((res) => {
          message.success("Customer list has been updated successfully.");
          // you will get beat plan by day name (send the day name like mon, sun, sat etc.), beatplanid

          this.getDayDetails(selectedDay[0].day, beatPlanId);
          this.getBeatPlanDetails();
          this.setState({
            loading: false,
            showAddCustomerModalStatus: false,
            showDeleteCustomerModalStatus: false,
          });
        })
        .catch((err) => {
          const { errorMessage, errors } = handleErrorResponse(err);
          message.error(errorMessage);
          this.setState({
            loading: false,
            showAddCustomerModalStatus: false,
            showDeleteCustomerModalStatus: false,
          });
        });
    }
  };

  editCustomerMapping = (beatPlanId, dayId, inputs, deleteView) => {
    let { deleteCustomerId, deleteCustomerOfTheDay } = this.state;
    editCustomerMappingDaywise(beatPlanId, dayId, inputs)
      .then((res) => {
        this.setState({ loading: false });

        if (deleteView == "dailyView") {
          message.success("Customer has been removed successfully");
          this.setView(deleteCustomerId, deleteCustomerOfTheDay);
          this.getBeatPlanDetails();
        } else if (deleteView != "dailyView") {
          this.getBeatPlanDetails();
        }
        this.setState({
          showAddCustomerModalStatus: false,
          showDeleteCustomerModalStatus: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        this.setState({
          showAddCustomerModalStatus: false,
          showDeleteCustomerModalStatus: false,
        });
      });
  };
  setView = (deleteCustomerId, deleteCustomerOfTheDay) => {
    this.setState({ loading: true });
    let beatPlanId = this.props.match.params.id;
    this.getDayDetails(deleteCustomerOfTheDay, beatPlanId);
  };
  showClearAllModal = () => {
    this.setState({
      showClearAllModalStatus: !this.state.showClearAllModalStatus,
    });
  };
  closeClearAllCustomerModal = () => {
    this.setState({
      showClearAllModalStatus: false,
    });
  };
  publishPlan = async () => {
    this.setState({ loading: true });
    let id = this.props.match.params.id;
    let beatPlanId = this.props.location.id;
    await publishDraftedBeatplan(id)
      .then((res) => {
        this.setState({ loading: false });
        message.success(`The beat plan published successfully!`);
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        this.setState({
          loading: false,
          showAddCustomerModalStatus: false,
        });
      });
  };
  selectOption = (e) => {
    if (e != null) {
      this.setState({ loading: true });
      let salesmanId = e.key,
        beatplanId = this.props.match.params.id;
      changeSalesmanOfBeatplan(salesmanId, beatplanId)
        .then((res) => {
          message.success("Salesman han been changed successfully");
          this.setState({
            loading: false,
            showChangeSalesmanOptionState: false,
          });
          this.getBeatPlanDetails();
        })
        .catch((err) => {
          this.setState({ loading: false });
          const { errorMessage, errors } = handleErrorResponse(err);
          message.error(errorMessage);
        });
    }
  };
  deleteCustomer = (id, day, customers, weekDayId, view) => {
    this.setState({
      showDeleteCustomerModalStatus: !this.state.showDeleteCustomerModalStatus,
      deleteCustomerId: id,
      deleteCustomerOfTheDay: day,
      _customersArr: customers,
      dayIdOfSelectedCustomer: weekDayId,
      deleteView: view,
    });
  };
  removeCustomer = () => {
    this.setState({ loading: true });
    let {
      deleteCustomerId,
      _customersArr,
      dayIdOfSelectedCustomer,
      deleteView,
    } = this.state;
    let beatPlanId = this.props.match.params.id;
    let filtered = _customersArr.filter((item) => {
      if (item._id != deleteCustomerId) {
        return item;
      }
    });
    let ordered = filtered.map((item, i) => {
      return { ...item, seq: i + 1 };
    });
    let dayId = dayIdOfSelectedCustomer;
    let inputs = {
      customers: ordered,
    };
    this.editCustomerMapping(beatPlanId, dayId, inputs, deleteView);
  };
  removeSelectedCustomers = () => {
    let { multiselectorCustomerArr, dayIdForMultiSelect } = this.state;
    let filteredArr = multiselectorCustomerArr.filter(
      (customer) => customer.selected === false
    );
    let seqArr = filteredArr.map((item, i) => {
      let newItem = item;
      delete newItem["selected"];
      newItem.seq = i + 1;
      return newItem;
    });
    let inputs = { customers: seqArr };
    let beatPlanId = this.props.match.params.id;
    let deleteView = "weekView";
    let dayId = dayIdForMultiSelect;

    this.editCustomerMapping(beatPlanId, dayId, inputs, deleteView);
    this.setState({
      multiselectorModalStatus: false,
      displayMultiselector: false,
      displayMultiselectorStatus: false,
      showOptionsModal: false,
      showOptionsModalFor: "",
    });
  };

  removeAllCustomer = () => {
    let { dailyViewDayDetails, selectedDay } = this.state;
    let day = dailyViewDayDetails.day;
    let dayId = dailyViewDayDetails._id;
    let beatPlanId = this.props.match.params.id;
    let inputs = {
      customers: [],
    };
    editCustomerMappingDaywise(beatPlanId, dayId, inputs)
      .then((res) => {
        this.setState(
          { loading: false, showClearAllModalStatus: false },
          () => {
            this.getDayDetails(dailyViewDayDetails.day, beatPlanId);
            this.getBeatPlanDetails();
          }
        );

        //  call api
        // call api to fetch beat plan details of the day;
        // this.getDayDetails(_day, beatPlanId);

        message.success("All customers have been removed successfully");
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  closeModal = () => {
    this.setState({
      showDeleteCustomerModalStatus: !this.state.showDeleteCustomerModalStatus,
      deleteCustomerId: "",
      deleteCustomerOfTheDay: "",
    });
  };
  getBeatPlanDetails = async () => {
    let { daysDetails } = this.state;
    let { getSingleDraftBeatPlanById } = this.props;

    this.setState({ loading: true });
    let id = this.props.match.params.id;
    await getDraftBeatPlanDetailsById(id)
      .then((res) => {
        res.data.data && getSingleDraftBeatPlanById(res.data.data);

        let frombk = res.data.data.dayMapping;
        let newArr = this.compareFun(frombk, daysDetails);
        let updatedAt = this.formatDate(res.data.data.updatedAt);
        let publishedTime = this.formatDate(res.data.data.publishedUpdateTime);
        this.setState(
          {
            loading: false,
            daysDetails: newArr,
            updatedAt,
            publishedTime,
            assignedSalesman: res.data.data.salesman.salesmanId._id,
          },
          () => {
            this.getOnboardedCustomers();
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        console.log(err);
        // message.error(errorMessage);
      });
  };
  // getOnboardedCustomers
  getOnboardedCustomers = async () => {
    let { onboardPage, assignedSalesman } = this.state;
    let qParams = [{ page: onboardPage }, { sortBy: "" }, { search: "" }];
    let id = assignedSalesman;
    await getOnboardedCustomerList(qParams, id)
      .then((res) => {
        this.setState({
          onboardedCustomers: res.data.data.results,
          pageMeta: res.data.data.pageMeta,
          // onboardedCustomers: this.state.data,
        });
      })
      .catch((err) => {
        // this.setState({ loading: false });
        console.log(err);
      });
  };
  // daypicker function
  selectDay = (day) => {
    this.setState({ loading: true });
    let beatPlanId = this.props.match.params.id;
    let _day = day.toLowerCase().slice(0, 3);
    // call api to fetch beat plan details of the day;
    this.getDayDetails(_day, beatPlanId);
  };
  // you will get beat plan by day name (send the day name like mon, sun, sat etc.), beatplanid
  getDayDetails = (_day, beatPlanId) => {
    getParticularDayDetails(_day, beatPlanId)
      .then((res) => {
        {
          res.data.data.dayMapping[0] != undefined &&
            res.data.data.dayMapping[0].customers.length == 0 &&
            message.warning("No customer available");
        }
        this.setState(
          {
            loading: false,
            dailyViewDayDetails: res.data.data.dayMapping[0],
            customerDetailsPerDay:
              res.data.data.dayMapping[0] != undefined
                ? res.data.data.dayMapping[0].customers
                : [],
          },
          () => {
            res.data.data.dayMapping[0] != undefined &&
              res.data.data.dayMapping[0].customers.length != 0 &&
              this.getLocationArr(this.state.customerDetailsPerDay);
          }
        );
        res.data.data.dayMapping[0] == undefined &&
          message.warning("Day has not been added!");
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        console.log(errorMessage);
        // message.error(errorMessage);
      });
  };
  // google map function
  getLocationArr = (customerDetailsPerDay) => {
    const { warehouseLocation } = this.state;
    this.setState({
      dailyViewDragging: true,
    });
    let latlngArray = customerDetailsPerDay.map((x) => {
      return (
        x.customer && x.customer.location && x.customer.location.coordinates
      );
    });
    let center = this.averageGeolocation(latlngArray && latlngArray);
    this.setState({
      mapCenter: center,
      dailyViewDragging: false,
    });

    let stops = [];

    customerDetailsPerDay.forEach((item) => {
      let temp = {
        label: item.customer && item.customer.name,
        lat: item.customer && item.customer.location.coordinates[1],
        lng: item.customer && item.customer.location.coordinates[0],
        value: item.customer && item.customerId,
      };
      if (temp.lat && temp.lng) stops.push(temp);
    });
    let to = stops.pop();
    // let from = stops.shift();
    let from = {
      label: `${warehouseLocation.name} - warehouse`,
      lat: warehouseLocation.lat,
      lng: warehouseLocation.lng,
      value: "warehouse",
    };
    this.setState({
      stops,
      from,
      to,
    });

    return latlngArray;
  };
  averageGeolocation = (coords) => {
    console.log(coords);
    if (coords.length === 1) {
      return coords[0];
    }
    let x = 0.0;
    let y = 0.0;
    let z = 0.0;
    for (let coord of coords && coords) {
      let latitude = (coord && coord[1] * Math.PI) / 180;
      let longitude = (coord && coord[0] * Math.PI) / 180;
      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    }
    let total = coords.length;
    x = x / total;
    y = y / total;
    z = z / total;
    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);
    return {
      latitude: (centralLatitude * 180) / Math.PI,
      longitude: (centralLongitude * 180) / Math.PI,
    };
  };
  showInfoMsg = (item) => {
    this.setState({
      selectedCustomer: item,
    });
  };
  removeInfo = () => {
    this.setState({
      selectedCustomer: null,
    });
  };
  showWarningForToggleBtn = (status) => {
    this.setState({
      toggleWarningMsg:
        status == 1
          ? "Are you sure you want to deactivate this beat plan? This will delete its corresponding published beat plan."
          : "Are you sure you want to active the beat plan?",
      showToggleModal: !this.state.showToggleModal,
      toggleBtnStatus: status,
    });
  };
  closeToggleModal = () => {
    this.setState({
      showToggleModal: false,
      toggleBtnStatus: null,
      toggleWarningMsg: "",
    });
  };
  changeTogglebtnStatus = () => {
    this.setState({ loading: true });
    let state = this.state.toggleBtnStatus;
    let status = state == 1 ? "deactivate" : "activate";
    let beatPlanId = this.props.match.params.id;
    //  call api
    changetoggleStatus(beatPlanId, status)
      .then((res) => {
        message.success("Status has been changed successfully");
        this.setState(
          {
            showToggleModal: false,
          },
          () => {
            this.getBeatPlanDetails();
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false, showToggleModal: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  showModalForDeletingMultipleCustomers = () => {
    this.setState({
      multiselectorModalStatus: !this.state.multiselectorModalStatus,
    });
  };
  closeModalForDeletingMultipleCustomers = () => {
    this.setState({
      multiselectorModalStatus: !this.state.multiselectorModalStatus,
      displayMultiselectorStatus: false,
      showOptionsModal: false,
      displayMultiselector: false,
      multiselectorCustomerArr: [],
    });
  };
  // drag drop functions
  onDragStart = () => {
    this.setState({
      showSaveAsDraftBtn: true,
    });
  };
  onDragStartOfDayView = () => {
    this.setState({
      showSaveAsDraftBtnForDailyView: true,
      dailyViewDragging: true,
    });
  };
  onDragEnd = (result) => {
    let { destination, source, draggableId } = result;

    if (!destination) {
      // this.setState({loading: false})
      return;
    }
    if (destination.droppableId == null) {
      // this.setState({loading: false})
      return;
    }
    let droppableId = result.destination.droppableId;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let { daysDetails } = this.state;
    let dayDetails = daysDetails.filter((day) => day.id === droppableId);
    let _customers = dayDetails[0].customers;
    let removedCustomer = _customers[source.index];
    //  removingCustomerFromSourceIndex
    _customers.splice(source.index, 1);

    //  incerting the removed customer in the destination index
    _customers.splice(destination.index, 0, removedCustomer);
    let seqCustomers = _customers.map((customer, i) => {
      customer.seq = i + 1;
      return customer;
    });

    // update state
    let modifiedDaysDetails = daysDetails.map((day) => {
      if (day.id === draggableId) {
        day.customers = seqCustomers;
        return day;
      } else {
        return day;
      }
    });

    this.setState({
      dayDetails: modifiedDaysDetails,
    });
  };

  onDragEndOfDayView = (result, dailyViewDayDetails, customerDetailsPerDay) => {
    const { warehouseLocation } = this.state;
    let { destination, source, draggableId } = result;

    if (!destination) {
      this.setState({
        dailyViewDragging: false,
      });
      return;
    }
    if (destination.droppableId == null) {
      this.setState({
        dailyViewDragging: false,
      });
      return;
    }
    let droppableId = result.destination.droppableId;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      this.setState({
        dailyViewDragging: false,
      });
      return;
    }
    let customerList = customerDetailsPerDay.customers;
    let removedCustomer = customerList[source.index];
    //  removingCustomerFromSourceIndex
    customerList.splice(source.index, 1);

    //  incerting the removed customer in the destination index
    customerList.splice(destination.index, 0, removedCustomer);

    let seqCustomers = customerList.map((customer, i) => {
      customer.seq = i + 1;
      return customer;
    });
    // update state
    let modifiedDaysDetails = this.state.daysDetails.map((day) => {
      if (day.id == droppableId) {
        day.customers = seqCustomers;
        return day;
      } else {
        return day;
      }
    });

    this.setState({
      daysDetails: modifiedDaysDetails,
      dailyViewDragging: false,
    });

    let stops = [];

    customerList.forEach((item) => {
      let temp = {
        label: item.customer.name,
        lat: item.customer.location.coordinates[1],
        lng: item.customer.location.coordinates[0],
        value: item.customerId,
      };
      if (temp.lat && temp.lng) stops.push(temp);
    });
    let to = stops.pop();
    // let from = stops.shift();
    let from = {
      label: `${warehouseLocation.name} - Warehouse`,
      lat: warehouseLocation.lat,
      lng: warehouseLocation.lng,
      value: "warehouse",
    };
    this.setState({
      stops,
      from,
      to,
    });
  };

  // saveAsDraft button function for week view
  getOrganizedArr = (customers) => {
    let result = customers.map((customer) => {
      let newCustomerObj = {
        customerId: customer.customerId,
        seq: customer.seq,
      };
      return newCustomerObj;
    });
    return result;
  };
  saveAsDraft = async () => {
    this.setState({ loading: true });
    let { daysDetails } = this.state;
    // remove the day with id="" from dayDetails

    let filteredDayDetails =
      daysDetails && daysDetails.filter((item) => item.id != "");
    let mappedDayDetails =
      filteredDayDetails &&
      filteredDayDetails.map((item) => {
        let newItem = {
          day: item.id,
          customers: this.getOrganizedArr(item.customers),
        };

        return newItem;
      });
    let inputs = {
      customerMapping: mappedDayDetails,
    };
    let id = this.props.match.params.id;
    // call addCustomersToBeatPlan
    this.addCustomersToBeatPlanFunction(id, inputs);
  };
  // addCustomersToBeatPlan function
  addCustomersToBeatPlanFunction = async (id, inputs) => {
    await addCustomersToBeatPlan(id, inputs)
      .then((res) => {
        this.getBeatPlanDetails();
        this.setState({
          showSaveAsDraftBtn: false,
          showSaveAsDraftBtnForDailyView: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          showSaveAsDraftBtn: false,
          showSaveAsDraftBtnForDailyView: false,
        });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  // saveAsDraftForDayView
  saveAsDraftForDayView = () => {
    this.setState({ loading: true });
    this.saveAsDraft();
  };

  // map view functions
  toggleMapView = () => {
    this.setState({
      mapView: !this.state.mapView,
    });
  };
  getPublishTime = async () => {
    await getMaxTimeToPublishDetails()
      .then((res) => {
        this.setState({
          getPublishTimeValue: res.data.data.value,
        });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        console.log("getPublishTime err", err);
      });
  };
  componentDidMount = async () => {
    this.getBeatPlanDetails();
    this.getPublishTime();
    let userProfile = await getProfileDetails();
    let coordinates =
      userProfile.data.data && userProfile.data.data.warehouse
        ? userProfile.data.data.warehouse.location &&
          userProfile.data.data.warehouse.location.coordinates
        : [null, null];
    let name = userProfile.data.data.warehouse
      ? userProfile.data.data.warehouse.name
      : "N/A";
    let warehouseLocation = {
      name: name,
      lat: coordinates && coordinates[1],
      lng: coordinates && coordinates[0],
    };
    this.setState({ warehouseLocation: warehouseLocation });
  };

  formatDate = (_date) => {
    if (_date != null && _date != "" && _date != "N/A" && _date != undefined) {
      let date = new Date(_date);
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
  };
  showDropdown = () => {
    this.setState({
      showCustomerDropdown: !this.state.showCustomerDropdown,
    });
  };
  fetchMoreData = () => {
    this.setState(
      {
        onboardPage: this.state.onboardPage + 1,
      },
      () => {
        this.getOnboardedCustomers();
      }
    );
  };

  /**
   * IMITA CHECK
   */

  // optimise the customer list
  hitOptimize = async () => {
    try {
      const {
        stops,
        from,
        to,
        customerDetailsPerDay,
        dailyViewDayDetails,
        daysDetails,
      } = this.state; // get the data
      console.log("The days details here is --", daysDetails);
      let stopsLocToSend = stops;
      let warehouseLoc = from;
      stopsLocToSend = stopsLocToSend.concat(to);
      this.setState({ loading: true });
      let dataToSend = {
        city: "default",
        source: {
          name: warehouseLoc.label,
          id: warehouseLoc.value,
          customerLocation: {
            type: "Point",
            coordinates: [warehouseLoc.lng, warehouseLoc.lat],
          },
        },
        customers: [],
        skipcustomer: [],
      };
      // mapping the customers
      for (let i = 0; i < customerDetailsPerDay.length; i++) {
        if (customerDetailsPerDay[i]) {
          if (
            customerDetailsPerDay[i].customer.location.coordinates &&
            customerDetailsPerDay[i].customer.location.coordinates[0] != null
          )
            dataToSend.customers.push({
              customerName: customerDetailsPerDay[i].customer.name,
              customerId: customerDetailsPerDay[i].customer._id,
              customerLocation: {
                type: "Point",
                coordinates: [
                  customerDetailsPerDay[i].customer.location.coordinates[0],
                  customerDetailsPerDay[i].customer.location.coordinates[1],
                ],
              },
            });
          else
            dataToSend.skipcustomer.push({
              customerName: customerDetailsPerDay[i].customer.name,
              customerId: customerDetailsPerDay[i].customer._id,
              customerLocation: {
                type: "Point",
                coordinates: [
                  customerDetailsPerDay[i].customer.location.coordinates[0],
                  customerDetailsPerDay[i].customer.location.coordinates[1],
                ],
              },
            });
        }
      }

      let response = {
        success: false,
        data: {
          customers: [],
        },
      };
      // if customers are not present
      if (dataToSend.customers && dataToSend.customers.length)
        response = await routeOptimisation(dataToSend);
      else {
        message.error(
          "There is no customer with proper coordinates to optimize !"
        );
        this.setState({ loading: false });
      }

      let customers = response.data;

      this.setState({ loading: false });
      let stopsArray = [];
      let newCustomerDetailsPerDay = [];
      let newDailyViewDayDetails = [];
      let index = 1;
      customers.customers.forEach((item) => {
        let temp = {
          label: item.customerName,
          lat: item.customerLocation.coordinates[1],
          lng: item.customerLocation.coordinates[0],
          value: item.customerId,
        };
        if (temp.lat && temp.lng) stopsArray.push(temp);
      });

      stopsArray.forEach((item) => {
        let customerDetails = _.find(customerDetailsPerDay, {
          customerId: item.value,
        });

        let dailyView = _.find(dailyViewDayDetails, {
          customerId: item.value,
        });
        newCustomerDetailsPerDay.push({ ...customerDetails, seq: index });
        newDailyViewDayDetails.push({ ...dailyView, seq: index });
        index++;
      });

      // if stops doesnt have lat long is present
      if (dataToSend.skipcustomer && dataToSend.skipcustomer.length) {
        dataToSend.skipcustomer.forEach((item) => {
          let customerDetails = _.find(customerDetailsPerDay, {
            customerId: item.customerId,
          });

          let dailyView = _.find(dailyViewDayDetails, {
            customerId: item.customerId,
          });
          newCustomerDetailsPerDay.push({ ...customerDetails, seq: index });
          newDailyViewDayDetails.push({ ...dailyView, seq: index });
          index++;
        });
      }

      let toStop = stopsArray.pop();

      let fromStop = {
        label: dataToSend.source.name,
        lat: dataToSend.source.customerLocation.coordinates[1],
        lng: dataToSend.source.customerLocation.coordinates[0],
        value: "warehouse",
      };

      this.setState({
        showSaveAsDraftBtnForDailyView: true,
        stops: stopsArray,
        from: fromStop,
        to: toStop,
        customerDetailsPerDay: newCustomerDetailsPerDay,
        dailyViewDayDetails: newDailyViewDayDetails,
      });

      // catch any runtime error
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }
  };

  render() {
    let {
      loading,
      updatedAt,
      publishedTime,
      dragging,
      getPublishTimeValue,
      dailyViewDragging,
      viewType,
      showTypeStatus,
      daysDetails,
      showChangeSalesmanOptionState,
      salesmanList,
      salesmanLoader,
      deleteCustomerId,
      deleteCustomerOfTheDay,
      customerDetailsPerDay,
      dailyViewDayDetails,
      toggleWarningMsg,
      showOptionsModal,
      showOptionsModalFor,
      displayMultiselector,
      mapView,
      showSaveAsDraftBtn,
      showSaveAsDraftBtnForDailyView,
      showInfoMsg,
      stops,
      from,
      to,
      onboardPage,
      assignedSalesman,
    } = this.state;
    let { details } = this.props;

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <>
        <div id="content">
          <PrimaryHeader title="View Beat Plan" />
          <Spin indicator={antIcon} spinning={loading}>
            <div className="d-flex align-items-center justify-content-between pb-2">
              <p className="page-title">{details && details.beatPlanId}</p>
              <div>
                <img
                  src={info}
                  alt="info"
                  onMouseEnter={(e) => this.showInfo(e)}
                  className="mr-2"
                />
                {details.status == 1 ? (
                  <>
                    <span>Active</span>{" "}
                    <img
                      src={activeToggle}
                      alt="toggle"
                      onClick={() =>
                        this.showWarningForToggleBtn(details.status)
                      }
                    />
                  </>
                ) : (
                  <>
                    <span>Inactive</span>{" "}
                    <img
                      src={inactiveToggle}
                      alt="toggle"
                      onClick={() =>
                        this.showWarningForToggleBtn(details.status)
                      }
                    />
                  </>
                )}
              </div>
            </div>
            <div className={details.status == 1 ? "" : "inactive-layer"}>
              <div className="row mb-0">
                <div className="col col-9 assignTo mb-2">
                  Assigned to :{" "}
                  {details.salesman &&
                    details.salesman.salesmanId &&
                    details.salesman.salesmanId.fullName}
                  {showChangeSalesmanOptionState && (
                    <Spin indicator={antIcon} spinning={salesmanLoader}>
                      <div className="selectSalesman">
                        <AsyncPaginate
                          value={""}
                          loadOptions={this.loadOptions}
                          additional={{
                            page: 1,
                          }}
                          isClearable={true}
                          isSearchable={true}
                          classNamePrefix="select"
                          onChange={(e) => {
                            this.selectOption(e);
                          }}
                        />
                      </div>
                    </Spin>
                  )}
                  <span
                    className="change"
                    onClick={this.showChangeSalesmanOption}
                  >
                    {showChangeSalesmanOptionState ? "CLOSE" : "CHANGE"}
                  </span>
                </div>
                <div
                  className="col col-3 assignTo mb-2 cursor"
                  onClick={this.showDropdown}
                >
                  Onboarded Customer List <img src={downArrow} alt="arrow" />
                  {this.state.showCustomerDropdown && (
                    <Dropdown
                      options={this.state.onboardedCustomers}
                      fetchMoreData={this.fetchMoreData}
                      dataLength={this.state.pageMeta.pageSize}
                    />
                  )}
                </div>
              </div>

              {/* rowWithOptions starts */}
              <RowWithOptions
                updatedAt={updatedAt}
                publishedTime={publishedTime}
                viewType={viewType}
                showSaveAsDraftBtn={showSaveAsDraftBtn}
                mapView={mapView}
                showSaveAsDraftBtnForDailyView={showSaveAsDraftBtnForDailyView}
                showType={this.showType}
                saveAsDraft={this.saveAsDraft}
                saveAsDraftForDayView={this.saveAsDraftForDayView}
                publishPlan={this.publishPlan}
                selectDay={this.selectDay}
               // saveAsDraft={this.saveAsDraft}
               // publishPlan={this.publishPlan}
              />
              {/* rowWithOptions ends */}

              {showTypeStatus && (
                <div className="showTypeDropdown">
                  <li value="Weekly" onClick={this.setType}>
                    Weekly
                  </li>
                  <li value="Daily" onClick={this.setType}>
                    Daily
                  </li>
                </div>
              )}

              {viewType == "Daily" && (
                <div className="row _View dailyRow">
                  <div className="col col-2">
                    {" "}
                    <img
                      src={AddCustomer}
                      alt="AddCustomer"
                      onClick={() =>
                        this.showAddCustomerModal(
                          dailyViewDayDetails._id && dailyViewDayDetails._id
                        )
                      }
                    />
                  </div>
                  <div className="col col-2">
                    {" "}
                    <img
                      src={clearAll}
                      alt="clearAll"
                      onClick={this.showClearAllModal}
                      className="ml-3"
                    />
                  </div>
                  <div className="col col-8 map-colmn">
                    {mapView && customerDetailsPerDay.length != 0 && (
                      <a
                        className="optimize-btn draftsPageBtn"
                        onClick={this.hitOptimize}
                      >
                        Optimize
                      </a>
                    )}
                    <span className="pr-1">Map View </span>
                    <img
                      src={mapView ? activeToggle : inactiveToggle}
                      alt="toggle"
                      onClick={this.toggleMapView}
                    />
                  </div>
                </div>
              )}

              {/* -------tables------ */}

              {/* weekly table */}
              {viewType == "Weekly" && (
                <div
                  className="display-list container-fluid"
                  // ref="draftList"
                  // onScroll={this.handleScroll}
                >
                  <div className="row flex-nowrap nameRow">
                    {/* weekDays */}

                    {daysDetails.map((day, i) => {
                      return (
                        <div
                          className={`col-3 days-title d-flex justify-content-between ${day.day}Col`}
                          key={i}
                        >
                          {getDayName(day.day)}
                          <div>
                            {day.id != "" && (
                              <img
                                src={plus}
                                alt="add"
                                className="add"
                                onClick={() =>
                                  this.showAddCustomerModal(day.id)
                                }
                                title="Add customer/customers"
                              />
                            )}
                            <img
                              src={Group}
                              alt="options"
                              className="options"
                              onClick={() => this.showOptions(day.day)}
                              title="options"
                            />
                          </div>

                          {showOptionsModal &&
                            showOptionsModalFor == day.day && (
                              <Options
                                showOptionsModalFor={showOptionsModalFor}
                                displayMultiselector={this.displayMultiSelector}
                                displayDeleteDayModal={
                                  this.displayDeleteDayModal
                                }
                                displayMultiselectorStatus={
                                  displayMultiselector
                                }
                                chosenDay={day.day}
                                closeOptionModal={this.closeOptionModal}
                                selectedCustomersNumber={this.lengthOfSelectedCustomerArr()}
                                showModal={
                                  this.showModalForDeletingMultipleCustomers
                                }
                              />
                            )}
                        </div>
                      );
                    })}
                  </div>
                  {/* drag and drop context */}
                  <DragDropContext
                    onDragEnd={this.onDragEnd}
                    onDragStart={this.onDragStart}
                  >
                    <div className="row flex-nowrap detailsRow">
                      {daysDetails.map((day, i) => {
                        if (day.id == "") {
                          return (
                            <div className={`col-3 ${day.day}`} key={i}>
                              <ShowAdd
                                dayName={day.day}
                                addDayFunction={this.addDayFunction}
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className={
                                (showOptionsModalFor == day.day &&
                                  this.state.displayMultiselector == true) ==
                                true
                                  ? `orange-backgroud col-3 ${day.day}`
                                  : `col-3 ${day.day}`
                              }
                              key={i}
                            >
                              <ShowList
                                dayName={day.day}
                                dragging={dragging}
                                arr={daysDetails}
                                deleteCustomer={this.deleteCustomer}
                                summery={day}
                                handleChangeChk={this.handleChangeChk}
                                displayMultiselectorState={
                                  this.state.displayMultiselector
                                }
                                showOptionsModal={
                                  showOptionsModalFor == day.day &&
                                  this.state.displayMultiselector
                                }
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                  </DragDropContext>
                </div>
              )}
              {/* daily table */}
              {viewType == "Daily" && customerDetailsPerDay.length > 0 && (
                <DailyViewTable
                  customerDetailsPerDay={customerDetailsPerDay}
                  dailyViewDayDetails={dailyViewDayDetails}
                  deleteCustomer={this.deleteCustomer}
                  mapView={mapView}
                  dragging={dailyViewDragging}
                  onDragStartOfDayView={this.onDragStartOfDayView}
                  onDragEndOfDayView={this.onDragEndOfDayView}
                  getLocationArr={this.getLocationArr}
                  mapCenter={this.state.mapCenter}
                  showInfo={this.showInfoMsg}
                  removeInfo={this.removeInfo}
                  selectedCustomer={this.state.selectedCustomer}
                  stops={stops}
                  from={from}
                  to={to}
                />
              )}
            </div>
          </Spin>
        </div>
        {/* show warning modals */}

        {this.state.showAddCustomerModalStatus && (
          <AddCustomerModal
            displayModal={this.showAddCustomerModal}
            addCustomersToBeatplan={
              viewType == "Weekly"
                ? this.addCustomersToBeatplan
                : this.addCustomersToDailyDraftPlan
            }
          />
        )}
        {this.state.showDeleteCustomerModalStatus && (
          <DeleteConfirmation
            header={"Delete Customer"}
            closeDeleteConfirmationModal={this.closeModal}
            warningMsg={
              "Are you sure you want to delete the Customer from the list?"
            }
            removeItem={this.removeCustomer}
          />
        )}

        {this.state.showToggleModal && (
          <DeleteConfirmation
            header={"Change Beat Plan Status"}
            closeDeleteConfirmationModal={this.closeToggleModal}
            warningMsg={toggleWarningMsg}
            removeItem={this.changeTogglebtnStatus}
            toggleModal={true}
          />
        )}

        {this.state.showClearAllModalStatus && (
          <DeleteConfirmation
            header={"Clear All Customers"}
            closeDeleteConfirmationModal={this.closeClearAllCustomerModal}
            warningMsg={
              "Are you sure you want to remove all customers from this day?"
            }
            removeItem={this.removeAllCustomer}
          />
        )}
        {this.state.multiselectorModalStatus && (
          <DeleteConfirmation
            header={"Delete Selected Customers"}
            closeDeleteConfirmationModal={
              this.closeModalForDeletingMultipleCustomers
            }
            warningMsg={
              "Are you sure you want to remove selected customers from this day?"
            }
            removeItem={this.removeSelectedCustomers}
          />
        )}
        {this.state.showDeleteDayModal && (
          <DeleteConfirmation
            header={"Delete Selected Day"}
            closeDeleteConfirmationModal={this.closeDisplayDeleteDayModal}
            warningMsg={
              "Are you sure you want to delete this day from the beat plan?"
            }
            removeItem={this.removeDay}
          />
        )}
      </>
    );
  }
}

export default DraftBeatPlan;
