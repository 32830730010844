import React, { useState, useEffect } from "react";
import { Input, Typography, Button, notification } from "antd";
import "./orders.scss";
import OtpInput from "react-otp-input";
import { verifyOtp } from "../../../services/otpServices/otpServices";
import digitalsign from "../../../images/digsignature.svg";
import digitalsig from "../../../images/digitalsig.svg";
import dgnotvailable from "../../../images/dgnotavailable.svg";
import VerifiedComponentMessge from "./VerifiedComponentMessge";
import { Link, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getIndividualOrderDetail } from "../../../action/IndividualOrderDetails";
import moment from "moment";
import { handleErrorResponse } from "../../../../src/utils/Request";
import message from "antd/lib/message";
function Otp() {
  const [otp, setOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(true);
  const dispatch = useDispatch();
  // const params = useParams();
  // const soId = params?.soId;
  // const InvoiceId = params?.InvoiceId;
  // const customerID = params?.customerID;
  // const triggerOtp = params?.Otp;
  const {
    state: { soId, InvoiceId, Otp, customerID, shipmentindex },
  } = useLocation();
  // const { soId, InvoiceId, Otp, customerID } = invData;
  console.log("jhj");
  const triggerOtp = Otp;
  const { IndividualOrderDetails, loading, ConfigData } = useSelector(
    (state) => state.individualOrderDetailsReducer
  );

  console.log(
    IndividualOrderDetails[0]?.shipments,
    "IndividualOrderDetails",
    IndividualOrderDetails[0]?.customerPhone
  );
  // const invoicedetails =
  //   IndividualOrderDetails &&
  //   IndividualOrderDetails[0]?.orderpacked?.invoicedetails?.filter(
  //     (invId) => invId.mginvoiceId == InvoiceId
  //   );
  const invoiceDetails =
    IndividualOrderDetails[0]?.shipments[shipmentindex]?.otp?.status;
  const mobileNo =
    IndividualOrderDetails &&
    IndividualOrderDetails[0]?.customerinfo?.customerPhone;
  // const mobileNo =
  //   IndividualOrderDetails &&
  //   IndividualOrderDetails[0]?.shipments[shipmentindex]?.customerPhone;
  console.log(mobileNo, "mobileNo");
  const otpSoId = IndividualOrderDetails && IndividualOrderDetails[0]?.soId;
  const invoicenumnber =
    IndividualOrderDetails &&
    IndividualOrderDetails[0]?.shipments[shipmentindex]?.invoiceNo;
  //console.log(invoicedetails[0].otp.updatedAt, "invv");
  const otpUpdateAt =
    IndividualOrderDetails[0]?.shipments[shipmentindex]?.otp?.generatedAt;
  const handleSubmit = async () => {
    if (otp == "") {
      notification.error({ message: "Enter valid four digits number" });
      return;
    }
    await verifyOtp(otp, invoicenumnber, otpSoId, ConfigData.isLogistics)
      .then((response) => {
        if (response.status === 200) {
          setOtpVerified(false);
          dispatch(getIndividualOrderDetail(soId, customerID));
        }
      })

      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error, "error");
          const { errorMessage, errors } = handleErrorResponse(error);
        } else {
          message.error(error?.response?.data?.message);
        }
      });
  };
  // if (invoicedetails === undefined) {
  //   return null;
  // }
  return (
    <div className="" style={{ backgroundColor: "#ffffff" }}>
      {triggerOtp == "4" && otpVerified ? (
        <div>
          <div className="">
            <Typography
              style={{
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "22px",
                color: "#3A4960",
                marginTop: "20px",
              }}
            >
              OTP Code sent to the customer
            </Typography>
            <span style={{ display: "flex", marginTop: "4px" }}>
              <span style={{ marginTop: "5px" }}>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "19px",
                    color: "#3A4960",
                  }}
                >
                  {" "}
                  collection verification code is sent to the customer on{" "}
                </Typography>
              </span>
              <span style={{ marginTop: "6px" }}>
                {" "}
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    marginLeft: "5px",
                  }}
                >
                  {mobileNo}
                </Typography>
              </span>
              <span></span>
            </span>
          </div>
          <div style={{ display: "flex", gap: "30px", marginTop: "55px" }}>
            <div>
              <OtpInput
                value={otp}
                onChange={(otp) => {
                  console.info(otp);

                  setOtp(otp);
                }}
                numInputs={4}
                inputStyle={{
                  fontSize: "24px",

                  width: "74px",

                  height: "36px",

                  margin: "4px",

                  borderTop: "0px",

                  borderLeft: "0px",

                  borderRight: "0px",

                  outline: "none",

                  borderColor: "#3A4960",
                }}
                containerStyle={{
                  margin: "1px auto",
                  padding: "1px",
                }}
                isInputNum
              />
            </div>
          </div>
          <div style={{ marginTop: "40px" }}>
            <Button
              style={{ height: "41px", width: "133px" }}
              type="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {!loading && invoiceDetails === true ? (
            <VerifiedComponentMessge
              imageUrl={digitalsig}
              title={"OTP Code Verified"}
              // with the customer mobile number +91 974 6351 695  on 12/03/1993 12:40 pm
              description={"with the customer mobile number  "}
              text={mobileNo}
              text1={"on "}
              text2={moment(otpUpdateAt)
                ?.utcOffset(0, false)
                .format("DD/MM/YYYY hh:mm A")}
            />
          ) : null}
          {!loading && (invoiceDetails === false || invoiceDetails == "") ? (
            <VerifiedComponentMessge
              imageUrl={dgnotvailable}
              title={"OTP Code not verified"}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}

export default Otp;
