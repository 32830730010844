import axios from "axios";
import { api as APIConfig } from "../../../src/appConfig";
import { getAuthToken } from "../../../src/utils/Auth";

export const getClusterHeadDetails = async (
  clusterLocation,
  areaLocation,
  searchKey,
  // areaLocation,
  path,
  page,
  pageSize
) => {
  console.log(path, searchKey, clusterLocation);

  let geturl = () => {
    let url = "";
    switch (path) {
      case "Cluster":
        url = `api/v1/teamMapping/listing?type=1&pageNo=${page}&size=${pageSize}`;
        if (searchKey) {
          url = url + `&searchText=${searchKey.trimEnd()}`;
          return url;
        }
        return url;
      case "Area":
        url = `api/v1/teamMapping/listing?type=2&pageNo=${page}&size=${pageSize}`;

        if (searchKey) {
          url = url + `&searchText=${searchKey.trimEnd()}`;
          return url;
        }

        if (clusterLocation === "all") {
          url = `api/v1/teamMapping/listing?type=2&pageNo=${page}&size=${pageSize}`;
          return url;
        }
        if (clusterLocation !== "all") {
          url = url + `&clusterId=${clusterLocation}`;
          return url;
        }

        console.log(url);

        return url;
      case "Hub":
        url = `api/v1/teamMapping/listing?type=3&pageNo=${page}&size=${pageSize}`;

        if (searchKey) {
          url = url + `&searchText=${searchKey.trimEnd()}`;
          return url;
        }

        if (clusterLocation === "all") {
          url = `api/v1/teamMapping/listing?type=3&pageNo=${page}&size=${pageSize}`;
          return url;
        }
        if (clusterLocation !== "all") {
          url = url + `&clusterId=${clusterLocation}`;
          if (areaLocation && areaLocation !== "allareas") {
            url = url + `&areaId=${areaLocation}`;
            return url;
          }
          return url;
        }

        if (areaLocation === "allareas") {
          url = `api/v1/teamMapping/listing?type=3&pageNo=${page}&size=${pageSize}`;
          return url;
        }
        return url;
    }
  };

  let request = await axios.get(APIConfig.base_url + geturl(), {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};
export const searchEmployee = async (employerId) => {
  let url = `api/v1/teamMapping/search?employeeId=${employerId}`;
  let request = await axios.get(
    APIConfig.base_url + url,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );

  return request;
};
export const clusterDropdown = async () => {
  let url = `api/v1/teamMapping/filter`;

  let request = await axios.get(
    APIConfig.base_url + url,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );

  return request;
};
export const areaDropdown = async (clusterLocation, areaLocation) => {
  let url = `api/v1/teamMapping/filter?`;

  if (clusterLocation !== "all") {
    url = url + `clusterId=${clusterLocation}&type=1`;
  }

  let request = await axios.get(
    APIConfig.base_url + url,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
export const viewEMployeeDetails = async (id) => {
  let url = `api/v1/teamMapping/viewHeadDetails?geoId=${id}`;
  let request = await axios.get(
    APIConfig.base_url + url,

    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );

  return request;
};
export const AddEmployeedata = async ({ id, employeenum, path }) => {
  console.log(path, "ll");
  let url = () => {
    if (path === "Cluster") {
      let url = `api/v1/teamMapping/create?type=1&clusterId=${id}&employeeId=${employeenum}`;
      return url;
    }
    if (path === "Area") {
      let url = `api/v1/teamMapping/create?type=2&areaId=${id}&employeeId=${employeenum}`;
      return url;
    }
    if (path === "Hub") {
      let url = `api/v1/teamMapping/create?type=3&hubId=${id}&employeeId=${employeenum}`;
      return url;
    }
  };

  //let url = `api/v1/teamMapping/create?type=1&clusterId=64367c3fecfd77367e2dbc4d&employeeId=5000101`;
  let request = await axios.patch(
    APIConfig.base_url + url(),
    {},

    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};

export const deleteEmployee = async ({ regionId, _id, path }) => {
  console.log(path, "ll");
  let url = () => {
    if (path === "Cluster") {
      let url = `api/v1/teamMapping/removeHead?type=1&clusterId=${_id}&regionId=${regionId}`;
      return url;
    }
    if (path === "Area") {
      let url = `api/v1/teamMapping/removeHead?type=2&areaId=${_id}&regionId=${regionId}`;
      return url;
    }
    if (path === "Hub") {
      let url = `api/v1/teamMapping/removeHead?type=3&hubId=${_id}&regionId=${regionId}`;
      return url;
    }
  };
  // let url = `api/v1/teamMapping/removeHead?type=2&areaId=643d80e5bb48e46720562bee&regionId=643d7d5fab51f262d05b2336`;
  let request = await axios.patch(
    APIConfig.base_url + url(),
    {},

    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};

export const downloadCustomerDetails = async (
  type,
  clusterId,
  areaId,
  RegionID
) => {
  let url;
  if (type == 2) {
    url = `api/v1/teamMapping/downloadCustomers?type=${type}&clusterId=${clusterId}&areaId=${areaId}`;
  } else {
    url = `api/v1/teamMapping/downloadCustomers?type=${type}&regionId=${RegionID}`;
  }
  let request = await axios.get(
    APIConfig.base_url + url,

    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );

  return request;
};
