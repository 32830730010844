import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";

export const getSalesOverview = async (from, to) => {
  let url = `api/v1/orderoverview/salesoverview?from=${from}&to=${to}`;
  let request = await axios.get(APIConfig.base_url + url, {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};
