import { LoadingOutlined } from "@ant-design/icons";
import { Pagination, Spin, Tabs } from "antd";
import message from "antd/lib/message";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import add from "../../images/add-new-plan.svg";
import defaultPic from "../../images/default-pic.jpg";
import edit from "../../images/edit.svg";
import inactiveToggle from "../../images/inactive-toggle.svg";
import activeToggle from "../../images/Toggle.svg";
import trash from "../../images/trash.svg";
import {
  generateOtp,
  getAllSalesmen,
  mapSalesmanWithAsm,
  removeSalesman,
  salesManDetails,
  toggleStatus,
  verifyOtp,
} from "../../services/teamServices/team";
import { getProfileDetails } from "../../services/userProfileServices/userProfile";
import { stringSortingByChar } from "../../utils/common";
import { handleErrorResponse } from "../../utils/Request";
import PrimaryHeader from "../header/PrimaryHeader";
import DeleteConfirmation from "../modal/deleteConfirmation";
import SalesmanModal from "../modal/SalesmanModal";
import SearchBar from "../search-bar/searchBar";
import AsmDropdown from "../team/asmDropdown";
import { getImageUrl } from "./../../utils/generateImageUrl";
import MappedSalesManDetails from "./../modal/mappedSalesManDetails";

class TeamSalesmen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      sortBy: "",
      search: "",
      loading: false,
      showDeleteConfirmation: false,
      removeId: null,
      removeItemId: null,
      scroller: false,
      salesmanType: "mapped",
      salesmanList: [],
      selectedIds: [],
      toggleStatus: [],
      showAsmDropdown: false,
      asmListPage: 1,
      asmOptions: null,
      singleAsmDetails: {},
      displayMappedSalesmanModal: false,
      totalPage: 0,
      pageSize: 0,
      totalItems: 0,
      apiCalling: false,
      selectedId: null,
      salesmanName: "",
      otpGenerated: false,
      visible: false,
      isOTPEnterModal: false,
      otpError: "",
      showOtpInputModal: false,
      isSalemanModal: false,
      isStandalone: false,
      isLoading: false,
      userDetails: {},
    };
  }
  componentDidMount() {
    this.getUserDetails();
    this.getSalesmenFunction();
  }

  //Get User Details
  getUserDetails = async () => {
    await getProfileDetails()
      .then((res) => {
        this.setState({
          loading: false,
          asmApprovalRequired:
            res.data.data && res.data.data.asmApprovalRequired,
          userDetails: res.data.data,
        });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        this.setState({ loading: false });
      });
  };

  getSalesmenFunction = async () => {
    this.setState({ loading: true, apiCalling: true }, () => {});
    let { page, sortBy, search, salesmanType } = this.state;
    let { getSalesmen } = this.props;
    let qParams = [{ page }, { sortBy }, { search }, { type: salesmanType }];
    await getAllSalesmen(qParams)
      .then((res) => {
        if (res.data.data.results.length == 0) {
          message.warning("No data available!");
        }
        this.setState({
          salesmanList: res.data.data.results,
          toggleStatus:
            res.data.data.results &&
            res.data.data.results.map((item, i) => {
              return { id: i, isChecked: false };
            }),
          loading: false,
          apiCalling: false,
          totalPage: res.data.data.pageMeta.total,
          pageSize: res.data.data.pageMeta.pageSize,
          totalItems: res.data.data.pageMeta.total,
        });
        // call action
        getSalesmen(res.data.data.results);
      })
      .catch((err) => {
        this.setState({ loading: false, apiCalling: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  // change toggle status
  changeToggleStatus = async (item) => {
    this.setState({ loading: true });
    let status = item.status == 1 ? "deactivate" : "activate";
    let id = item._id;
    let type = "salesman";
    await toggleStatus(id, status, type)
      .then((res) => {
        {
          status == "activate"
            ? message.success("Status activated successfully")
            : message.success("Status deactivated successfully");
        }
        this.getSalesmenFunction();
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  // closeDeleteConfirmationModal
  closeDeleteConfirmationModal = () => {
    this.setState({
      showDeleteConfirmation: false,
    });
  };
  // remove salesman
  removeSalesmanFun = async () => {
    this.setState({ loading: true });
    let id = this.state.removeItemId;
    // call api with this id
    await removeSalesman(id)
      .then((res) => {
        message.success("Salesman deleted successfully");
        this.closeDeleteConfirmationModal();
        this.getSalesmenFunction();
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        this.closeDeleteConfirmationModal();
      });
  };
  // tabChange
  changeTab = (e) => {
    let { asmListPage, salesmanType } = this.state;
    this.setState(
      {
        salesmanType: e,
        selectedIds: [],
      },
      () => {
        this.getSalesmenFunction();
      }
    );
  };

  // checkbox function
  toggleChange = (item, i) => {
    let { toggleStatus, salesmanType } = this.state;
    if (salesmanType == "unmapped") {
      let id = item._id.toString();
      let newCheckStatusArr = toggleStatus.map((a) => {
        let returnValue = { ...a };

        if (a.id == i) {
          returnValue.isChecked = !returnValue.isChecked;
        }

        return returnValue;
      });

      this.setState(
        {
          toggleStatus: newCheckStatusArr,
        },
        () => {
          if (
            this.state.toggleStatus[i].isChecked == true &&
            this.state.selectedIds.length == 0
          ) {
            let ids = this.state.selectedIds;
            ids.push(id);

            this.setState({
              selectedIds: ids,
            });
          } else if (
            this.state.toggleStatus[i].isChecked == true &&
            this.state.selectedIds.length != 0 &&
            this.state.selectedIds.indexOf(id) == -1
          ) {
            let ids = this.state.selectedIds;
            ids.push(id);
            this.setState({
              selectedIds: ids,
            });
          } else if (this.state.toggleStatus[i].isChecked == false) {
            // then remove it from the state array
            let ids = this.state.selectedIds;
            this.setState({
              selectedIds: _.pull(ids, id),
            });
          }
        }
      );
    }
  };
  // show asm dropdown
  showDropdown = () => {
    this.setState({
      showAsmDropdown: !this.state.showAsmDropdown,
    });
  };
  // function for asmDropdown component
  mapSalesmanWithAsmFunction = async (salesmanIds, reportingManagerId) => {
    this.setState({ loading: true });
    await mapSalesmanWithAsm(salesmanIds, reportingManagerId)
      .then((res) => {
        let msg =
          salesmanIds.length > 1
            ? "Unmapped salesmen have been"
            : "Unmapped salesman has been";
        message.success(
          `${msg} added to the mapped salesmen list Successfully!`
        );
        this.getSalesmenFunction();
        this.setState({
          showAsmDropdown: false,
          selectedIds: [],
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  openMappedSalesmanDetailsModal = async (item) => {
    await salesManDetails(item._id)
      .then((res) => {
        this.setState({ singleAsmDetails: res.data.data });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        console.log(err);
      });

    // this.setState({
    //   displayMappedSalesmanModal: !this.state.displayMappedSalesmanModal,
    // });
    this.setState({
      displayMappedSalesmanModal: true,
    });
  };

  onSearch = (e) => {
    this.setState(
      {
        search: e.target.value,
      },
      () => {
        this.getSalesmenFunction();
      }
    );
  };

  handleChange = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.getSalesmenFunction();
      }
    );
  };

  handleDelete = (id, fullName) => {
    this.setState({ selectedId: id, salesmanName: fullName });
    this.setState({ isSalemanModal: true });
  };

  handleCancel = (id) => {
    this.setState({ selectedId: id });
    this.setState({ isSalemanModal: false });
  };

  handleOtpCancel = (id) => {
    this.setState({ selectedId: id, isOTPEnterModal: false, otpError: "" });
    this.getSalesmenFunction();
  };
  handleCloseModal = () => {
    this.setState({
      displayMappedSalesmanModal: false,
    });
  };
  handleOtpInputClick = (id) => {
    this.setState({ selectedId: id });
    this.setState({
      isOTPEnterModal: true,
      isSalemanModal: false,
    });
  };

  //Get Salesman OTP Date Check
  getSalesmanOTPDate = (OTPDate) => {
    let isMoreThanADay = "";
    const now = Date.now();
    var date = new Date(OTPDate); // some mock date
    var milliseconds = date.getTime();
    const oneDay = 24 * 60 * 60 * 1000;
    isMoreThanADay = now - milliseconds > oneDay;
    if (isMoreThanADay) {
      return false;
    } else {
      return true;
    }
  };

  //Generate OTP for Salesman delete
  handleGenerateOtp = async () => {
    try {
      this.setState({ isLoading: true }); // set loading state to true
      const response = await generateOtp(this.state.selectedId);

      if (response?.status === 200) {
        this.setState({ isOTPEnterModal: true });
        this.setState({ isSalemanModal: false });
        message.success("OTP sent successfully");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false }); // set loading state to false
    }
  };

  //Delete Function
  handleDeleteSalesman = async (otpValue) => {
    try {
      await verifyOtp(this.state.selectedId, otpValue).then((result) => {
        if (result?.status == 200) {
          this.setState({ isOTPEnterModal: false });
          message.success(result.message);
          this.getSalesmenFunction();
        } else {
          this.setState({ otpError: result.message });
        }
      });
    } catch (error) {
      console.error("ERRRO", error);
    }
  };

  render() {
    const { userDetails } = this.state;
    console.log(userDetails);
    const dynamic = [
      userDetails && userDetails.isStandalone,
      userDetails && userDetails.warehouse && userDetails.warehouse.isHybrid,
    ].every((ele) => ele === true);
    const companyCode = userDetails && userDetails.companyCode;
    const accessRole = userDetails && userDetails.accessRole;
    const Standalone = userDetails && userDetails.isStandalone;
    console.log(Standalone);
    console.log(`${dynamic},${companyCode},${accessRole}`);
    let salesmanId;
    {
      this.state.salesmanList.map((item, i) => {
        return (salesmanId = item._id);
      });
    }
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const { TabPane } = Tabs;
    let {
      loading,
      scroller,
      salesmanType,
      removeId,
      salesmanList,
      selectedIds,
      toggleStatus,
      showAsmDropdown,
      totalPage,
      pageSize,
      totalItems,
      otpError,
      page,
      isStandalone,
      displayMappedSalesmanModal,
    } = this.state;
    return (
      <div id="content">
        <PrimaryHeader title="Salesmen" />
        <div className="salesman-header">
          <SearchBar searchText={this.state.search} onSearch={this.onSearch} />
          {Standalone == true ? (
            <Link
              to={{
                pathname: "/dashboard/team/salesmen/new",
                edit: false,
              }}
              className="id-color"
            >
              <div>
                <img
                  src={add}
                  alt="add asm"
                  className="cursor"
                  title="Add New Salesman"
                />
              </div>
            </Link>
          ) : accessRole == 1 ? (
            <Link
              to={{
                pathname: "/dashboard/team/salesmen/new",
                edit: false,
              }}
              className="id-color"
            >
              <div>
                <img
                  src={add}
                  alt="add asm"
                  className="cursor"
                  title="Add New Salesman"
                />
              </div>
            </Link>
          ) : (
            ""
          )}
          {selectedIds.length > 0 && salesmanType == "unmapped" && (
            <button className="mapped-btn" onClick={this.showDropdown}>
              Map
            </button>
          )}
          {selectedIds.length > 0 && showAsmDropdown && (
            <AsmDropdown
              asmOptions={this.state.asmOptions}
              selectedIds={selectedIds}
              mapSalesmanWithAsmFunction={this.mapSalesmanWithAsmFunction}
            />
          )}
        </div>
        <div className="mt-3">
          <Tabs
            onChange={(e) => {
              this.state.apiCalling == false && this.changeTab(e);
            }}
          >
            <TabPane
              tab="Mapped Salesmen"
              key="mapped"
              className="tabpane"
              disabled={this.state.apiCalling == true ? true : false}
            />

            <TabPane
              tab="Unmapped Salesmen"
              key="unmapped"
              disabled={this.state.apiCalling == true ? true : false}
              className={
                this.state.apiCalling == true
                  ? "tabpane pointerEvent"
                  : "tabpane"
              }
            />
          </Tabs>

          <div className="table-wrapper">
            <Spin indicator={antIcon} spinning={loading}>
              <table className="table common-table">
                {salesmanType == "unmapped" ? (
                  <thead>
                    <tr>
                      {Standalone == true ? (
                        <th>Select</th>
                      ) : accessRole == 0 ? (
                        <th>Select</th>
                      ) : (
                        ""
                      )}
                      <th>Employee</th>
                      <th>Employee ID</th>
                      <th>Contact No.</th>
                      <th>Employer</th>
                      <th>Customers</th>
                      {Standalone != true && accessRole == 1 ? (
                        <th>Distributor</th>
                      ) : (
                        ""
                      )}
                      <th className="change-style" id="status-color">
                        Status
                      </th>

                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                ) : (
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Employee ID</th>
                      <th>Contact No.</th>
                      <th>Employer</th>
                      <th>Customers</th>
                      {Standalone != true && accessRole == 1 ? (
                        <th>Distributor</th>
                      ) : (
                        ""
                      )}

                      {Standalone != true}
                      <th className="change-style" id="status-color">
                        Status
                      </th>
                      <th>Manager </th>
                      {Standalone == true ? (
                        <th>Action</th>
                      ) : accessRole == 1 ? (
                        <th>Action</th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                )}
                {salesmanList.length > 0 && (
                  <tbody>
                    {salesmanList.map((item, i) => {
                      return (
                        <tr
                          key={item._id}
                          className={item.status == 0 ? "disabled" : ""}
                        >
                          {salesmanType == "unmapped" && Standalone == true ? (
                            <td className="cursor" key={item.createdAt}>
                              <input
                                type="checkbox"
                                id={i}
                                className="check-box"
                                checked={
                                  toggleStatus[i].id == i &&
                                  toggleStatus[i].isChecked
                                }
                                key={i}
                                onChange={() => this.toggleChange(item, i)}
                              />
                            </td>
                          ) : (
                            ""
                          )}
                          {salesmanType == "unmapped" &&
                          Standalone != true &&
                          accessRole == 0 ? (
                            <td className="cursor" key={item.createdAt}>
                              <input
                                type="checkbox"
                                id={i}
                                className="check-box"
                                checked={
                                  toggleStatus[i].id == i &&
                                  toggleStatus[i].isChecked
                                }
                                key={i}
                                onChange={() => this.toggleChange(item, i)}
                              />
                            </td>
                          ) : (
                            ""
                          )}
                          <td
                            className="change-style on-Hover cursor"
                            title={item.firstName + " " + item.lastName}
                            onClick={() =>
                              item.status == 1 &&
                              this.openMappedSalesmanDetailsModal(item)
                            }
                          >
                            {item.profilePic ? (
                              <img
                                src={getImageUrl + item.profilePic}
                                style={{ borderRadius: "50%" }}
                                alt="logo"
                                id="user-logo"
                              />
                            ) : (
                              <img
                                src={defaultPic}
                                style={{
                                  borderRadius: "50%",
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "5px",
                                }}
                                alt=""
                              />
                            )}

                            {stringSortingByChar(
                              item.firstName + " " + item.lastName,
                              20
                            )}
                          </td>
                          {/* emp id */}
                          <td>{item.employeeId}</td>
                          {/* contact */}
                          <td>{item.contactMobile}</td>
                          {/* employer*/}
                          <td title={item.employerName}>{item.employerName}</td>
                          {/* customers */}
                          <td className="td-padding">
                            {item.numOfCustomersForTheDay}
                          </td>
                          {Standalone != true && accessRole == 1 ? (
                            <td>{item.distributorName}</td>
                          ) : (
                            ""
                          )}
                          {Standalone == true ? (
                            <td onClick={() => this.changeToggleStatus(item)}>
                              <img
                                src={
                                  item.status == 1
                                    ? activeToggle
                                    : inactiveToggle
                                }
                                alt="toggle"
                                name={item.status}
                              />
                            </td>
                          ) : accessRole == 1 ? (
                            <td onClick={() => this.changeToggleStatus(item)}>
                              <img
                                src={
                                  item.status == 1
                                    ? activeToggle
                                    : inactiveToggle
                                }
                                alt="toggle"
                                name={item.status}
                              />
                            </td>
                          ) : (
                            <td>
                              <div className="d-flex">
                                <div
                                  className={`mt-1 ${
                                    item.status == 1
                                      ? "active-dot"
                                      : "inActive-dot"
                                  }`}
                                ></div>
                                <div className="ml-2">
                                  {item.status == 1 ? "Active" : "Inactive"}
                                </div>
                              </div>
                            </td>
                          )}
                          {salesmanType == "mapped" && (
                            <td>
                              {item.asmMapping &&
                                item.asmMapping.asmId &&
                                item.asmMapping.asmId.firstName +
                                  " " +
                                  item.asmMapping.asmId.lastName}{" "}
                            </td>
                          )}
                          <td>
                            {Standalone == true ? (
                              <>
                                <Link
                                  to={{
                                    pathname: `/dashboard/team/salesmen/edit/${item._id}`,
                                    query: item._id,
                                    edit: true,
                                    employeeId: item.employeeId,
                                  }}
                                  className="id-color"
                                  disabled={item.status == 0 ? true : false}
                                >
                                  {salesmanType == "mapped" ? (
                                    // {item.isWaycoolEmp && salesmanType == "mapped" ? (
                                    <img
                                      src={edit}
                                      alt="edit"
                                      id="img-edit"
                                      className="ml-2"
                                    />
                                  ) : (
                                    " "
                                  )}
                                </Link>
                                {item?.deleteOTPdate &&
                                this.getSalesmanOTPDate(item?.deleteOTPdate) ? (
                                  <span
                                    style={{ color: "blue", cursor: "pointer" }}
                                    onClick={() =>
                                      this.handleOtpInputClick(item._id)
                                    }
                                  >
                                    Enter code
                                  </span>
                                ) : (
                                  <img
                                    src={trash}
                                    alt="edit"
                                    id="img-edit"
                                    className="ml-2"
                                    onClick={() =>
                                      this.handleDelete(item._id, item.fullName)
                                    }
                                  />
                                )}
                              </>
                            ) : Standalone != true && accessRole == 1 ? (
                              <Link
                                to={{
                                  pathname: `/dashboard/team/salesmen/edit/${item._id}`,
                                  query: item._id,
                                  edit: true,
                                  employeeId: item.employeeId,
                                }}
                                className="id-color"
                                disabled={item.status == 0 ? true : false}
                              >
                                {salesmanType == "mapped" ? (
                                  // {item.isWaycoolEmp && salesmanType == "mapped" ? (
                                  <img
                                    src={edit}
                                    alt="edit"
                                    id="img-edit"
                                    className="ml-2"
                                  />
                                ) : (
                                  " "
                                )}
                              </Link>
                            ) : (
                              ""
                            )}

                            {isStandalone == true ? (
                              <>
                                {item?.deleteOTPdate &&
                                this.getSalesmanOTPDate(item?.deleteOTPdate) ? (
                                  <span
                                    style={{ color: "blue", cursor: "pointer" }}
                                    onClick={() =>
                                      this.handleOtpInputClick(item._id)
                                    }
                                  >
                                    Enter code
                                  </span>
                                ) : (
                                  <img
                                    src={trash}
                                    alt="edit"
                                    id="img-edit"
                                    className="ml-2"
                                    onClick={() =>
                                      this.handleDelete(item._id, item.fullName)
                                    }
                                  />
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <SalesmanModal
                              isModalOpen={this.state.isSalemanModal}
                              selectedId={this.state.selectedId}
                              isModalClose={this.handleCancel}
                              handleGenerateOtp={this.handleGenerateOtp}
                              salesmanName={this.state.salesmanName}
                              isLoading={this.state.isLoading}
                            />
                            <SalesmanModal
                              isOpen={this.state.isOTPEnterModal}
                              otpError={otpError}
                              handleDeleteSalesman={this.handleDeleteSalesman}
                              isClose={this.handleOtpCancel}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </Spin>
          </div>

          {this.state.showDeleteConfirmation && (
            <DeleteConfirmation
              closeDeleteConfirmationModal={this.closeDeleteConfirmationModal}
              removeId={removeId}
              header={"Remove Salesman"}
              warningMsg={`Are you sure you want to delete the salesman with employee ID ${removeId}?`}
              removeItem={this.removeSalesmanFun}
            />
          )}
          {this.state.displayMappedSalesmanModal ? (
            <MappedSalesManDetails
              displayModal={this.openMappedSalesmanDetailsModal}
              details={
                this.state.singleAsmDetails && this.state.singleAsmDetails
              }
              salesmanType={salesmanType}
              handleCloseModal={this.handleCloseModal}
              //  visible ={this.state.displayMappedSalesmanModal}
            />
          ) : (
            ""
          )}
        </div>
        {/* pagination */}

        <div className="pagination">
          {totalItems != 0 && (
            <p className="p-2">Total {totalItems} Salesmen </p>
          )}
          {totalPage != 0 && (
            <Pagination
              className="page"
              defaultCurrent={1}
              current={page}
              total={totalPage}
              defaultPageSize={pageSize}
              showSizeChanger={false}
              onChange={(page, pageSize) => this.handleChange(page, pageSize)}
            />
          )}
        </div>
        {}
      </div>
    );
  }
}

export default TeamSalesmen;
export { TeamSalesmen };
