import React from 'react';
import addDay from '../../images/addDay.svg';

export const ShowAdd = ({ addDayFunction, dayName }) => {
  let title = `Add the day to beatplan`;
  return (
    <div className="addDay" title={title}>
      <img src={addDay} alt="add" onClick={() => addDayFunction(dayName)} />
    </div>
  );
};
