import React, { Component } from "react";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { Spin } from "antd";
import PrimaryHeader from "../header/PrimaryHeader";
import "./settings.scss";
import Pdwaycool from "../../../src/images/Pdwaycool.svg";
import GarudaDms from "../../../src/images/GarudaDms.svg";
import { LoadingOutlined } from "@ant-design/icons";
import garuda from "../../../src/images/garuda.svg";
import salesexecutive from "../../../src/images/salesexecutive.svg";
import waycool from "../../../src/images/waycoollogo.svg";
import { Switch } from "antd";
import { getProfileDetails } from "../../services/userProfileServices/userProfile";
import {
  dmsOtpValidation,
  pdwaycoolOtpValidation,
  distributorPortalOtpValidation,
} from "../../services/settings/settings";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      warehouseId: "",
      dmsOtpStatus: "",
      pdWaycoolOtpStatus: "",
      salesOrg: "",
      plant: "",
      distributionChannel: "",
      distSoOtpStatus: "",
      distributorTYpe: "",
      waycoolType: "",
      distributorportalOtpStatus: "",
    };
  }

  getUserDetails = async () => {
    this.setState({ loading: true });
    await getProfileDetails()
      .then((res) => {
        console.log(res, "hhhhhhhhhhh");
        this.setState({
          loading: false,
          warehouseId: res.data.data.warehouse._id,
          dmsOtpStatus: res.data.data.warehouse.dmsOtp,
          pdWaycoolOtpStatus: res.data.data.warehouse.pdOtp,
          waycoolSoOtpStatus: res.data.data.warehouse.waycoolSoOtpStatus,
          distSoOtpStatus: res.data.data.warehouse.distSoOtpStatus,
          salesOrg: res.data.data.warehouse.salesOrg,
          plant: res.data.data.warehouse.plant,
          distributionChannel: res.data.data.warehouse.distributionChannel,
        });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        this.setState({ loading: false });
      });
  };

  pdwaycoolToggleChange = async () => {
    this.setState({ loading: true });
    // let { plant, salesOrg, distributionChannel, waycoolSoOtpStatus } =
    //   this.state;
    // let newStatus = this.state.waycoolSoOtpStatus === 1 ? "true" : "false";
    let actionType = this.state.waycoolSoOtpStatus === true ? "false" : "true";
    let type = this.state.waycoolType;
    await pdwaycoolOtpValidation(type, actionType)
      .then((res) => {
        this.setState({ loading: false });
        if (actionType == "enabled") {
          message.success("Waycool Portal Otp has been enabled successfully.");
        } else if (
          res.data.message &&
          res.data.message.isStatusChangeAllowed == false &&
          actionType == "disabled"
        ) {
          message.success(
            "You Are Not Authorised to Disable OTP Verification !"
          );
        } else {
          message.success("Waycool OTP has been disabled successfully.");
        }
        this.getUserDetails();
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  dmsToggleChange = async () => {
    this.setState({ loading: true });
    let actionType = this.state.dmsOtpStatus === 1 ? "disabled" : "enabled";
    let id = this.state.warehouseId;
    await dmsOtpValidation(id, actionType)
      .then((res) => {
        this.setState({ loading: false });
        // {
        //     actionType == "enabled" &&
        //         message.success("Dms Otp has been enabled successfully.");
        // }
        // {
        //     actionType == "disabled" &&
        //         message.success("Dms Otp has been disabled successfully.");
        // }
        // this.getUserDetails();
        if (actionType == "enabled") {
          message.success("Dms Otp has been enabled successfully.");
        } else if (
          res.data.message &&
          res.data.message.isStatusChangeAllowed == false &&
          actionType == "disabled"
        ) {
          message.success(
            "You Are Not Authorised to Disable OTP Verification !"
          );
        } else {
          message.success("Dms Otp has been disabled successfully.");
        }
        this.getUserDetails();
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        this.setState({ loading: false });
      });
  };
  distributorToggleChange = async () => {
    this.setState({ loading: true });
    console.log(this.state.distSoOtpStatus, "distributorportalOtpStatus");
    let actionType = this.state.distSoOtpStatus === true ? "false" : "true";
    let type = this.state.distributorTYpe;
    await distributorPortalOtpValidation(type, actionType)
      .then((res) => {
        this.setState({ loading: false });
        if (actionType == "enabled") {
          message.success(
            "Distributor Portal Otp has been enabled successfully."
          );
        } else if (
          res.data.message &&
          res.data.message.isStatusChangeAllowed == false &&
          actionType == "disabled"
        ) {
          message.success(
            "You Are Not Authorised to Disable OTP Verification !"
          );
        } else {
          message.success(
            "Distributor Portal OTP has been disabled successfully."
          );
        }
        this.getUserDetails();
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        this.setState({ loading: false });
      });
  };

  componentDidMount = async () => {
    this.getUserDetails();
  };

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let { loading, dmsOtpStatus, waycoolSoOtpStatus, distSoOtpStatus } =
      this.state;
    return (
      <div className="mt-3 ml-2">
        <Spin indicator={antIcon} spinning={loading}>
          <div className="sales-order-text pl-1">
            Sales Order OTP Validation
          </div>
          <div className="mr-3 card mt-4 ">
            <div className="row mt-4 ml-2 mb-4">
              <div className="col-lg-1">
                {" "}
                <img src={waycool} />{" "}
              </div>
              <div className="col-lg-8">
                <p className="pdwaycool ml-2">Waycool App</p>
                <p className="ml-2 mt-n2 pdwaycool-text">
                  Enabling this will require customer to input an{" "}
                  <span className="otp">OTP</span> in order to Place an Order.
                </p>
              </div>
              <div className="col-lg-2 mt-2 ml-5">
                <Switch
                  checked={waycoolSoOtpStatus == true ? true : false}
                  onChange={this.pdwaycoolToggleChange}
                />
              </div>
            </div>
          </div>
          <div className="mr-3 card mt-4 ">
            <div className="row mt-4 ml-3 mb-4">
              <div className="col-lg-1">
                <img src={salesexecutive} />
              </div>
              <div className="col-lg-8">
                <p className="pdwaycool ml-2">Sales Executive App (Garuda)</p>
                <p className="ml-2 mt-n2 pdwaycool-text">
                  <span className="otp">OTP</span> required from customer in
                  order to Place an Order.
                </p>
              </div>
              <div className="col-lg-2 mt-2 ml-5">
                <Switch
                  checked={dmsOtpStatus == 1 ? true : false}
                  onChange={this.dmsToggleChange}
                />
              </div>
            </div>
          </div>
          <div className="mr-3 card mt-4 ">
            <div className="row mt-4 ml-3 mb-4">
              <div className="col-lg-1">
                <img src={garuda} />{" "}
              </div>
              <div className="col-lg-8">
                <p className="pdwaycool ml-2">Distributor portal (Garuda)</p>
                <p className="ml-2 mt-n2 pdwaycool-text">
                  <span className="otp">OTP</span> required from customer in
                  order to Place an Order.
                </p>
              </div>
              <div className="col-lg-2 mt-2 ml-5">
                <Switch
                  checked={distSoOtpStatus == true ? true : false}
                  onChange={this.distributorToggleChange}
                />
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default Settings;
