import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Profile from "../components/profile/profile";

//Action Creator
// import { getAllCustomers, syncWithGoFrugal } from "../action/customers";

const mapStateToProps = (state) => {
  return {
    profileDetails: state.user.profileDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      // getAllCustomers,
      // syncWithGoFrugal
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
