import axios from "axios";
import { getAuthToken } from "../../utils/Auth";
import { api as APIConfig } from "../../appConfig";
import { getQparams } from "../../utils/common";

export const getStockStorageLocation = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/currentStock/getStorageLocation` +
    qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getStockBatchId = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/currentStock/getBatchId` +
    qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getMaterialCode = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/currentStock/getMaterialCodeName` +
    qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getMaterialType = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/currentStock/getMaterialType` +
    qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getMaterialGroup = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/currentStock/getMaterialGroup` +
    qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getSearchClosingStock = async (date, body) => {
  let request = await axios.post(
    APIConfig.base_url +
    `api/v1/inventory_management/currentStock/searchListing?date=${date}`,
    { body },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
export const getStockType = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/closingStock/stockTypeMinifiedList` +
    qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
export const getSearchClosingStockByStockType = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/closingStock/searchListing${qUrl}`,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const downloadDoc = async (from, to) => {
  // let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/download/closingStock?from=${from}&to=${to}`,
    // +{ qUrl }
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get stock audit details
export const getStockAuditList = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/stock-audit/getStockDetails` +
    qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//for updating stock audit list
export const updateStockAuditList = async (data) => {
  let request = await axios.put(
    APIConfig.base_url +
    `api/v1/inventory_management/stock-audit/updateStockDetails`,

    { ...data },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get search list in stock movement
export const getStockMovementList = async (
  itemId,
  storageLocation,
  batchId
) => {
  // let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/searchResult/${itemId}/${storageLocation}/${batchId}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//for downloading stock audit list
export const getStockAuditDownload = async (qParams) => {
  let qUrl = getQparams(qParams);

  //open in same tab
  // window.open(
  //   `${APIConfig.base_url}api/v1/inventory_management/stock-audit/downloadStockDetails` +
  //     qUrl
  // );
  let request = await axios.get(
    `${APIConfig.base_url}api/v1/inventory_management/stock-audit/downloadStockDetails` +
    qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  console.log("Return------------>", request);
  return request;
};

//for uploading a file in stock audit using ReactFileReader
export const uploadStockAuditFile = async (file, qParams) => {
  let qUrl = getQparams(qParams);
  const formData = new FormData();
  formData.append("file", file);
  let request = await axios.post(
    APIConfig.base_url +
    `api/v1/inventory_management/stock-audit/uploadStockDetails`,

    formData,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//updateStockMovement data
export const updateStockMovement = async (batchId, qty, qParams, inputs) => {
  let qUrl = getQparams(qParams);
  let url =
    `api/v1/inventory_management/updateStockMovement/${batchId}/${qty}` + qUrl;
  let request = await axios.patch(
    APIConfig.base_url + url,
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get stockvarance filer data
export const getStockVarianceList = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/stock-variance/getStockVarianceList` +
    qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//for downloading stock variance data list
export const getStockVarianceDownload = async (start, to) => {
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/download/stockvariance-reports?fromDate=${start}&toDate=${to}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//for getting materialJourneyData
export const getMaterialJourney = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/inventory_management/material-search` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//for getting material journey road map
export const materialJourneyRoadMap = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/inventory_management/material-roadmap` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//for getting material journey road map
export const getMaterialJourneyNewAPI = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/material-roadmap/overall-clone` +
    qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  let resp = request?.data;
  return resp;
};

//for getting material journey road map
export const materialJourneyRoadMapforAll = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/material-roadmap/overall` +
    qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getSearchClosingStockByMaterialDetails = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/currentStock/searchListing${qUrl}`,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//upload price API
export const uploadFilePrice = async (file, plant, qParams) => {
  let qUrl = getQparams(qParams);
  const formData = new FormData();
  formData.append("file", file);
  formData.append("plant", plant);
  let request = await axios.post(
    APIConfig.Sap_url + `index.php/pricing_sheet_upload_forportal`,

    formData,
    plant,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//CurrentPricingLists
export const getPriceList = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/stock-audit/currentStockAndprice${qUrl}`,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//Patch Each Object Selling Price
export const patchCurrentPrice = async (inputs) => {
  let url = `index.php/pricingUpdate`;
  let request = await axios.post(
    APIConfig.generate_so_url + url,
    {
      ...inputs,
    },
    {
      headers: {
        "X-Authorization":
          "bearer dybhOxOVxuDlrBCpOE0mC4s4aJd5WZqBmQ8P1NF3MmP17xmoqzu23Zm5TVPmdYrZ",
      },
    }
  );
  return request;
};

export const reportDownloadService = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
    `api/v1/inventory_management/stock-audit/DownloadcurrentStockAndprice${qUrl}`,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const DownloadPriceSample =
  APIConfig.Sap_url + `downloads/sample_pricing.csv`;
