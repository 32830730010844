import { LoadingOutlined } from "@ant-design/icons";
import { Checkbox, DatePicker, Input, Pagination, Spin, Tabs } from "antd";
import message from "antd/lib/message";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import PrimaryHeader from "../../components/header/PrimaryHeader";
import {
  downloadSO,
  getAllSoOrderList,
  getCustomerDropdown,
  printSO,
} from "../../services/salesOrder/salesOrderServices";
import { handleErrorResponse } from "../../utils/Request";
import "./salesOrder.scss";

import ReactToPrint, { useReactToPrint } from "react-to-print";
import SuccessModalNew from "../base/SuccessModalNew";
import Select from "./innerComponent/Select";
import ComponentToPrint from "./printData";

const dateFormat = "DD/MM/YYYY";

const { TabPane } = Tabs;
const { Search } = Input;
const antIcon = <LoadingOutlined className="loadingIcon" spin />;

export default function SalesOrderAll(props) {
  const [tab, setTab] = useState("pending");
  const [downloadModal, setdownloadModal] = useState(false);
  const [id, setId] = useState("");
  const [printModal, setPrintModal] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [skipPage, setSkipPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalitems, setTotalitems] = useState(0);
  const [pendingArr, setPendingArr] = useState([]);
  const [historyArr, setHistoryArr] = useState([]);
  const [invoicedArr, setInvoicedArr] = useState([]);
  const [historyPage, setHistoryPage] = useState(1);
  const [invoicedPage, setInvoicedPage] = useState(1);
  const [historytotalitems, sethistoryTotalitems] = useState(0);
  const [invoicedtotalitems, setinvoicedTotalitems] = useState(0);
  const [searchValue, setSearchValue] = React.useState("");
  const [checkBox, setCheckBox] = useState({});
  const [printCheckBox, setprintCheckBox] = useState({});
  const [invoiceId, setInvoiceId] = useState("");
  const [invoiceResponseData, setinvoiceResponseData] = useState([]);
  const [distributorData, setDistributorData] = useState({});
  const [soid, setsoid] = useState("");
  const [isTaxSummaryShow, setIsTaxSummayShow] = useState();

  const [customerFilter, setCustomerFilter] = useState([]);
  const [customerDefaultValue, setCustomerDefaultValue] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [printSize, setPrintSize] = useState('A4')

  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handleP = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Invoice",
    removeAfterPrint: true,
  });

  const handlePrint = async () => {
    setIsPrint(true);
    setTimeout(async () => {
      await handleP();
    }, 3000);
    setTimeout(async () => {
      setIsPrint(false);
    }, 4000);
  };

  const [filterValues, setFilterValues] = React.useState({
    orderDate: "",
    fulfillmentStatus: "",
    deliveryDate: "",
    orderStatus: "",
    paymentType: "",
  });
  const onChangeTab = (key) => {
    setTab(key);
    setPage(1);
    setCustomerDefaultValue([]);
    setCustomerFilter([]);
    props.history.push({
      state: { tab: key },
    });
    setdownloadModal(false);
    setCheckBox({});
    setSearchValue("");
    setFilterValues((prev) => ({
      ...prev,
      orderDate: "",
      orderStatus: "",
      deliveryDate: "",
      paymentType: "",
      fulfillmentStatus: "",
      customerId: "",
      searchText: "",
    }));
    if (key == "pending") {
      setSearchText("");
      let filterParams = dynamicFilters();
      let Params = [
        { page: page },
        { pageSize: limit },
        { status: "pending" },
        ...filterParams,
      ];
      getPendingList(Params);
    }
    if (key == "invoiced") {
      setSearchText("");
      let filterParams = dynamicFilters();
      let Params = [
        { page: page },
        { pageSize: limit },
        { status: "invoiced" },
        ...filterParams,
      ];
      getInvoicedList(Params);
    }
    if (key == "history") {
      setSearchText("");
      let filterParams = dynamicFilters();
      let Params = [
        { page: page },
        { pageSize: limit },
        { status: "history" },
        ...filterParams,
      ];
      getHistoryList(Params);
    }
  };

  const closeModal = (type) => {
    if (type == "download") {
      setdownloadModal(false);
    } else {
      setPrintModal(false);
    }
  };

  const navigateToSODetail = (id, soid) => {
    setId(id);
    setsoid(soid);
    props.history.push({
      pathname: `/dashboard/sales-order/sales-order-all-so-detail/${id}?tab=${tab}`,
      state: { tab: tab, soid: soid },
    });
  };

  const navigaeToInvoiceDetail = (id, invoiceId) => {
    setId(id);
    setsoid(soid);
    props.history.push({
      pathname: `/dashboard/sales-order/sales-order-all-print/${invoiceId}`,
      state: { tab: tab, soid: soid },
    });
  };

  useEffect(() => {
    if ((props.location.state && props.location.state.tab) == "pending") {
      setTab("pending");

      let filterParams = dynamicFilters();
      let Params = [
        { page: page },
        { pageSize: limit },
        { status: "pending" },
        ...filterParams,
      ];
      getPendingList(Params);
    } else if (
      (props.location.state && props.location.state.tab) == "invoiced"
    ) {
      setTab("invoiced");
      let filterParams = dynamicFilters();
      let Params = [
        { page: page },
        { pageSize: limit },
        { status: "invoiced" },
        ...filterParams,
      ];
      getInvoicedList(Params);
    } else if (
      (props.location.state && props.location.state.tab) == "history"
    ) {
      setTab("history");
      let filterParams = dynamicFilters();
      let Params = [
        { page: page },
        { pageSize: limit },
        { status: "history" },
        ...filterParams,
      ];
      getHistoryList(Params);
    } else if (tab == "pending") {
      setTab("pending");

      let filterParams = dynamicFilters();
      let Params = [
        { page: page },
        { pageSize: limit },
        { status: "pending" },
        ...filterParams,
      ];
      getPendingList(Params);
    } else if (tab == "invoiced") {
      setTab("invoiced");

      let filterParams = dynamicFilters();
      let Params = [
        { page: page },
        { pageSize: limit },
        { status: "invoiced" },
        ...filterParams,
      ];
      getInvoicedList(Params);
    } else {
      setTab("history");
      let filterParams = dynamicFilters();
      let Params = [
        { page: page },
        { pageSize: limit },
        { status: "history" },
        ...filterParams,
      ];
      getHistoryList(Params);
    }
  }, [
    filterValues["deliveryDate"],
    filterValues["paymentType"],
    filterValues["orderDate"],
    filterValues["fulfillmentStatus"],
    filterValues["orderStatus"],
    page,
    searchText,
  ]);

  useEffect(() => {
    if (tab == "pending") {
      setTab("pending");

      let filterParams = dynamicFilters();
      let Params = [
        { page: page },
        { pageSize: limit },
        { status: "pending" },
        ...filterParams,
      ];
      getPendingList(Params);
    } else if (tab == "history") {
      let filterParams = dynamicFilters();
      let Params = [
        { page: page },
        { pageSize: limit },
        { status: "history" },
        ...filterParams,
      ];
      getHistoryList(Params);
    }
  }, [customerFilter]);

  const onSearch = (value) => {
    console.log(value);
    if (value != "") {
      setSearchText(true);
      setSearchValue(value);
    }
    if (value == "") {
      setSearchText(false);
    }
  };

  const resetFilters = () => {
    setCustomerDefaultValue([]);
    setCustomerFilter([]);
    setFilterValues((prev) => ({
      ...prev,
      orderDate: "",
      orderStatus: "",
      deliveryDate: "",
      paymentType: "",
      fulfillmentStatus: "",
      searchText: "",
    }));
  };

  async function getPendingList(Params) {
    setLoading(true);

    await getAllSoOrderList(Params)
      .then((res) => {
        setPendingArr(res.data.response.data);
        setPageSize(res.data.response.totalPages * 7);
        setLoading(false);
        setSkipPage(res.data.response.skip);

        setTotalitems(res.data.response.totalRecords);
        setSearchText(false);
      })

      .catch((err) => {
        console.log(err, "errrr");
        setLoading(false);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  }

  async function getInvoicedList(Params) {
    setLoading(true);

    await getAllSoOrderList(Params)
      .then((res) => {
        setInvoicedArr(res.data.response.data);
        setPageSize(res.data.response.totalPages * 7);
        setLoading(false);
        setSkipPage(res.data.response.skip);
        setinvoicedTotalitems(res.data.response.totalRecords);
        setSearchText(false);
      })

      .catch((err) => {
        console.log(err, "errrr");
        setLoading(false);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  }

  async function getHistoryList(Params) {
    setLoading(true);

    await getAllSoOrderList(Params)
      .then((res) => {
        setHistoryArr(res.data.response.data);
        setPageSize(res.data.response.totalPages * 7);
        setLoading(false);
        setSkipPage(res.data.response.skip);
        sethistoryTotalitems(res.data.response.totalRecords);
        setSearchText(false);
      })

      .catch((err) => {
        console.log(err, "errrr");
        setLoading(false);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  }
  const handlePageChange = (page, pageSize) => {
    if (tab == "pending") {
      let filterParams = dynamicFilters();
      setPage(page);
      setPageSize(pageSize);
      let Params = [
        { page },
        { pageSize: limit },
        { status: "pending" },
        { searchText: searchValue },
        ...filterParams,
      ];
      getPendingList(Params);
    }
    if (tab == "invoiced") {
      let filterParams = dynamicFilters();
      setInvoicedPage(page);
      setPageSize(pageSize);
      let Params = [
        { page },
        { pageSize: limit },
        { status: "invoiced" },
        { searchText: searchValue },
        ...filterParams,
      ];
      getInvoicedList(Params);
    }
    if (tab == "history") {
      setHistoryPage(page);
      setPageSize(pageSize);
      let filterParams = dynamicFilters();
      let Params = [
        { page },
        { pageSize: limit },
        { status: "history" },
        { searchText: searchValue },
        ...filterParams,
      ];
      getHistoryList(Params);
    }
  };

  const paymentOptions = [
    { key: "cod", value: "cod", label: "COD" },
    { key: "credit", value: "credit", label: "Credit" },
    { key: "waycoolCredit", value: "waycoolCredit", label: "WaycoolCredit" },
    { key: "paylater", value: "paylater", label: "Paylater" },
    { key: "online", value: "online", label: "Online" },
  ];
  const orderStaus = [
    {
      key: "Partially processed",
      value: "Partially processed",
      label: "Partially processed",
    },
    {
      key: "Completely processed",
      value: "Completely processed",
      label: "Completely processed",
    },
  ];
  const fulfillmentOptions = [
    { key: "Partially Fulfilled", value: "1", label: "Partially Fulfilled" },
    { key: "Completely Fulfilled", value: "2", label: "Completely Fulfilled" },
  ];

  useEffect(() => {
    handleGetCustomerList();
  }, []);

  const handleGetCustomerList = async () => {
    await getCustomerDropdown().then((res) => {
      if (res?.data?.data) {
        let formedArray = [];
        let result = res?.data?.data;
        result.forEach((list) => {
          let data = {
            value: list.value,
            label: `${list.empId} - ${list.label}`,
          };
          formedArray.push(data);
        });
        setCustomerList(formedArray);
      }
    });
  };

  const handleDropdownChange = (e, type) => {
    if (type == "search") {
      setSearchValue(e.target.value);
    }
    if (type == "paymentType") {
      setFilterValues((prev) => ({
        ...prev,
        paymentType: e ? e : "",
      }));
    }
    if (type == "fulfillmentStatus") {
      setFilterValues((prev) => ({
        ...prev,
        fulfillmentStatus: e ? e : "",
      }));
    }
    if (type == "orderStatus") {
      setFilterValues((prev) => ({
        ...prev,
        orderStatus: e ? e : "",
      }));
    }
    if (type == "customerId") {
      console.log(e);
      let arr = [];
      e &&
        e.length > 0 &&
        e.forEach((val) => {
          arr.push(val.value);
        });
      setCustomerDefaultValue(e);
      setCustomerFilter(arr);
    }
    if (type == "deliveryDate") {
      setFilterValues((prev) => ({
        ...prev,
        deliveryDate: e ? moment(e._d) : "",
      }));
    }
    if (type == "orderDate") {
      setFilterValues((prev) => ({
        ...prev,
        orderDate: e ? moment(e._d) : "",
      }));
    }
  };

  const dynamicFilters = () => {
    let qParams = [];
    if (filterValues["orderDate"] != "") {
      qParams = [
        ...qParams,
        {
          orderDate: moment(filterValues["orderDate"]).format("YYYY-MM-DD"),
        },
      ];
    }

    if (filterValues["deliveryDate"] != "") {
      qParams = [
        ...qParams,
        {
          deliveryDate: moment(filterValues["deliveryDate"]).format(
            "YYYY-MM-DD"
          ),
        },
      ];
    }
    if (filterValues["orderStatus"] != "") {
      qParams = [
        ...qParams,
        {
          orderStaus: filterValues["orderStatus"].value,
        },
      ];
    }
    if (customerFilter && customerFilter.length > 0) {
      qParams = [
        ...qParams,
        {
          customerId: customerFilter,
        },
      ];
    }
    if (filterValues["fulfillmentStatus"] != "") {
      qParams = [
        ...qParams,
        {
          fulfillmentStatus: filterValues["fulfillmentStatus"].value,
        },
      ];
    }
    if (filterValues["paymentType"] != "") {
      qParams = [
        ...qParams,
        {
          paymentType: filterValues["paymentType"].value,
        },
      ];
    }
    if (searchValue != "") {
      qParams = [...qParams, { searchText: searchValue }];
    }

    return qParams;
  };
  const handelCheckBox = (e) => {
    let key = Object.keys(e)[0];
    let key2 = Object.keys(e)[1];
    let value = Object.values(e)[0]; //_id of the checked row
    let value2 = Object.values(e)[1]; //_id of the checked row
    // checkHandleStatus(key2, value2);

    if (key === "checkbox") {
      let handleCheckBox = Object.assign({}, checkBox);
      //if id already exists then it means user unchecks the checkbox, in that case remove it from the state array
      if (handleCheckBox.hasOwnProperty(value)) {
        delete handleCheckBox[value];
        return setCheckBox(handleCheckBox);
      }
      Object.assign(handleCheckBox, { [value]: value });
      setCheckBox(handleCheckBox);
      console.log(handleCheckBox);
    }
  };

  const downloadSOIds = async () => {
    let arr = [];
    for (const key in checkBox) {
      arr.push(checkBox[key]);
    }
    console.log(arr);
    setLoading(true);
    let inputs = {
      soIds: arr,
    };
    await downloadSO(inputs)
      .then((res) => {
        setLoading(false);
        let blob = new Blob([res.data], { type: "text/csv" });
        saveAs(blob, `sales_order_report.csv`);
        setdownloadModal(true);
        setTimeout(() => {
          setdownloadModal(false);
        }, 2000);
        setCheckBox({});
      })

      .catch((err) => {
        console.log(err, "errrr");
        setLoading(false);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  const handelPrintCheckBox = (e) => {
    let key = Object.keys(e)[0];
    let key2 = Object.keys(e)[1];
    let value = Object.values(e)[0];
    let value2 = Object.values(e)[1];

    if (key === "printCheckBox") {
      let handleCheckBox = Object.assign({}, printCheckBox);
      //if id already exists then it means user unchecks the checkbox, in that case remove it from the state array
      if (handleCheckBox.hasOwnProperty(value)) {
        delete handleCheckBox[value];
        return setprintCheckBox(handleCheckBox);
      }
      Object.assign(handleCheckBox, { [value]: value });
      setprintCheckBox(handleCheckBox);
      console.log(handleCheckBox);
    }
  };

  const downloadInvoiceIds = async () => {
    let arr = [];
    for (const key in printCheckBox) {
      arr.push(printCheckBox[key]);
    }
    setLoading(true);
    let inputs = {
      InvoiceId: arr,
      isPrint: true,
    };
    setInvoiceId(arr[0]);
    console.log(inputs);
    await printSO(inputs)
      .then(async (res) => {
        setLoading(false);
        setPrintModal(true);
        setTimeout(() => {
          setPrintModal(false);
        }, 2000);
        setprintCheckBox({});
        let invoiceData = res?.data?.data?.invoiceDetails;
        let distributorData = res?.data?.data?.distributorData;
        let taxSummaryShow = res?.data?.data?.companyDetails?.isShowInvoiceTaxSummary;
        setIsTaxSummayShow(taxSummaryShow);
        setinvoiceResponseData(invoiceData);
        setDistributorData(distributorData);
        if (res.data.data) {
          await handlePrint();
        }
      })

      .catch((err) => {
        console.log(err, "errrr");
        setLoading(false);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const pendingBodyList =
    pendingArr.length > 0 &&
    pendingArr.map((item) => {
      return (
        <tr
          className={`${
            item?.isDownloaded && item?.isDownloaded == true && "bg-gray"
          }`}
        >
          <td>
            <Checkbox
              onChange={() =>
                handelCheckBox({
                  checkbox: item.so_no,
                })
              }
              checked={checkBox.hasOwnProperty(item.so_no) ? true : false}
              indeterminate={checkBox.hasOwnProperty(item.so_no) ? true : false}
            />
          </td>
          <td>{item.order_date}</td>
          <td
            className="cursor"
            onClick={() => navigateToSODetail(item._id, item.so_no)}
          >
            {item.so_no}
          </td>
          <td>{item.delivery_date}</td>
          <td>
            {item.customerName}
            <br />#{item.customerId}
          </td>
          <td>{item.payment_type}</td>
        </tr>
      );
    });
  const invoicedBodyList =
    invoicedArr.length > 0 &&
    invoicedArr.map((item) => {
      return (
        <tr
          className={`${
            item?.isPrinted && item?.isPrinted == true && "bg-gray"
          }`}
        >
          <td>
            <Checkbox
              onChange={() =>
                handelPrintCheckBox({
                  printCheckBox: item.invoiceNo,
                })
              }
              checked={
                printCheckBox.hasOwnProperty(item.invoiceNo) ? true : false
              }
              indeterminate={
                printCheckBox.hasOwnProperty(item.invoiceNo) ? true : false
              }
            />
          </td>
          <td>{item.delivery_date}</td>
          <td
            className="cursor"
            onClick={() => {
              if (item?.invoiceNo && item.invoiceNo.length > 0) {
                navigaeToInvoiceDetail(item._id, item.invoiceNo);
              } else {
                message.error("Invoice number not exist");
              }
            }}
          >
            {item.so_no}
          </td>
          <td>{item.invoiceNo}</td>
          <td>
            {item.customerName}
            <br />#{item.customerId}
          </td>
          <td>
            {item.fulfillment_status == "2" ? (
              <span class="dot" style={{ backgroundColor: "#328D41" }}>
                <span className="ml-3"> Fulfilled</span>
              </span>
            ) : (
              <span class="dot" style={{ backgroundColor: "#F2994A" }}>
                <span className="ml-3"> Partially Fulfilled</span>
              </span>
            )}
          </td>
        </tr>
      );
    });

  const historyBodyList =
    historyArr.length > 0 &&
    historyArr.map((item) => {
      return (
        <tr onClick={() => navigateToSODetail(item._id, item.so_no)}>
          <td>{item.order_date}</td>
          <td>{item.so_no}</td>
          <td>{item.invoiceNo}</td>
          <td>{item.delivery_date}</td>
          <td>
            {item.customerName}
            <br />#{item.customerId}
          </td>
          <td>{item.payment_type}</td>
          <td>
            {item.fulfillment_status == 2 ? (
              <span className="dot" style={{ backgroundColor: "#219653" }}>
                <span className="ml-3"> Invoiced</span>
              </span>
            ) : (
              <span className="dot" style={{ backgroundColor: "#E13440" }}>
                <span className="ml-3"> Sales Return</span>
              </span>
            )}
          </td>
        </tr>
      );
    });

  const pageStyleA5 = `@page {
      size: 210mm 148mm;
      }
      @media print {
      @page {  size: a5 landscape;
          margin: 0mm !important;
      }
      @media all {
                      .pagebreak {
                        overflow: visible; 
                      }
                  }
              }
          }`;

  const pageStyleA4 = `@page {
      size: A4;
      margin: 0;
    }
    @media print {
      html, body {
        width: 210mm;
        height: 297mm;
      }
    }`;

  return (
    <div id="content" className="">
      <PrimaryHeader title={"Sales Order"} />
      <Spin indicator={antIcon} spinning={loading}>
        <Tabs activeKey={tab} onChange={onChangeTab} className="">
          <TabPane tab="Pending" key="pending">
            <div className="col-lg-5  mt-4">
              <Search
                placeholder="Search by SO ID "
                enterButton
                onSearch={(e) => {
                  onSearch(e);
                }}
                onChange={(e) => handleDropdownChange(e, "search")}
                value={searchValue}
                className="customSearch"
              />
            </div>
            <button
              className="btn btn-primary mt-4 ml-4"
              onClick={downloadSOIds}
              disabled={Object.values(checkBox).length === 0}
            >
              Download
            </button>

            <div className="d-flex align-items-center">
              <div className="purchase_picker border_right">
                <DatePicker
                  format={dateFormat}
                  className=" mt-1 "
                  placeholder="Order Date"
                  onChange={(e) => handleDropdownChange(e, "orderDate")}
                  value={filterValues["orderDate"]}
                />
              </div>
              <div className=" franchise  col-lg-2 ml-2">
                <Select
                  name="customer-select"
                  isMulti
                  options={customerList}
                  defaultValue={customerDefaultValue}
                  placeholder="Customer ID/Name"
                  onChange={(e) => handleDropdownChange(e, "customerId", 500)}
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
              <div className="purchase_picker ml-3 borderLeft border_right">
                <DatePicker
                  format={dateFormat}
                  className="mt-1"
                  placeholder="Delivery Date"
                  onChange={(e) => handleDropdownChange(e, "deliveryDate")}
                  value={filterValues["deliveryDate"]}
                />
              </div>

              <div className="franchise col-lg-2 ml-2">
                <AsyncPaginate
                  className="basic-multi-select"
                  additional={{
                    page: 1,
                  }}
                  isClearable={true}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  onChange={(e) => handleDropdownChange(e, "paymentType", 500)}
                  loadingMessage={() => "No Dates Found"}
                  placeholder={"Payment Type"}
                  options={paymentOptions}
                  value={filterValues["paymentType"]}
                />
              </div>
              <div className=" ml-4 pt-2" onClick={resetFilters}>
                <label htmlFor="" className="cursor pending_label">
                  {"Reset Filter"}
                </label>
              </div>
            </div>

            <div className="mt-4  table-wrapper" id="print_table">
              <table className="table common-table">
                <thead className="remove-border-top">
                  <th></th>
                  <th scope="col">Order Date</th>
                  <th>SO NO.</th>
                  <th>Delivery Date</th>
                  <th>Customer</th>
                  <th>Payment Type</th>
                </thead>

                <tbody>
                  {pendingBodyList && pendingBodyList}
                  {pendingBodyList.length == 0 && (
                    <div className="no-data">
                      <p>No Data Available</p>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
            {pendingBodyList.length > 0 && (
              <div className="row mt-3 mb-3">
                <div className="col-lg-4">
                  Showing {skipPage + 1} to{" "}
                  {skipPage + 10 > totalitems ? totalitems : skipPage + 10} of{" "}
                  {totalitems} entries
                </div>
                <div className="offset-lg-4 col-lg-4">
                  {pageSize != 0 && (
                    <Pagination
                      defaultCurrent={1}
                      current={page}
                      total={totalitems}
                      //defaultPageSize={page}
                      showSizeChanger={false}
                      onChange={(page, pageSize) =>
                        handlePageChange(page, pageSize)
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </TabPane>

          <TabPane tab="Invoiced" key="invoiced">
            <div className="col-lg-5  mt-4">
              <Search
                placeholder="Search by SO ID/ Invoice ID "
                enterButton
                onSearch={onSearch}
                onChange={(e) => handleDropdownChange(e, "search")}
                value={searchValue}
                className="customSearch"
              />
            </div>
            <div className="btn-group" role="group">
              <button
                id="printBtnGrp"
                type="button"
                className="btn btn-primary dropdown-toggle mt-4 ml-4"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={Object.values(printCheckBox).length === 0}
              >
                Print Invoice
              </button>
              <div className="dropdown-menu" aria-labelledby="printBtnGrp">
                <span
                  className="dropdown-item"
                  onClick={() => {
                    setPrintSize('A4');
                    downloadInvoiceIds();
                  }}
                >
                  A4 Paper Size
                </span>
                <span
                  class="dropdown-item"
                  onClick={() => {
                    setPrintSize('A5');
                    downloadInvoiceIds();
                  }}
                >
                  A5 Paper Size
                </span>
              </div>
            </div>
            <div className="d-flex mb-5 mt-5">
              <div className="purchase_picker border_right">
                <DatePicker
                  format={dateFormat}
                  className="mt-1 "
                  placeholder="Delivery Date"
                  onChange={(e) => handleDropdownChange(e, "deliveryDate")}
                  value={filterValues["deliveryDate"]}
                />
              </div>
              <div className="franchise  col-lg-3 ml-2">
                <AsyncPaginate
                  className="basic-multi-select"
                  additional={{
                    page: 1,
                  }}
                  // loadOptions={loadMaterialcode}
                  isClearable={true}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  onChange={(e) =>
                    handleDropdownChange(e, "fulfillmentStatus", 500)
                  }
                  loadingMessage={() => "No Status Found"}
                  placeholder={"Fulfillment Status"}
                  options={fulfillmentOptions}
                  value={filterValues["fulfillmentStatus"]}
                />
              </div>
              <div className=" ml-4 pt-2" onClick={resetFilters}>
                <label htmlFor="" className="cursor pending_label">
                  {"Reset Filter"}
                </label>
              </div>
            </div>

            <div className="mt-4  table-wrapper" id="print_table">
              <table className="table common-table">
                <thead className="remove-border-top">
                  <th></th>
                  <th scope="col">Delivery Date</th>
                  <th>SO NO.</th>
                  <th>Invoice ID</th>
                  <th>Customer</th>
                  <th>Fulfillment Status</th>
                </thead>
                <tbody>
                  {invoicedBodyList && invoicedBodyList}
                  {invoicedBodyList.length === 0 && (
                    <div className="no-data">
                      <p>No Data Available</p>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
            {invoicedBodyList.length > 0 && (
              <div className="row mt-3 mb-3">
                <div className="col-lg-4">
                  Showing {skipPage + 1} to{" "}
                  {skipPage + 10 > invoicedtotalitems
                    ? invoicedtotalitems
                    : skipPage + 10}{" "}
                  of {invoicedtotalitems} entries
                </div>
                <div className="offset-lg-4 col-lg-4">
                  {pageSize != 0 && (
                    <Pagination
                      defaultCurrent={1}
                      current={invoicedPage}
                      total={invoicedtotalitems}
                      //defaultPageSize={page}
                      showSizeChanger={false}
                      onChange={(invoicedPage, pageSize) =>
                        handlePageChange(invoicedPage, pageSize)
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </TabPane>

          <TabPane tab="History" key="history">
            <div className="col-lg-5  mt-4">
              <Search
                placeholder="Search by SO ID/ Invoice ID "
                enterButton
                onSearch={onSearch}
                onChange={(e) => handleDropdownChange(e, "search")}
                value={searchValue}
                className="customSearch"
              />
            </div>

            <div className="d-flex align-items-center">
              <div className="purchase_picker border_right">
                <DatePicker
                  format={dateFormat}
                  className="mt-1 "
                  placeholder="Delivery Date"
                  onChange={(e) => handleDropdownChange(e, "deliveryDate")}
                  value={filterValues["deliveryDate"]}
                />
              </div>
              <div className="franchise  col-lg-2 ml-2">
                <Select
                  name="customer-select"
                  isMulti
                  options={customerList}
                  placeholder="Customer ID/Name"
                  onChange={(e) => handleDropdownChange(e, "customerId", 500)}
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
              <div className=" franchise border_right borderLeft  col-lg-2 ml-2">
                <AsyncPaginate
                  className="basic-multi-select"
                  additional={{
                    page: 1,
                  }}
                  // loadOptions={loadMaterialcode}
                  isClearable={true}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  onChange={(e) => handleDropdownChange(e, "paymentType", 500)}
                  loadingMessage={() => "No Status Found"}
                  placeholder={"Payment Type"}
                  options={paymentOptions}
                  value={filterValues["paymentType"]}
                />
              </div>
              <div className=" franchise   col-lg-2 ml-2">
                <AsyncPaginate
                  className="basic-multi-select"
                  additional={{
                    page: 1,
                  }}
                  // loadOptions={loadMaterialcode}
                  isClearable={true}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  onChange={(e) => handleDropdownChange(e, "orderStatus", 500)}
                  loadingMessage={() => "No Status Found"}
                  placeholder={"Order Status"}
                  options={orderStaus}
                  value={filterValues["orderStatus"]}
                />
              </div>
              <div className=" ml-4 pt-2" onClick={resetFilters}>
                <label htmlFor="" className="cursor pending_label">
                  {"Reset Filter"}
                </label>
              </div>
            </div>

            <div className="mt-4  table-wrapper" id="print_table">
              <table className="table common-table">
                <thead className="remove-border-top">
                  <th scope="col">Order Date</th>
                  <th>SO NO.</th>
                  <th>Invoice ID</th>
                  <th>Delivery Date</th>
                  <th>Customer</th>
                  <th>Payment Type</th>
                  <th>Order Status</th>
                </thead>
                <tbody>
                  {historyBodyList && historyBodyList}
                  {historyBodyList.length === 0 && (
                    <div className="no-data">
                      <p>No Data Available</p>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
            {historyBodyList.length > 0 && (
              <div className="row mt-3 mb-3">
                <div className="col-lg-4">
                  Showing {skipPage + 1} to{" "}
                  {skipPage + 10 > historytotalitems
                    ? historytotalitems
                    : skipPage + 10}{" "}
                  of {historytotalitems} entries
                </div>
                <div className="offset-lg-4 col-lg-4">
                  {pageSize != 0 && (
                    <Pagination
                      defaultCurrent={1}
                      current={historyPage}
                      total={historytotalitems}
                      showSizeChanger={false}
                      onChange={(historyPage, pageSize) =>
                        handlePageChange(historyPage, pageSize)
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </TabPane>
        </Tabs>
      </Spin>
      <SuccessModalNew
        isOpen={downloadModal}
        toggle={() => closeModal("download")}
        text={"Sales Order list downloaded Successfully!"}
        description={"SKU wise pick list has been downloaded successfully."}
      />
      <SuccessModalNew
        isOpen={printModal}
        toggle={() => closeModal("print")}
        text={"Invoice Printing is succesful!"}
        description={"Print copy of invoice have been taken successfully."}
      />
      {isPrint && invoiceResponseData && invoiceResponseData.length >= 0 && (
        <>
          <ReactToPrint
            content={reactToPrintContent}
            pageStyle={printSize == 'A5' ? pageStyleA5 : pageStyleA4}
            documentTitle="Invoice"
            removeAfterPrint
          />
          <div ref={componentRef}>
            {invoiceResponseData &&
              invoiceResponseData.length > 0 &&
              invoiceResponseData.map((invoiceData) => (
                <ComponentToPrint
                  invoiceData={invoiceData}
                  isTaxSummaryShow={isTaxSummaryShow}
                  itemSupplied={invoiceData?.itemSupplied}
                  shippingDetails={invoiceData?.shippingDetails}
                  companyDetails={invoiceData?.companyDetails}
                  itemList={invoiceData?.showItemList}
                  distributorDetail={distributorData}
                  payerDetails={invoiceData?.payerDetails}
                  invoiceTax={invoiceData?.invoiceTax}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
}
