import { Col, Input, message, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import offerPercentage from "../../../images/offerPercentage.svg";
import offerSelected from "../../../images/offerSelected.svg";
import placeHolder from "../../../images/groceries.svg";
import getINRString from "../../../utils/orders/getINRString";
import ActionButton from "./ActionButton/ActionButton";
import {
  addToCart as addToCartAction,
  deleteFromCart,
  updateCart as updateCartAction,
  emptyCart,
  getCartItemList,
} from "../../../action/itemCart";
import "./itemCard.scss";

const getFloat = (str) => parseFloat(str, 10);
const _fixToTwoDecimals = (value) => value.toFixed(2);

export const getPercentageDiscountPrice = (percentage, price) =>
  _fixToTwoDecimals(price - (percentage / 100) * price);

export const getPercentageDiscountPriceDisplay = (percentage, price, moq) => {
  if (moq > 1) {
    const itmPricemoq = _fixToTwoDecimals(
      moq * (price - (percentage / 100) * price)
    );
    return itmPricemoq;
  } else {
    const itmPrice = _fixToTwoDecimals(price - (percentage / 100) * price);
    return itmPrice;
  }
};
export const getMRPPrice = (price, moq) => {
  if (moq > 1) {
    return price * moq;
  } else {
    return price;
  }
};
export const getSetQuantity = (uom, moq) => {
  if (moq > 1) {
    return Math.ceil(uom / moq);
  } else {
    return uom;
  }
};

const ItemCard = ({
  product,
  customerId,
  // cartItemIfExists,
  setMessageInfo,
  setOpenMessage,
  page,
  pageSize,
  searchWord,
}) => {
  const [quantity, setQuantity] = useState();
  const [slabDiscount, setSlabDiscount] = useState(null);
  const [discountList, setDiscountList] = useState([]);
  const [selectedDiscountIndex, setSelectedDiscountIndex] = useState(null);
  const [buttonState, setButtonState] = useState("AAD_DISABLED");

  const dispatch = useDispatch();
  const { cartItemList, loading } = useSelector((state) => state.itemCart);
  const { itemActionLoading, outOfStockItems, extractedCart } = useSelector(
    (state) => state.itemsListReducer
  );
  const { selectedCustomer } = useSelector(
    (state) => state.serachOrdersReducer
  );
  useEffect(() => {
    setQuantity(product?.cart?.displayQty);
  }, [product, outOfStockItems]);

  useEffect(() => {
    if (product.discount) {
      let slabDiscount,
        isSlabDiscount = false;
      product.discount.forEach((ele) => {
        if (ele.slab_count == "00") {
          slabDiscount = ele.amount;
          isSlabDiscount = true;
        }
      });

      if (isSlabDiscount) {
        setSlabDiscount(slabDiscount);
        setDiscountList([]);
        setSelectedDiscountIndex(null);
      } else {
        setDiscountList([...product.discount]);
      }
      // console.log(product.discount);
      // setDiscountList([...product.discount]);
    }
  }, [product.discount]);

  const getType = () => {
    if (product.outOfStock) {
      setButtonState("OUT_OF_STOCK");
      return;
    }
    if (
      !quantity &&
      product?.cart?.displayQty &&
      product?.cart?.displayQty !== quantity
    ) {
      setButtonState("REMOVE");
      return;
    }

    if (product?.cart?.displayQty && product?.cart?.displayQty !== quantity) {
      setButtonState("UPDATE");
      return;
    }

    if (Object.keys(product.cart).length) {
      setButtonState("ADDED");
      return;
    }

    if (quantity === "") {
      setButtonState("AAD_DISABLED");
      return;
    }

    if (!!quantity) {
      setButtonState("ADD");
      return;
    }

    setButtonState("AAD_DISABLED");
  };

  // console.log(product);

  const addToCart = () => {
    if (!quantity) {
      // todo: remove item from the cart

      const cart_items = [
        {
          itemId: product.material,
          conditionType: product.condition_type,
          uom: product.unit_of_measure,
          uniqueId: product.uniqueId,
        },
      ];

      dispatch(
        deleteFromCart(selectedCustomer.goFrugalId, cart_items, {
          searchWord,
          customerId,
          page,
          pageSize,
        })
      );
      return;
    }
    const reqData = {
      uom: product.unit_of_measure,
      moq: product.moq.toString(),
      quantity,
      conditionType: product.condition_type,
      itemId: product.material,
      uniqueId: product.uniqueId,
      customerId,
    };

    if (
      extractedCart.length &&
      extractedCart[0]?.conditionType !== product.condition_type
    ) {
      setOpenMessage(true);
      setMessageInfo({
        success: false,
        title: "Item could not be added to basket!",
        subTitle:
          "Similar item with different condition type already exsist in basket hence not able to add the item.",
      });
      setQuantity();

      return;
    }

    dispatch(
      addToCartAction(reqData, { searchWord, customerId, page, pageSize })
    );
  };

  // console.log(cartItemList);

  const updateCart = () => {
    dispatch(updateCartAction(product.material, quantity));
  };

  useEffect(() => {
    updateApplicableOfferList();
  }, [quantity]);

  useEffect(() => {
    getType();
  }, [quantity, cartItemList]);

  // console.log(itemActionLoading, product.material);

  //#region Selecting the offer from the list upon quantity change.
  const updateApplicableOfferList = () => {
    // converting quantity to Integer
    let integerQuantity = getFloat(quantity);
    if (getFloat(product.moq) > 0) {
      integerQuantity = getFloat(quantity * getFloat(product.moq));
    }
    // If no quantity then remove selection and return.
    if (!integerQuantity) {
      setSelectedDiscountIndex(null);
      return;
    }
    // If no discount return from here
    if (!product.discount) {
      return;
    }

    let discountTempList = [...product.discount];
    let theIndex = 0;

    // Check if the quantity is in-between offers available and save the index in the state`

    if (integerQuantity < getFloat(discountTempList[0].min_quantity)) {
      setSelectedDiscountIndex(null);
      return;
    }
    if (
      integerQuantity >=
      getFloat(discountTempList[discountTempList.length - 1].min_quantity)
    ) {
      theIndex = discountTempList.length - 1;
    } else {
      discountTempList.forEach((dic, index) => {
        if (discountTempList[index]) {
          if (
            integerQuantity >= dic.min_quantity &&
            integerQuantity < discountTempList[index + 1]?.min_quantity
          ) {
            theIndex = index;
          }
        } else {
          theIndex = index;
        }
      });
    }

    setSelectedDiscountIndex(theIndex);
  };
  const getRemainingStock = () => {
    if (product?.stock == -1) {
      return "Unlimited";
    } else if (product?.stock == 0) {
      return "Out Of Stock";
    } else if (product?.moq > 0) {
      return _fixToTwoDecimals(
        (product?.stock - product?.orderQuantity) / product?.moq
      );
    } else {
      return _fixToTwoDecimals(product?.stock - product?.orderQuantity);
    }
  };

  //#region Function to get the Calculated price of the Item
  const getCalculatedPrice = () => {
    const intPrice = getFloat(product.amount);

    if (!quantity) {
      if (slabDiscount != null) {
        const discountPrice = getPercentageDiscountPrice(
          getFloat(slabDiscount),

          intPrice
        );

        return getINRString(
          1 * discountPrice * getFloat(product.moq > 1 ? product.moq : 1)
        );
      } else {
        // return getINRString(intPrice);
        return getINRString(
          1 * intPrice * getFloat(product.moq > 1 ? product.moq : 1)
        );
      }
    }

    if (
      (selectedDiscountIndex === 0 || selectedDiscountIndex) &&
      discountList?.length > 0
    ) {
      const selectedDiscount = discountList[selectedDiscountIndex];
      const discountPrice = getPercentageDiscountPrice(
        getFloat(selectedDiscount.amount),
        intPrice
      );
      return getINRString(
        quantity * discountPrice * getFloat(product.moq > 1 ? product.moq : 1)
      );
    } else if (slabDiscount !== null) {
      const discountPrice = getPercentageDiscountPrice(
        getFloat(slabDiscount),
        intPrice
      );
      // console.log(discountPrice);
      return getINRString(
        quantity * discountPrice * getFloat(product.moq > 1 ? product.moq : 1)
      );
    } else {
      const integerPricePerMOQ =
        intPrice * getFloat(product.moq > 1 ? product.moq : 1);
      return getINRString(quantity * integerPricePerMOQ);
    }
  };
  //#endregion

  const updateQuantity = (e) => {
    const quantity = e.target.value;
    // console.log(e.target.validity.valid);
    if (!e.target.validity.valid) {
      return;
    }
    if (quantity === "") {
      setQuantity(quantity);
    }
    if (quantity > 999999 || quantity <= 0) {
      return;
    }
    setQuantity(quantity);
  };

  return (
    <Col className="mt-5 d-flex" xs={12} sm={12} md={12} lg={12} xl={8} xxl={6}>
      <div
        className="ml-3 product_Card"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {/* Offer Ribbon */}
        {slabDiscount ? (
          <div className="ribbon">
            <span class="offer_tag">{slabDiscount}% OFF</span>
          </div>
        ) : (
          ""
        )}

        {/* Economy chip if ZBAS */}
        {product.condition_type === "ZSEC" ? (
          <Typography className="economy_type">Economy</Typography>
        ) : null}

        <div
          className="image-container p-2 d-flex flex-column"
          style={{ flex: 1 }}
        >
          <div className="image_url" style={{ flex: 1 }}>
            <img
              src={product.imageUrl || placeHolder}
              alt=""
              style={{
                width: "100px",
                height: "100px",
                textAlign: "center",
              }}
            />
          </div>
          <div className="pl-2 pt-2">
            <Typography style={{ color: "#858E9C", fontSize: "14px" }}>
              {product.brand}
            </Typography>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: 1.6,
                letterSpacing: 1.1,
                color: "#3A4960",
              }}
            >
              {product.name}{" "}
              {product.moq > 1 &&
                `(Set of ${product.moq} ${product.unit_of_measure})`}
            </Typography>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: 1.6,
              }}
            >
              ID # {product.material}
            </Typography>
            <Typography
              style={{
                fontWeight: 500,
                fontSize: "16px",
                marginTop: 10,
                lineHeight: 1.6,
                display: "flex",
                alignItems: "flex-start",
                gap: 5,
              }}
            >
              ₹
              {getPercentageDiscountPriceDisplay(
                slabDiscount,
                product.amount,
                product?.moq
              )}
              /{product?.moq > 1 ? "Set" : product.unit_of_measure}
              {slabDiscount ? (
                <span
                  style={{
                    textDecoration: "line-through",
                    fontSize: 12,
                    opacity: 0.6,
                    paddingTop: "2px",
                  }}
                >
                  MRP: ₹{getMRPPrice(product.amount, product.moq)}
                </span>
              ) : (
                ""
              )}
            </Typography>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: 1.6,
                marginTop: "2px",
              }}
            >
              Remaining Stock : {getRemainingStock()}
            </Typography>
          </div>
        </div>
        <div className="small_card mt-2 px-3">
          {discountList?.map((dis, index) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                <img
                  src={
                    index === selectedDiscountIndex
                      ? offerSelected
                      : offerPercentage
                  }
                  alt="Offer icon"
                />
              </span>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "15px",
                  color:
                    index === selectedDiscountIndex ? "#219653" : "#70727B",
                  marginTop: "3px",
                }}
                className="ml-1"
              >
                {product.moq > 1
                  ? `Buy ${getSetQuantity(
                      dis.min_quantity,
                      product.moq
                    )} Set + at ${getINRString(
                      getPercentageDiscountPriceDisplay(
                        dis.amount,
                        product.amount,
                        product.moq
                      )
                    )}/ Set
                `
                  : `Buy ${dis.min_quantity} ${
                      product.unit_of_measure
                    } + at  ${getINRString(
                      getPercentageDiscountPriceDisplay(
                        dis.amount,
                        product.amount,
                        product.moq
                      )
                    )}/${product.unit_of_measure}
                `}
              </Typography>
            </div>
          ))}

          <div
            className="mt-2"
            style={{
              display: "flex",
            }}
          >
            <div className="w-100">
              <div className="">
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex"
                    style={{
                      border:
                        outOfStockItems === product.uniqueId
                          ? "red 1px solid"
                          : "#c4c4c4 1px solid",
                    }}
                  >
                    <div className="input-prefix">
                      <span className="oty_text">Qty</span>
                    </div>
                    <Input
                      style={{
                        color: "gray",
                        width: 110,
                        border: "none",
                      }}
                      placeholder="Enter QTY"
                      value={quantity}
                      disabled={product.outOfStock}
                      type="number"
                      pattern="[0-9]*"
                      fontSize={8}
                      onChange={(e) => updateQuantity(e)}
                    />
                  </div>

                  {outOfStockItems === product.uniqueId ? (
                    <div>
                      <Typography
                        className="pl-1"
                        style={{
                          fontSize: 12,
                          color: "#E13440",
                          marginRight: "-10px",
                        }}
                      >
                        Quantity entered is more than available qty.
                      </Typography>
                    </div>
                  ) : null}
                </div>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontSize: 16, color: "#444" }}>
                    Price
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginLeft: 8,
                      color: "#3A4960",
                    }}
                  >
                    {getCalculatedPrice()}
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 12,
              marginBottom: 10,
            }}
          >
            <ActionButton
              type={buttonState}
              addToCart={addToCart}
              updateCart={addToCart}
              disabled={!product?.cart?.displayQty && !quantity}
              loading={itemActionLoading === product.uniqueId}
            />
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ItemCard;
