import { ClockCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionTypes,
  getOrderingTime,
  stopLoading,
} from "../../../action/manageOrder";
import { updateOrderingTime } from "../../../services/settings/manageOrder";
import BaseCard from "./BaseCard";

const OrderingTime = () => {
  const { orderingTime, loading } = useSelector((state) => state.manageOrder);
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = useState(false);
  const onHandleEditOpen = () => {
    setEditOpen(true);
  };

  const upDateOrderingTime = async (from, to) => {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.UPDATE_ORDERING_TIME,
      });
      const res = await updateOrderingTime(from, to);
      if (res.status === 200) {
        setEditOpen(false);
        notification.success({ message: "Data updated successfully" });
        dispatch(getOrderingTime());
        dispatch(stopLoading(actionTypes.UPDATE_ORDERING_TIME));
        dispatch({
          type: actionTypes.STOP_LOADING,
          load: actionTypes.UPDATE_ORDERING_TIME,
        });
      }
    } catch (error) {
      console.log({ error });
      dispatch({
        type: actionTypes.STOP_LOADING,
        load: actionTypes.UPDATE_ORDERING_TIME,
      });
    }
  };
  return (
    <BaseCard
      title="Ordering Time"
      subTitle="The time window within which orders can be placed."
      fromTime={orderingTime?.orderFromTime || "----"}
      toTime={orderingTime?.orderToTime || "----"}
      icon={<ClockCircleOutlined style={{ fontSize: 60, color: "#717C8D" }} />}
      upDateOrderingTime={upDateOrderingTime}
      loading={loading}
      editOpen={editOpen}
      setEditOpen={setEditOpen}
      onHandleEditOpen={onHandleEditOpen}
    />
  );
};

export default OrderingTime;
