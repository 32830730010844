import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import PoDetails from './../components/poGeneration/poDetails';
import { getSinglePoDetails } from "../action/poGeneration";


const mapStateToProps = (state) => {
    return {
        singlePoDetails: state.poGeneration.singlePoDetails,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSinglePoDetails,
        },
        dispatch
    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PoDetails));
