import React from "react";
import { Steps } from "antd";

const { Step } = Steps;

const OrderSteps = ({
  current,
  onClickBack = () => {},
  onClickBackItem = () => {},
}) => {
  return (
    <div className="bg-white p-1 m-4">
      <div class="container steps_container">
        <Steps current={current} className="m-5" labelPlacement="vertical">
          <Step
            title=""
            description="Search Customer"
            style={{ cursor: "pointer" }}
            onClick={onClickBack}
          />
          <Step
            description="Item"
            style={{ cursor: "pointer" }}
            onClick={onClickBackItem}
          />
          <Step description="Delivery & Payment " />
        </Steps>
      </div>
    </div>
  );
};

export default OrderSteps;
