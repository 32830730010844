import generateActionTypes from "../utils/generateActionTypes";
import {
  getItemsList,
  getCreditLimitsService,
  getBasket,
} from "../services/orderServices/itemsService";
import { handleErrorResponse } from "../utils/Request";
import { getPaymentDetails } from "./paymentDetailsaction";
import message from "antd/lib/message";

export const actionTypes = generateActionTypes(
  "GET_ITEMS_LIST",
  "LOADING",
  "STOP_LOADING",
  "GET_CREDIT_LIMITS",
  "REMOVE_CREDIT_DETAILS",
  "GET_BASKET_COUNT",
  "LOADING_ITEM_ACTION",
  "OUT_OF_STOCK_ERROR",
  "RESET"
);

export const getItemsLists = (
  searchVal,
  CustomerId,
  page,
  pageSize,
  dontLoad = false,
  uniqueId
) => {
  return async (dispatch) => {
    if (!dontLoad) {
      dispatch({
        type: actionTypes.LOADING,
      });
    }

    try {
      if (!CustomerId || !pageSize) {
        return;
      }
      const res = await getItemsList(searchVal, CustomerId, page, pageSize);
      const resData = res?.data;
      if (resData.status === 200) {
        const { records } = resData.result;
        const extractedCart = [];
        if (!records) {
          dispatch({
            type: actionTypes.GET_ITEMS_LIST,
            data: {
              list: [],
              searchTerm: "",
              totalRecords: 0,
              extractedCart: [],
            },
          });
          return;
        }
        const arrangedData = records.map((record) => {
          const {
            material,
            amount,
            item,
            condition_type,
            unit_of_measure,
            valid_from,
            valid_to,
            wcdiscount,
            cart,
          } = record;

          const { brand, imageUrl, itemName, shortName, inventory } = item[0];
          // console.log(
          //   cart[0]?.items[0],
          //   "CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC"
          // );

          const { stock, moq, orderQuantity } = inventory[0];

          let cartObj =
            cart[0]?.items[0] && Object.keys(cart[0]?.items[0]).length > 0
              ? cart[0]?.items[0]
              : {};

          if (Object.keys(cartObj).length > 0) {
            extractedCart.push(cartObj);
          }
          return {
            uniqueId: material.concat(unit_of_measure.concat(condition_type)),
            material,
            amount,
            brand,
            condition_type,
            unit_of_measure,
            valid_from,
            valid_to,
            imageUrl,
            name: itemName,
            shortName,
            stock,
            allowedQuantity: stock / moq,
            moq,
            orderQuantity,
            cart: { ...cartObj },
            outOfStock: stock === orderQuantity || stock === 0,
            discount: wcdiscount[0]?.discount.sort(
              (a, b) => a.min_quantity - b.min_quantity
            ),
          };
        });

        dispatch({
          type: actionTypes.GET_ITEMS_LIST,
          data: {
            list: arrangedData,
            searchTerm: searchVal,
            totalRecords: resData.result.totalRecords[0].Total,
            extractedCart,
          },
        });
        dispatch(getBasketCount(CustomerId));
        dispatch(getPaymentDetails(CustomerId));
        // dispatch(stop);
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      console.log(err, "error");
      const { errorMessage, errors } = handleErrorResponse(err);
    }
  };
};

export const removeCreditDetails = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_CREDIT_DETAILS,
    });
  };
};
export const getCreditLimits = (CustomerId) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.GET_CREDIT_LIMITS,
    });
    try {
      const res = await getCreditLimitsService(CustomerId);
      const resData = res?.data;
      if (resData.status === 200) {
        const { result } = resData;
        dispatch({
          type: actionTypes.GET_CREDIT_LIMITS,
          data: result.response[0],
        });
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
    }
  };
};
export const getBasketCount = (CustomerId) => {
  return async (dispatch) => {
    // dispatch({
    //   type: actionTypes.LOADING,
    //   load: actionTypes.GET_BASKET_COUNT,
    // });
    try {
      const res = await getBasket(CustomerId);
      const resData = res?.data;
      if (resData.status === 200) {
        const { result } = resData;
        dispatch({
          type: actionTypes.GET_BASKET_COUNT,
          data: { result },
        });
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
    }
  };
};

export const outOfStockError = (uniqueId) => {
  return async (dispatch) => {
    dispatch({ type: "OUT_OF_STOCK_ERROR", uniqueId: uniqueId });
  };
};

export const resetState = () => {
  return async (dispatch) => {
    dispatch({ type: "RESET" });
  };
};

// export const getIndividualOrderDetail = (salesOrderId) => {
//   return async (dispatch) => {
//     dispatch({
//       type: actionTypes.LOADING,
//       load: actionTypes.GET_INDIVIDUAL_ORDER_DETAILS,
//     });
//     try {
//       const res = await getIndividualOrderDetails(salesOrderId);
//       const resData = res?.data;
//       console.log(resData, "response");
//       if (resData.status == 200) {
//         const { result } = resData;
//         console.log(result, "result neww");
//         dispatch({
//           type: actionTypes.GET_INDIVIDUAL_ORDER_DETAILS,
//           data: result,
//         });
//         console.log(result, "");
//       }
//     } catch (error) {
//       console.log(error, "error");
//     }
//   };
// };
