import { actionTypes } from "../action/salesReturnAction";
const initialState = {
  loading: false,
  salesReturnList: [],
  totalRecords: 0,
};
export const salesReturnReducer = (state = initialState, action) => {
  const { type, data, load } = action;
  console.log(data, "kk");
  switch (type) {
    case actionTypes.LOADING:
      return { ...state, loading: true };

    case actionTypes.STOP_LOADING:
      return { ...state, loading: false };
    case actionTypes.GET_SALES_RETURN_LIST:
      let apires = data.result.data;
      apires.forEach((item) => {
        const abc = item.itemSupplied.map((v) => {
          return { ...v, approved: null };
        });
        item.itemSupplied = abc;
      });
      return {
        ...state,
        salesReturnList: apires,
        loading: false,
        totalDocuments: data.totalDocuments,
      };

    default:
      return state;
  }
};
