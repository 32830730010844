import { actionTypes } from "../action/SearchOrdersListaction";

const getSelectedCustomerFromLocalStorage = () => {
  let selectedCustomer = localStorage.getItem("customer");
  if (!selectedCustomer || selectedCustomer === "undefined") return {};
  selectedCustomer = JSON.parse(selectedCustomer);
  if (Object.keys(selectedCustomer).length) {
    return selectedCustomer;
  }
  return {};
};
const initialState = {
  loading: [],
  selectedCustomer: getSelectedCustomerFromLocalStorage(),
  searchedValue: "",
  searchOrdersList: null,
};
const stopLoading = (type, loadArray) => {
  return loadArray.filter((load) => load !== type);
};
export const serachOrdersReducer = (state = initialState, action) => {
  const { type, data, load } = action;
  switch (type) {
    case actionTypes.LOADING:
      if (state.loading.includes(load));
      return { ...state, loading: [...state.loading, load] };
    case actionTypes.STOP_LOADING:
      return { ...state, loading: stopLoading(load, state.loading) };
    case actionTypes.GET_SEARCH_CUSTOMER_ORDERS_LIST:
      return {
        ...state,
        searchOrdersList: [...data.list],
        loading: stopLoading(type, state.loading),
        searchedValue: data.searchTerm,
      };
    case actionTypes.SAVE_SEARCHED_VALUE:
      return { ...state, searchedValue: data };
    case actionTypes.SAVE_SELECTED_CUSTOMER:
      localStorage.setItem("customer", JSON.stringify(data));
      return { ...state, selectedCustomer: data };
    default:
      return state;
  }
  console.log("err");
};
