import { actionTypes } from "../action/ordersHistoryaction";
const initialState = {
  loading: false,
  ordersList: [],
  totalRecords: 0,
};

export const ordersHistoryReducer = (state = initialState, action) => {
  const { type, data, load } = action;
  switch (type) {
    case actionTypes.LOADING:
      return { ...state, loading: true };

    case actionTypes.STOP_LOADING:
      return { ...state, loading: false };
    case actionTypes.GET_ORDERS_LIST:
      return {
        ...state,
        ordersList: [data.result],
        loading: false,
        totalDocuments: data.totalDocuments,
      };

    default:
      return state;
  }
};
