import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";

export const dmsOtpValidation = async (id, status) => {
  let url = `api/v1/assests/${id}/status/${status}`;
  let request = await axios.patch(
    APIConfig.base_url + url,
    {},
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const pdwaycoolOtpValidation = async (type, status) => {
  let url = `api/v1/user/distbutionflagupdate?type=waycool&status=${status}`;
  let request = await axios.patch(
    APIConfig.base_url + url,
    {},

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
export const distributorPortalOtpValidation = async (type, status) => {
  let url = `api/v1/user/distbutionflagupdate?type=distributor&status=${status}`;
  let request = await axios.patch(
    APIConfig.base_url + url,
    {},
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
