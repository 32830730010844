import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";

export const getItemsList = async (searchTerm, CustomerId, page, pageSize) => {
  let skip = 0;
  if (page > 1) {
    skip = pageSize * page - pageSize;
  }
  let url = `api/v1/item/list?skip=${skip}&limit=${pageSize}&searchTerm=${searchTerm.toUpperCase()}`;
  let request = await axios.get(APIConfig.revamp_base_url + url, {
    headers: {
      authorization: getAuthToken(),
      portaltype: "distributor",
      customerid: CustomerId,
    },
  });
  return request;
};

export const getCreditLimitsService = async (CustomerId) => {
  let url = `api/v1/profile/sapCreditCheck?userId=${CustomerId}`;
  let request = await axios.get(APIConfig.revamp_base_url + url, {
    // headers: {
    //   authorization: getAuthToken(),
    //   portaltype: "distributor",
    //   customerid: 1600863,
    // },
  });
  return request;
};
export const getIndividualOrderDetails = async (soId, CustomerId) => {
  let url = `api/v1/orderdetails/getorderdetails?salesorderid=${soId}`;
  let request = await axios.get(APIConfig.revamp_base_url + url, {
    headers: {
      authorization: getAuthToken(),
      portaltype: "distributor",
      customerid: CustomerId,
    },
  });
  return request;
};
export const getBasket = async (CustomerId) => {
  let url = `api/v1/cart/getBasketCount`;
  let request = await axios.get(APIConfig.revamp_base_url + url, {
    headers: {
      authorization: getAuthToken(),
      portaltype: "distributor",
      customerid: CustomerId,
    },
  });
  return request;
};
