import React from "react";
import { Typography } from "antd";
import cross from "../../images/crossdata.svg";
function DataNotAvailable() {
  return (
    <div className="cancel-overview1">
      <div className="cancellation-data">
        <img src={cross} alt="availble" style={{ marginTop: "10px" }} />
        <Typography style={{ lineHeight: "35px" }}>
          Data not available
        </Typography>
      </div>
    </div>
  );
}

export default DataNotAvailable;
