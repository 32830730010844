import React, { Component } from "react";
import PrimaryHeader from "../header/PrimaryHeader";
import "./poGeneration.scss";
import { Pagination, Spin } from "antd";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { getSinglePoDetails } from "../../services/poGenerationServices/poGeneration";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { formatMoney ,stringSortingByChar} from "../../utils/common";

class PoDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      laoding: false,
      page: 1,
      pageSize: 0,
      totalPage: 0,
      pageSize: 0,
      totalItems: 0,
      singlePoDetails:{}
    };
  }

  getSinglePoDetailsList = async () => {
    this.setState({ loading: true });
    let id = this.props.match.params.id;
    let { page } = this.state;
    let qParams = [{ page }];

    await getSinglePoDetails(id, qParams)
      .then((res) => {
        this.setState({
          loading: false,
          totalPage: res.data.data.pageMeta.total,
          pageSize: res.data.data.pageMeta.pageSize,
          totalItems: res.data.data.pageMeta.total,
          singlePoDetails:res.data.data
        });
        this.props.getSinglePoDetails(res.data.data);
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  handleChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    });
  };

  handleChange = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.getSinglePoDetailsList();
      }
    );
  };

  componentDidMount = () => {
    this.getSinglePoDetailsList();
  };

  render() {
    let { tab ,} = this.props.location;
    //let { singlePoDetails } = this.props;
    let { page, pageSize, totalItems, totalPage ,singlePoDetails} = this.state;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    
    return (
      <div id="content" className="">
        <PrimaryHeader title="PO Request Details" />
        <div className="mr-3 card mt-4">
          <div className="row  ">
            <div className="col-lg-9 col-md-9  col-xl-10 mt-3 ml-3">
              <span className="base-details pl-1">Basic Details</span>
            </div>
          </div>

          <Spin indicator={antIcon} spinning={this.state.loading}>
            <div className="d-flex mt-4 mb-4 ">
              <div className="d-flex col-lg-12 col-md-12  col-xl-12 ">
                <div className="d-flex  border-lines col-lg-3 col-md-3  col-xl-3 flex-column">
                  <span className="title-name mb-1"> PO ID </span>
                  <span className="title-data"
                  >
                    {singlePoDetails &&
                    singlePoDetails.results &&
                    singlePoDetails.results[0].po_number
                      ? singlePoDetails &&
                        singlePoDetails.results &&
                         singlePoDetails.results[0].po_number
                      : "N/A"}
                  </span>
                </div>
                <div className="d-flex col-lg-2 col-md-2  col-xl-2 flex-column">
                  <span className="title-name1 mb-1">PO Type</span>
                  <span className="title-data1 nameCapitalize">
                    {singlePoDetails &&
                      singlePoDetails.results &&
                      singlePoDetails.results[0].poType}
                  </span>
                </div>
                {singlePoDetails &&
                singlePoDetails.results &&
                singlePoDetails.results[0].poType === "internal" ? (
                  <div className="d-flex col-lg-3 col-md-3  col-xl-3 flex-column">
                    <span className="title-name1 mb-1">Warehouse /Plant Name</span>
                    <span className="title-data1 nameCapitalize">
                      {singlePoDetails &&
                        singlePoDetails.results &&
                        singlePoDetails.results[0].warehouseName}
                    </span>
                  </div>
                ) : (
                  <>
                    <div className="d-flex col-lg-2 col-md-2  col-xl-2 flex-column">
                      <span className="title-name1 mb-1">Vendor ID</span>
                      <span className="title-data1">
                        {singlePoDetails &&
                          singlePoDetails.results &&
                          singlePoDetails.results[0].vendor_no}
                      </span>
                    </div>
                    <div className="d-flex col-lg-2 col-md-3  col-xl-2 flex-column">
                      <span className="title-name1 mb-1">Vendor Name</span>
                      <span className="title-data1 nameCapitalize">
                        {singlePoDetails &&
                          singlePoDetails.results &&
                          singlePoDetails.results[0].vendor_name}
                      </span>
                    </div>
                  </>
                )}

                <div className="d-flex col-lg-2 col-md-3  col-xl-2 flex-column">
                  <span className="title-name1 mb-1">Delivery Date</span>
                  <span className="title-data1">
                    {moment(
                      singlePoDetails &&
                        singlePoDetails.results &&
                        singlePoDetails.results[0].deliveryDate
                    ).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div className="d-flex col-lg-2 col-md-2 ml-md-n4  col-xl-2 flex-column">
                  <span className="title-name1 mb-1">Total Amount</span>
                  <span className="title-data1 data-color">
                    &#8377;
                    {singlePoDetails &&
                      singlePoDetails.results &&
                      formatMoney(singlePoDetails.results[0].totalAmount)}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex mt-4 mb-4 ">
              <div className="d-flex col-lg-12 col-md-12  col-xl-12 ">
                <div className="d-flex col-lg-2 col-md-2 ml-md-n4  col-xl-2 flex-column">
                  <span className="title-name1 mb-1"></span>
                  <span className="title-data1 data-color"></span>
                </div>
                {/* <div className="d-flex col-lg-3 col-md-2 ml-4  col-xl-3  flex-column">
                                    <span className="title-name1 mb-1">Validity</span>
                                    <span className="title-data1">
                                        {moment(singlePoDetails && singlePoDetails.results && singlePoDetails.results[0].startOfValidityPeriod).format("DD-MM-YYYY") + " - " + moment(singlePoDetails && singlePoDetails.results && singlePoDetails.results[0].endOfValidityPeriod).format("DD-MM-YYYY")}
                                    </span>
                                    <span className="title-data1">
                                       
                                    </span>
                                </div> */}
              </div>
            </div>
          </Spin>
        </div>
        <div className="col-lg-9 col-md-9  col-xl-10 mt-5 ml-1">
          <span className="base-details pl-1">Item Details</span>
        </div>
        <div className="table-wrapper">
          <table className="table approved-table ml-2" style={{ width: "98%" }}>
            <thead className="remove-border-top">
              <tr>
                <th scope="col">Item ID</th>
                <th scope="col">Item Name</th>
                <th scope="col">UOM</th>
                <th scope="col">Price</th>
                <th scope="col">Quantity</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              {singlePoDetails &&
                singlePoDetails.results &&
                singlePoDetails.results.length > 0 &&
                singlePoDetails.results[0].item.length>0 &&
                singlePoDetails.results[0].item.map((item) => {
                  return (
                    <tr>
                      <td>{item && item.material_no}</td>
                      <td className="nameCapitalize">
                        {item && item.material_description}
                      </td>
                      <td>{item && item.uom}</td>
                      <td>&#8377;{item && item.net_price}</td>
                      <td>{item && item.quantity}</td>
                      <td>
                        &#8377;
                        {item &&
                          item.net_price &&
                          item.net_price.toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          {totalItems != 0 && <p className="p-2">Total {totalItems} Items </p>}
          {totalPage != 0 && (
            <Pagination
              className="page"
              defaultCurrent={1}
              current={page}
              total={totalPage}
              defaultPageSize={pageSize}
              showSizeChanger={false}
              onChange={(page, pageSize) => this.handleChange(page, pageSize)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default PoDetails;
