import React, { Component } from "react";
import "./draftBeatPlan.scss";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { Spin } from "antd";
import plus from "../../images/plus.svg";
import { formatMoney } from "../../utils/common";
import downArrow from "../../images/down-arrow.svg";
import clearAll from "../../images/clearAll.svg";
import AddCustomer from "../../images/AddCustomer.svg";
import editBeatPlan from "../../images/editBeatPlan.svg";
import redCross from "../../images/redCross.svg";
import defaultPic from "../../images/defaultPic.svg";
import { getDayName } from "../../utils/common";
import history from "../../utils/History";
import {
  getPublistedBeatPlanDetailsById,
  removeCustomerFromPublishedBeatPlan,
  addAdhocCustomer,
} from "../../services/beatplanServices/beatplan";
import { ShowAdd } from "./addDay";
import ShowList from "./showCustomerList";
import AddCustomerModal from "../modal/addCustomerModal";
import { getAllSalesmen } from "../../services/teamServices/team";
import DailyViewTable from "./dailyViewTable";
import Select from "react-select";
import DeleteConfirmation from "../modal/deleteConfirmation";
import _ from "lodash";
import PrimaryHeader from "../header/PrimaryHeader";
class PublishedBeatPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      planDetails: {},
      customerlist: [],
      displayRemoveCustomerModalStatus: false,
      adhocCustomerModalStatus: false,
      removeCustomerId: "",
      showWarningModalStatus: false,
    };
  }
  getPublishedBeatPlanDetails = async () => {
    this.setState({ loading: true });
    let planItemId = this.props.match.params.id;
    await getPublistedBeatPlanDetailsById(planItemId)
      .then((res) => {
        this.setState(
          {
            planDetails: { ...res.data.data },
            customerlist: [...res.data.data.customers],
          },
          () => {
            this.setState({ loading: false });
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        console.log("err", errorMessage);
        if (errorMessage == "Invalid Published Beat Plan Id !") {
          window.location = `dashboard/beatplan/published`;
          history.push({
            pathname: `dashboard/beatplan/published`,
          });
        }
      });
  };
  deleteCustomer = async () => {
    this.setState({ loading: true });
    let { removeCustomerId, customerlist } = this.state;
    console.log("customerlist", customerlist);
    let beatPlanId = this.props.match.params.id;
    await removeCustomerFromPublishedBeatPlan(beatPlanId, removeCustomerId)
      .then((res) => {
        message.success("customer has been deleted successfully");
        this.getPublishedBeatPlanDetails();
        this.setState({
          displayRemoveCustomerModalStatus: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          displayRemoveCustomerModalStatus: false,
        });
        const { errorMessage, errors } = handleErrorResponse(err);

        message.error(errorMessage);
      });
  };
  showWarningModal = (id) => {
    console.log("showWarningModal", id);
    this.setState({
      showWarningModalStatus: !this.state.showWarningModalStatus,
      removeCustomerId: id && id,
    });
  };
  displayRemoveCustomerModal = (id) => {
    this.setState({
      displayRemoveCustomerModalStatus: !this.state
        .displayRemoveCustomerModalStatus,
      removeCustomerId: id && id,
    });
  };
  displayAdhocCustomerModal = () => {
    this.setState({
      adhocCustomerModalStatus: !this.state.adhocCustomerModalStatus,
    });
  };
  addCustomersToBeatplan = async (customers) => {
    this.setState({ loading: "true" });
    let customerIdArray = customers.map((x) => {
      return x.customerId;
    });
    let id = this.props.match.params.id;
    let inputs = {
      customerIds: customerIdArray,
    };

    if (customerIdArray.length == 0) {
      message.warning("Please select customer from the dropdown!");
    } else {
      await addAdhocCustomer(inputs, id)
        .then((res) => {
          message.success("Customer list has been updated successfully");
          this.setState({
            adhocCustomerModalStatus: false,
          });
          this.getPublishedBeatPlanDetails();
        })
        .catch((err) => {
          this.setState({ loading: false, adhocCustomerModalStatus: false });
          const { errorMessage, errors } = handleErrorResponse(err);
          message.error(errorMessage);
        });
    }
  };
  componentDidMount() {
    this.getPublishedBeatPlanDetails();
  }
  render() {
    let {
      loading,
      planDetails,
      customerlist,
      displayRemoveCustomerModalStatus,
      adhocCustomerModalStatus,
    } = this.state;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    console.log("customerlist", customerlist);
    return (
      <>
        {planDetails && (
          <div id="content">
            <PrimaryHeader title="Day's Plan#" />
            <Spin indicator={antIcon} spinning={loading}>
              <p className="page-title">{planDetails.beatPlanId}</p>
              <div className="row pb-2 nameRow">
                <div className="col col-md-3 assignTo">
                  Assigned to :{" "}
                  {planDetails.salesman && planDetails.salesman.fullName}
                </div>
                <div className="col col-md-6 assignTo">
                  <span className="day-picker showdate">
                    {" "}
                    {planDetails.dateOfBeatPlan}
                  </span>
                </div>
              </div>

              <div className="row pb-2 nameRow">
                <div className="col col-md-3 pt-3 ml-3">
                  <span
                    className="adhoc-btn"
                    onClick={this.displayAdhocCustomerModal}
                  >
                    Ad-hoc Customer <img src={plus} alt="+" />
                  </span>
                </div>
              </div>

              {/* -------tables------ */}
              <div className="dailyList">
                {customerlist.length > 0 &&
                  customerlist.map((customer) => {
                    console.log(customer.goFrugalId)
                    return (
                      <div
                        // style={{ overflowX: "auto", width: "100%" }}
                        key={customer.customerId}
                      >
                        {/* isAdhoc */}
                        <div
                          className={
                            customer.isAdhoc
                              ? "row daily-div p-2 isAdhoc"
                              : "row daily-div p-2"
                          }
                          title={
                            customer.isAdhoc ? "Ad-hoc customer" : "customer"
                          }
                        >
                          <img
                            src={defaultPic}
                            alt="defaultPic"
                            className="customerPic"
                          />
                          <div className="col ml-n2" key={customer.seq}>
                            <p
                              className="customerName "
                              title={customer.customerName}
                            >
                              {" "}
                              {customer.customerName} <br />
                              <span className="customer-name">
                                {customer &&
                                  customer.goFrugalId}
                              </span>
                            </p>
                          </div>
                          {/* <div className="col">
                            <p
                              title={
                                customer.customersAccount[0] &&
                                customer.customersAccount[0].group &&
                                customer.customersAccount[0].group
                              }
                            >
                              GROUP <br />
                              <span className="grp-name">
                                {customer.customersAccount[0] &&
                                  customer.customersAccount[0].group &&
                                  customer.customersAccount[0].group}
                              </span>
                            </p>
                          </div> */}
                          {/* <div className="col">
                            <p
                              title={
                                customer.customersAccount &&
                                customer.customersAccount[0] &&
                                customer.customersAccount[0].subGroup &&
                                customer.customersAccount[0].subGroup
                              }
                            >
                              {" "}
                              SUB-GROUP <br />
                              <span className="grp-name">
                                {customer.customersAccount &&
                                  customer.customersAccount[0] &&
                                  customer.customersAccount[0].subGroup &&
                                  customer.customersAccount[0].subGroup}
                              </span>
                            </p>
                          </div> */}
                          <div className="col">
                            <p>
                              ON-ACCOUNT <br />
                              <span className="on-account">
                                &#8377;{" "}
                                {formatMoney(
                                  customer.customersAccount[0] &&
                                    customer.customersAccount[0].onAccount
                                )}

                              </span>
                            </p>
                          </div>
                          <div className="col">
                            <p>
                              OUTSTANDING <br />{" "}
                              <span className="outstanding">
                                {" "}
                                &#8377;{" "}
                                {formatMoney(
                                  customer.customersAccount[0] &&
                                    customer.customersAccount[0]
                                      .customerTotalOutstanding
                                )}

                              </span>
                            </p>
                          </div>
                          <div className="col">
                            <p>
                              OVERDUE <br />{" "}
                              <span className="overdue">
                                {" "}
                                &#8377;{" "}
                                {formatMoney(
                                  customer.customersAccount[0] &&
                                    customer.customersAccount[0]
                                      .customerOverdueOutstanding
                                )}{" "}

                              </span>
                            </p>
                          </div>
                          <div className="col">
                            <p>
                              CREDIT LIMIT <br />
                              <span className="credit-limit">
                                {" "}
                                &#8377; {formatMoney(customer.creditLimit)}{" "}
                              </span>
                            </p>
                          </div>
                          <div className="col">
                            <p>
                              CREDIT DAYS <br />
                              <span className="credit-days">
                                {" "}
                                {customer.customercreditDays &&
                                  customer.customercreditDays[0] &&
                                  customer.customercreditDays[0].creditDays}

                              </span>
                            </p>
                          </div>

                          <img
                            src={redCross}
                            alt="cross"
                            className="p-1 "
                            onClick={
                              this.state.customerlist &&
                                this.state.customerlist.length == 1
                                ? () =>
                                  this.showWarningModal(customer.customerId)
                                : () =>
                                  this.displayRemoveCustomerModal(
                                    customer.customerId
                                  )
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Spin>
            {displayRemoveCustomerModalStatus && (
              <DeleteConfirmation
                header={"Delete Customer"}
                closeDeleteConfirmationModal={this.displayRemoveCustomerModal}
                warningMsg={
                  "Are you sure you want to delete the Customer from the list?"
                }
                removeItem={this.deleteCustomer}
              />
            )}
            {this.state.showWarningModalStatus && (
              <DeleteConfirmation
                header={"Alert"}
                closeDeleteConfirmationModal={this.showWarningModal}
                warningMsg={
                  "This is the last customer in the beat plan.If you remove it, it will also delete the beat plan. Delete anyway?"
                }
                removeItem={this.deleteCustomer}
              />
            )}
            {adhocCustomerModalStatus && (
              <AddCustomerModal
                displayModal={this.displayAdhocCustomerModal}
                addCustomersToBeatplan={this.addCustomersToBeatplan}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

export default PublishedBeatPlan;
