import React, { Component } from "react";
import "./modal.scss";
import cross from "../../images/cross.svg";
import history from "./../../utils/History";
import { formatMoney } from "../../utils/common";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Space } from "antd";
import { Link } from "react-router-dom";

class RepeatedCreditLimitExtension extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    let { modalData } = this.props;

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <React.Fragment>
        <div className="Backdrop">
          <div className="rcredit-modal-container ">
            <div className="text-center">
              <div className="credit-limit-text ml-2 ml-5 mr-5 mt-4">
                Required Credit Limt increased from 10,516/- to 20,516/- because
                Credit Exposure has been increased from 12,000 to 24,000.
              </div>
              <div className="credit-limit-text mt-5 ">
                Are you sure you want to proceed ?
              </div>
              <Link
                to={{
                  pathname: "/dashboard/cod-list",
                }}
              >
                <button className="btn btn-primary mr-4 mt-4">Yes</button>
              </Link>

              <button
                className="btn  btn-outline-primary mt-4"
                onClick={this.props.closeModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default RepeatedCreditLimitExtension;
