import { m } from "framer-motion";

export const deliveryDateOptions = [
  { key: "0 (Same Day Delivery)", value: "0" },
  { key: "1 day", value: "1" },
  { key: "2 days", value: "2" },
  { key: "3 days", value: "3" },
  { key: "4 days", value: "4" },
  { key: "5 days", value: "5" },
  { key: "6 days", value: "6" },
  { key: "7 days", value: "7" },
  { key: "8 days", value: "8" },
  { key: "9 days", value: "9" },
  { key: "10 days", value: "10" },
  { key: "11 days", value: "11" },
  { key: "12 days", value: "12" },
  { key: "13 days", value: "13" },
  { key: "14 days", value: "14" },
  { key: "15 days", value: "15" },
  { key: "16 days", value: "16" },
  { key: "17 days", value: "17" },
  { key: "18 days", value: "18" },
  { key: "19 days", value: "19" },
  { key: "20 days", value: "20" },
  { key: "21 days", value: "21" },
  { key: "22 days", value: "22" },
  { key: "23 days", value: "23" },
  { key: "24 days", value: "24" },
  { key: "25 days", value: "25" },
  { key: "26 days", value: "26" },
  { key: "27 days", value: "27" },
  { key: "28 days", value: "28" },
  { key: "29 days", value: "29" },
  { key: "30 days", value: "30" },
];

export const weekDays = [
  { key: "sunday", value: "Sunday" },
  { key: "monday", value: "Monday" },
  { key: "tuesday", value: "Tuesday" },
  { key: "wednesday", value: "Wednesday" },
  { key: "thursday", value: "Thursday" },
  { key: "friday", value: "Friday" },
  { key: "saturday", value: "Saturday" },
];
export const hours = [
  {
    key: "00",
    value: "00",
  },
  {
    key: "01",
    value: "01",
  },
  {
    key: "02",
    value: "02",
  },
  {
    key: "03",
    value: "03",
  },
  {
    key: "04",
    value: "04",
  },
  {
    key: "05",
    value: "05",
  },
  {
    key: "06",
    value: "06",
  },
  {
    key: "07",
    value: "07",
  },
  {
    key: "08",
    value: "08",
  },
  {
    key: "09",
    value: "09",
  },
  {
    key: "10",
    value: "10",
  },
  {
    key: "11",
    value: "11",
  },
  {
    key: "12",
    value: "12",
  },
  {
    key: "13",
    value: "13",
  },
  {
    key: "14",
    value: "14",
  },
  {
    key: "15",
    value: "15",
  },
  {
    key: "16",
    value: "16",
  },
  {
    key: "17",
    value: "17",
  },
  {
    key: "18",
    value: "18",
  },
  {
    key: "19",
    value: "19",
  },
  {
    key: "20",
    value: "20",
  },
  {
    key: "21",
    value: "21",
  },
  {
    key: "22",
    value: "22",
  },
  {
    key: "23",
    value: "23",
  },
  {
    key: "24",
    value: "24",
  },
];
export const minutes = [
  {
    key: "00",
    value: "00",
  },
  {
    key: "05",
    value: "05",
  },
  {
    key: "10",
    value: "10",
  },
  {
    key: "15",
    value: "15",
  },
  {
    key: "20",
    value: "20",
  },
  {
    key: "25",
    value: "25",
  },
  {
    key: "30",
    value: "30",
  },
  {
    key: "35",
    value: "35",
  },
  {
    key: "40",
    value: "40",
  },
  {
    key: "45",
    value: "45",
  },
  {
    key: "50",
    value: "50",
  },
  {
    key: "55",
    value: "55",
  },
  {
    key: "60",
    value: "60",
  },
];
