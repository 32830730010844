import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";

// details from zoho
export const zohoDetails = async (empId) => {
  let request = await axios.get(
    APIConfig.base_url + "api/v1/employee/zoho/details?empId=" + empId,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
// details of asm from dms backend
export const areaSalesManagerDetails = async (id) => {
  let request = await axios.get(
    APIConfig.base_url + `api/v1/employee/asm/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
// details of salesman from dms backend
export const salesManDetails = async (id) => {
  let request = await axios.get(
    APIConfig.base_url + `api/v1/employee/salesman/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const updateDetails = async (inputs, type) => {
  let url = type == "asm" ? "api/v1/employee/asm" : "api/v1/employee/salesman";
  console.log("url", APIConfig.base_url + url);
  let request = await axios.post(
    APIConfig.base_url + url,
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );

  // returning the request
  return request;
};
export const getAllAsms = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "api/v1/employee/asm" + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const toggleStatus = async (id, status, type) => {
  let url =
    type == "asm"
      ? `api/v1/employee/asm/${id}/status/${status}`
      : `api/v1/employee/salesman/${id}/status/${status}`;
  let request = await axios.patch(
    APIConfig.base_url + url,
    {},
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const removeAsm = async (id) => {
  let request = await axios.delete(
    APIConfig.base_url + `api/v1/employee/asm/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );

  // returning the request
  return request;
};

//remove salesman
export const removeSalesman = async (id) => {
  let request = await axios.delete(
    APIConfig.base_url + `api/v1/employee/salesman/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );

  // returning the request
  return request;
};

//genare OTP

export const generateOtp = async (id) => {
  let request = await axios.get(
    APIConfig.base_url + `api/v1/employee/salesman/generate/otp/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );

  // returning the request
  return request;
};

//verify OTP
export const verifyOtp = async (id, otp) => {
  let request = await axios.delete(
    APIConfig.base_url + `api/v1/employee/salesman/verify/otp/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
      params: {
        otp: otp,
      },
    }
  );
  let response = request?.data;

  // returning the request
  return response;
};

// get all salesman
export const getAllSalesmen = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "api/v1/employee/salesman" + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// get available asms to create a salesman
export const getAsmListForDropdown = async (page, search = "") => {
  let request = await axios.get(
    APIConfig.base_url +
      `api/v1/employee/asm/minified/list?page=${page}&search=${search}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//For HR portal
export const getAsmListForDropdownHRRole = async (
  page,
  search = "",
  distributorId
) => {
  let request = await axios.get(
    APIConfig.base_url +
      `api/v1/employee/asm/minified/list?page=${page}&search=${search}&distributorId=${distributorId}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
// edit asm

export const editAsm = async (id, inputs) => {
  console.log("service inputs", inputs);
  let url = `api/v1/employee/asm/${id}`;

  let request = await axios.patch(
    APIConfig.base_url + url,
    { ...inputs },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
// edit salesman

export const editSalesman = async (id, inputs) => {
  let url = `api/v1/employee/salesman/${id}`;

  let request = await axios.patch(
    APIConfig.base_url + url,
    { ...inputs },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// get salesman count by asm id
export const getSalesmanCount = async (asmId) => {
  let request = await axios.get(
    APIConfig.base_url + `api/v1/employee/asm/${asmId}/impact/salesman/count`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
// map the unmapped salesman/salesmen with asm
export const mapSalesmanWithAsm = async (salesmanIds, reportingManagerId) => {
  let request = await axios.patch(
    APIConfig.base_url + "api/v1/employee/salesman/bulk/asm-mapping",
    {
      salesmanIds,
      reportingManagerId,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//asm profile update

export const updateAsmProfile = async (input, asmId) => {
  let request = await axios.patch(
    APIConfig.base_url + `api/v1/employee/asm/${asmId}`,
    {
      ...input,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//salesman profile update

export const updateSalesmanProfile = async (input, salId) => {
  let request = await axios.patch(
    APIConfig.base_url + `api/v1/employee/salesman/${salId}`,
    {
      ...input,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// to get agency list

export const getAgencyList = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/agency/list` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const createAsm = async (inputs, type) => {
  let url = type == "asm" ? "api/v1/employee/asm" : "api/v1/employee/salesman";
  let request = await axios.post(
    APIConfig.base_url + url,
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );

  // returning the request
  return request;
};

export const createAgency = async (inputs) => {
  let url = "api/v1/agency";
  let request = await axios.post(
    APIConfig.base_url + url,
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );

  // returning the request
  return request;
};
// get reports
export const getAllReports = async (date) => {
  let url = "api/v1/employee/salesman/report/date-wise";
  let request = await axios.post(
    APIConfig.base_url + url,
    {
      dates: [date],
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );

  // returning the request
  return request;
};

// Bulk Upload
export const reportDownload = async (date, type) => {
  let url = `api/v1/employee/salesman/report/date-wise/download?type=${type}`;

  let request = await axios.post(
    APIConfig.base_url + url,
    {
      dates: [date],
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};
// get customer details for report section by customer id

export const getCustomerDetails = async (date, id) => {
  let url = `api/v1/employee/salesman/${id}/report/date-wise`;

  let request = await axios.post(
    APIConfig.base_url + url,
    {
      dates: [date],
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};

export const getOrderDetails = async (date, id) => {
  console.log("date,", date);
  let url = `api/v1/employee/salesman/${id}/order/report/date-wise`;
  let request = await axios.post(
    APIConfig.base_url + url,
    {
      dates: [date],
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};

export const particularReportDownload = async (
  type,
  from,
  to,
  dynamic,
  companyCode
) => {
  let url =
    type === "onboarding"
      ? `api/v1/rpt/rptdownload?from=${from}&to=${to}`
      : type === "adoption-metrics"
      ? `app/api/v1/salesman/adoption-metrics-warehouse?from=${from}&to=${to}`
      : type === "orders" && !dynamic
      ? `api/v1/sorpt/sorptdownload?from=${from}&to=${to}`
      : type == "orders" && dynamic && companyCode != "1000"
      ? `api/v1/sorpt/soreport?from=${from}-&to=${to}`
      : type === "itemisedOrder" && !dynamic
      ? `api/v1/soitem/rpt/soitem/download?from=${from}&to=${to}`
      : type === "itemisedOrder" && dynamic && companyCode != 1000
      ? `api/v1/sorpt/soitemreport?from=${from}-&to=${to}`
      : type === "customer-force-checkin"
      ? `app/api/v1/salesman/customer-force-checkin/download/csv?from=${from}&to=${to}`
      : type === "delivery-verification"
      ? `api/v1/rpt/rpdeliveryverification?from=${from}&to=${to}`
      : type === "tax-report"
      ? `api/v1/sorpt/taxitemreport?from=${from}&to=${to}`
      : type === "product-margin"
      ? `api/v1/sorpt/productmargin?from=${from}&to=${to}`
      : `api/v1/employee/salesman/report/${type}/date-range/download?from=${from}&to=${to}`;

  let request = await axios.get(APIConfig.base_url + url, {
    headers: {
      "x-access-token": getAuthToken(),
      "Content-Type": "text/csv",
    },
  });
  return request;
};
export const asmDownloadReport = async (from, to) => {
  let request = await axios.get(
    APIConfig.base_url +
      `app/api/v1/salesman/asm-onboardingReports?from=${from}&to=${to}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "text/csv",
      },
    }
  );
  return request;
};

//get Distributors
export const getDistributorOptions = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/assests/allDistributors`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
