import axios from "axios";
import { getAuthToken } from "../../utils/Auth";
import { api as APIConfig } from "../../appConfig";
import { getQparams } from "../../utils/common";

//get returnDate dropdown
export const getReturnDate = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/standalone/salesreturn/getReturnDate` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get customerId dropdown
export const getCutomerId = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url +
      `api/v1/standalone/salesreturn/getCustomerDetails` +
      qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get invoiceDate dropdown
export const getInvoiceDate = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/standalone/salesreturn/getInvoiceDate` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get paymenttype dropdown
export const getPaymentType = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/standalone/salesreturn/getPaymentType` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

//get searchlist
export const getSearchList = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/standalone/salesreturn/searchListing` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getSalesReturnView = async (id) => {
  let request = await axios.get(
    APIConfig.base_url + `api/v1/standalone/salesreturn/searchListing/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getInvoiceListDropdown = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/standalone/salesreturn/raise-return` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const postRaiseReturn = async (inputs) => {
  let request = await axios.post(
    APIConfig.base_url +
      `api/v1/standalone/salesreturn/raise-return/item-detail-return`,
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getInvoiceDetail = async (id) => {
  let request = await axios.get(
    APIConfig.base_url +
      `api/v1/standalone/salesreturn/raise-return/details/${id}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
export const getItemDetailByInvoiceNo = async (invoiceNo, itemId) => {
  let request = await axios.get(
    APIConfig.base_url +
      `api/v1/standalone/salesreturn/raise-return/itemDetails/${invoiceNo}?itemId=${itemId}`,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
