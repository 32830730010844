import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tabs, Typography, Divider, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import OrderStatusStepper from "../OrderStatusStepper";
import message from "antd/lib/message";
import ShippmentDetails from "./ShippmentDetails";
import {
  triggerOtpService,
  getOTPMenuConfig,
} from "../../../services/otpServices/otpServices";
import shippmenticon from "../../../images/tabsicon.svg";
//import history from "../../../utils/History";
import { handleErrorResponse } from "../../../../src/utils/Request";
function Welcome({ bgColor }) {
  return (
    <div
      style={{
        height: "10px",
        width: "10px",
        borderRadius: "50px",
        backgroundColor: bgColor,
        marginTop: "4px",
      }}
    ></div>
  );
}
function Shipment({ invoiceNo }) {
  // useEffect(() => {

  // }, [invoiceNo]);
  const { IndividualOrderDetails, ConfigData } = useSelector(
    (state) => state.individualOrderDetailsReducer
  );
  let result = IndividualOrderDetails[0]?.shipments
    ?.findIndex((x) => x.invoiceNo == invoiceNo)
    .toString();
  //result = result?.toString();

  let shipment, paymentstatus, digitalsign, pod, invoiceno;
  let Otp = "3";
  const params = useParams();
  let history = useHistory();
  const creditNote = IndividualOrderDetails[0]?.creditNote;

  const customerID = params?.customerID;
  const soId = params?.soId;
  if (IndividualOrderDetails[0]?.shipments?.length == 1) {
    shipment = IndividualOrderDetails[0]?.shipments[0];
    paymentstatus = shipment?.paymentStatus;
    pod = shipment?.pod;
    digitalsign = shipment?.digitalSignature;
    invoiceno = shipment?.invoiceNo;
  }
  let iconFlag = IndividualOrderDetails[0]?.soStatusNumber != 1 ? true : false;
  const paymentMode = IndividualOrderDetails[0]?.SopaymentMode;
  const [defaultTab, setDefaultTab] = useState("");

  useEffect(() => {
    if (result) {
      setDefaultTab(result);
    }
  }, [result]);
  const onChangeTab = (tabIndex) => {
    setDefaultTab(tabIndex);
  };

  const val = IndividualOrderDetails[0]?.shipments?.map((item, index) => {
    let flag = false;
    let len = item?.invoiceTrack.length;
    if (len == 0) {
      flag = iconFlag;
    } else if (len == 3) {
      if (item?.invoiceTrack[len - 1].invoiceStatus != 4) {
        flag = true;
      }
    } else if (len == 2) {
      if (item?.invoiceTrack[len - 1].invoiceStatus != 3) {
        flag = true;
      }
    } else if (len == 1) {
      if (item?.invoiceTrack[len - 1].invoiceStatus != 1) {
        flag = true;
      }
    } else {
      flag = true;
    }
    item.IsIcon = flag;
  });
  const invoiceDetails = async () => {
    try {
      // history.push({
      //   pathname:
      //     "/dashboard/orders/viewinvoice/" +
      //     soId +
      //     "/" +
      //     record.mginvoiceId +
      //     "/" +
      //     customerID +
      //     "/" +
      //     1,
      // });
      const InvoiceId = shipment.invoiceId;
      Otp = "3";
      const shipmentindex = 0;
      const location = {
        pathname: `/dashboard/orders/viewinvoice/`,
        state: { soId, InvoiceId, customerID, Otp, shipment, shipmentindex },
      };
      history.push(location);
    } catch (err) {
      console.log("err");
    }
  };
  const triggerOTP = async (record) => {
    try {
      const InvoiceId = shipment.invoiceNo;

      await triggerOtpService(soId, InvoiceId, ConfigData?.isLogistics).then(
        (res) => {
          if (res?.status == 200) {
            // history.push({
            //   pathname:
            //     "/dashboard/orders/viewinvoice/" +
            //     soId +
            //     "/" +
            //     record.mginvoiceId +
            //     "/" +
            //     customerID +
            //     "/" +
            //     4,
            // });
            const InvoiceId = shipment.invoiceId;
            Otp = "4";
            const shipmentindex = 0;
            const location = {
              pathname: `/dashboard/orders/viewinvoice/`,
              state: {
                soId,
                InvoiceId,
                customerID,
                Otp,
                shipment,
                shipmentindex,
              },
            };
            history.push(location);
          } else {
            message.error(res?.message);
          }
        }
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        console.log(error, "error");
        const { errorMessage, errors } = handleErrorResponse(error);
      } else {
        message.error(error?.response?.data?.message);
      }
    }
  };
  const getPODStatus = () => {
    if (shipment?.isCompleteSalesReturn == true) {
      return "NA";
    }
    if (shipment?.invoiceTrack?.length < 3) {
      if (ConfigData?.isLogistics == false) {
        if (pod?.length > 0) {
          return "AVAILABLE";
        } else if (pod?.length == 0) {
          return "UPLOAD";
        }
      } else {
        return "NA";
      }
    }

    if (pod?.length > 0) {
      return "AVAILABLE";
    } else if (pod?.length == 0) {
      return "UPLOAD";
    } else {
      return "NA";
    }

    return "NA";
  };
  const detailsBtnDisplay = () => {
    if (ConfigData.isLogistics == false) {
      return true;
    } else if (shipment?.isCompleteSalesReturn == true) {
      return false;
    } else if (shipment?.invoiceTrack?.length < 3) {
      return false;
    } else {
      return true;
    }
  };
  const getOTPStatus = () => {
    if (ConfigData?.isLogistics == false) {
      if (shipment?.otp?.status === true) {
        return "Verified";
      } else {
        return "Trigger";
      }
    } else {
      if (shipment?.isCompleteSalesReturn == true) {
        return "NA";
      }
      if (shipment?.otp?.status === true) {
        return "Verified";
      } else if (shipment?.otp?.statusFlag === true) {
        return "Trigger";
      } else if (shipment?.otp?.statusFlag === false) {
        return "Trigger OTP After ";
      } else {
        return "NA";
      }
    }
  };
  const triggerPod = async (record) => {
    try {
      // history.push({
      //   pathname:
      //     "/dashboard/orders/viewinvoice/" +
      //     soId +
      //     "/" +
      //     record.mginvoiceId +
      //     "/" +
      //     customerID +
      //     "/" +
      //     3,
      // });
      const InvoiceId = shipment.invoiceId;

      Otp = "3";
      const shipmentindex = 0;
      const location = {
        pathname: `/dashboard/orders/viewinvoice/`,
        state: { soId, InvoiceId, customerID, Otp, shipment, shipmentindex },
      };
      history.push(location);
    } catch (err) {
      console.log("err");
    }
  };
  const getRemainingTime = () => {
    const minutes = shipment?.otp?.pendingTime;
    if (minutes == undefined || minutes < 0) {
      return "";
    }

    if (minutes < 60) {
      return minutes + " Minutes";
    } else {
      var hours = parseInt(minutes / 60);
      var min = minutes % 60;
      if (hours == 1) {
        return hours + " Hour " + min + " Minutes";
      } else {
        return hours + " Hours " + min + " Minutes";
      }
    }
  };
  return (
    <div>
      <div>
        {IndividualOrderDetails[0]?.shipments?.length > 1 ? (
          <Tabs activeKey={defaultTab} onChange={onChangeTab}>
            {IndividualOrderDetails[0]?.shipments?.map((item, index) => (
              <Tabs.TabPane
                tab={
                  <div style={{ display: "flex" }}>
                    <Typography>{`Shipment ${index + 1} `}</Typography>
                    {item.IsIcon ? (
                      <img
                        style={{ marginLeft: "10px" }}
                        src={shippmenticon}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                  </div>
                }
                key={index}
              >
                <OrderStatusStepper data={item.invoiceTrack} />
                <ShippmentDetails
                  title="Shipment Details"
                  invoiceno={item?.invoiceNo}
                  paymentstatus={item.paymentStatus}
                  digitalsign={item.digitalSignature}
                  pod={item.pod}
                  otp={item.otp.status}
                  shipment={item}
                  shipmentindex={index}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        ) : IndividualOrderDetails[0]?.shipments?.length == 1 ? (
          <div>
            <OrderStatusStepper
              data={IndividualOrderDetails[0]?.shipments[0]?.invoiceTrack}
            />
            <div className="m-2 p-3" style={{ backgroundColor: "#FFFFFF" }}>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "22px",
                  color: "##3A4960",
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "-8px",
                  marginTop: "10px",
                  alignItems: "center",
                }}
              >
                <Divider
                  type="vertical"
                  style={{
                    border: "2px solid #5078F2",
                    height: "24px",
                  }}
                />
                Shipment Details
              </Typography>
              <div
                className="ml-4 m-3 p-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "-26px",
                  }}
                >
                  <span style={{ flexDirection: "column" }}>
                    <Typography
                      style={{
                        color: "#7A7A7A",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "17px",
                      }}
                    >
                      Invoice Number
                    </Typography>
                    <Typography
                      style={{
                        color: "#3A4960",
                        fontSize: "20px",
                        fontWeight: 600,
                        lineHeight: "34px",
                      }}
                    >
                      #{invoiceno}
                    </Typography>
                  </span>

                  <Divider
                    type="vertical"
                    style={{
                      height: 120,

                      border: "2px solid #ECEDEE",
                    }}
                  />
                </div>
                <div className="w-100">
                  <div
                    className="d-flex justify-content-between px-5"
                    style={{ marginLeft: "-25px" }}
                  >
                    <div>
                      <Typography
                        style={{
                          color: "#7A7A7A",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "15px",
                        }}
                      >
                        Payment Status
                      </Typography>
                      <Typography
                        style={{
                          color: "#3A4960",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "17px",
                          marginTop: "8px",
                        }}
                      >
                        {paymentstatus}
                      </Typography>
                    </div>
                    {ConfigData?.deDigitalSignature === true ? (
                      <div>
                        <Typography
                          style={{
                            color: "#7A7A7A",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "15px",
                          }}
                        >
                          Digital Sign
                        </Typography>

                        <div style={{ display: "flex", marginTop: "8px" }}>
                          {digitalsign?.status === true ? (
                            <div style={{ display: "flex" }}>
                              <Welcome bgColor="#219653" />
                              <Typography style={{ paddingLeft: "10px" }}>
                                Verified
                              </Typography>
                            </div>
                          ) : digitalsign?.status === false ? (
                            <div style={{ display: "flex" }}>
                              <Welcome bgColor="#E13440" />
                              <Typography style={{ paddingLeft: "10px" }}>
                                Not Verified
                              </Typography>
                            </div>
                          ) : (
                            <div style={{ display: "flex" }}>
                              <Welcome bgColor="#E13440" />
                              <Typography style={{ paddingLeft: "10px" }}>
                                NA
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}

                    <div>
                      <Typography
                        style={{
                          color: "#7A7A7A",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "15px",
                        }}
                      >
                        POD
                      </Typography>

                      <div style={{ display: "flex", marginTop: "8px" }}>
                        <Typography>
                          {getPODStatus().toUpperCase() === "AVAILABLE" ? (
                            <div style={{ display: "flex" }}>
                              <Welcome bgColor="#219653" />
                              <Typography style={{ marginLeft: "5px" }}>
                                Available
                              </Typography>
                            </div>
                          ) : getPODStatus().toUpperCase() === "UPLOAD" ? (
                            <Button type="link" onClick={() => triggerPod()}>
                              <div
                                style={{ display: "flex", marginLeft: "-15px" }}
                              >
                                <Welcome bgColor="#E13440" />
                                <Typography
                                  className="podtext"
                                  style={{
                                    marginLeft: "5px",
                                    textDecoration: "underline",
                                    color: "blue",
                                  }}
                                >
                                  Upload
                                </Typography>
                              </div>
                            </Button>
                          ) : (
                            <div style={{ display: "flex" }}>
                              <Welcome bgColor="#E13440" />
                              <Typography style={{ paddingLeft: "10px" }}>
                                NA
                              </Typography>
                            </div>
                          )}
                        </Typography>
                      </div>
                    </div>
                    {ConfigData?.deOtpStatus === true ? (
                      <div>
                        <Typography
                          style={{
                            color: "#7A7A7A",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "15px",
                          }}
                        >
                          OTP
                        </Typography>

                        <div style={{ display: "flex", marginTop: "8px" }}>
                          {getOTPStatus().toUpperCase() === "VERIFIED" ? (
                            <div style={{ display: "flex" }}>
                              <Welcome bgColor="#219653" />
                              <Typography style={{ paddingLeft: "10px" }}>
                                {getOTPStatus()}
                              </Typography>{" "}
                            </div>
                          ) : getOTPStatus().toUpperCase() ===
                            "TRIGGER OTP AFTER " ? (
                            <div
                              style={{ display: "flex", marginLeft: "-1px" }}
                            >
                              <Welcome bgColor="#E13440" />
                              <div style={{ paddingLeft: "10px" }}>
                                <Typography style={{ color: "#979797" }}>
                                  {getOTPStatus()}{" "}
                                </Typography>
                                <Typography style={{ color: "#979797" }}>
                                  {" "}
                                  {getRemainingTime()}
                                </Typography>
                              </div>
                            </div>
                          ) : getOTPStatus().toUpperCase() === "TRIGGER" ? (
                            <Button type="link" onClick={() => triggerOTP()}>
                              <div
                                style={{ display: "flex", marginLeft: "-17px" }}
                              >
                                <Welcome bgColor="#E13440" />
                                <Typography
                                  className="podtext"
                                  style={{
                                    marginLeft: "5px",
                                    textDecoration: "underline",
                                    color: "blue",
                                  }}
                                >
                                  Trigger OTP
                                </Typography>
                              </div>
                            </Button>
                          ) : getOTPStatus().toUpperCase() === "NA" ? (
                            <div style={{ display: "flex" }}>
                              <Welcome bgColor="#E13440" />
                              <Typography style={{ paddingLeft: "10px" }}>
                                {getOTPStatus()}
                              </Typography>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    <div>
                      <Typography
                        style={{
                          color: "#7A7A7A",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "17px",
                        }}
                      >
                        Credit note/Debit note
                      </Typography>

                      <Typography style={{ marginTop: "8px" }}>
                        {creditNote}
                      </Typography>
                    </div>

                    <div style={{ marginRight: "-100px" }}>
                      {detailsBtnDisplay() ? (
                        <div style={{ marginTop: "15px" }}>
                          <Button
                            style={{
                              border: "#5078F2 1px solid",
                              width: "100px",
                              height: "46px",
                              color: "#5078F2",
                              fontSize: "14px",
                            }}
                            onClick={(record) => invoiceDetails()}
                          >
                            Detail
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <OrderStatusStepper data={[]} />
            <div>
              <div className="m-2 p-3" style={{ backgroundColor: "#FFFFFF" }}>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "##3A4960",
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "-8px",
                    marginTop: "10px",
                    alignItems: "center",
                  }}
                >
                  <Divider
                    type="vertical"
                    style={{
                      border: "2px solid #5078F2",
                      height: "24px",
                    }}
                  />
                  Shipment Details
                </Typography>
                <div style={{ marginTop: "22px", marginLeft: "10px" }}>
                  <Typography
                    style={{
                      color: "#7A7A7A",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "15px",
                    }}
                  >
                    Payment Status
                  </Typography>
                  {paymentMode == "waycred" ||
                    paymentMode == "waycoolcredit" ? (
                    <Typography
                      style={{
                        color: "#3A4960",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "17px",
                        marginTop: "8px",
                      }}
                    >
                      Paid
                    </Typography>
                  ) : (
                    <Typography
                      style={{
                        color: "#3A4960",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "17px",
                        marginTop: "8px",
                      }}
                    >
                      Unpaid
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Shipment;
