import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import AddNewAsm from "../components/team/addNewAsm";

//Action Creator
import { asmData } from "../action/team"



const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      asmData
    },
    dispatch
  );
};

export default withRouter(
  connect(null, mapDispatchToProps)(AddNewAsm)
);
