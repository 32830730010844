import React, { useState, useEffect } from "react";
import { Typography, Input, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  searchEmployeeDetails,
  emptysearchfields,
} from "../../action/teamMappingAction";
import { notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import profilephoto from "../../images/default-pic.jpg";
import { useLocation } from "react-router-dom";
import { AddEmployeedata } from "../../services/teamMapping/teamMappingService";
function AddEmployee() {
  const { Search } = Input;
  const searchdetailal = useSelector(
    (state) => state.teamMappingReducer.searchdetailal
  );
  const dispatch = useDispatch();
  const searchemployeedetail = useSelector(
    (state) => state.teamMappingReducer.searchemploye
  );
  const [cancelemployee, setCancelEmployee] = useState(searchdetailal);

  // const [searchKey, setSearchKey] = useState(false);
  console.log(searchdetailal);
  const history = useHistory();

  const { state: path } = useLocation();
  const searchemployeedetails = searchemployeedetail;
  console.log(searchemployeedetails?.fullName, "searchemployeedetail");
  const params = useParams();
  const id = params.id;
  console.log(path);
  console.log(id, "iddemplo");
  const employeenum = searchemployeedetails?.employeeId;
  const addemployeeId = searchemployeedetails?._id;
  console.log(addemployeeId, "addemployeeId", employeenum);
  // searchemployeedetail?.data[0] || searchemployeedetail;

  console.log(addemployeeId, "addemployeeId", employeenum);
  const hadleAddEmployee = async (path) => {
    console.log(id, "addemployeeId", employeenum);
    try {
      const res = await AddEmployeedata({ id, employeenum, path });

      if (res.data.status == 200) {
        console.log(res, "message");
        notification.success({ message: res.data.message });
        history.push("/dashboard/teammapping/heads/" + path);
      }
    } catch (err) {}
  };
  const handleSearch = () => {
    console.log(employerId, "employerId");
    if (employerId === "") {
      notification.warning({
        message: "Please enter employee id.",
      });
    } else if (employerId !== "") {
      // setSearchKey(searchdetailal);
      dispatch(searchEmployeeDetails(employerId));
    }
  };
  useEffect(() => {
    return () => dispatch(emptysearchfields());
  }, []);

  const [employerId, setEmployeId] = useState("");

  const [employeeImage, setEmployeeImage] = useState("");

  const [fullname, setFullName] = useState("");
  const [manger, setManger] = useState("");
  const [mail, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const onSearchChange = (e) => {
    console.log(e.target.value, "kk");
    setEmployeId(e.target.value);
  };
  useEffect(() => {
    setFullName(searchemployeedetails?.fullName);
    setManger(searchemployeedetails?.reportingTo);
    setEmail(searchemployeedetails?.email);
    setPhone(searchemployeedetails?.contactMobile);
    setEmployeeImage(searchemployeedetails?.photo);
  }, [searchemployeedetails]);
  useEffect(() => {
    setCancelEmployee(searchdetailal);
  }, [searchdetailal]);
  const setCancelSearchdetails = () => {
    setCancelEmployee(!cancelemployee);
    setEmployeId("");
  };
  return (
    <div className="mt-3 w-100 px-4">
      {/*<PrimaryHeader title="View Salesman" /> */}
      <div className="mt-4" style={{ display: "flex", gap: "100px" }}>
        <span>
          <Typography
            style={{ fontSize: "14px", fontWeight: 400, marginTop: "25px" }}
          >
            Employer ID
          </Typography>
        </span>
        <span>
          <span>
            <div className="d-flex ml-4 mt-3">
              <Input
                style={{
                  width: "284px",
                  height: "45px",
                  borderRadius: "5px",
                  border: "2px solid #E1E7F0",
                }}
                size="large"
                className="serach_customerswithId"
                placeholder="Please enter employee id"
                // onSearch={handleSearch}
                value={employerId}
                onChange={onSearchChange}
              />
              {cancelemployee === false ? (
                <Button className="add-employee1" onClick={handleSearch}>
                  Search
                </Button>
              ) : (
                ""
              )}
            </div>
          </span>
        </span>
      </div>
      {cancelemployee == true ? (
        <>
          <div style={{ display: "flex", gap: "95px", marginTop: "23px" }}>
            <span>
              <Typography>Salesman Details</Typography>
            </span>
            <span>
              <span>
                <Typography>PROFILE PHOTO</Typography>
                {employeeImage !== "NA" && employeeImage !== "" ? (
                  <img src={employeeImage} alt="" style={{ width: "130px" }} />
                ) : (
                  <img src={profilephoto} alt="" style={{ width: "130px" }} />
                )}
                <Typography className="employee" style={{ marginTop: "23px" }}>
                  FULL NAME
                </Typography>
                <Input
                  value={fullname}
                  style={{
                    border: "none",
                    marginTop: "10px",
                    marginLeft: "-10px",
                  }}
                  className="emplee_inputtext"
                />
                <Typography className="employee" style={{ marginTop: "15px" }}>
                  REPORTING MANAGER
                </Typography>
                <Input
                  value={manger}
                  style={{
                    border: "none",
                    marginTop: "10px",
                    marginLeft: "-10px",
                  }}
                  className="emplee_inputtext"
                />
              </span>
              <span>
                <Typography className="employee" style={{ marginTop: "15px" }}>
                  Email id
                </Typography>
                <Input value={mail} className="employee_inputfields" readOnly />
              </span>
              <span>
                <Typography className="employee" style={{ marginTop: "15px" }}>
                  Phone Number
                </Typography>

                <Input
                  value={phone}
                  className="employee_inputfields"
                  readOnly
                />
              </span>
            </span>
          </div>
          <div style={{ marginLeft: "169px" }} className="mt-2">
            <Button
              className="add-employee"
              onClick={() => hadleAddEmployee(path)}
            >
              Submit
            </Button>
            <Button
              onClick={setCancelSearchdetails}
              className="cancel-employee"
            >
              Cancel
            </Button>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default AddEmployee;
