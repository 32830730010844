import React, { Component } from "react";
import "./drafts.scss";
import "./publish.scss";
import trash from "../../images/trash.svg";
import SearchBar from "../search-bar/searchBar";
import { LoadingOutlined } from "@ant-design/icons";
import { handleErrorResponse } from "../../utils/Request";
import user from "../../images/user-logo.svg";
import calendar from "../../images/calendar.svg";
// import DatePicker from "react-datepicker";
import { getDayName } from "../../utils/common";
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import {
  getPublishedBeatplanList,
  removePublishBeatPlan,
} from "../../services/beatplanServices/beatplan";
import message from "antd/lib/message";
import { Spin } from "antd";
import DeleteConfirmation from "../modal/deleteConfirmation";
import { determineMaxdate, GetFormattedDate } from "../../utils/common";
import { Pagination } from "antd";
import PrimaryHeader from "../header/PrimaryHeader";
class Publish extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searchText: "",
      startDate: new Date(),
      page: 1,
      totalPage: 0,
      pageSize: 0,
      totalItems: 0,
      lists: [],
      showRemoveWarning: false,
      removeItemModalDisplayId: "",
    };
  }
  changeDate = (date) => {
    if (date != null) {
      this.setState(
        {
          startDate: date._d,
        },
        () => {
          this.getList();
        }
      );
    } else {
      let today = new Date();
      if (today !== null) {
        this.setState(
          {
            startDate: today,
          },
          () => {
            this.getList();
          }
        );
      }
    }
  };

  getList = () => {
    let { page, searchText, startDate } = this.state;
    this.setState({ loading: true });
    let _date = GetFormattedDate(startDate);
    let qParams = [{ page }, { search: searchText }, { date: _date }];
    getPublishedBeatplanList(qParams)
      .then((res) => {
        if (res.data.data.results.length == 0) {
          message.warning("No data available!");
        }
        this.setState({
          lists: res.data.data.results,
          loading: false,
          totalPage: res.data.data.pageMeta.total,
          pageSize: res.data.data.pageMeta.pageSize,
          totalItems: res.data.data.pageMeta.total,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  handleChange = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.getList();
      }
    );
  };
  onSearch = (e) => {
    this.setState(
      {
        searchText: e.target.value,
      },
      () => {
        this.getList();
      }
    );
  };
  removeItemModalDisplay = (item) => {
    this.setState({
      showRemoveWarning: !this.state.showRemoveWarning,
      removeItemModalDisplayId: item._id,
    });
  };
  removeItem = async () => {
    let id = this.state.removeItemModalDisplayId;
    await removePublishBeatPlan(id)
      .then((res) => {
        message.success("Beat plan has been removed");
        this.setState(
          {
            loading: false,
            showRemoveWarning: false,
          },
          () => {
            this.getList();
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  componentDidMount = () => {
    this.getList();
  };
  render() {
    let {
      loading,
      startDate,
      lists,
      searchText,
      showRemoveWarning,
      removeItemModalDisplayId,
      totalPage,
      pageSize,
      totalItems,
      page,
    } = this.state;
    let maxdate = determineMaxdate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <>
        <div id="content">
          <PrimaryHeader title="Day's Plan" />
          <Spin indicator={antIcon} spinning={loading}>
            <div className="day-plan-header">
              <SearchBar searchText={searchText} onSearch={this.onSearch} />
              <DatePicker onChange={(date) => this.changeDate(date)} />
            </div>

            <div
              className="table-wrapper"
              // ref="asmList"
            >
              <table className="table">
                <thead>
                  <tr>
                    {/* headers */}
                    <th>Assigned to</th>
                    <th>Employee Id</th>
                    <th>Plan ID</th>
                    <th>Day</th>

                    <th>Customers</th>
                    <th>Action </th>
                  </tr>
                </thead>

                {lists.length != 0 && (
                  <tbody>
                    {lists &&
                      lists.map((list, i) => {
                        return (
                          <tr key={i}>
                            <td
                              className="change-style cursor"
                              onClick={this.openSalesManDetailsModal}
                              title="click"
                              id="ID"
                            >
                              <img src={user} alt="logo" id="user-logo" />
                              {list.salesman.fullName}
                            </td>{" "}
                            <td> {list.salesman.employeeId}</td>
                            <td className="cursor">
                              <Link
                                to={{
                                  pathname: `/dashboard/beatplan/published/${list._id}`,
                                  beatPlanId: list.beatPlanId,
                                  _id: list._id,
                                }}
                                className="id-color"
                                id="ID"
                                title="click"
                              >
                                {list.beatPlanId}
                              </Link>
                            </td>
                            <td>{getDayName(list.day)}</td>
                            <td>{list.noOfCustomers}</td>
                            <td>
                              {/* <img
                                      src={edit}
                                      alt="edit"
                                      id="edit-icon"
                                      title="edit"
                                    /> */}
                              <img
                                src={trash}
                                alt="trash"
                                title="delete"
                                onClick={() =>
                                  this.removeItemModalDisplay(list)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                )}
                {/* {lists.length == 0 && (
                        <tbody>
                          <div className="noDataAlert">No data available</div>
                        </tbody>
                      )} */}
              </table>
            </div>
            {/* remove item */}
            {showRemoveWarning && (
              <DeleteConfirmation
                header={"Clear Published Beat Plan"}
                warningMsg={`Permanently clear the beat plan? You can’t undo this.`}
                closeDeleteConfirmationModal={this.removeItemModalDisplay}
                removeItem={this.removeItem}
                removeId={removeItemModalDisplayId}
              />
            )}
            {/* </div>
              </div> */}
            {/* </div> */}

            <div className="pagination">
              {totalItems != 0 && (
                <p className="p-2">Total {totalItems} plans </p>
              )}
              {totalPage != 0 && (
                <Pagination
                  className="page"
                  defaultCurrent={1}
                  current={page}
                  total={totalPage}
                  defaultPageSize={pageSize}
                  showSizeChanger={false}
                  onChange={(page, pageSize) =>
                    this.handleChange(page, pageSize)
                  }
                />
              )}
            </div>
          </Spin>
        </div>
      </>
    );
  }
}
export default Publish;
