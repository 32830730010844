import {
  ArrowRightOutlined,
  CloseOutlined,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  notification,
  Row,
  Spin,
  Table,
  Typography,
  IconButton,
} from "antd";
import React, { useEffect, useState, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  addToCart,
  clearCartItems,
  deleteFromCart,
  getCartItemList,
  updateCart as updateCartService,
  removeOutOfStockItemFromError,
} from "../../action/itemCart";
// import kitti from "../../images/kitti.jpg";
import kitty1 from "../../images/kitty1.svg";
import { placeorders } from "../../utils/allroutes";

// import { addToCart } from "../../action/itemCart";
import PrimaryHeader from "../header/PrimaryHeader";
import CustomerDetails from "./CustomerDetails";
// import { deleteFromCart, clearCartItems } from "../../action/itemCart";
import { getPaymentDetails } from "../../action/paymentDetailsaction";
import placeHolder from "../../images/groceries.svg";
import "./OrderBasket.scss";
import OrderSteps from "./OrderSteps";
import emptyCart from "../../images/emptybasket.svg";
import transhbin from "../../images/trashbin.svg";
import offerPercentage from "../../images/offerPercentage.svg";
import offerSelected from "../../images/offerSelected.svg";
import getINRString from "../../utils/orders/getINRString";
import _ from "lodash";
import { getPercentageDiscountPrice } from "./Item/ItemCard";

const { Title } = Typography;
const getFloat = (str) => parseFloat(str, 10);
function OrdersList() {
  const [closemodal, setCloseModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const { cartItemList, loading, metaData, outOfStockItem } = useSelector(
    (state) => state.itemCart
  );
  const [tableDisplay, setTableDisplay] = useState(false);
  const [selectedDiscountIndex, setSelectedDiscountIndex] = useState(null);
  const history = useHistory();
  const [quantityList, setQuantityList] = useState({});
  const [isSingleDeleteModalOpen, setIsSingleDeleteModalOpen] = useState(false);
  const [selectedItemToBeDeleted, setSelectedItemToBeDeleted] = useState(null);
  const { selectedCustomer } = useSelector(
    (state) => state.serachOrdersReducer
  );
  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModalDeleteItem = () => {
    setCloseModal(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  const closeModalDeleteItem = () => {
    setCloseModal(false);
  };
  const handleYes = () => {
    setIsModalVisible(false);
    clearCartItem();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleCancel1 = () => {
    setCloseModal(false);
    console.log("cancel");
  };

  const selectRecordAndOpenModal = (rec) => {
    setSelectedItemToBeDeleted({ ...rec });
    setIsSingleDeleteModalOpen(true);
  };

  useEffect(() => {
    initiateQuantityList();
  }, [cartItemList]);

  const initiateQuantityList = (id) => {
    let listOfQuantity = {};
    if (id) {
      listOfQuantity = { ...quantityList };
      let itemQty = cartItemList.find((item) => item.uniqueId === id);
      listOfQuantity[id] = { qty: itemQty.displayQty };
      setQuantityList({ ...listOfQuantity });
    } else {
      cartItemList.forEach((element) => {
        listOfQuantity[element.uniqueId] = { qty: element.displayQty };
      });
      setQuantityList({ ...listOfQuantity });
    }
  };
  // const qtyInput = useRef([]);

  // const [editOpen, setEditOpen] = useState(false);

  const updateApplicableOfferList = (quantity, discount, moq) => {
    // converting quantity to Integer

    let integerQuantity = getFloat(quantity);
    if (getFloat(moq) > 0) {
      integerQuantity = getFloat(quantity * getFloat(moq));
    }

    // If no quantity then remove selection and return.
    if (!integerQuantity) {
      return;
    }
    // If no discount return from here
    if (!discount || discount.length == 0) {
      return;
    }

    let discountTempList = [...discount];
    let theIndex = 0;

    // return;
    // Check if the quantity is in-between offers available and save the index in the state`

    if (integerQuantity < getFloat(discountTempList[0].min_quantity)) {
      return;
    }
    if (
      integerQuantity >=
      getFloat(discountTempList[discountTempList.length - 1].min_quantity)
    ) {
      theIndex = discountTempList.length - 1;
    } else {
      discountTempList.forEach((dic, index) => {
        if (discountTempList[index]) {
          if (
            integerQuantity >= dic.min_quantity &&
            integerQuantity < discountTempList[index + 1]?.min_quantity
          ) {
            theIndex = index;
          }
        } else {
          theIndex = index;
        }
      });
    }

    return theIndex;
  };

  const getDiscountString = (rec) => {
    const offerIndex = updateApplicableOfferList(
      rec.displayQty,
      rec.discount[0]?.discount,
      rec.moq
    );

    let dis = null;

    if (!offerIndex && offerIndex !== 0) {
      dis = rec.discount[0]?.discount[0];
    } else {
      dis =
        rec.discount[0]?.discount[offerIndex + 1] &&
        rec.discount[0]?.discount[offerIndex + 1]
          ? rec.discount[0]?.discount[offerIndex + 1]
          : { ...rec.discount[0]?.discount[offerIndex], applied: true };
    }

    if (!dis) {
      return null;
    }

    return (
      <div style={{ display: "flex", alignItems: "center" }} className="mt-2">
        <span>
          <img
            src={!dis.applied ? offerPercentage : offerSelected}
            alt="Offer icon"
          />
        </span>
        <Typography
          style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "17px",
            marginTop: "3px",
          }}
          className="ml-1"
        >
          {`Buy ${dis.min_quantity} ${
            rec.unit_of_measure
          } & more at ${getINRString(
            getPercentageDiscountPrice(dis.amount, rec.price)
          )}/${rec.unit_of_measure}
      `}
        </Typography>
      </div>
    );
  };

  const onChangeEdit = (rec, editState) => {
    let id = rec.uniqueId;
    let list = { ...quantityList };
    for (const key in list) {
      if (key === id) {
        list[key].edit = editState;
      }
    }
    setQuantityList({ ...list });
    if (!editState) {
      initiateQuantityList(id);
      dispatch(removeOutOfStockItemFromError(rec.uniqueId));
    }

    clearTimeout(timer);
    var timer = setTimeout(() => {
      if (editState) document.getElementById(id).focus();
    }, 0);
  };

  // console.log(quantityList);
  // qtyInput.current = cartItemList.map(
  //   (element, i) => qtyInput.current[i] ?? createRef()
  // );

  const getEditActions = (rec) => {
    return quantityList[rec?.uniqueId]?.edit ? (
      <>
        <CheckOutlined
          className="ml-3 mr-1"
          style={{ fontSize: 20, color: "#4D9D5E", cursor: "pointer" }}
          onClick={() => updateCart(rec, quantityList[rec?.uniqueId]?.qty)}
        />
        <Divider
          type="vertical"
          style={{ height: 26, backgroundColor: "#E1E7F0" }}
        />
        <CloseOutlined
          className="ml-1"
          style={{ fontSize: 20, color: "#E13440", cursor: "pointer" }}
          onClick={() => onChangeEdit(rec, false)}
        />
      </>
    ) : (
      <EditOutlined
        className="ml-3"
        onClick={() => onChangeEdit(rec, true)}
        style={{ fontSize: 22, color: "#5078F2", cursor: "pointer" }}
      />
    );
  };

  const columns = [
    {
      key: "1",
      title: "Item Details",
      dataIndex: "name",
      render: (_, record) => (
        <>
          <div
            className="d-flex align-items-center justify-space-between"
            style={{ height: 118, gap: 5 }}
          >
            <div className="mr-1">
              <img
                src={record.imageUrl || placeHolder}
                width={64}
                className="rounded"
              />
            </div>
            <div
              className=" ml-2 d-flex flex-column justify-content-between"
              style={{ gap: 5 }}
            >
              <Typography
                className="bold"
                style={{
                  fontWeight: 600,
                  fontSize: 12,
                  lineHeight: 1.6,
                  letterSpacing: 1.1,
                  color: "#3A4960",
                }}
              >
                {record.name} {record.moq > 1 && `(Set of ${record.moq})`}
              </Typography>
              <Typography
                type="secondary"
                style={{ lineHeight: 1.3, opacity: 0.7 }}
              >
                Item ID {record.itemId}
              </Typography>

              <span
                style={{ display: "flex", flexDirection: "row" }}
                className="mt-1"
              >
                {record.flattDiscount ? (
                  <Typography
                    style={{ color: "#E13440" }}
                    className="mr-2 font-weight-bold"
                  >
                    {record.flattDiscount.amount}% OFF
                  </Typography>
                ) : record.discount?.length > 0 ? (
                  getDiscountString(record)
                ) : null}
                {record.conditionType === "ZSEC" ? (
                  <Typography className="economy_type1">Economy</Typography>
                ) : (
                  ""
                  // <Typography className="economy_type">
                  //   REgular
                  // </Typography>
                )}
              </span>
            </div>
          </div>
          {record.outOfStock ? (
            <Typography style={{ color: "#E13440", fontSize: "14px" }}>
              This product is currently out of stock
            </Typography>
          ) : null}
        </>
      ),
    },
    {
      key: "2",
      title: "Unit Price",

      dataIndex: "price",
      align: "right",
      render: (text, record) =>
        (record.flattDiscount || record.discount?.length) &&
        getFloat(record.offerPrice) > 0 ? (
          <div>
            <Typography className="font_roboto" style={{ fontSize: 18 }}>
              {getINRString(record.offerPrice)}
            </Typography>
            <Typography
              className="font_roboto mt-2"
              style={{
                textDecoration: "line-through",
                opacity: 0.7,
              }}
            >
              {getINRString(text)}
            </Typography>
          </div>
        ) : (
          <div>
            <Typography className="font_roboto" style={{ fontSize: 18 }}>
              {getINRString(text)}
            </Typography>
          </div>
        ),
    },
    {
      key: "3",
      title: "Quantity",
      dataIndex: "displayQty",
      align: "center",
      width: 250,
      render: (text, record) => (
        <div className="d-flex align-items-center quantity-update">
          <Input
            style={{
              width: 80,
              fontSize: 18,
              textAlign: "center",
              display: quantityList[record?.uniqueId]?.edit ? "flex" : "none",

              border: outOfStockItem.some((id) => record.uniqueId === id)
                ? "red 1px solid"
                : "auto",
            }}
            className="font_roboto"
            id={record.uniqueId}
            value={quantityList[record?.uniqueId]?.qty}
            type="number"
            pattern="[0-9]*"
            disabled={record.outOfStock}
            onChange={(e) => onChangeQuantity(e, record.uniqueId)}

            // onBlur={(e) => updateCartOnFocusOut(e, record.itemId)}
          />

          <Typography
            className="mock_input font_roboto"
            style={{
              display: quantityList[record?.uniqueId]?.edit ? "none" : "block",
              width: "110px",
            }}
          >
            {quantityList[record?.uniqueId]?.qty}
          </Typography>

          {getEditActions(record)}
          {outOfStockItem.some((id) => record.uniqueId === id) ? (
            <Typography
              style={{ fontSize: 14, color: "#E13440" }}
              className="stock_error"
            >
              Stock qty entered is more than available qty.
            </Typography>
          ) : null}
        </div>
      ),
    },
    {
      key: "4",
      title: "Sub Total",
      dataIndex: "total",
      render: (text) => (
        <Typography className="font_roboto" style={{ fontSize: 18 }}>
          {getINRString(text)}
        </Typography>
      ),
    },
    {
      key: "7",
      title: "",
      dataIndex: "key",
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => selectRecordAndOpenModal(record)}>
            <img src={transhbin} style={{ color: "red", fontSize: "16px" }} />
          </Button>
        </>
      ),
    },
  ];
  const handleYes1 = (record) => {
    deleteCartItem(record);
  };

  const onChangeQuantity = (e, uniqueId) => {
    if (!e.target.validity.valid) {
      return;
    }

    let val = e.target.value;
    if (val > 999999) return;

    let onChangeQuantityChange = { ...quantityList };
    for (const key in quantityList) {
      if (key === uniqueId) {
        onChangeQuantityChange[key].qty = val < 0 ? 1 : val;
      }
    }

    // updateCart(
    //   cartItemList.find((item) => item.itemId === itemId),
    //   val
    // );

    // dispatch(getPaymentDetails(selectedCustomer.goFrugalId));
    // console.log(onChangeQuantityChange);
    setQuantityList({ ...onChangeQuantityChange });
  };

  const updateCart = (product, newQty) => {
    if (newQty < 1) {
      notification.error({ message: "Please enter valid quantity" });
      return;
    }
    const reqData = {
      uom: product.unit_of_measure,
      moq: product.moq.toString(),
      quantity: newQty,
      conditionType: product.conditionType,
      itemId: product.itemId,
      customerId: selectedCustomer.goFrugalId,
    };
    dispatch(updateCartService(reqData));
  };

  const { paymentDetails } = useSelector(
    (state) => state.paymentDetailsReducer
  );
  // console.log(paymentDetails, "Payment details");

  useEffect(() => {
    dispatch(getCartItemList(selectedCustomer.goFrugalId));
    dispatch(getPaymentDetails(selectedCustomer.goFrugalId));
  }, []);

  const onClickCheckout = () => {
    if (
      parseFloat(paymentDetails?.netTotal) <
      parseFloat(paymentDetails?.minOrderAmount)
    ) {
      notification.error({
        message:
          "Total amount must be greater than or equal to " +
          paymentDetails?.minOrderAmount,
      });
      return;
    }
    if (paymentDetails?.orderTime == false) {
      notification.error({
        message: paymentDetails?.orderMessage,
      });
      return;
    }
    if (parseFloat(paymentDetails?.subTotal) <= 0) {
      notification.error({
        message: "Total amount must be greater than zero",
      });
      return;
    }
    if (cartItemList?.length >= 1) {
      let isAnyItemOutOfStock = false;

      cartItemList.forEach((element) => {
        if (element.outOfStock) {
          isAnyItemOutOfStock = true;
        }
      });

      if (isAnyItemOutOfStock) {
        notification.error({
          message: "Some items quanity from cart are greater than stock left",
        });
        return;
      }
    }
    // setIsModalVisible(false);
    history.push("/dashboard/orders/payment");
  };

  // console.log(loading);
  const deleteCartItem = () => {
    const cart_items = [
      {
        itemId: selectedItemToBeDeleted.itemId,
        conditionType: selectedItemToBeDeleted.conditionType,
        uom: selectedItemToBeDeleted.unit_of_measure,
      },
    ];
    dispatch(deleteFromCart(selectedCustomer.goFrugalId, cart_items));
    setIsSingleDeleteModalOpen(false);
    setSelectedItemToBeDeleted(null);
  };
  const clearCartItem = () => {
    dispatch(clearCartItems(selectedCustomer.goFrugalId));
  };

  // console.log(paymentDetails && Object.keys(paymentDetails).length);

  return (
    <div className="orders_new  w-100" style={{ backgroundColor: "#f5f7fa" }}>
      <div className="px-4">
        <PrimaryHeader title="Place Order" backgroundColor="#f5f7fa" />
      </div>
      <OrderSteps
        current={1}
        onClickBack={() => history.push("/dashboard/orders/placeorder")}
      />
      <CustomerDetails />
      <div
        className="m-4 p-3"
        style={{ backgroundColor: "#fff", flexDirection: "row" }}
      >
        <div
          className="my-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "15px",
            }}
          >
            <Link
              to={"/dashboard/orders/placeorder/" + selectedCustomer.goFrugalId}
            >
              <Button
                size="large"
                style={{
                  cursor: "pointer",
                  color: "#27AE60",
                  fontSize: "16px",
                  fontWeight: 400,
                  borderRadius: "4px",
                }}
                className="px-5 buttonContinue"
              >
                Back to item Listing
              </Button>
            </Link>
          </span>
          <span>
            <Button
              size="large"
              className="px-5 buttonClear"
              style={{
                cursor: "pointer",
                color: "#5078F2",
                borderRadius: "6px",
              }}
              onClick={showModal}
            >
              Clear Basket
            </Button>
            <Modal
              title=""
              visible={isModalVisible}
              footer={null}
              closable={false}
            >
              <div style={{ textAlign: "end" }}>
                <Button onClick={closeModal} type="link">
                  <CloseOutlined style={{ color: "#3A4960" }} />
                </Button>
              </div>
              <div style={{ textAlign: "center", padding: "30px" }}>
                <Typography
                  style={{
                    fontSize: "30px",
                    fontWeight: 500,
                    color: "#3A4960",
                  }}
                >
                  Are you sure?
                </Typography>
                <p style={{ color: "#3A4960", fontSize: "16px" }}>
                  This will remove all the items from the cart
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <span>
                    <Button
                      size="large"
                      onClick={handleCancel}
                      style={{
                        width: "109px",
                        height: "42px",
                        padding: "10px",
                      }}
                    >
                      No
                    </Button>
                  </span>
                  <span>
                    <Button
                      onClick={handleYes}
                      size="larger"
                      style={{
                        backgroundColor: "#5078F2",
                        width: "109px",
                        height: "42px",
                        color: "white",
                      }}
                    >
                      Yes
                    </Button>
                  </span>
                </div>
              </div>
            </Modal>
          </span>
        </div>
        <div className="d-flex ml-3 mt-2 p-1">
          <Divider
            type="vertical"
            style={{
              border: "2px solid #737EED",
              height: "22px",
              marginTop: "10px",
            }}
          />
          <div>
            <Typography style={{ marginTop: "10px" }}>Order Basket</Typography>
          </div>
        </div>
        {loading || cartItemList?.length ? (
          <div>
            <div className="my-3 mx-4 position-relative">
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  display:
                    paymentDetails && Object.keys(paymentDetails).length > 0
                      ? "none"
                      : "auto",
                }}
              >
                <Spin size="large" />
              </div>
              <div
                className="d-flex p-4 justify-content-between flex-wrap"
                style={{
                  flexDirection: "row",
                  border: "1px solid #E5E5E5",
                  opacity:
                    paymentDetails && Object.keys(paymentDetails).length > 0
                      ? 1
                      : 0.2,
                }}
              >
                <div
                  className="d-flex flex-column px-2 pr-5"
                  style={{ flex: 1.3 }}
                >
                  <div>
                    <Typography className="price_det_color">
                      Price Details
                    </Typography>
                  </div>
                  <div className="ml-4 mt-1">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <Typography className="mt-2 price_det_color">
                        Basket Total
                      </Typography>
                      <Typography className="mt-2 font_roboto price_det_color">
                        {getINRString(paymentDetails?.subTotal)}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <Typography className="mt-2 price_det_color">
                        Shipping
                      </Typography>
                      <Typography
                        className="mt-2 price_det_color font_roboto"
                        style={{ marginLeft: "43px" }}
                      >
                        {getINRString(paymentDetails?.deliveryCharge)}
                      </Typography>
                    </div>
                    <div>
                      <Divider type="horizontal" className="my-1" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <Title className="mt-2 font-weight-normal " level={3}>
                        Total Amount
                      </Title>
                      <Title
                        className="mt-2 font-weight-normal font_roboto"
                        level={3}
                      >
                        {getINRString(paymentDetails?.netTotal)}
                      </Title>
                    </div>
                  </div>
                </div>

                <Divider
                  type="verticle"
                  style={{ height: 170 }}
                  className="divider-y"
                />

                <div
                  style={{ display: "flex", flex: 1.1 }}
                  className="px-4 align-items-center justify-content-center "
                >
                  <img
                    src={kitty1}
                    alt="piggy_bank"
                    style={{ width: 150, height: 150 }}
                  />

                  <span className="m-4">
                    <Typography className="mt-2 price_det_color">
                      You Saved!
                    </Typography>
                    <Title
                      className="mt-2 font-weight-normal font_roboto"
                      level={4}
                    >
                      {getINRString(paymentDetails?.savedTotal)}
                    </Title>
                  </span>
                </div>
                <Divider
                  type="verticle"
                  style={{ height: 170 }}
                  className="divider-y"
                />
                <div
                  className="d-flex flex-1 px-4 align-items-center justify-content-center"
                  style={{ flex: 0.7 }}
                >
                  <Button
                    className="checkout_button"
                    style={{
                      backgroundColor: "#5078F2",
                      width: "159px",
                      height: "54px",
                      color: "white",
                      fontSize: 16,
                    }}
                    onClick={onClickCheckout}
                  >
                    Check Out
                    <ArrowRightOutlined />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="mx-4" style={{ minHeight: 360 }}>
          {loading || cartItemList?.length ? (
            <div className="">
              <Table
                dataSource={cartItemList}
                columns={columns}
                pagination={{
                  pageSize: 5,
                  position: ["bottomCenter"],
                  hideOnSinglePage: true,
                }}
                loading={loading}
                // rowClassName="out_of_stock_row"
                rowClassName={(record, index) =>
                  record.outOfStock ? "out_of_stock_row" : ""
                }
              />
            </div>
          ) : null}
          {!loading && !cartItemList?.length ? (
            <div style={{ textAlign: "center" }} className="mt-5 mb-5">
              <div className="m-5">
                <img
                  src={emptyCart}
                  className="mt-5"
                  style={{ height: "22em", weight: "22em" }}
                />
              </div>
              <div>
                <Typography
                  style={{
                    fontSize: 24,
                    fontWeight: "600",
                    letterSpacing: "1.3px",
                  }}
                >
                  Basket is empty!
                </Typography>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        title=""
        visible={isSingleDeleteModalOpen}
        footer={null}
        closable={false}
      >
        <div style={{ textAlign: "end" }}>
          <Button onClick={() => setIsSingleDeleteModalOpen(false)} type="link">
            <CloseOutlined style={{ color: "#3A4960" }} />
          </Button>
        </div>
        <div style={{ textAlign: "center", padding: "30px" }}>
          <Typography
            style={{
              fontSize: "30px",
              fontWeight: 500,
              color: "#3A4960",
            }}
          >
            Are you sure?
          </Typography>
          <p style={{ color: "#3A4960", fontSize: "16px" }}>
            This will remove the item from <br /> the cart
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <span>
              <Button
                size="large"
                onClick={() => setIsSingleDeleteModalOpen(false)}
                style={{
                  width: "109px",
                  height: "42px",
                  padding: "10px",
                }}
              >
                No
              </Button>
            </span>
            <span>
              <Button
                onClick={() => deleteCartItem()}
                size="larger"
                style={{
                  backgroundColor: "#5078F2",
                  width: "109px",
                  height: "42px",
                  color: "white",
                }}
              >
                Yes
              </Button>
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default OrdersList;
