import {
  APPROVED_DATA,
  PENDING_DATA,
  REJECTED_DATA,
  TARGET_SETTING_DETAILS,
} from "../action/targetSetting";

const initialState = {
  approvedData: { loadData: true, page: 1 },
  pendingData: { loadData: true, page: 1 },
  rejectedData: { loadData: true, page: 1 },
};

export const targetSettingData = (state = initialState, action) => {
  switch (action.type) {
    case APPROVED_DATA:
      return {
        ...state,
        approvedData: {
          ...state.approvedData,
          ...action.payload,
          loadData: action.loadData,
        },
      };
    case PENDING_DATA:
      return {
        ...state,
        // type: "Pending",
        pendingData: { ...action.payload, loadData: action.loadData },
      };
    case REJECTED_DATA:
      return {
        ...state,
        // type: "Rejected",
        rejectedData: { ...action.payload, loadData: action.loadData },
      };
  }
  return state;
};

export const targetSettingDetails = (state = null, action) => {
  if (action.type === TARGET_SETTING_DETAILS) {
    return action.payload;
  } else {
    return state;
  }
};
