//profile
export const profile = "/dashboard/profile";
//customers
export const customers = "/dashboard/customers";
export const customerDetails = "/dashboard/customers/:id";
export const customerOrderDetails = "/dashboard/team/reports/order-details";
export const myCustomers = "/dashboard/myCustomers";

//team
//team => ASMs
export const ASM = "/dashboard/team/asms";
export const createASM = "/dashboard/team/asms/new";

//team => salesman
export const salesmen = "/dashboard/team/salesmen";
export const createSalesman = "/dashboard/team/salesmen/new";

//team => reports
export const reports = "/dashboard/team/reports";
export const customerCollectionDetails =
  "/dashboard/team/reports/customer-details/:id";
export const downloadReports = "/dashboard/team/reports/download";

//beat plan
//beat plan => day's plan
export const daysPlan = "/dashboard/beatplan/published";
export const daysPlanWithId = "/dashboard/beatplan/published/:id";

//beat plan => view beat plan
export const viewBeatPlan = "/dashboard/beatplan/drafts";
export const viewBeatPlanDetails = "/dashboard/beatplan/drafts/:id";

//beat plan => create beat plan
export const createNew = "/dashboard/beatplan/create";

//gofrugal Sync
export const gofrugalSync = "/dashboard/gofrugal-sync";

//sap Sync
export const sapSync = "/dashboard/sap-sync";

//target Setting
export const targetSettings = "/dashboard/target-setting/:name";
export const targetSettingDetails = "/dashboard/target-setting/:name/:id";

// Sap Report
export const sapReport = "/dashboard/invoice-report";
// PO Generation
export const poGeneration = "/dashboard/po-generation";
// create new PO
export const createPO = "/dashboard/po-generation/create";

// po details

export const poDetails = "/dashboard/po-generation/details/:id";

//cod
export const cod = "/dashboard/cod-list";
export const creditExtension = "/dashboard/cod-list/:id";

//settings
export const settings = "/dashboard/settings";

// Products
export const stockAndMoq = "/dashboard/products/stock-and-moq";
export const pricing = "/dashboard/products/pricing";

// orders
export const orders = "/dashboard/orders";
export const salesDashboard = "/dashboard/sales-order";
export const placeorders = "/dashboard/orders/placeorder/:id";
export const OrdersCustomersList = "/dashboard/orders/placeorder";

export const viewInvoice =
  "/dashboard/orders/viewinvoice/:soId/:InvoiceId/:customerID/:Otp";

export const podOtp = "/dashboard/orders/viewinvoice/";
export const orderAndBasicDetails =
  "/dashboard/orders/order-details/:customerID/:soId/:invoiceNo";

export const orderAndBasicDetails1 =
  "/dashboard/orders/order-details/:customerID/:soId";
export const productLists = "/dashboard/orders/checkout";
// export const checkout = "/dashboard/orders/checkout1";
export const payment = "/dashboard/orders/payment";

//inventory management

export const inventoryManagementClosingStock =
  "/dashboard/inventory-management/closing-stock";
export const inventoryManagementStockAudit =
  "/dashboard/inventory-management/stock-audit";
export const inventoryManagementCurrentStock =
  "/dashboard/inventory-management/current-stock";
export const inventoryManagementStockMovement =
  "/dashboard/inventory-management/stock-movement";
export const inventoryManagementReport =
  "/dashboard/inventory-management/report";

//sales order
export const salesOrderAll = "/dashboard/sales-order/sales-order-all";
export const salesOrderDetail =
  "/dashboard/sales-order/sales-order-all-so-detail/:id";
export const salesOrderReturn = "/dashboard/sales-order/sales-order-return";
export const salesOrderReturnDetail =
  "/dashboard/sales-order/sales-order-return-so-detail";
export const invoicePrint = "/dashboard/sales-order/sales-order-all-print/:id";

export const orderOtp = "/dashboard/orders/payment-otp";
export const salesOrderReturnView =
  "/dashboard/sales-order/sales-order-return-view-only/:id";
export const asmlogout = "/dashboard/asmlogout";
//pricingUpload
export const pricingUpload = "/dashboard/pricingUpload/pricingUpload";
export const temMappingCluster = "/dashboard/teammapping/heads/Cluster";

export const temMappingArea = "/dashboard/teammapping/heads/Area";

export const temMappingHub = "/dashboard/teammapping/heads/Hub";
