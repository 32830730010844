import React, { Fragment, useEffect, useState } from "react";
import ExceptionalCustomers from "./ExceptionalCustomers";
import MinimumOrderValue from "./MinimumOrderValue";
import ShippingCharges from "./ShippingCharges";
import { getMinOrderValueMangShipping } from "../../../services/settings/manageShippingservice";
import {
  getListOfExceptionalCustomers,
  getListOfExceptionalCustomeTaleData,
  getMinimumOrdValue,
  getShippingCharges,
} from "../../../action/manageShippingaction";
import { useDispatch, useSelector } from "react-redux";

const Index = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMinimumOrdValue());
    dispatch(getShippingCharges());
    dispatch(getListOfExceptionalCustomers());
    dispatch(getListOfExceptionalCustomeTaleData());
  }, []);

  return (
    <Fragment>
      <MinimumOrderValue />
      <div className="mt-4" />
      <ShippingCharges />
      <div className="mt-4" />
      <ExceptionalCustomers />
    </Fragment>
  );
};

export default Index;
