import React, { Component } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import "./team.scss";
import "../dropdown/dropdown.scss";
import { createAsm } from "../../services/teamServices/team";
import { Spin } from "antd";
import message from "antd/lib/message";
import { handleErrorResponse } from "../../utils/Request";
import { Modal, Upload, Button } from "antd";
import { checkErr } from "../../utils/common";
import {
  LoadingOutlined,
  PlusOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { uploadFile } from "../../services/fileHandler";
import {
  getAsmListForDropdown,
  getAsmListForDropdownHRRole,
} from "../../services/teamServices/team";
import "../profile/profile.scss";
import Select from "react-select";
import history from "../../utils/History";

const options = [
  { value: "378158000000110025", label: "Vignesh Kumar Manogaran" },
];

// get bas64
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

// before uploading
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

class CompanyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      empId: "",
      fname: "",
      lname: "",
      reportingManager: "",
      email: "",
      phone: "",
      reporting: "",
      loading: false,
      page: 1,
      asmDropdownList: [],
      reportingTo: "Select",
      selectedReportingManagerId: "",
      showDropdown: false,
      personDetails: {},
      changedField: [],
      loading: false,
      imageLoading: false,
      details: {},
      email: "",
      phone: "",
      photo: "",
      salesmanId: "",
      isWaycoolEmp: false,
      gender: "",
      reportingId: "",
      removeProfilePic: "",
      picId: "",
      value: "",
      empIdErr: false,
    };
  }

  onSubmit = async () => {
    this.setState({ loading: true });

    let {
      isWaycoolEmp,
      fname,
      lname,
      empId,
      reportingManager,
      email,
      picId,
      phone,
      details,
      selectedReportingManagerId,
      gender,
      reportingId,
      reporting,
    } = this.state;
    let { selectedAgencyId, type, selectDistributor, selectedCity } =
      this.props;
    let inputs;
    if (type === "asm") {
      inputs = {
        empId: empId,
        isWaycoolEmp: isWaycoolEmp,
        agencyId: selectedAgencyId,
        firstName: fname,
        lastName: lname,
        // isWaycoolEmp:details.isWaycoolEmp,
        gender: gender,
        reportingManagerId: reporting,
        email: email,
        contactMobile: phone,
        profilePic: picId,
        distributorId: selectDistributor,
        cityId: selectedCity,
      };
    }

    if (type === "salesman") {
      inputs = {
        empId: empId,
        isWaycoolEmp: isWaycoolEmp,
        agencyId: selectedAgencyId,
        firstName: fname,
        lastName: lname,
        // isWaycoolEmp:details.isWaycoolEmp,
        gender: gender,
        reportingManagerId: selectedReportingManagerId,
        email: email,
        contactMobile: phone,
        profilePic: picId,
        distributorId: selectDistributor,
        cityId: selectedCity,
      };
    }

    await createAsm(inputs, type)
      .then((res) => {
        this.setState({ loading: false });
        if (type == "asm") {
          message.success(" Asm created successfully!!");
          window.location = `/dashboard/team/asms`;
          setTimeout(() => {
            history.push({
              pathname: `/dashboard/team/asms`,
            });
          }, 1000);
        } else {
          message.success(" Salesman created successfully!!");
          window.location = `/dashboard/team/salesmen`;
          history.push({
            pathname: `/dashboard/team/salesmen`,
          });
        }
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        // message.error(errorMessage);
        this.setState({ loading: false });
        console.log("message", err.response.data.error);
        // let _err = err.response.data.error;
        // let errMsg = checkErr(_err);

        message.error(err.response.data.message);
      });
  };

  // dropdown functions starts
  menuClick = (e) => {
    if (e != null) {
      let value = e[0] ? e[0].label : e.label;
      let id = e[0] ? e[0].value : e.value;
      this.setState({
        reportingTo: value,
        selectedReportingManagerId: id,
        value: e,
      });
    }
  };

  showDropDown = () => {
    this.setState({
      showDropdown: !this.state.showDropdown,
    });
  };

  // Need to remove
  getDropdownOptions = () => {
    let { page } = this.state;
    let { selectDistributor } = this.props;

    getAsmListForDropdownHRRole(page, "", selectDistributor)
      .then((res) => {
        this.setState({
          loading: false,
          asmDropdownList: res.data.data.results,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  loadOptions = async (search, loadedOptions, { page }) => {
    let { selectDistributor } = this.props;
    let data = await getAsmListForDropdownHRRole(
      page,
      search,
      selectDistributor
    ).then((res) => {
      return {
        options:
          res.data.data.results &&
          res.data.data.results.map((item) => {
            return {
              key: item.employeeId,
              value: item._id,
              label: `${item.firstName} ${item.lastName}`,
            };
          }),
        totalPage:
          parseInt(res.data.data.pageMeta.total || 0) /
          parseInt(res.data.data.pageMeta.pageSize || 0),
      };
    });

    return {
      options: data.options,
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  //dropdown functions end

  onchangeHandler = (event) => {
    if (event.target.id === "empId") {
      this.setState({
        empId: event.target.value,
      });
    }
    if (event.target.id === "fname") {
      this.setState({
        fname: event.target.value,
      });
    }
    if (event.target.id === "lname") {
      this.setState({
        lname: event.target.value,
      });
    }
    if (event.target.id === "reporting") {
      this.setState({
        reportingManager: event.target.value,
      });
    }
    if (event.target.id === "phone") {
      this.setState({
        phone: event.target.value,
      });
    }
    if (event.target.id === "email") {
      this.setState({
        email: event.target.value,
      });
    }
    if (event.target.id === "reporting") {
      this.setState({
        reporting: event.target.value,
      });
    }
  };
  onGenderChange = (e) => {
    this.setState({
      gender: e.target.value,
    });
  };

  reportingIdChange = (e) => {
    if (e != null) {
      this.setState({
        reportingId: e.label,
      });
    }
  };

  componentDidMount = () => {
    let { type } = this.props;
    {
      type == "salesman" && this.getDropdownOptions();
    }
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.selectDistributor !== this.props.selectDistributor) {
      let { type } = this.props;
      if (type === "salesman") {
        this.setState({
          reportingTo: "select",
          selectedReportingManagerId: "",
          value: "",
        });
        message.error(
          "Kindly re-select the Reporting Manager name as you have changed the Distributor",
          6
        );
      }
    }
  };
  // handle change of image uploader
  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  // uploading file to server
  uploadToServer = async ({ onSuccess, onError, file }) => {
    let res = await uploadFile(file)
      .then((res) => {
        this.setState({
          isProfilePicUpdate: true,
          picId: res.data.data.imageId,
          removeProfilePic: false,
        });
        onSuccess(null, file);
      })
      .catch((err) => {
        onError(err);
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };

  render() {
    let {
      value,
      lname,
      fname,
      empId,
      isWaycoolEmp,
      reportingManager,
      email,
      phone,
      loading,
      asmDropdownList,
      reportingTo,
      showDropdown,
      personDetails,
      removeProfilePic,
      imageLoading,
      imageUrl,
      details,
      gender,
      reporting,
      reportingId,
    } = this.state;
    let { type, selectedAgencyId, data } = this.props;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Spin indicator={antIcon} spinning={loading}>
        <div className="mt-5">
          {/* <div className="scroll-div"> */}
          <div className="employer-container">
            <div className="employer-container-left">
              {type == "asm" ? "ASM Details" : "Salesman Details"}
            </div>

            <div className="employer-container-right">
              <form className="form-horizontal">
                <div className="form-group mt-1">
                  <Upload
                    id="profilePicture"
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={this.uploadToServer}
                    beforeUpload={beforeUpload}
                    onChange={this.handleChange}
                  >
                    {!removeProfilePic ? (
                      imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </div>

                <div class="form-group row">
                  <label class="control-label text-muted col-sm-2" for="empId">
                    EmpId:
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="input-text"
                      value={empId}
                      id="empId"
                      placeholder="Enter Employee Id "
                      onChange={this.onchangeHandler}
                      minlength="1"
                      maxlength="30"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label text-muted col-sm-2" for="fname">
                    First Name:
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="input-text"
                      value={fname}
                      id="fname"
                      placeholder="Enter First Name"
                      onChange={this.onchangeHandler}
                      minLength="1"
                      maxlength="20"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label text-muted col-sm-2" for="lname">
                    {" "}
                    Last Name:
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="input-text"
                      minLength="1"
                      maxlength="20"
                      value={lname}
                      id="lname"
                      placeholder="Enter Last Name"
                      onChange={this.onchangeHandler}
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label text-muted col-sm-2" for="gender">
                    {" "}
                    Gender:
                  </label>
                  <div className="col-sm-10">
                    <div
                      className="asm-gender"
                      //  class="custom-control custom-radio custom-control-inline ml-3"
                    >
                      <div>
                        <input
                          type="radio"
                          // class="custom-control-input "
                          required
                          // id="defaultInline1"
                          name="Male"
                          value="Male"
                          checked={gender === "Male"}
                          onChange={this.onGenderChange}
                        />
                        <label
                          // class="custom-control-label custom-gender-label"
                          for="defaultInline1"
                        >
                          Male
                        </label>
                      </div>
                      <div
                      //  class="custom-control custom-radio custom-control-inline ml-3"
                      >
                        <input
                          type="radio"
                          required
                          // class="custom-control-input"
                          id="defaultInline2"
                          value="Female"
                          name="Female"
                          checked={gender === "Female"}
                          onChange={this.onGenderChange}
                        />
                        <label
                          // class="custom-control-label custom-gender-label"
                          for="defaultInline2"
                        >
                          Female
                        </label>
                      </div>
                      <div
                      // class="custom-control custom-radio custom-control-inline ml-3"
                      >
                        <input
                          type="radio"
                          required
                          // class="custom-control-input"
                          // id="defaultInline3"
                          value="Transgender"
                          name="Transgender"
                          checked={gender === "Transgender"}
                          onChange={this.onGenderChange}
                        />
                        <label
                          // class="custom-control-label custom-gender-label"
                          for="defaultInline3"
                        >
                          Transgender
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    class="control-label text-muted col-sm-2"
                    for="phone no"
                  >
                    Phone Number:
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="tel"
                      class="input-text"
                      pattern="\+?\d[\d -]{8,12}\d"
                      placeholder="Enter Phone No"
                      name="phone"
                      id="phone"
                      onChange={this.onchangeHandler}
                      value={phone}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    class="control-label text-muted col-sm-2"
                    for="phone no"
                  >
                    Reporting To:
                  </label>
                  <div class="col-sm-10">
                    <div className="special-dropdown-form">
                      {type == "asm" && (
                        <input
                          type="text"
                          class="input-text"
                          placeholder="Enter Report Manager"
                          name="reporting"
                          id="reporting"
                          onChange={this.onchangeHandler}
                          value={reporting}
                        />
                      )}

                      {type == "salesman" && (
                        <AsyncPaginate
                          key={this.props.selectDistributor}
                          className="repotingId-drop-down"
                          value={value}
                          loadOptions={this.loadOptions}
                          onChange={(e) => {
                            this.menuClick(e);
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    class="control-label text-muted col-sm-2"
                    for="phone no"
                  >
                    Email id:
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="email"
                      class="input-text"
                      placeholder="Enter Email"
                      name="email"
                      id="email"
                      onChange={this.onchangeHandler}
                      value={email}
                    />
                  </div>
                </div>
                <div></div>
              </form>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={this.onSubmit}
                disabled={
                  !(lname && fname && empId && email && phone && gender)
                }
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Spin>
    );
  }
}
export default CompanyDetails;
