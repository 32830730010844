import React, { Component } from "react";
import cross from "../../images/cross.svg";
import { Spin, Modal } from "antd";
import "./sapReport.scss";
class SapItemModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      page: 1,
      search: "",
      options: [],
      selectedCustomers: [],
      value: [],
      visible: true,
    };
  }
  componentDidMount = () => {};

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    let { tableHeaders } = this.props;

    let { visible } = this.state;
    return (
      <Modal
        visible={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
     
        className="modalWidth"
      >
        <>
          <div className="overlay"></div>
          <div className="sap-modal-container">
            <div className="row mt-2 ml-4 line">
              <div className="col-lg-10 col-md-8 item-text">Item Details</div>
              {/* <div className="col-lg-1 col-md-2 ml-3">
                        <img
                            src={cross}
                            onClick={this.displayModal}

                        />
                    </div> */}
              <div className="table-wrapper">
                <table className="table common-table">
                  <thead>
                    <tr>
                      {tableHeaders.map((trow, index) => {
                        return <th key={`tr-${index}`}>{trow}</th>;
                      })}
                    </tr>
                  </thead>
                  {
                    //  tBody.length != 0 &&
                    <tbody>
                      <tr>
                        {tableHeaders.map((x) => {
                          return (
                            <td>
                              {x == "Material No." && "WC0000000000120007"}
                              {x == "Material Description" &&
                                "RIDGE GOURD - KG"}
                              {x == "Item No." && "000020"}
                              {x == "Plant" && 1000}
                              {x == "Order Quantity" && 10}
                              {x == "Net Price" && "₹320"}
                              {x == "Storage Location" && 103}
                              {x == "Payment Terms" && "WC03"}
                              {x == "UOM" && "KG"}
                              {x == "MPR Amount" && "NA"}
                              {x == "Discount Amount" && "NA"}
                              {x == "Taxable Value" && "NA"}
                              {x == "CGST" && "NA"}
                              {x == "SGST" && "NA"}
                              {x == "IGST" && "NA"}
                              {x == "UGST" && "NA"}
                              {x == "Total Amount" && "₹320"}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  }
                  {/* { tBody.length == 0 && <div>no data</div>} */}
                </table>
              </div>
            </div>
          </div>
        </>
      </Modal>
    );
  }
}

export default SapItemModal;
