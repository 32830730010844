import React, { useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import "./stockmovement.scss";
import PrimaryHeader from "../../header/PrimaryHeader";
import { handleErrorResponse } from "../../../utils/Request";
import message from "antd/lib/message";
import {
    getMaterialCode,
    getStockBatchId,
    getStockStorageLocation,
} from "../../../services/inventoryServices/inventoryServices";
import { upperFirst } from "lodash";

function StockMovement(props) {
    // console.log(props, "stockAuditProps");
    const [materialCode, setMaterialCode] = useState("");
    const [storageLocation, setStorageLocation] = useState("");
    const [batchId, setBatchId] = useState("");

    const searchMethod = () => {
        props.history.push({
            pathname: "/dashboard/inventory-management/results",
            state: {
                materialCode: materialCode,
                storageLocation: storageLocation,
                batchId: batchId,
            },
        });
    };

    const handleChange = (e, type) => {
        if (type == "materialCode") {
            setMaterialCode(e);
        }
        if (type == "storageLocation") {
            setStorageLocation(e);
        }
        if (type == "batchId") {
            setBatchId(e);
        }
    };

    const loadStorageLocation = async (search, loadedOptions, { page }) => {
        let qParams = [{ page: page }, { pageSize: 15 }, { location: search }];
        let data = await getStockStorageLocation(qParams).then((res) => {
            console.log(res, "ress");
            return {
                options:
                    res.data.data &&
                    res.data.data.map((item) => {
                        return {
                            key: item._id,
                            value: item.location,
                            label: upperFirst(item.location),
                        };
                    }),
                totalPage:
                    parseInt(15 * res.data.data.length || 0) /
                    parseInt(15 || 0),
            };
        });
        return {
            options: data ? data.options : [],
            hasMore: data.totalPage > page ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };

    const loadBatchId = async (search, loadedOptions, { page }) => {
        let qParams = [{ page: page }, { limit: 15 }, { batchId: search }];
        let data = await getStockBatchId(qParams).then((res) => {
            console.log(res, "res");
            return {
                options:
                    res.data.data &&
                    res.data.data.map((item) => {
                        return {
                            key: item._id,
                            value: item.BatchID,
                            label: upperFirst(item.BatchID),
                        };
                    }),
                totalPage:
                    parseInt(15 * res.data.data.length || 0) /
                    parseInt(15 || 0),
            };
        });
        return {
            options: data ? data.options : [],
            hasMore: data.totalPage > page ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };

    const loadMaterialcode = async (search, loadedOptions, { page }) => {
        let qParams = [{ page: page }, { pageSize: 5 }, { material: search }];
        let data = await getMaterialCode(qParams).then((res) => {
            console.log(res, "resul");
            return {
                options:
                    res.data.data &&
                    res.data.data.map((item) => {
                        return {
                            key: item._id,
                            value: item.material,
                            label: upperFirst(item.material),
                        };
                    }),
                totalPage:
                    parseInt(15 * res.data.data.length || 0) /
                    parseInt(15 || 0),
            };
        });
        return {
            options: data ? data.options : [],
            hasMore: data.totalPage > page ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };

    return (
        <div id="content">
            <PrimaryHeader title={"Stock Movement"} />
            <div className="stock-card">
                <div className="card">
                    <div className="card-body">
                        <div className="stock-head">
                            <label htmlFor="" className="border_left">
                                {"Search Stock"}
                            </label>
                        </div>
                        <div className="stock-data">
                            <div className="mt-5">
                                <div className="stock-labels">
                                    <label htmlFor="">
                                        {"Material Code/Name"}
                                    </label>
                                </div>
                                <div className="stock-inputs">
                                    <AsyncPaginate
                                        className="basic-multi-select"
                                        additional={{
                                            page: 1,
                                        }}
                                        loadOptions={loadMaterialcode}
                                        isClearable={true}
                                        isSearchable={true}
                                        closeMenuOnSelect={true}
                                        placeholder={
                                            "Choose Material Code/Name"
                                        }
                                        // value={strgLocation}
                                        onChange={(e) =>
                                            handleChange(e, "materialCode")
                                        }
                                        loadingMessage={() =>
                                            "No code/name Found"
                                        }
                                        // autoSize={true}
                                        // menuPlacement="auto"
                                        // menuPosition="fixed"
                                    />
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="stock-labels">
                                    <label htmlFor="">
                                        {"Storage Location"}
                                    </label>
                                </div>
                                <div className="stock-inputs">
                                    <AsyncPaginate
                                        className="basic-multi-select"
                                        additional={{
                                            page: 1,
                                        }}
                                        loadOptions={loadStorageLocation}
                                        isClearable={true}
                                        isSearchable={true}
                                        closeMenuOnSelect={true}
                                        placeholder={"Choose Storage Location"}
                                        // value={strgLocation}
                                        onChange={(e) =>
                                            handleChange(e, "storageLocation")
                                        }
                                        loadingMessage={() =>
                                            "No location Found"
                                        }
                                        // autoSize={true}
                                        // menuPlacement="auto"
                                        // menuPosition="fixed"
                                    />
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="stock-labels">
                                    <label htmlFor="">{"Batch ID"}</label>
                                </div>
                                <div className="stock-inputs">
                                    <AsyncPaginate
                                        className="basic-multi-select"
                                        additional={{
                                            page: 1,
                                        }}
                                        loadOptions={loadBatchId}
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={"Choose Batch ID"}
                                        closeMenuOnSelect={true}
                                        // value={strgLocation}
                                        onChange={(e) =>
                                            handleChange(e, "batchId")
                                        }
                                        loadingMessage={() => "No ID's Found"}
                                        // autoSize={true}
                                        // menuPlacement="auto"
                                        // menuPosition="fixed"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="mt-4 mb-4"
                            style={{ marginLeft: "110px" }}
                        >
                            <button
                                className="btn btn-primary"
                                onClick={searchMethod}
                                disabled={
                                    materialCode == "" ||
                                    storageLocation == "" ||
                                    batchId == ""
                                }
                            >
                                {"Search"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StockMovement;
