import React, { useState, useEffect } from "react";
import "./customerMap.scss";
import {
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    Marker,
    InfoWindow,
} from "react-google-maps";


function SalesmanMap({ mapCenter, name, title, }) {
    console.log("mapCenter==============>>", mapCenter);
    const [selectedCustomer, setSelectedCustomer] = useState("");


    useEffect(() => {
     
    })

      
    return (
        <div>

            <GoogleMap
                defaultZoom={19}
                defaultCenter={{ lat: 12.951119, lng: 77.7048785 }}
              >

            

                {
                  mapCenter && 
                  mapCenter.map((map)=>{                     
                      return map.visitLog && map.visitLog.map((visit)=>{                        
                          return(
                              <Marker
                                  position={{
                                      lat: visit.checkIn.location
                                          .coordinates[1],
                                      lng: visit.checkIn.location
                                          .coordinates[0],
                                  }}
                              onClick={()=>{
                                  console.log(map)
                                  setSelectedCustomer(map)
                              }}
                              >
                                  {/* {selectedCustomer &&
                                  
                                      <InfoWindow
                                       onCloseClick={()=>{
                                           setSelectedCustomer(null)
                                       }}
                                      >
                                          <div>{selectedCustomer && selectedCustomer.customerName} </div>
                                      </InfoWindow>
                                  } */}
                              </Marker>    

                       
                              
                              
                           
                          )
                           
                      })
                  })
              }

           

               


            </GoogleMap>

        </div>

    )
}
const WrappedSalesmanMap = withScriptjs(withGoogleMap(SalesmanMap));
export default WrappedSalesmanMap;
