import { Typography, Input, Button, Divider } from "antd";
import React, { useState, useEffect } from "react";
import PrimaryHeader from "../header/PrimaryHeader";
import ClusterHead from "../../images/salesemployee.svg";
import profilephoto from "../../images/default-pic.jpg";
import "./teammapping.scss";

import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import {
  viewEmployeeDetails,
  viewEmptysearchfields,
} from "../../action/teamMappingAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
function Viewemployee() {
  const params = useParams();
  console.log("kkkk");
  const id = params.id;
  useEffect(() => {
    dispatch(viewEmployeeDetails(id));
  }, []);
  const history = useHistory();
  const { state: path } = useLocation();
  console.log(path);
  console.log(id, "view");
  const viweemployeedetails = useSelector(
    (state) => state.teamMappingReducer.viweemploye
  );
  console.log(viweemployeedetails?.photo, "viweemployeedetails");
  const [employerId, setEmployeId] = useState("");
  const [employeeImage, setEmployeeImage] = useState("");
  const [fullname, setFullName] = useState("");
  const [manger, setManger] = useState("");
  const [mail, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [employerType, setEmployeType] = useState(
    "Waycool Foods and Products Pvt. Ltd."
  );
  const navigateToBack = (path) => {
    history.push("/dashboard/teammapping/heads/" + path);
  };

  const [employerNumber, setEmployeNumber] = useState("");
  useEffect(() => {
    console.log(viweemployeedetails?.photo, "photo");
    setFullName(viweemployeedetails?.fullName);
    setManger(viweemployeedetails?.reportingTo);
    setEmail(viweemployeedetails?.email);
    setPhone(viweemployeedetails?.contactMobile);
    setEmployeeImage(viweemployeedetails?.photo);
    setEmployeId(viweemployeedetails?.employeeId);
    setEmployeNumber(viweemployeedetails?.employerName);
  }, [viweemployeedetails]);
  useEffect(() => {
    return () => dispatch(viewEmptysearchfields());
  }, []);
  const dispatch = useDispatch();

  return (
    <div className="mt-3 w-100 px-4">
      <div className="" style={{ display: "flex", marginTop: "6px" }}>
        <Button type="link" onClick={() => navigateToBack(path)}>
          <ArrowLeftOutlined
            style={{ color: "black", fontSize: "20px", marginTop: "15px" }}
          />
        </Button>
        <div className="llllll" style={{ width: "100%", zIndex: 4000 }}>
          <PrimaryHeader
            // title="Order ID"
            title="View Employee"
            marginTop="-6px"
          />
        </div>
      </div>

      <div style={{ display: "flex", gap: "100px" }}>
        <span>
          <Typography>Employer</Typography>
        </span>
        <span>
          <span>
            <Typography>SELECT EMPLOYER</Typography>
            {employerNumber ? (
              <Input
                value={employerNumber}
                className="employe_input"
                readOnly
              />
            ) : (
              <Button className="other-employee">Others</Button>
            )}
          </span>
          <span>
            <Typography style={{ marginTop: "20px" }}>
              ENTER EMPLOYEE ID
            </Typography>
            <Input
              className="employee_inputfields"
              value={employerId}
              readOnly
            />
          </span>
        </span>
      </div>
      <div style={{ display: "flex", gap: "58px", marginTop: "23px" }}>
        <span>
          <Typography>Salesman Details</Typography>
        </span>
        <span>
          <span>
            <Typography>PROFILE PHOTO</Typography>
            {employeeImage !== "NA" && employeeImage !== "" ? (
              <img src={employeeImage} alt="" style={{ width: "130px" }} />
            ) : (
              <img src={profilephoto} alt="" style={{ width: "130px" }} />
            )}

            <Typography className="employee" style={{ marginTop: "23px" }}>
              FULL NAME
            </Typography>
            <Input
              value={fullname}
              style={{ border: "none", marginLeft: "-11px" }}
              className="emplee_inputtext"
            />
            <Typography className="employee" style={{ marginTop: "15px" }}>
              REPORTING MANAGER
            </Typography>
            <Input
              value={manger}
              style={{ border: "none", marginLeft: "-11px" }}
              className="emplee_inputtext"
            />
          </span>
          <span>
            <Typography className="employee" style={{ marginTop: "15px" }}>
              Email id
            </Typography>
            <Input value={mail} className="employee_inputfields" readOnly />
          </span>
          <span>
            <Typography className="employee" style={{ marginTop: "15px" }}>
              Phone Number
            </Typography>

            <Input value={phone} className="employee_inputfields" readOnly />
          </span>
        </span>
      </div>
    </div>
  );
}

export default Viewemployee;
