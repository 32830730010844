import React, { useState, useEffect } from "react";
import "./customerMap.scss";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyAscjPuHsmxseZEeitNZp3SrZ8kl7Z1EX0");
Geocode.enableDebug();

function CustomerMap({ mapCenter, name, title }) {
  console.log("mapCenter==============>>", mapCenter);
  const [address, setAddress] = useState("");
  

  useEffect(() => {
    Geocode.fromLatLng(mapCenter[1], mapCenter[0])
      .then((res) => {
        setAddress(res.results[0].formatted_address)
       
      })
  })

 
  let content = mapCenter != undefined ?
    <div>

      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: mapCenter[1], lng: mapCenter[0] }}
      >
        <Marker
          position={{
            lat: mapCenter[1],
            lng: mapCenter[0],
          }}
          
        >
          <InfoWindow   >       
       
            <div>
              <span className="latlng">Lat: {mapCenter[1]} , Lng: {mapCenter[0]}</span><br />
              <span className="address-text" >{address}</span>
            </div>
          </InfoWindow>


        </Marker> 


      </GoogleMap>

    </div>
    : <div></div>
  return content
}
const WrappedCustomerMap = withScriptjs(withGoogleMap(CustomerMap));
export default WrappedCustomerMap;
