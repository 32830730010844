import React from "react";
import { Button, Image, Menu, Space, Table, Typography } from "antd";
const { Text } = Typography;

const getStock = (stock, orderdQuantity) => {
  switch (stock) {
    case -1:
      return { stock: "Unlimited", remainingStock: "Unlimited" };
    case 0:
      return { stock: "Out of stock", remainingStock: "Out of stock" };

    default:
      if (stock - orderdQuantity == 0) {
        return { stock: stock, remainingStock: "Out of stock" };
      }
      return { stock: stock, remainingStock: stock - orderdQuantity };
  }
};

export const columns = [
  {
    title: "Product",
    key: "name",
    render: (_, record) => (
      <div className="d-flex">
        <div className="mr-2 p-1">
          {record.imageUrl ? (
            <Image
              src={record.imageUrl || "https://picsum.photos/50/50"}
              width={40}
              className="rounded"
            />
          ) : (
            <div
              style={{
                width: 40,
                height: 40,
                backgroundColor: "#ccc",
                borderRadius: 10,
              }}
            ></div>
          )}
        </div>
        <div className="p-1 d-flex flex-column justify-content-between">
          <Text>{record.itemName}</Text>
          <Text type="secondary">{record.itemId}</Text>
        </div>
      </div>
    ),
  },
  {
    title: "Condition Type",
    dataIndex: "conditionType",
    key: "condition_type",
    render: (type) => (
      <div>
        {type === "ZBAS"
          ? `Regular - ZBAS`
          : type === "ZSEC"
            ? "Economy - ZSEC"
            : null}
      </div>
    ),
  },
  {
    title: "UOM",
    dataIndex: "uom",
    key: "uom",
  },
  {
    title: "MOQ",
    dataIndex: "moq",
    key: "moq",
  },
  {
    title: "Selling Price",
    dataIndex: "price",
    key: "price",
    render: (price) => (
      <div>
        {price === "NA"
          ? `-`
          : price}
      </div>
    ),
  },
  {
    title: "Stock Uploaded",
    dataIndex: "stock",
    key: "stock_uploaded",
    render: (stock, record) => (
      <div>{getStock(stock, record.orderQuantity).stock}</div>
    ),
  },
  {
    title: "Ordered Qty",
    dataIndex: "orderQuantity",
    key: "ordered_qty",
  },
  {
    title: "Remaining Stock",
    dataIndex: "remaining_stock",
    key: "remaining_stock",
    render: (remaining_stock, record) => (
      <div>{getStock(record.stock, record.orderQuantity).remainingStock}</div>
    ),
  },
];
