import React, { useState } from "react";
import accessdenaied from "../../images/accessdenied.svg";
import { Typography, Form, Input, Button, Modal } from "antd";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useParams, useHistory } from "react-router-dom";
import { set } from "lodash";

import { logoutFunction } from "../../utils/Auth";

function AccessDenied() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const id = params?.id;
  console.log(id, "jhj");
  const reLogin = () => {
    // setBalanceConfirmationToken("");
    // setAuthPhoneNo("");
    // const custId = getAuthTokenCustID();
    // dispatch({
    //   type: actionTypes.SETTOKEN,
    //   token: false,
    // });
    //  history.push(`/customerBalance/${custId}`);
  };
  return (
    <div className="w-100">
      <div className="" style={{ display: "flex", justifyContent: "center" }}>
        <span>
          <Link to={`/salesReturn`}>
            <img
              src={accessdenaied}
              className=""
              style={{ marginRight: "200px" }}
            />
          </Link>
        </span>
      </div>
    </div>
  );
}

export default AccessDenied;
