import React, { Component } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { getOrderDetails } from "../../services/teamServices/team";
import Moment from "moment";
import { userDetails } from "../../services/userServices/user";
import { stringSortingByChar } from "../../utils/common";
import { checkErr } from "../../utils/common";
import { formatMoney } from "../../utils/common";
import { Modal, Button } from "antd";
import history from "../../history";
class OrderDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      orderDetails: null,
      profileDetails: null,
      showItemDetailsStatus: false,
      totalItems: null,
    };
  }

  getuserDetails = async () => {
    await userDetails()
      .then((res) => {
        console.log();
        this.setState({
          profileDetails: res.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };

  getOrderDetailsFunction = async () => {
    this.setState({
      loading: true,
    });
    let date = Moment(this.props.location.state, "DD-MM-YYYY").format(
      "DD/MM/YYYY"
    );
    let Id = this.props.location.salesmanId;

    await getOrderDetails(date, Id)
      .then((res) => {
        if (!res.data.data) {
          message.warning("No data available!");
        }
        this.setState({
          loading: false,
          orderDetails: res.data.data[0].orders,
        });
      })
      .catch((err) => {
        // message.warning(err.response.data.message);
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };
  showItemDetails = (totalItems) => {
    this.setState({
      showItemDetailsStatus: !this.state.showItemDetailsStatus,
      totalItems: totalItems,
    });
  };
  closeModal = () => {
    this.setState({
      showItemDetailsStatus: false,
    });
  };
  componentDidMount = () => {
    if (this.props.location.salesmanId) {
      this.getOrderDetailsFunction();
      this.getuserDetails();
    } else {
      window.location = "/dashboard/team/reports";

      history.push("/dashboard/team/reports");
    }
  };

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let { loading, date, orderDetails, profileDetails } = this.state;

    console.log("orderDetails", this.state.orderDetails);

    return (
      <>
        <div id="content">
          <div className="col col-md-6 field-title">
            {" "}
            Salesman Name :{" "}
            <span className="fields" title={this.props.location.name}>
              {stringSortingByChar(this.props.location.name, 30)}
            </span>{" "}
          </div>
          {/* <div className="col col-md-4 field-title">
                    Date :{" "}
                    <span className="fields">
                      {Moment(this.props.location.state, "DD-MM-YYYY").format(
                        "DD-MM-YYYY"
                      )}
                    </span>
                  </div> */}

          <div>
            <div className="table-wrapper">
              <div>
                <Spin indicator={antIcon} spinning={loading}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">Order ID</th>
                        <th className="text-center">Invoice ID</th>
                        <th className="text-center">Total Ordered Items</th>
                        <th className="text-center">Payment Mode</th>
                        <th className="text-center">Payment Status</th>
                        <th className="text-center">Total Amount (&#8377;)</th>
                        {/* <th className="text-center">
                          Total Tax Amount (&#8377;)
                        </th>
                        <th className="text-center">
                          Total Amount Without Tax (&#8377;)
                        </th> */}
                        <th className="text-center">Order Time</th>
                        <th className="text-center">Delivery Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails &&
                        orderDetails.map((order, i) => {
                          return (
                            <tr key={i}>
                              <td
                                className="text-center"
                                title={order.salesOrderId ? order.salesOrderId : "N/A"}
                              >
                                {order. salesOrderId
                                  ? stringSortingByChar(order.salesOrderId, 7)
                                  : "N/A"}
                              </td>
                              <td
                                className="text-center"
                                title={order.invoceId ? order.invoceId : "N/A"}
                              >
                                {order.invoceId
                                  ? stringSortingByChar(order.invoceId, 7)
                                  : "N/A"}
                              </td>
                              <td
                                className="text-center text-primary cursor"
                                onClick={() =>
                                  this.showItemDetails(order.items)
                                }
                              >
                                {order.items && order.items.length}
                              </td>
                              <td
                                className="text-center"
                                style={{ textTransform: "capitalize" }}
                              >
                                {order.paymentMode}{" "}
                              </td>
                              <td
                                className="text-center"
                                style={{ textTransform: "capitalize" }}
                              >
                                {order.paymentStatus}
                              </td>
                              <td className="text-center">
                                {formatMoney(order.totalAmount)}
                              </td>
                              {/* <td className="text-center">
                                {formatMoney(order.totalTaxAmount)}
                              </td>
                              <td className="text-center">
                                {formatMoney(order.totalAmountWithoutTax)}
                              </td> */}
                              <td className="text-center">
                                {Moment(order.orderTime).format("HH:mm:ss")}
                              </td>

                              <td className="text-center">
                                {order.dateOfDelivery}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <Modal
                    title="Item Details"
                    visible={this.state.showItemDetailsStatus}
                    destroyOnClose={true}
                    closable={false}
                    confirmLoading={true}
                    width={750}
                    footer={[
                      <div class="d-flex justify-content-end">
                        <Button
                          key="back"
                          className="antd-btn-cancel close-btn"
                          onClick={this.closeModal}
                        >
                          Close
                        </Button>
                      </div>,
                    ]}
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Item Id</th>
                          <th scope="col">Item Name</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Price</th>
                          <th scope="col">Tax Amount(&#8377;)</th>
                          <th scope="col">Amount(&#8377;)</th>
                          <th scope="col">Total Amount(&#8377;)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.totalItems &&
                          this.state.totalItems.map((item) => {
                            return (
                              <tr>
                                <td title={item.itemId}>{stringSortingByChar(item.itemId, 7)}</td>
                                <td title={item.itemName}>{stringSortingByChar(item.itemName,7)}</td>
                                <td>{item.quantity}</td>
                                <td>{item.price}</td>
                                <td>{item.taxAmount}</td>
                                <td>{item.amount}</td>
                                <td>{item.totalAmount}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </Modal>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OrderDetails;
