import React, { useState,useEffect } from "react";

import { Typography, Form, Input, Button, Modal } from "antd";

// import "./balancecreation.scss";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../action/balanceCrestionAction";

import { useParams, useHistory,useLocation } from "react-router-dom";
import { set } from "lodash";
import OtpInput from "react-otp-input";
import { verifyOtp,getOtp } from "../../services/deleteUser/deleteUserServices";
// import {
//   setBalanceConfirmationToken,
//   getAuthTokenCustID,setAuthPhoneNo
// } from "../../components/balanceCreation/authToken";

import Sessionexpired from "../../images/balaceconfirmationsession.svg";

function Otp() {
  const [otp, setOtp] = useState("");
  const { state } = useLocation();
  const [seconds, setSeconds] = useState(120);
  const [phone, setPhone] = useState();
  const [otpWrong, setOtpWrong] = useState(false);
  const [emptyOtp, setEmptyOtp] = useState(false);
  const [buttondisable, setButtonDisable] = useState(false);
  const [resendOTPsuccess, setResendOTPsuccess ] = useState(false);
  const [resendOTPfailure, setResendOTPfailure ] = useState(false);

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  console.log(state)
  const phoneNo = state?.contactNumber;


  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  const verifyotp = async () => {
    if (otp.length === 0) {
      setEmptyOtp(true);
    } else {
      setEmptyOtp(false);
      try {
        const res = await verifyOtp(state?.contactNumber, otp);

        if (res.status == 200) {
          if (res.data.status == 200) {
            localStorage.setItem("deleteUserToken", res.data.result.token);
            const location = {
              pathname: `/deleteUserSubmission`,
              state: {
                phoneNo,
              },
            };
           history.push(location);
          } else if (res.data.status == 400) {
            setOtpWrong(true);
          }
        }
      } catch (err) {
        setOtpWrong(true);
        console.log(err, "err");
      }
    }
  };
  const onCancelRequest = (e) => {
    const location = {
      pathname: `/cancelRequest`,
    };
    history.push(location);
   }
  const resendotp = async () => {
    try {
      const res = await getOtp(phoneNo);
      if (res.status == 200) {
        if (res.data.status == 200) {
          setResendOTPsuccess(true);
          setTimeout(() => {
            setResendOTPsuccess(false);
          }, 4000);
          setSeconds(120);
        } else if (res.data.status == 400) {
          setResendOTPfailure(true);
          setTimeout(() => {
            setResendOTPfailure(false);
          }, 4000);
          setSeconds(120);
          
      }
      else if(res.data.status == 500){
        setResendOTPfailure(true);
        setTimeout(() => {
          setResendOTPfailure(false);
        }, 4000);
        setSeconds(120);   
      }
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  return (
    <div
    className="example"
    style={{
      //marginTop: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",

      overflow: "hidden",
      scrollBehavior: "none",
      position: "fixed",
    }}
  >
    <div>
      <Typography
        style={{ fontWeight: 700, fontSize: "34px", lineHeight: "41px",marginLeft:"10px" }}
      >
        Verify with OTP
      </Typography>
      <Typography
        style={{
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: " 22px",
          marginLeft:"10px"
        }}
      >
        Please enter the OTP sent to your phone <br /> +91 ******
        {state?.contactNumber?.slice(6, 10)} to proceed further
      </Typography>
      <div>
        <div style={{ display: "flex", gap: "30px", marginTop: "35px",marginLeft:"10px" }}>
          <OtpInput
            value={otp}
            onChange={(otp) => {
              setOtp(otp);
              setOtpWrong(false);
            }}
            isInputNum={true}
            inputStyle={{
              fontSize: "20px",
              width: "36px",
              height: "36px",
              margin: "4px",
              border: otpWrong ? "1px solid red" : " 1px solid black",

              borderRadius: "5px",
              outline: "none",
              color: "black",
            }}
            autoFocus
            numInputs={6}
            otpType="number"
            disabled={false}
            // onKeyDown={otpInputLoggedin}
          />
        </div>
        <span>
          {" "}
          {otpWrong ? (
            <Typography
              style={{
                color: "#E13440",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "19px",
                marginTop: "10px",
                marginLeft:"10px",
              }}
            >
              Incorrect OTP
            </Typography>
          ) : null}
          {emptyOtp === true ? (
            <Typography
              style={{
                color: "#E13440",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "19px",
                marginTop: "10px",
              }}
            >
              Please enter the OTP
            </Typography>
          ) : (
            ""
          )}
           {resendOTPfailure === true ? (
            <Typography
              style={{
                color: "#E13440",
                fontWeight: 200,
                fontSize: "16px",
                lineHeight: "19px",
                marginTop: "10px",
                marginLeft:"10px",
              }}
            >
              Failed to send OTP
            </Typography>
          ) : (
            ""
          )}
          {resendOTPsuccess === true ? (
            <Typography
              style={{
                color: "#07ad33",
                fontWeight: 200,
                fontSize: "16px",
                lineHeight: "19px",
                marginTop: "10px",
                marginLeft:"10px",
              }}
            >
             OTP sent successfully
            </Typography>
          ) : (
            ""
          )}
        </span>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            justifyContent: "end",
          }}
        >
          <Typography
            style={{
              marginRight: "20px",
              fontSize: "14px",
              marginTop: "6px",
              marginLeft:"10px"
            }}
          >
            Didn’t receive OTP?
          </Typography>
          <Button
            type="link"
            disabled={seconds > 0 || buttondisable}
            style={{ marginRight: "10px", fontWeight: 400, fontSize: "14px" }}
            onClick={() => resendotp()}
          >
            Resend OTP {seconds > 0 ? `(${seconds}s)` : ""}
          </Button>
          {/* <Link
            style={{ marginRight: "10px", fontWeight: 400, fontSize: "14px" }}
            disabled={seconds > 0}
          >
            Resent OTP ({seconds}s)
          </Link> */}
        </span>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
          onClick={() => onCancelRequest()}
        >
          Cancel
        </Button>
        <Button
          type="link"
          onClick={() => verifyotp()}
          disabled={buttondisable}
        >
          Submit
        </Button>
      </div>
    </div>
  </div>
  );
}

export default Otp;
