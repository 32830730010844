import { getDeliveryDatesServices } from "../services/orderServices/checkoutService";
import generateActionTypes from "../utils/generateActionTypes";
import { handleErrorResponse } from "../utils/Request";
import message from "antd/lib/message";
export const actionTypes = generateActionTypes(
    "DELIVERY_DATES",
);

export const getDeliveryDates = (CustomerId) => {
    return async (dispatch) => {
        try {

            const res = await getDeliveryDatesServices(CustomerId);
            const resData = res?.data;
            if (resData.status === 200) {
                dispatch({
                    type: actionTypes.DELIVERY_DATES,
                    data: resData,
                });
            } else {
                message.error(
                    resData?.error && typeof resData.error === "string"
                        ? resData.error
                        : "Something went wrong"
                );
            }
        } catch (err) {
            if (err?.response?.status === 401) {
                console.log(err, "error");
                const { errorMessage, errors } = handleErrorResponse(err);
            } else {
                message.error("Unable to process the request");
            }
        }
    };
};
