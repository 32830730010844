
import React, { Component } from 'react'
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";

class Map extends Component {
  constructor(props) {
    super(props)


  }

  render() {
    let { mapCenter, customerDetailsPerDay, showInfo, removeInfo, selectedCustomer } = this.props;
    console.log("mapCenter", mapCenter);
   
    return (
      <GoogleMap
        defaultZoom={10} 
        defaultCenter={{ lat: mapCenter.latitude, lng: mapCenter.longitude }}
      >
        {" "}
        {customerDetailsPerDay.map((item) => {
          return <Marker
            key={item.customer._id}
            position={{
              lat: item.customer.location.coordinates[1],
              lng: item.customer.location.coordinates[0],
            }}
            onClick={() => showInfo(item)}

          />
        })}
        {
          selectedCustomer && (<InfoWindow
            onCloseClick={removeInfo}
            position={{
              lat: selectedCustomer.customer.location.coordinates[1],
              lng: selectedCustomer.customer.location.coordinates[0],
            }}>
            <p className="location">{selectedCustomer.customer.name}</p>
          </InfoWindow>)
        }
      </GoogleMap>
    );
  }
}
const WrappedMap = withScriptjs(withGoogleMap(Map));
export default WrappedMap;