import { Typography } from "antd";
import React from "react";
import digitalsign from "../../../images/digsignature.svg";
import digitalsig from "../../../images/digitalsig.svg";
import dgnotvailable from "../../../images/dgnotavailable.svg";
import VerifiedComponentMessge from "./VerifiedComponentMessge";
import { getIndividualOrderDetail } from "../../../action/IndividualOrderDetails";
import { Link, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

function DigitalSignature() {
  // props
  // function DigitalSignature({ invData }) {

  // Querry paramas
  // const params = useParams();
  // const InvoiceId = params?.InvoiceId;
  const {
    state: { soId, InvoiceId, Otp, customerID, shipmentindex },
  } = useLocation();
  // props
  //const { soId, InvoiceId, Otp, customerID } = invData;
  const { IndividualOrderDetails, loading } = useSelector(
    (state) => state.individualOrderDetailsReducer
  );
  const mobileNo =
    IndividualOrderDetails &&
    IndividualOrderDetails[0]?.customerinfo?.customerPhone;

  // const invoicedetails =
  //   IndividualOrderDetails &&
  //   IndividualOrderDetails[0]?.orderpacked?.invoicedetails?.filter(
  //     (invId) => invId.mginvoiceId == InvoiceId
  //   );
  // const invoiceDetails = IndividualOrderDetails && IndividualOrderDetails[0]
  //const digitalsign1 = invoicedetails && invoicedetails[0]?.digitalSignature;
  const digitalsign =
    IndividualOrderDetails[0]?.shipments[shipmentindex]?.digitalSignature;

  // const mobileNo =
  //   IndividualOrderDetails &&
  //   IndividualOrderDetails[0]?.customerinfo?.customerPhone;
  if (digitalsign === undefined) {
    return null;
  }
  return (
    <div className="my-3">
      {digitalsign?.status === true ? (
        <div
          className=""
          style={{
            padding: "29px",
            margin: "26px",
            backgroundColor: "white",
          }}
        >
          <span className="mt-3">
            {" "}
            <img src={digitalsig} alt="img" />
          </span>
          <span className="mt-3">
            <Typography
              style={{
                fontWeight: 600,
                fontsize: "22px",
                lineHeight: "22px",
                color: "#3A4960",
                marginTop: "10px",
              }}
            >
              Signature captured
            </Typography>
            <span style={{ display: "flex" }}>
              <Typography
                style={{
                  fontWeight: 400,
                  fontsize: "20px",
                  lineHeight: "22px",
                  color: "#3A4960",
                }}
              >
                with the customer mobile number{" "}
              </Typography>
              <Typography
                style={{
                  marginLeft: "6px",
                  marginRight: "6px",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
              >
                {mobileNo}
              </Typography>{" "}
              <span style={{ display: "flex" }}>
                <Typography style={{ marginRight: "10px" }}> on</Typography>
                <Typography>
                  {" "}
                  {moment(digitalsign.updatedAt)
                    ?.utcOffset(0, false)
                    .format("DD/MM/YYYY HH:mm A")}
                </Typography>
              </span>
            </span>
            <span>
              <img
                style={{ width: "500px", height: "200px", marginTop: "20px" }}
                src={digitalsign.image_url}
              />
            </span>
          </span>
        </div>
      ) : (
        <div className="m-3">
          <VerifiedComponentMessge
            imageUrl={dgnotvailable}
            title={"Signature not available"}
          />
        </div>
      )}
    </div>
  );
}

export default DigitalSignature;
