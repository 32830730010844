import { actionTypes } from "../action/productListsaction";
const initialState = {
  loading: [],
  productsList: [],
  stockandMoq: { list: [], pageNo: 1, recordPerPage: 10, totalPages: 1, syncData: "NA" },
};
const stopLoading = (type, loadArray) => {
  // console.log(type, loadArray);
  return loadArray.filter((load) => load !== type);
};

export const productReducer = (state = initialState, action) => {
  const { type, data, load } = action;
  // console.log(type);
  switch (type) {
    case actionTypes.LOADING:
      if (state.loading.includes(load)) return { ...state };
      return { ...state, loading: [...state.loading, load] };
    case actionTypes.STOP_LOADING:
      return { ...state, loading: stopLoading(load, state.loading) };
    case actionTypes.PRODUCTS_LISTS:
      return { ...state, productsList: [] };
    case actionTypes.STOCK_MOQ:
      const { result } = data;
      return {
        ...state,
        stockandMoq: {
          ...state.stockandMoq,
          list: [...result?.items],
          pageNo: result.pageNo,
          totalPages: result.totalPages,
          recordPerPage: result.recordPerPage,
          syncData: result.lastSync,
        },
        loading: stopLoading(type, state.loading),
      };

    case actionTypes.PAGINATE_STOCK:
      return {
        ...state,
        stockandMoq: {
          ...state.stockandMoq,
          pageNo: data.pageNo,
          recordPerPage: data.recordPerPage,
        },
      };
    default:
      return state;
  }
};
