import React, { useState, useEffect } from "react";
import PrimaryHeader from "../header/PrimaryHeader";
import {
  DatePicker,
  Typography,
  Pagination,
  Space,
  Search,
  Input,
  message,
} from "antd";
import "./sales.scss";
import { Table } from "antd";
import moment from "moment";
import vectorDown from "../../images/VectorDown.png";
import { Link } from "react-router-dom";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import Group from "../../images/Group.png";
import useDebounce from "../../utils/useDebounce";
import { Sales } from "./data";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useSessionStorage from "../../utils/useSessionStorage";
import { getAllSalesReturnList } from "../../action/salesReturnAction";
import { getUserDetails } from "../../action/userDetails";
import { userDetails } from "../../services/userServices/user";
import { handleErrorResponse } from "../../utils/Request";
function SalesReturn() {
  const [fromDate, setFromDate] = useSessionStorage("_sd", moment());
  const [toDate, setToDate] = useSessionStorage("_ed", moment());

  const { Search } = Input;
  const filtersType = sessionStorage.getItem("SelectedFilters")
    ? JSON.parse(sessionStorage.getItem("SelectedFilters"))
    : "";
  const [placeBy, setPlacedBy] = useState(filtersType.placementby);

  const [selectedStatus, setSelectedStatus] = useState(filtersType.orderStatus);
  const [selectedplacedBy, setSelectedPlacedBy] = useState(
    filtersType.placementby
  );
  const profileDetails = useSelector((state) => state.userDetails);
  const [checkOrderStatus, setCheckOrderStatus] = useState(
    filtersType.orderStatus
  );
  const [searchKey, setSearchvalue] = useState("");
  const debouncedSearchTerm = useDebounce(searchKey, 500);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const history = useHistory();
  const customeDisableDates = (current) => {
    let date = new Date();
    return current && current > moment(date, "DD-MM-YYYY");
  };
  const { salesReturnList, totalDocuments, loading } = useSelector(
    (state) => state.salesReturnReducer
  );
  console.log(loading, "loading", totalDocuments);
  // const redirectToDetails = () => {
  //   props.history.push(`/salesReturn/SalesReturnDetails`);
  // };
  const handleSearch = (value) => {
    if (searchKey === "" && value === " ") return;
    setPage(1);
    setSearchvalue(value);
  };
  const goToUploadPage = (salesreturnData) => {
    console.log(salesreturnData);
    const location = {
      pathname: `salesReturn/SalesReturnDetails`,
      state: salesreturnData,
    };
    history.push(location);
  };
  useEffect(() => {
    if (profileDetails === null) {
      const data = fetchUserDetails();
      console.log("in dashboard");
    }
  }, []);
  console.log(salesReturnList, "salesReturnList");
  const salesreturnData = salesReturnList;
  const fetchUserDetails = async () => {
    try {
      console.log("fetch data");
      const result = await userDetails();
      console.log(result, "reuslt");
      dispatch(getUserDetails(result.data.data));
      return result.data.data;
    } catch (error) {
      console.log("in catch");
      const { errorMessage, errors } = handleErrorResponse(error);
      message.error(errorMessage);
    }
  };
  const handleChangefilter = (pagination, filters) => {
    console.log(filters);
    if (filters == undefined || filters == null) {
      return;
    }
    let filterkeys = Object.keys(filters);

    let placementby = [];
    let orderStatus = [];
    // filterkeys.forEach((key) =>{

    // })
    filterkeys.forEach((key) => {
      if (key == "4" && filters[4]) {
        placementby = [...filters[4]];
      }
      if (key == "6" && filters[6]) {
        orderStatus = [...filters[6]];
      }
    });
    setSelectedPlacedBy(placementby);
    setCheckOrderStatus(orderStatus);
    setPlacedBy(placementby);
    setSelectedStatus(orderStatus);

    sessionStorage.setItem(
      "SelectedFilters",
      JSON.stringify({
        placementby: placementby,
        orderStatus: orderStatus,
      })
    );
  };
  const getOrderstatuscolor = (status) => {
    if (status === 2) {
      return <Welcome bgColor="#F2BC50" />;
    }
    if (status === 3) {
      return <Welcome bgColor="#219653" />;
    }
  };
  useEffect(() => {
    if (fromDate && toDate) {
      dispatch(
        getAllSalesReturnList({
          fromDate: fromDate.format("yyyy/MM/DD"),
          toDate: toDate.format("yyyy/MM/DD"),
          searchKey,
          placeBy,
          checkOrderStatus,
          page,
          pageSize,
        })
      );
    }
  }, [fromDate, toDate, searchKey, placeBy, checkOrderStatus, page, pageSize]);
  const handleChange = (dates) => {
    const [start, end] = dates?.length ? dates : ["", ""];
    setPage(1);
    if (start) {
      setFromDate(start);
      setToDate("");
    }
    if (end) {
      setToDate(end);
    }
  };
  function Welcome({ bgColor }) {
    return (
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "50px",
          backgroundColor: bgColor,
          marginTop: "4px",
        }}
      ></div>
    );
  }
  const columns = [
    {
      key: "1",
      title: (
        <div>
          <Typography className="salesreturntablehead">RR ID</Typography>
        </div>
      ),
      dataIndex: "returnId",
    },
    {
      key: "2",
      title: (
        <div>
          <Typography className="salesreturntablehead">Invoice No</Typography>
        </div>
      ),
      dataIndex: "invoiceNumber",
    },
    {
      key: "3",
      title: (
        <div>
          <Typography className="salesreturntablehead">Customer</Typography>
        </div>
      ),
      render: (_, record) => (
        <div>
          <Typography className="sales-headers">
            {record.customerName}
          </Typography>
          <Typography className="sales-header-inner">
            {record.customerid}
          </Typography>
        </div>
      ),
    },

    {
      key: "4",

      title: (
        <div>
          <Typography className="salesreturntablehead">Placed by</Typography>
        </div>
      ),
      dataIndex: "source",

      filters: [
        {
          text: "Sales Executive",
          value: "Sales Executive",
        },
        {
          text: "Call Center",
          value: "Call Center",
        },
      ],
      filteredValue: selectedplacedBy,
      filterMultiple: false,
      filterIcon: (filtered) => <DownOutlined />,
    },
    {
      key: "5",

      title: (
        <div>
          <Typography className="salesreturntablehead">Assigned To</Typography>
        </div>
      ),
      render: (_, record) => (
        <div>
          <Typography className="sales-headers">
            {record.assigneeName}
          </Typography>
          <Typography className="sales-header-inner">
            {record.assigneeContact}
          </Typography>
        </div>
      ),
    },

    {
      key: "6",

      title: (
        <div>
          <Typography className="salesreturntablehead">Status</Typography>
        </div>
      ),
      render: (_, record) => (
        <div style={{ display: "flex", width: "100px" }}>
          {getOrderstatuscolor(record.status)}
          {record.status === 3 ? (
            <Typography style={{ marginLeft: "7px" }}>Closed</Typography>
          ) : (
            <Typography style={{ marginLeft: "7px" }}>In-Progress</Typography>
          )}
        </div>
      ),
      // render: (_, record) => ({
      //   getOrderstatuscolor(record.Status)
      // }),
      // dataIndex: "Status",

      filters: [
        {
          text: "In-Progress",
          value: "2",
        },
        {
          text: "Closed",
          value: "3",
        },
      ],
      filterMultiple: false,
      filteredValue: selectedStatus,
      filterIcon: (filtered) => <DownOutlined />,
    },
  ];
  return (
    <div id="content">
      {/* <img src={Group}/> */}
      <PrimaryHeader image={Group} title={"Dashboard"} />
      <div className="d-flex">
        <p className="mr-3 mt-2 heading">{"Sales Return"}</p>
        <div className="range-Picker">
          <RangePicker
            bordered={false}
            style={{ width: "429px" }}
            size="small"
            onCalendarChange={handleChange}
            disabledDate={customeDisableDates}
            defaultValue={[fromDate, toDate]}
            value={[fromDate, toDate]}
          />
        </div>
      </div>
      <div>
        <div className="d-flex ml-4 mt-4 search_input">
          <Input
            size="large"
            className="search_invoiceno"
            placeholder="Search by Customer ID / Invoice No"
            enterButton
            prefix={<SearchOutlined />}
            // onSearch={handleSearch}
            value={searchKey}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          dataSource={salesreturnData}
          columns={columns}
          loading={loading}
          pagination={false}
          onChange={handleChangefilter}
          onRow={(salesreturnData) => ({
            onClick: () => goToUploadPage(salesreturnData),
          })}
          // onRow={({ returnId }) => ({
          //   onClick: (id) => {
          //     history.push(`/salesReturn/SalesReturnDetails/${returnId}`);
          //   },
          // })}
        />
        <Space className="my-5 d-flex justify-content-center">
          <Pagination
            hideOnSinglePage={true}
            showSizeChanger={false}
            current={page}
            total={totalDocuments}
            pageSize={pageSize}
            onChange={(pageNo, pageSize) => {
              setPage(pageNo);
              setPageSize(pageSize);
            }}
            size="default"
          />
        </Space>
      </div>
    </div>
  );
}

export default SalesReturn;
