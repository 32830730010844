import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import PrimaryHeader from "../header/PrimaryHeader";
import OrderSteps from "./OrderSteps";

import {
  Divider,
  Typography,
  notification,
  Modal,
  message,
  Button,
} from "antd";
import { verifyOtp } from "../../services/otpServices/otpServices";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { handleErrorResponse } from "../../utils/Request";
import moment from "moment";
import { CheckOutlined } from "@ant-design/icons";
import {
  genereatesoOtp,
  initiateOtp,
  verifySoOtpTest,
  getOtpConfig,
  generateSOIDService,
  onCheckoutService,
} from "../../services/orderServices/checkoutService";
// import {
//   onCheckoutService,
//   generateSOIDService,
// } from "../../services/orderServices/checkoutService";
import "./otp.scss";
import { useLocation } from "react-router-dom";
import { getPaymentDetails } from "../../action/paymentDetailsaction";

function Otp() {
  const [current, setCurrent] = useState(2);
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [loading, setLoader] = useState(false);
  const [seconds, setSeconds] = useState(59);
  const [mobile, setMobile] = useState();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  //const [otpExpiration, SetOtpExpiration] = useState(true);
  const [isTooManyAttempts, setIsTooManyAttempts] = useState(false);

  const { paymentDetails } = useSelector(
    (state) => state.paymentDetailsReducer
  );
  const [isOffline, setIsOffline] = useState(false);
  const tempOnline = () => {
    setIsOffline(false);
  };
  const tempOffline = () => {
    setIsOffline(true);
  };

  const [otpWrong, setOtpWrong] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const creditLimit = paymentDetails?.creditLimit;
  const sootpattempts = paymentDetails?.orderOtpAttempts;
  const [attempts, setAttemts] = useState(sootpattempts);
  const [otpExpiryAt, setOtpExpiryAt] = useState(null);

  const resendOTP = () => {
    initialApiCalls();
    setMinutes(1);
    setSeconds(59);
  };
  const history = useHistory();
  const state = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (paymentDetails == undefined) {
      dispatch(getPaymentDetails(customerId));
    }
    initialApiCalls();
  }, []);
  useEffect(() => {
    const initialOfflineState = navigator.onLine;
    setIsOffline(!initialOfflineState);
    // console.log("InitialStateData", initialOfflineState);
    window.addEventListener("online", tempOnline);
    window.addEventListener("offline", tempOffline);
    return () => {
      window.removeEventListener("online", tempOnline);
      window.removeEventListener("offline", tempOffline);
    };
  }, []);

  // useEffect(() => {
  //   // Update network status
  //   const handleStatusChange = () => {
  //     setIsOnline(navigator.onLine);
  //   };

  //   // Listen to the online status
  //   window.addEventListener("online", handleStatusChange);

  //   // Listen to the offline status
  //   window.addEventListener("offline", handleStatusChange);

  //   // Specify how to clean up after this effect for performance improvment
  //   return () => {
  //     window.removeEventListener("online", handleStatusChange);
  //     window.removeEventListener("offline", handleStatusChange);
  //   };
  // }, [isOnline]);

  // useEffect(() => {
  //   {
  //     !isOffline
  //       ? message.error("onlinekkkkkkkk")
  //       : message.error("Youlllllllll are offine");
  //   }
  // }, []);

  useEffect(() => {
    if (paymentDetails?.orderOtpAttempts == 5) {
      var enableAt = moment(paymentDetails?.orderEnableAt);
      var myDateObj = new Date();
      var now = moment(myDateObj);
      var duration = moment.duration(enableAt.diff(now));
      var min = duration.minutes();
      var sec = duration.seconds();
      if (min <= 0 && sec <= 0) {
        setAttemts(0);
      } else {
        setAttemts(paymentDetails?.orderOtpAttempts);
      }
    }

    if (paymentDetails?.orderEnableAt != null) {
      // setIsTooManyAttempts(true);
      var enableAt = moment(paymentDetails?.orderEnableAt);
      var myDateObj = new Date();
      var now = moment(myDateObj);
      var duration = moment.duration(enableAt.diff(now));
      var min = duration.minutes();
      var sec = duration.seconds();
      console.log(min, sec);
      if (min > 0) {
        setMinutes(min);
        setIsTooManyAttempts(true);
      }
      if (sec > 0) {
        setSeconds(sec);
        setIsTooManyAttempts(true);
      }
    }
    if (isTooManyAttempts == false) {
      var enableAt = moment(otpExpiryAt);
      var myDateObj = new Date();
      var now = moment(myDateObj);
      var duration = moment.duration(enableAt.diff(now));
      var min = duration.minutes();
      var sec = duration.seconds();
      if (min > 0 || sec > 0) {
        if (min == 1 && sec <= 45) {
          setMinutes(min);
          setSeconds(sec);
        } else if (min == 0 && sec > 0) {
          setMinutes(min);
          setSeconds(sec);
        }
      }
    } else {
      //message.error(response?.data?.result?.msg);
      // notification.error({ message: response.data.error });
    }
  }, [paymentDetails?.orderOtpAttempts]);

  const { selectedCustomer } = useSelector(
    (state) => state.serachOrdersReducer
  );

  const customerId = selectedCustomer?.goFrugalId;

  const {
    state: { chekout },
  } = useLocation();
  // useEffect(() => {
  //   // if(paymentDetails?.orderOtpAttempts >=1)
  //   // {
  //   //   setIsTooManyAttempts(true);
  //   // }
  //   debugger;
  //   // if (paymentDetails?.orderEnableAt != null) {
  //   //   debugger;
  //   //  // setIsTooManyAttempts(true);
  //   //   var enableAt = moment(paymentDetails?.orderEnableAt);
  //   //   var myDateObj = new Date();
  //   //   var now = moment(myDateObj);
  //   //   var duration = moment.duration(enableAt.diff(now));
  //   //   var min = duration.minutes();
  //   //   var sec = duration.seconds();
  //   //   console.log(min, sec);
  //   //   if (min > 0) {
  //   //     setMinutes(min);
  //   //     setIsTooManyAttempts(true);
  //   //   }
  //   //   if (sec > 0) {
  //   //     setSeconds(sec);
  //   //     setIsTooManyAttempts(true);
  //   //   }
  //   // }
  //   // initialApiCalls();
  // }, [remark]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          if (isTooManyAttempts) {
            setIsTooManyAttempts(false);
            setAttemts(0);
            setOtpWrong(false);
            setOtp("");
          }
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const initialApiCalls = async () => {
    try {
      await initiateOtp(customerId).then((response) => {
        if (response.status === 200) {
          if (response.data.status == 200) {
            // const mobile = response?.data?.result?.phone_number;
            setOtpExpiryAt(response?.data?.result?.otpExpiryAt);
            setMobile(response?.data?.result?.phone_number);
            //  console.log(mobile, "mobile");
            if (isTooManyAttempts == false) {
              var enableAt = moment(response?.data?.result?.otpExpiryAt);

              var myDateObj = new Date();
              var now = moment(myDateObj);
              var duration = moment.duration(enableAt.diff(now));
              var min = duration.minutes();
              var sec = duration.seconds();
              if (min > 0 || sec > 0) {
                if (min == 1 && sec <= 45) {
                  setMinutes(min);
                  setSeconds(sec);
                } else if (min == 0 && sec > 0) {
                  setMinutes(min);
                  setSeconds(sec);
                }
              }
            } else {
              //message.error(response?.data?.result?.msg);
              // notification.error({ message: response.data.error });
            }
          } else {
            setMobile(response?.data?.error?.phoneNumber);
            if (
              response.data.error.msg != "Invalid OTP!" &&
              response.data.error.msg != "Otp Send Sucessfully" &&
              response.data.error.msg != "Too many attempts!"
            ) {
              message.error(response.data.error.msg);
            }
          }
        }
        // setLoadingUntilCustomerCheck(false);
      });
    } catch (err) {
      if (err?.response?.status === 401) {
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
    }
  };
  const postCheckout = async (soID) => {
    const request = {
      sales_order_key: soID,
    };
    await generateSOIDService(request).then((response) => {});
  };
  const checkoutorder = async () => {
    // const chekout = {
    //   dateOfDelivery: dateOfDelivery,
    //   orderRemark: remark,
    //   paymentMode: paymentMode,
    //   creditLimit: creditLimit,
    //   creditExposure: paymentDetails?.out_standing_amount,
    //   creditBalance: paymentDetails?.totalCreditBalance,
    //   endTimeSlot: timeSlot?.endTimeSlot,
    //   startTimeSlot: timeSlot?.startTimeSlot,
    //   displayLabel: timeSlot?.displayLabel,
    // };
    try {
      await onCheckoutService(selectedCustomer.goFrugalId, chekout).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.status == 200) {
              setIsModalVisible(true);
              setLoader(false);
              postCheckout(response.data.data.soDBId);
            } else {
              notification.error({ message: response.data.error });
            }
          } else {
            notification.error({
              message: response?.data?.error
                ? response.data.error
                : "Something went wrong",
            });
          }
          // setLoadingUntilCustomerCheck(false);
        }
      );
    } catch (err) {
      if (err?.response?.status === 401) {
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }

      // setLoadingUntilCustomerCheck(false);
    }
  };

  // const getOtpConfig = async () => {
  //   try {
  //     const res = await initiateOtp(customerId);

  //     if (res.status === 200) {
  //       {
  //       }
  //     } else {
  //     }
  //   } catch (err) {
  //     if (err?.response?.status === 401) {
  //       console.log(err, "error");
  //       const { errorMessage, errors } = handleErrorResponse(err);
  //     } else {
  //       message.error(err?.response?.data?.message);
  //     }
  //   }
  // };
  const getDisabled = () => {
    if (isTooManyAttempts) {
      return true;
    }
    if (otp.length < 4) {
      return true;
    }
    //!otp.length < 3;
  };

  const handleSubmit = async () => {
    setLoader(!loading);
    console.log(loading, "jjjj");
    if (otp == "") {
      notification.error({ message: "Invalid Otp" });
      return;
    }
    //setOtpWrong(false);

    // console.log(otp, "otp");
    await verifySoOtpTest(customerId, otp)
      .then((response) => {
        if (response.data.status === 200) {
          checkoutorder();
          setLoader(!loading);
        } else {
          setAttemts(
            response.data.error.attempts ? response.data.error.attempts : 0
          );
          setOtpWrong(true);
          setLoader(loading);
          if (response.data.error.attempts == 5) {
            setIsTooManyAttempts(true);
            setSeconds(59);
            setMinutes(4);
          }
          if (
            response.data.error.msg != "Invalid OTP!" &&
            response.data.error.msg != "Otp Verified Successfully" &&
            response.data.error.msg != "Too many attempts!"
          ) {
            message.error(response.data.error.msg);
          }
        }
        // setLoader(!loading);
      })

      .catch((error) => {
        console.log(error, "error");
        if (error?.response?.status === 401) {
          console.log(error, "error");
          const { errorMessage, errors } = handleErrorResponse(error);
        } else {
          message.error("Unable to process the request");
        }
        setLoader(!loading);
      });
  };
  return (
    <div className="w-100 h-100" style={{ backgroundColor: "#f5f7fa" }}>
      <div className="px-4">
        <PrimaryHeader title="Place Order" backgroundColor="#f5f7fa" />
      </div>
      <OrderSteps
        current={current}
        onClickBack={() => history.push("/dashboard/orders/placeorder")}
        onClickBackItem={() => history.push("/dashboard/orders/checkout")}
      />
      <div className="p-5 m-4 " style={{ backgroundColor: "#fff" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ display: "flex", flexDirection: "row" }}>
            {" "}
            <Divider
              type="vertical"
              style={{
                border: "2px solid #737EED",
                height: "22px",
                marginTop: "3px",
              }}
            />
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: 600,
                color: "#3A4960",
              }}
            >
              OTP Confirmation{" "}
            </Typography>
          </span>
          <span>
            {" "}
            <Link
              to="/dashboard/orders/payment"
              style={{ color: "black", fontSize: "16px", fontWeight: 600 }}
            >
              <u> Go back</u>
            </Link>
          </span>
        </div>
        <div style={{ marginLeft: "6px" }}>
          <span style={{ display: "flex" }}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#3A4960",
                lineHeight: 4,
              }}
            >
              OTP has been sent to
            </Typography>
            <div style={{ display: "flex" }}>
              <span>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#3A4960",
                    lineHeight: 4,
                    marginRight: "1px",
                    marginLeft: "2px",
                  }}
                >
                  +91
                </Typography>
              </span>
              <span>
                {" "}
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#3A4960",
                    lineHeight: 4,
                    marginRight: "1px",
                    marginLeft: "2px",
                  }}
                >
                  {mobile?.slice(0, 3)}
                </Typography>
              </span>
              <span>
                {" "}
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#3A4960",
                    lineHeight: 4,
                    marginRight: "1px",
                    marginLeft: "2px",
                  }}
                >
                  {mobile?.slice(3, 7)}
                </Typography>
              </span>
              <span>
                {" "}
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#3A4960",
                    lineHeight: 4,
                    marginRight: "1px",
                    marginLeft: "2px",
                  }}
                >
                  {mobile?.slice(7, 10)}
                </Typography>
              </span>
            </div>

            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#3A4960",
                lineHeight: 4,
                marginLeft: "3px",
              }}
            >
              to confirm order.{" "}
            </Typography>
          </span>

          <div>
            {" "}
            <OtpInput
              value={otp}
              onChange={(otp) => {
                console.info(otp);
                setOtp(otp);
                setOtpWrong(false);
                setAttemts(attempts);
              }}
              numInputs={4}
              focusStyle={{
                width: "74px",
                height: "36px",

                borderBottom: "2px solid #5078F2",
                borderTop: "0px",
                borderLeft: "0px",
                borderRight: "0px",
                outline: "none",
              }}
              // otpWrong ? (i) : ()
              inputStyle={{
                fontSize: "24px",
                width: "74px",
                height: "36px",
                margin: "4px",
                borderColor: otpWrong && otp.length > 0 ? "#E13440" : "black",
                borderTop: "0px",
                borderLeft: "0px",
                borderRight: "0px",
                outline: "none",
                color: "black",
              }}
              containerStyle={{ margin: "1px auto", padding: "1px" }}
              isInputNum
            />{" "}
          </div>
          {isTooManyAttempts == true && otp.length == 4 ? (
            <Typography
              style={{
                color: "#E13440",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "19px",
                marginTop: "10px",
              }}
            >
              Too many attempts !
            </Typography>
          ) : otpWrong == false ? (
            <Typography style={{ color: "#3A4960", lineHeight: 4 }}>
              Attempts :{attempts}/5
            </Typography>
          ) : null}

          {attempts != 5 && otpWrong && otp.length == 4 ? (
            <Typography
              style={{
                color: "#E13440",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "19px",
                marginTop: "10px",
              }}
            >
              Invalid OTP !
            </Typography>
          ) : null}
          <div className="">
            <Button
              loading={loading}
              disabled={getDisabled()}
              className="confirm_button"
              onClick={handleSubmit}
            >
              Confirm Order
            </Button>
          </div>
          <div className="mt-3">
            <div>
              {isTooManyAttempts ? (
                <span
                  style={{
                    display: "flex",
                    marginTop: "6px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  <Typography> Please try after :</Typography>
                  <Typography style={{ fontWeight: 600 }}>
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </Typography>
                </span>
              ) : seconds > 0 || minutes > 0 ? (
                <Typography style={{ fontWeight: 600 }}>
                  {" "}
                  {minutes < 10 ? `0${minutes}` : minutes}:{" "}
                  {seconds < 10 ? `0${seconds}` : seconds}{" "}
                </Typography>
              ) : (
                <span
                  style={{
                    display: "flex",
                    marginTop: "6px",
                    textAlign: "center",
                  }}
                >
                  <Typography style={{ marginTop: "6px" }}>
                    Didn’t receive OTP ?
                  </Typography>
                  <Button
                    type="link"
                    style={{
                      border: "none",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                    onClick={resendOTP}
                  >
                    Resend OTP
                  </Button>
                </span>
              )}
            </div>
          </div>
          <Modal visible={isModalVisible} closable={false} footer={null}>
            <div style={{ textAlign: "center", padding: "15px" }}>
              <CheckOutlined
                size="large"
                style={{
                  fontSize: "30px",
                  color: "white",
                  backgroundColor: "#219653",
                  borderRadius: "40px",
                  padding: "10px",
                }}
              />
              <h5 className="mt-3">Order has been placed succesfully!</h5>
              <br />
              <p>Go To</p>
              <Link to="/dashboard/orders">
                <Button
                  style={{
                    backgroundColor: "#5078F2",
                    color: "white",
                    width: "135px",
                    height: "36px",
                  }}
                >
                  Orders
                </Button>
              </Link>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Otp;
