import { getPaymentDetailsService } from "../services/orderServices/checkoutService";
import generateActionTypes from "../utils/generateActionTypes";
import { handleErrorResponse } from "../utils/Request";
import message from "antd/lib/message";
export const actionTypes = generateActionTypes(
  "GET_PAYMENT_DETAILS",
  "LOADING_PD",
  "STOP_LOADING",
  "UPDATE_ORDER_BLOCKED"
);

export const getPaymentDetails = (CustomerId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "LOADING_PD" });
      const res = await getPaymentDetailsService(CustomerId);
      const resData = res?.data;
      if (resData.status === 200) {
        dispatch({
          type: actionTypes.GET_PAYMENT_DETAILS,
          data: resData,
        });
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        message.error("Unable to process the request");
      }
    }
  };
};
