import { actionTypes } from "../action/poGeneration";
const { GET_ALL_INTERNAL_PO, GET_ALL_EXTERNAL_PO, GET_SINGLE_PO_DETAILS } = actionTypes;

const initialState = {
    internalPoList: [],
    externalPoList: [],
    singlePoDetails: {},
};

export default function poGeneration(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_INTERNAL_PO:
            return { ...state, internalPoList: [...action.data] };
        case GET_ALL_EXTERNAL_PO:
            return { ...state, externalPoList: [...action.data] };
        case GET_SINGLE_PO_DETAILS:
            return { ...state, singlePoDetails: {...action.data } };
        default:
            return state;
    }
}
