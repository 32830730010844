import React, { useState } from "react";
import PrimaryHeader from "../../header/PrimaryHeader";
import { useHistory } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import "./stockmovement.scss";
import ArrowLeft from "../../../images/arrow-left.svg";
import { Modal } from "antd";
import Select from "react-select";
import success from "../../../images/success.svg";
import { upperFirst } from "lodash";
import {
  getStockMovementList,
  getStockStorageLocation,
  updateStockMovement,
} from "../../../services/inventoryServices/inventoryServices";
import { handleErrorResponse } from "../../../utils/Request";
function Results(props) {
  console.log(props);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [storageLocation, setStorageLocation] = useState("");
  const [quantity, setQuantity] = useState("");
  const [location, setLocation] = useState("");
  const [array, setArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState({ quantity: "" });

  console.log(array, "array");
  // console.log(array[0].batchid,"array1");

  const handleChange = (e, type) => {
    if (type == "storageLocation") {
      setStorageLocation(e);
    }
    if (type == "quantity") {
      setQuantity(e.target.value);
      let err =
        e.target.value > (array[0] && array[0].qty)
          ? "Quantity should be less than quantity"
          : "";
      setError((prev) => ({
        ...prev,
        quantity: err,
      }));
    }
    if (type == "remarks") {
      setRemarks(e.target.value);
    }
  };

  const redirectToBack = () => {
    history.push("/dashboard/inventory-management/stock-movement");
  };

  React.useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    const asyncdata = props.location.state;
    let id = asyncdata["materialCode"].label.split(" ").shift();
    let storage =
      asyncdata["storageLocation"] &&
      asyncdata["storageLocation"].label.toLowerCase();
    await getStockMovementList(id, storage, asyncdata["batchId"].label)
      .then((res) => {
        console.log(res, "results");
        setArray(res && res.data.data);
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        console.log(err);
      });
  };

  const loadStorageLocation = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 5 }, { location : search }];
    let data = await getStockStorageLocation(qParams).then((res) => {
      console.log(res, "ress");
      return {
        options:
          res.data.data &&
          res.data.data.map((item) => {
            return {
              key: item._id,
              value: item.location,
              label: upperFirst(item.location),
            };
          }),
        totalPage: parseInt(15 * res.data.data.length || 0) / parseInt(15 || 0),
      };
    });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  const stockMovementUpdate = async () => {
    let batchId = array[0] && array[0].batchId;
    let qty = array[0] && array[0].qty;
    let materialId = array[0] && array[0].itemId;
    let qParams = [
      {
        uom: array[0] && array[0].uom,
      },
      {
        type: array[0] && array[0].storageLocation[0].type,
      },
    ];
    let inputs = {
      physical_qty: parseInt(quantity),
      storage_location: storageLocation.label.toLowerCase(),
      remarks: remarks,
      materialId : materialId
    };
    await updateStockMovement(batchId, qty, qParams, inputs)
      .then((res) => {
        console.log(res, "update");
        setModal(true);
        setTimeout(() => {
          history.push("/dashboard/inventory-management/stock-movement");
      }, 1500);
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        console.log(err);
      });
  };

  const showmodal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <div id="content">
      <PrimaryHeader title={"Stock Movement"} />
      <div className="">
        <div className="">
          <div className="d-flex cursor" onClick={redirectToBack}>
            <div className="">
              <img src={ArrowLeft} alt="" />
            </div>
            <div className="ml-2 mt-1 arrow_label">
              <p>{"Back"}</p>
            </div>
          </div>
          <div className="stock_title ml-1 mt-3">
            <label htmlFor="" className="border_left">
              {"Search Results"}
            </label>
          </div>
          <div className="mt-4 ml-3 card-head">
            <label htmlFor="">{" From Storage Location :"}</label>
          </div>
          <div className="row ml-1 mt-2">
            <div className="col-lg-3">
              <label htmlFor="" className="stock-headings">
                {"Material Code"}
              </label>
              <br />
              <span>{(array[0] && array[0].itemId) || "N/A"}</span>
            </div>
            <div className="col-lg-3">
              <label htmlFor="" className="stock-headings">
                {"Material Description"}
              </label>
              <br />
              <span>{array[0] && array[0].itemName || "N/A"}</span>
            </div>
            <div className="col-lg-2">
              <label htmlFor="" className="stock-headings">
                {"Batch ID"}
              </label>
              <br />
              <span>{array[0] && array[0].batchId || "N/A"}</span>
            </div>
            <div className="col-lg-1">
              <label htmlFor="" className="stock-headings">
                {"UOM"}
              </label>
              <br />
              <span>{array[0] && array[0].uom || "N/A"}</span>
            </div>
            <div className="col-lg-1">
              <label htmlFor="" className="stock-headings">
                {"Quantity"}
              </label>
              <br />
              <span>{array[0] && array[0].qty || "N/A"}</span>
            </div>
            <div className="col-lg-2">
              <label htmlFor="" className="stock-headings">
                {"Storage Location"}
              </label>
              <br />
              <span>{array[0] && array[0].storageLocation[0].type || "N/A"}</span>
            </div>
          </div>
          <div className="card mt-5 ml-3 result-card">
            <div className="card-body">
              <div className="card-head">
                <label htmlFor="">{"To Storage Location :"}</label>
              </div>
              <div className="d-flex">
                <div className="col-lg-4 mt-3">
                  <label htmlFor="" className="form-labels label-name">
                    {"Quantity"}
                  </label>
                  <input
                    type="text"
                    className="form-control label-name"
                    onChange={(e) => handleChange(e, "quantity")}
                  />
                  <p className="error-message ml-2">{error.quantity}</p>
                </div>
                <div className="col-lg-4 mt-3">
                  <label htmlFor="" className="form-labels label-name">
                    {"Storage Location"}
                  </label>
                  <AsyncPaginate
                    className="basic-multi-select"
                    additional={{
                      page: 1,
                    }}
                    loadOptions={loadStorageLocation}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    value={storageLocation}
                    onChange={(e) => handleChange(e, "storageLocation")}
                    loadingMessage={() => "No location Found"}
                    // autoSize={true}
                    // menuPlacement="auto"
                    // menuPosition="fixed"
                  />
                </div>
              </div>
              <div className="col-lg-4 mt-4">
                <label htmlFor="" className="form-labels label-name">
                  {"UOM"}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={array[0] && array[0].uom}
                />
              </div>
              <div className="form-group mt-4 ml-3">
                <label htmlFor="" className="form-labels label-name">
                  {"Remarks"}
                </label>
                <textarea
                  class="form-control col-lg-8"
                  rows="3"
                  onChange={(e) => handleChange(e, "remarks")}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="p-5">
            <button
              className="btn btn-primary"
              onClick={stockMovementUpdate}
              disabled={
                quantity == "" || storageLocation == "" || remarks == ""
              }
            >
              {"Submit"}
            </button>
          </div>
        </div>
      </div>
      {modal && (
        <Modal
          width={500}
          bodyStyle={{ height: 225 }}
          visible={modal}
          onCancel={closeModal}
          // closable
          maskClosable={true}
          footer={null}
        >
          <div className="center-content mt-4">
            <div className="">
              <img
                src={success}
                className=""
                // alt="successicon"
              />
            </div>
            <div>
              <p className="mt-3 modal-text">
                Stock items have been moved successfully
              </p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Results;
