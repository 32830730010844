export const invoiceTableHeaders = [
    "Invoice Number",
    "Billing Type",
    "Sales Org",
    "Distribution Channel",
    "Division",
    "Billing Date",
    "Customer Price Group",
    "Customer Group",
    "Inco Terms",
    "Payment terms",
    "Company Code",
    "Account Assignment Group",
    "Sold To Party",
    "Billed To Party",
    "Payer",
    "Item Count",
  ];
  export const soTableHeaders = [
    "Order Date",
    "Sales Document Type",
    "Sales Organization",
    "Distribution Channel",
    "Division ",
    "Sold To Party",
    "Ship To Party",
    "Refrence Key",
    "Item Count",
  ];
  export const purchaseOrderHeaders = [
    "PO ID",
    "PO No.",
    "PD Doc Type",
    "Company Code",
    "Vendor No.",
    "Vendor Name",
    "Purchase Organisation",
    "Purchase Group",
    "Document Date",
    "Plant",
    "Start Of Validity Period",
    "End Of Validity Period ",
    "Refrence No.",
    "Delivery Date",
    "Shipping Plant ",
    "Item No.",
    "Material No.",
    "Material Description",
    "PI Plant",
    "PI Plant",
    "Quantity",
    "Storage Location",
    ,
    "Net Price",
    "Tax Code",
    "Conversion  Factor Status",
    "UOM",
    "Selling Price",
    "Taxable Value",
    "MRP Amount",
    "Discount Amount",
    "Discount Percentage",
    "Discount Percentage",
    "Created At",
    "API Response",
  ];
  
  export const grnHeaders = [
    "GRN ID",
    "Material Document No.",
    "PO No.",
    "Document Date",
    "Posting Date",
    "Reference ",
    "Bill Of Landing",
    "Item No.",
    "Material Document Year",
    "Material No.",
    "Plant",
    "Storage Location",
    "Batch",
    "Vendor",
    "Quantity",
    "Base Unit Of Measurement",
    "Purchase Order",
    "Delivery Note Quantity",
    "Delivery Note Unit",
    "Date Of Manufacture",
    "Created At",
    "API Response",
  ];
  export const invoiceModelHeaders = [
    "Material No.",
    "Material Description",
    "Item No.",
    "Plant",
    "Order Quantity",
    "Storage Location",
    "Payment Terms",
    "UOM",
    "MPR Amount",
    "Discount Amount",
    "Net Price",
    "Taxable Value",
    "CGST",
    "SGST",
    "IGST",
    "UGST",
    "Total Amount",
  ];
  
  export const soModelHeaders = [
    "Material No.",
    "Material Description",
    "Item No.",
    "Order Quantity",
    "Net Price",
  ];
  