import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import TeamAsms from "../components/team/teamAsms";

//Action Creator
import { getAsms } from "../action/team";

const mapStateToProps = (state) => {
    return {
     allAsmDetails: state.team.allAsmDetails
    };
  };

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
        getAsms,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamAsms));
