import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import React from "react";
import "./_stockUpdateMessage.scss";

// <CloseCircleOutlined />
const StockUpdateMessage = ({
  success,
  title,
  subTitle,
  onHandleClose,
  open,
}) => {
  return (
    <div
      className="animated shadow-sm bg-white p-4 flex-row align-items-center justify-content-center"
      style={{ width: 450, borderRadius: 20, display: open ? "flex" : "none" }}
    >
      <div>
        {success ? (
          <CheckCircleOutlined style={{ fontSize: 64, color: "#219653" }} />
        ) : (
          <CloseCircleOutlined
            className="shadow-sm"
            style={{ fontSize: 64, color: "#EC4444" }}
          />
        )}
      </div>

      <div className="ml-3 d-flex flex-column justify-content-between">
        <Text className="font-weight-bold">{title}</Text>
        <Text>{subTitle}</Text>
      </div>

      <div className="position-absolute" style={{ top: 10, right: 15 }}>
        <CloseOutlined style={{ fontSize: 20 }} onClick={onHandleClose} />
      </div>
    </div>
  );
};

export default StockUpdateMessage;
