import "antd/dist/antd.css";
import React, { Component } from "react";
import { loginStatus } from "../src/utils/Auth.js";
import "./App.scss";
import { Routes } from "./routes";
import history from "./utils/History";
const events = ["mousedown", "click", "keypress"];
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
    };
  }

  redirectAfterLogin = () => {
    if (this.state.isLoggedIn) {
      window.location = "/dashboard/beatplan/published";
      history.push({   
        pathname: `/dashboard/beatplan/published`,
      });
    } else {
      window.location = "/login";
      history.push({
        pathname: `/login`,
      });
    }
  };
  checkPath = () => {
    if (localStorage.getItem("firstTimeLogin") == true) {
      window.location = "/user/profile/password/reset";
      history.push({
        pathname: `/user/profile/password/reset`,
      });
    }
  };

  componentDidMount = () => {
    // check local storage for token
    let status = loginStatus();

    this.setState({
      isLoggedIn: status,
    });
    // // sesion time out
    if (window.location.pathname !== "/login") {
      Object.values(events).forEach((item) => {
        window.addEventListener(item, this.handleUserInteraction);
      });
      this.handleLogoutTimer();
    } else {
      this.resetTimer();
    }
  };
  handleLogoutTimer = () => {
    const timer = setTimeout(() => {
      this.resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, this.resetTimer);
      });
      localStorage.clear();
      this.setState({
        isLoggedIn: false,
      });
      window.location.pathname = "/login";
    }, 30 * 60 * 1000); // 30 Mins. You can change the time.
    this.setState({
      timer,
    });
  };
  resetTimer = () => {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
      this.setState({
        timer: null,
      });
    }
  };
  componentWillUnmount = () => {
    this.resetTimer();
    Object.values(events).forEach((item) => {
      window.removeEventListener(item, this.handleUserInteraction);
    });
  };
  render() {
    const { isLoggedIn } = this.state;
    return (
      <Routes
      isLoggedIn={isLoggedIn && isLoggedIn}
        redirectAfterLogin={this.redirectAfterLogin}
        checkPath={this.checkPath}
      />
    );
  }
}
export default App;
