import React, { useState, useEffect } from "react";
import "./sales.scss";
import { Input, Space, Pagination, Typography, Table, Divider } from "antd";
import { useLocation } from "react-router-dom";

import Select from "react-select";
import PrimaryHeader from "../header/PrimaryHeader";
import arrowleft from "../../images/arrowleftsales.svg";
import Group from "../../images/Group.png";

import { Modal, Button } from "antd";
import { useHistory } from "react-router-dom";
import success from "../../images/success.svg";

import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAllSalesReturnList } from "../../action/salesReturnAction";
import message from "antd/lib/message";
import { rbhApproveSubmit } from "../../services/salesReturnService";

import { Link } from "react-router-dom";

function SalesReturnDetails() {
  const { Option } = Select;

  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();

  const { state: salesreturnData } = useLocation();

  // let data1 = salesreturnData;
  // const itemSupp = salesreturnData?.itemSupplied.map((v) => ({
  //   ...v,
  //   approved: null,
  // }));
  // data1.itemSupplied = itemSupp;
  // console.log(data1);

  const [submitData, setSubmitData] = useState(salesreturnData);

  // console.log(salesReturnList?.itemSupplied, "bbb");
  const noOfBrands = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];
  const showModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const submitButtonStatu = salesreturnData?.itemSupplied.map((item) => {
    return item.status;
  });
  const submitButtonStatus = submitButtonStatu[0].toString();
  // const getDisabled = () => {
  //   if (submitButtonStatus != 2) {
  //     return true;
  //   }
  // };
  const onHandleChange = (e, id) => {
    let data1 = submitData;
    const newArr1 = submitData?.itemSupplied?.map((v) => {
      return v._id == id ? { ...v, approved: e.value == 1 ? true : false } : v;
    });
    // let arr1 = submitData?.itemSupplied?.filter((v) => v._id == id);
    // arr1.approved = e.value == 1 ? true : false;
    // let arr = submitData?.itemSupplied?.filter((v) => v.itemId != id);
    // arr = [...arr, ...newArr1];
    data1.itemSupplied = newArr1;
    setSubmitData(data1);
  };
  const navigateToBack = () => {
    history.push({
      pathname: "/salesReturn",
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const SRID = salesreturnData?.returnId;
  const isusesType = {
    1: "Pending/ RBH rejected",
    2: "CRM approved/ RBH Review/Good not available",
    4: "RBH approved ",
    7: "Return Completed",
  };
  const changeNumber = (item) => {
    const data1 = salesreturnData?.itemSupplied[0]?.CRMattachmentsImages;
    const array = data1.find((element) => element == item);
    setDataNew(array);
  };
  function Welcome({ bgColor }) {
    return (
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "50px",
          backgroundColor: bgColor,
          marginTop: "4px",
        }}
      ></div>
    );
  }
  // 1-Pending 2-CRM approved 3-CRM rejected 4-Business Head Approved 5-Business Head Rejected 6-Return Approved 7-Return Rejected
  const getOrderstatuscolor = (status) => {
    if (status === 1) {
      return (
        <div className="orderstatus_return">
          <Welcome bgColor="#E13440" />
          <Typography className="individual_status">Pending</Typography>
        </div>
      );
    }
    if (status === 2) {
      return (
        <div className="orderstatus_return">
          <Welcome bgColor="#F2BC50" />
          <Typography className="individual_status">CRM approved</Typography>
        </div>
      );
    }
    if (status === 3) {
      return (
        <div className="orderstatus_return">
          <Welcome bgColor="#E13440" />
          <Typography className="individual_status">CRM rejected</Typography>
        </div>
      );
    }
    if (status === 4) {
      return (
        <div className="orderstatus_return">
          <Welcome bgColor="#F2BC50" />
          <Typography className="individual_status">RBH Approved</Typography>
        </div>
      );
    }
    if (status === 5) {
      return (
        <div className="orderstatus_return">
          <Welcome bgColor="#E13440" />
          <Typography className="individual_status">RBH Rejected</Typography>
        </div>
      );
    }
    if (status === 6) {
      return (
        <div className="orderstatus_return">
          <Welcome bgColor="#219653" />
          <Typography className="individual_status">Return Approved</Typography>
        </div>
      );
    }
    if (status === 7) {
      return (
        <div className="orderstatus_return">
          <Welcome bgColor="#F2BC50" />
          <Typography className="individual_status">Return Rejected</Typography>
        </div>
      );
    }
  };
  const [datanew, setDataNew] = useState(
    salesreturnData?.itemSupplied &&
      salesreturnData?.itemSupplied[0]?.CRMattachmentsImages &&
      salesreturnData?.itemSupplied[0]?.CRMattachmentsImages[0]
  );

  const salesretunstatus = salesreturnData?.itemSupplied || [];
  let allStatus = salesretunstatus.map((item) => item.status);
  let allSalesreturnstatus = [];
  allStatus.forEach((c) => {
    if (!allSalesreturnstatus.includes(c) && c) {
      allSalesreturnstatus.push(c);
    }
  });
  const salesreturnStatusall = allSalesreturnstatus.map((item) => ({
    value: item,
    text: isusesType[item],
  }));
  let allIssueType = salesretunstatus.map((item) => item.CRMissueType);
  let allIssueTypes = [];
  allIssueType.forEach((c) => {
    if (!allIssueTypes.includes(c) && c) {
      allIssueTypes.push(c);
    }
  });
  const salesreturnIssueTypes = allIssueTypes.map((item) => ({
    value: item,
    // if(item == 2){

    // }
    text: item,
  }));

  const columns = [
    {
      key: "1",
      title: "Item Details",

      render: (_, record) => (
        <div>
          <Typography className="sales-headers">{record.itemName}</Typography>
          <Typography className="sales-header-inner">
            {record.itemId}
          </Typography>
        </div>
      ),
    },
    {
      key: "2",
      title: "Qty",
      dataIndex: "returnQty",
    },
    {
      key: "3",
      title: "Amount",
      // dataIndex: "salePrice",
      render: (_, record) => (
        <Typography>{record?.returnQty * record?.salePrice}</Typography>
      ),
    },

    {
      key: "4",
      title: "Issue type",
      dataIndex: "CRMissueType",
      filters: salesreturnIssueTypes,
      // filters: [
      //   {
      //     text: "Inorganic foreign material",
      //     value: "Inorganic foreign material",
      //   },
      //   {
      //     text: "Live pest infestation",
      //     value: "Live pest infestation",
      //   },
      //   {
      //     text: "Fungal/mold contamination",
      //     value: "Fungal/mold contamination",
      //   },
      //   {
      //     text: "Mislabeling",
      //     value: "Mislabeling",
      //   },
      // ],
      onFilter: (value, record) => {
        return record.CRMissueType === value;
      },
      filterMultiple: false,
      filterIcon: (filtered) => <DownOutlined />,
    },
    {
      key: "5",
      title: "Remarks",
      render: (_, record) => (
        <div>
          {record.remarks === "" ? (
            <Typography style={{ textAlign: "center" }}>-</Typography>
          ) : (
            <Typography>{record.remarks}</Typography>
          )}
        </div>
      ),
    },

    {
      key: "6",
      title: "Status",
      dataIndex: "Approval",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          {getOrderstatuscolor(record.status)}
        </div>
      ),
      filters: salesreturnStatusall,
      onFilter: (value, record) => {
        return record.status === value;
      },
      filterMultiple: false,
      // filters: [
      //   {
      //     text: "Pending/ RBH rejected",
      //     value: "London",
      //   },
      //   {
      //     text: "CRM approved/ RBH Review/Good not available",
      //     value: "New York",
      //   },
      //   {
      //     text: "RBH approved ",
      //     value: "London",
      //   },
      //   {
      //     text: "Return Completed",
      //     value: "New York",
      //   },
      // ],
      filterIcon: (filtered) => <DownOutlined />,
    },
    {
      key: "7",
      title: "Image proof",
      render: (_, record) => (
        <div>
          {record?.CRMattachmentsImages?.length > 0 ? (
            <Button type="link" onClick={showModalOpen}>
              <Typography
                style={{ textDecoration: "underline", color: "blue" }}
              >
                View
              </Typography>
            </Button>
          ) : (
            <Typography style={{ textAlign: "center" }}>-</Typography>
          )}

          <Modal
            title="Upload image"
            visible={isModalOpen}
            footer={false}
            onCancel={handleCancel}
            className="salesreturnmodal"
          >
            <div style={{ textAlign: "center" }}>
              {" "}
              <span>
                <img
                  src={datanew}
                  style={{
                    width: "317px",
                    height: "281px",
                    border: "1px solid #D7D7D7",
                    borderRadius: "8px",
                  }}
                />{" "}
              </span>
              <span
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {record?.CRMattachmentsImages?.map((item, key) => (
                  <span style={{ gap: "10px", display: "flex" }}>
                    <Button type="link" onClick={() => changeNumber(item)}>
                      {
                        <img
                          style={{
                            width: "84px",
                            height: "84px",
                            borderRadius: "8px",
                          }}
                          src={item}
                        />
                      }
                    </Button>
                  </span>
                ))}{" "}
              </span>
            </div>
          </Modal>
        </div>
      ),
    },
    // if (submitButtonStatus == 2) {
    //   column.push({
    //     key: "3",
    //     title: "Digital Sign",
    //     render: (_, record) => (
    //       <div style={{ display: "flex" }}>
    //         {record.digitalSignature?.status === true ? (
    //           <div style={{ display: "flex" }}>
    //             <Welcome bgColor="#219653" />
    //             <Typography style={{ paddingLeft: "10px" }}>Verified</Typography>
    //           </div>
    //         ) : record.digitalSignature?.status === false ? (
    //           <div style={{ display: "flex" }}>
    //             <Welcome bgColor="#E13440" />
    //             <Typography style={{ paddingLeft: "10px" }}>
    //               Not Verified
    //             </Typography>
    //           </div>
    //         ) : (
    //           <div style={{ display: "flex" }}>
    //             <Welcome bgColor="#E13440" />
    //             <Typography style={{ paddingLeft: "10px" }}>NA</Typography>
    //           </div>
    //         )}
    //       </div>
    //     ),
    //     dataIndex: "sign",
    //   });
    // },
  ];
  if (submitButtonStatus == 2) {
    columns.push({
      key: "8",
      title: "Approval",

      render: (text, record) => (
        <div style={{ width: "110px" }}>
          <Select
            style={{ width: 120 }}
            id={record?._id}
            options={noOfBrands}
            placeholder="Select"
            onChange={(e) => onHandleChange(e, record?._id)}
          ></Select>
        </div>
      ),
    });
  }

  const submitReturnissue = () => {
    const result = submitData?.itemSupplied?.filter(
      (item) => item.approved === null
    );
    if (result?.length >= 1) {
      message.error("Please update status for all the items");
      return;
    } else {
      const apiRequest = submitData?.itemSupplied?.map((v) => {
        return {
          itemId: v.itemId,
          itemNo: v._id,
          itemStatus: v.approved == true ? 4 : 5,
        };
      });
      const returnId = submitData?.returnId;
      rbhApproveSubmit(returnId, apiRequest);
    }

    setShowModal(true);
  };

  const handleCancelConfirm = () => {
    setShowModal(false);
    const location = {
      pathname: `/salesReturn`,
    };
    history.push(location);
  };
  return (
    <div id="content">
      <PrimaryHeader image={Group} title={"Dashboard"} />
      <span>
        <img src={arrowleft} onClick={navigateToBack} />
        <span className="ml-2 goback">Back</span>
      </span>
      <span className="mt-5 mb-4" style={{ display: "flex" }}>
        <Divider
          type="vertical"
          style={{ border: "2px solid #5078F2", height: "24px" }}
        />
        <p className="subheader" style={{ marginLeft: "-5px" }}>
          RR ID - {SRID}
        </p>
      </span>

      {/* <Input
        className="search-input"
        placeholder="Search by product name/ product ID"
        // onChange={(e) => this.onLedgerSearch(e)}
        prefix={
          <SearchOutlined style={{ fontSize: "20px", marginRight: "20px" }} />
        }
      /> */}
      <div className="table-wrapper">
        <Modal
          width={500}
          bodyStyle={{ height: 250 }}
          visible={showModal}
          onCancel={handleCancelConfirm}
          // closable
          footer={null}
        >
          <div className="center-content mt-4">
            <div className="">
              <img
                src={success}
                className=""
                // alt="successicon"
              />
            </div>
            <div>
              <p className="mt-5 modal-text">
                Return request succesfully submitted
              </p>
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <Table
          dataSource={salesreturnData?.itemSupplied}
          columns={columns}
          pagination={false}
        />
        {submitButtonStatus == 2 ? (
          <Button
            className="mt-3 salesreturnbutton"
            // disabled={getDisabled()}
            onClick={() => submitReturnissue()}
            style={{ float: "right" }}
            type="primary"
          >
            Submit
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default SalesReturnDetails;
