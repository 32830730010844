import React, { Component, Fragment } from "react";
import {
  LoadingOutlined,
  PlusOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import message from "antd/lib/message";
import { Modal, Upload, Button } from "antd";
import { uploadFile } from "../../services/fileHandler";
import {
  updateAdminProfile,
  updatePassword,
} from "../../services/userServices/user";
import { handleErrorResponse } from "../../utils/Request";
import PrimaryHeader from "../header/PrimaryHeader";

// get bas64
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

// before uploading
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};
const validPasswordRegex = RegExp(
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{10,16}$/
);
// admin profile class
class AdminProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      apiLoading: false,
      isEmailEdit: false,
      isContactEdit: false,
      isProfilePicUpdate: false,
      removeProfilePic: false,
      contactNumber: "",
      isResetModalVisible: false,
      existingPassword: "",
      newPassword: "",
      confirmPassword: "",
      email: "",
      picId: "",
    };
  }

  // handle change of image uploader
  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  // uploading file to server
  uploadToServer = async ({ onSuccess, onError, file }) => {
    let res = await uploadFile(file)
      .then((res) => {
        this.setState({
          isProfilePicUpdate: true,
          picId: res.data.data.imageId,
          removeProfilePic: false,
        });
        onSuccess(null, file);
      })
      .catch((err) => {
        onError(err);
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };

  // toggele edit
  toggleEdit = (e, input) => {
    if (e.target.id == "contactEdit")
      this.setState({ isContactEdit: !this.state.isContactEdit });
    else if (e.target.id == "emailEdit")
      this.setState({ isEmailEdit: !this.state.isEmailEdit });
    else if (e.target.id == "removeProfilePic")
      this.setState({
        removeProfilePic: !this.state.removeProfilePic,
        isProfilePicUpdate: true,
        picId: "",
      });
  };

  // change edit
  changeInput = (e, input) => {
    if (input == "contactNumber")
      this.setState({ contactNumber: e.target.value });
    else if (input == "email") this.setState({ email: e.target.value });
    else if (input == "existingPassword")
      this.setState({ existingPassword: e.target.value });
    else if (input == "newPassword")
      this.setState({ newPassword: e.target.value });
    else if (input == "confirmPassword")
      this.setState({ confirmPassword: e.target.value });
  };

  // handle update
  handleUpdate = async (e) => {
    e.preventDefault();
    let updateObject = {};
    const {
      isEmailEdit,
      isContactEdit,
      isProfilePicUpdate,
      contactNumber,
      email,
      picId,
    } = this.state;
    this.setState({ apiLoading: true });
    if (isEmailEdit) updateObject = { ...updateObject, alternateEmail: email };
    if (isContactEdit)
      updateObject = { ...updateObject, phoneNumber: contactNumber };
    if (isProfilePicUpdate)
      updateObject = { ...updateObject, profilePicture: picId };

    // updating admin profile
    await updateAdminProfile(updateObject)
      .then((res) => {
        this.setState({ apiLoading: false });
        message.info("Profile Updated Successfully");
        this.props.onProfileUpdate();
        this.setState({
          isEmailEdit: false,
          isContactEdit: false,
          isProfilePicUpdate: false,
          contactNumber: "",
          email: "",
          picId: "",
        });
      })
      .catch((err) => {
        this.setState({ apiLoading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  // handle reset modal
  handleResetModal = async (e) => {
    this.setState({
      isResetModalVisible: true,
      existingPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  handleOk = async () => {
    this.setState({ apiLoading: true });
    const { existingPassword, newPassword, confirmPassword } = this.state;

    // if new password doesnt match confirm password
    if (newPassword !== confirmPassword) {
      message.error("New Password and Confirm Password does't match !");
      this.setState({ apiLoading: false });
      return;
    }

    if (validPasswordRegex) {
      message.error(
        "Password must be eight characters, with one Uppercase, one number and one special character !"
      );
      this.setState({ apiLoading: false });
      return;
    }

    // update password
    await updatePassword({
      existingPassword: existingPassword,
      password: newPassword,
      confirmPassword: confirmPassword,
    })
      .then((res) => {
        this.setState({ apiLoading: false, isResetModalVisible: false });
        message.info("Password Updated Successfully!");
      })
      .catch((err) => {
        this.setState({ apiLoading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  handleCancel = () => {
    this.setState({
      isResetModalVisible: false,
      existingPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  render() {
    let {
      loading,
      imageUrl,
      isEmailEdit,
      isContactEdit,
      contactNumber,
      email,
      isProfilePicUpdate,
      apiLoading,
      removeProfilePic,
      isResetModalVisible,
      existingPassword,
      newPassword,
      confirmPassword,
    } = this.state;
    const { data } = this.props;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <>
        <div id="content">
          <PrimaryHeader title="Profile Details" />
          <div class="d-flex justify-content-end">
            <Button
              type="primary"
              hidden={
                email || contactNumber || isProfilePicUpdate ? false : true
              }
              className="update-button mx-2"
              shape="rounded"
              onClick={(e) => this.handleUpdate(e)}
              loading={apiLoading ? true : false}
            >
              Update
            </Button>
            <Button
              type="danger"
              className="reset-button-css"
              shape="rounded"
              onClick={(e) => this.handleResetModal(e)}
            >
              Reset Password
            </Button>
          </div>
          <div className="employer-container mb-3">
            <div className="employer-container-left">
              <p>Employer:</p>
            </div>
            <div className="employer-container-right">
              {/* <div class="d-flex flex-column mb-3"> */}
              <p>Employee Id</p>
              <p>
                <div>{data.details.empId}</div>
              </p>
              {/* </div> */}
            </div>
          </div>
          <div className="employer-container">
            <div className="employer-container-left">
              <p>User Details:</p>
            </div>
            <div className="employer-container-right">
              <form className="form-horizontal">
                <div className="form-group">
                  <p>Profile Picture</p>
                  <Upload
                    id="profilePicture"
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={this.uploadToServer}
                    beforeUpload={beforeUpload}
                    onChange={this.handleChange}
                  >
                    {!removeProfilePic ? (
                      imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : data.imageUrl ? (
                        <img
                          src={data.imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                  <i
                    class="fa fa-times-circle-o pic-delete-btn text-danger"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    title="Remove!"
                    id="removeProfilePic"
                    onClick={(e) => this.toggleEdit(e, "removeProfilePic")}
                    hidden={
                      removeProfilePic ? true : data.imageUrl ? false : true
                    }
                  ></i>
                </div>
                <div class="form-group row">
                  <p className="col-sm-2 mt-1">Name:</p>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="input-text"
                      value={`${data.details.firstName} ${data.details.lastName}`}
                      id="name"
                      disabled={true}
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <p class="mt-1 col-sm-2">Location:</p>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="input-text"
                      value={data.details.region}
                      id="location"
                      disabled={true}
                      readonly
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <p class="mt-1 col-sm-2">Email:</p>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="input-text"
                      value={data.email}
                      disabled={true}
                      placeholder={data.email}
                      id="email"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <p class="mt-1 col-sm-2">Alternate Email:</p>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="input-text"
                      disabled={isEmailEdit ? false : true}
                      value={isEmailEdit ? email : data.details.alternateEmail}
                      placeholder={
                        data.details.alternateEmail
                          ? data.details.alternateEmail
                          : "Enter email id"
                      }
                      onChange={(e) => this.changeInput(e, "email")}
                      id="email"
                    />
                    <i
                      class="fa fa-pencil-square-o text-primary"
                      for="email"
                      id="emailEdit"
                      aria-hidden="true"
                      onClick={(e) => this.toggleEdit(e, "emailEdit")}
                      hidden={isEmailEdit ? true : false}
                    />
                    <i
                      class="fa fa-window-close text-danger"
                      for="email"
                      id="emailEdit"
                      aria-hidden="true"
                      onClick={(e) => this.toggleEdit(e, "emailEdit")}
                      hidden={isEmailEdit ? false : true}
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <p class="mt-1 col-sm-2" for="contactNumber">
                    Contact Number:
                  </p>
                  <div class="col-sm-10">
                    <input
                      type="tel"
                      disabled={isContactEdit ? false : true}
                      class="input-text"
                      pattern="\+?\d[\d -]{8,12}\d"
                      placeholder={data.phone ? data.phone : "Enter phone no"}
                      name="phone"
                      value={isContactEdit ? contactNumber : data.phone}
                      onChange={(e) => this.changeInput(e, "contactNumber")}
                    />
                    <i
                      class="fa fa-pencil-square-o text-primary"
                      for="contactNumber"
                      id="contactEdit"
                      aria-hidden="true"
                      onClick={(e) => this.toggleEdit(e, "contactNumber")}
                      hidden={isContactEdit ? true : false}
                    />
                    <i
                      class="fa fa-window-close text-danger"
                      for="contactNumber"
                      id="contactEdit"
                      aria-hidden="true"
                      onClick={(e) => this.toggleEdit(e, "contactNumber")}
                      hidden={isContactEdit ? false : true}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal
          visible={isResetModalVisible}
          title="Reset Password"
          destroyOnClose={true}
          closable={false}
          confirmLoading={true}
          footer={[
            <div class="d-flex justify-content-end">
              <Button
                key="back"
                className="antd-btn-cancel"
                onClick={this.handleCancel}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                className="antd-btn"
                loading={apiLoading ? true : false}
                onClick={this.handleOk}
              >
                Update
              </Button>
            </div>,
          ]}
        >
          <form className="form-horizontal">
            <div class="form-group row">
              <label class="control-label text-muted col-sm-4">
                Existing Password:
              </label>
              <div class="col-sm-8">
                <input
                  type="password"
                  className="input-modal-text"
                  placeholder="Enter Existing Password"
                  value={existingPassword}
                  onChange={(e) => this.changeInput(e, "existingPassword")}
                />
              </div>
              {/* <i class="fa fa-eye col-sm-1" aria-hidden="true"></i> */}
            </div>
            <div class="form-group row">
              <label class="control-label text-muted col-sm-4">
                New Password:
              </label>
              <div class="col-sm-8">
                <input
                  type="password"
                  className="input-modal-text"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => this.changeInput(e, "newPassword")}
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="control-label text-muted col-sm-4">
                Confirm Password:
              </label>
              <div class="col-sm-8">
                <input
                  type="password"
                  className="input-modal-text"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => this.changeInput(e, "confirmPassword")}
                />
              </div>
            </div>
          </form>
        </Modal>
      </>
    );
  }
}

export default AdminProfile;
