import React, { Component } from "react";
import Select from "react-select";
import { getAsmListForDropdown} from "../../services/teamServices/team";
// err msg
import message from "antd/lib/message";
import { handleErrorResponse } from "../../utils/Request";

class AsmDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      page: 1,
      loading: false,
    };
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    let { page, options } = this.state;
    // { value: "chocolate", label: "Chocolate" },
    getAsmListForDropdown(page)
      .then((res) => {
          this.setState(
          {
            options: res.data.data.results &&
            res.data.data.results.map((item) => {
              return { key: item._id,value: item.employeeId, label: item.firstName + " " + item.lastName };
            })
          
          },
          () => {
           
            this.setState({
              loading:false
            })
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  selectOption = (e) => {
    let {selectedIds, mapSalesmanWithAsmFunction} = this.props;
    let reportingManagerId = e.key.toString();
    let salesmanIds = selectedIds;
    mapSalesmanWithAsmFunction(salesmanIds,reportingManagerId)
  }
  render() {
    let { loading, options } = this.state;
    return (
      <div className="showModal">
        <p className="title">Select ASM</p>

        <div className="modalBody">
          <Select
            options={options && options}
            className="basic-single"
            classNamePrefix="select"
            // isLoading={loading}
            isClearable={true}
            isSearchable={true}
            onChange={(e)=>{this.selectOption(e)}}
          />
        </div>
        {/* <footer className="ddwn-footer">
          <p>Confirm</p>
        </footer> */}
      </div>
    );
  }
}

export default AsmDropdown;
