import React, { useState } from "react";
import PrimaryHeader from "../../components/header/PrimaryHeader";
import "./priceUpload.css";
import ReactFileReader from "react-file-reader";
import { CSVLink, CSVDownload } from "react-csv";
import fileSuccess from "../../images/success.svg";
import {
  uploadFilePrice,
  getPriceList,
  patchCurrentPrice,
  reportDownloadService,
  DownloadPriceSample,
} from "../../services/inventoryServices/inventoryServices";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { userDetails } from "../../services/userServices/user";
import { useSelector } from "react-redux";
import { Modal, Button, Spin, Pagination } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Upload from "../../images/price_upload.png";
import Download from "../../images/price_download.png";
import Edit from "../../images/edit-3.svg";
import Tick from "../../images/Vector.svg";
import Cross from "../../images/cross(1).svg";
import debounce from "debounce-async";
import _ from "lodash";
import moment from "moment";
import { saveAs } from "file-saver";

import { DatePicker, Input } from "antd";
const { Search } = Input;
const { RangePicker } = DatePicker;

function PricingUpload() {
  const userDetails = useSelector((state) => state.userDetails);
  console.log(userDetails);

  const [uploadFile, setUploadFile] = useState("");
  const [file, setFile] = useState("");
  const [value, setValue] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState("");
  const [modalDate, setModalDate] = useState("");
  const [fileError, setFileError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [zero, setZero] = useState(false);
  const [loading, setLoading] = useState(false);
  const [priceView, setPriceView] = useState(false);
  const [priceArray, setPriceArray] = useState([]);
  const [pageSize, setPageSize] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(7);
  const [sellingprice, setSellingPrice] = useState("");
  const [editObject, setEditObject] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [ValidityModal, setValidityModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  React.useEffect(() => {
    let qParams = [{ page: 1 }, { limit: 7 }];
    getCurrentPriceListings(qParams);
  }, []);

  const customeDisableDates = (current) => {
    let date = new Date();
    return current && current < moment(date, "DD-MM-YYYY").subtract(1, "day");
  };

  const getCurrentPriceListings = async (qParams) => {
    setLoading(true);
    await getPriceList(qParams)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setZero(false);
        const array =
          res &&
          res.data.data.map((item, index) => {
            return {
              ...item,
              unique: index,
              stateSellingPrice: item.SellingPrice,
              inputStatus: false,
            };
          });
        setPriceArray(array);
        const total = res.data.data.length > 0 ? res.data.pageMeta.total : 0;
        setPageSize(total);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const latestUpdate = new Date(
    Math.max(...priceArray.map((e) => new Date(e.updatedDate)))
  );

  // console.log(moment(latestUpdate).format("DD-MMM-YYYY:h:mm a"));

  const getLatestArray = () => {
    let latestArray = priceArray.map((item) => {
      return item;
    });
    setPriceArray(latestArray);
  };

  console.log(priceArray);

  const inputHandler = (e, item) => {
    setZero(true);
    let objIndex = priceArray.findIndex((obj) => obj.unique === item.unique);
    setSellingPrice(priceArray[objIndex].stateSellingPrice);
    setUniqueId(priceArray[objIndex].uniqueId)
    priceArray[objIndex].SellingPrice = e.target.value;
    getLatestArray();
  };
  const inputActivate = (item) => {
    let objIndex = priceArray.findIndex((obj) => obj.unique === item.unique);
    priceArray[objIndex].inputStatus = true;
    getLatestArray();
  };

  const inputDeActivate = (item, type) => {
    if (type == "cancel") {
      setZero(false);
      let objIndex = priceArray.findIndex((obj) => obj.unique === item.unique);
      if (priceArray[objIndex].SellingPrice != sellingprice) {
        priceArray[objIndex].SellingPrice = sellingprice;
      }
      if (priceArray[objIndex].SellingPrice == sellingprice) {
        priceArray[objIndex].SellingPrice =
          priceArray[objIndex].stateSellingPrice;
      }
      priceArray[objIndex].inputStatus = false;
      setUniqueId("")
      getLatestArray();
    } else if (item.SellingPrice !== "" && item.SellingPrice != "0") {
      setEditObject(item);
      setValidityModal(true);
    }
  };

  const currentPriceTbody = priceArray.map((item) => {
    return (
      <tr>
        <td>
          <div className="d-flex">
            <div>
              <img alt="img" src={item.Image} style={{ width: "35px" }} />
            </div>
            <div className="ml-2">
              <label className="material_id">
                {item.MaterialId ?? "NA"}
                <br />
                <span className="material_name mt-1">
                  {item.MaterialName ?? "NA"}
                </span>
              </label>
            </div>
          </div>
        </td>
        {/* <td>
          <p className="mt-2">
            {moment(item.ItemDate).format("YYYY-MM-DD") ?? "NA"}
          </p>
        </td> */}
        <td>
          <p className="mt-2">{item.ConditionType ?? "NA"}</p>
        </td>
        <td>
          <p className="mt-2">{item.UOM ?? "NA"}</p>
        </td>
        <td>
          <p className="mt-2">{item.UnrestrictedPhysicalQty ?? "NA"}</p>
        </td>
        <td>
          <div className="d-flex purchase_input">
            <span className="customTextfield">
              &#x20B9;
              <input
                type="number"
                value={
                  item.uniqueId == uniqueId && Math.floor(Number(item.SellingPrice) * 100) / 100 == 0
                    ? ""
                    : Math.floor(Number(item.SellingPrice) * 100) / 100
                }
                style={{ width: "100px" }}
                onChange={(e) => inputHandler(e, item)}
                disabled={!item.inputStatus}
              />
            </span>
            {item.inputStatus == false ? (
              <img
                src={Edit}
                alt=""
                className="ml-2"
                onClick={() => inputActivate(item)}
              />
            ) : (
              <div className="d-flex">
                <div className="mt-2 pt-1 ml-2">
                  <img
                    src={Tick}
                    alt=""
                    onClick={() => inputDeActivate(item, "inputTick")}
                  />
                </div>
                <div className="ml-2 mt-1 edit_border">
                  <img
                    src={Cross}
                    alt=""
                    onClick={() => inputDeActivate(item, "cancel")}
                  />
                </div>
              </div>
            )}
          </div>
        </td>
        {/* <td>
          <input
            className="form-control"
            type="number"
            value={item.SellingPrice.toFixed(2)}
            style={{ width: "100px" }}
          />
        </td> */}
      </tr>
    );
  });
  const csvData = [
    [
      "Pricing Condition Type",
      "Plant",
      "Customer Group",
      "Material",
      "Sales Unit",
      "Validity Start Date",
      "Validity End Date",
      "Amount",
      "Is standalone",
    ],
  ];

  const handleChange = (e) => {
    setValue(e.target.value);
    setErrorPassword("");
  };

  const handlePriceView = () => {
    if (value != "Waycool@123") {
      setErrorPassword("Please enter correct password");
    } else {
      setPriceView(true);
      let qParams = [{ page: 1 }, { limit: 7 }];
      getCurrentPriceListings(qParams);
    }
  };

  const handleFiles = async (files) => {
    setLoading(true);
    var reader = new FileReader();
    reader.onload = function (e) {
      console.log(files);
    };
    reader.readAsText(files[0]);
    let file = files[0];
    let plant = userDetails.warehouse.plant;
    await uploadFilePrice(file, plant)
      .then((res) => {
        console.log(res, "ress");
        setLoading(false);
        setFile(res);
        message.success("File Upload Successfully");
        let qParams = [{ page: 1 }, { limit: 7 }];
        getCurrentPriceListings(qParams);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response, "erorrrrrrrr");
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const handlePageChange = (page, limit) => {
    const filters = getAllFilters();
    setPage(page);
    let qParams = [{ page }, { limit }, ...filters];
    getCurrentPriceListings(qParams);
  };

  const onChangeHandler = (e, type) => {
    if (type == "search") {
      if (e && e.target.value === "") {
        setPage(1);
        let qParams = [{ page: 1 }, { limit: 7 }];
        getCurrentPriceListings(qParams);
      }
      setSearchText(e && e.target.value);
    }
    if (type == "date") {
      const date = e ? moment(e._d).format("YYYY-MM-DD") : "";
      setDate(date);
      let qParams = [{ page: 1 }, { limit: 7 }, { date: date }];
      getCurrentPriceListings(qParams);
    }
    if (type == "modalDate") {
      const date = e ? moment(e._d).format("YYYY-MM-DD") : "";
      setModalDate(date);
    }
  };
  console.log(modalDate);
  const onSearch = () => {
    let filters = getAllFilters();
    let qParams = [{ page: 1 }, { limit: 7 }, ...filters];
    getCurrentPriceListings(qParams);
  };

  const getAllFilters = () => {
    let qParams = [];
    if (searchText != "") {
      qParams = [...qParams, { material: searchText }];
    }
    if (date != "") {
      qParams = [...qParams, { date: date }];
    }
    return qParams;
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const editCurrentPrice = async () => {
    const filters = getAllFilters();
    console.log(editObject);
    setLoading(true);
    setValidityModal(false);
    setConfirmationModal(false);
    let inputs = {
      request: {
        object_id: editObject.priceLevelId,
        valid_from: moment(new Date()).format("YYYY-MM-DD"),
        valid_to: modalDate,
        amount: editObject.SellingPrice,
        user_id: userDetails && userDetails.empId,
        user_name: userDetails && userDetails.firstName,
      },
    };
    await patchCurrentPrice(inputs)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setModalDate("");
        message.success("Price Updated Successfully!");
        let qParams = [{ page: page }, { limit: 7 }, ...filters];
        getCurrentPriceListings(qParams);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const downloadSellingPrice = async () => {
    setLoading(true);
    let qParams = [{ date: date }];
    await reportDownloadService(qParams)
      .then((res) => {
        setLoading(false);
        message.success("Report Downloaded Successfully !");
        let blob = new Blob([res.data], { type: "text/csv" });
        saveAs(blob, "Current stock and Price report.csv");
      })
      .catch((err) => {
        setLoading(false);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div id="content">
      <PrimaryHeader title={"Current Stock and Price"} />
      <Spin indicator={antIcon} spinning={loading}>
        {!priceView && (
          <div>
            <div>
              <label style={{ color: "#3A4960" }}>{"Password"}</label>
            </div>
            <div className="col-lg-3 ml-n3">
              <input
                type="password"
                class="form-control"
                placeholder="Enter password"
                aria-label="select Document"
                aria-describedby="basic-addon2"
                onChange={(e) => handleChange(e)}
                value={value}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{errorPassword}</label>
            </div>
            <div className="mt-4">
              <button
                className="btn btn-primary"
                // onClick={(file) => uploadPriceFile(file)}
                onClick={handlePriceView}
                style={{ borderRadius: "8px" }}
                disabled={!value}
              >
                Submit
              </button>
            </div>
          </div>
        )}
        {priceView && (
          <>
            <div className="d-flex">
              <div className="mt-2 col-lg-3">
                <span
                  className=""
                  style={{
                    borderLeft: "3px solid #737eed",
                    paddingLeft: "5px",
                  }}
                ></span>
                <label className="customer_label">
                  {"Customer Group - ON"}
                </label>
              </div>
              <div className="offset-lg-3 mt-2">
                <label className="customer_updates">
                  {`Last updated on ${moment(latestUpdate).format(
                    "DD-MMM-YYYY : h:mm a"
                  )}`}
                </label>
              </div>
              <div>
                <button
                  className="btn btn-outline-secondary ml-3"
                  style={{ cursor: "pointer" }}
                  onClick={downloadSellingPrice}
                  disabled={priceArray.length == 0}
                >
                  <span style={{ fontSize: "14px" }}>{"Download"}</span>
                  <img src={Download} className="ml-1" />
                </button>
              </div>
              <div>
                <ReactFileReader handleFiles={handleFiles} fileTypes={[".csv"]}>
                  <button
                    className="btn btn-outline-secondary ml-3"
                    style={{ cursor: "pointer" }}
                  // onClick={()=>uploadPriceFile()}
                  >
                    <span style={{ fontSize: "14px" }}>{"Upload"}</span>
                    <img src={Upload} className="ml-1" />
                  </button>
                </ReactFileReader>
              </div>
            </div>
            <div className="mt-2 float-right" style={{ marginRight: "120px" }}>
              <a href={DownloadPriceSample}>{"Download Sample"}</a>
            </div>
            <div className="mt-5 d-flex justify-content-between">
              <div className="ml-2 pricePicker" style={{ width: "270px" }}>
                <DatePicker
                  className="col-lg-12"
                  style={{ borderRadius: "50px !important" }}
                  onChange={(e) => onChangeHandler(e, "date")}
                />
              </div>
              <div className="mr-5">
                <Search
                  placeholder="Search by Item Name / ID"
                  enterButton
                  onChange={(e) => onChangeHandler(e, "search")}
                  value={searchText}
                  onSearch={onSearch}
                  className="priceSearch"
                />
              </div>
            </div>
            <div className="mt-5">
              <div className="table-wrapper mt-4">
                <table className="table">
                  <thead className="remove-border-top">
                    <tr>
                      <th scope="col">{"Material"}</th>
                      {/* <th scope="col">{"Validity Date"}</th> */}
                      <th scope="col">{"Condition Type"}</th>
                      <th scope="col">{"UOM"}</th>
                      <th scope="col">{"Current Stock (Unrestricted)"}</th>
                      <th scope="col">{"Selling Price"}</th>
                    </tr>
                  </thead>
                  <tbody>{currentPriceTbody}</tbody>
                </table>
                {currentPriceTbody.length === 0 && (
                  <div className="no-data">
                    <p>{"No Data Available"}</p>
                  </div>
                )}
              </div>
              <div className="pagination">
                {pageSize != 0 && (
                  <Pagination
                    className="page"
                    defaultCurrent={1}
                    current={page}
                    total={pageSize}
                    defaultPageSize={limit}
                    showSizeChanger={false}
                    onChange={(page, limit) => handlePageChange(page, limit)}
                  />
                )}
              </div>
            </div>
          </>
        )}

        {/* <p className="error_msg">{errorPassword}</p>
      <div class="input-group">
        <input
          type="text"
          class="form-control mt-4"
          placeholder={"select Document"}
          aria-label="select Document"
          aria-describedby="basic-addon2"
        />
        <div class="input-group-append">
          <ReactFileReader handleFiles={handleFiles} fileTypes={[".csv"]}>
            <button className="btn btn-primary text-white mt-4">
              Upload File
            </button>
          </ReactFileReader>
        </div>
      </div>
      <p className="error_msg">{fileError}</p>
      <div>
        <div>
          {file ? (
            <div>           
              <Modal
                width={500}
                bodyStyle={{ height: 290}}
                visible={showModal}
                onCancel={closeModal}
                // closable
                // maskClosable={true}
                footer={null}
                style={{marginLeft:"40%"}}
              >
                <div className="center_content">
                <img src={fileSuccess}/>
                <span className="success_msg">{"File Uploaded Successfully"}</span>
                </div>
                <div className="modal_content">
                <p>Total Records = {file.data.total_records}</p>
                <p>Existing Records = {file.data.existing_records}</p>
                <p>Inserted Records = {file.data.inserted_records}</p>
                </div>
                <button className="btn btn-primary text-white float-right" onClick={closeModal}>Ok</button>
              </Modal>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="file mt-2">
          {uploadFile ? (
            <div>
              <p className="file_name">{uploadFile[0].name}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div> */}

        {/* <div className="bottom-text">
          <p>{"Download the sample csv"}</p>
          <u>
            {" "}
            <CSVLink data={csvData} filename={"sample_pricing.csv"}>
              sample CSV
            </CSVLink>
          </u>
        </div> */}
        {ValidityModal && (
          <Modal
            visible={true}
            width={400}
            bodyStyle={{ height: 290 }}
            onCancel={() => (setValidityModal(false), setModalDate(""))}
            maskClosable={false}
            footer={null}
          >
            <div
              className="d-flex"
              style={{ flexDirection: "column", alignItems: "center" }}
            >
              <div className="mt-5">
                <label className="validity_label">{"Enter the validity"}</label>
              </div>
              <div className="mt-4 pricePicker" style={{ width: "350px" }}>
                <DatePicker
                  className="col-lg-12"
                  onChange={(e) => onChangeHandler(e, "modalDate")}
                  disabledDate={customeDisableDates}
                />
              </div>
              <div className="mt-4 mb-4 offset-lg-8">
                <button
                  className="btn btn-primary"
                  onClick={() => setConfirmationModal(true)}
                  disabled={!modalDate}
                >
                  {"Next"}
                </button>
              </div>
            </div>
          </Modal>
        )}
        {confirmationModal && (
          <Modal
            visible={true}
            width={400}
            bodyStyle={{ height: 290 }}
            onCancel={() => setConfirmationModal(false)}
            maskClosable={false}
            footer={null}
          >
            <div
              className="d-flex"
              style={{ flexDirection: "column", alignItems: "center" }}
            >
              <div className="mt-5">
                <label className="validity_label">{"Are You Sure?"}</label>
              </div>
              <div className="mt-4 price_updates">
                <label>
                  {"This will change the item selling"}
                  <br />
                  <span>{"price permanently"}</span>
                </label>
              </div>
              <div className="mt-4 d-flex">
                <div>
                  <button
                    className="btn btn-outline-primary mr-5"
                    onClick={() => setConfirmationModal(false)}
                  >
                    {"No"}
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-primary ml-5"
                    onClick={editCurrentPrice}
                  >
                    {"Yes"}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Spin>
    </div>
  );
}

export default PricingUpload;
