import React, { Component } from "react";
import defaultPic from "../../images/default-pic.jpg";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
class ShowList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      check: false,
    };
  }

  render() {
    let {
      dayName,
      arr,
      deleteCustomer,
      summery,
      showOptionsModal,
      handleChangeChk,
      displayMultiselectorStatus,
      dragging,
    } = this.props;
    let day = arr.filter((item) => item.day == dayName);
    let customers = day[0].customers;
    let weekDayId = summery.id;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      //  droppable
      <Droppable droppableId={weekDayId}
        isDropDisabled={
          customers.length > 0 ? false : true
        }
      >
        {(provided, i) => (
          <Spin indicator={antIcon} spinning={dragging}>
            <div
              className="col"
              ref={provided.innerRef}
              {...provided.droppableProps}
              key={i}
            >
              {customers.length > 0 &&
                customers.map((customer, i) => {
                  return (
                    // conditionally add class orange-backgroud
                    // draggable
                    <Draggable
                      draggableId={customer._id}
                      index={i}
                      isDragDisabled={
                        displayMultiselectorStatus == true ? true : false
                      }
                      key={customer._id}
                    >
                      {(provided, i) => (
                        <div
                          className="customerListDiv"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          key={customer._id}
                        >
                          <li
                            className={showOptionsModal ? "List" : "list"}
                            key={i}
                          >
                            {/* conditionally display img and checkbox */}
                            {showOptionsModal == true ? (
                              <input
                                type="checkbox"
                                defaultChecked={this.state.check}
                                onChange={() => handleChangeChk(customer)}
                                className="image check_box"
                              />
                            ) : (
                              <img
                                src={defaultPic}
                                alt="image"
                                className="image"
                              />
                            )}

                            <p className="ID" title={customer.customer && customer.customer.name}>
                              {customer.customer &&
                                customer.customer.goFrugalId}
                              <br />
                              <span title={customer.customer && customer.customer.name}>
                                {customer.customer && customer.customer.name}
                              </span>
                            </p>
                            <span
                              className="del-customer"
                              onClick={() =>
                                deleteCustomer(
                                  customer._id,
                                  dayName,
                                  customers,
                                  weekDayId,
                                  "weekView"
                                )
                              }
                            >
                              &#10006;
                            </span>
                          </li>
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {customers.length == 0 && (
                <p className="noCustomer">Add Customer</p>
              )}

              {provided.placeholder}
            </div>
          </Spin>
        )}
      </Droppable>
    );
  }
}
export default ShowList;
