import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import "./gofrugalSync.scss";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  getAPIList,
  getList,
  postValues,
} from "../../services/gofrugalSyncServices/gofrugalServices";
import { useSelector } from "react-redux";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { handleErrorResponse } from "../../utils/Request";
import PrimaryHeader from "../header/PrimaryHeader";

function GofrugalSync() {
  const [APIs, setAPIs] = useState([]);
  const [selectedAPI, setSelectedAPI] = useState("");
  const [searchURL, setSearchURL] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [loading, setloading] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const antIcon = <LoadingOutlined className="loadingIcon" spin />;
  // const [page, setPage] = useState(1);
  const [clearData, setClearData] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAPIList();
        response.data.data.configValue.map((value) => {
          if (value === "priceLevel" || value === "customer") {
            setAPIs((APIs) => [
              ...APIs,
              {
                value: value,
                label: value === "priceLevel" ? "Price Level" : "Customer",
              },
            ]);
          } else {
            setAPIs((APIs) => [
              ...APIs,
              {
                value: value,
                label: value.charAt(0).toUpperCase() + value.slice(1),
                disabled: "yes",
              },
            ]);
          }
        });
        return response;
      } catch (error) {
        const { errorMessage, errors } = handleErrorResponse(error);
        message.error(errorMessage);
      }
    }

    APIs.length == 0 && fetchData();
  }, []);

  const handleSelectedAPI = async (value) => {
    setClearData(false);
    if (userDetails != null && location.length == 0) {
      userDetails.region.map((region) => {
        setLocation((location) => [
          ...location,
          {
            value: region,
            label: region.charAt(0).toUpperCase() + region.slice(1),
          },
        ]);
      });
    }
    setSearchValue("");
    setSelectedAPI(value);
    if (value.value === "priceLevel") {
      setSearchURL("api/v1/utils/price-level/minified/list");
    } else if (value.value === "customer") {
      setSearchURL("api/v1/customer/minified/list");
    } else {
      setSearchURL("");
    }
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    // if (clearData) {
    //   return { options: [] };
    // } else
    if (selectedAPI) {
      // let page = 1;
      let qParams = [{ page: page }, { search: search }];
      try {
        let data = await getList(qParams, searchURL).then((res) => {
          return {
            options:
              res.data.data.results &&
              res.data.data.results.map((item) => {
                return {
                  key: item._id,
                  value: item._id,
                  label:
                    selectedAPI.value === "priceLevel"
                      ? item.id != undefined
                        ? `${item.id} | ${item.name}`
                        : `${item.name}`
                      : item.goFrugalId != undefined
                      ? `${item.goFrugalId} | ${item.name}`
                      : `${item.name}`,
                };
              }),
            totalPage:
              parseInt(res.data.data.pageMeta.total || 0) /
              parseInt(res.data.data.pageMeta.pageSize || 0),
          };
        });
        console.log(data.options, "options");
        // setPage(page + 1);
        if (data.options.length === 0) {
          return {
            options: [
              { key: search, value: search, label: `Create ${search}` },
            ],
            additional: {
              page: page + 1,
            },
            hasMore: data.totalPage > page ? true : false,
          };
        } else {
          // page = page + 1;
          console.log(data, "data");
          return {
            options: data.options,
            additional: {
              page: page + 1,
            },
            hasMore: data.totalPage > page ? true : false,
          };
        }
      } catch (error) {
        const { errorMessage, errors } = handleErrorResponse(error);
        message.error(errorMessage);
      }
      console.log(page, "page");
    } else {
      return { options: [] };
    }
  };

  const handleSearchChange = (value) => {
    console.log(value.label, "value");
    if (value.label.includes("Create")) {
      const modifiedString = value.label.slice(7);
      setSearchValue({ ...value, label: modifiedString });
    } else {
      setSearchValue(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const body = {
      refId: searchValue.key,
      api: selectedAPI.value,
      region: selectedLocation.value,
      // region: "bangalore",
    };
    try {
      await postValues(body);
      setloading(false);
      message.success("Sync successful");
    } catch (error) {
      const { errorMessage, errors } = handleErrorResponse(error);
      setloading(false);
      message.error(errorMessage);
    }
    setSelectedAPI("");
    setSelectedLocation("");
    setSearchValue("");
    // await setClearData(true);
    // await loadOptions("");
  };

  return (
    <div id="content" className="gofrugal-container">
      <PrimaryHeader title="GoFrugal Sync" />
      <Spin indicator={antIcon} spinning={loading}>
        <div className="gofrugal-dropdown-container">
          <div className="gofrugal-dropdown-content">
            <p className="gofrugal-dropdown-name">Select option :</p>
            <Select
              value={selectedAPI}
              onChange={handleSelectedAPI}
              placeholder="Choose an option"
              options={APIs}
              isOptionDisabled={(option) => option.disabled === "yes"}
              isSearchable={false}
            />
            {/* <p>{dropdownError?.name}</p> */}
          </div>
          <div className="gofrugal-dropdown-content">
            <p className="gofrugal-dropdown-name">Location :</p>
            <Select
              value={selectedLocation}
              placeholder="Choose a location"
              options={location}
              onChange={(value) => setSelectedLocation(value)}
              isSearchable={false}
              required
            />
          </div>
          <div className="gofrugal-dropdown-content">
            <p className="gofrugal-dropdown-name">Select/Search Value :</p>
            <div>
              <AsyncPaginate
                key={selectedAPI?.value}
                value={searchValue}
                placeholder={"Search or Add values"}
                loadOptions={loadOptions}
                onChange={handleSearchChange}
                isSearchable={true}
                additional={{
                  page: 1,
                }}
              />
              {selectedAPI == "" && (
                <p className="required-text">
                  Select option to see search values
                </p>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={!(selectedAPI && searchValue && selectedLocation)}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default GofrugalSync;
