import {
  getListOfExeptionalCustomerShipping,
  getMinOrderValueMangShipping,
  getListOfExeptionalCustomerTable,
} from "../services/settings/manageShippingservice";
import generateActionTypes from "../utils/generateActionTypes";
import { handleErrorResponse } from "../utils/Request";

export const actionTypes = generateActionTypes(
  "MINIMUM_ORDER_VALUE",
  "MINIMUM_SHIPPING_CHARGES",
  "SHIPPING_ORDER_AMOUNT",
  "GET_CUSTOMER_LIST",
  "UPDATE_ORDER_AMOUNT_AND_SHIPPING",
  "MANAGE_SHIPPING_LOADING",
  "LOADING",
  "STOP_LOADING",
  "UPDATE_MINIMUM_ORDER_VALUE",
  "EXCEPTIONAL_UPDATE_ORDER_AMOUNT_AND_SHIPPING",
  "GET_LIST_OF_EXEPTIONAL_CUSTOMER_SHIPPING",
  "GET_LIST_0F_EXCEPTIONAL_CUSTOMER_TABLE"
);

// import generateActionTypes from "../utils/generateActionTypes";

export function startLoading() {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.LOADING,
    });
  };
}

export function stopLoading(loadType) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.STOP_LOADING,
      load: loadType,
    });
  };
}

export function getMinimumOrdValue() {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.MINIMUM_ORDER_VALUE,
      });
      const res = await getMinOrderValueMangShipping();
      const resData = res?.data;

      if (resData.status === 200) {
        const { minimumOrder } = resData.data;
        dispatch({
          type: actionTypes.MINIMUM_ORDER_VALUE,
          data: minimumOrder,
        });
      }
      dispatch({
        type: actionTypes.STOP_LOADING,
        load: actionTypes.MINIMUM_ORDER_VALUE,
      });
    } catch (error) {}
  };
}

export function getShippingCharges() {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.MINIMUM_SHIPPING_CHARGES,
      });
      const res = await getMinOrderValueMangShipping();

      const resData = res?.data;

      if (resData.status === 200) {
        const { minimumShippingCharges } = resData.data;

        dispatch({
          type: actionTypes.MINIMUM_SHIPPING_CHARGES,
          data: minimumShippingCharges,
        });
      }

      dispatch({
        type: actionTypes.STOP_LOADING,
        load: actionTypes.MINIMUM_SHIPPING_CHARGES,
      });
    } catch (error) {}
  };
}

export function getListOfExceptionalCustomers() {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.GET_LIST_OF_EXEPTIONAL_CUSTOMER_SHIPPING,
      });
      const res = await getListOfExeptionalCustomerShipping();

      const resData = res?.data;
      if (resData.status === 200) {
        const { data } = resData;
        dispatch({
          type: actionTypes.GET_LIST_OF_EXEPTIONAL_CUSTOMER_SHIPPING,
          data: data,
        });
      }
    } catch (error) {}
  };
}

export function getCustomerList(data) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.GET_CUSTOMER_LIST,
      data,
    });
  };
}
export function updateOrderamountandshippingcharge(data) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.UPDATE_ORDER_AMOUNT_AND_SHIPPING,
      data,
    });
  };
}
// export function exceptionalupdateOrderamountandshippingcharge(data) {
//   return (dispatch) => {
//     return dispatch({
//       type: actionTypes.EXCEPTIONAL_UPDATE_ORDER_AMOUNT_AND_SHIPPING,
//       data,
//     });
//   };
// }

export function getShippingChargesValue(data) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.MINIMUM_SHIPPING_CHARGES,
      data,
    });
  };
}

export function getShippingOrderAmount(data) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SHIPPING_ORDER_AMOUNT,
      data,
    });
  };
}
export function getListOfExceptionalCustomeTaleData() {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.GET_LIST_0F_EXCEPTIONAL_CUSTOMER_TABLE,
      });
      const res = await getListOfExeptionalCustomerTable();
      const resData = res?.data;
      if (resData.status === 200) {
        const { data } = resData;
        const arrangedData = data.map((item) => ({
          customerName: item.customerDetails.name,
          customerId: item.settings.exceptionalShippingCustomers.customerId,
          ShippingOrderAmt:
            item.settings.exceptionalShippingCustomers.ShippingOrderAmt,
          shippingCharge:
            item.settings.exceptionalShippingCustomers.shippingCharge,
          // excludedDays: item.settings.exceptionalShippingCustomers.excludedDays,
        }));
        dispatch({
          type: actionTypes.GET_LIST_0F_EXCEPTIONAL_CUSTOMER_TABLE,
          data: arrangedData,
        });
      }
    } catch (err) {
      console.log(err, "error");
      const { errorMessage, errors } = handleErrorResponse(err);
    }
  };
}
