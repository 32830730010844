import React, { Component } from "react";
import profilePic from "../../../src/images/default-pic.jpg";
import "./userProfile.scss";
import logout from "../../images/log-out.svg";
import cross from "../../images/cross.svg";
import { userDetails } from "../../services/userServices/user";
// loader
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// err msg
import message from "antd/lib/message";
import { handleErrorResponse } from "../../utils/Request";
import { logoutFunction } from "../../utils/Auth";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { getThumbnail } from "../../utils/generateImageUrl";
import { Modal, Button } from "antd";
import { motion } from "framer-motion";
class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileDetailsModalState: false,
      details: [],
      loading: false,
      variants: {
        before: { opacity: 0, x: -100 },
        after: { opacity: 1, x: "auto" },
        transition: {
          duration: 3,
        },
      },
    };
  }
  showprofileDetailsModal = () => {
    console.log("clicked");
    this.setState({
      loading: false,
      profileDetailsModalState: !this.state.profileDetailsModalState,
    });
  };
  componentDidMount = async () => {
    this.setState({ loading: true });
    //  call api
    // await userDetails()
    //   .then(async (res) => {
    //     this.setState({
    //       details: res.data.data,
    //       profilePicture: res.data.data.profilePicture
    //         ? await getThumbnail(res.data.data.profilePicture)
    //         : "",
    //       loading: false,
    //     });
    //   })
    //   .catch((err) => {
    //     this.setState({ loading: false });
    //     const { errorMessage } = handleErrorResponse(err);
    //     message.error(errorMessage);
    //   });
  };
  render() {
    let color = {
      color: "rgb(80, 120, 242)",
    };
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let { profileDetailsModalState, details, loading, profilePicture } =
      this.state;
    return (
      <>
        <img
          src={profilePicture ? profilePicture : profilePic}
          alt="pro-pic"
          className="profile-pic-logo"
          title="profile"
          onClick={this.showprofileDetailsModal}
        />
        {profileDetailsModalState && details != [] && (
          // <Modal
          //   className="my-usermodal"
          //   title="User Details"
          //   visible={this.state.profileDetailsModalState}
          //   onCancel={this.showprofileDetailsModal}
          //   footer={[
          //     <Button
          //       key="submit"
          //       type="primary"
          //       onClick={logoutFunction}
          //       className="br-2"
          //     >
          //       Logout
          //     </Button>,
          //   ]}
          // >
          <motion.div
            initial={{ y: -250 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.1, type: "spring", stiffness: 60 }}
            className="user-details"
          >
            <img
              src={cross}
              className="float-right mt-n3"
              onClick={this.showprofileDetailsModal}
            />
            <div className="d-flex align-items-center mb-2 mt-2">
              <div className="name mr-5">
                {/* {this.props.profileDetails && this.props.profileDetails.firstName +
                " " +
                this.props.profileDetails && this.props.profileDetails.lastName +
                " (" +
                this.props.profileDetails.empId +
                ")"} */}
                {this.props.profileDetails &&
                  this.props.profileDetails.firstName +
                    " " +
                    this.props.profileDetails.lastName +
                    " (" +
                    this.props.profileDetails.empId +
                    ")"}
                <br />
                {this.props.profileDetails &&
                  this.props.profileDetails.designation}
              </div>
              <img
                alt="profile"
                src={profilePic}
                className="user-details-image"
              />
            </div>
            <div className="row mb-0">
              <div className="col col-md-12 capitalise">
                Warehouse -{" "}
                {this.props.profileDetails &&
                  this.props.profileDetails.warehouse &&
                  this.props.profileDetails.warehouse.name}
              </div>
            </div>
            <div className="row mb-0">
              <div className="col col-md-12 capitalise">{`Region - ${
                this.props.profileDetails && this.props.profileDetails.region
              }`}</div>
            </div>
            <div className="d-flex justify-content-end">
              {/* <Button
                key="submit"
                type="primary"
                onClick={logoutFunction}
                className="br-2"
              >
                Logout
              </Button> */}
              <button className="btn btn-primary btn-sm"   onClick={logoutFunction}>Logout</button>
            </div>
          </motion.div>
          // </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileDetails: state.userDetails,
  };
};

export default connect(mapStateToProps, null)(UserProfile);
