import { Button, Modal, Spin } from "antd";
import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import "./SalesmanModal.scss";

const SalesmanModal = (props) => {
  const {
    isOpen,
    isClose,
    isModalOpen,
    isModalClose,
    handleGenerateOtp,
    handleDeleteSalesman,
    otpError,
    salesmanName,
    isLoading,
  } = props;

  const [otpValue, setOtpValue] = useState("");
  const [otpComplete, setOtpComplete] = useState(false);

  //Handle Change
  const handleOtpChange = (otp) => {
    setOtpValue(otp);
    setOtpComplete(otp.length === 4);
  };

  //Handle submit
  const handleSubmit = () => {
    handleDeleteSalesman(otpValue);
  };

  const otpModalClose = () => {
    setOtpValue("");
    setOtpComplete(false);
    isClose();
  };
  useEffect(() => {
    if (!isOpen) {
      setOtpValue("");
    }
  }, [isOpen]);

  return (
    <>
      {/* Generation OTP Modal */}
      <div>
        {isLoading && (
          <div
            className="text-center pt-4"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
          >
            <Spin size="large" />
          </div>
        )}
        {!isLoading && (
          <Modal
            style={{ zIndex: 1 }}
            visible={isModalOpen}
            className="salesman"
            onCancel={isModalClose}
            closable
            maskClosable={true}
            footer={null}
          >
            <div className="pt-4">
              <div className="fs-5 blue-border font-weight-bold">
                <span className="pl-2">Attention</span>
              </div>
              <p className="pt-3">
                You are about to permanently delete the{" "}
                <strong className="pl-2">{salesmanName}</strong> salesman.
                <br />
                <br />
                Are you sure?
              </p>
              <div className="d-flex justify-content-between pt-2 pr-3">
                <Button size="large" onClick={isModalClose}>
                  No
                </Button>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => handleGenerateOtp()}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </div>

      {/* Delete Salesman Modal  */}
      <div>
        <Modal
          id
          visible={isOpen}
          className="otpmodal"
          onCancel={otpModalClose}
          closable
          maskClosable={true}
          footer={null}
        >
          <div className="pt-4">
            <div className="fs-5 blue-border font-weight-bold">
              <span className="pl-2">Authentication required</span>
            </div>
            <div className="pb-3">
              <p className="pt-4">
                Deletion code has been sent.
                <br />
              </p>
              <span className="pt-2 pb-2">
                Please enter the code to delete the salesman.
              </span>
            </div>
            <OtpInput
              inputStyle={{
                fontSize: "24px",
                width: "74px",
                height: "36px",
                margin: "4px",
                borderTop: "0px",
                borderLeft: "0px",
                borderRight: "0px",
                outline: "none",
                color: "black",
              }}
              focusStyle={{
                width: "74px",
                height: "36px",
                borderBottom: "2px solid #5078F2",
                borderTop: "0px",
                borderLeft: "0px",
                borderRight: "0px",
                outline: "none",
              }}
              value={otpValue}
              onChange={handleOtpChange}
              numInputs={4}
              isInputNum={true}
              containerStyle={{ margin: "1px auto", padding: "1px" }}
            />
            {otpError && (
              <p style={{ color: "red", fontSize: "12px", marginTop: "8px" }}>
                {otpError}
              </p>
            )}
            <div className="pt-4 ">
              <button
                style={{ width: "90%" }}
                className={`btn ${
                  otpValue.length === 4 ? "btn-primary" : "btn-secondary"
                }`}
                onClick={handleSubmit}
                disabled={otpValue.length !== 4}
              >
                Delete Salesman
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SalesmanModal;
