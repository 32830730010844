export const APPROVED_DATA = "APPROVED_DATA";
export const PENDING_DATA = "PENDING_DATA";
export const REJECTED_DATA = "REJECTED_DATA";
export const TARGET_SETTING_DETAILS = "TARGET_SETTING_DETAILS";

export const addData = (data, type, loadData) => {
  return {
    type: type,
    payload: data,
    loadData: loadData,
  };
};

export const addTargetSettingDetails = (data) => {
  return {
    type: TARGET_SETTING_DETAILS,
    payload: data,
  };
};

// export const addRejectedData = (data) => {
//   return {
//     type: REJECTED_DATA,
//     payload: data,
//   };
// };
