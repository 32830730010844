import React, { useRef, useState, useEffect } from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import bell from "../../images/bell.svg";
import download from "../../images/download-icon.svg";
// import { UserOutlined } from "@ant-design/icons";
import { Avatar, DatePicker, Modal } from "antd";
import SuccessModal from "../modal/successModal";
import UserProfile from "../nav-profile/userProfile";
import { handleErrorResponse } from "../../utils/Request";
import {
  addComment,
  downloadCsv,
  getTargetSettingDetails,
} from "../../services/targetSettingServices/targetSetting";
import {
  addData,
  APPROVED_DATA,
  REJECTED_DATA,
  PENDING_DATA,
  addTargetSettingDetails,
} from "../../action/targetSetting";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import { message, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import PrimaryHeader from "../header/PrimaryHeader";

const { RangePicker } = DatePicker;

const statusOptions = [
  { value: "approve", label: "Approved" },
  { value: "reject", label: "Rejected" },
];

function TargetSettingDetails() {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const commentsRef = useRef();
  const statusRef = useRef();
  const contentRef = useRef();
  const [errors, seterrors] = useState({
    status: "",
    comment: "",
  });
  const [status, setStatus] = useState("");
  const targetSettingData = useSelector((state) => state.targetSettingData);
  const targetSettingDetails = useSelector(
    (state) => state.targetSettingDetails
  );
  const [targetDetails, setTargetDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [targetMessage, setTargetMessage] = useState("");
  const antIcon = <LoadingOutlined className="loadIcon" spin />;
  const dispatch = useDispatch();
  const { id, name } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getTargetSettingDetails(id);
      setTargetDetails(result.data.data);
      setLoading(false);
    } catch (error) {
      const { errorMessage, errors } = handleErrorResponse(error);
      setLoading(false);
      message.error(errorMessage);
    }
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      const result = await downloadCsv(targetDetails?._id);
      let blob = new Blob([result.data], { type: "text/csv" });
      saveAs(blob, "target.csv");
      setLoading(false);
    } catch (error) {
      const { errorMessage, errors } = handleErrorResponse(error);
      setLoading(false);
      message.error(errorMessage);
    }
  };

  const handleStatusChange = (status) => {
    seterrors({ ...errors, status: "" });
    setStatus(status);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (status === "") {
      statusRef.current.focus();
      seterrors({ ...errors, status: "Please select an option" });
    } else if (status?.label === "Approved") {
      try {
        console.log("approved");
        setLoading(true);
        await addComment(targetDetails?._id, status.value, {});
        setLoading(false);
        setTargetMessage("Target setting has been approved successfully");
        setShowSuccessModal(true);
        dispatch(addData(...targetSettingData, APPROVED_DATA, true));
        dispatch(addData(...targetSettingData, PENDING_DATA, true));
      } catch (error) {
        const { errorMessage, errors } = handleErrorResponse(error);
        setLoading(false);
        message.error(errorMessage);
      }
    } else {
      const { comments } = e.target.elements;
      if (comments.value.length === 0) {
        commentsRef.current.focus();
        seterrors({ ...errors, comment: "Please enter a comment" });
      } else if (comments.value.length < 40) {
        commentsRef.current.focus();
        seterrors({
          ...errors,
          comment: "Comment should be atleast 40 characters in length",
        });
      } else {
        seterrors({
          ...errors,
          comment: "",
        });
        setLoading(true);
        try {
          const input = {
            reason: comments.value,
          };
          await addComment(targetDetails?._id, status.value, input);
          setLoading(false);
          setTargetMessage("Target setting has been rejected");
          setShowSuccessModal(true);
          dispatch(addData(...targetSettingData, REJECTED_DATA, true));
          dispatch(addData(...targetSettingData, PENDING_DATA, true));
        } catch (error) {
          console.log("in catch");
          const { errorMessage, errors } = handleErrorResponse(error);
          setLoading(false);
          message.error(errorMessage);
        }
      }
    }
  };

  return (
    <>
      <div id="content" className="target-setting-container" ref={contentRef}>
        <PrimaryHeader title="Target Setting Details" />
        <Spin indicator={antIcon} spinning={loading}>
          <form onSubmit={handleSubmit} className="target-setting-form">
            <div className="form-content">
              <p>
                Distributor <span className="asterisk-req">*</span>
              </p>
              <Select
                placeholder={targetDetails?.distributorDetails?.distributorName}
                isDisabled={true}
              />
              <div>
                <span className="target-error-message">
                  {errors.distributor}
                </span>
              </div>
            </div>
            <div className="form-content">
              <p className="form-content-title">
                1. Customer Based Target Setting
              </p>
              <p>
                Customer <span className="asterisk-req">*</span>
              </p>
              <Select
                placeholder={targetDetails?.customerType}
                isDisabled={true}
              />
              <div>
                <span className="target-error-message">{errors.customer}</span>
              </div>
            </div>
            <div className="form-content">
              <p className="form-content-title">
                2. Product Based Target Setting
              </p>
              <p>
                Product <span className="asterisk-req">*</span>
              </p>
              <Select
                placeholder={targetDetails?.productTypeId}
                isDisabled={true}
              />
              <div>
                <span className="target-error-message">{errors.product}</span>
              </div>
            </div>
            <div className="form-content">
              <p className="form-content-title">3. Time Based Target Setting</p>
              <p>
                Time <span className="asterisk-req">*</span>
              </p>
              <Select
                placeholder={targetDetails?.timeBasedTarget}
                isDisabled={true}
              />
              <RangePicker
                placeholder={
                  targetDetails?.toDate != undefined &&
                  targetDetails?.fromDate != undefined
                    ? [
                        targetDetails?.toDate.substring(0, 9),
                        targetDetails?.toDate.substring(0, 9),
                      ]
                    : [targetDetails?.month, targetDetails?.month]
                }
                disabled
              />
            </div>
            <div className="form-content">
              <p className="form-content-title">Documents</p>
              <img
                className="mr-4 cursor"
                onClick={handleDownload}
                src={download}
                alt="download"
                download
              />
              <span onClick={handleDownload} className="blue-link">
                Target-setting.csv
              </span>
            </div>
            <div className="form-content">
              <p className="form-content-title-borderless">Proposal Status</p>
              {name === "pending" ? (
                <Select
                  name="status"
                  ref={statusRef}
                  options={statusOptions}
                  onChange={handleStatusChange}
                />
              ) : (
                <Select placeholder={name} isDisabled={true} />
              )}

              <div>
                <span className="target-error-message">{errors.status}</span>
              </div>
            </div>
            {name != "approved" && status?.label != "Approved" && (
              <div className="form-content">
                <p className="form-content-title-borderless">
                  Comments by Distributor
                </p>
                {name === "pending" ? (
                  <>
                    <textarea
                      ref={commentsRef}
                      name="comments"
                      cols="40"
                      rows="5"
                    ></textarea>
                    <div>
                      <span className="target-error-message">
                        {errors.comment}
                      </span>
                    </div>
                  </>
                ) : (
                  <textarea name="comments" cols="40" rows="5" readOnly>
                    {targetSettingDetails?.reasonToReject}
                  </textarea>
                )}
              </div>
            )}
            {name === "pending" && (
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            )}
          </form>
          <Modal
            centered
            visible={showSuccessModal}
            onCancel={closeSuccessModal}
            footer={null}
          >
            <SuccessModal
              message={targetMessage}
              name={status?.label === "Approved" ? "approved" : "rejected"}
            />
          </Modal>
        </Spin>
      </div>
    </>
  );
}

export default TargetSettingDetails;
