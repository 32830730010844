import { combineReducers } from "redux";
import test from "./test";
import user from "./user";
import team from "./team";
import customers from "./customers";
import beatPlans from "./beatPlans";
import { userDetails } from "./userDetails";
import { targetSettingData, targetSettingDetails } from "./targetSetting";
import poGeneration from "./poGeneration";
import cod from "./cod";
import manageOrder from "./manageOrder";
import { serachOrdersReducer } from "./SearchOrderReducer";
// import { productReducer } from "./productReducer";
import { productReducer } from "./productReducer";
// import { stockandmoqreducer } from "./Stockandmoqreducer";
import { manageShippingReducer } from "./manageShipping";
import { itemsListReducer } from "./itemsListReducer";
import { paymentDetailsReducer } from "./paymentDetailsReducer";
import { itemCart } from "./itemCart";
import { individualOrderDetailsReducer } from "./individualOrderDetailsReducer";
import { ordersHistoryReducer } from "./orderHistory";
import { salesOverviewReducer } from "./salesDashboard";
import { balanceCreationReducer } from "./balanceCreationReducer";
import { salesReturnReducer } from "./salesReturnReducer";
import { teamMappingReducer } from "./teamMappingReducer";
import { deliveryDatesReducer } from "./deliveryDatesReducer";

export default combineReducers({
  test,
  user,
  team,
  customers,
  beatPlans,
  userDetails,
  targetSettingData,
  targetSettingDetails,
  poGeneration,
  cod,
  productReducer,
  // stockandmoqreducer,
  ordersHistoryReducer,
  manageShippingReducer,
  manageOrder,
  serachOrdersReducer,
  itemsListReducer,
  individualOrderDetailsReducer,
  itemCart,
  paymentDetailsReducer,
  salesOverviewReducer,
  balanceCreationReducer,
  salesReturnReducer,
  teamMappingReducer,
  deliveryDatesReducer
});
