import React, { Component } from "react";
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { AsyncPaginate } from "react-select-async-paginate";
import add from "../../images/blue-plus.svg";
import trash from "../../images/trash.svg";
import { LoadingOutlined } from "@ant-design/icons";
import message from "antd/lib/message";
import { formatMoney } from "../../utils/common";
import {
  checkForChar,
  disabledDate,
  compareArrayFunction,
  disabledEndDate,
  disabledPastDate,
} from "../../utils/common";
import {
  getVendorList,
  getVendorItemList,
  createPO,
  getDocTypesList,
  createPOIDGeneration,
} from "../../services/poGenerationServices/poGeneration";
import { Spin, Pagination, Tabs } from "antd";
import moment from "moment";
import { handleErrorResponse } from "../../utils/Request";
import ShowSuccessModal from "../../components/modal/showSuccessModal";
import Select from "react-select";
import successicon from "../../images/success-icon.svg";
const UOMOptions = [{ value: 1, label: "PAK" }];
const dateFormat = "DD/MM/YYYY";
class ExternalPO extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      loading: false,
      selectedDate: null,
      selectedVendor: null,
      totalAmount: 0,
      page: 1,
      displaySuccessModal: false,
      selectedDocType: "",
      poId: "",
    };
  }
  addItem = () => {
    let cardItemStatus = this.emptyItemCard();
    if (this.state.selectedVendor && cardItemStatus) {
      console.log("addItem is called");
      let id = this.state.items.length + 1;
      let newItemObj = {
        id,
        item: null,
        material_no: "",
        material_description: "",
        uom: "",
        unitPrice: "",
        quantity: "",
        net_price: 0,

        delivery_date: "",
      };

      this.setState(
        {
          items: [...this.state.items, newItemObj],
        },
        () => {
          console.log("arr", this.state.items);
        }
      );
    } else if (!this.state.selectedVendor) {
      message.warning("Select vendor to add items");
    } else if (!cardItemStatus) {
      message.warning(
        "Please complete the following item card details before adding a new item card ."
      );
    }
  };
  emptyItemCard = () => {
    if (this.state.items === []) {
      return true;
    } else {
      let emptyItemCard =
        this.state.items &&
        this.state.items.filter(
          (x) => x.itemId === "" || x.unitPrice === "" || x.quantity === ""
        );
      let flag = emptyItemCard.length > 0 ? false : true;
      return flag;
    }
  };
  deleteItem = (id, item) => {
    console.log("id", id, "item", item);
    let filteredItemArr = this.state.items.filter((x) => x.id != id);
    let arrangedById = filteredItemArr.map((item, i) => {
      return { ...item, id: i + 1 };
    });
    this.setState(
      {
        items: arrangedById,
      },
      () => {
        this.updateTotalAmount();
      }
    );
  };
  handleChange = (e, i) => {
    //let value = Number(+e.target.value.replace(/[^0-9]/g, ""));
    let itemsArr = this.state.items.map((x) => {
      if (+x.id === i) {
        return {
          ...x,
          [e.target.name]: e.target.value,
        };
      }
      return x;
    });

    this.setState(
      {
        items: itemsArr,
      },
      () => {
        this.updateTotalAmount();
      }
    );
  };
  updateTotalAmount = () => {
    let items = this.state.items;
    console.log("items", items);
    let total = 0;
    items.length != 0 &&
      items.forEach((x) => {
        console.log("x", x);
        total = total + x.quantity * x.unitPrice;
      });
    console.log("total is", total);
    this.setState(
      {
        totalAmount: total,
      },
      () => {
        console.log("updated total amount in state", this.state.totalAmount);
      }
    );
  };

  loadOptions = async (search, loadedOptions, { page }) => {
    let qParams = [{ page }, { search }];

    let data = await getVendorList(qParams)
      .then((res) => {
        return {
          options:
            res.data.data.results &&
            res.data.data.results.map((item) => {
              return {
                key: item.vendorName,
                value: item.vendorId,
                label: item.vendorId,
                vendorDetails: item.vendorDetails,
              };
            }),
          totalPage:
            parseInt(res.data.data.pageMeta.total || 0) /
            parseInt(res.data.data.pageMeta.pageSize || 0),
        };
      })
      .catch((err) => {
        console.log("err", err);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
    console.log("**Options**", data.options);
    return {
      options: data.options,
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  loadDocTypesOptions = async (search, loadedOptions, { page }) => {
    let qParams = [{ page }, { search }];

    let data = await getDocTypesList(qParams)
      .then((res) => {
        return {
          options:
            res.data.data.results &&
            res.data.data.results.map((item) => {
              return {
                key: item.documentType,
                value: item.documentType,
                label: item.documentType,
              };
            }),
          totalPage:
            parseInt(res.data.data.pageMeta.total || 0) /
            parseInt(res.data.data.pageMeta.pageSize || 0),
        };
      })
      .catch((err) => {
        console.log("err", err);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
    return {
      options: data.options,
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  selectOption = (e) => {
    console.log(e);
    if (e != null) {
      this.setState(
        {
          selectedVendor: e,
          items: [],
        },
        () => {
          console.log(
            "selectOption is called,selectedVendor is",
            this.state.selectedVendor
          );
        }
      );
    }
  };

  selectDocTypeOption = (e) => {
    if (e != null) {
      this.setState({
        selectedDocType: e,
      });
    }
  };

  changeDate = (date, type) => {
    if (date != null) {
      this.setState(
        {
          [type]: moment(date._d).format("YYYY-MM-DD"),
        },
        () => {
          console.log("state", this.state);
        }
      );
    } else if (date === null) {
      this.setState({
        [type]: null,
      });
    }
  };
  loadItemOption = async (search, loadedOptions, { page }) => {
    let qParams = [{ page }, { search }];
    console.log("call the api for list");
    let data = await getVendorItemList(qParams)
      .then((res) => {
        let arrayFromRes =
          res.data.data.results &&
          res.data.data.results.map((item) => {
            return {
              key: item._id,
              value: item.itemId,
              label: item.itemId,
              item: item,
            };
          });
        let arrayFromState =
          this.state.items &&
          this.state.items.map((item) => {
            return {
              key: item.itemId,
              value: item.itemId,
              label: item.itemName,
              item: item,
            };
          });

        return {
          // options: compareArrayFunction(arrayFromRes, arrayFromState),
          options:
            res.data.data.results &&
            res.data.data.results.map((item) => {
              return {
                key: item._id,
                value: item.itemId,
                label: item.itemId,
                item: item,
              };
            }),
          totalPage:
            parseInt(res.data.data.pageMeta.total || 0) /
            parseInt(res.data.data.pageMeta.pageSize || 0),
        };
      })
      .catch((err) => {
        console.log("err", err);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
    console.log("**Options**", data.options);
    return {
      options: data.options,
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };
  selectItemOption = (e, i) => {
    if (e != null) {
      console.log("selected item", e, "card no", i);
      let updatedItems = this.state.items.map((x) => {
        if (x.id === i) {
          return {
            ...x,

            delivery_date: this.state.selectedDate,
            item: e.item,
            material_no: e.item.itemId,
            material_description: e.item.itemName,
            material_group: e.item.material_group,
            uom: e.item.uom,
          };
        }
        return x;
      });
      this.setState(
        {
          items: updatedItems,
        },
        () => {
          console.log("items ", this.state.items);
        }
      );
    }
  };
  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    let itemArr = this.state.items.map((x) => {
      delete x.id;
      //return { ...x, amount: x.unitPrice * x.quantity };
      return { ...x, net_price: x.unitPrice * x.quantity };
    });
    // check for date
    if (this.state.selectedDate === null) {
      message.warning("Please select delivery date");
      this.setState({
        loading: false,
      });
    } else if (this.state.items.filter((x) => x.unitPrice === 0).length > 0) {
      message.warning("Please select unit price of selected items ");
      this.setState({
        loading: false,
      });
    } else if (this.state.items.filter((x) => x.quantity === 0).length > 0) {
      message.warning("Please select quantity of selected items ");
      this.setState({
        loading: false,
      });
    } else {
      let inputs = {
        vendor_no: this.state.selectedVendor.value,
        vendorDetails: this.state.selectedVendor.vendorDetails,
        vendor_name: this.state.selectedVendor.key,
        po_document_type: this.state.selectedDocType.value,
        poType: "external",
        totalAmount: this.state.totalAmount,
        delivery_date: this.state.selectedDate,
        item: itemArr,
        itemId: this.state.itemId,
      };
      console.log("inputs", inputs);
      await createPO(inputs)
        .then((res) => {
          console.log("res", res.data);
          this.setState({
            items: [],
            selectedDate: null,
            selectedVendor: null,
            totalAmount: 0,
            page: 1,
            loading: false,
            poId: res.data.data._id,
          });
          setTimeout(() => {
            this.poGeneration();
          }, 2000);
        })
        .catch((err) => {
          console.log("err", err);
          const { errorMessage, errors } = handleErrorResponse(err);
          message.error(errorMessage);
          this.setState({
            loading: false,
          });
        });
    }
  };
  poGeneration = async () => {
    this.setState({ loading: true });
    let inputs = {
      request: {
        purchase_order_key: this.state.poId,
      },
    };
    await createPOIDGeneration(inputs)
      .then((res) => {
        if (res.data && res.data.message == "Failed") {
          message.info("SAP PO Creation Failed due to insufficient data!");
          this.setState({
            loading: false,
          });
        } else {
          message.success("SAP PO creation has been created successfully");
          this.setState({
            displaySuccessModal: true,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        this.setState({
          loading: false,
        });
      });
  };
  calculateValue = (x) => {
    if (x != undefined || x != null) {
      return x.toFixed(2);
    } else {
      return null;
    }
  };

  disableKey = (e) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };
  handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  redirectPage = () => {
    this.props.props.history.push({
      pathname: `/dashboard/po-generation`,
      state: { type: "External" },
    });
  };
  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let { loading } = this.state;
    return (
      <div className="container-fluid ml-4">
        <Spin indicator={antIcon} spinning={loading}>
          <section className="mb-3">
            <h2 className="row choose-date pb-2">1. Choose Delivery Date</h2>
            <label className="delivery-date-label mt-3 ">
              Delivery Date <span className="text-danger">*</span>{" "}
            </label>
            <div className="row mt-1">
              <DatePicker
                onChange={(date) => this.changeDate(date, "selectedDate")}
                disabledDate={disabledPastDate}
                format={dateFormat}
              />
            </div>
          </section>
          <section className="mb-3">
            <h2 className="row choose-date pb-2">2. Choose Vendor ID</h2>
            <div className="row">
              <div className="col col-sm-4 col-md-4 col-lg-4 col-xl-4">
                {" "}
                <label className="delivery-date-label mt-3 ">
                  Vendor ID <span className="text-danger">*</span>{" "}
                </label>
                <div className="mt-1 ">
                  <AsyncPaginate
                    loadOptions={this.loadOptions}
                    className="ml-n3"
                    value={
                      this.state.selectedVendor && {
                        value: this.state.selectedVendor.value,
                        label: this.state.selectedVendor.label,
                      }
                    }
                    additional={{
                      page: 1,
                    }}
                    isSearchable={true}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.selectOption(e);
                    }}
                  />
                </div>
              </div>

              <div className="col col-sm-4 col-md-4 col-lg-4 col-xl-4">
                {" "}
                <label className="delivery-date-label mt-3 ">
                  Vendor Name <span className="text-danger">*</span>{" "}
                </label>
                <input
                  type="text"
                  className="form-control mt-1 ml-n3"
                  aria-label="Large"
                  value={
                    this.state.selectedVendor && this.state.selectedVendor.key
                  }
                  readOnly
                />
              </div>
              <div className="col col-sm-4 col-md-4 col-lg-4 col-xl-4">
                {" "}
                <label className="delivery-date-label mt-3 ">
                  Document Type <span className="text-danger">*</span>{" "}
                </label>
                <div className="mt-1 ">
                  <AsyncPaginate
                    loadOptions={this.loadDocTypesOptions}
                    className="ml-n3 mr-4"
                    additional={{
                      page: 1,
                    }}
                    isSearchable={true}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.selectDocTypeOption(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          {/* <section className="mb-3">
            <h2 className="row choose-date pb-2">3. Choose Validity Period</h2>
            <div className="row">
              <div className="col col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-0">
                {" "}
                <label className="delivery-date-label mt-3 pl-3">
                  Start Date <span className="text-danger">*</span>{" "}
                </label>
                <div className="mt-1 select-warehouse">
                  <DatePicker
                    onChange={(date) => this.changeDate(date, "startDate")}
                    disabledDate={disabledDate}
                  />
                </div>
              </div>

              <div className="col col-sm-6 col-md-6 col-lg-6 col-xl-6" pl-0>
                {" "}
                {this.state.startDate != null && (
                  <>
                    <label className="delivery-date-label mt-3 pl-3">
                      End Date <span className="text-danger">*</span>{" "}
                    </label>
                    <div className="mt-1 select-warehouse">
                      <DatePicker
                        onChange={(date) => this.changeDate(date, "endDate")}
                        disabledDate={(current) =>
                          disabledEndDate(current, this.state.startDate)
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </section> */}
          <section className="mb-3">
            <h2 className="row choose-date pb-2">3. Add Item Details</h2>
            <div className="row mt-3 d-flex align-items-center">
              <div className="col col-sm-3 col-md-3 col-lg-3 col-xl-3 amount-column">
                <p className="total-amount mb-2">TOTAL AMOUNT</p>
                <span className="amount pt-1">
                  &#8377;{formatMoney(this.state.totalAmount)}
                </span>
              </div>
              <div className="col col-sm-9 col-md-9 col-lg-9 col-xl-9">
                <p id="note">
                  Note : Add Items to Calculate Total Amount{" "}
                  <span className="ml-3">
                    <img
                      src={add}
                      alt="add"
                      className="add-btn non-clickable"
                      title="ADD ITEM"
                      onClick={this.addItem}
                      // className="non-clickable"
                    />
                  </span>
                </p>
              </div>
            </div>
          </section>

          {this.state.items.map((item, i) => {
            return (
              <section className="row mb-3">
                <div className="col col-sm-11 col-md-11 col-lg-11 col-xl-11 pl-0">
                  <div className="card-container mt-3">
                    <div class="card">
                      <div class="card-body">
                        <div className="d-flex row">
                          <div className="col col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-1">
                            <label>Choose Item Id</label>
                            <AsyncPaginate
                              value={
                                this.state.items.filter(
                                  (x) => x.id === i + 1
                                )[0] && {
                                  value: this.state.items.filter(
                                    (x) => x.id === i + 1
                                  )[0].item_no,
                                  label: this.state.items.filter(
                                    (x) => x.id === i + 1
                                  )[0].material_no,
                                }
                              }
                              loadOptions={this.loadItemOption}
                              additional={{
                                page: 1,
                              }}
                              isSearchable={true}
                              classNamePrefix="select"
                              onChange={(e) => {
                                this.selectItemOption(e, i + 1);
                              }}
                              placeholder="Select Item Id"
                            />
                          </div>
                          <div className="col col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-1">
                            <label>Item Name</label>
                            <input
                              type="text"
                              className="form-control"
                              aria-label="Large"
                              readOnly
                              value={
                                this.state.items.filter(
                                  (x) => x.id === i + 1
                                )[0] &&
                                this.state.items.filter(
                                  (x) => x.id === i + 1
                                )[0].material_description
                              }
                            ></input>
                          </div>
                          <div className="col col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-1">
                            <label>UOM</label>
                            <input
                              type="text"
                              className="form-control"
                              aria-label="Large"
                              readOnly
                              value={
                                this.state.items.filter(
                                  (x) => x.id === i + 1
                                )[0] &&
                                this.state.items.filter(
                                  (x) => x.id === i + 1
                                )[0].uom
                              }
                            ></input>
                            {/* <Select
                              options={UOMOptions}
                              onChange={this.handleChangeUOM}
                              value={
                                this.state.items.filter(
                                  (x) => x.id === i + 1
                                )[0] && {
                                  value: this.state.items.filter(
                                    (x) => x.id === i + 1
                                  )[0].uom,
                                  label: this.state.items.filter(
                                    (x) => x.id === i + 1
                                  )[0].uom,
                                }
                              }
                            /> */}
                          </div>
                        </div>
                        <div className="d-flex row mt-3">
                          <div className="col col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-1">
                            <label>Unit Price</label>
                            <input
                              type="number"
                              min="1"
                              step="any"
                              className="form-control"
                              aria-label="Large"
                              onKeyPress={this.disableKey}
                              value={
                                this.state.items.filter(
                                  (x) => x.id === i + 1
                                )[0] &&
                                this.state.items.filter(
                                  (x) => x.id === i + 1
                                )[0].unitPrice
                              }
                              name="unitPrice"
                              onChange={(e) => this.handleChange(e, i + 1)}
                              onKeyDown={this.handleKeypress}
                            />
                          </div>
                          <div className="col col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-1">
                            <label>Quantity</label>
                            <input
                              type="number"
                              min={1}
                              name="quantity"
                              onKeyPress={this.disableKey}
                              onChange={(e) => this.handleChange(e, i + 1)}
                              className="form-control"
                              aria-label="Large"
                              value={
                                this.state.items &&
                                this.state.items.filter(
                                  (x) => x.id === i + 1
                                )[0] &&
                                this.state.items.filter(
                                  (x) => x.id === i + 1
                                )[0].quantity
                              }
                              onKeyDown={this.handleKeypress}
                            />
                          </div>
                          <div className="col col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-1">
                            <label>Amount</label>
                            <input
                              type="number"
                              className="form-control"
                              step="any"
                              aria-label="Large"
                              value={this.calculateValue(
                                this.state.items &&
                                  this.state.items.filter(
                                    (x) => x.id === i + 1
                                  )[0] &&
                                  this.state.items.filter(
                                    (x) => x.id === i + 1
                                  )[0].unitPrice *
                                    this.state.items.filter(
                                      (x) => x.id === i + 1
                                    )[0].quantity
                              )}
                              name="amount"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-sm-1 col-md-1 col-lg-1 col-xl-1 d-flex align-items-center pl-0">
                  {" "}
                  <img
                    src={trash}
                    alt="delete"
                    className="add-btn delete"
                    onClick={() => this.deleteItem(item.id, item)}
                    title="Delete Item"
                  />
                </div>
              </section>
            );
          })}
          <div className="">
            {" "}
            <button
              className="btn btn-primary"
              onClick={this.onSubmit}
              disabled={
                this.state.totalAmount === 0 ||
                this.state.selectedDate === null ||
                // this.state.startDate === null ||
                // this.state.endDate === null ||
                this.state.items.length === 0 ||
                !this.state.selectedDocType.value ||
                this.state.items.filter((x) => x.item === null).length > 0 ||
                this.state.items.filter((x) => x.unitPrice === "").length > 0 ||
                this.state.items.filter((x) => x.quantity === "").length > 0
              }
            >
              Submit
            </button>
          </div>
        </Spin>
        {this.state.displaySuccessModal && (
          // <ShowSuccessModal
          //   message={"External PO created successfully!"}
          //   link={"/dashboard/po-generation"}
          //   buttonName={"PO Generation List Page"}

          // />
          <React-Fragment>
            <div className="Backdrop">
              <div className="success-modal-container">
                <div>
                  <div className="text-center">
                    <img
                      src={successicon}
                      className="mx-auto mt-4"
                      alt="successicon"
                    />
                  </div>
                  <p className="new-vehicle  mt-3 mx-md-5 mx-lg-auto col-lg-12 text-center col-md-12 mt-3">
                    {"External PO created successfully!"}
                  </p>
                  <p className="col-lg-8 col-md-10 text-center mx-auto mt-5 new-vehicle">
                    Go To
                  </p>

                  <button
                    type="button"
                    className="btn btn-primary mb-5 mx-auto d-block btn-md"
                    onClick={this.redirectPage}
                  >
                    {"PO Generation List Page"}
                  </button>
                </div>
              </div>
            </div>
          </React-Fragment>
        )}
      </div>
    );
  }
}

export default ExternalPO;
