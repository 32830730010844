import { actionTypes } from "../action/salesDashboard";
const initialState = {
  loading: false,
  salesList: [],
  isTokenExpired: false,
  isError: false,
};

export const salesOverviewReducer = (state = initialState, action) => {
  const { type, data, load } = action;
  switch (type) {
    case actionTypes.LOADING:
      return { ...state, loading: true };

    case actionTypes.STOP_LOADING:
      return { ...state, loading: false };
    case actionTypes.TOKEN_EXPIRED:
      return { ...state, isTokenExpired: true };
    case actionTypes.ERROR:
      return { ...state, isError: true };
    case actionTypes.GET_SALES_LIST:
      return {
        ...state,
        salesList: data,
        loading: false,
        isError: false,
        //isTokenExpired: true,
        // totalDocuments: data.totalDocuments,
      };

    default:
      return state;
  }
};
