import downArrow from "../../images/down-arrow.svg";
import React, { Component } from "react";

class DayPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dayNo: 1,
      dayName: "Sunday",
      dayvalue: "sun"
    };
  }
  decreaseDayNum = () => {
    let { dayNo } = this.state;
    let { selectDay } = this.props;
    if (dayNo == 1) {
      this.setState({
        dayNo: 7,
      },
      () => {
        let name = this.displayDayName(this.state.dayNo);
        console.log("***name***", name);
        let _name = name.toLowerCase().slice(0,3);
        this.setState({
          dayvalue: name
      },()=>{
        selectDay(this.state.dayvalue)
      })
    
      });
    } else {
      this.setState({
        dayNo: this.state.dayNo - 1,
      },
      () => {
        let name = this.displayDayName(this.state.dayNo);
        console.log("***name***", name);
        this.setState({
          dayvalue: name
      },()=>{
        selectDay(this.state.dayvalue)
      })
    
      });
    }
  };
  increaseDayNum = () => {
    let { dayNo } = this.state;
    let { selectDay } = this.props;
    if (dayNo == 7) {
      this.setState(
        {
          dayNo: 1,
        },
        () => {
          let name = this.displayDayName(this.state.dayNo);
          console.log("***name***", name);
          this.setState({
              dayvalue: name
          },()=>{
            selectDay(this.state.dayvalue)
          })
         
        }
      );
    } else {
      this.setState(
        {
          dayNo: this.state.dayNo + 1,
        },
        () => {
          let name = this.displayDayName(this.state.dayNo);
          console.log("***name***", name);
          this.setState({
            dayvalue: name
        },()=>{
          selectDay(this.state.dayvalue)
        });
      
        }
      );
    }
  };
  displayDayName = (no) => {
    switch (no) {
      case 1:
        return "Sunday";
      case 2:
        return "Monday";
      case 3:
        return "Tuesday";
      case 4:
        return "Wednesday";
      case 5:
        return "Thursday";
      case 6:
        return "Friday";
      case 7:
        return "Saturday";
    }
  };
  componentDidMount = () =>{
    this.props.selectDay(this.state.dayvalue)
  }
  render() {
    let { dayNo } = this.state;

    
    return (
      <div className="day-picker">
        <img
          src={downArrow}
          className="backArr"
          alt="arr"
          onClick={this.decreaseDayNum}
        />{" "}
        {this.displayDayName(dayNo)} 
        <img
          src={downArrow}
          className="frontArr"
          alt="arr"
          onClick={this.increaseDayNum}
        />
      </div>
    );
  }
}
export default DayPicker;
