import React, { Component } from "react";
import "./team.scss";
import search from "../../images/white-search-icon.svg";
import Employer from "./employer";
// loader
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// err msg
import message from "antd/lib/message";
import { handleErrorResponse } from "../../utils/Request";
import Details from "./details";
import { zohoDetails, salesManDetails } from "../../services/teamServices/team";
import CompanyDetails from "./companyDetails";
import {
  getAgencyList,
  createAgency,
} from "./../../services/teamServices/team";
// import bell from "../../../src/images/bell.svg";
import UserProfile from "../nav-profile/userProfile";
import PrimaryHeader from "../header/PrimaryHeader";

class AddNewSalesman extends Component {
  constructor(props) {
    super(props);

    this.state = {
      empId: "",
      loading: false,
      salesmanDetails: null,
      employeeType: "",
      companyName: "Marico",
      isEditPage: "",
      searchoptions: [],
      selectedAgencyId: "",
      options: [],
      page: 1,
      search: "",
      showButton: false,
      error: "",
      selectedDist: "",
      selectedCity: "",
    };
  }
  selectDistributor = (e) => {
    this.setState({
      selectedDist: e.value,
    });
    this.setState({
      selectedCity: e.cityId,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (value.length >= 12) {
      this.setState({ error: "Please enter upto 12 digits" });
    } else if (value.length < 12) {
      this.setState(
        {
          [e.target.name]: e.target.value.toUpperCase(),
          error: "",
        },
        () => {
          this.setState({
            email: "",
            phone: null,
            salesmanDetails: null,
          });
        }
      );
    }
  };
  updateCompanyName = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  searchById = async () => {
    // let { asmData } = this.props;
    // if (this.state.selectedDist == "") {
    //   message.error("Please select Distributor");
    //   return;
    // }

    this.setState({ loading: true });
    let empId = this.state.empId;

    await zohoDetails(empId)
      .then((res) => {
        this.setState({
          loading: false,
          salesmanDetails: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  selectEmployeeType = (e) => {
    this.setState(
      {
        employeeType: e.target.name,
      },
      () => {
        this.setState({
          salesmanDetails: null,
          search: "",
        });
      }
    );
  };

  getAgencyList = async () => {
    let { page, search, options } = this.state;
    let qParams = [{ page }, { search }];
    this.setState({ loading: true });
    await getAgencyList(qParams)
      .then((res) => {
        this.setState({
          options:
            res.data.data.results &&
            res.data.data.results.map((agency) => {
              return {
                key: agency._id,
                value: agency._id,
                label: agency.nameToDisplay,
              };
            }),
          loading: false,
        });
      })
      .catch((err) => {
        console.log("Error", err);
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };

  selectOption = (e) => {
    // if (this.state.selectedDist == "") {
    //   message.error("Please select Distributor");
    //   this.setState({
    //     search: "",
    //   });
    //   return;
    // }
    if (e != null) {
      this.setState({
        search: e.label,
        selectedAgencyId: e.key,
      });
    }
    if (e == null) {
      this.setState({ search: "" });
    }
  };

  componentDidMount() {
    this.getAgencyList();
    let editId = this.props.match.params.id;
    let editPage =
      this.props.match.path === "/dashboard/team/salesmen/edit/:id"
        ? true
        : false;
    if (editPage == true) {
      salesManDetails(editId).then((res) => {
        this.setState({
          loading: false,
          salesmanDetails: res.data.data,
          empId: res.data.data.employeeId,
        });
      });
    }
  }

  createNewAgency = async (inputValue) => {
    // if (this.state.selectedDist == "") {
    //   message.error("Please select Distributor");
    //   return;
    // }
    let inputs = {
      name: inputValue,
    };

    await createAgency(inputs)
      .then((res) => {
        message.success("Agency created successfully!!");
        this.setState(
          {
            showButton: true,
          },
          () => {
            this.getAgencyList();
          }
        );
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let {
      loading,
      empId,
      employeeType,
      companyName,
      salesmanDetails,
      isEditPage,
      searchoptions,
      selectedAgencyId,
      options,
      selectedDist,
      selectedCity,
    } = this.state;
    return (
      <div id="content">
        <PrimaryHeader title="Add new Salesman" />
        <Spin indicator={antIcon} spinning={loading}>
          {/* <header className="_header-with-border">
            {this.props.match.path === "/dashboard/team/salesmen/edit/:id" ? (
              <span className="add-new-salesman">Update Salesman </span>
            ) : (
              <span className="add-new-salesman">Add New Salesman</span>
            )}
            <img
                src={bell}
                alt="notification"
                id="bell"
             
                title="notification"
              />

            <UserProfile />
          </header> */}
          <Employer
            isEditPage={
              this.props.match.path === "/dashboard/team/salesmen/edit/:id"
            }
            empId={empId}
            employeeType={employeeType}
            companyName={companyName}
            updateCompanyName={this.updateCompanyName}
            selectEmployeeType={this.selectEmployeeType}
            selectDistributor={this.selectDistributor}
            showCompanyEmpDetails={this.showCompanyEmpDetails}
            searchById={this.searchById}
            handleChange={this.handleChange}
            options={options}
            onCreateOption={this.createNewAgency}
            selectedAgency={(e) => {
              this.selectOption(e);
            }}
            showButton={this.state.showButton}
            error={this.state.error}
          />
          {salesmanDetails && (
            <Details
              detailsId={salesmanDetails._id}
              details={salesmanDetails}
              isEditPage={
                this.props.match.path === "/dashboard/team/salesmen/edit/:id"
              }
              type="salesman"
              selectDistributor={selectedDist}
              selectedCity={selectedCity}
            />
          )}

          {this.state.search && (
            <CompanyDetails
              selectedAgencyId={selectedAgencyId}
              type="salesman"
              selectDistributor={selectedDist}
              selectedCity={selectedCity}
            />
          )}
        </Spin>
      </div>
    );
  }
}
export default AddNewSalesman;
