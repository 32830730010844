import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardLeftContent from "../CardLeftContent";
import Box from "../../../images/box.svg";
import { Button, Input, Typography, Spin, notification } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { upDateMinOrderValue } from "../../../services/settings/manageShippingservice";
import message from "antd/lib/message";
import {
  actionTypes,
  getMinimumOrdValue,
  startLoading,
  stopLoading,
} from "../../../action/manageShippingaction";
import { blockInvalidChar } from "../../../utils/numberValidation";

const MinimumOrderValue = () => {
  const dispatch = useDispatch();
  const { minimumOrderValue, loading } = useSelector(
    (state) => state.manageShippingReducer
  );
  const [editOpen, setEditOpen] = useState(false);
  const [minOrderValue, setMinOrderValue] = useState();

  const onHandleEditOpen = () => {
    setEditOpen(true);
  };

  useEffect(() => {
    setMinOrderValue(minimumOrderValue);
  }, [minimumOrderValue]);

  async function onClickSaveHandler() {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.UPDATE_MINIMUM_ORDER_VALUE,
    });
    try {
      const res = await upDateMinOrderValue({
        minimumOrderValue: minOrderValue,
      });

      if (res.data.status == 200) {
        // message.success("Minimum order value updated successfully!");
        notification.success({ message: "Data updated successfully!" });
        setEditOpen(false);
        dispatch(getMinimumOrdValue());
      } else {
        message.error(res.data.data);
      }

      dispatch({
        type: actionTypes.STOP_LOADING,
        load: actionTypes.UPDATE_MINIMUM_ORDER_VALUE,
      });
    } catch (error) {
      console.log({ error });
    }
  }

  const onChangeMinOrderValue = (e) => {
    if (e.target.value.length > 8) return false;
    setMinOrderValue(e.target.value);
  };

  return (
    <div className="rounded-lg shadow-sm py-4 px-4 bg-white">
      <div className=" d-flex justify-content-between">
        <CardLeftContent
          icon={<img src={Box} alt="box" style={{ width: 65 }} />}
          title="Minimum Order Value"
          subTitle="Minimum order value for the order to be placed."
        />

        {editOpen ? null : (
          <div className="d-flex flex-row ml-5">
            <div className="d-flex justify-content-center flex-column">
              {loading.includes(actionTypes.MINIMUM_ORDER_VALUE) ? (
                <Spin />
              ) : (
                <Typography
                  className="font-weight-bold"
                  style={{ fontSize: 16, color: "#3A4960" }}
                >
                  ₹ {minimumOrderValue}
                </Typography>
              )}
            </div>

            <div className="p-3 ml-5 d-flex align-items-center">
              <EditOutlined
                style={{ fontSize: 24, color: "#5078F2" }}
                onClick={() => onHandleEditOpen()}
              />
            </div>
          </div>
        )}
      </div>
      {editOpen ? (
        <div style={{ marginLeft: "160px" }}>
          <div className="pt-3">
            <div className="d-flex w-50 flex-column">
              <Typography style={{ fontSize: 16, color: "#3A4960" }}>
                Minimum order value :
              </Typography>
              <div className="d-flex w-75 flex-row">
                <Input
                  placeholder="₹ 10,000"
                  className="mr-3"
                  type="number"
                  value={minOrderValue}
                  onChange={onChangeMinOrderValue}
                  onKeyPress={blockInvalidChar}
                />
              </div>
              <div className="mt-5">
                <Button
                  size="large"
                  value="default"
                  className="cancel_button"
                  onClick={() => setEditOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  className="rounded-lg save_button ml-5"
                  disabled={!minOrderValue}
                  style={{ backgroundColor: "#5078F2", color: "#fff" }}
                  onClick={onClickSaveHandler}
                  loading={loading.includes(
                    actionTypes.UPDATE_MINIMUM_ORDER_VALUE
                  )}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MinimumOrderValue;
