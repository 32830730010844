import React from "react";
import PrimaryHeader from "../header/PrimaryHeader";
import ArrowLeft from "../../images/arrow-left.svg";
import papad from "../../images/papad-removebg-preview 1.svg";
import Select from "react-select";
import { getPurchasePendingDetails } from "../../services/franchiseServices/purchaseServices";
import moment from "moment";
import { Pagination, Spin, Modal } from "antd";
import { formatMoney } from "../../utils/common";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { LoadingOutlined } from "@ant-design/icons";
import { isAmount } from "../../helper/Common";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function HistoryDetails(props) {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(7);
  const [id, setId] = React.useState("");
  const [basicDetails, setBasicDetails] = React.useState({});
  const [items, setItems] = React.useState([]);
  const [skip, setSkip] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  console.log(props);

  const redirectToBack = () => {
    props.history.push({
      pathname: "/dashboard/purchaseOrders",
      state: "history",
    });
  };
  React.useEffect(() => {
    setId(props.match.params.id);
    getDetails(props.match.params.id);
  }, []);

  const getDetails = async (id) => {
    setLoading(true);
    await getPurchasePendingDetails(id)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setBasicDetails(res && res.data.response.data[0].basicDetails);
        setItems(res && res.data.response.data[0].itemBreakDown);
        setSkip(res && res.data.response.skip);
        setTotalPage(res && res.data.response.totalPage);
        setTotalRecords(res && res.data.response.totalRecords);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  //Calculate Unit Price
  const calculateUnitPrice = (total, discount, qty) => {
    discount = Math.abs(discount);
    let unitPrice = (Number(total)+Number(discount))/Number(qty);
    unitPrice = isAmount(unitPrice, true);
    return unitPrice;
  };

  const purchaseTbody = items.map((item) => {
    let objIndex = items.findIndex((obj) => obj.itemId === item.itemId);
    return (
      <>
        <tr>
          <td>
            <div className="d-flex">
              <div className="">
                <img src={papad} alt="" className="table_image" />
              </div>
              <div className="ml-4 mt-3">
                <p className="purchase_item">{item.itemName}</p>
                <p className="mt-n2 purchase_id">{item.itemId}</p>
                {/* <p className="mt-n2 discount">
                                    {item.discount}
                                    <span className="ml-2 economy">
                                        {item.status}
                                    </span>
                                </p> */}
              </div>
            </div>
            {items[objIndex].remarks != "" && (
              <div className="mt-3 mb-3">
                <label htmlFor="" className="purchase_remarks">
                  {"Remarks"}
                </label>
                <br />
                <Select
                  className={"reactSelect"}
                  menuPosition="fixed"
                  value={
                    item.remarks
                      ? {
                          label: item.remarks,
                          value: item.remarks,
                        }
                      : ""
                  }
                  placeholder={"Choose Remarks"}
                />
              </div>
            )}
          </td>
          <td>
            <div className="mt-3">
              <p className="purchase_unit">
                {item?.salePrice &&
                  calculateUnitPrice(
                    item?.itemAmount,
                    item?.discountAmount,
                    item?.received_qty
                  )}
              </p>
            </div>
          </td>
          <td>
            <div className="mt-4">
              <p className="purchase_unit">{isAmount(item.itemAmount, true)}</p>
              {item.discountAmount != 0 && (
                <p className="strike_data">{item.discountAmount}</p>
              )}{" "}
            </div>
          </td>
          <td>
            <div className="mt-4">
              <p className="purchase_unit">{item.quantity}</p>
            </div>
          </td>
          <td>
            <div
              className={`${
                item.received_qty != item.quantity
                  ? "purchase_history_recieveQty mt-4"
                  : "purchase_history_Qty mt-4"
              }`}
            >
              {item.received_qty}
            </div>
          </td>
        </tr>
      </>
    );
  });

  const handleChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  return (
    <div id="content">
      <PrimaryHeader title={`Order # ${basicDetails.invoiceId}`} />
      <Spin indicator={antIcon} spinning={loading}>
        <div className="mt-5 cursor d-flex col-lg-2" onClick={redirectToBack}>
          <div className="">
            <img src={ArrowLeft} alt="" />
          </div>
          <div className="ml-2 mt-1 arrow_label">
            <p>{"Back"}</p>
          </div>
        </div>
        <div className="mt-4  purchase_content">
          <div className="border_left ml-3">
            <label htmlFor="" className="purchase_label">
              {"Basic Details"}
            </label>
          </div>
          <div className="mt-4">
            <div className="row col-lg-12">
              <div className="col-lg-3">
                <label htmlFor="" className="purchase_sub">
                  {"Order Date"}
                </label>
                <p htmlFor="" className="purchase_value">
                  {basicDetails.orderDate
                    ? moment(basicDetails.orderDate).format("DD-MM-YYYY")
                    : "N/A"}{" "}
                </p>
              </div>
              <div className="col-lg-2">
                <label htmlFor="" className="purchase_sub">
                  {"Invoice ID"}
                </label>
                <p htmlFor="" className="purchase_value">
                  {basicDetails ? basicDetails.invoiceId : "N/A"}
                </p>
              </div>
              <div className="col-lg-3 pl-5">
                <label htmlFor="" className="purchase_sub">
                  {"Delivery Date"}
                </label>
                <p htmlFor="" className="purchase_value">
                  {basicDetails
                    ? moment(basicDetails.deliveryDate).format("DD-MM-YYYY")
                    : "N/A"}
                </p>
              </div>
              <div className="col-lg-2">
                <label htmlFor="" className="purchase_sub">
                  {"Item Count"}
                </label>
                <p htmlFor="" className="purchase_value">
                  {basicDetails ? basicDetails.itemCount : "N/A"}{" "}
                </p>
              </div>
              <div className="col-lg-2">
                <label htmlFor="" className="purchase_amt_sub">
                  {"Total Amount"}
                </label>
                <p htmlFor="" className="purchase_amount">
                  {basicDetails
                    ? isAmount(basicDetails.totalAmount, true)
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 purchase_content">
          <div className="border_left ml-3">
            <label htmlFor="" className="purchase_label">
              Item Breakdown
            </label>
          </div>
          <div className="table-wrapper">
            <div className="mt-4 ml-2 purchase_table">
              <table className="table">
                <thead className="remove-border-top">
                  <tr>
                    <th>Item Details</th>
                    <th>Unit Price</th>
                    <th>Net Total Amount</th>
                    <th>Invoiced Qty</th>
                    <th>Recieved Qty</th>
                  </tr>
                </thead>
                <tbody>{purchaseTbody}</tbody>
              </table>
              {purchaseTbody.length === 0 && (
                <div className="no-data">
                  <p>No Data Available</p>
                </div>
              )}
              {purchaseTbody.length > 0 && (
                <div className="row mt-3 mb-3">
                  <div className="col-lg-4">
                    Showing {skip + 1} to{" "}
                    {skip + 10 > totalRecords ? totalRecords : skip + 10} of{" "}
                    {totalRecords} entries
                  </div>
                  <div className="offset-lg-4 col-lg-4 franchisePagination">
                    {totalPage != 0 && (
                      <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRecords}
                        showSizeChanger={false}
                        onChange={(page, pageSize) =>
                          handleChange(page, pageSize)
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}
