import React, { Component, Fragment } from "react";
import "./profile.scss";
import AdminProfile from "../form/AdminProfile";
// images
import editProfile from "../../images/edit-profile.svg";
import defaultPic from "../../images/default-pic.jpg";
// loader
import { Spin, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
// err msg
import message from "antd/lib/message";
import { handleErrorResponse } from "../../utils/Request";
import UserProfile from "../nav-profile/userProfile";
import { userDetails } from "../../services/userServices/user";
import { getActualImage } from "../../utils/generateImageUrl";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      imageLoading: false,
      details: {},
      email: "",
      phone: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSubmit = () => {};

  componentDidMount = async () => {
    this.setState({ loading: true });
    //  call api
    await userDetails()
      .then(async (res) => {
        this.setState({
          details: res.data.data,
          email: res.data.data.email,
          phone: res.data.data.phoneNumber,
          loading: false,
          imageUrl: res.data.data.profilePicture
            ? await getActualImage(res.data.data.profilePicture)
            : "",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  onProfileUpdate = async () => {
    this.setState({ loading: true });
    //  call api
    await userDetails()
      .then(async (res) => {
        this.setState({
          details: res.data.data,
          email: res.data.data.email,
          phone: res.data.data.phoneNumber,
          loading: false,
          imageUrl: res.data.data.profilePicture
            ? await getActualImage(res.data.data.profilePicture)
            : "",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  render() {
    let { loading, email, imageLoading, imageUrl, phone, details } = this.state;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      // <Spin indicator={antIcon} spinning={loading}>
      // <div>
      //   {details && (
      <AdminProfile
        data={{ phone, details, email, imageUrl }}
        onProfileUpdate={() => this.onProfileUpdate()}
      />
      // )}
      // </div>

      // </Spin>
    );
  }
}

export default Profile;
