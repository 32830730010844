import { EditOutlined, TeamOutlined } from "@ant-design/icons";
import { Button, notification, Select, Spin, Table, Typography } from "antd";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionTypes,
  getListOfExceptionalCustomerDeliveryDates,
} from "../../../action/manageOrder";
import { updateManageOrderEarlierLastAndExcluded } from "../../../services/settings/manageOrder";
import CardLeftContent from "../CardLeftContent";
import "./styles.scss";
import { deliveryDateOptions, weekDays } from "./_deliveryOptions";

const { Option } = Select;

const OrderingTime = () => {
  const [editOpen, setEditOpen] = useState(false);
  const [earliestDelDate, setEarliestDelDate] = useState();
  const [lastDay, setLastDelDate] = useState();
  const [excludedDays, setExcludedDays] = useState("");
  const [customerIdList, setCustomerIdList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const dispatch = useDispatch();
  const columns = [
    {
      title: "Customer",
      dataIndex: "customerId",
      render: (_, record) => (
        <div>
          <Typography>{record.customerName}</Typography>
          <Typography style={{ opacity: 0.7 }} className="mt-1">
            {record.customerId}
          </Typography>
        </div>
      ),
    },
    {
      title: "Starting From",
      dataIndex: "earliestDay",
      render: (earliestDay) => (
        <div>
          <Typography style={{ opacity: 0.7 }} className="mt-1">
            {getDateFromTodayWithFormat(earliestDay)}
          </Typography>
        </div>
      ),
    },
    {
      title: "Ending In",
      dataIndex: "lastDay",
      render: (lastDay) => (
        <div>
          <Typography style={{ opacity: 0.7 }} className="mt-1">
            {getDateFromTodayWithFormat(lastDay)}
          </Typography>
        </div>
      ),
    },
    {
      title: "Days Excluded",
      dataIndex: "excludedDays",
      render: (_, record) => (
        <div>
          {record.excludedDays.length ? (
            record.excludedDays.map((item) => (
              <div>
                <Typography
                  style={{ opacity: 0.7, display: "inline" }}
                  className="mt-1 weekdaysChips uppercase"
                  key={item}
                >
                  {item.substring(0, 3)}
                </Typography>
              </div>
            ))
          ) : (
            <div>-</div>
          )}
        </div>
      ),
    },
  ];

  const onChangeEarlyDeliveryDate = (val) => {
    if (val > lastDay) {
      setLastDelDate("");
    }
    setEarliestDelDate(val);
  };
  const {
    exceptionalCustomerDeliveryDates,
    loading,
    listOfEceptionalCustomers,
  } = useSelector((state) => state.manageOrder);

  const onHandleEditOpen = () => {
    setEditOpen(true);
  };
  const onChangeLastDelDate = (val) => {
    setLastDelDate(val);
  };
  function onChangehandleDayChange(value) {
    // console.log(value);
    setExcludedDays(value);
  }

  const onClickCancelHandler = () => {
    setEditOpen(false);
    setLastDelDate(null);
    setEarliestDelDate(null);
    setCustomerIdList([]);
  };
  const updateExceptionCustomers = async () => {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.UPDATE_EXCEPTIONAL_CUSTOMER_DELIVERY_DATES,
      });

      let sortedExcludedDays = weekDays
        .filter((day) => excludedDays.includes(day.key))
        .map((day) => day.key);

      const response = await updateManageOrderEarlierLastAndExcluded(
        earliestDelDate,
        lastDay,
        sortedExcludedDays,
        customerIdList
      );

      if (response.status == 200) {
        const { data } = response;
        const message = data.data;
        notification.success({
          message: message,
        });
        emptyTextFields();
        dispatch({
          type: actionTypes.STOP_LOADING,
          load: actionTypes.UPDATE_EXCEPTIONAL_CUSTOMER_DELIVERY_DATES,
        });
        dispatch(getListOfExceptionalCustomerDeliveryDates());
        setEditOpen(false);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: actionTypes.STOP_LOADING,
        load: actionTypes.UPDATE_EXCEPTIONAL_CUSTOMER_DELIVERY_DATES,
      });
    }
  };

  const emptyTextFields = () => {
    setEarliestDelDate(null);
    setLastDelDate(null);
    setCustomerIdList([]);
    setExcludedDays([]);
  };

  const getButtonDisableState = () =>
    !earliestDelDate || !lastDay || !customerIdList.length;

  const onChangeCustomerList = (val) => {
    setCustomerIdList(val);
  };

  const getDateFromTodayWithFormat = (days) => {
    if (days === 0 || days) {
      return moment().add(days, "d").format("DD MMMM YYYY");
    } else {
      return;
    }
  };

  return (
    <div className="rounded-lg shadow-sm py-4 px-4 bg-white">
      <div className=" d-flex justify-content-between">
        <CardLeftContent
          icon={<TeamOutlined style={{ fontSize: 60, color: "#717C8D" }} />}
          title="Exceptional Customers"
          subTitle="Earliest to Lastest date for delivery."
        />
        {editOpen ? null : (
          <div className="d-flex flex-row align-items-center">
            {loading.includes(actionTypes.GET_LIST_OF_EXCEPTIONAL_CUSTOMERS) ? (
              <Spin />
            ) : (
              <Fragment>
                <div>
                  <Typography className="font-weight-bold">
                    {listOfEceptionalCustomers?.length || "0"} Customers
                  </Typography>
                </div>
              </Fragment>
            )}
            <div className="p-3 ml-5 d-flex align-items-center">
              <EditOutlined
                style={{ fontSize: 24, color: "#5078F2" }}
                onClick={() => onHandleEditOpen()}
              />
            </div>
          </div>
        )}
      </div>
      {editOpen ? (
        <div className="mt-3 mb-2" style={{ marginLeft: 160 }}>
          <div className="d-flex flex-column py-1">
            <div className="w-25 pb-4 pt-2">
              <Typography style={{ fontSize: 16 }}>Select Customer:</Typography>
              <Select
                className="datePicker"
                mode="multiple"
                placeholder="Eg: 10012345 - Venkatesh Rao"
                onChange={onChangeCustomerList}
                style={{ borderRadius: 30 }}
                value={customerIdList}
              >
                {exceptionalCustomerDeliveryDates?.map((customerlist) => (
                  <Option
                    key={customerlist.goFrugalId}
                    value={customerlist.goFrugalId}
                  >
                    <Typography>{`${customerlist.goFrugalId} - ${customerlist.name}`}</Typography>
                  </Option>
                ))}
              </Select>
            </div>
            <div className="d-flex w-100 flex-row">
              <div className="w-25">
                <Typography style={{ fontSize: 16 }} className="my-1">
                  Earliest Delivery Date:
                </Typography>
                <Select
                  className="datePicker"
                  value={earliestDelDate}
                  placeholder="0 (Same Day Delivery)"
                  onChange={onChangeEarlyDeliveryDate}
                >
                  {deliveryDateOptions.map((opt) => (
                    <Option
                      value={opt.value}
                      key={opt.value}
                      // disabled={parseInt(opt.value) >= parseInt(lastDay)}
                      disabled={parseInt(opt.value) > parseInt(lastDay) - 1}
                    >
                      {opt.key}
                    </Option>
                  ))}
                </Select>
                <Typography
                  className="mt-2"
                  style={{
                    color: "#219653",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                >
                  From the date of order
                </Typography>
              </div>
              <div className="mr-5" />
              <div className="w-25">
                <Typography style={{ fontSize: 16 }} className="my-1">
                  Last Delivery Date :
                </Typography>
                <Select
                  className="datePicker"
                  value={lastDay}
                  placeholder="4 days"
                  onChange={onChangeLastDelDate}
                >
                  {deliveryDateOptions.map((opt) => (
                    <Option
                      value={opt.value}
                      key={opt.value}
                      disabled={
                        earliestDelDate === "0"
                          ? false
                          : parseInt(opt.value) < parseInt(earliestDelDate) + 1
                      }
                      // disabled={parseInt(opt.value) < parseInt(earliestDelDate)}
                    >
                      {opt.key}
                    </Option>
                  ))}
                </Select>
                <Typography
                  className="mt-2"
                  style={{
                    color: "#219653",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                >
                  From the date of order
                </Typography>
              </div>
              <div className="mr-5" />
              <div className="w-25">
                <Typography style={{ fontSize: 16 }} className="my-1">
                  Days Excluded :
                </Typography>
                <Select
                  className="datePicker"
                  placeholder="Eg. Sunday"
                  style={{ width: 120 }}
                  mode="multiple"
                  onChange={onChangehandleDayChange}
                >
                  {weekDays.map((day) => (
                    <Option value={day.key}>{day.value}</Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          {listOfEceptionalCustomers?.length ? (
            <div className="mt-5">
              <Table
                pagination={{
                  hideOnSinglePage: true,
                  current: page,
                  pageSize: pageSize,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                  },
                }}
                dataSource={listOfEceptionalCustomers}
                columns={columns}
                align="center"
                rowKey={(record) => record.goFrugalId}
              />
            </div>
          ) : null}
          <div
            className="mt-3 d-flex justify-content-between"
            style={{ width: 200 }}
          >
            <Button
              size="large"
              onClick={onClickCancelHandler}
              value="default"
              className="cancel_button"
            >
              Cancel
            </Button>
            <Button
              size="large"
              className="rounded-lg save_button"
              style={{ backgroundColor: "#5078F2", color: "#fff" }}
              onClick={updateExceptionCustomers}
              disabled={getButtonDisableState()}
              loading={loading.includes(
                actionTypes.UPDATE_EXCEPTIONAL_CUSTOMER_DELIVERY_DATES
              )}
            >
              Save
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OrderingTime;
