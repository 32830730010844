import { actionTypes } from "../action/customers";
const {
  GET_ALL_CUSTOMERS_DATA,
  SYNC_WITH_GO_FRUGAL,
  GET_ALL_CUSTOMERS_LEDGER_DATA,
  GET_INVOICE_DATA,
  GET_PAYMENT_DATA,
  GET_CREDIT_DATA,
  GET_DEBIT_DATA,
  GET_LEDGER_DETAILS,
} = actionTypes;

const initialState = {
  allCustomersDetails: [],
  allCustomersLedgerDetails: {},
  syncingStarted: false,
  syncingMsg: "",
  allInvoiceData: {},
  allPaymentData: {},
  allCreditdata: {},
  allDebitData: {},
  allLedgerDetails:{},
};

export default function customers(state = initialState, action) {
  switch (action.type) {
    case SYNC_WITH_GO_FRUGAL:
      return {
        ...state,
        syncingStarted: action.data.syncingStarted,
        syncingMsg: action.data.syncingMsg,
      };

    case GET_ALL_CUSTOMERS_DATA:
      return { ...state, allCustomersDetails: [...action.data] };

    case GET_ALL_CUSTOMERS_LEDGER_DATA:
      return { ...state, allCustomersLedgerDetails: { ...action.data } };

    case GET_INVOICE_DATA:
      return { ...state, allInvoiceData: { ...action.data } };

    case GET_PAYMENT_DATA:
      return { ...state, allPaymentData: { ...action.data } };

    case GET_CREDIT_DATA:
      return { ...state, allCreditdata: { ...action.data } };

    case GET_DEBIT_DATA:
      return { ...state, allDebitData: { ...action.data } };

    case GET_LEDGER_DETAILS:
      return { ...state,allLedgerDetails: { ...action.data } };

    default:
      return state;
  }
}
