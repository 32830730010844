export default (inPutAmount) => {
  let amount = inPutAmount;
  if (typeof amount !== "number") {
    amount = parseFloat(amount, 10);
  }
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "INR",
  });
};
