import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import DraftBeatPlan from "../components/beatplans/draftBeatPlan";

//Action Creator
import { getSingleDraftBeatPlanById } from "../action/beatplan";

const mapStateToProps = (state) => {
  return {
    details: state.beatPlans.getSingleDraftBeatPlanById,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSingleDraftBeatPlanById,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DraftBeatPlan)
);
