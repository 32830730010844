import React from "react";
import { Typography } from "antd";

// function VerifiedComponentMessge({
//     imageUrl,Typography,title
// })
//   return (
//     <div>
//   <img src={imageUrl} alt={name}/>
//   </div>
//   )

// export default VerifiedComponentMessge;
function VerifiedComponentMessge({
  name,
  imageUrl,
  title,
  description,
  text,
  text1,
  text2,
}) {
  return (
    <div className="author m-3">
      <img src={imageUrl} alt={name} style={{ margin: "10px" }} />
      <Typography
        style={{ fontSize: "20px", marginTop: "5px", marginLeft: "10px" }}
      >
        {title}
      </Typography>
      <span style={{ display: "flex", marginTop: "4px", marginLeft: "10px" }}>
        <span style={{ marginTop: "10px" }}>
          <Typography
            style={{
              fontWeight: 400,
              fontsize: "18px",
              lineHeight: "19px",
              color: "#3A4960",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            {" "}
            {description}
          </Typography>
        </span>
        <span>
          {" "}
          <Typography style={{ fontSize: "20px" }}>{text}</Typography>
        </span>
        <span>
          {" "}
          <Typography style={{ marginLeft: "5px", marginTop: "6px" }}>
            {text1}
          </Typography>
        </span>
        <span>
          {" "}
          <Typography
            style={{
              fontSize: "14px",
              marginLeft: "5px",
              marginTop: "6px",
              fontWeight: 400,
            }}
          >
            {text2}
          </Typography>
        </span>
      </span>
    </div>
  );
}
export default VerifiedComponentMessge;
