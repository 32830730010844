import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";

// get all so list

export const getAllSoOrderList = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + "api/v1/standalone/salesorder/list" + qUrl,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getCustomerDropdown = async () => {
  let request = await axios.get(
    APIConfig.base_url + "api/v1/standalone/salesorder/customersList",

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const downloadSO = async (inputs) => {
  let request = await axios.post(
    APIConfig.base_url +
      "api/v1/standalone/salesorder/downloadSalesOrderWithIdNameQty",
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );

  return request;
};

export const getSoOrderDetail = async (id) => {
  let request = await axios.get(
    APIConfig.base_url + `api/v1/standalone/salesorder/detail/${id}`,

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const printSO = async (inputs) => {
  let request = await axios.post(
    APIConfig.base_url + "api/v1/standalone/salesorder/downloadInvoice",
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );

  return request;
};

export const PrintKeySet = async (invoiceId) => {
  let request = await axios.patch(
    APIConfig.base_url +
      `api/v1/standalone/salesorder/updatePrintInvoice/${invoiceId}`,
    {},
    {
      headers: {
        "x-access-token": getAuthToken(),
        "Content-Type": "application/json",
      },
    }
  );
  return request;
};

export const getInvoiceDetail = async (inputs) => {
  let request = await axios.post(
    APIConfig.base_url + `api/v1/standalone/salesorder/downloadInvoice`,
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const generateSOInvoice = async (id, inputs) => {
  let request = await axios.patch(
    APIConfig.base_url + `api/v1/standalone/salesorder/generateInvoice/${id}`,
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
