import React, { Component } from "react";
import "./login.scss";
import { RightSideDiv, TopDiv } from "./commondivs";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { forgetPassword } from "../../services/loginServices";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import Toast_msg from "../Toast/toast";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class ForgetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      email: "",
      showToastMsg: false,
      message: "",
    };
  }
  onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
    });
  };
  dismiss = () => {
    this.setState({
      showToastMsg: false,
    });
  };
  sendLink = async (e) => {
    e.target.blur();
    // call api then in success show an alert message!
    this.setState({
      loading: true,
    });
    const { email } = this.state;
    await forgetPassword({ email: email })
      .then((res) => {
        this.setState({
          loading: false,
          showToastMsg: true,
          message: res.data.message,
          email: "",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.info(errorMessage);
      });
  };

  render() {
    let { loading, showToastMsg } = this.state;
    return (
      <div className="login-form-container">
        <RightSideDiv message={"Forgot Password"} />
        <div className="login-left-div">
          <TopDiv />
          <Spin indicator={antIcon} spinning={loading}>
            <div className="form">
              {/* <p className="title">Forget Password</p> */}
              <input
                name="email"
                className="email"
                placeholder="Enter registered email id"
                value={this.state.email}
                type="text"
                onChange={this.onChangeHandler}
              />

              <button
                className="btn btn-primary mt-3 px-5"
                onClick={(e) => this.sendLink(e)}
              >
                Send Link
              </button>
            </div>
          </Spin>
        </div>

        {showToastMsg && (
          <Toast_msg
            dismiss={this.dismiss}
            category={"info"}
            message={this.state.message}
          />
        )}
      </div>
    );
  }
}

export default ForgetPassword;
