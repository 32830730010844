import { actionTypes } from "../action/itemCart";
const initialState = {
  loading: false,
  itemActionLoading: null,
  cartItemList: [],
  metaData: {},
  totalPrice: 0,
  shippingCharges: 0,
  outOfStockItem: [],
};

export const itemCart = (state = initialState, action) => {
  const { type, data, load, metaData, uniqueId } = action;
  switch (type) {
    case actionTypes.LOADING_CART:
      return { ...state, loading: true };
    case actionTypes.LOADING_ITEM_ACTION:
      return { ...state, itemActionLoading: load };
    case actionTypes.STOP_LOADING_ITEM_ACTION:
      return { ...state, itemActionLoading: null };
    case actionTypes.GET_CART_ITEMS_LIST:
      return {
        ...state,
        cartItemList: [...data],
        itemActionLoading: null,
        metaData: { ...metaData },
        loading: false,
      };
    case actionTypes.ADD_TO_CART:
      return {
        ...state,
        cartItemList: [...state.cartItemList, { ...data }],
        itemActionLoading: null,
      };
    case actionTypes.UPDATE_CART:
      let updatedCart = [...state.cartItemList];
      let productIndex = updatedCart.findIndex(
        (item) => item.material === data.material
      );
      updatedCart[productIndex].qty = data.qty;

      return { ...state, cartItemList: [...updatedCart], loading: false };
    case actionTypes.EMPTY_CART:
      return { ...state, cartItemList: [], loading: false };
    case actionTypes.OUT_OF_STOCK_ERROR_IN_CART:
      return {
        ...state,
        outOfStockItem: [...state.outOfStockItem, uniqueId],
        loading: false,
      };

    case actionTypes.REMOVE_OUT_OF_STOCK_ERROR_IN_CART:
      let filterdList = [...state.outOfStockItem].filter(
        (item) => item !== uniqueId
      );
      return { ...state, outOfStockItem: [...filterdList], loading: false };

    default:
      return state;
  }
};
