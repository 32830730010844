import React, { Component } from "react";
import "./drafts.scss";
import add from "../../images/add new plan.svg";
import edit from "../../images/edit.svg";
import trash from "../../images/trash.svg";
import arrow from "../../images/down-arrow.svg";
import user from "../../images/user-logo.svg";
import Modal from "../modal/createPlanModal";
import SalesManDetails from "../modal/salesManDetails";
import { Link } from "react-router-dom";
import SearchBar from "../search-bar/searchBar";
import { LoadingOutlined } from "@ant-design/icons";
import { handleErrorResponse } from "../../utils/Request";
import { Pagination } from "antd";
import upload from "../../images/uploadCsv.png";
import { Modal as _Modal, Upload, Button } from "antd";
import { checkErr } from "../../utils/common";
import { saveAs } from "file-saver";
import {
  getDraftedBeatplanList,
  removeDraftBeatPlan,
  csvTemplateDownload,
  bulkUpload,
  downloadBeatplans,
} from "../../services/beatplanServices/beatplan";
import { salesManDetails } from "../../services/teamServices/team";

import message from "antd/lib/message";
import { Spin } from "antd";
import DeleteConfirmation from "../modal/deleteConfirmation";
import PrimaryHeader from "../header/PrimaryHeader";
export class Drafts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayAddBeatPlanModal: false,
      displaySalesManDetailsModal: false,
      isModalVisible: false,
      file: null,
      page: 1,
      drafts: [],
      searchText: "",
      showRemoveWarning: false,
      removeItemModalDisplayId: "",
      singleSalesmanDetails: "",
      totalPage: 0,
      pageSize: 0,
      totalItems: 0,
      downloadingBeatplans: false,
      fileUploading: false,
    };
  }
  isModalVisibleFun = () => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      file: null,
    });
  };
  displayAddBeatPlanModalFun = () => {
    this.setState({
      displayAddBeatPlanModal: !this.state.displayAddBeatPlanModal,
    });
  };
  openSalesManDetailsModal = async (item) => {
    await salesManDetails(item._id)
      .then((res) => {
        this.setState({ singleSalesmanDetails: res.data.data });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        console.log(err);
      });
    this.setState({
      displaySalesManDetailsModal: !this.state.displaySalesManDetailsModal,
    });
  };

  getDraftedBeatPlan = async () => {
    this.setState({ loading: true });
    console.log("getDraftedBeatPlan is called", this.state.page);
    let { page, searchText } = this.state;
    let { getAllDraftBeatPlans } = this.props;
    let qParams = [{ page }, { search: searchText }];
    await getDraftedBeatplanList(qParams)
      .then((res) => {
        if (res.data.data.results.length == 0) {
          message.warning("No data available!");
        }

        this.setState({
          drafts: res.data.data.results,
          loading: false,
          totalPage: res.data.data.pageMeta.total,
          pageSize: res.data.data.pageMeta.pageSize,
          totalItems: res.data.data.pageMeta.total,
        });
        getAllDraftBeatPlans(res.data.data.results);
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  onSearch = (e) => {
    this.setState(
      {
        searchText: e.target.value,
      },
      () => {
        this.getDraftedBeatPlan();
      }
    );
  };
  removeItemModalDisplay = (item) => {
    this.setState({
      showRemoveWarning: !this.state.showRemoveWarning,
      removeItemModalDisplayId: item._id,
    });
  };
  removeItem = async () => {
    let id = this.state.removeItemModalDisplayId;
    await removeDraftBeatPlan(id)
      .then((res) => {
        message.success("Beat plan has been removed");
        this.setState(
          {
            loading: false,
            showRemoveWarning: false,
          },
          () => {
            this.getDraftedBeatPlan();
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  handleChange = (page, pageSize) => {
    console.log("handleChange is called", page, pageSize);
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.getDraftedBeatPlan();
      }
    );
  };
  downloadForm = async () => {
    console.log("downloadForm is called");
    await csvTemplateDownload()
      .then((res) => {
        console.log("response", res.data.toString().split(","));
        let blob = new Blob([res.data], { type: "text/csv" });
        saveAs(blob, "form.csv");
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        console.log("err", err);
      });
  };

  onChange = (e) => {
    this.setState({ file: e.target.files[0] }, () => {
      console.log("file", this.state.file);
    });
  };
  fileUpload = async (file) => {
    console.log("fileUpload is called");
    this.setState({
      fileUploading: true,
    });
    await bulkUpload(file)
      .then((res) => {
        console.log("res", res);
        message.success("file uploaded successfully!");
        this.setState({
          isModalVisible: false,
          file: null,
          fileUploading: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        console.log("message", err.response.data.message);
        // let _err = err.response.data;
        // let errMsg = checkErr(_err);
        //  console.log("****", _err)
        message.error(err.response.data.message);
        this.setState({
          isModalVisible: false,
          file: null,
          fileUploading: false,
        });
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };
  onFormSubmit = (e) => {
    e.preventDefault();
    let file = this.state.file;
    this.fileUpload(file);
  };
  downloadBeatplansFunction = async () => {
    this.setState({
      downloadingBeatplans: true,
    });
    await downloadBeatplans()
      .then((res) => {
        console.log(res, "kk");
        let blob = new Blob([res.data], { type: "text/csv" });
        saveAs(blob, "beatplans.csv");
        this.setState({
          downloadingBeatplans: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({
          downloadingBeatplans: false,
        });
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };
  componentDidMount() {
    this.getDraftedBeatPlan();
  }

  render() {
    let {
      loading,
      drafts,
      searchText,
      showRemoveWarning,
      removeItemModalDisplayId,
      totalPage,
      pageSize,
      totalItems,
      page,
      isModalVisible,
      downloadingBeatplans,
    } = this.state;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <>
        <div id="content">
          <PrimaryHeader title="View Beat Plans" />
          <Spin indicator={antIcon} spinning={loading}>
            <div className="view-beatplan-header">
              <div className="d-flex align-items-center">
                <SearchBar
                  fromPage="drafts"
                  searchText={searchText}
                  onSearch={this.onSearch}
                />{" "}
                <button
                  className="btn btn-outline-primary ml-4"
                  onClick={this.displayAddBeatPlanModalFun}
                  alt="add beat plan"
                >
                  Create New
                </button>
              </div>
              <div className="d-flex align-items-center">
                {/* <div onClick={this.isModalVisibleFun}> */}
                <img
                  src={upload}
                  alt="upload CSV file"
                  className="pr-2"
                  onClick={this.isModalVisibleFun}
                />
                <p className="bulk-upload mr-4">Bulk Upload</p>
                {/* </div> */}
                <div>
                  {downloadingBeatplans ? (
                    <Spin indicator={antIcon} spinning={downloadingBeatplans} />
                  ) : (
                    <button
                      className="btn btn-outline-primary"
                      onClick={this.downloadBeatplansFunction}
                      title="Download beatplans list"
                    >
                      Download
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="row "> */}
            {/*<header className="header p-3 pb-1 header-border">
                <div className="d-flex">
                  <div className="mr-auto p-2 w-100">
                    <div className="col col-md-3">View Beat Plans</div>
                    <div className="col col-md-3">
                      <a
                        className="generalButton"
                        onClick={this.displayAddBeatPlanModalFun}
                        alt="add beat plan"
                      >
                        Create New
                      </a>
                    </div>
                    <div
                      className="col col-md-3 cursor"
                      style={{ fontSize: "1.5rem" }}
                      onClick={this.isModalVisibleFun}
                    >
                      <img
                        src={upload}
                        alt="upload CSV file"
                        className="pr-2"
                        onClick={this.isModalVisibleFun}
                      />
                      Bulk Upload
                    </div>
                    <div className="col col-md-3">
                      {downloadingBeatplans ? (
                        <Spin
                          indicator={antIcon}
                          spinning={downloadingBeatplans}
                        />
                      ) : (
                        <a
                          className="generalButton"
                          onClick={this.downloadBeatplansFunction}
                          title="Download beatplans list"
                        >
                          Download
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </header> */}
            <_Modal
              visible={isModalVisible}
              title="Bulk Upload"
              destroyOnClose={true}
              closable={false}
              confirmLoading={true}
              footer={[
                <div class="d-flex justify-content-end">
                  <Button
                    key="back"
                    className="antd-btn-cancel cancelBtn col-sm-3"
                    onClick={this.isModalVisibleFun}
                  >
                    Cancel
                  </Button>
                  <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => this.onChange(e)}
                    className="col-sm-6"
                  />

                  {this.state.file != null && (
                    <Button
                      type="submit"
                      className="col-sm-3"
                      onClick={(e) => this.onFormSubmit(e)}
                      disabled={this.state.fileUploading === true}
                    >
                      Upload
                    </Button>
                  )}
                </div>,
              ]}
            >
              <div className="d-flex align-items-center">
                <p className="mb-0 mr-3">
                  Please download the template to upload the CSV File
                </p>
                <Button
                  key="submit"
                  type="primary"
                  className="antd-btn"
                  onClick={this.downloadForm}
                >
                  Download
                </Button>
              </div>
            </_Modal>

            <div className="table-wrapper">
              <table className="table">
                <thead>
                  <tr>
                    {/* headers */}
                    <th>Assigned to</th>
                    <th>Plan ID</th>
                    <th>Added on</th>
                    <th className="change-style" id="status-color">
                      Status{" "}
                      {/* <img src={arrow} alt="down-arrow" id="arrow" /> */}
                    </th>
                    <th>Customers</th>
                    <th>Action </th>
                  </tr>
                </thead>

                <tbody>
                  {drafts &&
                    drafts.map((draft, i) => {
                      return (
                        <tr key={i}>
                          <td
                            className="change-style cursor"
                            onClick={() =>
                              this.openSalesManDetailsModal(
                                draft.salesman.profile
                              )
                            }
                            title="click"
                            id="ID"
                          >
                            <img src={user} alt="logo" id="user-logo" />
                            {draft.salesman &&
                              draft.salesman.profile &&
                              draft.salesman.profile.fullName}
                          </td>{" "}
                          <td className="cursor">
                            <Link
                              to={{
                                pathname: `/dashboard/beatplan/drafts/${draft._id}`,
                                beatPlanId: draft.beatPlanId,
                                _id: draft._id,
                              }}
                              className="id-color"
                              id="ID"
                              title="click"
                            >
                              {draft.beatPlanId}{" "}
                            </Link>
                          </td>
                          <td>{draft.createdAt.slice(0, 10)}</td>
                          <td
                            className={
                              draft.status == 1
                                ? "status active"
                                : "status inactive"
                            }
                          >
                            {draft.status == 1 ? "active" : "inactive"}
                          </td>
                          <td>{draft.numOfCustomerToday}</td>
                          <td>
                            <Link
                              to={{
                                pathname: `/dashboard/beatplan/drafts/${draft._id}`,
                                beatPlanId: draft.beatPlanId,
                                _id: draft._id,
                              }}
                              className="id-color"
                              id="ID"
                              title="click"
                            >
                              <img
                                src={edit}
                                alt="edit"
                                id="edit-icon"
                                title="edit"
                                className="mr-2"
                              />
                            </Link>
                            <img
                              src={trash}
                              alt="trash"
                              title="delete"
                              onClick={() => this.removeItemModalDisplay(draft)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {/* remove item */}
            {showRemoveWarning && (
              <DeleteConfirmation
                header={"Clear Draft Beat Plan"}
                warningMsg={`Permanently clear the beat plan? You can’t undo this.`}
                closeDeleteConfirmationModal={this.removeItemModalDisplay}
                removeItem={this.removeItem}
                removeId={removeItemModalDisplayId}
              />
            )}
            {/* add bit plan modal */}
            {this.state.displayAddBeatPlanModal && (
              <Modal displayModal={this.displayAddBeatPlanModalFun} />
            )}
            {/* displaySalesManDetailsModal */}
            {this.state.displaySalesManDetailsModal && (
              <SalesManDetails
                displayModal={this.openSalesManDetailsModal}
                details={this.state.singleSalesmanDetails}
              />
            )}
            {/* </div> */}

            {/* pagination */}

            <div className="pagination">
              {totalItems != 0 && (
                <p className="p-2">Total {totalItems} plans </p>
              )}
              {totalPage != 0 && (
                <Pagination
                  className="page"
                  defaultCurrent={1}
                  current={page}
                  total={totalPage}
                  defaultPageSize={pageSize}
                  showSizeChanger={false}
                  onChange={(page, pageSize) =>
                    this.handleChange(page, pageSize)
                  }
                />
              )}
            </div>
          </Spin>
        </div>
      </>
    );
  }
}

export default Drafts;
