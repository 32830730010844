import React, { Component } from "react";
import "./customers.scss";
import { Link } from "react-router-dom";
import SearchBar from "../search-bar/searchBar";
import customerFilterBtn from "../../images/customer-filter-btn.svg";
import sync from "../../images/sync.svg";
import arrow from "../../images/down-arrow.svg";
import user from "../../images/user-logo.svg";
import filterIcon from "../../images/filter.svg";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { Spin } from "antd";
import {
  LoadingOutlined,
  SyncOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { formatMoney, stringSortingByChar } from "../../utils/common";
import {
  getAllCustomerDetails,
  getDataFromGoFrugal,
  getDataFromTally,
  getSalesmenList,
  getGrpANdSubGrpForFilter,
} from "../../services/customerServices/customer";
import { Pagination } from "antd";
import moment from "moment";
import { FaAngleUp } from "react-icons/fa";
import Select from "react-select";
import PrimaryHeader from "../header/PrimaryHeader";
import { AsyncPaginate } from "react-select-async-paginate";
class Customers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scroller: false,
      loading: false,
      check: false,
      search: "",
      showFilter: false,

      totalPage: 0,
      pageSize: 0,
      page: 1,
      totalItems: 0,
      inSync: false,
      isAccountsSync: false,
      lastUpdateTime: "",
      lastCustomerAccountsUpdateTime: "",
      errorInLastCustomerAccountSync: "",
      groupName: [],
      salesmenList: [],
      selectedSalesman: [],
      customerGrpSubgrp: [],
      grp: [],
      subGrp: [],
      choosedSubgrpName: [],
      // choosedSubgrpName: "",
    };
  }

  changeTime = (time) => {
    let gmtDateTime = moment.utc(time);
    let local = gmtDateTime.local().format("h:mm A | DD MMM YYYY");
    return local;
  };
  getAllCustomersFunction = async (body) => {
    this.setState({ loading: true });

    let { search, page } = this.state;

    let pageNo = this.state.showFilter == true ? 1 : page;
    let { getAllCustomers } = this.props;
    let qParams = [{ page: pageNo }, { search }];
    await getAllCustomerDetails(qParams, body)
      .then((res) => {
        {
          res.data.data.results.length == 0 &&
            message.warning("No data available ");
        }
        this.setState({
          loading: false,
          inSync: res.data.data.syncStatus.isSyncing === true ? true : false,
          isAccountsSync:
            res.data.data.syncStatus.isCustomerAccountSyncing === true
              ? true
              : false,
          lastUpdateTime: this.changeTime(
            res.data.data.syncStatus.lastCustomerListSync
          ),
          lastCustomerAccountsUpdateTime: this.changeTime(
            res.data.data.syncStatus.lastCustomerAccountsSync
          ),
          errorInLastCustomerAccountSync:
            this.syncErrStatusCheck(
              res.data.data.syncStatus.lastCustomerAccountsSync,
              res.data.data.syncStatus.customerAccountsSyncStarted
            ) == true
              ? res.data.data.syncStatus.errorInLastCustomerAccountSync
              : "",
          totalPage: res.data.data.pageMeta.total,
          pageSize: res.data.data.pageMeta.pageSize,
          totalItems: res.data.data.pageMeta.total,
        });

        // calling action
        getAllCustomers(res.data.data.results);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  getAllSalesman = async () => {
    this.setState({ loading: true });
    await getSalesmenList()
      .then((res) => {
        let array = res.data.data;
        let arr = array.map((item) => {
          let newItem = {
            value: item._id,
            label: item.fullName + "(" + item.employeeId + ")",
            // label: item.employeeId,
            // employer: item.employerName,
          };
          return newItem;
        });
        this.setState({
          loading: false,
          salesmenList: arr,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };
  getGrpANdSubGrpForFilterFunction = async () => {
    this.setState({ loading: true });
    await getGrpANdSubGrpForFilter()
      .then((res) => {
        let grpArr = res.data.data.map((item) => {
          return {
            value: item.group,
            label: item.group,
            subGroup: item.subGroup,
          };
        });
        this.setState({
          loading: false,
          customerGrpSubgrp: res.data.data,
          grp: grpArr,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  handleChange = (page, pageSize) => {
    console.log("handleChange is called", page, pageSize);
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        let { groupName, choosedSubgrpName, selectedSalesman } = this.state;
        let group =
          groupName.length != 0 &&
          groupName.map((x) => {
            return x.label;
          });
        let subGroup =
          choosedSubgrpName.length != 0 &&
          choosedSubgrpName.map((x) => {
            return x.label;
          });
        let salesmanId =
          selectedSalesman.length != 0 &&
          selectedSalesman.map((x) => {
            return x.value;
          });

        let body = {
          group: groupName.length == 0 ? [] : group,
          subGroup: choosedSubgrpName.length == 0 ? [] : subGroup,
          salesmanId: selectedSalesman.length == 0 ? [] : salesmanId,
        };

        this.getAllCustomersFunction(body);
      }
    );
  };
  syncErrStatusCheck = (lastCustomerListSync, customerListSyncStarted) => {
    var a = moment(lastCustomerListSync);
    var b = moment(customerListSyncStarted);
    return a.isBefore(b);
  };
  // customer list sync
  startSyncing = async () => {
    // call api

    await getDataFromGoFrugal()
      .then((res) => {
        let { page, search } = this.state;
        let qParams = [{ page }, { search }];
        getAllCustomerDetails(qParams)
          .then((res) => {
            let a = res.data.data.syncStatus.lastCustomerListSync;
            let b = res.data.data.syncStatus.customerListSyncStarted;
            if (res.data.data.syncStatus.isSyncing === true) {
              this.setState({
                inSync: true,
              });
              let syncingMsg = "Syncing process has been started";
              message.info(syncingMsg);
            } else if (
              res.data.data.syncStatus.isSyncing == false &&
              this.syncErrStatusCheck(a, b) == true
            ) {
              this.setState({
                inSync: false,
              });
              let msg = res.data.syncStatus.lastCustomerAccountsSync;
              message.error(msg);
            }
          })
          .catch((err) => {
            const { errorMessage, errors } = handleErrorResponse(err);
            message.error(errorMessage);
          });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        if (errorMessage === "Customer List Sync is already Running !") {
          this.setState({
            inSync: true,
          });
        }
      });
  };
  // start customer accounts sync
  startCustomerAccountsSyncing = async () => {
    // call api
    await getDataFromTally()
      .then((res) => {
        let { page, search } = this.state;
        let qParams = [{ page }, { search }];
        getAllCustomerDetails(qParams)
          .then((res) => {
            let a = res.data.data.syncStatus.lastCustomerAccountsSync;
            let b = res.data.data.syncStatus.customerAccountsSyncStarted;
            if (res.data.data.syncStatus.isCustomerAccountSyncing === true) {
              this.setState({
                isAccountsSync: true,
              });
              let syncingMsg = "Syncing process has been started";
              message.info(syncingMsg);
            } else if (
              res.data.data.syncStatus.isCustomerAccountSyncing == false &&
              this.syncErrStatusCheck(a, b) == true
            ) {
              this.setState({
                isAccountsSync: false,
              });
              let msg = res.data.syncStatus.lastCustomerAccountsSync;
              message.error(msg);
            }
          })
          .catch((err) => {
            const { errorMessage, errors } = handleErrorResponse(err);
            message.error(errorMessage);
          });
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
        if (errorMessage === "Customer Account Sync is already Running !") {
          this.setState({
            isAccountsSync: true,
          });
        }
      });
  };
  onSearch = (e) => {
    this.setState(
      {
        search: e.target.value,
        page: 1,
      },
      () => {
        let body = {};
        this.getAllCustomersFunction(body);
      }
    );
  };

  // handle scroll
  handleScroll = (e) => {
    if (e.target.scrollTop > 0) this.setState({ scroller: true });
    else this.setState({ scroller: false });
  };

  // filter functions
  showFilterOptions = () => {
    this.setState({
      showFilter: !this.state.showFilter,
    });
  };
  chooseGroupName = (e) => {
    console.log("group name", e);
    console.log("state groupName", this.state.groupName);
    if (e != null) {
      let subGroupArr = [];
      e.length != 0 &&
        e.forEach((item) => {
          subGroupArr.push(item.subGroup);
        });

      let newSubgrpArr = subGroupArr.flat();
      let modified = newSubgrpArr.map((item) => {
        return { value: item, label: item };
      });

      let parameter = e.length < this.state.groupName;
      console.log("parameter", parameter);
      this.setState({
        groupName: e,
        subGrp: modified,
        choosedSubgrpName: [],
      });
    }

    if (e != null && e.length == 0) {
      this.setState({
        groupName: [],
        choosedSubgrpName: [],
        subGrp: [],
      });
    }
    if (e == null) {
      this.setState({
        groupName: [],
        choosedSubgrpName: [],
        subGrp: [],
      });
    }
  };
  chooseSubgrp = (e) => {
    if (e != null) {
      this.setState({
        choosedSubgrpName: e,
      });
    }
    if (e == null) {
      this.setState({
        choosedSubgrpName: [],
      });
    }
  };
  chooseSalesman = (e) => {
    if (e != null) {
      this.setState({
        selectedSalesman: e,
      });
    }
    if (e == null) {
      this.setState({
        selectedSalesman: [],
      });
    }
  };
  clearFilter = () => {
    let { groupName, subGrp, choosedSubgrpName, selectedSalesman } = this.state;

    if (
      groupName.length == 0 &&
      subGrp.length == 0 &&
      choosedSubgrpName.length == 0 &&
      selectedSalesman.length == 0
    ) {
      message.warning("No filter selected");
    } else {
      this.setState(
        {
          groupName: [],
          subGrp: [],
          choosedSubgrpName: [],
          // choosedSubgrpName: "",
          selectedSalesman: [],
        },
        () => {
          let body = {};
          this.getAllCustomersFunction(body);
        }
      );
    }
  };

  applyFilters = () => {
    let { groupName, selectedSalesman, choosedSubgrpName } = this.state;

    if (groupName.length == 0 && selectedSalesman.length == 0) {
      message.warning("Please select atleast one filter");
    } else {
      let group =
        groupName.length != 0 &&
        groupName.map((x) => {
          return x.label;
        });
      let subGroup =
        choosedSubgrpName.length != 0 &&
        choosedSubgrpName.map((x) => {
          return x.label;
        });
      let salesmanId =
        selectedSalesman.length != 0 &&
        selectedSalesman.map((x) => {
          return x.value;
        });
      let body = {
        group: groupName.length == 0 ? [] : group,
        subGroup: choosedSubgrpName.length == 0 ? [] : subGroup,
        salesmanId: selectedSalesman.length == 0 ? [] : salesmanId,
      };

      this.getAllCustomersFunction(body);
    }
  };
  componentDidMount = () => {
    let body = {};
    this.getAllCustomersFunction(body);
    this.getAllSalesman();
    // this.getGrpANdSubGrpForFilterFunction();  as we are not using filter by sub grp also it was throwing 500 error
  };
  // render
  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    let {
      scroller,
      loading,
      showFilter,
      totalPage,
      pageSize,
      page,
      totalItems,
      inSync,
      isAccountsSync,
      lastUpdateTime,
      lastCustomerAccountsUpdateTime,
      errorInLastCustomerAccountSync,
      groupName,
      flavourOptions,
      salesmenList,
      selectedSalesman,
      grp,
      subGrp,
      choosedSubgrpName,
    } = this.state;
    let { allCustomersDetails } = this.props;
    return (
      <div id="content">
        <PrimaryHeader title="Customers" />
        <Spin indicator={antIcon} spinning={loading}>
          <div className="customers-header">
            <SearchBar
              onSearch={this.onSearch}
              searchText={this.state.search}
              fromPage="customer"
              disProp={this.state.loading === true ? true : false}
            />
            <div className="d-flex align-items-center">
              {/* <div className="mr-3">
                <p className="last-update mb-2">
                  {lastUpdateTime && `LAST UPDATED ON ${lastUpdateTime}`}
                </p>
                <p className="last-update mb-0">
                  {lastCustomerAccountsUpdateTime &&
                    `LAST TALLY UPDATED ON ${lastCustomerAccountsUpdateTime}`}
                  {errorInLastCustomerAccountSync && (
                    <p className="text-danger">
                      {" "}
                      ERROR WHILE SYNC TALLY- {
                        errorInLastCustomerAccountSync
                      }{" "}
                    </p>
                  )}
                </p>
              </div> */}
              <div className="d-flex align-items-center">
                <img
                  src={filterIcon}
                  alt="filter"
                  className="filter-img mr-5"
                  onClick={this.showFilterOptions}
                />
                {/* <SyncOutlined
                  style={{
                    fontSize: 20,
                    color: "blue",
                  }}
                  onClick={this.startSyncing}
                  title="Click to start Go Frugal sync"
                  spin={this.state.inSync}
                  className="mr-3"
                />
                <SyncOutlined
                  style={{
                    fontSize: 20,
                    color: "purple",
                  }}
                  title="Click to start Accounts sync"
                  onClick={this.startCustomerAccountsSyncing}
                  spin={this.state.isAccountsSync}
                  className="mr-3"
                /> */}
              </div>
            </div>
          </div>
          {showFilter && (
            <div className="filter-wrapper">
              <div className="filter-heading">Filters</div>
              <div className="filters">
                {/* <div className="drop_dwn">
                  <Select
                    value={groupName}
                    isMulti
                    name="Customers"
                    placeholder="Customer Group"
                    loadoptions={grp}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.chooseGroupName(e)}
                  />
                </div>
                <div className="drop_dwn">                  
                  <Select
                    value={choosedSubgrpName}
                    isMulti
                    placeholder="Customer Sub-Group"
                    name="Customers subgroup"
                    options={subGrp}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.chooseSubgrp(e)}
                  />
                  {groupName == "" && (
                    <p className="select_grp">
                      Select a group name to get subgroup options{" "}
                    </p>
                  )}
                </div> */}
                <div className="drop_dwn salesman_drop_dwn">
                  <Select
                    value={selectedSalesman}
                    isMulti
                    name="salesman"
                    placeholder="Salesman"
                    options={salesmenList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.chooseSalesman(e)}
                  />
                </div>
              </div>
              <div className="filter-btn-para">
                <p className="clear-filters" onClick={this.showFilterOptions}>
                  <span className="mr-4" onClick={this.applyFilters}>
                    APPLY FILTERS
                  </span>{" "}
                  <span onClick={this.clearFilter}>CLEAR FILTERS</span>
                </p>
              </div>
            </div>
          )}

          <div
            className="table-wrapper"
            // className={
            //   showFilter
            //     ? "customer-list pl-2 new-height"
            //     : "customer-list pl-2"
            // }
            // ref="customerList"
            // onScroll={this.handleScroll}
          >
            {allCustomersDetails.length > 0 && (
              <table className="table common-table">
                <thead>
                  <tr>
                    <th>Customer</th>
                    <th>Customer ID</th>
                    <th>Contact</th>
                    <th>City</th>
                    <th>Price Level Code</th>
                    {/* <th className="change-style" id="status-color">
                      Outstanding(&#8377;)
                    </th>
                    <th>Overdue(&#8377;)</th>
                    <th>Credit Limit(&#8377;)</th>
                    <th>Credit Days</th> */}
                    <th>Salesman Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allCustomersDetails.map((customer, i) => {
                    return (
                      <tr key={i}>
                        <td
                          className="change-style name-td cursor"
                          title={customer.name}
                        >
                          <img src={user} alt="logo" id="user-logo" />
                          {stringSortingByChar(customer.name, 18)}
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: `/dashboard/customers/${customer._id}`,
                              customerIdProps: customer._id,
                              customerLocationProps: customer.city,
                            }}
                          >
                            {customer.goFrugalId && customer.goFrugalId}
                          </Link>
                        </td>

                        <td>
                          {customer.mobile == " " ? "N/A" : customer.mobile}
                        </td>
                        <td>{customer.city ? customer.city : "N/A"}</td>

                        <td>
                          {customer.pricingLevel && customer.pricingLevel.id
                            ? customer.pricingLevel.id
                            : "N/A"}
                        </td>

                        {/* <td>
                          {formatMoney(
                            customer.customerAccounts &&
                              customer.customerAccounts.totalOutStanding
                              ? customer.customerAccounts.totalOutStanding
                              : "N/A"
                          )}
                        </td> */}

                        {/* <td style={{ color: "red" }}>
                          {formatMoney(
                            customer.customerAccounts &&
                              customer.customerAccounts.overdueOutStanding
                              ? customer.customerAccounts.overdueOutStanding
                              : "N/A"
                          )}
                        </td> */}

                        {/* <td>
                          {formatMoney(
                            customer.customerAccounts && customer.creditLimit
                          )}
                        </td> */}
                        {/* <td
                          title={
                            customer.customerAccounts &&
                            customer.customerAccounts.creditDays
                              ? customer.customerAccounts.creditDays
                              : ""
                          }
                        >
                          {customer.customerAccounts &&
                          customer.customerAccounts.creditDays
                            ? customer.customerAccounts.creditDays
                            : ""}
                        </td> */}

                        <td
                          style={{ fontSize: "14px" }}
                          title={
                            customer.salesmanName &&
                            customer.salesmanName.employeeId
                          }
                        >
                          {" "}
                          {customer.salesmanName &&
                            customer.salesmanName.fullName}
                        </td>
                        <td>
                          {customer.active_state
                            ? customer.active_state
                            : "N/A"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>

          <div className="pagination">
            {totalItems != 0 && (
              <p className="p-2">Total {totalItems} Customers </p>
            )}
            {totalPage != 0 && (
              <Pagination
                className="page"
                defaultCurrent={1}
                current={page}
                total={totalPage}
                defaultPageSize={pageSize}
                showSizeChanger={false}
                onChange={(page, pageSize) => this.handleChange(page, pageSize)}
              />
            )}
          </div>
        </Spin>
      </div>
      // </div>
    );
  }
}
export default Customers;
