import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import "./index.scss";
import PrimaryHeader from "../../header/PrimaryHeader";
import {
    getMaterialCode,
    getStockBatchId,
    getStockStorageLocation,
    getMaterialGroup,
} from "../../../services/inventoryServices/inventoryServices";
import { handleErrorResponse } from "../../../utils/Request";
import message from "antd/lib/message";
import { upperFirst } from "lodash";

export default function StockAudit(props) {
    console.log(props, "stockAuditProps");

    const [asyncValues, setAsyncValues] = React.useState({
        materialCode: "",
        storageLocation: "",
        batchId: "",
        materialGroup: "",
    });

    const searchMethod = () => {
        props.history.push({
            pathname: "/dashboard/inventory-management/searchResults",
            state: { location: asyncValues },
        });
    };

    const handleChange = (e, type) => {
        if (type == "materialCode") {
            setAsyncValues((prev) => ({
                ...prev,
                materialCode: e,
            }));
        }
        if (type == "storageLocation") {
            setAsyncValues((prev) => ({
                ...prev,
                storageLocation: e,
            }));
        }
        if (type == "batchId") {
            setAsyncValues((prev) => ({
                ...prev,
                batchId: e,
            }));
        }
        if (type == "materialGroup") {
            setAsyncValues((prev) => ({
                ...prev,
                materialGroup: e,
            }));
        }
    };

    console.log(asyncValues, "asyncvalues");

    const storageLocation = async (search, loadedOptions, { page }) => {
        let qParams = [{ page: page }, { pageSize: 5 }, { location: search }];
        let data = await getStockStorageLocation(qParams).then((res) => {
            console.log(res, "ress");
            return {
                options:
                    res.data.data &&
                    res.data.data.map((item) => {
                        return {
                            key: item._id,
                            value: item.location,
                            label: upperFirst(item.location),
                        };
                    }),
                totalPage:
                    parseInt(15 * res.data.data.length || 0) /
                    parseInt(15 || 0),
            };
        });
        return {
            options: data ? data.options : [],
            hasMore: data.totalPage > page ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };

    const loadBatchId = async (search, loadedOptions, { page }) => {
        let qParams = [{ page: page }, { pageSize: 5 }, { batchId: search }];
        let data = await getStockBatchId(qParams).then((res) => {
            console.log(res, "res");
            return {
                options:
                    res.data.data &&
                    res.data.data.map((item) => {
                        return {
                            key: item._id,
                            value: item.BatchID,
                            label: upperFirst(item.BatchID),
                        };
                    }),
                totalPage:
                    parseInt(15 * res.data.data.length || 0) /
                    parseInt(15 || 0),
            };
        });
        return {
            options: data ? data.options : [],
            hasMore: data.totalPage > page ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };

    const loadMaterialcode = async (search, loadedOptions, { page }) => {
        let qParams = [{ page: page }, { pageSize: 5 }, { material: search }];
        let data = await getMaterialCode(qParams).then((res) => {
            console.log(res, "resul");
            return {
                options:
                    res.data.data &&
                    res.data.data.map((item) => {
                        return {
                            key: item._id,
                            value: item.material,
                            label: upperFirst(item.material),
                        };
                    }),
                totalPage:
                    parseInt(15 * res.data.data.length || 0) /
                    parseInt(15 || 0),
            };
        });
        return {
            options: data ? data.options : [],
            hasMore: data.totalPage > page ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };

    const loadMaterialGroup = async (search, loadedOptions, { page }) => {
        let qParams = [
            { page: page },
            { pageSize: 5 },
            { materialGroup: search },
        ];
        let data = await getMaterialGroup(qParams).then((res) => {
            console.log(res, "resul");
            return {
                options:
                    res.data.data &&
                    res.data.data.map((item) => {
                        return {
                            key: item._id,
                            value: item["Material Group"],
                            label: upperFirst(item["Material Group"]),
                        };
                    }),
                totalPage:
                    parseInt(15 * res.data.data.length || 0) /
                    parseInt(15 || 0),
            };
        });
        return {
            options: data ? data.options : [],
            hasMore: data.totalPage > page ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };

    return (
        <div id="content">
            <PrimaryHeader title={"Stock Audit"} />
            <div className="stock_card">
                <div className="card">
                    <div className="card-body">
                        <div className="stock_title">
                            <label htmlFor="" className="border_left">
                                {"Search Stock"}
                            </label>
                        </div>
                        <div className="stock_data">
                            <div className="mt-5">
                                <div className="stock_labels">
                                    <label htmlFor="">
                                        {"Material Code/Name"}
                                    </label>
                                </div>
                                <div className="async_width">
                                    <AsyncPaginate
                                        className="basic-multi-select"
                                        additional={{
                                            page: 1,
                                        }}
                                        loadOptions={loadMaterialcode}
                                        isClearable={true}
                                        isSearchable={true}
                                        closeMenuOnSelect={true}
                                        value={asyncValues["materialCode"]}
                                        onChange={(e) =>
                                            handleChange(e, "materialCode")
                                        }
                                        loadingMessage={() =>
                                            "No code/name Found"
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="stock_labels">
                                    <label htmlFor="">
                                        {"Storage Location"}
                                    </label>
                                </div>
                                <div className="async_width">
                                    <AsyncPaginate
                                        className="basic-multi-select"
                                        additional={{
                                            page: 1,
                                        }}
                                        loadOptions={storageLocation}
                                        isClearable={true}
                                        isSearchable={true}
                                        closeMenuOnSelect={true}
                                        value={asyncValues["storageLocation"]}
                                        onChange={(e) =>
                                            handleChange(e, "storageLocation")
                                        }
                                        loadingMessage={() =>
                                            "No location Found"
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="stock_labels">
                                    <label htmlFor="">{"Batch ID"}</label>
                                </div>
                                <div className="async_width">
                                    <AsyncPaginate
                                        className="basic-multi-select"
                                        additional={{
                                            page: 1,
                                        }}
                                        loadOptions={loadBatchId}
                                        isClearable={true}
                                        isSearchable={true}
                                        closeMenuOnSelect={true}
                                        value={asyncValues["batchId"]}
                                        onChange={(e) =>
                                            handleChange(e, "batchId")
                                        }
                                        loadingMessage={() => "No ID's Found"}
                                    />
                                </div>
                            </div>
                            {/* <div className="mt-4">
                                <div className="stock_labels">
                                    <label htmlFor="">{"Material Group"}</label>
                                </div>
                                <div className="async_width">
                                    <AsyncPaginate
                                        className="basic-multi-select"
                                        additional={{
                                            page: 1,
                                        }}
                                        loadOptions={loadMaterialGroup}
                                        isClearable={true}
                                        isSearchable={true}
                                        closeMenuOnSelect={true}
                                        value={asyncValues["materialGroup"]}
                                        onChange={(e) =>
                                            handleChange(e, "materialGroup")
                                        }
                                        loadingMessage={() => "No Groups Found"}
                                    />{" "}
                                </div>
                            </div> */}
                        </div>

                        <div
                            className="mt-4 mb-4"
                            style={{ marginLeft: "110px" }}
                        >
                            <button
                                className="btn btn-primary"
                                onClick={searchMethod}
                                // disabled={Object.values(asyncValues).every(
                                //     (item) => {
                                //         if (item === "" || item === null) {
                                //             return true;
                                //         }
                                //     }
                                // )}
                            >
                                {"Search"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
