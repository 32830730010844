import React from "react";
import PrimaryHeader from "../../header/PrimaryHeader";
import { useState, useEffect, useRef } from "react";
import arrowleft from "../../../images/arrow-left.jpg";
import download from "../../../images/download (1).svg";
import print from "../../../images/printer.svg";
import { Modal, Pagination } from "antd";
import { handleErrorResponse } from "../../../utils/Request";
import message from "antd/lib/message";
import { Spin } from "antd";
import { formatMoney, stringSortingByChar } from "../../../utils/common";
import { useSelector, useDispatch } from "react-redux";
import {
    getSearchClosingStock,
    getSearchClosingStockByStockType,
    downloadDoc,
    getSearchClosingStockByMaterialDetails,
} from "../../../services/inventoryServices/inventoryServices";
import { saveAs } from "file-saver";
import { LoadingOutlined } from "@ant-design/icons";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./printTable";
import moment from "moment";

export default function ClosingStock(props) {
    const [openModal, setopenModal] = useState(false);
    const [viewData, setViewData] = useState([]);
    const [closingStockListItems, setclosingStockListItems] = useState([]);
    const [loading, setloading] = useState(false);
    const [skipPage, setSkipPage] = useState(0);
    const [totalitems, setTotalitems] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [page, setPage] = useState(1);
    const [date, setDate] = useState("");
    const [tab, setTab] = useState("");
    const [limit, setLimit] = useState(10);
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let componentRef = useRef();
    useEffect(() => {
        console.log(props.location.state);
        searchResults();
        setTab(props.location.state && props.location.state.tab);
        setDate(props.location.state && props.location.state.date);
    }, [page]);

    const getServiceCallApi = async (Params) => {
        setloading(true)
        if (props.location.state && props.location.state.tab == "stockType") {
            await getSearchClosingStockByStockType(Params)
                .then((res) => {
                    console.log(res.data.data.pageMeta);
                    setloading(false)
                    setclosingStockListItems(
                        res.data.data && res.data.data.searchListing
                    );
                    setPageSize(res.data.data.pageMeta.pageSize * 7);
                    setSkipPage(res.data.data.pageMeta.skip);
                    setTotalitems(res.data.data.pageMeta.total);
                })

                .catch((err) => {
                    console.log(err, "errrr");
                    setloading(false)
                    const { errorMessage } = handleErrorResponse(err);
                    message.error(errorMessage);
                });
        } else {
            await getSearchClosingStockByMaterialDetails(Params)
                .then((res) => {
                    console.log(res.data.data.pageMeta);
                    setloading(false)
                    setclosingStockListItems(
                        res.data.data && res.data.data.searchListing
                    );
                    setPageSize(res.data.data.pageMeta.pageSize * 7);
                    setSkipPage(res.data.data.pageMeta.skip);
                    setTotalitems(res.data.data.pageMeta.total);
                })

                .catch((err) => {
                    console.log(err, "errrr");
                    const { errorMessage } = handleErrorResponse(err);
                    message.error(errorMessage);
                });
        }
    };

    const searchResults = () => {
        if (props.location.state && props.location.state.tab == "matDetails") {
            // const date =
            //   props.location.state &&
            //   props.location.state.inputs &&
            //   props.location.state.inputs.date;
            // setDate(date);

            let inputs =
                props.location.state && props.location.state.inputs
                    ? props.location.state.inputs
                    : "";
            // let delDate = delete inputs.date;

            //let Params = [{ from: date }, {to:date},{ limt: limit },inputs];
            let Params = [...inputs, { page: page }];
            console.log(Params);

            getServiceCallApi(Params);
        } else {
            // const date =
            //   props.location.state &&
            //   props.location.state.inputs &&
            //   props.location.state.inputs.date;
            // setDate(date);
            let inputs = props.location.state && props.location.state.inputs;
            //let delDate = delete inputs.date;
            // let Params = [{ date: date }, { limt: limit }];
            let Params = [...inputs, { page: page }];
            getServiceCallApi(Params, inputs);
        }
    };

    console.log(page)

    const viewInfo = (item) => {
        setopenModal(!openModal);
        setViewData(item && item.StorageLocation);
    };
    const listOfClosingStock =
        closingStockListItems.length > 0 &&
        closingStockListItems.map((item) => {
            return (
                <tr>
                    <td>
                        {item.Material.substring(0, item.Material.indexOf(" "))}

                        <p>
                            {item.Material.substring(
                                item.Material.indexOf(" ") + 1
                            )}
                        </p>
                    </td>
                    <td>{item.BatchID ? item.BatchID : "N/A"}</td>
                    {/* <td>{item.MaterialGroup ? item.MaterialGroup : "N/A"}</td>
                    <td>{item.MaterialType ? item.MaterialType : "N/A"}</td> */}
                    <td>{item.UOM ? item.UOM : "N/A"}</td>
                    <td
                        className="cursor"
                        style={{ textDecoration: "underline" }}
                        onClick={() => viewInfo(item)}
                    >
                        View Info
                    </td>

                    <td>{item.TotalQuantity ? item.TotalQuantity : 0}</td>

                    <td>{item.TotalValue ? item.TotalValue : 0}</td>
                </tr>
            );
        });
    const closeModal = () => {
        setopenModal(!openModal);
    };
    const navigateToBack = () => {
        props.history.push({
            pathname: "/dashboard/inventory-management/current-stock",
            state: { tab: tab },
        });
    };

    const downloadExcelSheets = async () => {
        setloading(true);
        // let from = moment(date).format("DD-MM-YYYY");
        // let to =  moment(date).format("DD-MM-YYYY");
        let from = date;
        let to = date;
        await downloadDoc(from, to)
            .then((res) => {
                setloading(false);
                // if ( res.response.status === 204) {
                //   alert()
                // }else{
                let blob = new Blob([res.data], { type: "text/csv" });
                saveAs(blob, `data`);
                message.success("download sucessfully");
                // }
            })
            .catch((err) => {
                setloading(false);
                const { errorMessage, errors } = handleErrorResponse(err);
                message.error(errorMessage);
            });
    };

    const handleChange = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
        let Params = [{ page }, { limit: limit }];
        // searchResults();
    };

    const pdfFormat = () => {
        var doc = new jsPDF();

        var col = [
            "Material",
            "Batch ID",
            "Material Group",
            "Materail Type",
            "UOM",
            "Storage Location",
            "Total Quantity",
            "Total Value",
        ];

        var rows = [];

        closingStockListItems &&
            closingStockListItems.map((item) => {
                let temp1 = [
                    item.Material,
                    item.BatchID,
                    item.MaterialGroup,
                    item.MaterialType,

                    item.UOM,
                    "",
                    item.TotalQuantity,
                    item.totalValue,
                ];

                rows.push(temp1);
            });

        let doc1 = doc.autoTable(col, rows, { startY: 10 });
        console.log(doc1);
        // doc.save('document1')
        // let documentToPrint = document.getElementById("print_table");
        // let win = window.open();
        // win.document.write('document1');
        // // let document1 = doc.save("ClosingStock.pdf");
        // win.print("document1");
    };

    const [a, b, c, d, e, f, g, h] = viewData;
    console.log("Slaed", viewData);
    return (
        <div id="content" className="">
            <PrimaryHeader title="Closing Stock" />
            <div className="card ml-1" style={{ width: "55rem" }}>
                <div class="card-body mt-3">
                    <img src={arrowleft} onClick={navigateToBack} />
                    <span className="ml-2 " style={{ color: "#5078F2" }}>
                        Back
                    </span>

                    <p className="border_left subheader mt-4 ml-2">
                        Search Results
                    </p>
                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-outline-dark"
                            onClick={downloadExcelSheets}
                        >
                            Download
                            <img src={download} className="pl-2" />
                        </button>
                        {/* <button
              type="button"
              className="btn btn-outline-dark mr-2 ml-2"
              onClick={pdfFormat}
            >
              Print
              <img src={print} className="pl-2" />
            </button> */}
                        {/* <div>
              <ReactToPrint
                trigger={() => (
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-dark ml-2 mr-2"
                    >
                      {"Print"}
                      <img src={print} className="cursor" />
                    </button>
                  </div>
                )}
                content={() => componentRef}
              />
              <div style={{ display: "none" }}>
                <ComponentToPrint
                  ref={(el) => (componentRef = el)}
                  data={closingStockListItems}
                />
              </div>
            </div> */}
                        {/* <button
              type="button"
              className="btn btn-outline-dark ml-2 mr-2"
              //  onClick={() =>
              //  console.log("first")}
            >
              Print
              <ReactToPrint
                trigger={() => (
                  <div>
                    {" "}
                    <img src={print} className="cursor" />
                  </div>
                )}
                content={() => componentRef}
              />
              <div style={{ display: "none" }}>
                <ComponentToPrint
                  ref={componentRef}
                  data={listOfClosingStock}
                />
              </div>
            </button> */}
                    </div>
                    <div className="d-flex justify-content-center">
                        <Spin indicator={antIcon} spinning={loading}></Spin>
                    </div>
                    <div className="mt-4  table-wrapper" id="print_table">
                        <table className="table common-table">
                            <thead className="remove-border-top">
                                <th scope="col">Material</th>
                                <th>Batch ID</th>
                                {/* <th>Material Group</th>
                                <th>Material Type</th> */}
                                <th>UOM</th>
                                <th>Storage Location</th>
                                <th>Total Quantity</th>
                                <th>Total Value</th>
                            </thead>
                            <tbody>
                                {listOfClosingStock && listOfClosingStock}
                                {listOfClosingStock.length === 0 && (
                                    <div className="no-data">
                                        <p>No Data Available</p>
                                    </div>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-center">
                        {pageSize != 0 && (
                            <Pagination
                                defaultCurrent={1}
                                current={page}
                                total={totalitems}
                                //defaultPageSize={page}
                                showSizeChanger={false}
                                onChange={(page, pageSize) =>
                                    handleChange(page, pageSize)
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            {openModal && (
                <>
                    <Modal
                        width={450}
                        visible={openModal}
                        closable
                        maskClosable={true}
                        footer={null}
                        onCancel={closeModal}
                        bodyStyle={{ height: 200 }}
                    >
                        <div className="table-wrapper">
                            <table className="table common-table">
                                <thead className="remove-border-top">
                                    <th scope="col">Unrestricted</th>
                                    <th>Silo</th>
                                    <th>Cold Room</th>
                                    <th>Sales</th>
                                    <th>Dump</th>
                                    <th>Expired</th>
                                    {/* <th>Sales Return</th> */}
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{(a && a).toFixed(2)}</td>
                                        <td>{(b && b).toFixed(2)}</td>
                                        <td>{(c && c).toFixed(2)}</td>
                                        <td>{(d && d).toFixed(2)}</td>
                                        <td>{(e && e).toFixed(2)}</td>
                                        <td>{(f && f).toFixed(2)}</td>

                                        {/* <td>{h && h}</td> */}
                                    </tr>
                                    {/* {viewData &&
                    viewData.length > 0 &&
                    viewData.map((item) => {
                      return (
                        <tr>
                          <td>{a ? a : "N/A"}</td>
                          <td>{b}</td>
                          <td>{c}</td>
                          <td>{d}</td>
                          <td>{e}</td>
                          <td>{f}</td>
                          <td>{g}</td>
                        </tr>
                      );
                    })} */}
                                    {/* {viewData.length == 0 && (
                  <div className="no-data">
                    <p>No Data Available</p>
                  </div>
                )}  */}
                                </tbody>
                            </table>
                        </div>
                    </Modal>
                </>
            )}
        </div>
    );
}
