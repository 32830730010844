import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { handleErrorResponse } from "../../utils/Request";

export const userDetails = async () => {
  try{
    let request = await axios.get(APIConfig.base_url 
      + 
      "api/v1/user/details"
      , {
      headers: {
        "x-access-token": getAuthToken(),
      },
    });
    return request;
  }catch(err){
    const { errorMessage } = handleErrorResponse(err);
  }
};

// updating admin profile
export const updateAdminProfile = async (input) => {
  let request = await axios.patch(APIConfig.base_url + "api/v1/user/", {
    // let request = await axios.patch(APIConfig.base_url + "api/v1/user/", {
    ...input
  }, {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};

// update user password
export const updatePassword = async (input) => {
  let request = await axios.patch(APIConfig.base_url + "api/v1/user/password/reset/", {
    ...input
  }, {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};
