import React, { useState, useEffect } from "react";

function File() {
  useEffect(() => {
    window.location.replace(
      "https://play.google.com/store/apps/details?id=com.waycool.android"
    );
  }, []);
  return (
    <div>
      <h6> Re directing to Play store</h6>
    </div>
  );
}

export default File;
