import { actionTypes } from "../action/teamMappingAction";
const initialState = {
  loading: false,
  allClusterDetails: [],
  totalRecords: 0,
  viweemploye: [],
  searchemploye: {},
  clusterslocation: [],
  areaslocation: [],
  searchdetailal: false,
  viewempty: [],
};
export const teamMappingReducer = (state = initialState, action) => {
  const { type, data, load } = action;
  console.log(data?.result?.data?.totalDocuments, "kk");
  switch (type) {
    case actionTypes.LOADING:
      return { ...state, loading: true };
    case actionTypes.SEARCH_DETAILS:
      return { ...state, searchdetailal: false };
    case actionTypes.STOP_LOADING:
      return { ...state, loading: false };
    case actionTypes.GET_CLUSTER_HEAD_DETAILS:
      return {
        ...state,
        allClusterDetails: data?.result?.data,
        loading: false,
        totalDocuments: data?.result?.data?.totalDocuments,
      };
    case actionTypes.VIEW_EMPLOYEE:
      return {
        ...state,
        viweemploye: data?.data,
        loading: false,
        // totalDocuments: data.totalDocuments,
      };
    case actionTypes.SEARCH_EMPLOYEE:
      console.log(data, "datared");
      return {
        ...state,
        searchemploye: data,
        searchdetailal: true,
        totalDocuments: data?.status,
        // totalDocuments: data.totalDocuments,
      };
    case actionTypes.EMPTY_SEARCH_FIELDS:
      return {
        ...state,
        searchemploye: {},
        searchdetailal: false,
      };
    case actionTypes.CLUSTERS_DROPDOWN:
      console.log(data, "datared");
      return {
        ...state,
        clusterslocation: data?.data,
        // totalDocuments: data.totalDocuments,
      };
    case actionTypes.View_DETAILS:
      return {
        ...state,
        viweemploye: {},
        // searchdetailal: false,
      };
    case actionTypes.AREAS_DROPDOWN:
      console.log(data, "datared");
      return {
        ...state,
        areaslocation: data?.data,
        loading: false,
        // totalDocuments: data.totalDocuments,
      };
    default:
      return state;
  }
};
