import { ReactComponent as Customers } from "../../../src/images/users.svg";
import { ReactComponent as Team } from "../../../src/images/team.svg";
import { ReactComponent as BeatPlan } from "../../../src/images/file.svg";
import { ReactComponent as GoFrugal } from "../../../src/images/gofrugal.svg";
import { ReactComponent as Invoice } from "../../../src/images/Invoice.svg";
import { ReactComponent as TargetSetting } from "../../../src/images/targetSetting.svg";
import { ReactComponent as sapReport } from "../../../src/images/InvoiceReport.svg";
import { ReactComponent as POLogo } from "../../../src/images/po.svg";
import { ReactComponent as CODLogo } from "../../../src/images/cod.svg";
import { ReactComponent as SettingsLogo } from "../../../src/images/settings.svg";
import { ReactComponent as InventoryLogo } from "../../../src/images/package.svg";
import { ReactComponent as Products } from "../../../src/images/products.svg";
import { ReactComponent as Orders } from "../../../src/images/orders.svg";
import { ReactComponent as SalesDashboard } from "../../../src/images/SalesDashboard.svg";

import "./navbar.scss";
import * as routes from "../../utils/allroutes";

// const isValid = localStorage.getItem("isValid"
// const inventory = {
//     icon: InventoryLogo,
//     title: "Inventory Management",
//     sub_items: [
//         {
//             title: "Current Stock",
//             path: routes.inventoryManagementCurrentStock,
//             classname: "nav_items sub_items",
//         },
//         {
//             title: "Stock Audit",
//             path: routes.inventoryManagementStockAudit,
//             classname: "nav_items sub_items",
//         },
//         {
//             title: "Stock Movement",
//             path: routes.inventoryManagementStockMovement,
//             classname: "nav_items sub_items",
//         },
//         {
//             title: "Report",
//             path: routes.inventoryManagementReport,
//             classname: "nav_items sub_items",
//         },
//     ],
// };

// const data = [
//     {
//         icon: GoFrugal,
//         title: "Sap Sync",
//         path: routes.sapSync,
//         classname: "nav_items",
//     },
//     {
//         icon: CODLogo,
//         title: "Credit Extension",
//         path: routes.cod,
//         classname: "nav_items",
//     },
// ];

// export const dynamicData = [
//     {
//         icon: Customers,
//         title: "Customers",
//         path: routes.customers,
//         classname: "nav_items",
//     },
//     {
//         icon: Team,
//         title: "Team",
//         sub_items: [
//             {
//                 title: "ASMs",
//                 path: routes.ASM,
//                 classname: "nav_items sub_items",
//             },
//             {
//                 title: "Salesmen",
//                 path: routes.salesmen,
//                 classname: "nav_items sub_items",
//             },
//             {
//                 title: "Reports",
//                 path: routes.reports,
//                 classname: "nav_items sub_items",
//             },
//         ],
//     },
//     {
//         icon: BeatPlan,
//         title: "Beat Plan",
//         sub_items: [
//             {
//                 title: "Day's Plan",
//                 path: routes.daysPlan,
//                 classname: "nav_items sub_items",
//             },
//             {
//                 title: "View Beat Plans",
//                 path: routes.viewBeatPlan,
//                 classname: "nav_items sub_items",
//             },
//             {
//                 title: "Create New",
//                 path: routes.createNew,
//                 classname: "nav_items sub_items",
//             },
//         ],
//     },

//     ,
//     // {
//     //   icon: GoFrugal,
//     //   title: "Gofrugal Sync",
//     //   path: routes.gofrugalSync,
//     //   classname: "nav_items",
//     // },
//     // {
//     //   icon: TargetSetting,
//     //   title: "Target Setting",
//     //   path: "/dashboard/target-setting/approved",
//     //   classname: "nav_items change_fill_color",
//     // },

//     // {
//     //   icon: sapReport,
//     //   title: "Sap Report",
//     //   path: routes.sapReport,
//     //   classname: "nav_items",
//     // },
//     // {
//     //   icon: sapReport,
//     //   title: "Sap Report",
//     //   path: routes.sapReport,
//     //   classname: "nav_items",
//     // },
//     {
//         icon: POLogo,
//         title: "PO Generation",
//         path: routes.poGeneration,
//         classname: "nav_items",
//     },

//     {
//         icon: SettingsLogo,
//         title: "Settings",
//         path: routes.settings,
//         classname: "nav_items",
//     },
//     // {
//     //     icon: Invoice,
//     //     title: "Purchase Receipt",
//     //     path: "/dashboard/purchaseOrders",
//     //     classname: "nav_items",
//     // },

//     // {
//     //     icon: InventoryLogo,
//     //     title: "Sales Order",
//     //     sub_items: [
//     //         {
//     //             title: "All",
//     //             path: routes.salesOrderAll,
//     //             classname: "nav_items sub_items",
//     //         },
//     //         {
//     //             title: "Return",
//     //             path: routes.salesOrderReturn,
//     //             classname: "nav_items sub_items",
//     //         },
//     //     ],
//     // },
// ].concat(isValid == "true" ? inventory : [...data]);

export const navbarData = [
  {
    icon: SalesDashboard,
    title: "SalesDashboard",
    path: routes.salesDashboard,
    classname: "nav_items",
  },
  {
    icon: Customers,
    title: "Customers",
    sub_items: [
      {
        title: "All Customers",
        path: routes.customers,
        classname: "nav_items sub_items",
      },
      {
        title: "My Customer Management",
        path: routes.myCustomers,
        classname: "nav_items sub_items",
      },
    ],
  },
  {
    icon: Team,
    title: "Team",
    sub_items: [
      {
        title: "ASMs",
        path: routes.ASM,
        classname: "nav_items sub_items",
      },
      {
        title: "Salesmen",
        path: routes.salesmen,
        classname: "nav_items sub_items",
      },
      {
        title: "Reports",
        path: routes.reports,
        classname: "nav_items sub_items",
      },
    ],
  },
  {
    icon: BeatPlan,
    title: "Beat Plan",
    sub_items: [
      {
        title: "Day's Plan",
        path: routes.daysPlan,
        classname: "nav_items sub_items",
      },
      {
        title: "View Beat Plans",
        path: routes.viewBeatPlan,
        classname: "nav_items sub_items",
      },
      {
        title: "Create New",
        path: routes.createNew,
        classname: "nav_items sub_items",
      },
    ],
  },
  // {
  //   icon: GoFrugal,
  //   title: "Gofrugal Sync",
  //   path: routes.gofrugalSync,
  //   classname: "nav_items",
  // },
  {
    icon: GoFrugal,
    title: "Sap Sync",
    path: routes.sapSync,
    classname: "nav_items",
  },

  // {
  //   icon: TargetSetting,
  //   title: "Target Setting",
  //   path: "/dashboard/target-setting/approved",
  //   classname: "nav_items change_fill_color",
  // },

  // {
  //   icon: sapReport,
  //   title: "Sap Report",
  //   path: routes.sapReport,
  //   classname: "nav_items",
  // },
  // {
  //   icon: sapReport,
  //   title: "Sap Report",
  //   path: routes.sapReport,
  //   classname: "nav_items",
  // },
  {
    icon: POLogo,
    title: "PO Generation",
    path: routes.poGeneration,
    classname: "nav_items",
  },

  {
    icon: CODLogo,
    title: "Credit Extension",
    path: routes.cod,
    classname: "nav_items",
  },

  {
    icon: SettingsLogo,
    title: "Settings",
    path: routes.settings,
    classname: "nav_items",
  },

  {
    icon: Products,
    title: "Products",
    sub_items: [
      {
        title: " Price, Stock & MOQ",
        path: routes.stockAndMoq,
        classname: "nav_items sub_items",
      },
      // {
      //   title: "Pricing",
      //   path: routes.pricing,
      //   classname: "nav_items sub_items",
      // },
    ],
  },
  {
    icon: Orders,
    title: "Orders",
    path: routes.orders,
    classname: "nav_items",
  },

  // {
  //     icon: Invoice,
  //     title: "Purchase Receipt",
  //     path: "/dashboard/purchaseOrders",
  //     classname: "nav_items",
  // },

  // {
  //     icon: InventoryLogo,
  //     title: "Inventory Management",
  //     sub_items: [
  //         {
  //             title: "Current Stock",
  //             path: routes.inventoryManagementCurrentStock,
  //             classname: "nav_items sub_items",
  //         },
  //         {
  //             title: "Stock Audit",
  //             path: routes.inventoryManagementStockAudit,
  //             classname: "nav_items sub_items",
  //         },
  //         {
  //             title: "Stock Movement",
  //             path: routes.inventoryManagementStockMovement,
  //             classname: "nav_items sub_items",
  //         },
  //         {
  //             title: "Report",
  //             path: routes.inventoryManagementReport,
  //             classname: "nav_items sub_items",
  //         },
  //     ],
  // },

  // {
  //     icon: InventoryLogo,
  //     title: "Sales Order",
  //     sub_items: [
  //         {
  //             title: "All",
  //             path: routes.salesOrderAll,
  //             classname: "nav_items sub_items",
  //         },
  //         {
  //             title: "Return",
  //             path: routes.salesOrderReturn,
  //             classname: "nav_items sub_items",
  //         },
  //     ],
  // },
];

export const userAccess = [
  {
    icon: Team,
    title: "Team",
    sub_items: [
      {
        title: "ASMs",
        path: routes.ASM,
        classname: "nav_items sub_items",
      },
      {
        title: "Salesmen",
        path: routes.salesmen,
        classname: "nav_items sub_items",
      },
    ],
  },
];

export const inventoryData = [
  {
    icon: Customers,
    title: "Customers",
    path: routes.customers,
    classname: "nav_items",
  },
  {
    icon: Team,
    title: "Team",
    sub_items: [
      {
        title: "ASMs",
        path: routes.ASM,
        classname: "nav_items sub_items",
      },
      {
        title: "Salesmen",
        path: routes.salesmen,
        classname: "nav_items sub_items",
      },
      {
        title: "Reports",
        path: routes.reports,
        classname: "nav_items sub_items",
      },
    ],
  },
  {
    icon: BeatPlan,
    title: "Beat Plan",
    sub_items: [
      {
        title: "Day's Plan",
        path: routes.daysPlan,
        classname: "nav_items sub_items",
      },
      {
        title: "View Beat Plans",
        path: routes.viewBeatPlan,
        classname: "nav_items sub_items",
      },
      {
        title: "Create New",
        path: routes.createNew,
        classname: "nav_items sub_items",
      },
    ],
  },
  // {
  //   icon: GoFrugal,
  //   title: "Gofrugal Sync",
  //   path: routes.gofrugalSync,
  //   classname: "nav_items",
  // },
  // {
  //     icon: GoFrugal,
  //     title: "Sap Sync",
  //     path: routes.sapSync,
  //     classname: "nav_items",
  // },

  // {
  //   icon: TargetSetting,
  //   title: "Target Setting",
  //   path: "/dashboard/target-setting/approved",
  //   classname: "nav_items change_fill_color",
  // },

  // {
  //   icon: sapReport,
  //   title: "Sap Report",
  //   path: routes.sapReport,
  //   classname: "nav_items",
  // },
  // {
  //   icon: sapReport,
  //   title: "Sap Report",
  //   path: routes.sapReport,
  //   classname: "nav_items",
  // },
  // {
  //   icon: POLogo,
  //   title: "PO Generation",
  //   path: routes.poGeneration,
  //   classname: "nav_items",
  // },

  // {
  //     icon: CODLogo,
  //     title: "Credit Extension",
  //     path: routes.cod,
  //     classname: "nav_items",
  // },

  // {
  //   icon: SettingsLogo,
  //   title: "Settings",
  //   path: routes.settings,
  //   classname: "nav_items",
  // },
  // {
  //   icon: Products,
  //   title: "Products",
  //   sub_items: [
  //     {
  //       title: "Stock & MOQ",
  //       path: routes.stockAndMoq,
  //       classname: "nav_items sub_items",
  //     },
  //     // {
  //     //   title: "Pricing",
  //     //   path: routes.pricing,
  //     //   classname: "nav_items sub_items",
  //     // },
  //   ],
  // },
  // {
  //   icon: Orders,
  //   title: "Orders",
  //   path: routes.orders,
  //   classname: "nav_items",
  // },
  {
    icon: Invoice,
    title: "Purchase Receipt",
    path: "/dashboard/purchaseOrders",
    classname: "nav_items",
  },

  {
    icon: InventoryLogo,
    title: "Inventory Management",
    sub_items: [
      {
        title: "Current Stock",
        path: routes.inventoryManagementCurrentStock,
        classname: "nav_items sub_items",
      },
      {
        title: "Stock Audit",
        path: routes.inventoryManagementStockAudit,
        classname: "nav_items sub_items",
      },
      {
        title: "Stock Movement",
        path: routes.inventoryManagementStockMovement,
        classname: "nav_items sub_items",
      },
      {
        title: "Report",
        path: routes.inventoryManagementReport,
        classname: "nav_items sub_items",
      },
    ],
  },

  {
    icon: InventoryLogo,
    title: "Sales Order",
    sub_items: [
      {
        title: "All",
        path: routes.salesOrderAll,
        classname: "nav_items sub_items",
      },
      {
        title: "Return",
        path: routes.salesOrderReturn,
        classname: "nav_items sub_items",
      },
    ],
  },
  {
    icon: Products,
    title: "Current Stock and  Price",
    path: routes.pricingUpload,
    classname: "nav_items",
  },
];

export const GeoFencingData = [
  {
    icon: SalesDashboard,
    title: "SalesDashboard",
    path: routes.salesDashboard,
    classname: "nav_items",
  },
  {
    icon: Customers,
    title: "Customers",
    path: routes.customers,
    classname: "nav_items",
  },
  {
    icon: Team,
    title: "Team",
    sub_items: [
      {
        title: "ASMs",
        path: routes.ASM,
        classname: "nav_items sub_items",
      },
      {
        title: "Salesmen",
        path: routes.salesmen,
        classname: "nav_items sub_items",
      },
      {
        title: "Reports",
        path: routes.reports,
        classname: "nav_items sub_items",
      },
    ],
  },
  {
    icon: BeatPlan,
    title: "Beat Plan",
    sub_items: [
      {
        title: "Day's Plan",
        path: routes.daysPlan,
        classname: "nav_items sub_items",
      },
      {
        title: "View Beat Plans",
        path: routes.viewBeatPlan,
        classname: "nav_items sub_items",
      },
      {
        title: "Create New",
        path: routes.createNew,
        classname: "nav_items sub_items",
      },
    ],
  },

  {
    icon: GoFrugal,
    title: "Sap Sync",
    path: routes.sapSync,
    classname: "nav_items",
  },

  {
    icon: POLogo,
    title: "PO Generation",
    path: routes.poGeneration,
    classname: "nav_items",
  },

  {
    icon: CODLogo,
    title: "Credit Extension",
    path: routes.cod,
    classname: "nav_items",
  },

  {
    icon: SettingsLogo,
    title: "Settings",
    path: routes.settings,
    classname: "nav_items",
  },

  {
    icon: Products,
    title: "Products",
    sub_items: [
      {
        title: "Stock & MOQ",
        path: routes.stockAndMoq,
        classname: "nav_items sub_items",
      },
    ],
  },
  {
    icon: Orders,
    title: "Orders",
    path: routes.orders,
    classname: "nav_items",
  },
  {
    icon: Products,
    title: "Team Mapping",
    sub_items: [
      {
        title: "Cluster Head",
        path: routes.temMappingCluster,
        classname: "nav_items sub_items",
      },
      {
        title: "Area Mapping",
        path: routes.temMappingArea,
        classname: "nav_items sub_items",
      },
      {
        title: "Hub Mapping",
        path: routes.temMappingHub,
        classname: "nav_items sub_items",
      },
    ],
  },
];
