import React, { useState } from "react";
import PrimaryHeader from "../../header/PrimaryHeader";
import ArrowLeft from "../../../images/arrow-left.svg";
import Upload from "../../../images/upload (1).svg";
import Download from "../../../images/download (2).svg";
import Edit from "../../../images/edit-3.svg";
import Tick from "../../../images/Vector.svg";
import Cross from "../../../images/cross(1).svg";
// import ChevDown from "../../../images/chevron-down.svg";
import ChevDown from "../../../images/chevDown.svg";
import ChevUp from "../../../images/ChevUp.svg";
import Select from "react-select";
import {
  getStockAuditList,
  updateStockAuditList,
  getStockAuditDownload,
  uploadStockAuditFile,
} from "../../../services/inventoryServices/inventoryServices";
import { handleErrorResponse } from "../../../utils/Request";
import message from "antd/lib/message";
import { Pagination, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import ReactFileReader from "react-file-reader";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import moment from "moment";

const selectOptions = [
  { label: "Shrinkage", value: 1 },
  { label: "Return fraud", value: 2 },
  { label: "Transaction missed in system", value: 3 },
  { label: "Issue in UOM", value: 4 },
  { label: "Moisture loss", value: 5 },
  { label: "Grading Weight loss", value: 6 },
];

//-->adding extra key value pair to all objects inside an array
// const extraKeyArray = arr.map((item) => {
//     return { ...item, inputStatus: false, selectStatus: false };
// });
// setArray(extraKeyArray)

//-->removing the single key for all objects in the array
// array.forEach((item) => {
//     delete item["inputStatus"];
// })

//-->removing the multiple keys for all objects in the array
// let removekeysArray = array.map(
//     ({ inputStatus, selectStatus, ...rest }) => {
//         return rest;
//     }
// );

export default function SearchResults(props) {
  const [array, setArray] = useState([]);
  const [image, setImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(7);
  const [totalPage, setTotalPage] = useState(0);
  const [physicalQty, setPhysicalQty] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  const userDetails = useSelector((state) => state.userDetails);
  console.log(userDetails);
  // console.log(props);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  React.useEffect(() => {
    getList();
  }, [page]);

  const redirectToBack = () => {
    props.history.push("/dashboard/inventory-management/stock-audit");
  };

  const getList = async () => {
    setLoading(true);
    let filterQparams = FilterQparams();
    let qParams = [{ page: page }, { limit: limit }, ...filterQparams];
    await getStockAuditList(qParams)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setArray(
          res &&
            res.data.data.filteredStocks.map((item) => {
              return {
                ...item,
                statePhysicalQuantity: item.physical_Qty,
                stateRemarks: item.Remark,
              };
            })
        );
        setTotalPage(res && res.data.data.totalCount);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const FilterQparams = () => {
    let qParams = [];
    const asyncdata = props.location.state.location;
    if (asyncdata["materialCode"] !== "") {
      qParams = [
        ...qParams,
        {
          material: asyncdata["materialCode"].label.split(" ").shift(),
        },
      ];
    }
    if (asyncdata["batchId"] !== "") {
      qParams = [...qParams, { batchId: asyncdata["batchId"].label }];
    }
    if (asyncdata["storageLocation"] !== "") {
      qParams = [
        ...qParams,
        { storage: asyncdata["storageLocation"].label.toLowerCase() },
      ];
    }
    if (asyncdata["materialGroup"] !== "") {
      qParams = [
        ...qParams,
        { materialgroup: asyncdata["materialGroup"].label },
      ];
    }
    return qParams;
  };

  console.log(array, "dynamicArray");

  //for getting latest array
  const getLatestArray = () => {
    let latestArray = array.map((item) => {
      return item;
    });
    setArray(latestArray);
  };

  const inputActivate = (item) => {
    let objIndex = array.findIndex((obj) => obj.uniqueId === item.uniqueId);
    array[objIndex].inputStatus = true;
    getLatestArray();
  };

  const inputDeActivate = (item, type) => {
    if (type == "cancel") {
      let objIndex = array.findIndex((obj) => obj.uniqueId === item.uniqueId);
      console.log(physicalQty);
      if (array[objIndex].physical_Qty != physicalQty) {
        array[objIndex].physical_Qty = physicalQty;
      }
      if (array[objIndex].physical_Qty == physicalQty) {
        array[objIndex].physical_Qty = array[objIndex].statePhysicalQuantity;
      }

      array[objIndex].inputStatus = false;

      if (Math.sign(array[objIndex].physical_Qty) == -1) {
        array[objIndex].physical_Qty = 0;
      }
      getLatestArray();
    } else {
      patchStockList(item, type);
    }
  };

  const selectActivate = (item) => {
    let objIndex = array.findIndex((obj) => obj.uniqueId === item.uniqueId);
    console.log(objIndex);
    array[objIndex].selectStatus = true;
    getLatestArray();
  };

  const selectDeActivate = (item, type) => {
    if (type == "cancel") {
      let objIndex = array.findIndex((obj) => obj.uniqueId === item.uniqueId);
      if (array[objIndex].Remark != selectedValue) {
        array[objIndex].Remark = selectedValue;
      }
      if (array[objIndex].Remark == selectedValue) {
        array[objIndex].Remark = array[objIndex].stateRemarks;
      }
      array[objIndex].selectStatus = false;
      getLatestArray();
    } else {
      patchStockList(item, type);
    }
  };

  const inputHandler = (e, item) => {
    let objIndex = array.findIndex((obj) => obj.uniqueId === item.uniqueId);
    setPhysicalQty(array[objIndex].statePhysicalQuantity);
    array[objIndex].physical_Qty = e.target.value;
    getLatestArray();
  };

  const selectHandler = (e, item) => {
    let objIndex = array.findIndex((obj) => obj.uniqueId === item.uniqueId);
    setSelectedValue(array[objIndex].stateRemarks);
    array[objIndex].Remark = e.label;
    getLatestArray();
  };

  const patchStockList = async (item, type) => {
    let objIndex = array.findIndex((obj) => obj.uniqueId === item.uniqueId);
    console.log(array[objIndex]);
    let data;
    type === "inputTick"
      ? (data = {
          fieldToUpdate: {
            _id: item._id,
            physicalQty:
              array[objIndex].physical_Qty == "" ||
              Math.sign(array[objIndex].physical_Qty) == -1
                ? 0
                : parseInt(array[objIndex].physical_Qty),
            storageLocation: array[objIndex].storageLocation,
          },
        })
      : (data = {
          fieldToUpdate: {
            _id: item._id,
            Remark: array[objIndex].Remark,
            storageLocation: array[objIndex].storageLocation,
          },
        });
    await updateStockAuditList(data)
      .then((res) => {
        console.log(res);
        if (type === "inputTick") {
          array[objIndex].inputStatus = false;
          array[objIndex].statePhysicalQuantity = array[objIndex].physical_Qty;
        } else {
          array[objIndex].selectStatus = false;
        }
        if (Math.sign(array[objIndex].physical_Qty) == -1) {
          array[objIndex].physical_Qty = 0;
        }
        getLatestArray();
        // getList();
        message.success(res.data.message);
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const handleFiles = async (files) => {
    var reader = new FileReader();
    console.log(files, "file");
    console.log(files[0], "fils");
    let file = files[0];
    reader.onload = function (e) {
      // console.log(reader.result);
    };
    //    if (file != ".png" || file != ".jpg" || file != ".pdf") {
    //        message.error(
    //            "File does not support. You must use .png or .jpg or .pdf "
    //        );
    //        return false;
    //    }
    //    if (file.size > 10e6) {
    //        message.error("Please upload a file smaller than 10 MB");
    //        return false;
    //    }

    reader.readAsText(files[0]);
    await uploadStockAuditFile(file)
      .then((res) => {
        console.log(res, "ress");
        message.success("File Upload sucessfully");
        getList();
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const stockAuditDownload = async () => {
    let data = [
      {
        customerCode: "101111",
        customerName: "saiteja",
      },
      {
        customerCode: "101111",
        customerName: "saiteja",
      },
      {
        customerCode: "101111",
        customerName: "saiteja",
      },
    ];
    // const newArr = array.map(
    //     ({ _id, inputStatus, selectStatus, ...rest }) => {
    //         return rest;
    //     }
    // );
    // const ws = XLSX.utils.json_to_sheet(newArr);
    // const wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    // /* generate XLSX file and send to client */
    // XLSX.writeFile(wb, "StockAuditTemplate.xlsx");

    let filterQparams = FilterQparams();
    let qParams = [
      { companyCode: userDetails.companyCode },
      { distributorId: userDetails.warehouse._id },
      ...filterQparams,
    ];
    let date = moment().format("ll");
    await getStockAuditDownload(qParams)
      .then((res) => {
        if (res?.status == 200) {
          let blob = new Blob([res.data], { type: "text/csv" });
          saveAs(blob, `Inventorylist-${date}.csv`);
        } else {
          message.error("No data found");
        }
      })
      .catch((err) => {
        console.log(err);
        const { errorMessage } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  const toggleImage = (type) => {
    setImage(!image);
    if (type == "ascending") {
      array.sort((a, b) => {
        let fa = a.storageLocation.toLowerCase(),
          fb = b.storageLocation.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    } else {
      array.sort((a, b) => {
        let fa = a.storageLocation.toLowerCase(),
          fb = b.storageLocation.toLowerCase();

        if (fa < fb) {
          return 1;
        }
        if (fa > fb) {
          return -1;
        }
        return 0;
      });
    }

    console.log(array, "sortedArray");
  };

  const handlePageChange = (page, limit) => {
    setPage(page);
    // setLimit(limit);
    // getList();
  };

  const stockAuditTbody = array.map((item, index) => {
    return (
      <tr>
        <td className="pt-3">
          {item.itemId ? item.itemId : "N/A"} <br />
          <p>{item.itemName ? item.itemName : "N/A"}</p>
        </td>
        <td className="pt-4">{item.batchId ? item.batchId : "N/A"}</td>
        {/* <td className="pt-4">
                    {item.material_group ? item.material_group : "N/A"}
                </td>
                <td className="pt-4">
                    {item.material_type ? item.material_type : "N/A"}
                </td> */}
        <td className="pt-4">
          {item.storageLocation ? item.storageLocation : "N/A"}
        </td>
        <td className="pt-4">{item.uom ? item.uom : "N/A"}</td>
        <td className="pt-4">{item.System_Qty ? item.System_Qty : "N/A"}</td>
        <td>
          <div className="d-flex">
            <input
              type="number"
              value={item.physical_Qty ? item.physical_Qty : "0"}
              className="form-control form-label"
              style={{ width: "100px" }}
              disabled={!item.inputStatus}
              onChange={(e) => inputHandler(e, item)}
            />
            {item.inputStatus == false ? (
              <img
                src={Edit}
                alt=""
                className="ml-2"
                onClick={() => inputActivate(item)}
              />
            ) : (
              <div className="d-flex">
                <div className="mt-2 pt-1 ml-2">
                  <img
                    src={Tick}
                    alt=""
                    onClick={() => inputDeActivate(item, "inputTick")}
                  />
                </div>
                <div className="ml-2 mt-1 edit_border">
                  <img
                    src={Cross}
                    alt=""
                    onClick={() => inputDeActivate(item, "cancel")}
                  />
                </div>
              </div>
            )}
          </div>
        </td>
        <td>
          <div className="d-flex">
            <div>
              <Select
                options={selectOptions}
                isDisabled={!item.selectStatus}
                className={"reactSelect"}
                menuPosition="fixed"
                onChange={(e) => selectHandler(e, item)}
                value={
                  item.Remark != ""
                    ? {
                        label: item.Remark,
                        value: item.Remark,
                      }
                    : "Select Any.."
                }
              />
            </div>
            <div>
              {item.selectStatus == false ? (
                <img
                  src={Edit}
                  alt=""
                  className="ml-2 mt-2"
                  onClick={() => selectActivate(item)}
                />
              ) : (
                <div className="d-flex">
                  <div className="mt-2 pt-1 ml-4">
                    <img
                      src={Tick}
                      alt=""
                      onClick={() => selectDeActivate(item, "selectTick")}
                    />
                  </div>
                  <div className="ml-2 mt-1 edit_border">
                    <img
                      src={Cross}
                      alt=""
                      onClick={() => selectDeActivate(item, "cancel")}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  });
  return (
    <div id="content">
      <PrimaryHeader title={"Stock Audit"} />
      <Spin indicator={antIcon} spinning={loading}>
        <div className="card">
          <div className="card-body">
            <div className="d-flex   cursor" onClick={redirectToBack}>
              <div className="">
                <img src={ArrowLeft} alt="" />
              </div>
              <div className="ml-2 mt-1 arrow_label">
                <p>{"Back"}</p>
              </div>
            </div>
            <div className="stock_title ml-1 mt-3">
              <label htmlFor="" className="border_left">
                {"Search Results"}
              </label>
            </div>
            <div className="d-flex mt-3 ml-1">
              <div className="cursor">
                <ReactFileReader
                  handleFiles={handleFiles}
                  fileTypes={[".csv", ".xlsx"]}
                >
                  <div className="stock_audit_btns">
                    <button className="btn btn-outline-secondary">
                      <span
                        htmlFor=""
                        className="mt-1"
                        style={{ color: "#3A4960" }}
                      >
                        {"Upload"}
                      </span>
                      <img src={Upload} alt="" className="ml-2" />
                    </button>
                  </div>
                </ReactFileReader>
              </div>

              <div className="cursor stock_audit_btns">
                <button
                  className="btn btn-outline-secondary ml-4"
                  onClick={stockAuditDownload}
                >
                  <span
                    htmlFor=""
                    className="mt-1"
                    style={{ color: "#3A4960" }}
                  >
                    {"Download"}
                  </span>
                  <img src={Download} alt="" className="ml-2" />
                </button>
              </div>
            </div>
            {/* <div className="stockAudit_table"> */}
            <div className="table-wrapper mt-4">
              <table className="table common-table">
                <thead className="remove-border-top">
                  <tr>
                    <th scope="col">{"Material"}</th>
                    <th scope="col">{"Batch ID"}</th>
                    {/* <th scope="col">{"Material Group"}</th>
                                        <th scope="col">{"Material Type"}</th> */}
                    <th scope="col">
                      {"Storage Location"}
                      <span
                        className=""
                        onClick={
                          !image
                            ? () => toggleImage("ascending")
                            : () => toggleImage("descending")
                        }
                      >
                        <img
                          src={!image ? ChevDown : ChevUp}
                          alt=""
                          className="ml-2"
                          style={{
                            width: "10px",
                          }}
                        />
                      </span>
                    </th>
                    <th scope="col">{"UOM"}</th>
                    <th scope="col">{"System Qty"}</th>
                    <th scope="col">{"Physical Quantity"}</th>
                    <th scope="col">{"Remarks"}</th>
                  </tr>
                </thead>
                <tbody>{stockAuditTbody}</tbody>
              </table>
              {stockAuditTbody.length === 0 && (
                <div className="no-data">
                  <p>{"No Data Available"}</p>
                </div>
              )}
            </div>
            {/* </div> */}
            <div className="pagination">
              {totalPage != 0 && (
                <Pagination
                  className="page"
                  defaultCurrent={1}
                  current={page}
                  total={totalPage}
                  defaultPageSize={limit}
                  showSizeChanger={false}
                  onChange={(page, limit) => handlePageChange(page, limit)}
                />
              )}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}
