import React from "react";
import "./modal.scss";
const DeleteConfirmation = ({
  removeId,
  header,
  closeDeleteConfirmationModal,
  warningMsg,
  removeItem,
  toggleModal,
}) => {
  return (
    <>
      <div className="overlay"></div>
      <div id="modal-container" className="delete-modal">
        <header className="dlt-modal-border">{header}</header>
        <section>{warningMsg}</section>
        <footer>
          <button onClick={closeDeleteConfirmationModal}>Cancel</button>{" "}
          <button onClick={removeItem}>
            {toggleModal ? "Change" : "Delete"}
          </button>
        </footer>
      </div>
    </>
  );
};
export default DeleteConfirmation;
