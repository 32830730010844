import React, { useState, useEffect } from "react";

import { GetInvoiceDetails } from "../../../services/orderServices/orderService";
import { Link, useParams, useLocation } from "react-router-dom";
import waycoollogo from "../../../images/waycool.svg";
import { handleErrorResponse } from "../../../utils/Request";
import message from "antd/lib/message";
import { formatMoney } from "../../../utils/common";

import moment from "moment";
import { Divider, Spin } from "antd";
export const ComponentToPrint = React.forwardRef((props, ref) => {
  const [state, setState] = useState({
    invoiceDatas: [],
    invoicePrint: [],
    invoiceTax: [],
    invoiceCompany: [],
  });
  useEffect(() => {
    const inv = getInvoicedata(InvoiceId);
  }, []);
  const downloadFile = async () => {};
  // Querry paramas
  // const params = useParams();
  // const soId = params?.soId;
  // const InvoiceId = params?.InvoiceId;
  // const customerID = params?.customerID;

  const {
    state: { soId, InvoiceId, Otp, customerID },
  } = useLocation();
  const getInvoicedata = async (InvoiceId) => {
    try {
      const res = await GetInvoiceDetails(InvoiceId);
      setState({
        invoicePrint: res.data.data,
        invoiceDatas:
          res.data.data.invoiceDetail &&
          res.data.data.invoiceDetail.itemSupplied,
        invoiceTax: res.data.data.taxSummary,
        invoiceCompany: res.data.data.companyDetails,
      });
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else {
        //message.error(err.response.data.message);
      }
    }
  };
  const { setIsDataLoaded } = props;
  const {
    cgst,
    sgst,
    igst,
    cess,
    TCS_amount,
    roundOff,
    totalBaseValue,
    totalDiscount,
    basketTotal = 0,
    finalTotal,
    gstAmount,
    amountInWords,
  } = state.invoicePrint;

  useEffect(() => {
    if (Object.keys(state.invoicePrint).length > 0) setIsDataLoaded(true);
  }, [state.invoicePrint]);

  return (
    <div className="m-3" style={{ backgroundColor: "#FFFFFF" }} ref={ref}>
      {!Object.keys(state.invoicePrint).length > 0 ? (
        <div
          className="d-flex"
          style={{
            height: "80vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div className="container-fluid bg-white">
          <div className="row invoice-header ">
            <span className="col-2 pt-5 pl-5">
              {state.invoiceCompany &&
              state.invoiceCompany.companyCode === "1000" ? (
                <img src={waycoollogo} />
              ) : (
                <div></div>
              )}
            </span>

            <div
              className="col-6 pt-5 text-center "
              style={{ marginLeft: "81px" }}
            >
              {state.invoiceCompany &&
              state.invoiceCompany.companyCode === 1000 ? (
                <div className="waycool-title pl-5">
                  Waycool Foods and Products Pvt Ltd.
                </div>
              ) : (
                <div className="waycool-title pl-5">
                  {state.invoiceCompany && state.invoiceCompany.companyName}
                </div>
              )}

              <div className="waycool-address mt-2">
                {state.invoicePrint.warehouseDetails &&
                  (state.invoicePrint.warehouseDetails.street
                    ? state.invoicePrint.warehouseDetails.street
                    : "")}
              </div>

              <div className="waycool-address mt-2">
                {state.invoicePrint.warehouseDetails &&
                  (state.invoicePrint.warehouseDetails.city
                    ? state.invoicePrint.warehouseDetails.city
                    : " ") +
                    " - " +
                    (state.invoicePrint.warehouseDetails.pincode
                      ? state.invoicePrint.warehouseDetails.pincode
                      : " ") +
                    " , " +
                    (state.invoicePrint.warehouseDetails.country
                      ? state.invoicePrint.warehouseDetails.country
                      : " ")}
              </div>
            </div>

            <div className="col-3 pt-5 align-item-center irn-image">
              <div className="ml-1">
                {state.invoicePrint &&
                state.invoicePrint.invoiceDetail &&
                state.invoicePrint.invoiceDetail.signed_GpnQrCode ? (
                  <img
                    src={
                      state.invoicePrint &&
                      state.invoicePrint.invoiceDetail &&
                      state.invoicePrint.invoiceDetail.signed_GpnQrCode
                    }
                    className="qrImage"
                    style={{ width: 140 }}
                  />
                ) : (
                  ""
                )}
              </div>

              {state.invoicePrint &&
              state.invoicePrint.invoiceDetail &&
              state.invoicePrint.invoiceDetail.signed_GpnQrCode ? (
                <div className="gpnVal">GPN</div>
              ) : (
                ""
              )}
            </div>
          </div>
          <Divider type="horizontal" style={{ color: "ECEDEE" }} />
          <div className="row tax-invoice col-12 pt-4">
            <div
              className="text-center mx-auto"
              style={{
                fontSize: "19.6829px",
                fontWeight: 700,
                color: "#3A4960",
              }}
            >
              TAX INVOICE
            </div>
          </div>
          <div className="row tax-invoice-details pt-2 pb-2">
            <div className="col-4">
              <div>
                CIN :{" "}
                {state.invoicePrint &&
                state.invoicePrint.warehouseDetails &&
                state.invoicePrint.warehouseDetails.cin
                  ? state.invoicePrint &&
                    state.invoicePrint.warehouseDetails &&
                    state.invoicePrint.warehouseDetails.cin
                  : "N/A"}{" "}
              </div>

              <div>
                Email :{" "}
                {state.invoicePrint &&
                state.invoicePrint.warehouseDetails &&
                state.invoicePrint.warehouseDetails.email
                  ? state.invoicePrint &&
                    state.invoicePrint.warehouseDetails &&
                    state.invoicePrint.warehouseDetails.email
                  : "N/A"}
              </div>
            </div>

            <div className="col-4">
              <div>
                {" "}
                Tel No. :{" "}
                {state.invoicePrint &&
                  state.invoicePrint.invoiceDetail &&
                  state.invoicePrint.invoiceDetail.companyDetails &&
                  state.invoicePrint.invoiceDetail.companyDetails.telephoneNo}
              </div>

              <div>
                GSTN No. :{" "}
                {state.invoicePrint &&
                state.invoicePrint.warehouseDetails &&
                state.invoicePrint.warehouseDetails.gstin
                  ? state.invoicePrint &&
                    state.invoicePrint.warehouseDetails &&
                    state.invoicePrint.warehouseDetails.gstin
                  : "NA"}
              </div>
            </div>

            <div className="col-4">
              <div>
                FSSAI No. :
                {state.invoicePrint &&
                state.invoicePrint.warehouseDetails &&
                state.invoicePrint.warehouseDetails.cin
                  ? state.invoicePrint &&
                    state.invoicePrint.warehouseDetails &&
                    state.invoicePrint.warehouseDetails.fssai
                  : "N/A"}{" "}
              </div>

              <div>
                Contact No. :{" "}
                {state.invoicePrint &&
                state.invoicePrint.warehouseDetails &&
                state.invoicePrint.warehouseDetails.contactNumber
                  ? state.invoicePrint &&
                    state.invoicePrint.warehouseDetails &&
                    state.invoicePrint.warehouseDetails.contactNumber
                  : "N/A"}
              </div>
            </div>

            {state.invoicePrint && state.invoicePrint.signed_qrcode ? (
              <div className="col-12">
                <div>
                  IRN No. :{" "}
                  {state.invoicePrint &&
                  state.invoicePrint.invoiceDetail &&
                  state.invoicePrint.invoiceDetail.invoiceDetails &&
                  state.invoicePrint.invoiceDetail.invoiceDetails.irn_no
                    ? state.invoicePrint &&
                      state.invoicePrint.invoiceDetail &&
                      state.invoicePrint.invoiceDetail.invoiceDetails &&
                      state.invoicePrint.invoiceDetail.invoiceDetails.irn_no
                    : "N/A"}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={{ border: "1px solid black" }} className="p-3">
            <div className="row tax-invoice-details-section ">
              <div
                className="col-4  border-bottom border-dark"
                style={{ borderRight: "1px solid black", marginTop: "-17px" }}
              >
                <div className="font-weight-bold mt-3">Payer Details</div>
                <div>
                  Customer Name :{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint.invoiceDetail &&
                    state.invoicePrint.invoiceDetail.shippingDetails.name
                      ? state.invoicePrint.invoiceDetail.shippingDetails.name
                      : "NA"}
                  </span>
                </div>
                <div>
                  Customer ID :{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint.invoiceDetail &&
                      state.invoicePrint.invoiceDetail.invoiceDetails
                        .sold_to_party}
                  </span>
                </div>
                <div>&nbsp;</div>
                <div>
                  Address :{" "}
                  <div className="font-weight-bold">
                    {`${
                      state.invoicePrint.invoiceDetail &&
                      state.invoicePrint.invoiceDetail.shippingDetails
                        .address1 == undefined
                        ? ""
                        : state.invoicePrint.invoiceDetail &&
                          state.invoicePrint.invoiceDetail.shippingDetails
                            .address1
                    },${
                      state.invoicePrint.invoiceDetail &&
                      state.invoicePrint.invoiceDetail.shippingDetails
                        .address2 == undefined
                        ? ""
                        : state.invoicePrint.invoiceDetail &&
                          state.invoicePrint.invoiceDetail.shippingDetails
                            .address2
                    },
                           ${
                             state.invoicePrint.invoiceDetail &&
                             state.invoicePrint.invoiceDetail.shippingDetails
                               .address3 == undefined
                               ? ""
                               : state.invoicePrint.invoiceDetail &&
                                 state.invoicePrint.invoiceDetail
                                   .shippingDetails.address3
                           } ,
                           ${
                             state.invoicePrint.invoiceDetail &&
                             state.invoicePrint.invoiceDetail.shippingDetails
                               .cityId == undefined
                               ? ""
                               : state.invoicePrint.invoiceDetail &&
                                 state.invoicePrint.invoiceDetail
                                   .shippingDetails.cityId
                           }
                           
                           -
                           
                           ${
                             state.invoicePrint.invoiceDetail &&
                             state.invoicePrint.invoiceDetail.shippingDetails
                               .pincode == undefined
                               ? ""
                               : state.invoicePrint.invoiceDetail &&
                                 state.invoicePrint.invoiceDetail
                                   .shippingDetails.pincode
                           },
                          

                         ${
                           state.invoicePrint.invoiceDetail &&
                           state.invoicePrint.invoiceDetail.shippingDetails
                             .country == undefined
                             ? ""
                             : state.invoicePrint.invoiceDetail &&
                               state.invoicePrint.invoiceDetail.shippingDetails
                                 .country
                         }`}
                  </div>
                </div>
                <div>&nbsp;</div>
                <div>
                  Contact No. :{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint &&
                      state.invoicePrint.invoiceDetail &&
                      state.invoicePrint.invoiceDetail.shippingDetails &&
                      state.invoicePrint.invoiceDetail.shippingDetails.mobileNo}
                  </span>
                </div>
                <div>
                  GSTN No. :{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint &&
                      state.invoicePrint.invoiceDetail &&
                      state.invoicePrint.invoiceDetail.shippingDetails &&
                      state.invoicePrint.invoiceDetail.shippingDetails.gstNo}
                  </span>
                </div>
                <div>&nbsp;</div>
              </div>

              <div
                className="col-4   border-bottom   border-dark"
                style={{ borderRight: "1px solid black", marginTop: "-17px" }}
              >
                <div className="font-weight-bold mt-3">Shipping Details</div>
                <div>
                  Customer Name :{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint.invoiceDetail &&
                    state.invoicePrint.invoiceDetail.shippingDetails.name
                      ? state.invoicePrint.invoiceDetail.shippingDetails.name
                      : "NA"}
                  </span>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>
                  Address :{" "}
                  <div className="font-weight-bold">
                    {`${
                      state.invoicePrint.invoiceDetail &&
                      state.invoicePrint.invoiceDetail.shippingDetails
                        .address1 == undefined
                        ? ""
                        : state.invoicePrint.invoiceDetail &&
                          state.invoicePrint.invoiceDetail.shippingDetails
                            .address1
                    },${
                      state.invoicePrint.invoiceDetail &&
                      state.invoicePrint.invoiceDetail.shippingDetails
                        .address2 == undefined
                        ? ""
                        : state.invoicePrint.invoiceDetail &&
                          state.invoicePrint.invoiceDetail.shippingDetails
                            .address2
                    },
                           ${
                             state.invoicePrint.invoiceDetail &&
                             state.invoicePrint.invoiceDetail.shippingDetails
                               .address3 == undefined
                               ? ""
                               : state.invoicePrint.invoiceDetail &&
                                 state.invoicePrint.invoiceDetail
                                   .shippingDetails.address3
                           } ,
                           ${
                             state.invoicePrint.invoiceDetail &&
                             state.invoicePrint.invoiceDetail.shippingDetails
                               .cityId == undefined
                               ? ""
                               : state.invoicePrint.invoiceDetail &&
                                 state.invoicePrint.invoiceDetail
                                   .shippingDetails.cityId
                           }
                           
                           -
                           
                           ${
                             state.invoicePrint.invoiceDetail &&
                             state.invoicePrint.invoiceDetail.shippingDetails
                               .pincode == undefined
                               ? ""
                               : state.invoicePrint.invoiceDetail &&
                                 state.invoicePrint.invoiceDetail
                                   .shippingDetails.pincode
                           },
                          

                         ${
                           state.invoicePrint.invoiceDetail &&
                           state.invoicePrint.invoiceDetail.shippingDetails
                             .country == undefined
                             ? ""
                             : state.invoicePrint.invoiceDetail &&
                               state.invoicePrint.invoiceDetail.shippingDetails
                                 .country
                         }`}
                  </div>
                </div>

                <div>&nbsp;</div>
                <div>
                  Contact No. :{" "}
                  <span className="font-weight-bold">
                    {" "}
                    {state.invoicePrint &&
                      state.invoicePrint.invoiceDetail &&
                      state.invoicePrint.invoiceDetail.shippingDetails &&
                      state.invoicePrint.invoiceDetail.shippingDetails.mobileNo}
                  </span>
                </div>
                <div>
                  GSTN No. :{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint &&
                      state.invoicePrint.invoiceDetail &&
                      state.invoicePrint.invoiceDetail.shippingDetails &&
                      state.invoicePrint.invoiceDetail.shippingDetails.gstNo}
                  </span>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="col-4 border-bottom border-dark">
                <div>
                  Invoice Number :{" "}
                  <span className="font-weight-bold">
                    {" "}
                    {state.invoicePrint.invoiceNo
                      ? state.invoicePrint.invoiceNo
                      : ""}
                  </span>
                </div>
                <div>
                  Invoice Date :{" "}
                  <span className="font-weight-bold">
                    {moment(state.invoicePrint.invoiceDate).format(
                      "DD MMM YYYY,HH:mm A"
                    )}
                  </span>
                </div>
                <div>
                  SAP ID :{" "}
                  <span className="font-weight-bold">
                    {" "}
                    {state.invoicePrint.invoiceDetail &&
                      state.invoicePrint.invoiceDetail.invoiceDetails
                        .sold_to_party}
                  </span>
                </div>
                <div>
                  Customer PO Number :{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint.customerPONumber}
                  </span>
                </div>
                <div>
                  Customer PO Date :{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint.customerPODate}
                  </span>
                </div>
                <div>
                  Payment Terms :{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint &&
                    state.invoicePrint.invoiceDetail &&
                    state.invoicePrint.invoiceDetail.invoiceDetails &&
                    state.invoicePrint.invoiceDetail.invoiceDetails
                      .payment_terms_description
                      ? state.invoicePrint.invoiceDetail.invoiceDetails
                          .payment_terms_description
                      : "NA"}
                  </span>
                </div>
                <div>
                  Sales Emp ID :{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint.salesmanEmpId
                      ? state.invoicePrint.salesmanEmpId
                      : "NA"}
                  </span>
                </div>
                <div>
                  Sales Emp Name :{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint.salesmanEmpName
                      ? state.invoicePrint.salesmanEmpName
                      : "NA"}
                  </span>
                </div>
                <div>
                  Sales Emp Contact No.:{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint.salesmanContactNo
                      ? state.invoicePrint.salesmanContactNo
                      : "NA"}
                  </span>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>

                <div>
                  Delivery From :{" "}
                  <span className="font-weight-bold">
                    {state.invoicePrint &&
                      state.invoicePrint.warehouseDetails &&
                      state.invoicePrint.warehouseDetails.nameToDisplay}
                  </span>
                </div>
              </div>
            </div>

            <div
              className="row tax-invoice-table"
              style={{ paddingBottom: "14px" }}
            >
              <table className=" border-dark" colSpan={8}>
                <tr>
                  <th className="border-right border-bottom">SL No.</th>

                  <th className="border-right border-bottom p-2">Material</th>
                  <th className="border-right border-bottom p-2">HSN</th>
                  <th className="border-right border-bottom p-2">UOM</th>
                  <th className="border-right border-bottom p-2">
                    Unit Price with tax
                  </th>
                  <th className="border-right border-bottom p-2">Quantity</th>
                  <th className="border-right border-bottom p-2">Discount</th>
                  <th className="border-right border-bottom p-2">Base Value</th>

                  <th className="border-right border-bottom p-2">GST Rate</th>
                  <th className="border-right border-bottom p-2">GST Amount</th>

                  <th className="border-right border-bottom p-2">
                    Total Amount
                  </th>
                </tr>
                {state.invoiceDatas.map((item, i) => {
                  return (
                    <tr>
                      <td className="border-right border-bottom p-2">
                        {i + 1}
                      </td>
                      <td className="border-right border-bottom p-2">
                        {/* {item.itemId ? item.itemId : "N/A"} */}
                        {`${item.itemId} ${item.itemName}` || "NA"}
                      </td>
                      <td className="border-right border-bottom p-2">
                        {item.HSN_number ? item.HSN_number : "N/A"}
                      </td>
                      <td className="border-right border-bottom p-2">
                        {item.uom ? item.uom : "N/A"}
                      </td>
                      <td className="border-right border-bottom p-2">
                        {item.unitPrice ? (
                          <span>&#8377;{item.unitPrice}</span>
                        ) : (
                          "Free"
                        )}
                      </td>

                      <td className="border-right border-bottom p-2">
                        {item.suppliedQty ? item.suppliedQty : "N/A"}
                      </td>
                      <td className="border-right border-bottom p-2">
                        <span>&#8377;</span>
                        {item.discountAmount}
                      </td>
                      <td className="border-right border-bottom p-2">
                        <span>&#8377;</span>
                        {item.baseValue ? item.baseValue : "0"}
                      </td>

                      <td className="border-right border-bottom p-2">
                        {parseFloat(item.cgst_pr) +
                          parseFloat(item.sgst_pr) +
                          parseFloat(item.igst_pr) +
                          parseFloat(item.ugst_pr)}
                        <span>&#37;</span>
                      </td>
                      <td className="border-right border-bottom p-2">
                        <span>&#8377;</span>
                        {item.taxable_value ? item.taxable_value : "0.00"}
                      </td>

                      <td className="border-right border-bottom p-2">
                        <span>&#8377;</span>
                        {item.totalAmount
                          ? Number.parseFloat(item.totalAmount).toFixed(2)
                          : "0.00"}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <div className="row tax-total ">
              <div className="col-8 pr-0 pb-0 mr-0 mb-0"></div>
              <div
                className="col-4 pr-0 pb-0 mr-0 mb-0"
                style={{ marginLeft: "0px", marginTop: "-14px" }}
              >
                <table class="table table-bordered no-gutters p-3 m-0">
                  <tr>
                    <th>SubTotal</th>
                    <td className="d-flex justify-content-end">
                      <span>&#8377;</span>
                      {basketTotal?.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <th>GST Amount</th>
                    <td className="d-flex justify-content-end">
                      <span>&#8377;</span>
                      {gstAmount}
                    </td>
                  </tr>
                  {/* <tr>
                          <th scope="row no-gutters">Discount</th>
                          <td>{totalDiscount}</td>
                      </tr> */}
                  <tr>
                    <th scope="row no-gutters">Discount</th>
                    <td className="d-flex justify-content-end">
                      <span>&#8377;</span>
                      {totalDiscount}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row no-gutters">Round Off</th>
                    <td className="d-flex justify-content-end">
                      <span>&#8377;</span> {roundOff}
                    </td>
                  </tr>

                  {/* <tr>
                          <th scope="row no-gutters">CGST</th>
                          <td>
                              {" "}
                              {cgst === 0
                                  ? cgst + ".00"
                                  : cgst && cgst.toFixed(2)}
                          </td>
                      </tr> */}
                  {/* <tr>
                          <th scope="row no-gutters">SGST</th>
                          <td>
                              {sgst === 0
                                  ? sgst + ".00"
                                  : sgst && sgst.toFixed(2)}
                          </td>
                      </tr> */}

                  <tr>
                    <th scope="row no-gutters">Grand Total</th>
                    <td className="d-flex justify-content-end">
                      <span>&#8377;</span>
                      {finalTotal}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div
              className="row tax-total-inwords pb-4"
              style={{ borderTop: "1px solid black" }}
            >
              <div className="col-3 p-3 border-right">Amount in words :</div>
              <div className="col-9 p-3  font-weight-bold">{amountInWords}</div>
            </div>
          </div>

          {state.invoicePrint &&
          state.invoicePrint.invoiceDetail &&
          state.invoicePrint.invoiceDetail.invoiceDetails &&
          state.invoicePrint.invoiceDetail.invoiceDetails.payment_terms !==
            "WCDL" &&
          state.invoicePrint &&
          state.invoicePrint.invoiceDetail &&
          state.invoicePrint.invoiceDetail.cashfreeQr ? (
            <div className="main-box pageBreak">
              <div className="heading-pay text-center pt-2 pb-2">
                Quick Payment
              </div>
              <div className="d-flex mt-2 mb-2">
                <div className="col-lg-6 col-md-6">
                  {state.invoicePrint && state.invoicePrint.invoiceDetail && (
                    <img
                      className="cashfree"
                      src={state.invoicePrint.invoiceDetail.cashfreeQr}
                    />
                  )}
                </div>

                <div className="col-lg-6 col-md-6 my-auto">
                  <p className="para-content">
                    Please Scan the dynamic QR Code to make{" "}
                    <span className="font-weight-bold">
                      online UPI payment.
                    </span>
                    {""} to get a 0.5% discount as credit note
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row tax-authorization pb-4 pt-4">
            <div className="col-12 pt-3 pl-3">
              I/We hereby certify that food/foods mentioned in this Invoice
              is/are warranted to be of the nature and quality if/these
              purports/purpot to be.
            </div>
            <div className="col-6 float-left pt-4 pl-3">
              Recieved the above goods in good condition
            </div>
            {state.invoiceCompany &&
            state.invoiceCompany.companyCode === 1000 ? (
              <div className="col-6 float-left pt-4 pl-5">
                For Waycool Foods & Products Pvt Ltd.
              </div>
            ) : (
              <div className="col-6 float-left pt-4 pl-5">
                For {state.invoiceCompany && state.invoiceCompany.companyName}
              </div>
            )}

            <div className="col-6 float-left pt-5 pl-3">
              Customers Signature &amp; Seal
            </div>
            <div className="col-6 float-right pt-5 pl-5">
              Authorized Signature
            </div>
          </div>

          <div className="d-flex justify-content-between pageBreak m-4 ">
            <div className="col-3">
              <table className="table table-bordered">
                <tr>
                  <th
                    className="tax-sum"
                    style={{ marginLeft: "200px" }}
                    colSpan={9}
                  >
                    Tax summary
                  </th>
                </tr>
                <tr>
                  {" "}
                  <th className="tax-sum p-3">SL No.</th>
                  <th className="tax-sum p-3">Tax %</th>
                  <th className="tax-sum p-3">Taxable Amount</th>
                  <th className="tax-sum p-3">CGST Amount</th>
                  <th className="tax-sum p-3">SGST Amount</th>
                  <th className="tax-sum p-3">IGST Amount</th>
                  <th className="tax-sum p-3">CESS Amount</th>
                  <th className="tax-sum p-3">TCS Amount</th>
                  <th className="tax-sum p-3">Round Off Amount</th>
                </tr>
                {state.invoiceTax !== [] &&
                  state.invoiceTax.map((item, i) => {
                    return (
                      <>
                        <tr>
                          <td>{i + 1}</td>
                          <td> {item.taxPercentage}</td>
                          <td>
                            {item.taxableAmount ? item.taxableAmount : "NA"}
                          </td>
                          <td>{item.cgst_value} </td>
                          <td>{item.sgst_value}</td>
                          <td>{item.igst_value}</td>
                          <td>{item.cess_value ? item.cess_value : "NA"}</td>
                          <td>{item.TCS_amount ? item.TCS_amount : "NA"}</td>
                          <td></td>
                        </tr>
                      </>
                    );
                  })}

                <tr>
                  <th></th>
                  <td className="tax-sum">Total</td>
                  {state.invoicePrint !== [] ? (
                    <td className="tax-sum">
                      <span>&#8377;</span>
                      {totalBaseValue}
                    </td>
                  ) : (
                    ""
                  )}

                  <td colSpan={4} className="tax-sum">
                    <span>&#8377;</span> {gstAmount}
                  </td>
                  <td></td>
                  <td className="tax-sum">
                    <span>&#8377;</span> {roundOff}
                  </td>
                </tr>
                <tr>
                  <th colSpan={9} className="tax-sum">
                    <span className="float-left ml-5"> Grand Total</span>
                    <span> &#8377;</span> {finalTotal}
                  </th>
                </tr>
              </table>
            </div>

            <div className=" ">
              <div className="qrCode ">
                <div className="">
                  {state.invoicePrint &&
                  state.invoicePrint.invoiceDetail &&
                  state.invoicePrint.invoiceDetail.signed_qrcode ? (
                    <img
                      src={
                        state.invoicePrint &&
                        state.invoicePrint.invoiceDetail &&
                        state.invoicePrint.invoiceDetail.signed_qrcode
                      }
                      className="qrImage"
                      style={{ width: 140 }}
                    />
                  ) : (
                    ""
                  )}
                </div>

                {state.invoicePrint &&
                state.invoicePrint.invoiceDetail &&
                state.invoicePrint.invoiceDetail.signed_qrcode ? (
                  <div className="gpnVal">IRN</div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
