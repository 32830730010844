import { EditOutlined } from "@ant-design/icons";
import { Button, Spin, TimePicker, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { actionTypes } from "../../../action/manageOrder";
import getMomentFromTimeString from "../../../utils/settings/getMomentFromTimeString";
import getTimeTo12HoursFormat from "../../../utils/settings/getTimeTo12HoursFormat";
import CardLeftContent from "../CardLeftContent";
import "./manageOrder.scss";
import "./styles.scss";

const { Text } = Typography;

// * COMPONENT BASECARD
const BaseCard = ({
  title,
  subTitle,
  toTime,
  fromTime,
  icon,
  upDateOrderingTime,
  loading,
  setEditOpen,
  editOpen,
  onHandleEditOpen,
}) => {
  const [fromTimeValue, setFromTimeValue] = useState("");
  const [toTimeValue, setToTimeValue] = useState("");
  const [invalidError, setInvalidError] = useState("");

  //#region ON CHANGE FROM DATE HANDLER
  function onChangeFrom(time) {
    setFromTimeValue(time);
    if (toTimeValue > time) {
      setInvalidError("");
    } else {
      setInvalidError("Ordering Time 'From' Can't be more than 'To' time");
    }
  }
  //#endregion

  //#region ON CHANGE TO DATE CHANGE
  function onChangeTo(time) {
    setToTimeValue(time);
    if (fromTimeValue < time) {
      setInvalidError("");
    } else {
      setInvalidError("Ordering Time 'To' Can't be less than 'From' time");
    }
  }
  //#endregion

  //#region ON CLICK SAVE BUTTON HANDLER
  console.log(fromTimeValue < toTimeValue);

  function onClickSaveHandler() {
    const fromTimeWithRequestFormat = moment(fromTimeValue).format("HH:mm:ss");
    const toTimeWithRequestFormat = moment(toTimeValue).format("HH:mm:ss");
    upDateOrderingTime(fromTimeWithRequestFormat, toTimeWithRequestFormat);
  }
  //#endregion

  //#region ON CLICK CANCEL BUTTON HANDLER
  function onClickCancel() {
    setEditOpen(false);
    setFromTimeValue(getMomentFromTimeString(fromTime));
    setToTimeValue(getMomentFromTimeString(toTime));
    setInvalidError("");
  }
  //#endregion

  //#region UPDATING THE INPUT FIELDS WITH VALUES RECEIVED FROM REDUX
  useEffect(() => {
    let tFromTime = getMomentFromTimeString(fromTime);
    let tToTime = getMomentFromTimeString(toTime);
    setFromTimeValue(tFromTime);
    setToTimeValue(tToTime);
    if (tFromTime > tToTime) {
      setInvalidError("Ordering Time 'From' Can't be more than 'To' time");
    }
  }, [fromTime, toTime]);
  //#endregion

  return (
    <div className="rounded-lg shadow-sm py-4 px-4 bg-white">
      <div className=" d-flex justify-content-between">
        {/* Content with Icon, Title and Sub Title*/}
        <CardLeftContent icon={icon} title={title} subTitle={subTitle} />
        {/* END OF Content with Icon, Title and Sub Title*/}

        {/* Info with Edit Icon */}
        {editOpen ? null : (
          <div className="d-flex flex-row">
            <div className="d-flex justify-content-center flex-column">
              {loading.includes(actionTypes.GET_ORDERING_TIME) ||
              loading.includes(actionTypes.UPDATE_ORDERING_TIME) ? (
                <Spin />
              ) : (
                <Typography
                  className="font-weight-bold"
                  style={{ fontSize: 16, color: "#3A4960" }}
                >
                  {getTimeTo12HoursFormat(fromTime)} to{" "}
                  {getTimeTo12HoursFormat(toTime)}
                </Typography>
              )}
            </div>
            <div className="p-3 ml-5 d-flex align-items-center">
              <EditOutlined
                style={{ fontSize: 24, color: "#5078F2" }}
                onClick={() => onHandleEditOpen()}
              />
            </div>
          </div>
        )}
        {/* END OF Info with Edit Icon */}
      </div>

      {/* Area with Input elements with action button */}
      {editOpen ? (
        <div style={{ marginLeft: 160 }}>
          <div className="d-flex flex-row  w-100 py-2 flex-wrap">
            <div>
              <Typography style={{ fontSize: 16 }} className="my-1">
                From:
              </Typography>
              <TimePicker
                use12Hours
                format="h:mm a"
                className="antPickerWidthImp"
                onChange={onChangeFrom}
                value={fromTimeValue}
                style={{ marginRight: 40 }}
              />
            </div>
            <div>
              <Typography style={{ fontSize: 16 }} className="my-1">
                To:
              </Typography>
              <TimePicker
                use12Hours
                format="h:mm a"
                className="antPickerWidthImp"
                value={toTimeValue}
                onChange={onChangeTo}
              />
            </div>
          </div>
          <Text type="danger">{invalidError}</Text>
          <div
            className="mt-4 d-flex justify-content-between"
            style={{ width: 200 }}
          >
            {/*Cancel Button */}
            <Button
              onClick={onClickCancel}
              size="large"
              value="default"
              className="cancel_button"
            >
              Cancel
            </Button>
            {/*END OF Cancel Button */}

            {/*Save Button */}
            <Button
              size="large"
              disabled={!toTimeValue || !fromTimeValue || invalidError}
              className="rounded-lg save_button"
              onClick={onClickSaveHandler}
              loading={loading.includes(actionTypes.UPDATE_ORDERING_TIME)}
            >
              Save
            </Button>
            {/*END OF Save Button */}
          </div>
        </div>
      ) : null}
      {/* END OF Area with Input elements with action button */}
    </div>
  );
};

export default BaseCard;
