import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import PoGeneration from './../components/poGeneration/PoGeneration';
import { getAllInternalPo, getAllExternalPo } from "../action/poGeneration";

const mapStateToProps = (state) => {
    return {
        internalPoList: state.poGeneration.internalPoList,
        externalPoList: state.poGeneration.externalPoList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAllInternalPo,
            getAllExternalPo,
        },
        dispatch
    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PoGeneration));
