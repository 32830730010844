import axios from "axios";
import { getAuthToken } from "../../utils/Auth";
import { api as APIConfig } from "../../appConfig";
import { getQparams } from "../../utils/common";

export const getPurchasePendingList = async (qParams) => {
  let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/standalone/purchaseorder/list` + qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getPurchasePendingDetails = async (id) => {
  // let qUrl = getQparams(qParams);
  let request = await axios.get(
    APIConfig.base_url + `api/v1/standalone/purchaseorder/getbyid/${id}`,
    // +
    // qUrl,
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const updatePurchaseList = async (id, data) => {
  let request = await axios.patch(
    APIConfig.base_url + `api/v1/standalone/purchaseorder/items/${id}`,
    { ...data },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const GRNService = async (qParams) => {
  let qUrl = getQparams(qParams);

  let request = await axios.post(
    APIConfig.base_url +
      `api/v1/standalone/purchaseReceiptGrn/generateGrn/invid` +
      qUrl,
    {},
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const generateGRNEmail = async (data) => {
  let request = await axios.post(
    APIConfig.base_url + `api/v1/standalone/generateGrn/sendmail`,
    { ...data },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};
