import generateActionTypes from "../utils/generateActionTypes";
import { getSalesOverview } from "../services/salesDashboard/salesDashboardServices";

import { handleErrorResponse } from "../utils/Request";
import message from "antd/lib/message";
export const actionTypes = generateActionTypes(
  "GET_SALES_LIST",
  "LOADING",
  "STOP_LOADING",
  "TOKEN_EXPIRED",
  "ERROR",
  "CANCELLATION_LOADING",
  "CANCELLATION_STOP_LOADING"
);
export function stopLoading(loadType) {
  return (disaptch) => {
    return disaptch({
      type: actionTypes.STOP_LOADING,
      load: loadType,
    });
  };
}
export const getSalesList = ({ startDate, endDate }) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.GET_ORDERS_LIST,
    });
    try {
      const res = await getSalesOverview(startDate, endDate);
      const resData = res?.data;
      if (resData.status == 200) {
        const result = resData.data;
        dispatch({
          type: actionTypes.GET_SALES_LIST,
          data: result,
        });
      } else {
        message.error(
          resData?.error && typeof resData.error === "string"
            ? resData.error
            : "Something went wrong"
        );
        dispatch({
          type: actionTypes.STOP_LOADING,
        });
        dispatch({
          type: actionTypes.ERROR,
        });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        dispatch({
          type: actionTypes.TOKEN_EXPIRED,
        });
      } else {
        dispatch({
          type: actionTypes.STOP_LOADING,
        });
        dispatch({
          type: actionTypes.ERROR,
        });
        message.error("Unable to process the request");
      }
    }
  };
};
