import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";


//  sap report download
export const sapReportDownload = async (type, from, to) => {

  let url = `http://13.76.91.237/waycool_services/sap_service/index.php/export?table=${type}&from_date=${from}&to_date=${to}`;
  console.log("getListData is called", url)
  let request = await axios.get(url, {
    headers: {
      // "access-control-allow-origin": "*",
      //   "x-access-token": getAuthToken(),
      // "Content-Type": "text/csv",
    },
  });
  return request;
};
