import React, { Component } from "react";
import { Spin, Modal, Popover, Button } from "antd";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import message from "antd/lib/message";
import { getCustomerDetails } from "../../services/teamServices/team";
import { userDetails } from "../../services/userServices/user";
import { formatMoney } from "../../utils/common";
import PrimaryHeader from "../header/PrimaryHeader";
import WrappedCustomerMap from "./customerLocation/customerMap";
import SalesmanCustomerMap from './../modal/salesmanCustomersMap';
import WrappedSalesmanMap from './customerLocation/salesmanMap';
import { handleErrorResponse } from "../../utils/Request";


const content = (
  <div>
    <p>Content</p>
    <p>Content</p>
  </div>
);
class AllCustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      details: null,
      mapdetails:null,
      profileDetails: null,
      selectedDate: null,
      sumOfCollection: null,
      showMap: false,
      showSalesManCustomerMap:false,
    };
  }
 
  openSalesManCustomerMap=(details)=>{
    console.log(details)
    this.setState({
      showSalesManCustomerMap:!this.state.showSalesManCustomerMap,
      mapdetails: details,
    })
  }

  getCustomerDetailsFun = async () => {
    this.setState({
      loading: true,
    });
    let id = this.props.match.params.id;
    let date = this.props.location.state;
    await getCustomerDetails(date, id)
      .then((res) => {
        if (res.data.data.customers.length == 0) {
          message.warning("No data available!");
        }
        let sumOfCollection = this.totalSum(res.data.data.customers);

        this.setState({
          details: res.data.data.customers,
          sumOfCollection,
          loading: false,
          sumOfCollection,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({
          loading: false,
        });
        const { errorMessage, errors } = handleErrorResponse(err);
      });
  };

  getuserDetails = async () => {
    await userDetails()
      .then((res) => {
        this.setState({
          profileDetails: res.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        // console.log(err);
      });
  };
  getTime = (str) => {
    if (str) {
      // let date = str && str.slice(0, 10);
      let time = str && str.slice(11, 16);

      return time;
    } else {
      return "N/A";
    }
  };
  totalCollection = (collection) => {
    let value = 0;
    collection.forEach((x) => {
      value = value + x.collectionAmount;
    });
    return value;
  };
  totalSum = (customers) => {
    let totalSum = 0;
    customers.forEach((customer) => {
      let value = this.totalCollection(customer.collection);
      totalSum = totalSum + value;
    });
    // console.log("totalSum", totalSum);
    return totalSum;
  };
  getISTTime = (date) => {
    return moment.utc(date).utcOffset("+05:30").format("HH:mm");
  };

  componentDidMount = () => {
  
    this.getCustomerDetailsFun();
    this.getuserDetails();
    let date = moment(this.props.location.state, "DD-MM-YYYY").format(
      "DD-MM-YYYY"
    );
    this.setState({
      selectedDate: date,
    });
  };
  handleShowMap = () => {
    this.setState({
      showMap: !this.state.showMap,
    });
  };
  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let { loading, details, sumOfCollection } = this.state;
    return (
      <div id="content">
        <PrimaryHeader title="Customer Collection Details" />
        <p className="selected-date">
          Selected Date:
          {this.state.selectedDate && this.state.selectedDate}
        </p>
        {/* <div>
          <Button type="primary"  onClick={()=>this.openSalesManCustomerMap(details)}
          className="float-right mt-n5">Route Map</Button>
        </div> */}
        {/* <div className="row">
            <header className="header p-3 pb-1 header-border">
              <div className="d-flex border-bottom">
                <div className="mr-auto p-2 w-100 row">
                  <div className="col mr-0">
                    Customer Collection Details
                    <span className="selected-date">
                      Selected Date:
                      {this.state.selectedDate && this.state.selectedDate}
                    </span>
                  </div>
                </div>
              </div>
            </header>
          </div> */}
        {/* <div className="row"> */}
        <div className="table-wrapper">
          <Spin indicator={antIcon} spinning={loading}>
            <table className="table">
              <thead>
                <tr className="main-tr">
                  <th colspan="2">Customer</th>
                  <th colspan="4">Visits</th>
                  <th colspan="3">Collection</th>
                  <th colspan="1">Customer Location On Map</th>
                  <th>Total Collection(&#8377;)</th>
                </tr>
                <tr>
                  {/* customer th */}
                  <th>Code</th>
                  <th>Customer Name</th>
                  {/* <th>Customer Type</th> */}

                  {/* Visits */}
                  <th>Visiting No.</th>
                  <th>Is Force</th>
                  <th>Check In</th>
                  <th>Check Out</th>

                  {/* collection */}
                  <th>Type</th>
                  <th>Mode</th>
                  <th>Amount(&#8377;)</th>

                  {/* total collection */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {details &&
                  details.map((customer, i) => {
                    return (
                      <tr key={i}>
                        <td>{customer.goFrugalId} </td>
                        <td title={customer.customerName} className="ordername">
                          {customer.customerName}{" "}
                        </td>
                        {/* <td title={customer.customerGroup}>{customer.customerGroup}</td> */}

                        {/* Visits */}
                        <td>
                          {customer.visitLog &&
                            customer.visitLog.length > 0 &&
                            customer.visitLog.map((visit, i) => {
                              return (
                                // <tr key={visit._id}>
                                <div className="section addition">{i + 1}</div>
                              );
                            })}
                        </td>
                        <td>
                          {customer.visitLog &&
                            customer.visitLog.length > 0 &&
                            customer.visitLog.map((visit, i) => {
                              return (
                                <div className="section addition">
                                  {visit.checkIn.isForceCheckIn == 1
                                    ? "true"
                                    : "false"}
                                </div>
                              );
                            })}
                        </td>
                        <td>
                          {customer.visitLog &&
                            customer.visitLog.length > 0 &&
                            customer.visitLog.map((visit, i) => {
                              return (
                                <div className="section addition">
                                  {this.getISTTime(
                                    visit.checkIn.checkInDate &&
                                      visit.checkIn.checkInDate
                                  )}{" "}
                                </div>
                                // </tr>
                              );
                            })}
                        </td>
                        <td>
                          {customer.visitLog &&
                            customer.visitLog.length > 0 &&
                            customer.visitLog.map((visit, i) => {
                              return (
                                <div className="section addition">
                                  {this.getISTTime(
                                    visit.checkOut &&
                                      visit.checkOut.checkOutDate &&
                                      visit.checkOut.checkOutDate
                                  )}
                                </div>
                              );
                            })}
                        </td>

                        <td>
                          {customer.collection &&
                            customer.collection.map((collection, i) => {
                              return (
                                <div className="section addition">
                                  {i + 1}. {collection.type}
                                </div>
                                // </tr>
                              );
                            })}
                        </td>
                        <td>
                          {customer.collection &&
                            customer.collection.map((collection, i) => {
                              return (
                                <div className="section addition">
                                  {i + 1}. {collection.mode}
                                </div>
                                // </tr>
                              );
                            })}
                        </td>
                        <td>
                          {customer.collection &&
                            customer.collection.map((collection, i) => {
                              return (
                                <div className="section addition">
                                  {i + 1}. {collection.collectionAmount}
                                </div>
                                // </tr>
                              );
                            })}
                        </td>
                        {/* map */}
                        <td>
                          {customer.visitLog[0] &&
                          customer.visitLog[0].checkIn.location.coordinates !=
                            undefined ? (
                            <Popover
                              placement="left"
                              title={`Customer Name - ${customer.customerName}`}
                              content={
                                <div className="map-container customer-map-container">
                                  <WrappedCustomerMap
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAscjPuHsmxseZEeitNZp3SrZ8kl7Z1EX0"
                                    loadingElement={
                                      <div
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                        }}
                                      />
                                    }
                                    containerElement={
                                      <div style={{ height: `100%` }} />
                                    }
                                    mapElement={
                                      <div style={{ height: `100%` }} />
                                    }
                                    mapCenter={
                                      customer.visitLog[0] &&
                                      customer.visitLog[0].checkIn.location
                                        .coordinates
                                    }
                                    name={customer.customerName}
                                                />
                                  
                                </div>
                              }
                              trigger="click"
                            >
                              <Button type="primary" className="mb-2">
                                Show Map
                              </Button>
                            </Popover>
                          ) : (
                            <div>No map location </div>
                          )}
                        </td>

                        {/* total collection */}
                        <td> {this.totalCollection(customer.collection)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="row">
              <div className="col col-md-9"></div>
              <div className="col col-md-3 pr-2 sum">
                Total Collection{" "}
                <span className="pl-2">
                  {" "}
                  &#8377;{formatMoney(sumOfCollection)}
                </span>
              </div>
            </div>
          </Spin>
        </div>
        {/* </div> */}
        {
          this.state.showSalesManCustomerMap && 
           <SalesmanCustomerMap
            displayModal={this.openSalesManCustomerMap}
            mapdetails={this.state.mapdetails}/>
        }
      </div>
    );
  }
}
export default AllCustomerDetails;
