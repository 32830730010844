import { actionTypes } from "../action/test";
const { TEST } = actionTypes;

const initialState = {
  testing: false,
};

export default function test(state = initialState, action) {
  switch (action.type) {
    case TEST:
      return { ...state, testing: true };

    default:
      return state;
  }
}
