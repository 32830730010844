import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Space,
  Table,
  Typography,
  Pagination,
  Modal,
  notification,
  Button,
  Divider,
  Input,
  Spin,
  message,
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { handleErrorResponse } from "../../utils/Request";

import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import {
  DownloadOutlined,
  FileAddOutlined,
  MailOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";

import StockUpdateMessage from "./_StockUpdateMessage";
import { columns } from "./_stockTableColumn";
import PrimaryHeader from "../header/PrimaryHeader";
import {
  getProducts,
  getUpload,
  uploadStockAndUpload,
  getAllProducts,
  priceSync,
  getCustomerGroupService
} from "../../services/Products/product";
import {
  getStockmoq,
  listenOnStockPageChange,
} from "../../action/productListsaction";
import { actionTypes } from "../../action/productListsaction";
import useDebounce from "../../utils/useDebounce";
import "./stockAndMoq.scss";
import success from "../../images/sync-success.svg";
import error from "../../images/sync-error.svg";
import {  Select } from "antd";

// import Select from "react-select";



const Stockandmoq = () => {
  const {
    loading,
    stockandMoq: { list, pageNo, recordPerPage, totalPages, syncData },
  } = useSelector((state) => state.productReducer);
  const userDetails = useSelector((state) => state.userDetails);
  const [selectedCustGrp, setSelectedCustGrp] = useState();
  const [customerGrpList, setCustomerGrpList] = useState();
  const [openMessage, setOpenMessage] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});
  const [_pageNumber, set_PageNumber] = useState(1);
  const [_recordPerPage, set_RecordPerPage] = useState(10);
  const [searchKey, setSearchvalue] = useState("");
  const [file, setFile] = useState(null);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isFileValid, setIsFileValid] = useState(true);
  const debouncedSearchTerm = useDebounce(searchKey, 500);
  const [errorMsg, setErrorMsg] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSyncModal, setIsSyncModal] = useState(false);
  const [isSyncSuccess, setISSyncSuccess] = useState(true);
  const [syncloading, setSyncLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [isData, setIsData] = useState(false);
  const { Option } = Select;

  const options =[{ _id: 'FG' }, { _id: 'JS' }, { _id: 'Q3' }, { _id: 'FT' }, { _id: 'EY' }];

  const { Search } = Input;

  const dispatch = useDispatch();

  const fileUploadRef = useRef();

  const downloadStockAndMoq = async () => {
    await getAllProducts(selectedCustGrp)
      .then((response) => {
        if (response.status === 200 && response?.data.result.items.length > 0) {
          const dataofArray = response.data.result.items;
          const arrayofData = [];
          arrayofData.push({
            "SAP ITEM ID": "SAP Item ID",
            PRODUCT: "Name",
            CONDITIONTYPE: "Condition Type",
            UOM: "UOM",
            MOQ: "MOQ",
            SELLINGPRICE: "Selling Price",
            STOCKUPLOAD: "Stock",
            ORDERQUANTITY: "Order Quantity",
            REMAINGSTOCK: "Remaining Stock",
          });
          dataofArray.forEach((item) => {
            arrayofData.push({
              "PRODUCT SAP ID": item.itemId,
              Product: item.itemName.replace(/,/g, " "),
              CONDITIONTYPE: item.conditionType,
              UOM: item.uom,
              MOQ: item.moq,
              SELLINGPRICE: item.price,
              STOCKUPLOAD: item.stock === -1 ? "Unlimited" : item.stock,
              ORDERQUANTITY: item.orderQuantity,
              REMAINGSTOCK:
                item.stock === -1
                  ? "Unlimited"
                  : item.stock - item.orderQuantity,
            });
          });

          const fileTitle = "stock&moq"; // or 'my-unique-title'

          exportCSVFile(arrayofData, fileTitle);
          setOpenMessage(true);
          setMessageInfo({
            success: true,
            title: "Download Successful!",
            subTitle: "Stock and MOQ file has been downloaded.",
          });
        }
        // console.log(res.data.result.items);
        // let blob = new Blob([res.data.result.items], { type: "text/csv" });
        // console.log(blob, "blobbbb");
        // saveAs(blob, `stock_moq1_report.csv`);
      })

      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(error);
        // message.error(errorMessage);
        console.log({ err });
      });
  };

  const showModal = () => {
    setVisible(true);
  };
  const sync = async () => {
    try {
      setSyncLoading(true);
      const res = await priceSync(selectedCustGrp);
      if (res.status === 200) {
        setSyncLoading(false);
        setIsSyncModal(true);
        setISSyncSuccess(true);
        setIsData(false);
        // if (res?.data?.status == 400) {

        // }
        dispatch(getStockmoq(pageNo, recordPerPage, debouncedSearchTerm,selectedCustGrp));
      } else if (res.status === 204) {
        // dispatch(getStockmoq(pageNo, recordPerPage, debouncedSearchTerm));
        setISSyncSuccess(false);
        setIsSyncModal(true);
        setSyncLoading(false);
        setErrorResponse("No data found");
        setIsData(true);

      }
    } catch (error) {
      const { errorMessage, errors } = handleErrorResponse(error);
      setIsData(false);
      setErrorResponse("Please try after some time");
      setSyncLoading(false);
      setISSyncSuccess(false);
      setIsSyncModal(true);
      console.log("err", error);
    }
  }
  const handleOk = () => {
    fileUpload();
  };

  const handleCancel = () => {
    setVisible(false);
    setFile(null);
  };

  const checkFileValid = (fileToRead) => {
    // const fileToRead = file;
    const reader = new FileReader();
    setIsFileValid(true);
    let isValid = true;
    reader.onload = (event) => {
      // The file's text will be printed here
      const csvData = event.target.result;
      const headers = csvData.slice(0, csvData.indexOf("\n")).split(",");
      headers.pop();
      const requiredHeaders = [
        "SAP Item ID",
        "Name",
        "Condition Type",
        "UOM",
        "MOQ",
        "Selling Price",
        "Stock",
        "Order Quantity",
      ];
      headers.forEach((item, i) => {
        console.log(item, requiredHeaders[i], item !== requiredHeaders[i]);
        if (item !== requiredHeaders[i]) {
          setIsFileValid(false);
        }
      });
    };
    reader.readAsText(fileToRead);
    // return isValid;
  };

  const fileUpload = async () => {
    if (!isFileValid) {
      notification.error({ message: "Please select a valid csv file" });
      return;
    }
    try {
      setConfirmLoading(true);
      const res = await uploadStockAndUpload(file);
      if (res.status === 200) {
        if (res?.data?.status == 400) {
          setConfirmLoading(false);
          setVisible(false);
          //  setOpenMessage(true);
          setFile(null);
          fileUploadRef.current.value = "";
          setIsModalOpen(true);
          setErrorMsg(res?.data?.error);
          return;
        }
        console.log(res);
        setConfirmLoading(false);
        setVisible(false);
        setOpenMessage(true);
        setFile(null);
        fileUploadRef.current.value = "";
        setMessageInfo({
          success: true,
          title: "Upload Successful",
          subTitle: "Stock quantity has been updated.",
        });
        dispatch(getStockmoq(pageNo, recordPerPage, debouncedSearchTerm,selectedCustGrp));
      }
    } catch (error) {
      const { errorMessage, errors } = handleErrorResponse(error);
      //  message.error(errorMessage);
      setConfirmLoading(false);
      setFile(null);
      console.log("err", error);
    }
  };
  // console.log(file);
  const onUploadFileChange = (files) => {
    let fileName = files[0].name;
    let extensionStartIndex = fileName.lastIndexOf(".");
    let extension = fileName.substring(extensionStartIndex);

    if (extension === ".csv") {
      checkFileValid(files[0]);
      setFile(files[0]);
    } else {
      notification.error({ message: "Please select a valid csv file" });
    }
  };
  useEffect(() => {
    let timer;
    if (openMessage) {
      timer = setTimeout(() => {
        // console.log("setVisible false");
        setMessageInfo({});
        setOpenMessage(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [openMessage]);

  const getCustomerGroup = async () => {
    try {
      const res = await getCustomerGroupService();
      if (res.status === 200) {
        console.log(res.data)
        setCustomerGrpList(res.data.data);
       
      }
    } catch (error) {
      const { errorMessage, errors } = handleErrorResponse(error);
      console.log("err", error);
    }
  }
  useEffect(() => {
    getCustomerGroup();
    setSelectedCustGrp(userDetails?.customerGroup);
  }, [userDetails]);
  useEffect(() => {
    set_PageNumber(pageNo);
    set_RecordPerPage(recordPerPage);
    if(selectedCustGrp != undefined)
    {
      dispatch(getStockmoq(pageNo, recordPerPage, debouncedSearchTerm,selectedCustGrp));
    }
   
    // console.log(debouncedSearchTerm, "debouncedSearchTerm");
  }, [pageNo, recordPerPage, debouncedSearchTerm,selectedCustGrp]);

  useEffect(() => {
    // console.log(_pageNumber, pageNo, "and", _recordPerPage, recordPerPage);

    // console.log(_pageNumber !== pageNo || _recordPerPage !== recordPerPage);
    if (_pageNumber !== pageNo || _recordPerPage !== recordPerPage) {
      dispatch(listenOnStockPageChange(_pageNumber, _recordPerPage));
    }
    return () => dispatch(listenOnStockPageChange(1, 10));
  }, [_pageNumber, _recordPerPage]);

  const onHandleMessageClose = () => {
    setOpenMessage(false);
  };

  const onChangeSearchValueHandle = (value) => {
    if (searchKey === "" && value === " ") return;
    set_PageNumber(1);
    setSearchvalue(value);
  };

  const onShowSizeChange = (pn, ps) => {
    set_PageNumber(pn);
  };
  const exportCSVFile = (items, fileTitle) => {
    // Convert Object to JSON`
    const jsonObject = JSON.stringify(items);

    // eslint-disable-next-line no-use-before-define
    const csv = convertToCSV(jsonObject);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `Stock&MOQ_Product_list-WC_PD.csv`);
  };
  const handleOk1 = () => {
    setIsModalOpen(false);
  };

  const handleCancel1 = () => {
    setIsModalOpen(false);
  };
  const handleSyncOk = () => {
    setIsSyncModal(false);
  };

  const handleSyncCancel = () => {
    setIsSyncModal(false);
  };

  const onChangeCustomerList = (val) => {
    setSelectedCustGrp(val);
  };

  const convertToCSV = (objArray) => {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += `${line}\r\n`;
    }
    return str;
  };

  return (
    <Fragment>
      {!syncloading ? <div id="content" className="mt-3">
        <PrimaryHeader title="Stock, Price & MOQ" />
        <div className="d-flex justify-content-between ml-2 mr-2" style={{ marginBottom: "5px" }}>
          <div className="w-100" style={{ display: "flex" }}>
            <Divider
              type="vertical"
              style={{
                border: "1.5px solid #5078F2",
                height: "24px",
              }}
            />
            <p style={{ fontWeight: "600" }}>{selectedCustGrp}</p>
          </div>
          <div>
            <div
              className="d-flex justify-content-between mr-4"
              style={{ width: 466,marginBottom:"10px" }}
            >
              <div >
                {syncData != "NA" ?
                  <p style={{ marginTop: "7px", fontSize: "11px" }}>Last updated on {moment(syncData).format("Do-MMM-YYYY : h:mm:ss a")}</p>
                  : ""}
              </div>
              <div style={{width:"136px",marginTop:"-2px"}}>
              {/* <Select
                            options={options}
                            placeholder="Select the distributor"
                             selected={selectedCustGrp}
                            // onChange={this.handleSelect}
                            // onChange={(e) => selectDistributor(e)}
                          /> */}
                          <p>Customer Group</p>
               <Select
               // className="datePicker"
                showSearch
                placeholder="Customer Group"
                value={selectedCustGrp}
                // defaultValue={{
                //   value: selectedCustGrp,
                //   label: selectedCustGrp,
                // }}
                onChange={onChangeCustomerList}
                width="122px"
              >
                {customerGrpList?.map((opt) => (
                  <Option value={opt._id} key={opt._id}>
                    {/*  disabled={parseInt(opt.value) > parseInt(lastDelDate) - 1}*/}
                    {opt._id}
                  </Option>
                ))}
              </Select>
              </div>
             

              <div >
                <Button type="primary" onClick={sync}>Sync</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between ml-2 mr-2 search_input">
          <div className="w-100">
            <Search
              style={{ width: "320px", maxHeight: "100px", padding: "10px 20px" }}
              size="large"
              className="serach_customerswithId"
              placeholder="Search by Product ID, Name"
              enterButton
              value={searchKey}
              // onSearch={handleSearch}
              // value={searchWord}
              onChange={(e) => onChangeSearchValueHandle(e.target.value)}
            />
            {/* <input
              onChange={(e) => onChangeSearchValueHandle(e.target.value)}
              placeholder="Search by Product ID, Name"
              style={{
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderBottom: "1px solid #E1E7F0",
                outline: "none",
                paddingLeft: 20,
                width: "70%",
                lineHeight: 3,
              }}
              value={searchKey}
            />
            <SearchOutlined
              style={{ marginLeft: -50, fontSize: 32, color: "#3A4960" }}
            /> */}
          </div>
          <div>
            <div
              className="d-flex justify-content-between mr-4"
              style={{ width: 315 }}
            >
              {/*    <label className="upAndDownloadBtn" for="upload">
                  <UploadOutlined className="btnIcon" />
                  <Typography>
                    Upload
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="upload"
                      onChange={(e) => onUploadFileChange(e.target.files)}
                    />
                  </Typography>
            </label>*/}
              <div className="upAndDownloadBtn" onClick={downloadStockAndMoq}>
                <Typography>Download Sample CSV</Typography>
                <DownloadOutlined className="btnIcon" />
              </div>
              <div className="upAndDownloadBtn" onClick={showModal}>
                <Typography>Upload</Typography>
                <UploadOutlined className="btnIcon" />
              </div>


            </div>
          </div>
        </div>

        <div className="mt-3" style={{ overflowX: "auto" }}>
          {/*<button type="button" onClick={downloadStockAndMoq}>
          Download
              </button>*/}
          <Table
            dataSource={list}
            columns={columns}
            rowKey={(record) =>
              record._id +
              record.uom +
              record.conditionType +
              Math.random(3).toString().substring(2)
            }
            pagination={false}
            loading={loading?.includes(actionTypes.STOCK_MOQ)}
            style={{ minHeight: 760 }}
          />

        </div>
        {list?.length ? (
          <Space
            className="my-5 d-flex justify-content-center"
            style={{
              pointerEvents: loading?.includes(actionTypes.STOCK_MOQ)
                ? "none"
                : "auto",
              opacity: loading?.includes(actionTypes.STOCK_MOQ) ? 0.5 : 1,
            }}
          >
            <Pagination
              showSizeChanger={false}
              current={_pageNumber}
              total={totalPages * recordPerPage}
              onChange={(pageNo, pageSize) =>
                onShowSizeChange(pageNo, pageSize)
              }
              size="default"
            />
          </Space>
        ) : null}
        <div style={{ position: "fixed", top: 50, right: 45, zIndex: 1010 }}>
          <StockUpdateMessage
            open={openMessage}
            success={messageInfo.success}
            onHandleClose={onHandleMessageClose}
            title={messageInfo.title}
            subTitle={messageInfo.subTitle}
          />
        </div>
      </div> :
        <div id="content">
          <div style={{ marginLeft: "430px", marginTop: "230px", }}>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 74,
                  }}
                  spin
                />} />
          </div>
        </div>}



      <Modal
        title="Upload"
        visible={visible}
        onOk={handleOk}
        okText="Upload file"
        okButtonProps={{ disabled: !file }}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "column",
            borderRadius: "0px",
          }}
        >
          <Typography className="py-2">Stock and MOQ's</Typography>

          <label className="upAndDownloadBtn" htmlFor="upload">
            <FileAddOutlined className="btnIcon" />
            <Typography>
              Select csv file (only .csv)
              <input
                type="file"
                style={{ display: "none" }}
                id="upload"
                ref={fileUploadRef}
                accept=".csv"
                onChange={(e) => onUploadFileChange(e.target.files)}
              />
            </Typography>
          </label>
          <Typography className="py-2">{file?.name}</Typography>
        </div>
      </Modal>
      <Modal
        // title="Basic Modal"
        visible={isModalOpen}
        onOk={handleOk1}
        onCancel={handleCancel1}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>{errorMsg}</p>
      </Modal>
      <Modal
        footer={null}
        visible={isSyncModal}
        onOk={handleSyncOk}
        onCancel={handleSyncCancel}
        className="balnce_modal"
      >
        <div style={{ display: "flex", justifyContent: "center" }}>

          {isSyncSuccess ?
            <div>
              <img src={success} alt="" style={{ marginLeft: "65px", height: "35px", marginTop: "35px" }} />
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "22px",
                  marginTop: "15px",
                  marginLeft: "15px"
                }}
              >
                Sync Successful
              </Typography> </div>
            : <div>
              <img src={error} alt="" style={{ marginLeft: "44px", height: "35px", marginTop: "35px" }} />
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "22px",
                  marginTop: "15px",
                  marginLeft: "15px"
                }}
              >
                Sync Failed
              </Typography>
              {isData ? <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "22px",
                  marginTop: "11px",
                  marginLeft: "24px",
                }}
              >
                {errorResponse}

              </Typography> :
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "22px",
                    marginTop: "11px",
                    marginLeft: "-6px",
                  }}
                >
                  {errorResponse}

                </Typography>}

            </div>}

        </div>
      </Modal>
    </Fragment>
  );
};

export default Stockandmoq;
