import { actionTypes } from "../action/team";
const { GET_SALES_MANAGER_DETAILS, GET_ALL_ASMS,  GET_ALL_SALESMEN } = actionTypes;

const initialState = {
  allAsmDetails: [],
  asmDetails: {},
  allSalesmen: []
};

export default function team(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ASMS:
      return { ...state, allAsmDetails: [...action.data] };
    case GET_SALES_MANAGER_DETAILS:
      return { ...state, asmDetails: { ...action.data } };
      case GET_ALL_SALESMEN:
        return { ...state, allSalesmen: [...action.data] };
    default:
      return state;
  }
}
