import {
  Table,
  Button,
  Select,
  Input,
  Modal,
  Typography,
  Divider,
  notification,
  Space,
  Pagination,
} from "antd";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PrimaryHeader from "../header/PrimaryHeader";
import { useParams } from "react-router-dom";
import { data } from "./data";
import moment from "moment";
import {
  getareassDropdown,
  getClusterDetails,
  getclustersDropdown,
} from "../../action/teamMappingAction";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import menu from "../../images/menu.svg";
import {
  deleteEmployee,
  downloadCustomerDetails,
} from "../../services/teamMapping/teamMappingService";
import { SearchOutlined } from "@ant-design/icons";
import useDebounce from "../../utils/useDebounce";
import { saveAs } from "file-saver";

function TeamMapping() {
  // const id = data.map((item) => {
  //   return item.id;
  // });
  const location = useLocation();

  const path = location.pathname.split("/").at(-1);
  console.log(path, "path");
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const debouncedSearchTerm = useDebounce(searchKey, 500);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const { Option } = Select;
  const [type, setType] = useState("");
  console.log(location);
  const dispatch = useDispatch();
  const handleSearch = (value) => {
    // const regEx = /^[0-9a-zA-Z]+$/;
    var regEx = /^[A-Za-z0-9 ]+$/;
    if (searchKey === "" && value === " ") return;
    if (value !== "" && !value.match(regEx)) return;
    // setPage(1);
    setSearchKey(value);
  };

  const { allClusterDetails, loading } = useSelector(
    (state) => state.teamMappingReducer
  );

  const RegionName = allClusterDetails?.regionName;
  const RegionID = allClusterDetails?.regionId;
  const [clusterLocation, setClusterLocation] = useState("all");
  const [areaLocation, setAreaLocation] = useState("allareas");
  const { clusterslocation } = useSelector((state) => state.teamMappingReducer);
  const { totalDocuments } = useSelector((state) => state.teamMappingReducer);
  const [selectedId, setSelectedId] = useState("");
  const areaslocation = useSelector(
    (state) => state.teamMappingReducer?.areaslocation
  );
  const ref = useRef(null);
  const locationcluster = clusterslocation[0]?.clusters || [];
  console.log(locationcluster, "locationcluster");
  useEffect(() => {
    dispatch(getclustersDropdown());
  }, []);

  const [menuIcon, setMenuIcon] = useState();

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(
      getClusterDetails(
        clusterLocation,
        areaLocation,
        searchKey,
        path,
        page,
        pageSize
      )
    );
  }, [
    clusterLocation,
    areaLocation,
    debouncedSearchTerm,
    path,
    page,
    pageSize,
  ]);
  //to="/dashboard/teammapping/heads/viewemployee"
  const viewEMployeeDetails = (record) => {
    console.log(record);
    const location = {
      pathname: `/dashboard/teammapping/heads/${path}/viewemployee/${record}`,
      state: path,
    };
    history.push(location);
  };

  const tableData = () => {
    switch (path) {
      case "Cluster":
        return allClusterDetails?.totalClusterHead;

      case "Area":
        return allClusterDetails?.totalAreaHead;

      case "Hub":
        return allClusterDetails?.totalHub;
        break;
      default:
        return;
    }
  };
  const downloadretailers = async () => {
    console.log(clusterLocation, areaLocation);
    if (path == "Hub") {
      if (clusterLocation == "all" || areaLocation == "allareas") {
        notification.error({ message: "Select the cluster and area" }, 2);
        return;
      }
    }
    setLoader(true);
    try {
      const type = path === "Hub" ? 2 : 1;
      const res = await downloadCustomerDetails(
        type,
        clusterLocation,
        areaLocation,
        RegionID
      );
      console.log(res?.data?.data, "kj");
      if (res.status == 200) {
        let today = new Date();
        let blob = new Blob([res.data], { type: "text/csv" });
        if (path == "Hub") {
          saveAs(blob, `MappedCustomers_as_on_${today}.csv`);
        } else {
          saveAs(blob, `AllCustomers_as_on_${today}.csv`);
        }
      } else {
        //  notification.warning({ message: res?.data?.data });
      }
      setLoader(false);
    } catch (err) {
      notification.error({ message: err?.response?.data?.message }, 2);
      setLoader(false);
    }
  };
  console.log(allClusterDetails, menuIcon, "nnnn");
  const deleteEmployeedetails = async (path) => {
    // const clusterCount = data11.map((item, key) => {
    //   console.log(item);

    switch (path) {
      case "Cluster":
        {
          let deletehead = allClusterDetails?.totalClusterHead?.find(
            (word) => word._id === selectedId
          );
          let regionId = deletehead?.regionId;
          let _id = deletehead?._id;
          console.log(deletehead);
          try {
            const res = await deleteEmployee({ regionId, _id, path });
            console.log(res?.data?.data, "kj");
            if (res.data.status == 200) {
              notification.success({ message: res?.data?.data });
              setIsModalOpen(false);
              dispatch(
                getClusterDetails(
                  clusterLocation,
                  areaLocation,
                  searchKey,
                  path,
                  page,
                  pageSize
                )
              );
              setSelectedId(null);
            }
          } catch (err) {}
        }
        break;
      case "Area":
        {
          let deletehead = allClusterDetails?.totalAreaHead?.find(
            (word) => word._id === selectedId
          );
          let regionId = deletehead?.regionId;
          let _id = deletehead?._id;
          console.log(deletehead);
          try {
            const res = await deleteEmployee({ regionId, _id, path });
            console.log(res?.data?.data, "kj");
            if (res.data.status == 200) {
              notification.success({ message: res?.data?.data });
              setIsModalOpen(false);
              dispatch(
                getClusterDetails(
                  clusterLocation,
                  areaLocation,
                  searchKey,
                  path,
                  page,
                  pageSize
                )
              );
              setSelectedId(null);
            }
          } catch (err) {}
        }
        break;
      case "Hub": {
        let deletehead = allClusterDetails?.totalHub?.find(
          (word) => word._id === selectedId
        );
        console.log(deletehead);
        let regionId = deletehead?.regionId;
        let _id = deletehead?._id;
        try {
          const res = await deleteEmployee({ regionId, _id, path });
          console.log(res?.data?.data, "kj");
          if (res.data.status == 200) {
            notification.success({ message: res?.data?.data });
            setIsModalOpen(false);
            dispatch(
              getClusterDetails(
                clusterLocation,
                areaLocation,
                searchKey,
                path,
                page,
                pageSize
              )
            );
            setSelectedId(null);
          }
        } catch (err) {}
      }
    }

    // });
    console.log(path, "path");
  };
  const handleChageClusterLocation = (list) => {
    console.log(list);
    console.log(clusterLocation, "clusterLocation");
    setClusterLocation(list.value);
    setAreaLocation("allareas");
    dispatch(getareassDropdown(list.value, areaLocation));
    // setPage(1);
    // setSelectedImage();
  };
  // useEffect(() => {
  //   dispatch(getareassDropdown(clusterLocation));
  // }, [clusterLocation]);

  const handleChageAreaLocation = (list) => {
    setAreaLocation(list.value);
    // setPage(1);
    // setSelectedImage();
  };
  const addEmployeedetails = (record) => {
    console.log(record, "employeerecord", path);
    const location = {
      pathname: `/dashboard/teammapping/heads/${path}/addemployee/${record}`,
      state: path,
    };
    history.push(location);
  };
  //  location = {
  //   pathname: `/teammapping/heads/${id}`,
  // };
  // console.log(id, "id");

  const showModal = () => {
    console.log(isModalOpen);
    setIsModalOpen(true);
  };
  // path.split("/").at(-1)
  const columns = [
    {
      title: "Cluster",
      dataIndex: "clusterName",
    },
    {
      title: "Cluster Code",
      dataIndex: "clusterCode",
    },
    {
      title: "Cluster Head",
      dataIndex: "headName",
    },
    {
      title: "MobileNo",

      render: (text, record) => {
        return record.isAllocated === false ? (
          <Typography>-</Typography>
        ) : (
          <Typography>{record.mobileNumber}</Typography>
        );
      },
      // dataIndex: "mobileNumber",
    },
    {
      title: "Date",
      // dataIndex: "mappedDate",
      render: (text, record) => {
        return record.isAllocated === false ? (
          <Typography>-</Typography>
        ) : (
          <Typography>
            {moment(record.mappedAt).format("DD/MM/YYYY")}
          </Typography>
        );
      },
    },

    {
      title: "",
      // dataIndex:""
      render: (_, record) => (
        <div ref={ref}>
          <Button
            type="link"
            onClick={() => {
              openEmployee(record._id);
            }}
          >
            <img src={menu} alt="" />
          </Button>
          {menuIcon === record._id ? (
            <div
              style={{
                position: "absolute",
                top: "40px",
                right: "0px",
                width: "fit-content",
                padding: "10px",

                backgroundColor: "#ffffff",

                boxShadow: "0px 8px 16px rgb(24 13 37 / 8%)",
                borderRadius: "16px",
                zIndex: 999,
                background: "white",
              }}
            >
              <ul
                style={{
                  lineHeight: "37px",
                  listStyle: "none",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {record.isAllocated === false ? (
                  <Button
                    // to="/dashboard/teammapping/heads/addemployee"
                    style={{
                      marginLeft: "16px",
                      color: "black",
                      transform: "translate(-32px, 10px)",
                    }}
                    onClick={() => {
                      addEmployeedetails(record._id);
                    }}
                  >
                    Add Employee
                  </Button>
                ) : (
                  <>
                    <Button
                      type="link"
                      onClick={() => {
                        viewEMployeeDetails(record.headId);
                      }}
                    >
                      <li
                        value="1"
                        style={{
                          marginLeft: "16px",
                          color: "black",
                          transform: "translate(-65px, 10px)",
                        }}
                      >
                        View Employee
                      </li>
                    </Button>

                    <Button
                      type="link"
                      onClick={showModal}
                      style={{
                        color: "black",
                        transform: "translate(-47px, 10px)",
                      }}
                      value="3"
                    >
                      Remove Employee
                    </Button>
                  </>
                )}

                {/*  <li value="2">Locate in Org. Chart</li>*/}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];
  const column = [
    {
      title: "Area",
      dataIndex: "areaName",
    },
    {
      title: "Area Code",
      dataIndex: "areaCode",
    },
    {
      title: "Area Head",
      dataIndex: "headName",
    },
    {
      title: "MobileNo",

      render: (text, record) => {
        return record.isAllocated === false ? (
          <Typography>-</Typography>
        ) : (
          <Typography>{record.mobileNumber}</Typography>
        );
      },
      // dataIndex: "mobileNumber",
    },
    {
      title: "Date",
      // dataIndex: "mappedDate",
      render: (text, record) => {
        return record.isAllocated === false ? (
          <Typography>-</Typography>
        ) : (
          <Typography>
            {moment(record.mappedAt).format("DD/MM/YYYY")}
          </Typography>
        );
      },
    },

    {
      title: "",
      // dataIndex:""
      render: (_, record) => (
        <div ref={ref}>
          {console.log(record, "jj")}
          <Button
            type="link"
            onClick={() => {
              openEmployee(record._id);
            }}
          >
            <img src={menu} alt="" />
          </Button>
          {menuIcon === record._id ? (
            <div
              style={{
                position: "absolute",
                top: "40px",
                right: "0px",
                width: "fit-content",
                padding: "10px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 8px 16px rgb(24 13 37 / 8%)",
                borderRadius: "16px",
                zIndex: 999,
                background: "white",
              }}
            >
              <ul
                style={{
                  lineHeight: "37px",
                  listStyle: "none",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {record.isAllocated === false ? (
                  <Button
                    // to="/dashboard/teammapping/heads/addemployee"
                    style={{
                      marginLeft: "16px",
                      color: "black",
                      transform: "translate(-32px, 10px)",
                    }}
                    onClick={() => {
                      addEmployeedetails(record._id);
                    }}
                  >
                    Add Employee
                  </Button>
                ) : (
                  <>
                    <Button
                      type="link"
                      onClick={() => {
                        viewEMployeeDetails(record.headId);
                      }}
                    >
                      <li
                        value="1"
                        style={{
                          marginLeft: "16px",
                          color: "black",
                          transform: "translate(-65px, 10px)",
                        }}
                      >
                        View Employee
                      </li>
                    </Button>

                    <Button
                      type="link"
                      style={{
                        color: "black",
                        transform: "translate(-47px, 10px)",
                      }}
                      onClick={showModal}
                      value="3"
                    >
                      Remove Employee
                    </Button>
                  </>
                )}

                {/*  <li value="2">Locate in Org. Chart</li>*/}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const columnhub = [
    {
      title: "Hub",
      dataIndex: "hubName",
    },
    {
      title: "Hub Code",
      dataIndex: "hubCode",
    },
    {
      title: "Hub Head",
      dataIndex: "headName",
    },
    {
      title: "MobileNo",

      render: (text, record) => {
        return record.isAllocated === false ? (
          <Typography>-</Typography>
        ) : (
          <Typography>{record.mobileNumber}</Typography>
        );
      },
      // dataIndex: "mobileNumber",
    },
    {
      title: "Date",
      // dataIndex: "mappedDate",
      render: (text, record) => {
        return record.isAllocated === false ? (
          <Typography>-</Typography>
        ) : (
          <Typography>
            {moment(record.mappedAt).format("DD/MM/YYYY")}
          </Typography>
        );
      },
    },

    {
      title: "",
      // dataIndex:""
      render: (_, record) => (
        <div ref={ref}>
          {console.log(record, "jj")}
          <Button
            type="link"
            onClick={() => {
              openEmployee(record._id);
            }}
          >
            <img src={menu} alt="" />
          </Button>
          {menuIcon === record._id ? (
            <div
              style={{
                position: "absolute",
                top: "40px",
                right: "0px",
                width: "fit-content",
                padding: "10px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 8px 16px rgb(24 13 37 / 8%)",
                borderRadius: "16px",
                zIndex: 999,
                background: "white",
              }}
            >
              <ul
                style={{
                  lineHeight: "37px",
                  listStyle: "none",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {record.isAllocated === false ? (
                  <Button
                    // to="/dashboard/teammapping/heads/addemployee"
                    style={{
                      marginLeft: "16px",
                      color: "black",
                      transform: "translate(-32px, 10px)",
                    }}
                    onClick={() => {
                      addEmployeedetails(record._id);
                    }}
                  >
                    Add Employee
                  </Button>
                ) : (
                  <>
                    <Button
                      type="link"
                      onClick={() => {
                        viewEMployeeDetails(record.headId);
                      }}
                    >
                      <li
                        value="1"
                        style={{
                          marginLeft: "16px",
                          color: "black",
                          transform: "translate(-65px, 10px)",
                        }}
                      >
                        View Employee
                      </li>
                    </Button>

                    <Button
                      type="link"
                      style={{
                        color: "black",
                        transform: "translate(-47px, 10px)",
                      }}
                      onClick={showModal}
                      value="3"
                    >
                      Remove Employee
                    </Button>
                  </>
                )}

                {/*  <li value="2">Locate in Org. Chart</li>*/}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];
  const tabledata = () => {
    switch (path) {
      case "Cluster":
        return columns;
        break;

      case "Area":
        return column;

      case "Hub":
        return columnhub;

      default:
        return;
    }
  };

  useEffect(() => {
    tabledata();
  }, []);

  const openEmployee = (record) => {
    if (!menuIcon) {
      setSelectedId(record);
      setMenuIcon(record);
    } else {
      setSelectedId(null);
      setMenuIcon(record);
    }
  };
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      let validButtons = ["Add Employee", "View Employee", "Remove Employee"];
      if (validButtons.includes(e.target.innerHTML)) {
        console.log(ref, e.target.innerHTML);
      }
      if (
        menuIcon &&
        !validButtons.includes(e.target.innerHTML) &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setMenuIcon(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [menuIcon]);
  // const openEmployeeArea = (record) => {
  //   setMenuIcon(record);
  //   console.log(menuIcon, "menu", record);
  // };
  return (
    <div className="mt-3 w-100 px-4">
      <PrimaryHeader title={path + " Head Mapping"} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "23px",
        }}
      >
        <div className="d-flex ml-1">
          <Divider
            type="vertical"
            style={{
              border: "2px solid #737EED",
              height: "24px",
              marginTop: "5px",
            }}
          />
          <div>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: "16px",
                color: "#3A4960",
                lineHeight: "19px",
                marginTop: "6px",
              }}
            >
              {RegionName}
            </Typography>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {path == "Cluster" || path == "Hub" ? (
            <Button
              type="primary"
              onClick={downloadretailers}
              className="downloadbeat"
              loading={loader}
            >
              Download
            </Button>
          ) : (
            ""
          )}

          <Input
            placeholder={"Search " + path}
            value={searchKey}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: "250px", height: "40px" }}
            prefix={<SearchOutlined />}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {path === "Area" || path == "Hub" ? (
          <>
            <div className="mt-3 ml-4">
              <Typography className="headtype">Cluster</Typography>
              <Select
                className="selecthead"
                labelInValue
                defaultValue={{
                  value: "all",
                  label: "All Clusters",
                }}
                style={{
                  width: 200,
                }}
                value={clusterLocation}
                onChange={handleChageClusterLocation}
                // onChange={(e) => setClusterLocation(e)}
              >
                <Option value="all">All Clusters</Option>
                {locationcluster?.map((location) => (
                  <Option key={location.key} value={location?._id}>
                    {location?.clusterName}
                  </Option>
                ))}
              </Select>
            </div>
          </>
        ) : (
          ""
        )}
        {path === "Hub" ? (
          <div className="mt-3">
            <Typography style={{ marginLeft: "19px" }} className="headtype">
              Area
            </Typography>
            <Select
              className="selecthead ml-3"
              labelInValue
              defaultValue={{
                value: "allareas",
                label: "Areas",
              }}
              style={{
                width: 200,
              }}
              value={areaLocation}
              onChange={handleChageAreaLocation}
              // onChange={(e) => setImageStatus(e)}
            >
              <Option value="allareas">All Areas</Option>
              {areaslocation?.map(
                (location) =>
                  location?.areaid && (
                    <Option key={location.key} value={location?.areaid}>
                      {location?.areaName}
                    </Option>
                  )
              )}
            </Select>
          </div>
        ) : (
          ""
        )}
      </div>

      <Table
        className="mt-3"
        dataSource={tableData()}
        columns={tabledata()}
        loading={loading}
        pagination={false}
      />
      <Space className="my-5 d-flex justify-content-center">
        <Pagination
          hideOnSinglePage={true}
          showSizeChanger={false}
          current={page}
          total={totalDocuments}
          pageSize={pageSize}
          onChange={(pageNo, pageSize) => {
            setPage(pageNo);
          }}
          size="default"
        />
      </Space>
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        closable={false}
        className="delete-modal"
        footer={null}
      >
        <Typography className="delete-text">
          Are you sure you want to remove this emp.?
        </Typography>
        <Typography className="delete_small">
          If you remove this emp. you can’t recover it.
        </Typography>
        <span
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "30px",
          }}
        >
          <Button
            type="link"
            style={{
              width: "47px",
              height: "17px",

              color: "#7A7A7A",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: "150px",
              height: "37px",
              backgroundColor: "#E13440",
              color: "white",
              border: "1px solid #FF475C",
              borderRadius: "4px",
            }}
            onClick={() => deleteEmployeedetails(path)}
          >
            Remove
          </Button>
        </span>
      </Modal>
    </div>
  );
}

export default TeamMapping;
