import React, { useEffect } from "react";
import DeliveryDates from "./DeliveryDates";
import OrderingTime from "./OrderingTime";
import { useDispatch } from "react-redux";
import OrderCancellation from "./OrderCancellation";

import ExceptionalCustomers from "./ExceptionalCustomers";
import {
  getOrderingTime,
  getDeliveryDates,
  getExceptionalCustomerDeliveryDates,
  getListOfExceptionalCustomerDeliveryDates,
  getCancellationOrderDetails,
} from "../../../action/manageOrder";

const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderingTime());
    dispatch(getDeliveryDates());
    dispatch(getExceptionalCustomerDeliveryDates());
    dispatch(getListOfExceptionalCustomerDeliveryDates());
    dispatch(getCancellationOrderDetails());
  }, []);

  return (
    <>
      <OrderingTime />
      <div className="mt-4" />
      <DeliveryDates />
      <div className="mt-4" />
      <ExceptionalCustomers />
      <OrderCancellation />
    </>
  );
};

export default Index;
