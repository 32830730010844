import generateActionTypes from "../utils/generateActionTypes";

import {
  getExeptionalCustomerOrder,
  getListOfExeptionalCustomerOrder,
  getCancellationOrderDetail,
  getOrderingTime as getOrderingTimeRequest,
  updateCancellationOrderDetails,
} from "../services/settings/manageOrder";
import { handleErrorResponse } from "../utils/Request";

export const actionTypes = generateActionTypes(
  "GET_ORDERING_TIME",
  "GET_DELIVERY_DATES",
  "GET_EXCEPTIONAL_CUSTOMER_DELIVERY_DATE",
  "LOADING",
  "STOP_LOADING",
  "UPDATE_ORDERING_TIME",
  "UPDATE_DELIVERY_DATES",
  "GET_ORDER_CANCELLATION",
  "UPDATE_ORDER_CANCELLATION",
  "UPDATE_EXCEPTIONAL_CUSTOMER_DELIVERY_DATES",
  "GET_EARLIER_LAST_EXCLUDED_DAY",
  "GET_LIST_OF_EXCEPTIONAL_CUSTOMERS"
);

// Start Loading
export function startLoading(loadType) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.LOADING,
      load: loadType,
    });
  };
}

export function stopLoading(loadType) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.STOP_LOADING,
      load: loadType,
    });
  };
}

// get ORDERING TIME
export const getOrderingTime = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.GET_ORDERING_TIME,
      });
      const res = await getOrderingTimeRequest();
      const resData = res?.data;
      if (resData.status === 200) {
        const { settings } = resData.data[0];
        const { orderFromTime, orderToTime } = settings;
        dispatch({
          type: actionTypes.GET_ORDERING_TIME,
          data: { orderFromTime, orderToTime },
        });
      }
    } catch (error) {
      const { errorMessage, errors } = handleErrorResponse(error);
      console.log({ error });
    }
  };
};

// get DELIVERY DATES
export const getDeliveryDates = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.GET_DELIVERY_DATES,
      });
      const res = await getOrderingTimeRequest();
      const resData = res?.data;
      if (resData.status === 200) {
        const { settings } = resData.data[0];
        const { orderDeliveryDays } = settings;
        dispatch({
          type: actionTypes.GET_DELIVERY_DATES,
          data: orderDeliveryDays,
        });
      }
    } catch (err) {
      console.log(err, "error");
      const { errorMessage, errors } = handleErrorResponse(err);
    }
  };
};

// get EXCEPTIONAL CUSTOMERS DELIVERY DATES
export function getExceptionalCustomerDeliveryDates() {
  return async (dispatch) => {
    try {
      const res = await getExeptionalCustomerOrder();
      const resData = res?.data;
      if (resData.status === 200) {
        const { data } = resData;
        dispatch({
          type: actionTypes.GET_EXCEPTIONAL_CUSTOMER_DELIVERY_DATE,
          data: data,
        });
      }
    } catch (err) {
      console.log(err, "error");
      const { errorMessage, errors } = handleErrorResponse(err);
    }
  };
}
export function updateOrderCancellation(
  cancelToggelStatus,
  cancelTimeHours,
  cancelTimeMinutes
) {
  return async (dispatch) => {
    try {
      const res = await updateCancellationOrderDetails(
        cancelToggelStatus,
        cancelTimeHours,
        cancelTimeMinutes
      );
      const resData = res?.data;
      if (resData.status === 200) {
        const { data } = resData;
        dispatch({
          type: actionTypes.UPDATE_ORDER_CANCELLATION,
          data: data,
        });
      }
    } catch (err) {
      console.log(err, "error");
      const { errorMessage, errors } = handleErrorResponse(err);
    }
  };
}
export function getCancellationOrderDetails() {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.GET_ORDER_CANCELLATION,
      });

      const res = await getCancellationOrderDetail();
      const resData = res?.data;
      if (resData.status === 200) {
        const { data } = resData;
        dispatch({
          type: actionTypes.GET_ORDER_CANCELLATION,
          data: data,
        });
      }
    } catch (err) {
      console.log(err, "error");
      const { errorMessage, errors } = handleErrorResponse(err);
    }
  };
}
export function getListOfExceptionalCustomerDeliveryDates() {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOADING,
        load: actionTypes.GET_LIST_OF_EXCEPTIONAL_CUSTOMERS,
      });
      const res = await getListOfExeptionalCustomerOrder();
      const resData = res?.data;
      if (resData.status === 200) {
        const { data } = resData;
        const arrangedData = data.map((item) => ({
          customerName: item.customerDetails.name,
          customerId: item.settings.exceptionalCustomers.customerId,
          earliestDay: item.settings.exceptionalCustomers.earliestDay,
          lastDay: item.settings.exceptionalCustomers.lastDay,
          excludedDays: item.settings.exceptionalCustomers.excludedDays,
        }));
        dispatch({
          type: actionTypes.GET_LIST_OF_EXCEPTIONAL_CUSTOMERS,
          data: arrangedData,
        });
      }
    } catch (err) {
      console.log(err, "error");
      const { errorMessage, errors } = handleErrorResponse(err);
    }
  };
}
