import React from "react";
import PrimaryHeader from "../../header/PrimaryHeader";
import { useState, useEffect } from "react";
import "./currentstock.scss";
import { Spin, Pagination, Tabs, DatePicker, Space, Modal } from "antd";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  getMaterialCode,
  getStockBatchId,
  getStockStorageLocation,
  getMaterialType,
  getMaterialGroup,
  getStockType,
} from "../../../services/inventoryServices/inventoryServices";
import { upperFirst } from "lodash";
import { handleErrorResponse } from "../../../utils/Request";
import message from "antd/lib/message";
import debounce from "debounce-async";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
const dateFormat = "YYYY-MM-DD";

const { TabPane } = Tabs;
const today = new Date();
export default function CurrentStock(props) {
  const [tab, setTab] = useState("matDetails");
  const [materialCode, setMaterialCode] = useState("");
  const [strgLocation, setStrgLocation] = useState("");
  const [batchId, setBatchId] = useState("");
  const [date, setDate] = useState(moment(today));
  const [materialType, setMaterialType] = useState("");
  const [materialGroup, setMaterialGroup] = useState("");
  const [stockType, setStockType] = useState("");
  const [Inputs, setInputs] = useState([]);
  const dispatch = useDispatch();
  // const closingStockListItems = useSelector(
  //   (state) => state.inventoryReducer.closingStockList
  // );
  const history = useHistory();
  useEffect(() => {
    setTab(props.location.state ? props.location.state.tab  :"matDetails" );
  }, []);
  const onChangeTab = (key) => {
    console.log(key);
    setTab(key);
    if (tab == "matDetails") {
      setStockType("");
      setDate(moment(today).format("YYYY-MM-DD"));
    } else {
      setMaterialGroup("");
      setStockType("");
      setBatchId("");
      setDate(moment(today).format("YYYY-MM-DD"));
      setMaterialCode("");
      setStrgLocation("");
    }
  };

  const storageLocation = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 5 }, { location: search }];
    let data = await getStockStorageLocation(qParams)
      .then((res) => {
        console.log(res, "ress");
        return {
          options:
            res.data.data &&
            res.data.data.map((item) => {
              return {
                key: item._id,
                value: item.location,
                label: upperFirst(item.location),
              };
            }),
          totalPage:
            parseInt(15 * res.data.data.length || 0) / parseInt(15 || 0),
        };
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadBatchId = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 5 }, { batchId: search }];
    let data = await getStockBatchId(qParams)
      .then((res) => {
        console.log(res, "res");
        return {
          options:
            res.data.data &&
            res.data.data.map((item) => {
              return {
                key: item._id,
                value: item.BatchID,
                label: upperFirst(item.BatchID),
              };
            }),
          totalPage:
            parseInt(15 * res.data.data.length || 0) / parseInt(15 || 0),
        };
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadMaterialcode = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 50 }, { material: search }];
    let data = await getMaterialCode(qParams)
      .then((res) => {
        console.log(res, "resul");
        return {
          options:
            res.data.data &&
            res.data.data.map((item) => {
              return {
                key: item._id,
                value: item.material,
                label: upperFirst(item.material),
              };
            }),
          totalPage:
            parseInt(15 * res.data.data.length || 0) / parseInt(15 || 0),
        };
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };
  const loadMaterialType = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 5 }, { materialType: search }];
    let data = await getMaterialType(qParams)
      .then((res) => {
        console.log(res, "resul");
        return {
          options:
            res.data.data &&
            res.data.data.map((item) => {
              return {
                key: item._id,
                value: item["Material Type"],
                label: upperFirst(item["Material Type"]),
              };
            }),
          totalPage:
            parseInt(15 * res.data.data.length || 0) / parseInt(15 || 0),
        };
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };
  const loadMaterialGroup = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 5 }, { materialGroup: search }];
    let data = await getMaterialGroup(qParams)
      .then((res) => {
        console.log(res, "resul");

        return {
          options:
            res.data.data &&
            res.data.data.map((item) => {
              return {
                key: item._id,
                value: item["Material Group"],
                label: upperFirst(item["Material Group"]),
              };
            }),
          totalPage:
            parseInt(15 * res.data.data.length || 0) / parseInt(15 || 0),
        };
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };
  const loadStockType = async (search, loadedOptions, { page }) => {
    let qParams = [{ page: page }, { pageSize: 5 }, { stockType: search }];
    let data = await getStockType(qParams)
      .then((res) => {
        console.log(res, "resul");

        return {
          options:
            res.data.data &&
            res.data.data.map((item) => {
              return {
                key: item._id,
                value: item["type"],
                label: upperFirst(item["type"]),
              };
            }),
          totalPage:
            parseInt(15 * res.data.data.length || 0) / parseInt(15 || 0),
        };
      })
      .catch((err) => {
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
    return {
      options: data ? data.options : [],
      hasMore: data.totalPage > page ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };
  const handleChange = (e, type) => {
    console.log(e);
    if (type == "materialCode") {
      setMaterialCode(e);
    }
    if (type == "storageLocation") {
      setStrgLocation(e);
    }
    if (type == "batchId") {
      setBatchId(e);
    }
    if (type == "materialType") {
      setMaterialType(e);
    }
    if (type == "materialGroup") {
      setMaterialGroup(e);
    }
    if (type == "stockType") {
      setStockType(e);
    }
  };
  const changeDate = (date) => {
    if (date != null) {
      setDate(date._d);
      console.log(moment(date._d).format("YYYY-MM-DD"));
    }
    //  else {
    //   let today = new Date();
    //   if (today !== null) {
    //     setDate(moment(date._d).format("YYYY-MM-DD"));
    //   }
    // }
  };
  const navToCLosingStock = () => {
    props.history.push(`/dashboard/inventory-management/closing-stock`);
  };
  const searchListing = async () => {
    let inputs = [];
    if (tab == "matDetails") {
      let str = materialCode.label ? materialCode.label : "";
      let code = str.substring(0, str.indexOf(" "));
      let name = str.substring(str.indexOf(" ") + 1);
      inputs = [
      {  materialCode: code ? code :""},
     {   materialName: name ? name :""},
      {  batchId: batchId.label ? batchId.label :""},
      {  materialGroup: materialGroup.label ? materialGroup.label :""},
      {  materialType: materialType.label ? materialType.label :""},
      {  storageLocation: strgLocation.label ? strgLocation.label :""},
       { from: moment(date).format("YYYY-MM-DD")},
       {to:moment(date).format("YYYY-MM-DD")}
      ];
      console.log(inputs);
      setInputs(inputs);
    } else {
      inputs = [{
        stockType: stockType.label},
        {from: moment(date).format("YYYY-MM-DD")},
      {to:moment(date).format("YYYY-MM-DD")}];
      console.log(inputs);
      setInputs(inputs);
    }
    history.push({
      pathname: `/dashboard/inventory-management/closing-stock`,
      state: { inputs: inputs && inputs, tab: tab,date:moment(date).format("YYYY-MM-DD")},
    });

    // await getSearchClosingStock(date, inputs)
    //   .then((res) => {
    //     console.log(res.data.data, "ressss");
    //     dispatch(getClosingStockData(res.data.data ),GET_CLOSINGSTOCK_DATA);
    //     navToCLosingStock();
    //   })

    //   .catch((err) => {
    //     console.log(err, "errrr");
    //     const { errorMessage } = handleErrorResponse(err);
    //     message.error(errorMessage);
    //   });
  };

  return (
    <div id="content" className="">
      <PrimaryHeader
        title={tab == "matDetails" ? "Current Stock" : "Closing Stock"}
      />

      <div className="card ml-5 mb-5" style={{ width: "40rem" }}>
        <div class="card-body">
          <p className="border_left subheader mt-4">Search Stock</p>

          <Tabs activeKey={tab} onChange={onChangeTab} className="ml-5">
            <TabPane tab="Material Details" key="matDetails">
              <div className="mt-3">
                <div>
                  <label class="form-labels ml-3  labelNm">Date</label>
                  <br />
                  <div className="stock-antpicker">
                    <DatePicker
                      format={dateFormat}
                      className="ml-3  mt-1 stock-date"
                      placeholder="eg: 12-07-2022"
                      onChange={(date) => changeDate(date)}
                      value={moment(date)}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label class="form-labels ml-3 labelNm">
                    Material Code/Name
                  </label>
                  <br />
                  <AsyncPaginate
                    additional={{
                      page: 1,
                    }}
                    loadOptions={debounce(loadMaterialcode)}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    // value={strgLocation}
                    onChange={(e) => handleChange(e, "materialCode", 500)}
                    loadingMessage={() => "No code/name Found"}
                    // autoSize={true}
                    // menuPlacement="auto"
                    // menuPosition="fixed"
                    className="col-lg-6 mt-1"
                    placeholder="Choose Material Code/name"
                  />
                </div>

                <div className="mt-3">
                  <label class="form-labels ml-3 labelNm">
                    Storage Location
                  </label>
                  <br />
                  <AsyncPaginate
                    additional={{
                      page: 1,
                    }}
                    loadOptions={debounce(storageLocation, 500)}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    // value={strgLocation}
                    onChange={(e) => handleChange(e, "storageLocation")}
                    loadingMessage={() => "No location Found"}
                    // autoSize={true}
                    // menuPlacement="auto"
                    // menuPosition="fixed"
                    className="col-lg-6 mt-1"
                    placeholder="Choose Storage Location"
                  />
                </div>
                <div className="mt-3 ">
                  <label class="form-labels ml-3 labelNm">Batch ID</label>
                  <br />
                  <AsyncPaginate
                    additional={{
                      page: 1,
                    }}
                    loadOptions={debounce(loadBatchId, 500)}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    // value={strgLocation}
                    onChange={(e) => handleChange(e, "batchId")}
                    loadingMessage={() => "No ID's Found"}
                    // autoSize={true}
                    // menuPlacement="auto"
                    // menuPosition="fixed"
                    className="col-lg-6 mt-1 "
                    placeholder="Choose Batch ID"
                  />
                </div>
                {/* <div className="mt-3">
                  <label class="form-labels ml-3 labelNm" >Material Type</label>
                  <br />
                  <AsyncPaginate
                    // value={""}
                    loadOptions={debounce(loadMaterialType, 500)}
                    additional={{
                      page: 1,
                    }}
                    isClearable={true}
                    isSearchable={true}
                    classNamePrefix="select"
                    onChange={(e) => {
                      handleChange(e, "materialType");
                    }}
                    className="col-lg-6 mt-1 "
                    placeholder="Choose Material Type"
                    loadingMessage={() => "No Type's Found"}
                    // autoSize={true}
                    // menuPlacement="auto"
                    // menuPosition="fixed"
                  />
                </div>
                <div className="mt-3 mb-5 col-lg-6"
              
                >
                  <label class="form-labels  labelNm">Material Group</label>
                  <br />

                  <AsyncPaginate
                    loadOptions={debounce(loadMaterialGroup, 500)}
                    isClearable={true}
                    isSearchable={true}
                    classNamePrefix="select"
                    onChange={(e) => {
                      handleChange(e, "materialGroup");
                    }}
                    loadingMessage={() => "No Groups's Found"}
                    // autoSize={true}
                    // menuPlacement="auto"
                     menuPosition="fixed"
                    className=" mt-1"
                    placeholder="Choose Material Group"
                    additional={{
                      page: 1,
                    }}
                   
                  />
                </div> */}
                <div className="mt-4 mb-3">
                  <button
                    className="btn btn-primary ml-3"
                    onClick={searchListing}
                    // disabled={
                    //   date == "" 
                    // }
                  >
                    Search
                  </button>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Stock Type" key="stockType">
              <div className="mt-3">
                <div>
                  <label class="form-labels ml-3  labelNm">Date</label>
                  <br />
                  <div className="stock-antpicker">
                    <DatePicker
                      className="ml-3  mt-1 stock-date"
                      placeholder="eg: 12-07-2022"
                      onChange={(date) => changeDate(date)}
                      format={dateFormat}
                      value={moment(date)}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label class="form-labels ml-3 labelNm">Stock Type</label>
                  <br />
                  <AsyncPaginate
                    loadOptions={debounce(loadStockType, 500)}
                    isClearable={true}
                    isSearchable={true}
                    classNamePrefix="select"
                    onChange={(e) => {
                      handleChange(e, "stockType");
                    }}
                    loadingMessage={() => "No Stock Type's Found"}
                    // autoSize={true}
                    // menuPlacement="auto"
                    menuPosition="fixed"
                    additional={{
                      page: 1,
                    }}
                    className="col-lg-6 mt-1"
                    placeholder="Choose Stock Type"
                  />
                </div>
                <div className="mt-4 mb-3">
                  <button
                    className="btn btn-primary ml-3"
                    onClick={searchListing}
                    // disabled={
                    //   date == "" 
                    // }
                  >
                    Search
                  </button>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
