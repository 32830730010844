import React, { Component } from "react";
import SearchBar from "../search-bar/searchBar";
import { handleErrorResponse } from "../../utils/Request";
import message from "antd/lib/message";
import { Spin, Pagination, Tabs } from "antd";
import plus from "../../images/plus.svg";
import PrimaryHeader from "../header/PrimaryHeader";
import { Link } from "react-router-dom";
import "./poGeneration.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { getAllIntExtList } from "../../services/poGenerationServices/poGeneration";
import moment from "moment";
import { formatMoney, stringSortingByChar } from "../../utils/common";
const { TabPane } = Tabs;

class PoGeneration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tab: "Internal",
      page: 1,
      totalPage: 0,
      pageSize: 0,
      totalItem: 0,
      internalSearch: "",
      externalSearch: "",
    };
  }
  onChangeTab = (key) => {
    this.setState(
      {
        tab: key,
      },
      () => {
        this.callListApi(key);
      }
    );
  };

  getInternalPoDetails = async () => {
    this.setState({ loading: true });
    let { page, internalSearch } = this.state;
    let { getAllInternalPo } = this.props;
    let qParams = [
      { poType: "internal" },
      { page },
      { search: internalSearch },
    ];
    await getAllIntExtList(qParams)
      .then((res) => {
        this.setState({
          loading: false,
          totalPage: res.data.data.pageMeta.total,
          pageSize: res.data.data.pageMeta.pageSize,
          totalItems: res.data.data.pageMeta.total,
        });

        getAllInternalPo(res.data.data.results);
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  getExternalPoDetails = async () => {
    this.setState({ loading: true });
    let { page, externalSearch } = this.state;
    let { getAllExternalPo } = this.props;
    let qParams = [
      { poType: "external" },
      { page },
      { search: externalSearch },
    ];
    await getAllIntExtList(qParams)
      .then((res) => {
        this.setState({
          loading: false,
          totalPage: res.data.data.pageMeta.total,
          pageSize: res.data.data.pageMeta.pageSize,
          totalItems: res.data.data.pageMeta.total,
        });

        getAllExternalPo(res.data.data.results);
      })
      .catch((err) => {
        this.setState({ loading: false });
        const { errorMessage, errors } = handleErrorResponse(err);
        message.error(errorMessage);
      });
  };

  callListApi = async (key) => {
    this.setState({
      loading: true,
    });
    if (key === "Internal") {
      this.getInternalPoDetails();
    } else if (key === "External") {
      this.getExternalPoDetails();
    } else {
      console.log("no");
    }
  };

  handleInternalChange = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.callListApi(this.state.tab);
      }
    );
  };

  handleExternalChange = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.callListApi(this.state.tab);
      }
    );
  };

  onInternalSearch = (e) => {
    this.setState(
      {
        page: 1,
        internalSearch: e.target.value,
      },
      () => {
        this.callListApi(this.state.tab);
      }
    );
  };

  onExternalSearch = (e) => {
    this.setState(
      {
        page: 1,
        externalSearch: e.target.value,
      },
      () => {
        this.callListApi(this.state.tab);
      }
    );
  };

  componentDidMount = () => {
    if (
      this.props.location.state &&
      this.props.location.state.type === "Internal"
    ) {
      this.setState({ tab: "Internal" }, () => {
        this.callListApi(this.state.tab);
      });
    } else if (
      this.props.location.state &&
      this.props.location.state.type === "External"
    ) {
      this.setState({ tab: "External" }, () => {
        this.callListApi(this.state.tab);
      });
    } else {
      this.callListApi(this.state.tab);
    }
  };

  render() {
    let { loading, tab, page, totalItems, totalPage, pageSize } = this.state;
    let { internalPoList, externalPoList } = this.props;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div id="content" className="">
        <PrimaryHeader title="PO Generation" />
        <div className="customers-header">
          <div className="row ml-2 w-100">
            <div className="col col-sm-8 col-md-8 col-lg-10 col-xl-10">
              {tab === "Internal" ? (
                <SearchBar
                  onSearch={this.onInternalSearch}
                  searchText={this.state.internalSearch}
                  fromPage="po"
                />
              ) : (
                <SearchBar
                  onSearch={this.onExternalSearch}
                  searchText={this.state.externalSearch}
                  fromPage="po"
                />
              )}
            </div>

            <div
              className=" col col-sm-4 col-md-4
             col-lg-2 col-xl-2 "
            >
              <Link
                to={{
                  pathname: `/dashboard/po-generation/create`,
                }}
                className="id-color"
              >
                <button className="btn btn-primary">
                  {" "}
                  Create New <img src={plus} alt="plus" />
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <Tabs activeKey={tab} onChange={this.onChangeTab}>
            <TabPane tab="Internal" key="Internal">
              <div className="table-wrapper">
                <Spin indicator={antIcon} spinning={loading}>
                  <table className="table approved-table">
                    <thead className="remove-border-top">
                      <tr>
                        <th scope="col">Creation Date</th>
                        <th scope="col">PO ID</th>
                        {/* <th scope="col">Validity</th> */}
                        <th scope="col">Warehouse/Plant Name</th>
                        <th scope="col">Delivery Date</th>
                        <th scope="col">Item Count</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">PO Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {internalPoList &&
                        internalPoList.length > 0 &&
                        internalPoList.map((internal) => {
                          return (
                            <tr>
                              <td>
                                {moment(internal.delivery_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td>
                                <Link
                                  to={{
                                    pathname: `/dashboard/po-generation/details/${internal._id}`,
                                    tab: tab,
                                  }}
                                >
                                  {internal.po_number
                                    ? internal.po_number
                                    : "-"}
                                </Link>
                              </td>
                              {/* <td>
                                {moment(internal.startOfValidityPeriod).format(
                                  "DD-MM-YY"
                                ) +
                                  " - " +
                                  moment(internal.endOfValidityPeriod).format(
                                    "DD-MM-YY"
                                  )}
                              </td> */}
                              <td className="nameCapitalize">
                                {internal.warehouseName}
                              </td>
                              <td>
                                {moment(internal.delivery_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td>{internal.itemCount}</td>
                              <td>
                                &#8377;{formatMoney(internal.totalAmount)}
                              </td>
                              <td
                                className={
                                  internal.orderStatus == "in-progress"
                                    ? "progress-text nameCapitalize"
                                    : "completed-text nameCapitalize"
                                }
                              >
                                {internal.orderStatus}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {internalPoList.length === 0 && (
                    <div className="no-data">
                      <p>No Data Available</p>
                    </div>
                  )}
                </Spin>
              </div>
              <div className="pagination">
                {totalItems != 0 && (
                  <p className="p-2">Total {totalItems} PO's </p>
                )}
                {totalPage != 0 && (
                  <Pagination
                    className="page"
                    defaultCurrent={1}
                    current={page}
                    total={totalPage}
                    defaultPageSize={pageSize}
                    showSizeChanger={false}
                    onChange={(page, pageSize) =>
                      this.handleInternalChange(page, pageSize)
                    }
                  />
                )}
              </div>
            </TabPane>
            <TabPane tab="External" key="External">
              <div className="table-wrapper">
                <Spin indicator={antIcon} spinning={loading}>
                  <table className="table approved-table">
                    <thead className="remove-border-top">
                      <tr>
                        <th scope="col">Creation Date</th>
                        <th scope="col">PO ID</th>
                        {/* <th scope="col">Validity</th> */}
                        <th scope="col">Vendor ID</th>
                        <th scope="col">Vendor Name</th>
                        <th scope="col">Delivery Date</th>
                        <th scope="col">Item Count</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">PO Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {externalPoList &&
                        externalPoList.length > 0 &&
                        externalPoList.map((external) => {
                          return (
                            <tr>
                              <td>
                                {moment(external.createdAt).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td>
                                <Link
                                  to={{
                                    pathname: `/dashboard/po-generation/details/${external._id}`,
                                    tab: tab,
                                  }}
                                >
                                  {external.po_number
                                    ? external.po_number
                                    : "-"}
                                </Link>
                              </td>
                              {/* <td>
                                {moment(external.startOfValidityPeriod).format(
                                  "DD-MM-YY"
                                ) +
                                  " - " +
                                  moment(external.endOfValidityPeriod).format(
                                    "DD-MM-YY"
                                  )}
                              </td> */}

                              <td>{external.vendor_no}</td>
                              <td
                                className="nameCapitalize"
                                title={external.vendor_name}
                              >
                                {stringSortingByChar(external.vendor_name, 8)}
                              </td>
                              <td>
                                {moment(external.delivery_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td>{external.itemCount}</td>
                              <td>
                                &#8377;{formatMoney(external.totalAmount)}
                              </td>
                              <td
                                className={
                                  external.orderStatus == "in-progress"
                                    ? "progress-text nameCapitalize"
                                    : "completed-text nameCapitalize"
                                }
                              >
                                {external.orderStatus}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {externalPoList.length === 0 && (
                    <div className="no-data">
                      <p>No Data Available</p>
                    </div>
                  )}
                </Spin>
              </div>
              <div className="pagination">
                {totalItems != 0 && (
                  <p className="p-2">Total {totalItems} PO's </p>
                )}
                {totalPage != 0 && (
                  <Pagination
                    className="page"
                    defaultCurrent={1}
                    current={page}
                    total={totalPage}
                    defaultPageSize={pageSize}
                    showSizeChanger={false}
                    onChange={(page, pageSize) =>
                      this.handleExternalChange(page, pageSize)
                    }
                  />
                )}
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default PoGeneration;
