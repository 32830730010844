import { actionTypes } from "../action/paymentDetailsaction";
const initialState = {
  loading: false,
  paymentDetails: null,
};

export const paymentDetailsReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case actionTypes.LOADING_PD:
      return { ...state, loading: true };
    case actionTypes.STOP_LOADING:
      return { ...state, loading: false };
    case actionTypes.GET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: { ...data.result },
        loading: false,
      };
    case actionTypes.UPDATE_ORDER_BLOCKED:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          orderBlockDetails: {
            ...state.paymentDetails.orderBlockDetails,
            orderBlockPopupStatus: false,
          },
        },
        loading: false,
      };
    default:
      return state;
  }
};
