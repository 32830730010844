import { Select } from "antd";
import React from "react";
const { Option } = Select;

const EditableSelect = (props) => {
  let {
    name,
    open,
    defaultValue,
    className,
    placeholder,
    width,
    onChange,
    error,
  } = props;
  const size = width ?? 200;
  return (
    <div className={className}>
      {open ? (
        <>
          <Select
            name={name}
            placeholder={placeholder ?? "Choose remark"}
            style={{ width: size }}
            onChange={onChange}
          >
            <Option value="Stock Available is Less">
              Stock Available is Less
            </Option>
          </Select>
          {error && <p className="text-danger">{error}</p>}
        </>
      ) : (
        <div className="pt-2">{defaultValue ?? "-"}</div>
      )}
    </div>
  );
};

export default EditableSelect;
