import { getAllCreditData } from "../services/customerServices/customer";
import generateActionTypes from "../utils/generateActionTypes";
import {
  getCreditBalance,
  getTransactions,
} from "../services/balanceConfirmation/balanceConfirmationServices";
import { handleErrorResponse } from "../utils/Request";
export const actionTypes = generateActionTypes(
  "GET_BALANCE_CONFORMATION_DATA",
  "LOADING",
  "STOP_LOADING",
  "TOKENEXPIRED",
  "SETTOKEN",
  "GET_TRANASACTION"
);
// export function stopLoading()
export const getBalanceData = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.GET_BALANCE_CONFORMATION_DATA,
    });
    try {
      const res = await getCreditBalance();
      console.log(res, "balance");
      const resData = res?.data;
      if (resData.status == 200) {
        console.log(resData);
        const data = resData?.result;
        console.log(data, "llllllllll");
        dispatch({
          type: actionTypes.GET_BALANCE_CONFORMATION_DATA,
          data: data,
        });
      } else if (resData.status == 404) {
        dispatch({
          type: actionTypes.TOKENEXPIRED,
          token: true,
        });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else if (err?.response?.status === 401) {
        dispatch({
          type: actionTypes.TOKENEXPIRED,
          token: true,
        });
      }
    }
  };
};
export const getTransactionsData = (
  monthNumber,
  id,
  pagination,
  yearnumber
) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      load: actionTypes.GET_TRANASACTION,
    });
    try {
      let skip = 0;
      console.log({ pagination });
      if (pagination?.skip || pagination?.skip === 0) {
        skip = pagination.skip + 50;
      }
      const res = await getTransactions(monthNumber, id, skip, yearnumber);
      console.log("balance", id, yearnumber);
      const resData = res?.data;
      if (resData.status == 200) {
        console.log(resData);
        const data = resData?.result;

        console.log(data, "llllllllll");
        dispatch({
          type: actionTypes.GET_TRANASACTION,
          data: data,
          id: id,
        });
      } else if (resData.status == 404) {
        dispatch({
          type: actionTypes.TOKENEXPIRED,
          token: true,
        });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err, "error");
        const { errorMessage, errors } = handleErrorResponse(err);
      } else if (err?.response?.status === 401) {
        dispatch({
          type: actionTypes.TOKENEXPIRED,
          token: true,
        });
      }
    }
  };
};
