import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";

const formateQueryString = (inArray, paramName) => {
  let queryString = "";
  inArray.forEach((item, index) => {
    queryString = queryString.concat(`${paramName}[${index}]=${item}&`);
  });
  return queryString;
};

export const onClickOrderBlockPopUp = async (CustomerId) => {
  let url = `api/v1/orders/blockPopupUpdate`;
  let request = await axios.get(APIConfig.revamp_base_url + url, {
    headers: {
      authorization: getAuthToken(),
      portaltype: "distributor",
      customerid: CustomerId,
    },
  });
  return request;
};

export const getSearchCustomerList = async (searchTerm) => {
  let url = `api/v1/orders/searchCustomers?searchParam=${searchTerm}`;
  let request = await axios.get(APIConfig.base_url + url, {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};

export const verifyIfCustomerIsWaycredCustomer = async (customerId) => {
  let url = `api/v1/orders/getWaycredStatus?goFrugalId=${customerId}`;
  let request = await axios.get(APIConfig.base_url + url, {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};
// customerGroupoptions.forEach((item, index) => {
//   console.log(item, index, "iiiiiiiiiiiiiiiii");
// });
export const getOrdersList = async (
  startDate,
  endDate,
  searchKey,

  // checkedCustomerGropList,
  checkOrderStatus,
  checkPaymentOption,
  checkedList,
  podStatus,
  otpVerification,
  soStatusList,
  invStatusList,
  page,
  pageSize
) => {
  console.log(checkedList, checkPaymentOption);
  //https://uat-api-dms.censanext.com/api/v1/soOrderStatus/orderList
  //?searchKey=Aj&pageNo=1&recordPerPage=100&startDate=2022-10-30&endDate=2023-01-30&placedBy[0]=SalesManApp
  //&placedBy=Customer&paymentType=cod&paymentType=credit&soStatus=3&soStatus=1&podStatus=true&podStatus=false&
  //otpVerification=true&otpVerification=false
  //let url1 = APIConfig.base_url + `api/v1/soOrderStatus/allOrderStatus?startDate=${startDate}&endDate=${endDate}&pageNo=${page}&recordPerPage=${pageSize}&`

  let url =
    APIConfig.base_url +
    `api/v1/soOrderStatus/orderList?startDate=${startDate}&endDate=${endDate}&pageNo=${page}&recordPerPage=${pageSize}&`;
  if (searchKey) {
    url = `${url}searchKey=${searchKey}&`;
  }
  if (podStatus?.length == 1) {
    url = url + `podStatus=${podStatus[0]}&`;
  }
  if (otpVerification?.length == 1) {
    // let otpString = formateQueryString(otpVerification, "otp");
    // url = url.concat(otpString);
    url = url + `otp=${otpVerification[0]}&`;
  }

  // if (checkedCustomerGropList?.length > 0) {
  //   let customerSegementString = formateQueryString(
  //     checkedCustomerGropList,
  //     "customerSegement"
  //   );
  //   url = url.concat(customerSegementString);
  // }
  if (checkPaymentOption?.length > 0) {
    let paymentSegementString = formateQueryString(
      checkPaymentOption,
      "paymentType"
    );
    url = url.concat(paymentSegementString);
  }
  if (checkedList?.length > 0) {
    let checkedLiistString = formateQueryString(checkedList, "placedBy");
    url = url.concat(checkedLiistString);
  }
  if (checkOrderStatus?.length > 0) {
    let checkedOrderStatusString = formateQueryString(
      checkOrderStatus,
      "orderStatus"
    );
    url = url.concat(checkedOrderStatusString);
  }

  if (soStatusList?.length > 0) {
    let soStatusString = formateQueryString(soStatusList, "soStatus");
    url = url.concat(soStatusString);
  }
  if (invStatusList?.length > 0) {
    let invStatusListString = formateQueryString(invStatusList, "invStatus");
    url = url.concat(invStatusListString);
  }
  // if (podStatus?.length > 0) {
  //   let podStatusString = formateQueryString(podStatus, "checkingPodStatus");
  //   url = url.concat(podStatusString);
  // }
  // if (otpVerification?.length > 0) {
  //   let otpString = formateQueryString(
  //     otpVerification,
  //     "otpVerificationStatus"
  //   );
  //   url = url.concat(otpString);
  // }
  console.log(url);

  // &pageNo=2&recordPerPage=2

  // let paymentTypeString = formateQueryString(checkPaymentOption, "paymentType");

  let request = await axios.get(url.slice(0, -1), {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};
// export const ordersList = async (startDate, endDate) => {
//   let url = `api/v1/soOrderStatus/allOrderStatus`;

//   let request = await axios.get(
//     APIConfig.base_url + url,
//     { startDate, endDate },
//     {
//       headers: {
//         "x-access-token": getAuthToken(),
//       },
//     }
//   );
//   return request;
// };

export const GetInvoiceDetails = async (InvoiceId) => {
  // let url = `https://uat.api-logistics.waycool.in/app/api/v1/picker-app/invoice-details/62f0a1e9b36a0e33943585b9`;
  let url = `app/api/v1/picker-app/invoice-details/${InvoiceId}`;
  let request = await axios.get(APIConfig.invoice_url + url, {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};
export const getAllItemsList = async (CustomerId) => {
  let url = `api/v1/item/bulkDownloadlist`;
  let request = await axios.get(APIConfig.revamp_base_url + url, {
    headers: {
      authorization: getAuthToken(),
      portaltype: "distributor",
      customerid: CustomerId,
    },
  });
  return request;
};

export const uploadCartItemsCSV = async (CustomerId, file) => {
  //let url = `api/v1/distributor/update-inventory`;
  let formData = new FormData();
  formData.append("file", file);
  // formData.append("basket_id", 11682);

  // let request = await axios.put(APIConfig.base_url + url, formData, {
  let url = `api/v1/cart/importCartProducts`;
  let request = await axios.post(APIConfig.revamp_base_url + url, formData, {
    headers: {
      authorization: getAuthToken(),
      portaltype: "distributor",
      customerid: CustomerId,
    },
  });
  return request;
};
