import React from "react";

const EditableInput = (props) => {
  const {
    open,
    className,
    size,
    placeholder,
    defaultValue,
    id,
    name,
    type,
    error,
    onChange,
    maxValue,
  } = props;

  return (
    <div style={{ width: `${size}px` }} className={className}>
      {open ? (
        <>
          <input
            id={id}
            name={name}
            type={type}
            min={0}
            max={maxValue}
            className="form-control"
            defaultValue={defaultValue ?? "100"}
            placeholder={placeholder}
            onChange={onChange}
          ></input>
          <span style={{whiteSpace: 'normal'}} className="text-danger">{error}</span>
        </>
      ) : (
        <div className="pt-2 mt-1">{defaultValue}</div>
      )}
    </div>
  );
};

export default EditableInput;
