import React,{useState} from 'react';
import { Table, Typography,Pagination,Tag, message, Spin,Button,Modal,Upload,Alert,Input } from 'antd';
import PrimaryHeader from "../header/PrimaryHeader";
import SearchBar from "../search-bar/searchBar";
import { LoadingOutlined,ArrowDownOutlined,ArrowUpOutlined,SearchOutlined } from '@ant-design/icons';
import {
  ConsoleSqlOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { handleErrorResponse } from "../../utils/Request";
import { getMyCustomersList,uploadCustomerGroup,getAllMinifiedCustomerGroup } from '../../services/customerServices/customer';
import { useEffect } from 'react';
import { Link } from "react-router-dom";

const {Text} = Typography

const { Search } = Input;

function MyCustomers() {
  const [customersList, setCustomerList] = useState([]);
  const [customerGroupMinifiedList, setcustomerGroupMinifiedList] = useState([]);
  const [searchText,setSearchText] = useState('')
  const [pageNo,setPageNo] = useState(1)
  const [totalPages,setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false);
  const [selectedCustomerGroupFilter, setSelectedCustomerGroupFilter] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isImportDisabled, setIsImportDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [invalidData,setInvalidData] = useState([])
  const [errordata,setErrorData] = useState([])

  const buttonStyle = {
    color: '#ffffff', 
    backgroundColor: 'navy',
    padding: '8px 16px', 
    borderRadius: '7px', 
  };

  const statusMap = {
    0: 'pending',
    1: 'success',
    2: 'failed',
  };

  const filters = customerGroupMinifiedList.map(group => ({
    text: group,
    value: group,
  }));

  const statusColors = {
    success: 'green',
    pending: 'orange',
    failed: 'red',
  };

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'name',
    },
    {
      title: 'Customer ID',
      dataIndex: 'customerId',
    },
    {
      title: 'Contact',
      dataIndex: 'mobile',
    },
    {
      title: 'Pincode',
      dataIndex: 'pincode',
    },
    {
      title: 'Customer Type',
      dataIndex: 'customerType',
      render: (text) => (text === 'NA' ? '-' : text),
    },
    {
      title: 'Customer Group',
      dataIndex: 'customer_group',
      filters : filters,
      // onFilter: (value, record) => record.customer_group.includes(value),
    },
    {
      title: 'Status',
      dataIndex: 'customerGroupRequestStatus',
      render: (customerGroupRequestStatus) => {
        const statusText = statusMap[customerGroupRequestStatus];
        const color = statusColors[statusText];
        return <Tag color={color}>{statusText}</Tag>;
      },
      filters: [
       {
        text : "pending",
        value : 0,
       },
       {
        text : "success",
        value : 1,
       },
       {
        text : "failed",
        value : 2,
       }
      ],
      // onFilter: (value, record) => statusMap[record.customerGroupRequestStatus] === value,
    },
  ];
   
  // { priceGp: selectedFilter ? selectedFilter : "" }

  const getMyCustomers = async () => {
    setLoading(true);
    try {
      let qParams = [];
      if (searchText || selectedCustomerGroupFilter.length > 0 || selectedStatus.length > 0) {
        qParams.push({ page: 1 }); 
      }else{
          qParams.push({ page: pageNo });
      }           
      if (searchText) {
        qParams.push({ search: searchText });
      }  
      selectedCustomerGroupFilter.forEach((group, index) => {
        qParams.push({ [`priceGp[${index}]`]: group });
      });  
      selectedStatus.forEach((group, index) => {
        qParams.push({ [`status[${index}]`]: group });
      });
      
      const res = await getMyCustomersList(qParams);
      if (res.status === 200) {
        setCustomerList(res?.data?.data?.getPlantCustomers);      
        setTotalPages(res?.data?.data?.totalCustomer);      
      }
    } catch (error) {
      const { errorMessage, errors } = handleErrorResponse(error);
      console.log("err", error);
    } finally{
      setLoading(false);
    }
  }

  const getMinifiedCustomerGroup = async ()=> {
    try {
      let res = await getAllMinifiedCustomerGroup();
      setcustomerGroupMinifiedList(res.data.data)
    } catch (error) {  
      console.log(error)  
    }
  }
  
  useEffect(() => {
    getMyCustomers();
    getMinifiedCustomerGroup();
  },[searchText,pageNo,selectedCustomerGroupFilter,selectedStatus])

  const showModal = () => {
    setIsModalOpen(true);
  };
 
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsImportDisabled(false);
    setCsvData([]);
    setFileList([])
    setErrorMessage(null); 
    setInvalidData([])  
    setErrorData([])  
  };

  const downloadSampleFile = () => {
    setLoading(true);

    setTimeout(() => {
      const csvContent = [
        ['Customer ID', 'Destination Customer Group'],
        ['1234567', 'NG'],
        ['6565657', 'EG'],
        ['6565657', 'SP'],
      ];
      const csvString = csvContent.map((row) => row.join(',')).join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sample.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      message.success('File downloading..');
      setLoading(false);
    }, 2000);
  };
  const onSearch = (e) => {
    setSearchText(e.target.value)
  }

  const onChange=(page, pageSize) => 
    {
      setPageNo(page)
    }

    const props = {
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setFileList([...fileList, file]);
        return false;
      },
      fileList,
    };

    // 409 - for missing data in cid/cgp
    // 500 - for invalid data in cid/cgp

    const uploadCSV = async () => {
      setUploading(true);
      if(invalidData.length > 0 || errorMessage){
        setIsModalOpen(false)
        setInvalidData([])
        setErrorData([])  
        setUploading(false);
        setIsImportDisabled(false);
        setErrorMessage(null);
        return;
      }
      let formData = new FormData();
      if (fileList.length > 0) {
        formData.append('file', fileList[0].originFileObj);
        try {
          const upload = await uploadCustomerGroup(formData);
        if (upload.status === 200) {
          message.success('Upload Successfully');
          setIsModalOpen(false)
          setTimeout(() => {
            setUploading(false);
          }, 5 * 60 * 1000); 
          getMyCustomers()
          setErrorMessage(null); 
          setInvalidData([]); 
          setErrorData([])  
        } else {
          throw upload; 
        }
        } catch (error) {
           const errorMsg = error.message || 'Upload failed';
          if (error.response && error.response.status === 409) {
            console.log(error?.response?.data?.data,'Check Invalid Data')
            setErrorMessage(error?.response?.data?.message)
            setErrorData(error?.response?.data?.data)
          } else {
            if(error.response.data.data.length > 0){
              setInvalidData(error?.response?.data?.data)
            }
            else{
              setErrorMessage(error?.response?.data?.message)
            }
          }
          setUploading(false);
          setFileList([]);
        }
      } else {
        message.error('No file selected');
        setUploading(false);
        setIsModalOpen(false)
      }
    };

    const handleFilterChange = (filters) => {
      if (filters.customer_group && filters.customer_group.length > 0) {
        setSelectedCustomerGroupFilter(filters.customer_group);
      } else {
        setSelectedCustomerGroupFilter([]);
      }
    
      if (filters.customerGroupRequestStatus && filters.customerGroupRequestStatus.length > 0) {
        setSelectedStatus(filters.customerGroupRequestStatus);
      } else {
        setSelectedStatus([]);
      }
    };
    
    const handleUpload = ({ fileList }) => {
      setFileList(fileList);
      if (fileList.length > 0) {
        const file = fileList[0].originFileObj;
        setIsImportDisabled(true);
      } else {
        setIsImportDisabled(false); 
        setCsvData([]); 
      }
    };

    // const CSVColums = csvData.length > 0 
    // ? Object.keys(csvData[0]).map(key => ({
    //     title: key,
    //     dataIndex: key,
    //     key: key,
    //   }))
    // : [];

    const handleTableChange = (pagination) => {
      setCurrentPage(pagination.current);
    };

    const textStyle = {
      marginBottom: '10px',
  };
  
  const bulletStyle = {
      listStyleType: 'disc',
  };

    const groupByCase = (data) => {
      return data.reduce((acc, item) => {
        if (!acc[item.invalidCase]) {
          acc[item.invalidCase] = [];
        }
        acc[item.invalidCase].push(item);
        return acc;
      }, {});
    };

    const groupedData = groupByCase(invalidData);

    const invalidCaseMessages = {
      0: 'Customer not found',
      1: 'Customer group not found',
      3: 'Both customer and customer group not found'
    };


  return(
    <div id="content">
        <PrimaryHeader title="My Customer Management" />
        <div className="d-flex justify-content-between ml-2 mr-2">
        <SearchOutlined style={{ fontSize: 32, color: "#3A4960" }} />
      <input
        placeholder="Search by Customer ID/ Name, Contact Number"
        style={{
          outline: "none",
          paddingLeft: 20,
          width: "70%",
          lineHeight: 3,
          border: "none",
        }}
        onChange={onSearch}
      />
          <div>
            <div
              className="d-flex justify-content-between mr-4"
              style={{ width: 315 }}
            >
              <div className="upAndDownloadBtn" onClick={downloadSampleFile} style={buttonStyle}>
                <Typography style={{color:"white"}}>Download Sample CSV</Typography>
                <DownloadOutlined className="btnIcon" />
              </div>
              <div className={`upAndDownloadBtn ${uploading ? 'disabled' : ''}`} onClick={!uploading ? showModal : undefined}>
      <Typography>Upload</Typography>
      <UploadOutlined className="btnIcon" />
    </div>
            </div>
          </div>
        </div>
          <br></br>
     
      <Table
        columns={columns}
        dataSource={customersList}
        loading={loading}
        pagination={false}
        rowKey="customerId"
        onChange={(_,filters) => handleFilterChange(filters)}
      />
       <Modal className='customers-modal' closable={false} title="Customer Group Upload" visible={isModalOpen} onCancel={handleCancel} onOk={uploadCSV} style={{width:"1000px"}}>
      
          <div className="d-flex justify-content-center mb-3">
           <Upload
            beforeUpload={() => false} 
            onChange={handleUpload}
            accept=".csv"
            fileList={fileList}
          >
            <Button icon={<DownloadOutlined />}  disabled={isImportDisabled}>
              Import CSV File
            </Button>
          </Upload>
        </div>
        {invalidData.length > 0 && (
             <div>
             {Object.keys(groupedData).map((invalidCase, index) => (
        <div key={index} style={{ marginBottom: '10px' }}>
          <Alert 
        message={<b>{invalidCaseMessages[invalidCase]}</b>} 
        type="warning" 
            description={groupedData[invalidCase].map((data, idx) => (
              <Text color="orange" key={idx}>
                            {data.customerCode}{idx < groupedData[invalidCase].length - 1 ? ', ' : ''}
              </Text>
            ))}
          />
        </div>
      ))}
        </div>
        )}

{errordata.length > 0 ? (
  <>
    <Alert 
      message={<b>Please correct the format and try uploading the file again</b>} 
      type="warning"
      description={errordata.join(', ')} // Assuming errordata is an array of errors
    />
    <br />
    <div style={textStyle}>
      <Text style={{color: '#ff4d4f'}}>Please ensure the following format:</Text>
    </div>
    <div style={textStyle}>
      <ul style={bulletStyle}>
        <li><Text><b>Customer Code: </b>Must be exactly 7 digits (e.g., 1234567)</Text></li>
        <li><Text><b>Customer Group: </b>Must be 2 uppercase letters (e.g., AB)</Text></li>
      </ul>
    </div>
  </>
) : (
  <>
    {errorMessage && (
      <Alert 
        message={<b>{errorMessage}</b>} 
        type="warning"
      />
    )}
  </>
)}

        </Modal>
      {totalPages > 5 && !loading && (
  <div className="pagination-container">
    <Pagination
      align="center"
      defaultCurrent={pageNo}
      total={totalPages}
      showSizeChanger={false}
      onChange={onChange}
    />
  </div>
)}
        
  </div>
);
}

export default MyCustomers;