import axios from "axios";
import { api as APIConfig } from "../../appConfig";
import { getAuthToken } from "../../utils/Auth";
import { getQparams } from "../../utils/common";

export const getmanageShipping = async () => {
  let request = await axios.get(
    APIConfig.base_url +
      // "api/v1/distributorSettings/settings/manageShipping/getExceptionalCustomers",
      // "api/v1/distributorSettings/settings/getCustomerDropDown",
      {
        headers: {
          "x-access-token": getAuthToken(),
        },
      }
  );
  return request;
};
export const getMinOrderValueMangShipping = async () => {
  let request = await axios.get(
    APIConfig.base_url + "api/v1/distributorSettings/settings/manageShipping",
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// update min order value
export const upDateMinOrderValue = async (inputs) => {
  let request = await axios.patch(
    APIConfig.base_url +
      `api/v1/distributorSettings/settings/manageShipping/update/manageOrderValue`,
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

// update min order value
export const upDateShippingCharge = async (inputs) => {
  let request = await axios.patch(
    APIConfig.base_url +
      `api/v1/distributorSettings/settings/manageShipping/update/shippingCharges`,
    {
      ...inputs,
    },
    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const updateOrderamountAndShippingCharge = async (
  customerId,
  orderAmount,
  shippingCharge
) => {
  console.log(orderAmount, shippingCharge, customerId);
  let request = await axios.patch(
    APIConfig.base_url +
      `api/v1/distributorSettings/settings/manageShipping/update/exceptionalCustomers`,

    {
      customerId,
      orderAmount,
      shippingCharge,
    },

    {
      headers: {
        "x-access-token": getAuthToken(),
      },
    }
  );
  return request;
};

export const getListOfExeptionalCustomerShipping = async () => {
  // let url = `api/v1/distributorSettings/settings/manageShipping/getExceptionalCustomers`;
  let url = `api/v1/distributorSettings/settings/getCustomerDropDown`;
  let request = await axios.get(APIConfig.base_url + url, {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};
export const getListOfExeptionalCustomerTable = async () => {
  let url = `api/v1/distributorSettings/settings/manageShipping/getExceptionalCustomers`;

  let request = await axios.get(APIConfig.base_url + url, {
    headers: {
      "x-access-token": getAuthToken(),
    },
  });
  return request;
};
