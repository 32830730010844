import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import "./style.scss";

// <CloseCircleOutlined />
const ErrorAlertMessage = ({
  success,
  title,
  subTitle,
  onHandleClose,
  open,
}) => {
  return (
    <div
      className="animated shadow-sm bg-white p-4 flex-row  justify-content-center"
      style={{
        width: 650,
        borderRadius: 20,
        display: open ? "flex" : "none",
      }}
    >
      <div className="mr-3 mt-2">
        {success ? (
          <CheckCircleOutlined style={{ fontSize: 64, color: "#219653" }} />
        ) : (
          <CloseCircleOutlined
            className="shadow-sm"
            style={{ fontSize: 64, color: "#EC4444" }}
          />
        )}
      </div>

      <div className="ml-3 d-flex flex-column justify-content-between">
        <Text className="font-weight-bold">{title}</Text>
        <Text>{subTitle}</Text>

        <div>
          <Button
            size="large"
            className="rounded-lg save_button mt-3"
            style={{ backgroundColor: "#5078F2", color: "#fff" }}
            onClick={onHandleClose}
          >
            OK
          </Button>
        </div>
      </div>

      <div className="position-absolute" style={{ top: 15, right: 20 }}>
        <CloseOutlined style={{ fontSize: 20 }} onClick={onHandleClose} />
      </div>
    </div>
  );
};

export default ErrorAlertMessage;
