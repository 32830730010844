import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, Button, Typography } from "antd";
import { Link } from "react-router-dom";
import "./pod.scss";
import moment from "moment";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useParams, useHistory, useLocation } from "react-router-dom";
import ImageUploading from "react-images-uploading";
// import { imagesdocuments } from "./imagelist";
import { uploadPODService } from "../../../services/otpServices/otpServices";
import { getIndividualOrderDetail } from "../../../action/IndividualOrderDetails";
import message from "antd/lib/message";
import { useSelector, useDispatch } from "react-redux";

import { handleErrorResponse } from "../../../../src/utils/Request";
// import { Document } from "react-pdf";
import { Document, pdfjs, Page } from "react-pdf";
import { slice } from "lodash";
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
function Pod() {
  const [images, setImages] = useState([]);
  const [podImageShow, setPodImageShow] = useState(true);
  const [selectedFile, setSelectedFile] = useState(true);
  const [viewimageUrl, setViewimageUrl] = useState();
  const [viewimageUrlType, setViewimageUrlType] = useState("");
  const [preview, setPreview] = useState();
  const [viewImage, setViewImage] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfType, setPdfType] = useState("");
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [OTP, setOTP] = useState("");
  const maxNumber = 69;
  const [file, setFile] = useState(null);
  const history = useHistory();
  // query params
  // const params = useParams();
  // const soId = params?.soId;
  // const InvoiceId = params?.InvoiceId;

  // const customerID = params?.customerID;
  const {
    state: { soId, InvoiceId, Otp, customerID, shipmentindex },
  } = useLocation();

  const { IndividualOrderDetails, loading } = useSelector(
    (state) => state.individualOrderDetailsReducer
  );

  // const invoicedetails =
  //   IndividualOrderDetails[0]?.orderpacked?.invoicedetails?.filter(
  //     (invId) => invId.mginvoiceId == InvoiceId
  //   );
  // const podData = invoicedetails && invoicedetails[0]?.pod;
  const podData = IndividualOrderDetails[0]?.shipments[shipmentindex]?.pod;

  // const PDF = [];
  // const image = [];
  // let bb = podData?.map((imageitem) => {
  //   console.log(imageitem.url.slice(-3) == "pdf" ? "van" : " nn");
  //   if (imageitem?.url.slice(-3) == "pdf") {
  //     PDF.push(imageitem.url);
  //   } else {
  //     image.push(imageitem.url);
  //   }

  //   console.log(imageitem.url.slice(-3) == "pdf", "VV");
  // });
  // console.log("bb", PDF, image);
  const InvoiceID =
    IndividualOrderDetails[0]?.shipments[shipmentindex]?.invoiceId;

  // const InvoiceID =
  //   IndividualOrderDetails && IndividualOrderDetails[0]?.orderpacked;
  const dispatch = useDispatch();

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    const filetype = imageList[0].file.type;
    if (
      filetype !== "image/jpeg" &&
      filetype !== "image/jpg" &&
      filetype !== "image/svg+xml" &&
      filetype !== "image/png" &&
      filetype !== "application/pdf"
    ) {
      message.warning(
        "Upload valid file. Only PNG,JPEG,SVG and PDF files are allowed."
      );
      return;
    }
    let fileSize = imageList[0].file.size;
    let bytes = fileSize / 1024;
    if (bytes >= 5000) {
      message.warning(
        "Image size is greater than 5MB. Please upload the image below 5MB."
      );
      return;
    }
    if (filetype == "application/pdf") {
      setPdfType(true);
    } else {
      setPdfType(false);
    }
    setImages(imageList);
    setSelectedFile(false);
  };

  const viewImages = (image) => {
    setViewImage(true);
    setPodImageShow(false);
    setViewimageUrl(image?.url);
    setViewimageUrlType(image?.url?.slice(-3));
  };

  const onSelectFile = (e) => {
    let formData = new FormData();
    formData.append("file", images[0].file);
    uploadPod(formData);
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
  };
  const url =
    "https://waycoolstorage.blob.core.windows.net/receivable/file-56ee0d08-edb9-4fa3-9fd1-c92547ce16be.pdf";
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  const showPodList = () => {
    setPodImageShow(true);
    setViewImage(false);
  };
  const uploadPod = async (formData) => {
    try {
      setSubmitBtn(true);
      const res = await uploadPODService(formData, InvoiceId, 1);
      if (res.data.status) {
        message.success(res.data.result);
        dispatch(getIndividualOrderDetail(soId, customerID));
      } else {
        message.warning("Failed...");
      }
      setImages([]);
      setSubmitBtn(false);
    } catch (error) {
      setImages([]);
      setSubmitBtn(false);
      if (error?.response?.status === 401) {
        console.log(error, "error");
        const { errorMessage, errors } = handleErrorResponse(error);
      } else {
        message.error(error?.response?.data?.message);
      }
    }
  };
  const onSelectBack = (e) => {
    setImages([]);
  };

  return (
    <div style={{ display: "flex", justifyContent: "start" }}>
      <div style={{ width: "100%" }}>
        {podImageShow ? (
          <>
            {" "}
            <ImageUploading
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
              allowNonImageType={true}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  {imageList.length ? null : (
                    <div style={{ display: "flex", marginTop: "50px" }}>
                      <div>
                        <button
                          style={{
                            width: "152px",
                            height: "211px",
                            border: "2px dashed #5078F2",
                            backgroundColor: "#E9EEFF",
                          }}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          <UploadOutlined
                            style={{ fontSize: "20px", color: "#5078F2" }}
                          />
                        </button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          //flexWrap: "wrap",
                          gap: "20px",
                          marginLeft: "25px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            //flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          {podData?.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                              }}
                            >
                              {item?.url.slice(-3) != "pdf" ? (
                                <img
                                  src={item?.url}
                                  style={{
                                    width: "152px",

                                    height: "211px",

                                    objectFit: "contain",
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    width: "152px",
                                    height: "211px",

                                    // objectFit: "contain",
                                  }}
                                >
                                  <Document
                                    file={item?.url}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                  >
                                    <Page
                                      pageNumber={pageNumber}
                                      renderTextLayer={false}
                                    />
                                  </Document>
                                </div>
                              )}

                              <br />
                              <div>
                                <div>
                                  {/* <Link> */}

                                  <Button
                                    type="link"
                                    style={{
                                      marginBottom: "14px",

                                      fontSize: "20px",
                                    }}
                                    onClick={() => viewImages(item)}
                                  >
                                    View
                                  </Button>

                                  {/* </Link> */}
                                </div>
                                <div>
                                  <Typography
                                    style={{
                                      fontSize: "12px",

                                      fontWeight: 500,

                                      lineHeight: "12px",

                                      color: "#7A7A7A",
                                    }}
                                  >
                                    Uploaded on{" "}
                                    {moment(item.uploadedAt)
                                      ?.utcOffset(0, false)

                                      .format("DD/MM/YYYY HH:mm A")}
                                  </Typography>

                                  <Typography
                                    style={{
                                      fontSize: "12px",

                                      fontWeight: 500,

                                      textAlign: "center",

                                      marginTop: "10px",

                                      color: "#7A7A7A",
                                    }}
                                  >
                                    {item.uploadedBy}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}{" "}
                  &nbsp;
                  <div>
                    {imageList.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "5px",
                        }}
                      >
                        <span>
                          <Button type="link" onClick={onSelectBack}>
                            <ArrowLeftOutlined
                              style={{ color: "#5078F2", fontSize: "20px" }}
                            />
                          </Button>
                          <Button
                            type="link"
                            onClick={onSelectBack}
                            style={{ color: "#5078F2" }}
                          >
                            Back to POD
                          </Button>
                        </span>
                        <span>
                          {" "}
                          <Button
                            style={{
                              backgroundColor: "#5078F2",
                              color: "white",
                              height: "45px",
                              width: "185px",
                              borderRadius: "4px",
                            }}
                            disabled={submitBtn}
                            onClick={onSelectFile}
                          >
                            Confirm and Upload
                          </Button>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        {index === index ? (
                          <div
                            className="mmm"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <div>
                              {pdfType == true ? (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                  }}
                                >
                                  <Document
                                    file={image["data_url"]}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                  >
                                    <Page
                                      pageNumber={pageNumber}
                                      renderTextLayer={false}
                                    />
                                  </Document>
                                  <div style={{ textAlign: "center" }}>
                                    <div className="pagec">
                                      Page {pageNumber || (numPages ? 1 : "--")}{" "}
                                      of {numPages || "--"}
                                    </div>
                                    <div className="buttonc">
                                      <Button
                                        type="default"
                                        disabled={pageNumber <= 1}
                                        onClick={previousPage}
                                        className="Pre"
                                      >
                                        Previous
                                      </Button>
                                      <Button
                                        className="ml-3"
                                        type="default"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}
                                      >
                                        Next
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  style={{
                                    width: "80%",
                                    padding: "5px",
                                    margin: "auto",
                                    display: "flex",
                                  }}
                                />
                              )}
                            </div>
                            <div></div>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </ImageUploading>
          </>
        ) : null}
        <div>
          {viewImage ? (
            <div>
              <div className="m-3">
                <span>
                  <Button type="link" onClick={showPodList}>
                    <ArrowLeftOutlined
                      style={{ color: "#5078F2", fontSize: "20px" }}
                    />
                  </Button>
                </span>
                <span>
                  <Button
                    type="link"
                    onClick={showPodList}
                    style={{ color: "#5078F2" }}
                  >
                    Back to POD
                  </Button>
                </span>
              </div>
              <div
                className="mmm"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div>
                  {" "}
                  {viewimageUrlType == "pdf" ? (
                    <div>
                      {" "}
                      <Document
                        file={viewimageUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page pageNumber={pageNumber} renderTextLayer={false} />
                      </Document>
                      <div style={{ textAlign: "center" }}>
                        <div className="pagec">
                          Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                          {numPages || "--"}
                        </div>
                        <div className="buttonc">
                          <Button
                            type="default"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                            className="Pre"
                          >
                            Previous
                          </Button>
                          <Button
                            className="ml-3"
                            type="default"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={viewimageUrl}
                      style={{
                        width: "80%",
                        height: "80%",
                        display: "flex",
                        margin: "auto",
                      }}
                    />
                  )}
                </div>
                <div></div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Pod;
