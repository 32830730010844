import React from "react";
import ReactSelect from "react-select";

function Select(props) {
  const {
    placeholder,
    options,
    className,
    label,
    closeMenu,
    isMulti,
    onChange,
    defaultValue,
    name,
    error,
    onSearch,
    disabled,
    customStyles,
    isClearable,
    menuHeight
  } = props;

  return (
    <div style={{zIndex: '4000'}} className={`${className}`}>
      <label className="cust">{label}</label>
      <ReactSelect
        isMulti={isMulti ?? false}
        closeMenuOnSelect={closeMenu ?? false}
        components={{
          IndicatorSeparator: () => null,
        }}
        placeholder={placeholder ?? "Select"}
        value={defaultValue}
        onChange={onChange}
        maxMenuHeight={menuHeight}
        isClearable={isClearable}
        onInputChange={onSearch}
        styles={customStyles}
        name={name}
        options={options}
        isDisabled={disabled}
      />
      <div>
        <span className="error-message">{error}</span>
      </div>
    </div>
  );
}
export default Select;
